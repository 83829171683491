import React, { FC } from 'react'

// Dependencies
import { useActor } from '@xstate/react';

// Types and model
import Texts from '../../models/Texts'
import { Sensor, Word } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface SensorListProps {
  service: any
}
 
/**
 * La lista de sensores
 * @param props contiene todas las propiedades necesarias descritas en SensorListProps
 * @param props.service actor que controla la vista
 * @returns tabla de sensores
 */
const SensorList: FC<SensorListProps> = ({ service }) => {

  // Hook para la traducción
  const { getTranslation } = useTranslation()

  // Actor que controla la lista de sensores
  const [current, _] = useActor(service)

  // Sacamos los valores del context
  const { context, value } : any = current

  /**
   * Obtener el número de resultados
   * @returns el total de resultados
   */
  const getPerPage = () : number => {

    if (!context.sensors) return 0

    // eslint-disable-next-line camelcase
    const { per_page, total } = context.sensors.meta

    // eslint-disable-next-line camelcase
    return total < per_page ? total : per_page
  }

  /**
   * Obtener el tipo de sensor
   * @param type de sensor que es
   * @returns la cadena de texto a mostrar
   */
  const getOwnerShip = (sensor: Sensor) : string => {
    
    if (sensor.ownership_type && sensor.user_uuid) 
      return getTranslation( Texts.user as Word )

    return getTranslation( Texts.center as Word )

  }

  return ( 
    <div className="overflow-x-scroll lg:overflow-x-auto">
      <p
        className = "lg:bg-input-background p-2 mt-8 lg:rounded-tl-xl lg:rounded-tr-xl lg:border lg:border-table text-gray-450"
      >
        { getTranslation( Texts.showing as Word )} 1-{ getPerPage() } de { context.sensors ? context.sensors.meta.total : 0 } {getTranslation( Texts.navMenuSensors as Word ) }
      </p>
      <table className="table-auto shadow-md w-full capitalize">
        <thead className="bg-input-background">
          <tr>
            <th className="py-2 border border-table">
              { getTranslation( Texts.sensorTableHeaderSensor as Word ) }
            </th>
            <th className="py-2 border border-table">
              MAC
            </th>
            <th className="py-2 border border-table">
              { getTranslation( Texts.sensorTableHeaderIDSensor as Word ) }
            </th>
            <th className="py-2 border border-table">
              { getTranslation( Texts.user as Word ) }
            </th>
            <th className="py-2 border border-table">
              { getTranslation( Texts.sensorTableHeaderOwnership as Word ) }
            </th>
            <th className="py-2 border border-table">
              { getTranslation( Texts.trainingDetailTime as Word ) }
            </th>
          </tr>
        </thead>
        <tbody>

          { (value !== 'loading' && context.sensors) && (
            <>
              {context.sensors.data.map((sensor: Sensor) => (
                <tr
                  key = { `${sensor.sensor_uuid}-${sensor.user_uuid}`}
                >
                  <td className="p-2 border border-table" >
                    { sensor.sensor_name }
                  </td>
                  <td className="p-2 border border-table normal-case" >
                    { sensor.sensor_mac }
                  </td>
                  <td className="p-2 border border-table normal-case" >
                    { sensor.sensor_id_center || '-' }
                  </td>
                  <td className="p-2 border border-table normal-case" >
                    { sensor.user_email || '-' }
                  </td>
                  <td className="p-2 border border-table" >
                    { getOwnerShip(sensor) }
                  </td>
                  <td className="p-2 border border-table" >
                    { sensor.updated_at || '-' }
                  </td>
                </tr>
              ))}
            </>
          )}

        </tbody>
      </table>
    </div>
  );
}
 
export default SensorList;