/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, CSSProperties } from 'react'

// Dependencies
import { FormControl, Select, SelectProps } from '@mui/material';
import { useField } from 'formik'
import InputError from './InputError';

interface InputSelectProps extends SelectProps {
  background ?: 'light' | 'dark',
  name: string,
  label: string,
  labelStyle ?: CSSProperties
  errorText ?: string,
  divClasses ?: string,
  divStyle ?: CSSProperties,
  [key: string]: any,
}
 
const InputSelect: FC<InputSelectProps> = ({ 
  divClasses, divStyle, name, label, background = 'light', errorText, children, 
  labelStyle,
  ...rest 
}) => {

  const [field, meta, ] = useField(name)

  return ( 
    <div 
      className = { `mt-8 md:mt-4 flex flex-col ${divClasses || ''}` }
      style = {{ ...divStyle }}
    >

      <label
        htmlFor="role"
        className = "uppercase mb-4"
        style = {{ ...labelStyle }}
      >
        { label }
      </label>

      <FormControl
        fullWidth
        variant = 'outlined'
        error = {(meta.touched && !!meta.error)}
        sx = {{
          background: '#303030',
          borderRadius: 8,
          color: 'white'
        }}
      >
          <Select
            labelId = "type"
            id = "role"
            sx = {{
              background: background === 'light' ? '#424242' : '#303030',
              borderColor: 'none',
              color: 'white',
              borderRadius: '0.75rem!important',
              outlineOffset: '1px',
              '& fieldset': {
                borderColor: `${background === 'light' ? '#424242' : '#303030'} !important`
              },
              '&:hover fieldset': {
                borderColor: 'white !important'
              }
            }}
            MenuProps = {{ 
              sx: {
                color: "white"
              }
            }}
            { ...field }
            { ...rest }
          >
            { children }
          </Select>
      </FormControl>

      { meta.touched && meta.error
        && (
          <InputError
            colorLabel = "text-red-500"
          >
            { errorText }
          </InputError>
        ) 
      }
    </div>
  );
}
 
export default InputSelect;