import React, { FC } from 'react'

// Dependencies
import { useQRCode } from 'next-qrcode'

interface RQRImageProps {
  url: string,
  width ?: number
}
 
const RQRImage: FC<RQRImageProps> = ({ url, width = 800 }) => {

  // Hook para sacar el QR
  const { Image } = useQRCode()

  return ( 
    <Image
      text = { url }
      options={{
        type: 'image/png',
        width, 
      }}
    />
  );
}
 
export default RQRImage;