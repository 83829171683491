import React, { FC, ReactElement } from 'react'

interface ContainerProps {
  title: string,
  icon ?: ReactElement
  classes ?: string,
  uppercase ?: boolean,
  rightButton ?: ReactElement,
  description ?: string,
}

/**
 * Contenedor principal donde aparece una barra gris hasta arriba y el contenido dentro de este
 * @param {Object} props contiene todas las propiedades necesarias descritas en ContainerProps
 * @param {string} props.title que tendrá el contenedor
 * @param {ReactElement} props.icon puede llevar un icono a lado del titulo
 * @param {string} props.classes css para modificar la vista del contenedor
 * @param {boolean} props.uppercase hacer mayúsculas el titulo
 * @param {ReactElement} props.rightButton puede llevar un botón en el lado derecho
 * @param {string} props.description puede llevar una descripción debajo del titulo
 * @returns barra superior
 */
const Container: FC<ContainerProps> = ({ 
  title, icon, classes,children, uppercase, rightButton, description
}) => ( 
  <div className="border border-card-body rounded-md">
    <div 
      className={`bg-card-body flex justify-between items-center p-5 items-center 
      ${uppercase ? 'uppercase' : 'capitalize '}`}
    >
      <div className = "flex items-center">
        {icon}
        <div className='ml-2'>
          <h3 className = {`tracking-wide font-bold ${uppercase ? 'text-base' : 'text-lg'}`}>
            {title}
          </h3>

          { description && (
            <p className='text-sm font-light'>
              { description }
            </p>
          )}
        </div>
      </div>
      { rightButton || null}
    </div>
    <div className={`p-5 ${classes || ''}`}>
      {children}
    </div>
  </div>
)
 
export default Container;