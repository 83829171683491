import React, { FC } from 'react'

// Dependencias
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

// Types
import { ChartData } from '../../types';

// Charts
import PieChart from '../charts/PieChart'
import LineChart from '../charts/LineChart'

interface ChartModalProps {
  open: boolean,
  isPie: boolean,
  sessions: ChartData,
  trainings: ChartData,
  setClose: () => void,
}
 
/**
 * Modal para maximizar la gráfica
 * @param props contiene todas las propiedades necesarias descritas en ChartModalProps
 * @param props.open saber cuando abrir el modal
 * @param props.isPie saber si la gráfica a mostrar será tipo pie
 * @param props.sessions data la para la gráfica de puntos
 * @param props.trainings data para la gráfica de pie
 * @param props.setClose cerrar el modal
 * @returns Modal para maximizar la gráfica
 */
const ChartModal: FC<ChartModalProps> = ({ open, isPie, sessions, trainings, setClose }) => ( 
    <Dialog
      open = { open }
      onClose = { () => setClose() }
      fullWidth
    >

      <DialogTitle>
        { isPie
          ? 'Tipo de entrenamiento'
          : 'Historial de sesiones'
        }
      </DialogTitle>

      <div className="p-8 h-100">
        { isPie
          ? (<PieChart data = { trainings } />)
          : (<LineChart data = { sessions } />)
        }
      </div>
    </Dialog>
  )
 
export default ChartModal;