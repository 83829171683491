/**
 * @description
 * Los colores están agregados en el archivo de configuración de tailwind en colores
 * evitar agregar colores en este fichero y mejor agregar en tailwind para usarlos como
 * utilidad de css :)
 * Unicamente utilizar cuando se tiene insertar un color con js
 */
enum Colors {
  eMax = '#e42823',
  eIte = '#ff9800',
  eMod = '#4caf50',
  eLgt = '#2196f3',
  eMin = '#bdbdbd',
  secondaryBackgroundColor = '#424242',
  primaryColor = '#fff',
  waiting = '#171717',
  deleteUserButton = '#fff',
  mainColor =  "#009688",
  primary =  "#009688",
  transparent =  "rgba(255, 0, 0, 0);",
  third = '#9E7537',
  forth = '#ffed00',
  title = '#ED3E3E',
  eighth = '#23618D',
  ninth = '#6ABA5D',
  tenth = '#A75EA9',
  'first-gradient' = '#D0C55F',
  'second-gradient' = '#838383',
}

export default Colors