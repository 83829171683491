import React, { FC } from 'react'

import { DialogContent } from '@mui/material';

interface RContentProps {
  
}

const RContent: FC<RContentProps> = ({ children }) => ( 
    <DialogContent
      sx ={{
        position: 'relative'
      }}
    >
      { children }
    </DialogContent>
  )
 
export default RContent;