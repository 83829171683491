import React, { FC, useContext, useState } from 'react'

// Dependencies
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress, Dialog, DialogTitle, Switch, SelectChangeEvent } from '@mui/material'

// Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Validation
import { Formik, FormikProps } from "formik";

// Context
import UIContext from '../../context/ui/UIContext'

// Types and models
import { 
  Room, Athlete, 
  ChallengeConfig, 
  CreateChallenge, 
  UserIncluded, 
  Challenge, 
  UserChallenge, 
  EditChallenge,
  RoomIncluded,
  ChallengeTypeModality, 
} from '../../types'

// Components
import { 
  InputForm, InputError, 
  InputTextArea, InputDatePicker, InputSelect 
} from '../utils/RookFormInputs';
import Configurator from './Configator';
import { RBox } from '../utils/RookLayout';
import HtmlTooltip from './utils/HtmlTooltip';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validations
import { 
  validationSchema, 
  validationSchemaEdit 
} from '../../validations/ValidationsCreateChallenge'


interface CreateChallengeModalProps {
  open: boolean,
  loadingExtra: boolean,
  loading: boolean,
  rooms: Room[],
  users: Athlete[],
  config: ChallengeConfig,
  edit: Challenge | null,
  onClose: () => void,
  sendChallenge: (challenge: CreateChallenge) => void
  updateChallenge: (challenge: EditChallenge) => void
}

/**
 * Componente tiene el formulario para crear un reto
 * @param props contiene todas las propiedades necesarias descritas en
 * CreateChallengeModalProps
 * @param props.open saber si esta abierto o no
 * @param props.loadingExtra saber si se están descargando rooms o usuarios
 * @param props.loading saber si esta esperando respuesta del server
 * @param props.rooms listado de salas disponibles
 * @param props.users listado de usuarios disponibles
 * @param props.config listado de tipos de reto y modalidades
 * @param props.edit cuando se va cargar la data del formulario
 * @param props.onClose función que se ejecuta al cerrar el modal
 * @param props.sendChallenge función para crear un reto
 * @param props.updateChallenge función para editar un reto
 * @returns componente tiene el formulario para crear un reto
 */
const CreateChallengeModal: FC<CreateChallengeModalProps> = ({ 
  open, loadingExtra, loading, rooms, users, config, edit, onClose, sendChallenge, updateChallenge
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // para comenzar a cargar pero se esta formateando la data antes de enviar
  const [preparing, setPreparing] = useState(false)

  // Context
  const { lang, branchUUID, centerData } = useContext( UIContext )

  /**
   * Retorna la posición del arreglo que tiene menor valor [0,1] -> 0
   * @param array de números para saber el equipo que tiene menos integrantes
   * @returns el index de la posición con menor valor
   */
  const getFirstIndexOfMinValue = (array: number[]) =>
        array.reduce((r, v, i, a) => v >= a[r] ? r : i, -1); 

  /**
   * Reiniciar el valor de modalidad
   * @param event cuando cambie el tipo de reto
   * @param props se reinicia el valor de modalidad
   */
  const resetModality = (event: SelectChangeEvent<any>, props: FormikProps<any>) : void => {
    
    props.handleChange( event )
    props.handleChange({ target: { name: 'modality', value: 'b' }})
    props.handleChange({ target: { name: 'minimumUsersRegisterForTeams', value: true }})
    props.handleChange({ target: { name: 'rooms', value: '' }})
    props.handleChange({ target: { name: 'users', value: '' }})

  }

  /**
   * Saber que vamos a validar si usuarios o rooms
   * @param event cuando cambie la modalidad del reto
   * @param props se busca validar los usuarios o rooms
   */
  const handleChangeModality = (event: SelectChangeEvent<any>, props: FormikProps<any>) : void => {
    
    props.handleChange(event)

    // Verificar que vamos a validar si usuarios o equipos
    if (event.target.value === 'user' || event.target.value === 'teams') {
      props.handleChange({ target: { name: 'users', value: 'b' }})
      props.handleChange({ target: { name: 'rooms', value: '' }})
    }

    else {
      props.handleChange({ target: { name: 'users', value: '' }})
      props.handleChange({ target: { name: 'rooms', value: 'b' }})
    }
    
    if (event.target.value === 'teams') {
      props.handleChange({ target: { name: 'minimumUsersRegisterForTeams', value: false }})
    }
    else {
      props.handleChange({ target: { name: 'minimumUsersRegisterForTeams', value: true }})
    }

  }

  /**
   * Formatear la data para poder ser enviada
   * @param rawRooms rooms seleccionados en formik
   * @param rawUsers usuarios seleccionados en formik
   * @param modality la modalidad seleccionada
   * @param teams número de equipos
   * @returns devolver los rooms o los usuarios ya formateados
   */
  const parseData = (
    rawRooms: string, rawUsers: string, modality: string, teams: number,
  ) : { 
    uuidRooms: RoomIncluded[], 
    users: { [key: string]: UserIncluded }, 
    ceroTeam: string[] 
  } => {

    // Parseamos los rooms
    const uuidRooms : RoomIncluded[] = []
    const selectedRooms : Room[] = JSON.parse(rawRooms === '' ? '[]' : rawRooms)

    let uuidTeams: string[] = []

    // Verificamos si hay usuarios
    let selectedAthletes: Athlete[] = []
    const selectedUsers: { [key: string]: UserIncluded } = {}

    if (selectedRooms.length === 0) {
      selectedAthletes = JSON.parse(rawUsers === '' ? "[]" : rawUsers)

      let teamCount: number[] = []

      if (modality === 'teams') {

        if (edit?.challenge_teams) {
          
          edit.challenge_teams.data.forEach( team => {

            uuidTeams.push(team.team_uuid)

          })

          if (uuidTeams.length < teams) {

            const diff = teams - uuidTeams.length
            for (let index = 0; index < diff; index += 1) {
              
              uuidTeams.push(uuidv4())
              
            }

          }

        }
        else {
          uuidTeams = Array(teams).fill("").map(() => uuidv4())
        }

        teamCount = Array(teams).fill(0)
      }

      selectedAthletes.forEach((e, idx) => {

        let teamFormat : any = {}

        if (modality === 'teams') {

          if (e.team) {
            teamCount[Number(e.team)-1] = teamCount[Number(e.team)-1] + 1
            teamFormat = { uuid: uuidTeams[Number(e.team)-1], name: e.team }
          }
          else {
            const place = getFirstIndexOfMinValue(teamCount)
            teamCount[place] += 1
            teamFormat = { uuid: uuidTeams[place], name: `${place + 1}` }
          }

        }

        selectedUsers[`${idx + 1}`] = {
          uuid: e.user_uuid || '',
          name: e.name || null,
          last_name_1: e.last_name_1 && e.last_name_1 !== 'null' ? e.last_name_1 : '-',
          email: e.email,
          pseudonym: e.pseudonym,
          team: teamFormat,
          image_url: e.url_image
        }

      })

      teamCount.forEach( (count, index) => {


        if (count > 0) {
          uuidTeams[index] = 'NA'
        }

      })

    }
    else {

      selectedRooms.forEach(r => {
        
        uuidRooms.push({
          uuid: r.room_uuid || r.uuid,
          name: r.name,
          description: r.description,
          'logo_image': r.logo_image,
          'background_image': r.background_image,
        })

      })

    }

    return {
      uuidRooms,
      users: selectedUsers,
      ceroTeam: uuidTeams.filter( i => i !== 'NA' )
    }
    
  }

  /**
   * Determinar los usuarios a enviar
   * @param previous saber los usuarios que antes estaban seleccionados
   * @param current lista de usuarios actuales
   * @returns un listado de usuarios eliminados, finales o nuevos
   */
  const getFinalUsers = (previous: UserChallenge[], current: UserIncluded[]) 
  : { deleted: string[], final: { [key: string]: UserIncluded } } => {
    
    const newUsers = current
    const deletedUsers : string[] = []

    let i = 0

    while (i < previous.length) {

      // eslint-disable-next-line no-loop-func
      const index = newUsers.findIndex( c => c.email === previous[i].email )

      if ( index >= 0) {

        if ( newUsers[index].team.name && newUsers[index].team.name !== previous[i].team ) {
          deletedUsers.push( previous[i].user_uuid )
        }
        else {
          newUsers.splice(index, 1)
        }

      }
      else {
        deletedUsers.push( previous[i].user_uuid )
      }

      i += 1

    }

    return {
      deleted: deletedUsers,
      final: newUsers.reduce((a, v, idx) => ({ ...a, [`${idx}`]: v}), {}) 
    }

  }

  /**
   * Determinar los rooms a enviar
   * @param previous listado de rooms antes seleccionados
   * @param currentRooms listados de room seleccionados
   * @returns una lista de rooms eliminados
   */
  const getFinalRooms = (previous: Room[], currentRooms: RoomIncluded[]) : {
    deleted: string[],
    finalRooms: RoomIncluded[]
  } => {
    
    const newRooms = currentRooms
    const deletedRooms : string[] = []

    let i = 0

    while (i < previous.length) {
      
      const previousRoomID = previous[i].room_uuid || previous[i].uuid

      // eslint-disable-next-line no-loop-func
      const index = newRooms.findIndex( c => c.uuid === previousRoomID )

      // Si existe en los nuevos rooms lo sacamos para no insertarlo doble
      if ( index >= 0)  newRooms.splice(index, 1)

      // Si no existe lo ponemos como room eliminado
      else  {
        deletedRooms.push( previousRoomID )
      }

      i += 1
    }

    return {
      deleted: deletedRooms,
      finalRooms: newRooms
    }

  }

  /**
   * Petición para crear un reto
   * @param modalityID id de la modalidad
   * @param typeID id del tipo de reto
   * @param values data a enviar
   * @param selectedUsers usuarios finales
   * @param selectedRooms salas finales
   */
  const handleCreateChallenge = (
    modalityID: ChallengeTypeModality | undefined, 
    typeID: ChallengeTypeModality | undefined, 
    values: any,
    selectedUsers: { [key: string]: UserIncluded }, 
    selectedRooms: RoomIncluded[]
  ) : void => {
    
    const currentDate = moment().tz(centerData?.offset_name || 'America/Mexico_City')
    let startDate = moment(values.start).tz(centerData?.offset_name || 'America/Mexico_City')
    const finishAt = moment
      .tz(values.end, centerData?.offset_name || 'America/Mexico_City')
      .endOf('day')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')

    if (startDate > currentDate) {
      startDate = startDate.startOf('day')
    }

    const createChallenge : CreateChallenge = {
      branch_uuid: branchUUID,
      challenge_modality_id: modalityID ? modalityID.id : 0,
      challenge_type_id: typeID ? typeID.id : 0,
      name: values.name,
      start_at: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
      finish_at: finishAt,
      users: selectedUsers,
      rooms: selectedRooms,
      challenge_compliance: values.compliance ? 'free' : 'competitive',
      offset_name: centerData?.offset_name || 'America/Mexico_City',
      offset: centerData?.offset || '-5'
    }

    let { description } : any = values
    description = description.trim()

    if (description !== '') createChallenge.description = description

    // Enviamos el challenge
    sendChallenge(createChallenge)

  }

  /**
   * Petición para editar un reto
   * @param modalityID id de la modalidad
   * @param typeID id del tipo de reto
   * @param values data a enviar
   * @param selectedUsers usuarios nuevos
   * @param deletedUsers usuarios eliminados
   * @param deletedTeams equipos eliminados
   * @param deletedRooms salas eliminadas
   * @param selectedRooms salas nuevas
   */
  const handleEditChallenge = (
    modalityID: ChallengeTypeModality | undefined, 
    typeID: ChallengeTypeModality | undefined, 
    values: any,
    selectedUsers: { [key: string]: UserIncluded }, 
    deletedUsers: string[],
    deletedTeams: string[],
    deletedRooms: string[],
    selectedRooms: RoomIncluded[]
  ) : void => {
    
    const finishAt = moment
      .tz(values.end, centerData?.offset_name || 'America/Mexico_City')
      .endOf('day')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')

    const startAt = moment
      .tz(values.start,centerData?.offset_name || 'America/Mexico_City')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')

    const editChallenge : EditChallenge = {
      branch_uuid: branchUUID,
      challenge_modality_id: modalityID ? modalityID.id : 0,
      challenge_type_id: typeID ? typeID.id : 0,
      name: values.name,
      start_at: startAt,
      finish_at: finishAt,
      users: selectedUsers,
      detached_users: deletedUsers,
      detached_teams: deletedTeams,
      detached_rooms: deletedRooms,
      rooms: selectedRooms,
      challenge_compliance: values.compliance ? 'free' : 'competitive',
      offset_name: centerData?.offset_name || 'America/Mexico_City',
      offset: centerData?.offset || '-5'
    }

    let { description } : any = values
    description = description.trim()

    if (description !== '') editChallenge.description = description

    updateChallenge(editChallenge)

  }

  /**
   * Manejar el submit
   * @param values data a enviar
   * @returns early return
   */
  const handleSubmit = (values: any) : void => {
    
    setPreparing(true)

    // Sacamos la modalidad
    const modalityID : ChallengeTypeModality | undefined = config.modalities.find(m => {

      if (values.modality === 'user') 
        return m.id === 1

      if (values.modality === 'room') 
        return m.id === 3

      return m.id === 2

    })

    // Sacamos el tipo de reto
    const typeID : ChallengeTypeModality | undefined = config.types.find(m => {

      if (values.challenge === 'ind') 
        return m.id === 1

      return m.id === 2

    })

    const parsed = parseData(values.rooms, values.users, values.modality, values.teams)

    if (!edit) {

      handleCreateChallenge(modalityID, typeID, values, parsed.users, parsed.uuidRooms)
      setPreparing(false)
      return
    }

    if (edit.challenge_users) {

      const newUsers = getFinalUsers( edit.challenge_users.data, Object.values(parsed.users) )
      handleEditChallenge( 
        modalityID, typeID, values, newUsers.final, newUsers.deleted, [], [], [] 
      )
      setPreparing(false)

      return

    }

    if (edit.challenge_teams) {

      const { final, deleted } = getFinalUsers(
        getPreviousUsers() || [], 
        Object.values(parsed.users)
      )
      
      handleEditChallenge( modalityID, typeID, values, final, deleted, parsed.ceroTeam, [], [] )
      setPreparing(false)

      return

    }

    if (edit.challenge_rooms) {

      const { deleted, finalRooms } = getFinalRooms(edit.challenge_rooms.data, parsed.uuidRooms)
      handleEditChallenge( modalityID, typeID, values, {}, [], [], deleted, finalRooms )

      setPreparing(false)

    }

  }
  
  /**
   * Obtener el tipo de reto seleccionado en caso de ser edición
   */
  const getChallengeType = () : string => {
    
    if (!edit) return 'a'

    if (edit.challenge_type_id === 1)
      return 'ind'

    return 'group'

  }

  /**
   * Obtener la modalidad seleccionado en caso de ser edición
   */
  const getModalityType = () : string => {
    
    if (!edit) return 'b'

    switch (edit.challenge_modality_id) {
      case 1: return 'user'
      case 2: return 'teams'
      case 3: return 'room'
      default: return 'b'
    }

  }

  /**
   * Obtener los usuarios en edición
   * @returns retorna una lista de usuarios seleccionados
   */
  const getPreviousUsers = () : UserChallenge[] | null => {
    
    if (!edit) return null

    if (edit.challenge_users) return edit.challenge_users.data

    if (edit.challenge_teams) {

      let bridge : UserChallenge[] = []

      edit.challenge_teams.data.forEach( team => {
        
        if (team.users)
          bridge = [ ...bridge, ...team.users.map( u => ({ ...u, team: team.name }) ) ]

      })

      return bridge

    }

    return null

  }

  return centerData &&( 
    <>
      <Dialog
        fullWidth
        maxWidth = 'md'
        open = { open }
        onClose={ onClose }
      >
        <DialogTitle>
          { edit 
            ? getTranslationByKey('gamingEditChallenge')
            : getTranslationByKey('gamingCreateChallenge') 
          }
        </DialogTitle>

        <Formik
          enableReinitialize = { !!edit }
          validationSchema = { !edit ? validationSchema : validationSchemaEdit }
          initialValues={{
            name: edit ? edit.name : '',
            description: edit ? edit.description || '' :'',
            challenge: getChallengeType(),
            modality: getModalityType(),
            start: edit 
              ? moment
                .utc(edit.start_at)          
                .tz(centerData.offset_name)
              : moment().tz(centerData.offset_name),
            end: edit 
              ? moment
                .utc(edit.finish_at)
                .tz(centerData.offset_name)
              : moment().add(1, 'M').tz(centerData.offset_name),
            users: '',
            rooms: '',
            teams: 0,
            minimumUsersRegisterForTeams: true,
            compliance: (edit?.challenge_compliance === 'free') || false,
          }}
          onSubmit={values => handleSubmit(values)}
        >

          { props => (

            <form className='p-5' onSubmit = { props.handleSubmit }>
            
              <InputForm 
                initialCount={ edit ? edit.name.length : 0 }
                type= "text"
                placeholder= { getTranslationByKey('gamingGoalModalName') }
                id= "name" 
                title= { getTranslationByKey('gamingGoalModalName') }
                background = "bg-card-body"
                marginTop = "mt-0"
                value={ props.values.name } 
                handleChange={props.handleChange} 
                handleBlur={props.handleBlur} 
                isError={(props.touched.name && !!props.errors.name )} 
                maxCountValue = { 50 }
                errorText={ props.errors.name && 
                  getTranslationByKey(props.errors.name) 
                }   
              />

              <InputTextArea 
                isLimited
                total = { 150 }
                initialCount={ (edit && edit.description) ? edit.description.length : 0 }
                position = 'bottom'
                placeholder={ getTranslationByKey('gamingGoalModalDescription') }
                id='description'
                title={ getTranslationByKey('gamingGoalModalDescription') }
                background = "bg-card-body"
                value={props.values.description}
                handleChange={props.handleChange}
                handleBlur={props.handleBlur} 
                isError={(props.touched.description && !!props.errors.description )} 
                errorText={ props.errors.description && 
                  getTranslationByKey(props.errors.description) 
                }    
              />

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                <InputSelect
                  name = 'challenge'
                  label = { getTranslationByKey('gamingGoalModalType') } 
                  divStyle = {{ margin: 0 }}
                  onChange = { e => resetModality(e, props) }
                  disabled = { !!edit }
                >
                    <MenuItem
                      value = 'a'
                    >
                      { getTranslationByKey('choose') }
                    </MenuItem>

                    <MenuItem
                      value = 'ind'
                    >
                      { getTranslationByKey('gamingGoalModalOptionInd') }
                    </MenuItem>

                    <MenuItem
                      value = 'group'
                    >
                      { getTranslationByKey('gamingGoalModalOptionTeams') }
                    </MenuItem>
                </InputSelect>

                <InputSelect
                  name = 'modality'
                  label = { getTranslationByKey('gamingGoalModalModality') }
                  divStyle = {{ margin: 0 }}
                  onChange = { e => handleChangeModality(e, props) }
                  disabled = { !!edit }
                >
                  <MenuItem
                    value = 'b'
                  >
                    { getTranslationByKey('choose') }
                  </MenuItem>

                  { props.values.challenge !== 'group' && (
                    <MenuItem
                      value = 'user'
                    >
                      { getTranslationByKey('gamingGoalModalOptionUsers') }
                    </MenuItem>
                  )}

                  <MenuItem
                    value = 'room'
                  >
                    { getTranslationByKey('gamingGoalModalOptionRooms') }
                  </MenuItem>

                  { props.values.challenge !== 'ind' && (
                    <MenuItem
                      value = 'teams'
                    >
                      { getTranslationByKey('gamingGoalModalOptionTeamsM') }
                    </MenuItem>
                  )}
                </InputSelect>

              </div>

              <div className='mt-4'>

                <p>{ getTranslationByKey('compliance') }</p>

                <RBox className='items-center gap-4'>
                  <p>{ getTranslationByKey('competitive') }</p>
                  <Switch
                    name = 'compliance'
                    disabled = { !!edit }
                    checked = { props.values.compliance }
                    onChange = { props.handleChange }
                  />
                  <p>{ getTranslationByKey('free') }</p>
                  <HtmlTooltip 
                    title={ getTranslationByKey ('gamingGoalModalChallengeComplianceTPTitle') } 
                    list={[
                      getTranslationByKey('gamingGoalModalChallengeComplianceTPList1'),
                      getTranslationByKey('gamingGoalModalChallengeComplianceTPList2'),
                    ]} 
                  >
                    <HelpOutlineIcon />
                  </HtmlTooltip>
                </RBox>
              </div>

              { (props.values.modality !== 'b' && props.values.challenge !== 'a') && (
                <>
                  <Configurator 
                    isEditable = { edit?.status !== 'completed' }
                    loading={loadingExtra}
                    modality={props.values.modality}
                    challenge={props.values.challenge}
                    rooms={ rooms } 
                    users={ users }
                    handleChange = { props.handleChange }
                    previousUsers={ getPreviousUsers() }
                    previousRooms={
                      (edit && edit.challenge_rooms) 
                      ? edit.challenge_rooms.data
                      : null
                    }
                    selectedTeams={ 
                      (edit && edit.challenge_teams) 
                        ? edit.challenge_teams.data.length
                        : 2
                    }
                  />

                  { 
                    (props.errors.minimumUsersRegisterForTeams 
                      && props.values.modality === 'teams') && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { getTranslationByKey('gamingGoalModalChallengeTeams') }
                    </InputError>
                  )}
    
                  { (props.errors.rooms && props.values.modality === 'room' 
                    && props.values.challenge === 'ind') && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { getTranslationByKey('gamingGoalModalChallengeRoom') }
                    </InputError>
                  )}

                  { (props.errors.rooms && props.values.modality === 'room'
                     && props.values.challenge === 'group') && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { getTranslationByKey('gamingGoalModalChallengeRoom2Rooms') }
                    </InputError>
                  )}
    
                  { (props.errors.users && props.values.modality === 'user') && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { getTranslationByKey('gamingGoalModalChallengeUsers') }
                    </InputError>
                  )}
                </>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                <div className = "flex flex-col mt-4">

                  <label
                    htmlFor="start"
                    className = "uppercase mb-1"
                  >
                    { getTranslationByKey('gamingGoalModalStartingDate') }
                  </label>

                    <InputDatePicker 
                      rounded
                      lang={ lang } 
                      disabled = { !!edit }
                      value={ props.values.start } 
                      onChange = {
                        date => props.handleChange({ target: { name: "start",value: date } }) 
                      }                    
                    />

                    { (props.errors.start )
                        && (
                          <InputError
                            colorLabel = "text-red-500"
                          >
                            { getTranslationByKey('gamingGoalModalChallengeStartDate') }
                          </InputError>
                        ) 
                    }
                  </div>
                <div className = "flex flex-col mt-4">

                  <label
                    htmlFor="end"
                    className = "uppercase mb-1"
                  >
                    { getTranslationByKey('gamingGoalModalEndingDate') }
                  </label>

                    <InputDatePicker
                      rounded 
                      lang = { lang } 
                      value = { props.values.end } 
                      onChange={ 
                        date => props.handleChange({ target: { name: "end",value: date } })
                      }                    
                    />

                    { (props.errors.end )
                        && (
                          <InputError
                            colorLabel = "text-red-500"
                          >
                            { getTranslationByKey('gamingGoalModalChallengeEndDate') }
                          </InputError>
                        ) 
                    }
                  </div>
              </div>

              <small
                className='text-center mt-4 text-gray-400 block'
              >
                { getTranslationByKey('gamingGoalModalChallengeTimeZone') }
              </small>

              <div className = "md:flex md:justify-around mt-10">

                <button
                  type = "button"
                  className = "uppercase bg-secondary-background px-8 py-2 rounded-3xl w-full md:w-max"
                  onClick = { onClose }
                >
                  { getTranslationByKey('cancel') }
                </button>

                { loading || preparing
                  ? (
                    <CircularProgress />
                  ) 
                  : (
                    <button
                      type = "submit"
                      className = "uppercase bg-primary px-8 py-2 rounded-3xl w-full md:w-max"
                    >
                      { !edit 
                        ? getTranslationByKey('gamingListCreateChallenge') 
                        : getTranslationByKey('save')
                      }
                    </button>
                  ) 
                }


              </div>

            </form>

          )}

        </Formik>

      </Dialog>

    </>
   );
}
 
export default CreateChallengeModal;