import React,{ FC } from 'react'

// Dependecies
import { Bar } from 'react-chartjs-2';

// Utils
import Colors from '../../utils/colors'

type TimeIn = 'hours' | 'minutes' | 'seconds' 

interface HorizontalChartProps {
  options ?: any,
  data ?: any,
  time ?: TimeIn
}

// Data Por default si se manda llamar el componente sin data
const defData = {
  labels: ['Máximo', 'Intenso', 'Moderado', 'Ligero', 'Muy Ligero'],
  datasets: [
    {
      label: 'Minutos en la zona',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        Colors.eMax,
        Colors.eIte,
        Colors.eMod,
        Colors.eLgt,
        Colors.eMin,
      ],
      borderWidth: 1,
    },
  ],
};

// Configuración por defecto en caso que no se pase una configuración
const defOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
  },
  maintainAspectRatio: false,
};

/**
 * Gráfica de barras
 * @param {Object} props contiene todas las propiedades necesarias descritas en
 * HorizontalChartProps
 * @param {ChartData} props.data data a mostrar en la gráfica
 * @param {Any} props.options opciones para personalizar la gráfica como se requiera
 * @returns Componente con gráfica de barras
 */
const HorizontalChart: FC<HorizontalChartProps> = ({ 
  data = defData, options = defOptions, time 
}) => { 

  const getLabel = (context: any) => {

    if (time === 'seconds') 
      return `${Math.round(context.parsed)} seconds`
    
    const calc = context.formattedValue.split('.')
    let first : string = 'min'
    let second : string = 'secs'                  

    if (time === 'hours') {
      first = 'hrs'
      second = 'min'
    }

    if (calc.length > 1) {
      let seconds : string = ''
      const minutes = Number(`0.${calc[1]}`) * 60
      const rawSeconds = Number(minutes.toFixed(2).split('.')[1])

      if (rawSeconds > 0) {
        seconds = `${(Number(`0.${rawSeconds}`) * 60).toFixed(0)} s`
      }

      if (time === 'hours')
        return `${calc[0]} ${first} ${minutes.toFixed(0)} ${second} ${seconds}`
      
      return `${calc[0]} ${first} ${minutes.toFixed(0)} ${second}`
    }

    return  `${calc[0]} ${first}`

  }

  return ( 
    <Bar 
      data={data} 
      options = {
        time 
        ? { 
          ...options,
          plugins: {
            ...options.plugins,
            tooltip: {
              callbacks: {
                title: (context: any) => context[0].label,
                label: getLabel,
              }
            }
          } 
        } 
        : options
      }
    />
  )
}
 
export default HorizontalChart;