import React, { FC } from 'react'

// Dependencies
import moment, { Moment } from 'moment';
import esLocale from 'date-fns/locale/es'
import enLocale from 'date-fns/locale/en-US';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Style
import { makeStyles } from '@mui/styles';

// types
import { Translation } from '../../../types';

// Hooks
import useTranslation from '../../../hooks/useTranslation'

export const datePickerStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    padding: '0rem 1rem',
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd button": {
      color: 'white'
    },
    '& #date-picker-inline': {
      color: 'white',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        color: 'white'
      }
    },
   '& fieldset':{
      borderColor: 'rgba(255, 255, 255, 0)'
   }
  },
});

export const dateRoundedPickerStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    borderRadius: '0.75rem',
    padding: '0rem 1rem',
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd button": {
      color: 'white'
    },
    '& #date-picker-inline': {
      color: 'white',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        color: 'white'
      }
    },
   '& fieldset':{
      borderColor: 'rgba(255, 255, 255, 0)'
   }
  },
});

interface InputDatePickerProps {
  lang: Translation,
  value: Moment | null,
  rounded ?: boolean,
  disabled ?: boolean,
  onChange: (date: Moment) => void
}

const InputDatePicker: FC<InputDatePickerProps> = ({
  lang, value, rounded = false, disabled = false, onChange 
}) => {

  // Estilos del picker
  const datePickerStyle = datePickerStyles()
  const dateRoundedPickerStyle = dateRoundedPickerStyles()

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <FormControl
      variant = 'outlined'
      classes = { rounded ? dateRoundedPickerStyle : datePickerStyle }
    >

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale = { lang === Translation.es ? esLocale : enLocale }
      >
        <DatePicker
          disabled = { disabled }
          inputFormat={ getTranslationByKey('datePickerInputFormat') }
          mask='__/__/____'
          InputProps = {{ disableUnderline: true }}
          value = { value }
          onChange = { date => onChange(date || moment()) }
          renderInput={({inputRef, inputProps, InputProps}) => (
            <div className = "flex items-center justify-between p-2">
              <input 
                ref={inputRef} 
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                className = 'appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-card-body focus:bg-card-body' 
                readOnly
              />
              {InputProps?.endAdornment}
            </div>
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
 
export default InputDatePicker;