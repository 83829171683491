import React, { FC } from 'react';

interface SensorIconProps {
  color ?: string
}
 
// eslint-disable-next-line import/prefer-default-export
export const SensorIcon: FC<SensorIconProps> = ({ color }) => ( 
  <span className={`material-icons ${color || ''}`}>
    sensors
  </span>
)

export const AdminIcon: FC<SensorIconProps> = ({ color }) => ( 
  <span className={`material-icons ${color || ''}`}>
    admin_panel_settings
  </span>
)

export const BandSensors: FC<SensorIconProps> = ({ color }) => ( 
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3197 19.5647H8.30467C8.25012 19.5647 8.19762 19.5454 8.15578 19.5106L6.69767 18.2862C6.46348 18.0894 6.60252 17.7075 6.90849 17.7075H13.471C13.7622 17.7075 13.9086 18.059 13.7036 18.2662L12.4837 19.4962C12.4407 19.5401 12.3816 19.5647 12.3197 19.5647Z" fill={color}/>
<path d="M8.28684 0.95627L12.3097 0.929199C12.3642 0.929199 12.4167 0.948477 12.4585 0.98334L13.6816 2.23473C13.9158 2.4316 13.7768 2.81346 13.4708 2.81346H6.90831C6.6171 2.81346 6.47067 2.46195 6.67575 2.25482L8.12237 1.02477C8.16626 0.980879 8.22532 0.95627 8.28684 0.95627Z" fill={color}/>
<path d="M13.0306 3.63623H7.30191C5.72814 3.63623 4.45215 4.91223 4.45215 6.486V14.0349C4.45215 15.6087 5.72814 16.8847 7.30191 16.8847H13.0306C14.6043 16.8847 15.8803 15.6087 15.8803 14.0349V6.486C15.8807 4.91223 14.6047 3.63623 13.0306 3.63623ZM14.412 13.1203L13.1434 13.0854C12.9555 13.0805 12.789 12.957 12.7291 12.779L11.3481 8.66762L9.53809 14.2827C9.50609 14.3823 9.40109 14.4598 9.29609 14.4615C9.29486 14.4615 9.29363 14.4615 9.2924 14.4615C9.1833 14.4615 9.08609 14.393 9.04959 14.29L8.12387 11.6187L7.75186 12.5924C7.69566 12.7396 7.5517 12.8389 7.39379 12.8389H5.93281C5.79787 12.8389 5.68836 12.7294 5.68836 12.5945C5.68836 12.4595 5.79787 12.35 5.93281 12.35H7.32119L7.89459 10.8492C7.9315 10.7529 8.03404 10.6872 8.13904 10.6844C8.24609 10.6864 8.34166 10.7553 8.37693 10.8566L9.28461 13.4759L11.0704 7.9359C11.1086 7.81736 11.2181 7.73738 11.3424 7.73656C11.3432 7.73656 11.3436 7.73656 11.3444 7.73656C11.4679 7.73656 11.5774 7.8149 11.6168 7.93262L13.184 12.5981L14.4255 12.6322C14.5604 12.6359 14.6671 12.7483 14.663 12.8832C14.6597 13.0173 14.5453 13.1268 14.412 13.1203Z" fill={color}/>
</svg>

)

export const Gaming: FC<SensorIconProps> = ({ color }) => ( 
  <svg className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium icons-set' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 1.75H3.5V3.5L8.58375 7.315C7.0409 7.82375 5.76317 8.92428 5.03139 10.3747C4.29962 11.8251 4.17368 13.5068 4.68125 15.05C4.93237 15.8145 5.33174 16.522 5.85653 17.132C6.38132 17.7419 7.02123 18.2425 7.73964 18.6049C8.45806 18.9674 9.24089 19.1847 10.0433 19.2443C10.8458 19.3039 11.6521 19.2048 12.4162 18.9525C13.6417 18.5497 14.7086 17.7704 15.4651 16.7255C16.2215 15.6807 16.6287 14.4237 16.6287 13.1338C16.6287 11.8438 16.2215 10.5868 15.4651 9.542C14.7086 8.49715 13.6417 7.71778 12.4162 7.315L17.5 3.5V1.75ZM13.0725 17.0625L10.5 15.5575L7.9275 17.0625L8.61 14.1488L6.34375 12.1888L9.3275 11.935L10.5 9.1875L11.6725 11.935L14.6562 12.1888L12.39 14.1488L13.0725 17.0625V17.0625Z" fill={color}/>
</svg>

)

export const Points: FC<SensorIconProps> = ({ color }) => ( 
  <svg width="40" height="41" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_694_2821)">
<path fillRule="evenodd" clipRule="evenodd" d="M7.69833 4.09691C5.43318 4.09691 3.59691 5.93318 3.59691 8.19833C3.59691 10.4635 5.43318 12.2997 7.69833 12.2997C9.96348 12.2997 11.7997 10.4635 11.7997 8.19833C11.7997 5.93318 9.96348 4.09691 7.69833 4.09691ZM2 8.19833C2 5.05123 4.55123 2.5 7.69833 2.5C10.8454 2.5 13.3967 5.05123 13.3967 8.19833C13.3967 11.3454 10.8454 13.8967 7.69833 13.8967C4.55123 13.8967 2 11.3454 2 8.19833Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.0563 9.15752C15.2764 8.77543 15.7647 8.64417 16.1467 8.86434C16.9044 9.30092 17.5515 9.90603 18.0377 10.6328C18.524 11.3596 18.8365 12.1885 18.951 13.0555C19.0655 13.9224 18.979 14.8041 18.6981 15.6322C18.4172 16.4603 17.9494 17.2126 17.3311 17.831C16.7128 18.4493 15.9604 18.917 15.1323 19.198C14.3042 19.4789 13.4225 19.5654 12.5556 19.4509C11.6887 19.3364 10.8597 19.0239 10.1329 18.5376C9.40616 18.0513 8.80105 17.4043 8.36446 16.6466C8.1443 16.2645 8.27556 15.7763 8.65764 15.5562C9.03972 15.336 9.52793 15.4673 9.7481 15.8493C10.0623 16.3947 10.4979 16.8604 11.021 17.2104C11.5441 17.5604 12.1407 17.7853 12.7647 17.8678C13.3887 17.9502 14.0233 17.8879 14.6193 17.6857C15.2153 17.4835 15.7569 17.1468 16.2019 16.7018C16.647 16.2567 16.9836 15.7152 17.1858 15.1192C17.388 14.5231 17.4503 13.8886 17.3679 13.2646C17.2855 12.6406 17.0605 12.044 16.7105 11.5209C16.3605 10.9977 15.8948 10.5622 15.3495 10.248C14.9674 10.0278 14.8361 9.5396 15.0563 9.15752Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.19995 6.79821C6.19995 6.35724 6.55743 5.99976 6.9984 5.99976H7.69839C8.13936 5.99976 8.49684 6.35724 8.49684 6.79821V9.59814C8.49684 10.0391 8.13936 10.3966 7.69839 10.3966C7.25741 10.3966 6.89993 10.0391 6.89993 9.59814V7.59065C6.50542 7.54213 6.19995 7.20584 6.19995 6.79821Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.6435 12.0436C13.9553 11.7317 14.4609 11.7317 14.7727 12.0436L15.2627 12.5335C15.4124 12.6833 15.4966 12.8864 15.4966 13.0981C15.4966 13.3099 15.4124 13.513 15.2627 13.6627L13.3027 15.6227C12.9909 15.9345 12.4854 15.9345 12.1736 15.6227C11.8617 15.3109 11.8617 14.8053 12.1736 14.4935L13.5738 13.0932C13.3338 12.7802 13.357 12.3301 13.6435 12.0436Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_694_2821">
<rect width="20.9999" height="21" fill={color} transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>


)