import React, { FC, ChangeEvent, useState, useEffect, useContext, ReactElement } from 'react'

// Dependencies
import _ from 'lodash';
import SelectInput from 'react-select'
import { Box, CircularProgress, ListItem, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Checkbox, { CheckboxProps }  from '@mui/material/Checkbox';
import { from, map, pluck, asyncScheduler, scheduled } from 'rxjs';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

// Context
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import UIContext from '../../context/ui/UIContext'

// Componentes
import BasicRow from './utils/BasicRow';
import RadiosRow from './utils/RadiosRow';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Styles
import CreateChallengeStyles from './styles/CreateChallengeStyles'
import { Athlete, Room, UserChallenge, Translation } from '../../types';

// Utils
import Colors from '../../utils/colors';
import { fakeEngAthlete, fakeSpaAthlete, fakeEngRoom, fakeSpaRoom } from '../../utils'

type TeamOption = {
  value: string,
  label: string
}

const english: TeamOption[] = [
  { value: '2', label: '2 Teams' },
  { value: '3', label: '3 Teams' },
  { value: '4', label: '4 Teams' }
]

const spanish : TeamOption[] = [
  { value: '2', label: '2 Equipos' },
  { value: '3', label: '3 Equipos' },
  { value: '4', label: '4 Equipos' }
]

interface ConfiguratorProps {
  challenge: string,
  isEditable: boolean,
  loading: boolean,
  modality: string,
  previousRooms: Room[] | null,
  previousUsers: UserChallenge[] | null,
  rooms: Room[],
  selectedTeams ?: number | null
  users: Athlete[],
  handleChange: (event: { target: { name: string, value: any }}) => void
}

const AllUsersCheckBox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/**
 * Componente que contiene los inputs para crear un reto
 * @param props contiene todas las propiedades necesarias descritas en
 * ConfiguratorProps
 * @param props.modality modalidad que esta seleccionada
 * @param props.challenge tipo de reto seleccionado
 * @param props.loading saber si esta descargando usuarios o salas
 * @param props.selectedTeams equipos ya formados cuando es editar
 * @param props.previousUsers usuarios ya seleccionados cuando es editar
 * @param props.previousRooms salas ya seleccionadas cuando es editar
 * @param props.handleChange función para actualizar el formik
 * @returns componente que contiene los inputs para crear un reto
 */
const Configurator: FC<ConfiguratorProps> = ({ 
  modality, challenge, loading, rooms, users, previousUsers, selectedTeams, previousRooms, isEditable,
  handleChange 
}) => {

  // Saber cuantos equipos
  const [teams, setTeams] = useState<TeamOption>({ value: '2', label: '2 Equipos' })

  // filtrar los usuarios para poder sacarlos del drop
  const [filteredUsers, setFilteredUsers] = useState([...users])

  // filtrar los usuarios para poder sacarlos del drop
  const [filteredRooms, setFilteredRooms] = useState([...rooms])

  // saber que usuarios están seleccionados
  const [selectedUsers, setSelectedUsers] = useState<Athlete[]>([])

  // Saber que rooms están seleccionados
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([])

  // Saber que se esta cargando el random
  const [isRandomizing, setIsRandomizing] = useState(false)

  // hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Saber si esta marcado el check de todas las salas/usuarios
  const [checked, setChecked] = useState(false)

  // Deshabilitar el check mientras agrega todo
  const [disabled, setDisable] = useState(false)

  // Sacar el idioma
  const { lang } = useContext( UIContext )

  useEffect(() => {
    
    if (selectedTeams) {

      if (lang === Translation.es) {

        const found = spanish.find( op => op.value === `${selectedTeams}` )

        if (found) {
          setTeams(found)
        }

      }

      const found = english.find( op => op.value === `${selectedTeams}` )

      if (found) {
        setTeams(found)
      }

    }

  }, [])

  useEffect(() => {
    
    if (previousUsers && users.length && selectedUsers.length === 0) {
      
      const parsedUsers = parseUsers()

      if (parsedUsers.length)
        setSelectedUsers(parsedUsers)
      
      if (previousUsers.length)
        handleChange({ target: { name: 'users', value: JSON.stringify(
          previousUsers
        )}})

    }

  }, [users, previousUsers])

  useEffect(() => {
    
    if (previousRooms && rooms.length > 0) {

      setSelectedRooms([...previousRooms])
      setFilteredRooms(prepareRooms())
      handleChange({ target: { name: 'rooms', value: JSON.stringify(
        previousRooms
      )}})

    }

  }, [rooms, previousRooms])

  useEffect(() => {
    
    if (!previousUsers && !previousRooms) {
      setFilteredUsers([...users])
      setSelectedUsers([])
      setSelectedRooms([])
      setTeams({ value: '2', label: lang === Translation.en ? '2 Teams' : '2 Equipos' })
    }

  }, [modality, challenge, users])

  useEffect(() => {
    
    handleChange({ target: { name: 'teams', value: Number(teams.value)}})

  }, [teams])

  useEffect(() => {
    
    if (modality === 'teams') {
      if (selectedUsers.length >= Number(teams.value)) 
        handleChange({ target: { name: 'minimumUsersRegisterForTeams', value: true }})
      else 
        handleChange({ target: { name: 'minimumUsersRegisterForTeams', value: false }})
    }

  }, [teams, selectedUsers])

  /**
   * Pasar el usuario que me devuelve gamification al tipo de API v2
   */
  const parseUsers = () : Athlete[] => {
    
    if (!previousUsers) return []

    const athletes : Athlete[] = []
    const finalUsers : Athlete[] = [...users]

    previousUsers.forEach(user => {
      
      const index = finalUsers.findIndex( u => u.email === user.email )

      if (index >= 0) {
        const bridge = finalUsers[index]
        athletes.push(modality !== 'teams' ? bridge : { ...bridge, team: user.team })
        finalUsers.splice(index, 1)
      }

    })

    setFilteredUsers(finalUsers)
    
    return athletes
  }

  const prepareRooms = () : Room[] => {
    
    if (!previousRooms) return []

    const finalRooms : Room[] = [...rooms]

    previousRooms.forEach(prev => {

      const index = finalRooms.findIndex(i => {
        const prevUUID : string = prev.room_uuid || prev.uuid
        const currentUUID : string = i.room_uuid || i.uuid
        return prevUUID === currentUUID
      })

      if (index >= 0) finalRooms.splice(index, 1)

    })

    return finalRooms

  }

  /**
   * Agregar un usuario a la tabla
   * @param user a agregar
   */
  const onChangeUser = (user: Athlete | null) : void => {
    
    if(user) {

      // Sacamos el usuario seleccionado para sacarlo del drop
      const index = filteredUsers.findIndex( u => u.email === user.email )
      if (index >= 0)
        setFilteredUsers( prev => {
          prev.splice(index, 1) 
          return prev
        })

      const exists = selectedUsers.find( selected => selected.email === user.email ) 

      if (!exists && selectedUsers.length >= 1) {
        handleChange({ target: { name: 'users', value: JSON.stringify([
          ...selectedUsers, 
          user
        ])}})
      }

      setSelectedUsers([...selectedUsers, user])

    }
    
  }

  /**
   * Revisar el evento de change del check
   * @param event cuando se marca o desmarca el check de todos los usuarios
   */
  const checkChange = (event: ChangeEvent<HTMLInputElement>): void => {

    setChecked(event.target.checked)
    setDisable(true)

    if (event.target.checked) {
      if (modality === 'user' || modality === 'teams') {

        setSelectedUsers([...users])
  
        handleChange({ target: { name: 'users', value: JSON.stringify([
          ...users, 
        ])}})

        setFilteredUsers([])

      }
      else{

        setSelectedRooms([...rooms ])
        setFilteredRooms([])
        handleChange({ target: { name: 'rooms', value: JSON.stringify([
          ...rooms, 
        ])}})

      }
    }
    else if (modality === 'user' || modality === 'teams') {

        setSelectedUsers([])
        setFilteredUsers([...users])
        handleChange({ target: { name: 'users', value: JSON.stringify([])}})

      }
      else {

        setSelectedRooms([])
        setFilteredRooms([...rooms])
        handleChange({ target: { name: 'rooms', value: JSON.stringify([])}})

      }

    setDisable(false)

  }

  /**
   * Remover un usuario
   * @param uuid del usuario a sacar del reto
   */
  const removeUser = (uuid: string) : void => {
    
    // Sacamos el usuario seleccionado
    const removedUser = selectedUsers.find( i => i.user_uuid === uuid )
    if (removedUser) setFilteredUsers([removedUser, ...filteredUsers])

    // Filtramos los usuarios
    const newUsers = selectedUsers.filter( i => i.user_uuid !== uuid )

    // Actualizamos en formik los usuarios
    if (newUsers.length > 1 || modality === 'teams')
      handleChange({ target: { name: 'users', value: JSON.stringify([
        ...newUsers
      ])}})
    else handleChange({ target: { name: 'users', value: 'b'}})

    setSelectedUsers(newUsers)

  }

  /**
   * Remover un usuario
   * @param uuid del usuario a sacar del reto
   */
  const removeRoom = (uuid: string) : void => {
    
    // Sacamos el room eliminado y lo agregamos al drop
    const removedRoom = selectedRooms.find( r => r.uuid === uuid || r.room_uuid === uuid)
    if (removedRoom) setFilteredRooms([removedRoom, ...filteredRooms])

    // Filtramos los rooms
    const newRooms = selectedRooms.filter(i => {

      const uuidI : string = i.room_uuid || i.uuid
      return uuidI !== uuid

    })

    if (newRooms.length > 1)
      // Actualizamos en formik los usuarios
      handleChange({ target: { name: 'rooms', value: JSON.stringify([
        ...newRooms
      ])}})
    else 
      handleChange({ target: { name: 'rooms', value: 'b' }})

    setSelectedRooms(newRooms)

  }

  /**
   * Agregar un room al challenge
   * @param room a agregar a la tabla
   */
  const onChangeRoom = (room: Room | null) : void => {
   
    if (room) {

      const index = filteredRooms.findIndex( r => r.uuid === room.uuid )
      if (index >= 0)
        setFilteredRooms( prev => {
          prev.splice(index, 1)
          return prev
        })
      
      if (challenge === 'ind') {

        handleChange({ target: { name: 'rooms', value: JSON.stringify([
          room
        ])}})
        
        // Regresamos el room antes seleccionado
        setFilteredRooms(prev => [...selectedRooms, ...prev])

        setSelectedRooms([room])

      }
      else if (!selectedRooms.find( selected => selected.uuid === room.uuid )) {
          if (selectedRooms.length >= 1)
            handleChange({ target: { name: 'rooms', value: JSON.stringify([
              ...selectedRooms, 
              room
            ])}})
    
          setSelectedRooms(previous => [...previous, room])
        }

    }

  }

  /**
   * Cambiar o agregar a un usuario a un equipo
   * @param team al que se va agregar al usuario
   * @param uuid del usuario
   */
  const changeTeam = (team: string, uuid: string) : void => {
    
    // actualizamos al usuario los usuarios
    const newUsers = selectedUsers.map( i => i.user_uuid === uuid ? { ...i, team } : i)

    // Actualizamos en formik los usuarios
    handleChange({ target: { name: 'users', value: JSON.stringify([
      ...newUsers
    ])}})

    setSelectedUsers(newUsers)

  }

  /**
   * Agregar a los usuarios en los equipos de manera aleatoria
   */
  const shuffleUsersAndAddATeam = () : void => {

    // Mostrar el spinner
    setIsRandomizing(true)

    // Saber donde se va a plantar al usuario
    let seed = Math.floor(Math.random() * (Number(teams.value) - 1 + 1) + 1)

    // Revolvemos los usuarios
    let included : Athlete[] = JSON.parse(JSON.stringify(selectedUsers)) 
    included = _.shuffle(included)

    // Tener una referencia de los usaurios
    const temp : Athlete[] = JSON.parse(JSON.stringify(selectedUsers)) 

    // Hacemos el agregado de los usuarios en segundo plano
    scheduled(from(included), asyncScheduler)
      .pipe(
        pluck('email'),
        map(email => {

          if (seed < Number(teams.value)) seed = seed + 1
          else seed = 1

          return {
            email, 
            seed
          }

        }),
        map(resp => {
          const index = temp.findIndex( su => su.email === resp.email )
          if (index >= 0) temp[index].team = `${resp.seed}`
        })
      )
      .subscribe({
        complete: () => {
          setSelectedUsers([...temp])
          setTimeout(() => { setIsRandomizing(false) }, 300)
        }
      })

  }

  /**
   * Buscar solo en el label
   * @param candidate lista de posibles opciones a mostrar en el drop
   * @param input lo escrito en el input text
   * @returns lista de opciones
   */
  const filterUserOptions = (candidate: FilterOptionOption<Athlete>, input: string) : boolean => {
   
    if (input) {
      
      if (candidate.data.email.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
        return true
      
      if (candidate.label.toLocaleLowerCase().includes(input.toLocaleLowerCase())) 
        return true

      return false
    }

    return true
    
  }

  /**
   * Buscar solo en el label
   * @param candidate lista de posibles opciones a mostrar en el drop
   * @param input lo escrito en el input text
   * @returns lista de opciones
   */
  const filterRoomOptions = (candidate: FilterOptionOption<Room>, input: string) : boolean => {
   
    if (input) {
      if (candidate.label.toLocaleLowerCase().includes(input.toLocaleLowerCase())) 
        return true

      return false
    }

    return true
    
  }

  const renderRows = (props: ListChildComponentProps) : ReactElement => {
    
    const { index, style } = props

    if (modality === 'room') {

      const room = selectedRooms[index]

      return (
        <ListItem 
          style = { style } 
          key = { index } 
          disablePadding
        >
          <div className="grid grid-cols-2 p-2 items-center w-full">
            <BasicRow
              isDeletable = { isEditable }
              key = { room.uuid || room.room_uuid }
              firstText = { room.name }
              secondText = { room.description || '-' }
              deleteRow={ () => removeRoom( room.room_uuid || room.uuid)}
            />
          </div>
        </ListItem>
      )
    }

    const user = selectedUsers[index]

    if (modality === 'teams') {

      return (
        <ListItem 
          style = { style } 
          key = { index } 
          disablePadding
        >
          <div className='grid grid-cols-header gap-3.5 p-2 items-center w-full'>
            <RadiosRow 
              isEditable = { isEditable }
              teams={ Number(teams.value) }
              key={ user.user_uuid || user.email }
              firstText = { `${user.name || '-'} ${user.last_name_1 && user.last_name_1 !== 'null' ? user.last_name_1 : ''}` }
              secondText = { user.email }
              deleteRow={ () => removeUser(user.user_uuid || '')}
              changeTeam={ team => changeTeam(team, user.user_uuid || '') }
              previousTeam={ user.team || null }
            />
          </div>
        </ListItem>
      )
    }

    return (
      <ListItem 
        style = { style } 
        key = { index } 
        disablePadding
      >
        <div className="grid grid-cols-2 p-2 items-center w-full">
          <BasicRow 
            isDeletable = { isEditable }
            key = { user.email }
            firstText = { `${user.name || '-'} ${user.last_name_1 && user.last_name_1 !== 'null' ? user.last_name_1 : ''}` }
            secondText = { user.email }
            deleteRow={ () => removeUser(user.user_uuid || '')}
          />
        </div>
      </ListItem>
    )
  }

  return ( 
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div>
          <label 
            className = 'uppercase'
          >
            { modality === 'room' 
              ? getTranslationByKey('gamingGoalModalChallengeConfigRoomsLabel')
              : getTranslationByKey('gamingGoalModalChallengeConfigUsersLabel') 
            }
          </label>

          { modality === 'room' 
            ? (
              <SelectInput
                key= 'room'
                isDisabled = { !isEditable }
                filterOption={ filterRoomOptions }
                value = { lang === Translation.en ? fakeEngRoom : fakeSpaRoom }
                isLoading = { loading }
                options = { filteredRooms }
                styles = { CreateChallengeStyles }
                onChange={ option => onChangeRoom(option) }
                placeholder = { getTranslationByKey('choose') }
                getOptionValue = { options => options.uuid }
                getOptionLabel = { options => options.name }
                noOptionsMessage = { 
                  () => getTranslationByKey('gamingGoalModalChallengeConfigNoOptions') 
                }
              />
            ) 
            : (
              <SelectInput
                key = 'user'
                filterOption={ filterUserOptions }
                isDisabled = { isRandomizing || !isEditable }
                value = { lang === Translation.en ? fakeEngAthlete : fakeSpaAthlete }
                isLoading = { loading }
                options = { filteredUsers }
                styles = { CreateChallengeStyles }
                maxMenuHeight = { 150 }
                onChange={ option => onChangeUser(option) }
                placeholder = { getTranslationByKey('choose') }
                getOptionValue = { options => options.user_uuid || ''}
                getOptionLabel = { 
                  options => `${options.name || '-'} ${options.last_name_1 && options.last_name_1 !== 'null' ? options.last_name_1 : ''}`
                }
                noOptionsMessage = { 
                  () => getTranslationByKey('gamingGoalModalChallengeConfigNoOptions') 
                }
              />
            ) 
          }
        </div>
        <div 
          className = {`flex items-center md:mt-4 
          ${(modality === 'room' && challenge === 'ind') && 'hidden'}
          ${ isEditable ? '' : 'hidden'}
        `}
        >
          <AllUsersCheckBox
            name = "allUsers"
            id = "allUsers"
            checked = { checked }
            onChange = { checkChange }
            inputProps={{ 'aria-label': 'primary checkbox' }}
            data-cy = "btn-allUsers"
            disabled = { disabled }
          />
          <label htmlFor="allUsers">
            { modality === 'user' || modality === 'teams' 
              ? getTranslationByKey('gamingGoalModalChallengeConfigCheckAllUsers') 
              : getTranslationByKey('gamingGoalModalChallengeConfigCheckAllRooms')  
            }
          </label>
        </div>
      </div>

      { (challenge === 'group' && modality === 'teams') && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <label 
              className = 'uppercase'
            >
              { getTranslationByKey('gamingGoalModalOptionTeams') }
            </label>
            <SelectInput
              isDisabled = { isRandomizing || !isEditable }
              value = { teams }
              options = { lang === Translation.es ? spanish : english }
              styles = { CreateChallengeStyles }
              placeholder = { getTranslationByKey('choose') }
              onChange = { option => setTeams(option || { value: '2', label: '2 Equipos' }) }
              noOptionsMessage = { 
                () => getTranslationByKey('gamingGoalModalChallengeConfigNoOptions') 
              }
            />
          </div>
          <div className = {`items-center md:mt-4 ${ previousUsers ? 'hidden': 'flex'}`}>
            
            { isRandomizing 
              ? (
                <CircularProgress />
              ) 
              : (
                <button
                  type = 'button'
                  className={`uppercase bg-primary px-8 py-2 rounded-3xl w-full md:w-max
                    ${selectedUsers.length < 2 ? 'opacity-30' : 'opacity-100'}
                  `}
                  disabled = { selectedUsers.length < 2 }
                  onClick={ shuffleUsersAndAddATeam }
                >
                  { getTranslationByKey('gamingGoalModalChallengeConfigRandomTeams') }
                </button>
              )
            }


            <div className="ml-4">
              <Tooltip
                title = { getTranslationByKey('gamingGoalModalChallengeConfigRandomTeamsDesc') }
              >
                <InfoOutlinedIcon
                  fontSize = 'small'
                />
              </Tooltip>
            </div>

          </div>
        </div>
      )}

      <div className='mt-4'>

        <div className={`grid ${ modality === 'teams' ? 'grid-cols-header' : 'grid-cols-2'} bg-card-header rounded-t-md p-2`}>

          { modality === 'room' && (
            <>
              <p>
                { getTranslationByKey('room') }
              </p>
              <p>
                { getTranslationByKey('description') }
              </p>
            </>
          )}

          { modality === 'teams' && (
            <>
              <p>
              { getTranslationByKey('user') }
              </p>
              <p>
                { getTranslationByKey('loginEmail') }
              </p>
              <p className='ml-6'>
                { getTranslationByKey('team') }
              </p>
            </>
          )}

          { modality === 'user' && (
            <>
              <p>
                { getTranslationByKey('name') }
              </p>
              <p>
                { getTranslationByKey('loginEmail') }
              </p>
            </>
          )}
        </div>

        <Box
            sx={{ 
              width: '100%', 
              height: 100, 
              bgcolor: Colors.secondaryBackgroundColor,
            }}
          >
          { isRandomizing 
            ? (
              <div className='flex w-full h-full items-center justify-center'>
                <CircularProgress />
              </div>
            ) 
            : (

              <FixedSizeList
                height={100}
                width = '100%'
                itemSize = { 46 }
                itemCount = { modality === 'room' ? selectedRooms.length : selectedUsers.length }
                overscanCount = { 5 }
              >
                { renderRows }
              </FixedSizeList>

            )
          }
        </Box>

      </div>

    </>
  )
}

export default Configurator;