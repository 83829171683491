import { createMachine, assign, send, spawn, DoneInvokeEvent, sendParent } from "xstate";
import * as Sentry from "@sentry/react";

// HTTP client
import moment from 'moment'
import axiosClient from '../../config/axios'

// Types
import { 
  Role, 
  AuthData, 
  SearchIn,
  Administrator, 
  Administrators, 
  LocalStorageKey,
} from '../../types'

/**
 * page: lista que esta viendo el usuario es un actor
 * pageData: objeto con las páginas
 * pages: el total de páginas actuales si es que la búsqueda tiene paginación
 * totalPages: paginas totales originales sin paginación
 * openModal: saber si esta abierto el modal o no 
 * addAdmin: la data del admin a crear
 * statusError: en caso de error se guarda el error que dio la api
 * admin: la data del admin a actualizar esta este para compartir la fn de actualizar con createPageMachine
 * pageAlreadyExists: saber si vamos a visitar una página que ya se creo
 * search: criterio de la busqueda columna y que
 * searched: resultado de la busqueda
 * roles: los roles que tiene el centro
 */
type AdminMachineContext = {
  page: any | null,
  pageData: any,
  pages: number,
  totalPages: number,
  openModal: boolean,
  addAdmin: Administrator | null,
  statusError: number,
  admin: Administrator | null,
  pageAlreadyExists: boolean,
  search: SearchIn | null,
  searched: Administrators | null,
  currentPage: number,
  roles: Role[]
}

/**
 * PAGEING: hacer una páginación
 * READY: cuando la lista de admins esta lista
 * ADDADMIN: agregar un admin
 * UPDATE: mostrar el modal para actualizar
 * CHANGE: indicar que se puede actualizar un usuario
 * SEARCH: buscar un admin
 * TOGGLE: Abrir/cerrar el modal de crear admin
 */
type AdminMachineEvents = 
 | { type: 'PAGEING', page: number }
 | { type: 'READY', pages: number }
 | { type: 'ADDADMIN', data: Administrator }
 | { type: 'UPDATE', data: Administrator }
 | { type: 'CHANGE', data: Administrator }
 | { type: 'SEARCH', data: SearchIn }
 | { type: 'TOGGLE' }

/**
 * Retorna una lista de administradores
 * @returns una lista de administradores
 */
const fetchAdmins = async (context: CreatePageMachineContext) : Promise<Administrators> => {
  
  // Sacamos la pagina
  const { page } = context

  // Definimos de que página vamos a descargar
  const pageURl : string = page ? `/api/v2/admin/user?page=${page}` : '/api/v2/admin/user'

  const response = await axiosClient.get(pageURl)
  return response.data

}

/**
 * Crear admins
 * @param context de la maquina padre
 * @returns un nuevo admin
 */
const createAdmin = async (context: AdminMachineContext) : Promise<any> => {
  
  // Sacamos el nuevo admin
  const { addAdmin } = context

  // Verificamos que exista
  if ( !addAdmin ) throw new Error()

  // Quitamos el landline si viene vació
  if (addAdmin.landline === '') delete addAdmin.landline
  
  // Quitamos el segundo apellido si viene vació
  if (addAdmin.last_name_2 === '') delete addAdmin.last_name_2

  const response = await axiosClient.post(`/api/v2/admin/user`, addAdmin)

  return {
    ...addAdmin,
    uuid: response.data.uuid,
    lastName: `${addAdmin.last_name_1} ${addAdmin.last_name_2 || ''}`,
    status: 1
  }

}

/**
 * Actualizar administrador
 * @param context de la maquina pagina
 * @returns un admin actualizado
 */
const updateAdmin = async (context: CreatePageMachineContext | AdminMachineContext) : Promise<Administrator> => {
  
  // Sacamos el uuid y el status
  const { admin } = context

  // Si no existe el uuid
  if (!admin) throw new Error()
  
  // Quitamos el segundo apellido si viene vació
  if (admin.last_name_2 === '')
    delete admin.last_name_2

  // Quitamos el land line si viene vació
  if (admin.landline === '')
    delete admin.landline

  await axiosClient.put(`/api/v2/admin/user/${admin.uuid}`, admin)

  return { 
    ...admin,
    lastName: `${admin.last_name_1} ${admin.last_name_2 || ''}`
  }

}

/**
 * Eliminar un admin
 * @param context de la maquina que controla la lista
 * @returns Lista de administradores
 */
const deleteAdmin = async (context: CreatePageMachineContext) : Promise<Administrator> => {
  
  // Sacamos el admin
  const { admin } = context

  // Si no existe el uuid
  if (!admin) throw new Error()

  await axiosClient.delete(`/api/v2/admin/user/${admin.uuid}`)

  return admin

}

/**
 * Buscar por un administrador
 * @param context la maquina principal
 * @returns lista de administradores
 */
const searchForAdmins = async (context: AdminMachineContext) : Promise<Administrators> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search } = context

  if ( !search || search.search === '-1') throw new Error()

  // Hacemos la petición
  const url : string = `/api/v2/admin/user?search_colum=${search.search_column}&search=${search.search}`

  const response = await axiosClient.get(url) 

  return response.data
}

const searchCompundRequest = async (context: AdminMachineContext) : Promise<Administrators> => {
  
  // Sacamos el objeto a buscar ademas de la página
  const { currentPage, search } = context

  if (!search) throw new Error()

  // Formateamos la url
  let url = `/api/v2/admin/user`
  url = `${url}?search_colum=${search.search_column}&search=${search.search}`
  url = `${url}&page=${currentPage}`

  const response = await axiosClient.get(url)

  return response.data

}

/**
 * Retornar la data del centro
 * @returns la data del centro
 */
 const fetchCenterData = async () : Promise<Role[]> => {
  
  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const response = await axiosClient.get(`/api/v2/centers/${authData.uuidBranch}`)
  return response.data.roles

}

const AdminMachine = createMachine<AdminMachineContext, AdminMachineEvents>(
  {
    id: 'AdminMachine',
    initial: 'idle',
    context: {
      page: null,
      pageData: {},
      pages: 0,
      openModal: false,
      addAdmin: null,
      statusError: 0,
      admin: null,
      pageAlreadyExists: false,
      totalPages: 0,
      search: null,
      searched: null,
      currentPage: 0,
      roles: []
    },
    states: {
      idle: {
        invoke: {
          id: 'fetchCenterData',
          src: fetchCenterData,
          onDone: {
            actions: [
              assign({ roles: (_, event) => event.data }),
              send({ type: 'PAGEING', page: 1 })
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar la data del centro: ',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo descargar la data del centro: ',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se consulto tal vez porque no se conoce el uuid del branch',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      loaded: {},
      loading: {
        always: [
          { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ]
      },
      waiting: {},
      updated: {},
      adding: {
        invoke: {
          id: 'createAdmin',
          src: createAdmin,
          onDone: {
            target: 'created',
            actions: assign((context, event) => ({
              ...context,
              openModal: !context.openModal,
              addAdmin: event.data,
              statusError: 0,
            }))
          },
          onError: {
            target: 'aborted',
            actions: assign((context, event) => {

              let statusError = 13

              if (event.data.response) {

                const { email } = event.data.response.data

                if (email) statusError = event.data.response.status

                const temp = { ...event.data }
                delete temp.password
                delete temp.password_confirmation

                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: temp
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo crear tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError
              }

            })
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateAdmin',
          src: updateAdmin,
          onDone: {
            target: 'updated',
            actions: [
              'toogleModal',
              send((_, event) => ({ type: 'APPEND', data: event.data }), 
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                const temp = { ...event.data }
                delete temp.password
                delete temp.password_confirmation

                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar al admin.',
                  level: Sentry.Severity.Error,
                  data: temp
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar al admin tal vez no se conocía el branch.',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      created: {
        entry: send((context) => ({ type: 'APPEND', data: context.addAdmin }), 
          { to: (context) => context.page }
        )
      },
      searching: {
        invoke: {
          id: 'searchForAdmins',
          src: searchForAdmins,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedAdmins',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: assign((context, _) => ({
                ...context,
                currentPage: 1,
                page: Object.values(context.pageData)[0],
                pages: context.totalPages
              }))
          }
        }
      },
      lookingFor: {
        invoke: {
          id: 'searchCompundRequest',
          src: searchCompundRequest,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedAdmins',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          }
        }
      },
      aborted: {},
      login: {},
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
    },
    on: {
      TOGGLE: {
        target: '.loaded',
        actions: [ 'toogleModal'],
      },
      PAGEING: {
        target: '.loading',
        actions: assign((context, event) => {

          if (event.type !== 'PAGEING') return context

          // Use the existing subredit actor if one already exists
          let subPage = context.pageData[`${event.page}`]

          if (subPage) {
            return {
              ...context,
              page: subPage,
              pageAlreadyExists: true,
            }
          }

          // Otherwise, spawn a new subreddit actor and
          // save it in the subreddits object
          subPage = spawn( createPageMachine( event.page, 'loading' ) )

          return {
            pageData: {
              ...context.pageData,
              [`${event.page}`]: subPage
            },
            page: subPage,
            pageAlreadyExists: false,
          }
        })
      },
      READY: {
        target: '.loaded',
        actions: assign({ 
          pages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
          totalPages: (context, event) => event.pages > context.pages ? event.pages : context.pages
        })
      },
      ADDADMIN: {
        target: '.adding',
        actions: assign({ addAdmin: (_, event) => event.data })
      },
      UPDATE: {
        target: '.waiting',
        actions: assign((context, event) => ({
            ...context,
            openModal: !context.openModal,
            addAdmin: event.data
          }))
      },
      CHANGE: {
        target: '.updating',
        actions: assign({ admin: (_, event) => event.data })
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
          ...context,
          search: event.data,
          currentPage: 1
        }))
      }
    }
  },
  {
    actions: {
      toogleModal: assign((context) => ({
          ...context,
          openModal: !context.openModal,
          addAdmin: null,
          info: null
        })),
      searchedAdmins: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Administrators> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.searchForAdmins") 
          return { ...context }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, 'loaded' ) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      })
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      },
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

type CreatePageMachineContext = {
  page: number,
  admins: Administrators | null,
  status: boolean,
  statusError: number,
  admin: Administrator | null,
}

/**
 * APPEND: agregar un admin recién creado a la lista
 * STATUS: actualizar el status de un admin
 * UPDATE: le aviso a la maquina padre que hay que actualizar
 * DELETE: eliminar un admin
 * REFRESH: cuando es una página que es de busqueda se le manda la data buscada
 */
type CreatePageMachineEvent = 
  | { type: 'APPEND', data: Administrator }
  | { type: 'STATUS', data: Administrator }
  | { type: 'UPDATE', data: Administrator }
  | { type: 'DELETE', data: Administrator }
  | { type: 'REFRESH', data: Administrators }

/**
 * Actor que controla la lista de admins
 * @param page a la que pertenece este actor
 * @param initial estado inicial loading si es una página normal o loaded si es un búsqueda
 * @returns un actor
 */
export const createPageMachine = (page: number, initial: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvent >(
  {
    id: 'pageMachine',
    initial,
    context: {
      page,
      admins: null,
      status: false,
      admin: null,
      statusError: 0,
    },
    states: {
      loaded: {},
      login: {},
      deleted: {},
      loading: {
        invoke: {
          id: 'fetchAdmins',
          src: fetchAdmins,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent((context, _) => ({ type: 'READY', pages: context.admins?.meta.last_page || 0 }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              // sendParent({ type: 'READY', pages: -1 }),
              assign({ statusError: (_, event) => event.data.response.status })
            ]
          }
        }
      },
      changing: {
        invoke: {
          id: 'changeStatus',
          src: updateAdmin,
          onDone: {
            target: 'loaded',
            actions: send((context) => ({ type: 'APPEND', data: context.admin }))
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar al admin tal vez no se conocía el branch.',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      deleting: {
        invoke: {
          id: 'deleteAdmin',
          src: deleteAdmin,
          onDone:{
            target: 'deleted',
            actions: ['removeAdminOfList']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo eliminar al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo eliminar al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar al admin tal vez no se conocía el branch.',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      APPEND: {
        target: '.loaded',
        actions: ['appendAdmin']
      },
      STATUS: {
        target: 'changing',
        actions: assign({ admin: (_, event) => event.data })
      },
      UPDATE: {
        target: '.loaded',
        actions: sendParent((_, event) => ({ type: 'UPDATE', data: event.data }))
      },
      DELETE: {
        target: '.deleting',
        actions: assign({ admin: (_, event) => event.data })
      },
      REFRESH: {
        target: '.loaded',
        actions: ['preparingData']
      }
    }
  },
  {
    actions: {
      preparingData: assign((context, _event: any) => {
        
        // Cargamos el evento
        const event : DoneInvokeEvent<Administrators> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.fetchAdmins" && event.type !== "REFRESH") 
          return context

        // Combinamos los apellidos
        const admins : Administrator[] = event.data.data.map((athlete: Administrator) => ({
            ...athlete,
            lastName: `${athlete.last_name_1 || ""} ${athlete.last_name_2 || ""}`
          }))

        // Spread en event.data para combiar las links y meta
        return {
          ...context,
          admins: {
            ...event.data,
            data: admins
          }
        }

      }),
      appendAdmin: assign((context, event) => {

        if (!context.admins || event.type !== 'APPEND') return context

        const { data } = context.admins

        const exists = data.find((admin: Administrator) => admin.uuid ===  event.data.uuid)

        if (!exists){
          return {
            ...context,
            admins: {
              ...context.admins,
              data: [
                {
                  ...event.data,
                  created: moment().format('YYYY-MM-DD H:mm:ss')
                },
                ...context.admins.data
              ]
            }
          }
        }

        const newUsers = data.map((admin: Administrator) => {

          if (admin.uuid === event.data.uuid) return {...admin, ...event.data}

          return admin

        })

        return {
          ...context,
          admins: {
            ...context.admins,
            data: newUsers
          }
        }

      }),
      removeAdminOfList: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Administrator> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if ( !context.admins || event.type !== "done.invoke.deleteAdmin") return context

        const { data } = context.admins

        const newUsers = data.filter((admin: Administrator) => admin.uuid !== event.data.uuid)

        return {
          ...context,
          admins: {
            ...context.admins,
            data: newUsers
          }
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

export default AdminMachine