/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';

// Types
import { ShowChallenge, ChallengeRoom, UserChallenge, TeamMetric } from '../../../types';

// Componentes
import Group from '../utils/group/Group';
import GroupDetail from '../utils/group/GroupDetail';

interface RoomChallengeProps {
  isLoadingMetrics: { flag: boolean, who: string },
  isLoadingMembers: { flag: boolean, who: string },
  data: ShowChallenge,
  filteredData: ChallengeRoom[],
  goalsDetail: TeamMetric[],
  openDetail: boolean,
  onClose: () => void,
  showDetail: (uuid: string) => void,
  handleMembers: (data: { name: string, uuid: string }) => void
}
 
/**
 * Contenedor de la lista de salas
 * @param props contiene todas las propiedades necesarias descritas en
 * RoomChallengeProps
 * @param props.data en general del reto
 * @param props.filteredData lista de salas filtrada
 * @param props.openDetail bandera para mostrar o no el detalle del usuario
 * @param props.goalsDetail listado de objetivos
 * @param props.removing saber a quien se esta eliminando
 * @param props.onClose función que se ejecuta cuando se esta cerrando el modal de 
 * @param props.removeUser función que se ejecuta para eliminar un usuario
 * @param props.showUserDetail función que se ejecuta para saber los detalles del usuairo
 * @returns Contenedor de la lista de salas
 */
const RoomChallenge: FC<RoomChallengeProps> = ({ 
  data, filteredData, openDetail, goalsDetail, isLoadingMetrics, isLoadingMembers,
  onClose, showDetail, handleMembers
}) => {

  // Guardar la data para mostrar cuales son los objetivos completos
  const [roomDetail, setRoomDetail] = useState({
    uuid: '',
    team: '',
    percentage: 0,
    earned: 0,
    total: 0,
    position: 0,
    rookpoints: 0
  })

  /**
   * Obtener el porcentaje de avances
   * @param users lista de usuarios que están dentro del reto
   * @returns el porcentaje de avance del equipo
   */
   const getProgress = (users: UserChallenge[]) : number => {
    
    const objetives : number = data.total_goals * users.length
    const advances: number[] = []

    users.forEach( user => {

      const completed : number = data.goals.reduce((total, i) => {
  
        if (i.user_goal_completed) {
  
          if (i.user_goal_completed.find( current => current.user_uuid === user.user_uuid))
            return total + 1
        }
        
        return total
  
      }, 0)

      advances.push( completed * 100 / objetives )

    })

    return advances.reduce((total, i) => total + i, 0)

  }

  const handleClick = (
    uuid: string, percentage: number, earned: number, 
    total: number, team: string, position: number, rookpoints: number
  ) : void => {
    
    setRoomDetail({ percentage, earned, total, team, position, uuid, rookpoints })
    showDetail(uuid)

  }

  return ( 
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        { data.challenge_rooms && (
            filteredData.map((room, idx) => {
              return (
                <Group 
                  loadingMetrics = { 
                    isLoadingMetrics.flag && isLoadingMetrics.who === room.room_uuid 
                  }
                  loadingMembers = { 
                    isLoadingMembers.flag && isLoadingMembers.who === room.room_uuid 
                  }
                  isFree = { data.challenge_compliance === 'free' } 
                  isRoom
                  key={room.room_uuid || room.uuid} 
                  team={ room.name } 
                  earned={room.earned || 0} 
                  total={ data.total_goals } 
                  percentage={room.progress || 0 } 
                  rookPoints = { room.rookpoints }
                  position={(idx + 1)}
                  onClickMembers={
                    () => handleMembers({uuid: room.room_uuid || room.uuid, name: room.name}) 
                  }
                  onClick={ 
                    () => handleClick( 
                        room.room_uuid || room.uuid, 
                        room.progress || 0, 
                        room.earned || 0, 
                        data.total_goals, 
                        room.name,
                        (idx + 1),
                        room.rookpoints || 0
                      ) 
                  }          
                />
              )
            })     
        )}
      </div>

      <GroupDetail 
        isFree = { data.challenge_compliance === 'free' }
        open = { openDetail }
        onClose = { onClose } 
        teamName = { roomDetail.team }
        goals = {{ earned: roomDetail.earned, total: roomDetail.total  }}
        percentage = { roomDetail.percentage }
        goalList = { goalsDetail }
        handleMembers = { () => handleMembers({ 
          uuid: roomDetail.uuid,
          name: roomDetail.team
        })}
      />
    </>
  );
}
 
export default RoomChallenge;