import React, { FC, useEffect } from 'react'

// Dependencies
import { CircularProgress } from '@mui/material';

// Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Types
import Texts from '../../models/Texts'
import { UpdatePassword, Word } from '../../types';

// Componentes
import InputChangePasswordForm from '../utils/RookFormInputs/InputChangePasswordForm';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface ChangePasswordFormProps {
  spinner: boolean,
  reset: boolean,
  handleUpdate: (user: UpdatePassword) => void,
}

/**
 * Formulario de actualizar contraseña
 * @param props contiene todas las propiedades necesarias descritas en ProfileFormProps
 * @param props.reset reiniciar el formulario
 * @param props.spinner saber si se esta actualizando el contraseña
 * @param props.handleUpdate función para actualizar la contraseña
 * @returns formulario de contraseña
 */
const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ spinner, reset, handleUpdate }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Validation
  const formik = useFormik({
    initialValues: {
      'password_current': '',
      "password_confirmation": '',
      password: '',
    },
    validationSchema: Yup.object({
      'password_current': Yup.string()
                          .required('profileChangePwdCurrentError')
                          .min(8, 'profileChangePwdCurrentErrorLengthMin')
                          .max(21, 'profileChangePwdCurrentErrorLengthMax'),
      password: Yup.string()
                    .required("profileChangePwdNewError")
                    .min(8, 'profileChangePwdNewErrorMin'),
      'password_confirmation': Yup.string()
                                  .required('profileChangePwdConfirmationError')
                                  .oneOf(
                                    [Yup.ref('password'), null], 
                                    'profileChangePwdConfirmationErrorEqual'
                                  )
        
    }),
    onSubmit: values => {
      handleUpdate(values)
    }
  })

  // Cuando es exitoso el cambio de contraseña se reinicia el formulario
  useEffect(() => {
    
    if (reset) formik.resetForm()

  }, [reset])

  return ( 
    <form
      onSubmit = { formik.handleSubmit }
    >
      
      <InputChangePasswordForm 
        placeholder={ getTranslation( Texts.profileChangePwdCurrentPlaceholder as Word ) }
        id="password_current"
        title={ getTranslation( Texts.profileChangePwdCurrent as Word ) }
        value={ formik.values.password_current } 
        handleChange={ formik.handleChange } 
        handleBlur={ formik.handleBlur }    
        isError={(formik.touched.password_current && !!formik.errors.password_current )} 
        errorText={ formik.errors.password_current && 
          getTranslation( Texts[formik.errors.password_current] as Word) 
        }   
      />

      <InputChangePasswordForm 
        placeholder={ getTranslation( Texts.profileChangePwdNewPlaceholder as Word ) }
        id="password" 
        title={ getTranslation( Texts.profileChangePwdNew as Word ) } 
        value={ formik.values.password } 
        handleChange={ formik.handleChange } 
        handleBlur={ formik.handleBlur }  
        isError={(formik.touched.password && !!formik.errors.password )} 
        errorText={ formik.errors.password && 
          getTranslation( Texts[formik.errors.password] as Word) 
        }         
      />

      <InputChangePasswordForm 
        placeholder={ getTranslation( Texts.profileChangePwdConfirmationPlaceholder as Word ) }
        id="password_confirmation" 
        title={ getTranslation( Texts.profileChangePwdConfirmation as Word ) }   
        value={ formik.values.password_confirmation } 
        handleChange={ formik.handleChange } 
        handleBlur={ formik.handleBlur }    
        isError={
          (formik.touched.password_confirmation && !!formik.errors.password_confirmation )
        } 
        errorText={ formik.errors.password_confirmation && 
          getTranslation( Texts[formik.errors.password_confirmation] as Word) 
        }     
      />

      <div className = "mt-8 flex justify-center">
        { spinner 
          ? (<CircularProgress />)  
          : (
            <button
              type = "submit"
              className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
            >
              { getTranslation( Texts.profileChangePwdButton as Word ) }
            </button>
          )
      }
      </div>

    </form>
  )

}
 
export default ChangePasswordForm;