import {
  createMachine,
  assign,
  DoneInvokeEvent,
  spawn,
  send,
  sendParent,
} from "xstate";

import moment from "moment";
import lodash from "lodash";
import { jsPDF as JSPDF } from "jspdf";
import capitalize from "@mui/utils/capitalize";
import * as Sentry from "@sentry/react";

// Axios
import axiosClient from "../../config/axios";

// Utils
import Colors from "../../utils/colors";
import Ranges from "../../models/Ranges";
import { generateRandomColors, calculateAge, dataURItoBlob } from "../../utils";
import GeneratePDF from "../../utils/GeneratePDF";

// Types
import {
  Athlete,
  Athletes,
  AuthData,
  FullAthlete,
  LocalStorageKey,
  SearchIn,
  MetricsDetail,
  ChartData,
  UserStats,
  Translation,
  ReportTraining,
  Word,
} from "../../types";
import Texts from "../../models/Texts";

/**
 * READY: Cuando la página termino de cargar la lista de entrenamientos
 * PAGEING: Paginar
 * SEARCH: Buscar por un sensor,
 * ADDUSER: Agregar un usuario
 * UPDATEUSER: Actualizar un usuario
 * METRICS: Iniciar un actor para mostrar las métricas de un usuario
 * SYSTEM: Guardar el sistema métrico del admin
 * TOGGLE: Mostrar/Ocultar el modal
 * CLEVER: Cuando el actor termino de cargar la data del usuario
 * BACK: Volver a la vista anterior
 */
type UserMachineEvents =
  | { type: "READY"; pages: number }
  | { type: "PAGEING"; page: number }
  | { type: "SEARCH"; data: SearchIn }
  | { type: "ADDUSER"; data: Athlete }
  | { type: "UPDATEUSER"; data: string }
  | { type: "METRICS"; data: Athlete }
  | { type: "SYSTEM"; data: string }
  | { type: "LANG"; data: Translation }
  | { type: "TOGGLE" }
  | { type: "CLEVER" }
  | { type: "BACK" };

/**
 * metrics: actor para la vista gráficos
 * pageData: objeto con los actores
 * pages: número de páginas actuales
 * page: actor actual
 * totalPages: número de páginas originales
 * openModal: saber si esta abierto o no el modal
 * uuid: del usuario a actualizar
 * statusError: error del api
 * currentPage: número de la página actual
 * metricSystem: Guardar el sistema métrico del admin
 * search: criterios de búsqueda
 * addUser: data para crear un usuario
 * info: data descargada del usuario se consulta antes de abrir el modal
 * searched: resultados de la búsqueda
 * pageAlreadyExists: saber si la página que se esta consultando ya existe
 */
type UserMachineContext = {
  metrics: any;
  pageData: any;
  pages: number;
  page: any | null;
  lang: Translation;
  totalPages: number;
  openModal: boolean;
  uuid: string | null;
  statusError: number;
  currentPage: number;
  metricSystem: string;
  search: SearchIn | null;
  addUser: Athlete | null;
  info: FullAthlete | null;
  searched: Athletes | null;
  pageAlreadyExists: boolean;
};

type RecalculateReport = {
  time: number;
  steps: number;
  points: number;
  calories: number;
  labels: string[];
  durations: number[];
  total: number;
  zones: number[];
  lang: Translation;
};

type RecalculatedData = {
  metrics: MetricsDetail;
  zones: ChartData;
  activities: ChartData;
};

/**
 * Descargar la lista de usuarios
 * @returns Lista de usuarios vinculados al centro
 */
const retrieveUsers = async (context: any): Promise<Athletes> => {
  // Sacamos la pagina
  const { page } = context;

  // Definimos de que página vamos a descargar
  const pageURl: string = page ? `/api/v2/users?page=${page}` : "/api/v2/users";

  const response = await axiosClient.get(pageURl);
  return response.data;
};

/**
 * Crear un usuario
 * @param context es el context que tiene el automata
 * @returns respuesta de la api
 */
const createUser = async (context: UserMachineContext): Promise<any> => {
  // Sacamos el nuevo usuario del context
  const { addUser } = context;

  // Verificamos que exista
  if (!addUser) throw new Error("Invalid user");

  // Sacamos el uuid
  let { uuid } = context;

  if (!uuid) {
    // Solicitamos un nuevo uuid
    const response = await axiosClient.post(`/api/v2/users`, {
      email: addUser.email,
    });

    // Si era null actualizamos el uuid
    uuid = response.data.user_uuid;
  }

  // Armamos los objetos para cada endpoint
  const basicUser: any = {
    email: addUser.email,
    name: addUser.name,
    last_name_1: addUser.last_name_1,
    pseudonym: addUser.pseudonym,
    birthday: addUser.birthday,
    sex: addUser.sex,
  };

  if (addUser.last_name_2 !== "") basicUser.last_name_2 = addUser.last_name_2;

  const measurements = {
    weight: addUser.weight,
    height: addUser.height,
    resting_heart_rate: addUser.resting_heart_rate,
  };

  // Formamos las inserciones el paralelo
  await Promise.all([
    axiosClient.put(`/api/v2/users/${uuid}/information`, basicUser),
    axiosClient.post(`/api/v2/users/${uuid}/variables`, measurements),
  ]);

  return {
    ...basicUser,
    user_uuid: uuid,
    lastName: `${basicUser.last_name_1} ${basicUser.last_name_2 || ""}`,
  };
};

/**
 * Descargar la información un usuario para actualizarlo
 * @param context contexto que tiene el automata
 * @returns una respuesta de la ap
 */
const checkUserForUpdate = async (
  context: UserMachineContext
): Promise<FullAthlete> => {
  // Sacamos el uuid del context
  const { uuid } = context;

  // hacemos la petición para descargar la información del usuario
  const response = await axiosClient.get(`/api/v2/users/${uuid}/information`);

  return response.data;
};

/**
 * Eliminar al usuairo de la sucursal
 * @param context Context del automata para sacar el uuid
 */
const removeUser = async (context: CreatePageMachineContext): Promise<any> => {
  // Sacamos el uuid del context
  const { uuid, branchID } = context;

  // Realizamos la petición
  await axiosClient.delete(`/api/v2/centers/${branchID}/user/${uuid}/unlinked`);
};

/**
 * Buscar un usuario
 * @param context Contexto de la maquina principal
 * @returns Retorna la respuesta de la api
 */
const searchForUser = async (
  context: UserMachineContext
): Promise<Athletes> => {
  // Sacamos el objeto con el que vamos a buscar
  const { search } = context;

  // Si no hay lanzamos un error
  if (!search || search.search === "-1") throw new Error();

  // Hacemos la petición
  const url = `/api/v2/users?search_column=${search.search_column}&search=${search.search}`;
  const response = await axiosClient(url);

  return response.data;
};

/**
 * Buscar un usuario con páginación
 * @param context Contexto de la maquina principal
 * @returns Retorna la respuesta de la api
 */
const searchCompundRequest = async (
  context: UserMachineContext
): Promise<Athletes> => {
  // Sacamos el objeto con el que vamos a buscar
  const { search, currentPage } = context;

  // Si no hay lanzamos un error
  if (!search || search.search === "-1") throw new Error();

  // Formatemos la url
  let url: string = "/api/v2/users";
  url = `${url}?search_column=${search.search_column}&search=${search.search}`;
  url = `${url}&page=${currentPage}`;

  // Hacemos la petición
  const response = await axiosClient(url);

  return response.data;
};

/**
 * Descargar las estadisticas del usuarios
 * @param context maquina de las sessiones
 * @returns una lista de estadisticas del usuario
 */
const fetchUserStats = async (
  context: MetricsSessionMachineContext
): Promise<UserStats> => {
  // Sacamos el uuid
  const { uuid } = context;

  const dateTo = moment().format("YYYY-MM-DD");
  const dateFrom = moment().subtract(7, "d").format("YYYY-MM-DD");

  // Formateamos las urls
  let statsURL: string = `/api/v2/trainings/user/${uuid}`;
  statsURL = `${statsURL}?from_date=${dateFrom}&to_date=${dateTo}`;

  let weigthURL = `/api/v2/users/${uuid}/variables`;
  weigthURL = `${weigthURL}?from_date=${dateFrom}&to_date=${dateTo}&type_variable=weight`;

  // Formamos las inserciones el paralelo
  const [stats, weight] = await Promise.all([
    axiosClient.get(statsURL),
    axiosClient.get(weigthURL),
  ]);

  return {
    metrics: stats.data,
    weigth: weight.data,
  };
};

/**
 * Buscar metricas del usuario en cierto periodo
 * @param context de la maquina que controla las metricas
 * @returns las metricas del usuario
 */
const searchPeriod = async (
  context: MetricsSessionMachineContext
): Promise<UserStats> => {
  // Sacamos el uuid
  const { uuid, period } = context;

  // Formateamos las urls
  let statsURL: string = `/api/v2/trainings/user/${uuid}`;
  statsURL = `${statsURL}?from_date=${period[0]}&to_date=${period[1]}`;

  let weigthURL = `/api/v2/users/${uuid}/variables`;
  weigthURL = `${weigthURL}?from_date=${period[0]}&to_date=${period[1]}&type_variable=weight`;

  const [stats, weight] = await Promise.all([
    axiosClient.get(statsURL),
    axiosClient.get(weigthURL),
  ]);

  return {
    metrics: stats.data,
    weigth: weight.data,
  };
};

/**
 * Reintegrar usuario
 */
const reinstateUser = async (
  context: CreatePageMachineContext
): Promise<any> => {
  // Sacamos el uuid
  const { uuid } = context;

  // Mandamos la petición
  const response = await axiosClient.delete(`/api/v2/room/banned`, {
    data: {
      user_uuid: uuid,
    },
  });
  return response.data;
};

/**
 * It takes a context object, extracts the user and reportDataUri properties, converts the
 * reportDataUri to a blob, creates a file with the blob, and then sends the file to the user via email
 * @param {MetricsSessionMachineContext} context - MetricsSessionMachineContext
 */
const sendReportToUser = async (
  context: MetricsSessionMachineContext
): Promise<any> => {
  // Sacamos al usuario
  const { user, reportDataUri, centerName } = context;

  const data = dataURItoBlob(reportDataUri);
  const file = new File([data], `${user.name}-${user.lastName || ""}.pdf`);

  if (!user.email || !file || centerName === "") throw new Error();

  const formData = new FormData();
  formData.append("from", centerName);
  formData.append("email", user.email);
  formData.append("attach", file);

  const response = await axiosClient.post(
    `/api/v2/report/training-user`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

/**
 * It takes a userStats object and a language and returns an array of ReportTraining objects
 * @param {UserStats | null} userStats - UserStats | null
 * @param {Translation} lang - Translation.en | Translation.it
 * @returns An array of ReportTraining objects
 */
const getTrainingList = (
  userStats: UserStats | null,
  lang: Translation
): ReportTraining[] => {
  if (!userStats) return [];

  const training: ReportTraining[] = [];
  const format: string = lang === Translation.en ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const rawOffset = userStats.metrics.data[0]?.offset;
  const offset = Number.isNaN(Number(rawOffset)) ? -5 : Number(rawOffset);

  userStats.metrics.data.forEach((i) => {
    const seconds = Number(i.summaries.duration_time_tot);

    training.push({
      uuid: i.training_uuid,
      modality: capitalize(i.device_type),
      trainingType: i.training_type,
      duration: new Date(seconds * 1000).toISOString().substr(11, 8),
      date: moment.utc(i.start).utcOffset(offset).format(format),
      summaries: i.summaries,
      rewards: i.rewards,
    });
  });

  return training;
};

/**
 * It takes an array of training sessions, and returns an object with the total time, steps, points,
 * calories, and a breakdown of the time spent in each zone
 * @param {ReportTraining[]} trainings - ReportTraining[]
 * @param {Translation} lang - Translation
 */
const recalculateMetrics = (
  trainings: ReportTraining[],
  lang: Translation
): RecalculatedData => {
  let time = 0;
  let steps = 0;
  let points = 0;
  let calories = 0;
  const labels: string[] = [];
  const durations: number[] = [];

  // z5, z4, z3, z2, z1
  const zones = [0, 0, 0, 0, 0];

  trainings.forEach((session) => {
    const index: number = labels.findIndex((r) => r === session.trainingType);

    calories = calories + Number(session.summaries.calories_tot || "0");
    steps = steps + Number(session.summaries.steps_tot || "0");
    points = points + Number(session.rewards.calories_points || "0");
    time = time + +Number(session.summaries.duration_time_tot || "0");

    zones[0] += !session.summaries.z5_time_tot
      ? 0
      : Number(session.summaries.z5_time_tot) / 60;
    zones[1] += !session.summaries.z4_time_tot
      ? 0
      : Number(session.summaries.z4_time_tot) / 60;
    zones[2] += !session.summaries.z3_time_tot
      ? 0
      : Number(session.summaries.z3_time_tot) / 60;
    zones[3] += !session.summaries.z2_time_tot
      ? 0
      : Number(session.summaries.z2_time_tot) / 60;
    zones[4] += !session.summaries.z1_time_tot
      ? 0
      : Number(session.summaries.z1_time_tot) / 60;

    if (index < 0) {
      labels.push(session.trainingType);
      durations.push(Number(session.summaries.duration_time_tot) / 60);
    } else {
      durations[index] += Number(session.summaries.duration_time_tot) / 60;
    }
  });

  return formatDataForReport({
    time,
    steps,
    zones,
    points,
    calories,
    labels,
    durations,
    lang,
    total: trainings.length,
  });
};

/**
 * It takes a set of data and returns a new set of data that is formatted for the report
 * @param {RecalculateReport} props - RecalculateReport
 * @returns An object with the following properties:
 *   - metrics: An object with the following properties:
 *     - steps: The number of steps
 *     - points: The number of points
 *     - calories: The number of calories
 *     - sessions: The number of sessions
 *     - time: The time in the format '00 hrs 00 min 00 s'
 *   - activities: An object
 */
const formatDataForReport = (props: RecalculateReport): RecalculatedData => {
  const {
    labels,
    durations,
    time,
    steps,
    points,
    calories,
    total,
    zones,
    lang,
  } = props;

  const colors = generateRandomColors(labels.length);

  let newDurations: number[] = [...durations];

  if (durations.some((t) => t > 60)) {
    newDurations = durations.map((t) => t / 60);
  }

  const timeWithDots = new Date(time * 1000)
    .toISOString()
    .substr(11, 8)
    .split(":");
  let formattedTime = "";

  if (timeWithDots[0] !== "00") formattedTime = `${timeWithDots[0]} hrs`;

  if (formattedTime.length > 0 || timeWithDots[1] !== "00")
    formattedTime = `${formattedTime} ${timeWithDots[1]} min`;

  formattedTime = `${formattedTime} ${timeWithDots[2]} s`;

  return {
    metrics: {
      steps,
      points,
      calories,
      sessions: total,
      time: formattedTime,
    },
    activities: {
      labels: labels.map((lab) => capitalize(lab)),
      datasets: [
        {
          label: "",
          data: newDurations,
          backgroundColor: colors,
          borderColor: colors,
        },
      ],
    },
    zones: {
      labels: Ranges.map(({ label }) =>
        lang === Translation.es ? label.es : label.en
      ),
      datasets: [
        {
          label: "",
          data: zones,
          backgroundColor: [
            Colors.eMax,
            Colors.eIte,
            Colors.eMod,
            Colors.eLgt,
            Colors.eMin,
          ],
          borderColor: [
            Colors.eMax,
            Colors.eIte,
            Colors.eMod,
            Colors.eLgt,
            Colors.eMin,
          ],
          borderWidth: 1,
        },
      ],
    },
  };
};

/**
 * It takes a language and a report data object and returns an object with a head and a body property.
 *
 * The head property is an array of arrays of strings. The body property is an array of arrays of
 * strings.
 *
 * The body property is an array of arrays of strings. Each array of strings represents a row in the
 * table.
 *
 * The head property is an array of arrays of strings. Each array of strings represents a row in the
 * table.
 *
 * The head property is
 * @param {Translation} lang - Translation - The language of the report.
 * @param {ReportData | null} reportData - ReportData | null
 */
const getTableData = (
  lang: Translation,
  reportData: ReportData | null
): {
  body: string[][];
  head: string[][];
} => {
  let body: string[][] = [[]];
  const head: string[][] =
    lang === Translation.es
      ? [
          [
            "Nº",
            "Modalidad",
            "Tipo de entrenamiento",
            "Tiempo",
            "Fecha de entrenamiento",
          ],
        ]
      : [["Nº", "Modality", "Training Type", "Duration", "Date"]];

  if (reportData)
    body = reportData.trainings.map((i, index) => [
      `${index + 1}`,
      capitalize(i.modality),
      capitalize(i.trainingType),
      i.duration,
      i.date,
    ]);

  return {
    body,
    head,
  };
};

/**
 * It takes an athlete, a period and a language and returns a two dimensional array with the athlete's
 * information
 * @param {Athlete} user - Athlete - The user object
 * @param {string[]} period - string[]: the period of time you want to get the data from.
 * @param {Translation} lang - Translation.en | Translation.es
 * @returns An array of arrays with the user information
 */
const getUserInformation = (
  user: Athlete,
  period: string[],
  lang: Translation
): string[][] => {
  const format = lang === Translation.en ? "MM/DD/YYYY" : "DD/MM/YYYY";

  return [
    [lang === Translation.en ? "NAME" : "NOMBRE", user.name || "-"],
    [lang === Translation.en ? "LAST NAME" : "APELLIDO", user.lastName || "-"],
    [
      lang === Translation.en ? "AGE" : "EDAD",
      `${calculateAge(new Date(user.birthday))} ${
        lang === Translation.en ? "years old" : "años"
      }`,
    ],
    [lang === Translation.en ? "SEX" : "SEXO", user.sex === "male" ? "M" : "F"],
    [
      lang === Translation.en ? "BIRTHDAY" : "FECHA DE NACIMIENTO",
      `${moment(user.birthday).format(format)}`,
    ],
    [
      lang === Translation.en ? "PERIOD" : "PERIODO",
      `${moment(period[0]).format(format)} - ${moment(period[1]).format(
        format
      )}`,
    ],
  ];
};

/**
 * Preparar la info para mostrar las métricas del usuario
 * @param reportData data del usuario
 * @param lang lenguaje
 * @returns un arreglo bi-dimensional
 */
const getPeriodMetrics = (
  reportData: ReportData | null,
  lang: Translation
): string[][] => {
  if (!reportData) return [[]];

  const {
    userStatisticsTrainingLabel,
    userStatisticsTime,
    userStatisticsSteps,
    userStatisticsPoints,
  } = Texts;
  const workouts = userStatisticsTrainingLabel as Word;
  const time = userStatisticsTime as Word;
  const steps = userStatisticsSteps as Word;
  const points = userStatisticsPoints as Word;

  return [
    [
      lang === Translation.en
        ? "calories".toUpperCase()
        : "Calorías".toUpperCase(),
      `${reportData.metrics?.calories}`,
    ],
    [
      lang === Translation.en
        ? workouts.en.toUpperCase()
        : "Entrenamientos".toUpperCase(),
      `${reportData.metrics?.sessions}`,
    ],
    [
      lang === Translation.en
        ? points.en.toUpperCase()
        : points.es.toUpperCase(),
      `${reportData.metrics?.points.toFixed(2)}`,
    ],
    [
      lang === Translation.en ? steps.en.toUpperCase() : steps.es.toUpperCase(),
      `${reportData.metrics?.steps}`,
    ],
    [
      lang === Translation.en ? time.en.toUpperCase() : time.es.toUpperCase(),
      `${reportData.metrics?.time}`,
    ],
  ];
};

const getChartsTitle = (
  lang: Translation,
  isEffortInHours: boolean,
  isTrainingInHours: boolean
): string[] => {
  const { hours, minutes, userStatisticsMinutes, userStatisticsTypeTraining } =
    Texts;
  const timeHours = hours as Word;
  const timeMinutes = minutes as Word;
  const effortTitle = userStatisticsMinutes as Word;
  const trainingTitle = userStatisticsTypeTraining as Word;

  let effort =
    lang === Translation.en
      ? `${effortTitle.en.toUpperCase()}`
      : `Zonas de intensidad de FC.`.toUpperCase();

  if (lang === Translation.en) {
    if (isEffortInHours) effort = `${effort} (${timeHours.en.toUpperCase()})`;
    else effort = `${effort} (${timeMinutes.en.toUpperCase()})`;
  } else if (isEffortInHours)
    effort = `${effort} (${timeHours.es.toUpperCase()})`;
  else effort = `${effort} (${timeMinutes.es.toUpperCase()})`;

  let type =
    lang === Translation.en
      ? `${trainingTitle.en.toUpperCase()}`
      : `${trainingTitle.es.toUpperCase()}`;

  if (lang === Translation.en) {
    if (isTrainingInHours) type = `${type} (${timeHours.en.toUpperCase()})`;
    else type = `${type} (${timeMinutes.en.toUpperCase()})`;
  } else if (isTrainingInHours)
    type = `${type} (${timeHours.es.toUpperCase()})`;
  else type = `${type} (${timeMinutes.es.toUpperCase()})`;

  return [effort, type];
};

const UserMachine = createMachine<UserMachineContext, UserMachineEvents>(
  {
    id: "UserMachine",
    initial: "idle",
    context: {
      uuid: null,
      page: null,
      metrics: null,
      addUser: null,
      pageData: {},
      statusError: 0,
      openModal: false,
      search: null,
      info: null,
      searched: null,
      currentPage: 0,
      totalPages: 0,
      pages: 0,
      lang: Translation.en,
      pageAlreadyExists: false,
      metricSystem: "metric_system",
    },
    states: {
      idle: {
        entry: send({ type: "PAGEING", page: 1 }),
      },
      loaded: {
        on: {
          SYSTEM: {
            actions: assign({ metricSystem: (_, event) => event.data }),
          },
          LANG: {
            actions: assign({ lang: (_, event) => event.data }),
          },
        },
      },
      loading: {
        always: [
          { target: "lookingFor", cond: "isCompoundPagination" },
          { target: "loaded", cond: "isPageAlreadyExists" },
        ],
      },
      updated: {
        entry: send((context) => ({ type: "APPEND", data: context.addUser }), {
          to: (context) => context.page,
        }),
      },
      failure: {},
      adding: {
        invoke: {
          id: "createUser",
          src: createUser,
          onDone: {
            target: "updated",
            actions: assign((context, event) => ({
              ...context,
              openModal: !context.openModal,
              statusError: 0,
              addUser: event.data,
            })),
          },
          onError: {
            target: "failure",
            actions: [
              send({ type: "FAILURE" }, { to: (context) => context.page }),
              assign((context, event) => {
                const errorMessage: string = "No se editar/crear el usuario";

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });

                  Sentry.addBreadcrumb({
                    category: "info",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                if (event.data.response.status !== 409) {
                  return {
                    ...context,
                    openModal: !context.openModal,
                    statusError: event.data.response.status,
                  };
                }

                return {
                  ...context,
                  statusError: event.data.response.status,
                };
              }),
            ],
          },
        },
      },
      checking: {
        invoke: {
          id: "checkingUserForUpdate",
          src: checkUserForUpdate,
          onDone: {
            target: "loaded",
            actions: assign((context, event) => ({
              ...context,
              info: event.data,
              openModal: !context.openModal,
            })),
          },
          onError: {
            target: "failure",
            actions: assign((context, event) => {
              const errorMessage: string = "No se pudo descargar el usuario";

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: "error",
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data,
                });

                Sentry.addBreadcrumb({
                  category: "info",
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response,
                });
              } else {
                Sentry.addBreadcrumb({
                  category: "warning",
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data,
                });
              }

              Sentry.captureException(event.data);

              return {
                ...context,
                statusError: event.data.response
                  ? event.data.response.status
                  : 11,
              };
            }),
          },
        },
      },
      searching: {
        invoke: {
          id: "searchingUser",
          src: searchForUser,
          onDone: {
            target: "loaded",
            actions: [
              "searchedUsers",
              send((context) => ({ type: "REFRESH", data: context.searched }), {
                to: (context) => context.page,
              }),
            ],
          },
          onError: {
            target: "loaded",
            actions: assign((context, event) => {
              const errorMessage: string = "No se editar/crear el usuario";

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: "error",
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data,
                });

                Sentry.addBreadcrumb({
                  category: "info",
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response,
                });
              } else {
                Sentry.addBreadcrumb({
                  category: "warning",
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data,
                });
              }

              Sentry.captureException(event.data);

              return {
                ...context,
                page: Object.values(context.pageData)[0],
                currentPage: 1,
                pages: context.totalPages,
              };
            }),
          },
        },
      },
      lookingFor: {
        invoke: {
          id: "searchCompundRequest",
          src: searchCompundRequest,
          onDone: {
            target: "loaded",
            actions: [
              "searchedUsers",
              send((context) => ({ type: "REFRESH", data: context.searched }), {
                to: (context) => context.page,
              }),
            ],
          },
          onError: {
            actions: assign((context, event) => {
              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: "error",
                  message: "No se pudo hacer la busqueda",
                  level: Sentry.Severity.Error,
                  data: event.data,
                });

                Sentry.addBreadcrumb({
                  category: "info",
                  message: "No se pudo hacer la busqueda",
                  level: Sentry.Severity.Error,
                  data: event.data.response,
                });
              }

              Sentry.captureException(event.data);

              return {
                ...context,
                statusError: event.data.response
                  ? event.data.response.status
                  : 10,
              };
            }),
          },
        },
      },
      graphics: {
        on: {
          BACK: {
            target: "loaded",
            actions: [
              send(
                { type: "STOP" },
                {
                  to: (context) => context.metrics,
                }
              ),
            ],
          },
        },
      },
    },
    on: {
      READY: {
        target: ".loaded",
        actions: assign({
          pages: (context, event) =>
            event.pages > context.pages ? event.pages : context.pages,
          totalPages: (context, event) =>
            event.pages > context.pages ? event.pages : context.pages,
        }),
      },
      TOGGLE: {
        target: ".loaded",
        actions: [
          "toogleModal",
          send({ type: "READY" }, { to: (context) => context.page }),
        ],
      },
      PAGEING: {
        target: ".loading",
        actions: assign((context, event) => {
          if (event.type !== "PAGEING") return { ...context };

          if (context.search && context.search.search !== "-1")
            return {
              ...context,
              page: context.pageData.searchMachine,
              pageAlreadyExists: true,
              currentPage: event.page,
            };

          // Use the existing subredit actor if one already exists
          let subPage = context.pageData[`${event.page}`];

          if (subPage) {
            return {
              ...context,
              page: subPage,
              pageAlreadyExists: true,
              currentPage: event.page,
            };
          }

          // Otherwise, spawn a new subreddit actor and
          // save it in the subreddits object
          subPage = spawn(createPageMachine(event.page, "loading"));

          return {
            pageData: {
              ...context.pageData,
              [`${event.page}`]: subPage,
            },
            page: subPage,
            pageAlreadyExists: false,
            currentPage: event.page,
          };
        }),
      },
      ADDUSER: {
        target: ".adding",
        actions: assign({ addUser: (_, event) => event.data }),
      },
      UPDATEUSER: {
        target: ".checking",
        actions: assign({ uuid: (_, event) => event.data }),
      },
      SEARCH: {
        target: ".searching",
        actions: ["prepareSearch"],
      },
      METRICS: {
        target: ".loading",
        actions: ["prepareTransition"],
      },
      CLEVER: {
        target: ".graphics",
      },
    },
  },
  {
    actions: {
      toogleModal: assign((context, _) => ({
        ...context,
        openModal: !context.openModal,
        uuid: null,
        info: null,
      })),
      searchedUsers: assign((context, _event: any) => {
        // Cargamos el evento
        const event: DoneInvokeEvent<Athletes> = _event;

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (
          event.type !== "done.invoke.searchingUser" &&
          event.type !== "done.invoke.serchCompundRequest"
        )
          return { ...context };

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine;

        if (subPage) {
          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page,
          };
        }

        subPage = spawn(createPageMachine(1, "loaded"));

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage,
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page,
        };
      }),
      prepareSearch: assign((context, event) => {
        if (event.type !== "SEARCH") return { ...context };

        return {
          ...context,
          search: event.data,
          currentPage: 1,
        };
      }),
      prepareTransition: assign((context, event) => {
        if (event.type !== "METRICS") return { ...context };

        const metricsMachine = spawn(
          createMetricsMachine(
            event.data.user_uuid!,
            event.data,
            context.metricSystem,
            context.lang
          )
        );

        return {
          ...context,
          metrics: metricsMachine,
          search: null,
          pageAlreadyExists: false,
        };
      }),
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isCompoundPagination: (context) => {
        if (context.search && context.search.search !== "-1") return true;

        return false;
      },
    },
  }
);

/**
 * page: número de página a la que pertenece el actor
 * users: lista de sessiones
 * statusError: de la API
 * uuid: usuario a eliminar o desbanear
 */
type CreatePageMachineContext = {
  page: number;
  users: Athletes | null;
  statusError: number;
  uuid: string;
  branchID: string | null;
};

/**
 * APPEND: agregar un usuario recién creado a la lista
 * REFRESH: cuando es una página que es de busqueda se le manda la data buscada
 * UPDATE: le aviso a la maquina padre que hay que actualizar
 * TRANSITION: Indicarle al padre que se van a visualizar las gráficas y haga otro actor
 * UNBAN: desbanear un usuario
 * FAILURE: Algo fallo
 * READY: mover a estado de loaded
 */
type CreatePageMachineEvents =
  | { type: "APPEND"; data: Athlete }
  | { type: "REFRESH"; data: Athletes }
  | { type: "UPDATE"; data: string }
  | { type: "DELETE"; data: string }
  | { type: "TRANSITION"; data: Athlete }
  | { type: "UNBAN"; data: string }
  | { type: "FAILURE" }
  | { type: "READY" };

export const createPageMachine = (page: number, initial: string) =>
  createMachine<CreatePageMachineContext, CreatePageMachineEvents>(
    {
      id: "pageMachine",
      initial,
      context: {
        page,
        uuid: "",
        users: null,
        statusError: 0,
        branchID: null,
      },
      states: {
        login: {},
        loaded: {},
        updating: {},
        removed: {},
        authorized: {},
        loading: {
          invoke: {
            id: "fetchUsersPerPage",
            src: retrieveUsers,
            onDone: {
              target: "loaded",
              actions: [
                "preparingData",
                sendParent((context) => ({
                  type: "READY",
                  pages: context.users?.meta.last_page || 0,
                })),
              ],
            },
            onError: {
              target: "failure",
              actions: assign((context, event) => {
                const errorMessage: string =
                  "No se pudieron descargar los usuarios";

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });

                  Sentry.addBreadcrumb({
                    category: "info",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                return {
                  ...context,
                  statusError: event.data.response
                    ? event.data.response.status
                    : 10,
                };
              }),
            },
          },
        },
        removing: {
          invoke: {
            id: "removeUser",
            src: removeUser,
            onDone: {
              target: "removed",
              actions: ["removeUserOfData"],
            },
            onError: {
              target: "aborted",
            },
          },
        },
        authorizing: {
          invoke: {
            id: "reinstateUser",
            src: reinstateUser,
            onDone: {
              target: "authorized",
              actions: ["removeBan"],
            },
            onError: {
              target: "aborted",
            },
          },
        },
        success: {
          type: "final",
        },
        failure: {
          always: [{ target: "login", cond: "isUnauthorized" }],
        },
        aborted: {},
      },
      on: {
        APPEND: {
          target: ".loaded",
          actions: assign((context, event) => {
            if (!context.users) return { ...context };

            const { data } = context.users;

            const exists = data.find(
              (user: Athlete) => user.user_uuid === event.data.user_uuid
            );

            if (!exists)
              return {
                ...context,
                users: {
                  ...context.users,
                  data: [event.data, ...context.users.data],
                },
              };

            const newUsers = data.map((user: Athlete) =>
              user.user_uuid === event.data.user_uuid ? event.data : user
            );

            return {
              ...context,
              users: {
                ...context.users,
                data: newUsers,
              },
            };
          }),
        },
        UPDATE: {
          target: ".updating",
          actions: sendParent((_, event) => ({
            type: "UPDATEUSER",
            data: event.data,
          })),
        },
        READY: {
          target: ".loaded",
        },
        DELETE: {
          target: ".removing",
          actions: assign({ uuid: (_, event) => event.data }),
        },
        UNBAN: {
          target: ".authorizing",
          actions: assign({ uuid: (_, event) => event.data }),
        },
        REFRESH: {
          target: ".loaded",
          actions: ["preparingData"],
        },
        FAILURE: {
          target: ".loaded",
        },
        TRANSITION: {
          actions: [
            sendParent((_, event) => ({ type: "METRICS", data: event.data })),
          ],
        },
      },
    },
    {
      actions: {
        preparingData: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<Athletes> = _event;

          // Si no se mando llamar de retrieveUsers hacemos un early return
          if (
            event.type !== "done.invoke.fetchUsersPerPage" &&
            event.type !== "REFRESH"
          )
            return { ...context };

          // Combinamos los apellidos
          const users: Athlete[] = event.data.data.map((athlete: Athlete) => {
            let first = "-";
            let second = "";

            if (athlete.last_name_1 && athlete.last_name_1 !== "null")
              first = athlete.last_name_1;

            if (athlete.last_name_2 && athlete.last_name_2 !== "null")
              second = athlete.last_name_2;

            return {
              ...athlete,
              lastName: `${first} ${second}`,
            };
          });

          const rawAuth = localStorage.getItem(LocalStorageKey.auth);
          const authData: AuthData = JSON.parse(rawAuth || "null");

          return {
            ...context,
            users: {
              ...event.data,
              data: users,
            },
            branchID: authData.uuidBranch,
          };
        }),
        removeUserOfData: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<any> = _event;

          // Si no se mando llamar de retrieveUsers hacemos un early return
          if (event.type !== "done.invoke.removeUser") return { ...context };

          // Inicializamos el arreglo para filtrar
          let filteredUsers = context.users ? context.users.data : [];

          // Filtramos los usuarios que sean diferentes al uuid en el context
          filteredUsers = filteredUsers.filter(
            (user: Athlete) => user.user_uuid !== context.uuid
          );

          return {
            ...context,
            users: {
              links: context.users!.links,
              meta: context.users!.meta,
              data: filteredUsers,
            },
          };
        }),
        removeBan: assign((context, _event: any) => {
          // Cargamos el event
          const event: DoneInvokeEvent<any> = _event;

          // Si no se mando llamar de reinstateUser hacemos un early return
          if (event.type !== "done.invoke.reinstateUser") return { ...context };

          let newUsers = context.users ? context.users.data : [];

          newUsers = newUsers.map((user) => {
            if (user.user_uuid === context.uuid)
              return {
                ...user,
                banned_user: 0,
              };

            return user;
          });

          return {
            ...context,
            users: {
              links: context.users!.links,
              meta: context.users!.meta,
              data: newUsers,
            },
          };
        }),
      },
      guards: {
        isUnauthorized: (context) => context.statusError === 401,
      },
    }
  );

type ReportData = {
  zones: ChartData | null;
  activities: ChartData | null;
  metrics: MetricsDetail | null;
  trainings: ReportTraining[];
};

/**
 * uuid: del ususario
 * user: data del ususario
 * system: systema de mediciones
 * statusError: error de la API
 * zones: data para la gráfica de barras y pie
 * data: data del periodo de entrenamientos del usuario
 * isEffortInHours: saber si el esfuerzo son horas
 * isTrainingInHours: dsaber si los entrenamientos son de horas
 * extra: data extra que esta hasta arriba de la página
 * activities: data extra para la gráficas de actividades que hace el usuario
 * weigth: data para el peso del usuario
 */
type MetricsSessionMachineContext = {
  uuid: string;
  user: Athlete;
  system: string;
  period: string[];
  lang: Translation;
  download: boolean;
  centerName: string;
  statusError: number;
  reportDataUri: string;
  data: UserStats | null;
  zones: ChartData | null;
  isEffortInHours: boolean;
  weigth: ChartData | null;
  isOpenReportModal: boolean;
  isTrainingInHours: boolean;
  extra: MetricsDetail | null;
  activities: ChartData | null;
  reportData: ReportData | null;
};

/**
 * PERIOD: Buscar entrenamientos dentro de un periodo
 * BACK: Regresar a la vista anterior
 * STOP: Detener el actor
 */
type MetricsSessionMachineEvent =
  | { type: "REMOVE"; data: string }
  | { type: "PERIOD"; data: string[] }
  | {
      type: "DOWNLOAD";
      data: { center: string; comments: string; download: boolean };
    }
  | { type: "BACK" }
  | { type: "STOP" }
  | { type: "OPEN" }
  | { type: "CLOSE" }
  | { type: "DISMISS" };

export const createMetricsMachine = (
  uuid: string,
  user: Athlete,
  system: string,
  lang: Translation
) =>
  createMachine<MetricsSessionMachineContext, MetricsSessionMachineEvent>(
    {
      id: "MetricsSession",
      initial: "loading",
      context: {
        uuid,
        user,
        lang,
        system,
        data: null,
        extra: null,
        zones: null,
        weigth: null,
        centerName: "",
        statusError: 0,
        download: false,
        activities: null,
        reportData: null,
        reportDataUri: "",
        isEffortInHours: false,
        isOpenReportModal: false,
        isTrainingInHours: false,
        period: [
          moment().subtract(7, "d").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      states: {
        login: {},
        loaded: {
          on: {
            BACK: {
              actions: sendParent({ type: "BACK" }),
            },
            OPEN: {
              target: "review",
              actions: ["prepareReportData"],
            },
            PERIOD: {
              target: "searching",
              actions: assign({ period: (_, event) => event.data }),
            },
          },
        },
        loading: {
          invoke: {
            id: "fetchUserStats",
            src: fetchUserStats,
            onDone: {
              target: "loaded",
              actions: ["prepareData", sendParent({ type: "CLEVER" })],
            },
            onError: {
              target: "failure",
            },
          },
        },
        searching: {
          invoke: {
            id: "searchPeriod",
            src: searchPeriod,
            onDone: {
              target: "loaded",
              actions: ["prepareData"],
            },
            onError: {
              target: "failure",
              actions: assign({
                statusError: (_, event) =>
                  event.data.response ? event.data.response.status : 10,
              }),
            },
          },
        },
        view: {},
        decide: {
          always: [
            {
              target: "view",
              cond: "showPreview",
            },
            {
              target: "sending",
            },
          ],
        },
        sended: {
          after: {
            3400: {
              target: "loaded",
            },
          },
        },
        sending: {
          invoke: {
            id: "sendReportToUser",
            src: sendReportToUser,
            onDone: {
              target: "sended",
              actions: "closeReportModal",
            },
            onError: {
              target: "failure",
              actions: assign({
                statusError: (_, event) =>
                  event.data.response ? event.data.response.status : 10,
              }),
            },
          },
        },
        review: {
          on: {
            REMOVE: {
              actions: ["removeTraining"],
            },
            DOWNLOAD: {
              target: "decide",
              actions: ["prepareDocument"],
            },
          },
        },
        failure: {
          always: [{ target: "login", cond: "isUnauthorized" }],
          after: {
            3400: [
              {
                target: "loaded",
                cond: "goToLoaded",
              },
              {
                target: "review",
              },
            ],
          },
        },
        end: {
          type: "final",
        },
      },
      on: {
        STOP: {
          target: ".end",
        },
        CLOSE: {
          target: ".loaded",
          actions: ["closeReportModal"],
        },
        DISMISS: {
          target: ".loaded",
        },
      },
    },
    {
      actions: {
        prepareData: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<UserStats> = _event;

          if (
            event.type !== "done.invoke.fetchUserStats" &&
            event.type !== "done.invoke.searchPeriod"
          ) {
            return { ...context };
          }

          const labels: string[] = [];
          const weigthLabel: string[] = [];
          const values: number[] = [];
          let calories: number = 0;
          let points: number = 0;
          let time: number = 0;
          let steps: number = 0;
          let finalTime: string = "";
          let isEffortInHours = false;
          let isTrainingInHours = false;
          let calc: string[] = [];
          let first: string = "min";
          let second: string = "secs";
          let times: number[] = [];
          let colors: string[] = [];

          // z5, z4, z3, z2, z1
          const zones = [0, 0, 0, 0, 0];

          event.data.weigth.data.forEach((item) => {
            weigthLabel.push(item.datetime);
            values.push(
              context.system === "english_system"
                ? Number(item.weight) * 2.205
                : Number(item.weight)
            );
          });

          event.data.metrics.data.forEach((item) => {
            calories += Number(item.summaries.calories_tot || "0");
            points += !item.rewards.calories_points
              ? 0
              : Number(item.rewards.calories_points);
            time += Number(item.summaries.duration_time_tot) / 60;
            steps += Number(item.summaries.steps_tot || "0");
            zones[0] += !item.summaries.z5_time_tot
              ? 0
              : Number(item.summaries.z5_time_tot) / 60;
            zones[1] += !item.summaries.z4_time_tot
              ? 0
              : Number(item.summaries.z4_time_tot) / 60;
            zones[2] += !item.summaries.z3_time_tot
              ? 0
              : Number(item.summaries.z3_time_tot) / 60;
            zones[3] += !item.summaries.z2_time_tot
              ? 0
              : Number(item.summaries.z2_time_tot) / 60;
            zones[4] += !item.summaries.z1_time_tot
              ? 0
              : Number(item.summaries.z1_time_tot) / 60;

            const index: number = labels.findIndex(
              (r) => r === item.training_type
            );

            if (index < 0) {
              labels.push(item.training_type);
              times.push(Number(item.summaries.duration_time_tot) / 60);
            } else {
              times[index] += Number(item.summaries.duration_time_tot) / 60;
            }
          });

          colors = generateRandomColors(labels.length);

          if (time > 60) {
            time /= 60;
            first = "hrs";
            second = "min";
          }

          calc = time.toFixed(2).split(".");

          if (calc.length > 1) {
            let seconds: string = "";
            const minutes = Number(`0.${calc[1]}`) * 60;
            const rawSeconds = Number(minutes.toFixed(2).split(".")[1]);

            if (rawSeconds > 0) {
              seconds = `${(Number(`0.${rawSeconds}`) * 60).toFixed(0)} s`;
            }

            if (first !== "min")
              finalTime = `${calc[0]} ${first} ${minutes.toFixed(
                0
              )} ${second} ${seconds}`;
            else
              finalTime = `${calc[0]} ${first} ${minutes.toFixed(0)} ${second}`;
          } else finalTime = `${calc[0]} ${first}`;

          if (zones.some((z) => z > 60)) {
            zones[0] /= 60;
            zones[1] /= 60;
            zones[2] /= 60;
            zones[3] /= 60;
            zones[4] /= 60;
            isEffortInHours = true;
          }

          if (times.some((t) => t > 60)) {
            times = times.map((t) => t / 60);
            isTrainingInHours = true;
          }

          return {
            ...context,
            isEffortInHours,
            isTrainingInHours,
            data: event.data,
            extra: {
              sessions: event.data.metrics.data.length,
              calories,
              time: finalTime,
              points,
              steps,
            },
            zones: {
              labels: Ranges.map(({ label }) =>
                context.lang === Translation.es ? label.es : label.en
              ),
              datasets: [
                {
                  label: "",
                  data: zones,
                  backgroundColor: [
                    Colors.eMax,
                    Colors.eIte,
                    Colors.eMod,
                    Colors.eLgt,
                    Colors.eMin,
                  ],
                  borderColor: [
                    Colors.eMax,
                    Colors.eIte,
                    Colors.eMod,
                    Colors.eLgt,
                    Colors.eMin,
                  ],
                  borderWidth: 1,
                },
              ],
            },
            activities: {
              labels: labels.map((lab) => capitalize(lab)),
              datasets: [
                {
                  label: "",
                  data: times,
                  backgroundColor: colors,
                  borderColor: colors,
                },
              ],
            },
            weigth: {
              labels: weigthLabel,
              datasets: [
                {
                  label: "",
                  data: values,
                  backgroundColor: Colors.eMax,
                  borderColor: Colors.eMax,
                  tension: 0.4,
                },
              ],
            },
          };
        }),
        closeReportModal: assign((context) => ({
          ...context,
          isOpenReportModal: false,
          reportData: null,
          reportDataUri: "",
          download: false,
        })),
        prepareReportData: assign((context, event) => {
          if (event.type !== "OPEN" && event.type !== "CLOSE")
            return { ...context };

          const { isOpenReportModal, zones, activities, extra } = context;

          if (!isOpenReportModal && zones && activities && extra)
            return {
              ...context,
              isOpenReportModal: true,
              reportData: {
                zones: lodash.cloneDeep(zones),
                activities: lodash.cloneDeep(activities),
                metrics: lodash.cloneDeep(extra),
                trainings: getTrainingList(context.data, context.lang),
              },
            };

          return {
            ...context,
            isOpenReportModal: false,
            reportData: null,
            reportDataUri: "",
            download: false,
          };
        }),
        removeTraining: assign((context, event) => {
          if (event.type !== "REMOVE") return { ...context };

          if (!context.reportData) return { ...context };

          const trainings = context.reportData.trainings.filter(
            (t) => t.uuid !== event.data
          );

          const data = recalculateMetrics(trainings, context.lang);

          return {
            ...context,
            reportData: {
              ...context.reportData,
              trainings,
              metrics: data.metrics,
              activities: data.activities,
              zones: data.zones,
            },
          };
        }),
        prepareDocument: assign((context, event) => {
          if (event.type !== "DOWNLOAD") return { ...context };

          let total = 0;

          if (context.reportData && context.reportData.metrics)
            total = Math.ceil(context.reportData.metrics.sessions / 30 + 1);

          const doc = new JSPDF();
          const generator = new GeneratePDF(doc, context.lang, total);
          const charts = document.querySelectorAll<HTMLCanvasElement>(
            "#report-charts canvas"
          );

          const metricsTitle =
            context.lang === Translation.en
              ? "TRAINING METRICS DURING THE PERIOD"
              : "MÉTRICAS DE ENTRENAMIENTOS DURANTE EL PERIODO";

          // Insertamos header y footer
          generator.getHeader();
          generator.getFooter(1);

          // Insertamos el titulo de la página
          generator.getPageTitle(
            context.lang === Translation.es
              ? "CENTRO FITNESS:"
              : "FITNESS CENTER:",
            event.data.center
          );

          // Inserto información del usuario
          generator.getBoxContent(
            context.lang === Translation.en
              ? "USER INFORMATION"
              : "INFORMACIÓN DEL USUARIO",
            getUserInformation(context.user, context.period, context.lang),
            10,
            45,
            190,
            43
          );

          // Insertamos métricas del usuario
          generator.getBoxContent(
            metricsTitle,
            getPeriodMetrics(context.reportData, context.lang),
            10,
            90,
            190,
            43
          );

          const commentsTitle =
            context.lang === Translation.es
              ? "Comentario Adicional".toUpperCase()
              : "Additional Comments".toUpperCase();

          // Insertamos los comentarios
          generator.getSimpleContent(
            commentsTitle,
            event.data.comments,
            10,
            135,
            190,
            68
          );

          const [effort, type] = getChartsTitle(
            context.lang,
            context.isEffortInHours,
            context.isTrainingInHours
          );

          // Insertamos las gráficas
          generator.getChartContainer(effort, 10, 205, 90, 80);

          generator.insertImage(charts[0].toDataURL(), "png", 19, 221, 70, 50);

          generator.getChartContainer(type, 105, 205, 95, 80);

          generator.insertImage(charts[1].toDataURL(), "png", 117, 212, 70, 70);

          // Agregamos una página para que se inserte la tabla
          doc.addPage();

          // Insertamos la tabla
          const { head, body } = getTableData(context.lang, context.reportData);
          generator.getTable(
            head,
            body,
            context.lang === Translation.es ? "ENTRENAMIENTOS" : "TRAININGS"
          );

          return {
            ...context,
            reportDataUri: doc.output("datauristring", {
              filename: "reporte.pdf",
            }),
            download: event.data.download,
            centerName: event.data.center,
          };
        }),
      },
      guards: {
        isUnauthorized: (context) => context.statusError === 401,
        showPreview: (context) => !!context.download,
        goToLoaded: (context) => !context.isOpenReportModal,
      },
    }
  );

export default UserMachine;
