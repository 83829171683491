/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMachine, assign, DoneInvokeEvent, sendParent } from "xstate";
import * as Sentry from "@sentry/react";

import shortid from "shortid";
import "moment-timezone";
import moment from "moment";

// Types and models
import { Channel } from "pusher-js";
import {
  Configuration,
  LocalStorageKey,
  TrainingTypeList,
  Subscription,
  Measurements,
  BanUser,
  Training,
  ClassConfig,
  ClusterIcon,
  ZoomCredentials,
} from "../../types";

// Firebase
import firebase from "../../utils/firebase";

// Http Client
import pusher from "../../config/pusher";
import axiosClient from "../../config/axios";

import sizes from "../../models/Sizes";
import { fakeMeasurement } from "../../utils";

type GridSpace = { columns: number; extra: number };

export type RealTimeMachineContext = {
  id: string;
  state: string;
  total: number;
  waiting: number;
  columns: number;
  floated: boolean;
  roomName: string;
  formated: string;
  hostname: string;
  progress: number;
  residuary: number;
  openModal: boolean;
  maximized: boolean;
  statusError: number;
  ban: BanUser | null;
  hasStreaming: boolean;
  isFullScreen: boolean;
  isBetaTester: boolean;
  channel: Channel | null;
  classFinishAt: Date | null;
  config: Configuration | null;
  measurementsInFlex: Training[];
  classConfig: ClassConfig | null;
  measurementsLogs: Measurements[];
  subscription: Subscription | null;
  measurements: Measurements | null;
  streamingIsAlreadyActive: boolean;
  observer: IntersectionObserver | null;
  trainingTypes: TrainingTypeList | null;
  zoomCredentials: ZoomCredentials | null;
  counter: number;
  current: number;
  isUp: boolean;
};

/**
 * CONFIG: configuración que se va a subir
 * FLOAT: Flotar la barra
 * ID: settear el id del room
 * UPDATE: Guardar nuevas mediciones
 * REMOVE: Bannear un usuario
 * DECREASE: Timer de espera
 * BACK: Regresar y matar los timers
 * REMOTE: Cambiar de estado por medio de pusher
 * STATE: Cambiar de estado por medio del botón
 * FINAL: Terminar la maquina
 * RESIZE: Maximizar o minimizar
 * OBSERVE: observer para flotar o no la barra
 */
export type RealTimeMachineEvent =
  | { type: "CONFIG"; data: Configuration }
  | { type: "FLOAT"; data: boolean }
  | { type: "ID"; data: string }
  | { type: "UPDATE"; data: Measurements }
  | { type: "REMOVE"; data: BanUser }
  | { type: "DECREASE"; data: number }
  | { type: "REMOTE"; data: string }
  | { type: "SCREEN"; data: boolean }
  | { type: "NoSTREAMING"; data: false }
  | { type: "BACK" }
  | { type: "STATE" }
  | { type: "TOGGLE" }
  | { type: "PROGRESS" }
  | { type: "FINAL" }
  | { type: "RESIZE" }
  | { type: "RECOVERY" }
  | {
      type: "OBSERVE";
      callback: (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => void;
    };

/**
 * Descargar la lista de entrenamientos
 * @returns una lista de tipos de entrenamientos
 */
const fetchTrainingTypes = async (): Promise<TrainingTypeList> => {
  const response = await axiosClient.get(`/api/v2/trainings/type`);

  return response.data;
};

/**
 * Iniciar un room
 * @param context de la maquina que controla esta vista
 * @returns una subscription
 */
const initRoom = async (
  context: RealTimeMachineContext
): Promise<Subscription> => {
  // Sacamos el context
  const { config, id } = context;

  if (!config) throw new Error();

  const response = await axiosClient.post(
    `/api/v2/room/${id}/configuration`,
    config
  );

  return response.data;
};

/**
 * Cambiar el estado del room
 * @param context de la maquina que controla la vista
 * @returns el siguiente estado
 */
const changeRoomState = async (
  context: RealTimeMachineContext
): Promise<string> => {
  // Sacamos el estado
  const { state, subscription, hasStreaming } = context;

  if (state !== "stop" && state !== "reset") throw new Error();

  if (!subscription) throw new Error();

  // Cerramos la clase
  const { uuid } = subscription;
  await axiosClient.put(`/api/v2/room/configuration/${uuid}/state`, { state });

  if (state === "stop") {
    // Si hay streaming intentamos cerrarla
    if (hasStreaming)
      try {
        await axiosClient.post(`/api/v2/streaming-delete`, {
          configuration_class_uuid: uuid,
        });
      } catch (error) {
        Sentry.captureException(error);
      }

    // Esperamos 6 segundos para notificar a apps
    await new Promise((resolve) => setTimeout(resolve, 6000));
  }

  return state === "stop" ? "reset" : "initial";
};

/**
 * Descargar la lista de mediciones
 * @param context maquina que controla la vista
 * @returns una lista de mediciones
 */
const fetchMeasurements = async (
  context: RealTimeMachineContext
): Promise<Measurements> => {
  // sacamos el id del room
  const { subscription } = context;

  if (!subscription) throw new Error();

  const { uuid } = subscription;

  const response = await axiosClient({
    url: `/api/v2/measurements/class/${uuid}`,
    baseURL: process.env.REACT_APP_RT,
  });

  return response.data;
};

/**
 * Banear un usuario
 * @param context maquina que controla el grid
 * @returns el uuid y tipo de baneo
 */
const deleteUserFromRoom = async (
  context: RealTimeMachineContext
): Promise<BanUser> => {
  // Sacamos el usuario a banear
  const { ban, subscription } = context;

  if (!ban || !subscription) throw new Error();

  await axiosClient.delete(`/api/v2/room/configuration/${subscription.uuid}`, {
    data: ban,
  });

  return ban;
};

const recoverStreamingSession = async (
  context: RealTimeMachineContext
): Promise<Subscription> => {
  // Sacamos la configuración de la clase
  const { classConfig } = context;

  if (!classConfig) throw new Error();

  const response = await axiosClient.post("/api/v2/streaming-recovery", {
    configuration_class_uuid: classConfig.uuid || "",
  });

  return response.data;
};

/**
 * Identificar el icono a mostrar
 * @param type el tipo de icono a mostrar
 * @returns El icono a mostrar
 */
const getIcon = (type: string): ClusterIcon | null => {
  switch (type) {
    case "jumps":
    case "rope":
    case "trampoline":
      return ClusterIcon.trampoline;

    case "boots":
      return ClusterIcon.boots;

    case "steps":
    case "run":
      return ClusterIcon.steps;

    default:
      return null;
  }
};

/**
 * Calcular el autosize
 * @param n el número de clusters
 * @param isFullScreen saber si estamos a pantalla completa arriba de 992px
 * @param isStreaming saber si la clase de remote tiene streaming
 * @returns las columnas y cuantas van al flex
 */
const calculateTheNumberOfColumns = (
  n: number,
  isFullScreen: boolean
): GridSpace => {
  if (n > 12 || !isFullScreen) return { columns: 5, extra: 0 };

  // Para saber si no esta dentro de la plantilla
  let flag = false;

  // Sacar el modelo la plantilla
  let size = sizes[`${n}`];

  // Saber cuantos saltos hay que hacer dentro de la plantilla
  let carry = n;

  // Saber cuantos saltos se hicieron dentro de la plantilla
  let counter = 0;

  while (!size && carry > 0) {
    carry -= 5;
    flag = true;
    size = sizes[`${carry}`];
    counter += 1;
  }

  if (carry <= 0) return { columns: 5, extra: 0 };

  return {
    columns: flag ? size.columns + counter : size.columns,
    extra: flag ? n % (size.columns + counter) : n % size.columns,
  };
};

/**
 * Enviar a firebase todas la mediciones que recibimos
 * @param context de la máquina que controla el grid
 */
const sendMeasurementsToFirebase = async (
  context: RealTimeMachineContext
): Promise<any> => {
  // Si se dio stop y hay logs lo enviamos
  if (context.state === "reset" && context.measurementsLogs.length > 0) {
    const admitedHostNames = [
      "siluettesfitnesscenter.rookmotion.com",
      "fitnesstotalxalapa.rookmotion.com",
    ];

    const bridge = {
      url: window.location.hostname,
      roomID: context.id,
      data: context.measurementsLogs,
    };

    if (!admitedHostNames.includes(bridge.url)) throw new Error();

    await firebase.addDocument(
      bridge,
      `${moment().format("YYYY-MM-DDTHH:mm")}-${shortid.generate()}`,
      "measurements"
    );
  }
};

/**
 * Crear un actor de realtime
 * @param initial el estado donde iniciará el actor
 * @param id del room
 * @param state estado en el que inicia start, stop o reset
 * @param subscription la información del canal de pusher y parametros más
 * @param config configuración del room que se envía al servidor
 * @param classConfig configuración de la clase
 * @returns un actor
 */
const createRTMachine = (
  initial: string,
  id: string,
  state: string,
  roomName: string,
  subscription: Subscription | null,
  config: Configuration | null,
  classConfig: ClassConfig | null,
  hostname: string,
  zoomCredentials: ZoomCredentials | null,
  streamingIsAlreadyActive: boolean
) =>
  createMachine<RealTimeMachineContext, RealTimeMachineEvent>(
    {
      id: "RTM",
      initial,
      context: {
        id,
        state,
        config,
        total: 0,
        hostname,
        roomName,
        ban: null,
        waiting: 0,
        columns: 0,
        progress: 0,
        classConfig,
        formated: "",
        residuary: 0,
        subscription,
        channel: null,
        statusError: 0,
        floated: false,
        observer: null,
        zoomCredentials,
        maximized: false,
        openModal: false,
        measurements: null,
        isBetaTester: true,
        classFinishAt: null,
        isFullScreen: false,
        hasStreaming: false,
        trainingTypes: null,
        measurementsLogs: [],
        measurementsInFlex: [],
        streamingIsAlreadyActive,
        counter: 0,
        current: -1,
        isUp: true,
      },
      states: {
        login: {
          type: "final",
        },
        loading: {
          invoke: {
            id: "fetchTrainingTypes",
            src: fetchTrainingTypes,
            onDone: {
              target: "idle",
              actions: [
                assign({ trainingTypes: (_, event) => event.data }),
                sendParent({ type: "READY" }),
              ],
            },
            onError: {
              target: "failure",
              actions: assign((context, event) => {
                const errorMessage: string =
                  "No se pudieron obtener los tipos de entrenamiento";

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                return {
                  ...context,
                  statusError: event.data.response
                    ? event.data.response.status
                    : 4,
                };
              }),
            },
          },
        },
        idle: {
          entry: ["checkPreviousConfiguration"],
          on: {
            TOGGLE: {
              actions: assign({ openModal: (context) => !context.openModal }),
            },
            CONFIG: {
              target: "starting",
              actions: ["setConfiguration"],
            },
          },
        },
        starting: {
          invoke: {
            id: "initRoom",
            src: initRoom,
            onDone: {
              target: "started",
              actions: [
                "subscript",
                "initChannel",
                sendParent((context) => ({
                  type: "START",
                  data: { uuid: context.id, config: context.classConfig },
                })),
              ],
            },
            onError: {
              target: "not",
              actions: assign((context, event) => {
                const errorMessage: string = "No se pudo iniciar el room.";
                let statusError = event.data.response
                  ? event.data.response.status
                  : 10;

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                if (
                  event.data.response &&
                  event.data.response.data &&
                  event.data.response.data.result &&
                  event.data.response.data.result.includes("zoom")
                ) {
                  statusError = 43;
                }

                return {
                  ...context,
                  statusError,
                };
              }),
            },
          },
        },
        not: {
          always: [{ target: "login", cond: "isUnauthorized" }],
        },
        started: {
          entry: [
            "initChannel",
            assign({ maximized: (context) => !context.maximized }),
            sendParent({ type: "READY" }),
          ],
          invoke: {
            id: "fetchMeasurements",
            src: fetchMeasurements,
            onDone: {
              actions: ["prepareFinishAt", "prepareMeasurements"],
            },
            onError: {
              target: "error",
              actions: assign((context, event) => {
                Sentry.captureException(event.data);

                return {
                  ...context,
                  statusError: event.data.response
                    ? event.data.response.status
                    : 6,
                };
              }),
            },
          },
        },
        restart: {
          entry: sendParent((context) => ({
            type: "RESTART",
            data: {
              id: context.id,
              streaming: context.hasStreaming,
            },
          })),
        },
        stop: {
          entry: ["deleteInterval"],
          always: [{ target: "restart", cond: "isRestarted" }],
          invoke: {
            id: "firebase",
            src: sendMeasurementsToFirebase,
            onError: {
              actions: (_, event) => console.log(`event`, event),
            },
          },
        },
        moving: {
          invoke: {
            id: "changeRoomState",
            src: changeRoomState,
            onDone: {
              target: "stop",
              actions: ["changeState"],
            },
            onError: {
              target: "error",
              actions: assign((context, event) => {
                Sentry.captureException(event.data);

                return {
                  ...context,
                  statusError: event.data.response
                    ? event.data.response.status
                    : 9,
                };
              }),
            },
          },
        },
        removed: {},
        removing: {
          invoke: {
            id: "deleteUserFromRoom",
            src: deleteUserFromRoom,
            onDone: {
              target: "removed",
              actions: ["removeUser"],
            },
            onError: {
              target: "error",
              actions: assign((context, event) => {
                const errorMessage: string = "No se pudo banear al usuario";

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                return {
                  ...context,
                  statusError: event.data.response
                    ? event.data.response.status
                    : 8,
                };
              }),
            },
          },
        },
        recovering: {
          invoke: {
            id: "recoverStreamingSession",
            src: recoverStreamingSession,
            onDone: {
              actions: assign({
                subscription: (context, event) => ({
                  ...context.subscription,
                  ...event.data,
                }),
              }),
            },
            onError: {
              target: "rejected",
              actions: assign((context, event) => {
                const errorMessage: string = "No se pudo recuperar el zoom.";
                let statusError = event.data.response
                  ? event.data.response.status
                  : 10;

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: "error",
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data,
                  });
                } else {
                  Sentry.addBreadcrumb({
                    category: "warning",
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data,
                  });
                }

                Sentry.captureException(event.data);

                if (
                  event.data.response &&
                  event.data.response.data &&
                  event.data.response.data.result &&
                  event.data.response.data.result.includes("zoom")
                ) {
                  statusError = 43;
                }

                return {
                  ...context,
                  statusError,
                };
              }),
            },
          },
        },
        failure: {
          always: [{ target: "login", cond: "isUnauthorized" }],
        },
        error: {
          always: [{ target: "login", cond: "isUnauthorized" }],
        },
        rejected: {
          always: [{ target: "login", cond: "isUnauthorized" }],
        },
        end: {
          type: "final",
        },
      },
      on: {
        RESIZE: {
          actions: [
            assign({ maximized: (context) => !context.maximized }),
            sendParent({ type: "RESIZE" }),
          ],
        },
        FLOAT: {
          actions: [sendParent({ type: "MAXIMIZE" }), "prepareFloat"],
        },
        OBSERVE: {
          actions: ["initObserver"],
        },
        FINAL: {
          target: ".end",
          actions: ["unbind"],
        },
        BACK: {
          actions: [sendParent({ type: "BACK" })],
        },
        STATE: {
          target: ".moving",
        },
        UPDATE: {
          actions: ["prepareMeasurements"],
        },
        REMOVE: {
          target: ".removing",
          actions: assign({ ban: (_, event) => event.data }),
        },
        REMOTE: {
          target: ".stop",
          actions: ["changeRemoteState"],
        },
        DECREASE: {
          actions: ["delayTimer"],
        },
        PROGRESS: {
          actions: ["progressTimer"],
        },
        SCREEN: {
          actions: assign({ isFullScreen: (_, event) => event.data }),
        },
        NoSTREAMING: {
          actions: assign({ hasStreaming: (_, event) => event.data }),
        },
        RECOVERY: {
          target: "recovering",
        },
      },
    },
    {
      actions: {
        setBetaTester: assign((context) => {
          const testers: string[] = ["rookmotion", "adrianfit"];

          if (context.hostname === "") return { ...context };

          const split = context.hostname.split(".");

          if (split.pop() === "test" || testers.includes(split[0]))
            return {
              ...context,
              isBetaTester: true,
            };

          return {
            ...context,
            isBetaTester: false,
          };
        }),
        setConfiguration: assign((context, event) => {
          if (event.type !== "CONFIG") return { ...context };

          return {
            ...context,
            openModal: !context.openModal,
            config: event.data,
          };
        }),
        subscript: assign((context, _event: any) => {
          const event: DoneInvokeEvent<Subscription> = _event;

          if (event.type !== "done.invoke.initRoom") return { ...context };

          // Guardamos la configuración
          localStorage.setItem(
            `${LocalStorageKey.training}-${context.id}`,
            JSON.stringify(context.config)
          );

          // Sacamos el tiempo de espera
          const waiting = Number(event.data.remaining_waiting_seconds);

          // Guardamos cuando se termina el tiempo de espera
          const startDate = new Date();
          startDate.setSeconds(startDate.getSeconds() + waiting);

          // Fecha de terminación
          const duration = context.config ? context.config.duration : 60;
          const finishAt = new Date();
          finishAt.setMinutes(finishAt.getMinutes() + duration);
          finishAt.setSeconds(finishAt.getSeconds() + waiting);

          // Guardamos el tiempo restante
          localStorage.setItem(
            `${LocalStorageKey.delay}-${context.id}`,
            JSON.stringify(startDate)
          );

          let icon: string = "";

          if (context.trainingTypes && context.config) {
            const training = context.trainingTypes.data.find(
              (t) => t.training_type_uuid === context.config!.training_type_uuid
            );

            if (training && training.use_steps) {
              icon = training.use_steps.steps_types;
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-shadow
          const classConfig: ClassConfig = {
            uuid: event.data.uuid,
            channel: event.data.channel,
            capacity:
              context.config && context.config.capacity
                ? context.config.capacity
                : 0,
            duration,
            data_order: context.config ? context.config.data_order : "calories",
            class_delay: context.config ? context.config.class_delay : 0,
            class_start_at: `${moment(startDate)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")}`,
            class_finish_at: `${moment(finishAt)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss")}`,
            remote_state: 2,
            steps_icon: getIcon(icon),
            step_options: getIcon(icon),
            streaming: !!event.data.meet_number,
            meet_number: `${event.data.meet_number}`,
            meet_password: event.data.meet_password,
            signature: event.data.signature,
          };

          return {
            ...context,
            classConfig,
            maximized: true,
            subscription: event.data,
            waiting: Number(event.data.remaining_waiting_seconds),
            residuary: context.config!.duration + context.config!.class_delay,
            hasStreaming: !!event.data.meet_number,
          };
        }),
        initChannel: assign((context) => {
          let remaining = 0;

          if (context.subscription && context.waiting === 0) {
            remaining = Number(context.subscription.remaining_waiting_seconds);
          }

          let sub = context.subscription;

          if (
            context.classConfig &&
            context.classConfig.meet_number &&
            context.subscription
          ) {
            sub = {
              ...context.subscription,
              meet_number: Number(context.classConfig.meet_number),
              meet_password: context.classConfig.meet_password,
              signature: context.classConfig.signature,
              meet_url: "as",
            };
          }

          return {
            ...context,
            waiting: remaining,
            channel: pusher.subscribe(context.subscription?.channel || ""),
            hasStreaming: Boolean(context.classConfig?.streaming),
            subscription: sub,
          };
        }),
        checkPreviousConfiguration: assign((context) => {
          const raw: string | null = localStorage.getItem(
            `${LocalStorageKey.training}-${context.id}`
          );
          const bridge: Configuration | null = JSON.parse(raw || "null");

          if (bridge) {
            return {
              ...context,
              config: bridge,
            };
          }

          return { ...context };
        }),
        initObserver: assign((context, event) => {
          if (context.observer || event.type !== "OBSERVE")
            return { ...context };

          const observer: IntersectionObserver = new IntersectionObserver(
            event.callback
          );

          const actionBar = document.querySelector("#action-bar");

          // Se inicia el observer
          if (actionBar) observer.observe(actionBar);

          return {
            ...context,
            observer,
          };
        }),
        prepareFloat: assign((context, event) => {
          if (event.type !== "FLOAT") return { ...context };

          return {
            ...context,
            floated: event.data,
            maximized: true,
          };
        }),
        changeState: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<string> = _event;

          if (event.type !== "done.invoke.changeRoomState")
            return { ...context };

          return {
            ...context,
            state: event.data,
          };
        }),
        prepareFinishAt: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<Measurements> = _event;

          if (event.type !== "done.invoke.fetchMeasurements")
            return {
              ...context,
            };

          // Pasarla fecha de tiempo de México a Local
          // Sacamos la fecha actual más el residuo para obtener el término
          const finishAt = moment.utc(event.data.time_finish).local();

          return {
            ...context,
            classFinishAt: !context.classFinishAt
              ? finishAt.toDate()
              : context.classFinishAt,
          };
        }),
        prepareMeasurements: assign((context, _event: any) => {
          // Cargamos el evento
          const event: DoneInvokeEvent<Measurements> = _event;

          if (
            event.type !== "UPDATE" &&
            event.type !== "done.invoke.fetchMeasurements"
          )
            return {
              ...context,
            };

          const newResiduary: number = event.data.remaining_time
            ? Number(event.data.remaining_time)
            : context.residuary;

          const inFlex: Training[] = [];
          const { columns, extra } = calculateTheNumberOfColumns(
            event.data.data.length,
            context.isFullScreen
          );

          const step = event.data;

          for (let index = 0; index < extra; index += 1) {
            const bridge = step.data.pop();
            if (bridge) inFlex.unshift(bridge);
          }

          return {
            ...context,
            measurements: step,
            measurementsInFlex: inFlex,
            columns,
            residuary: newResiduary,
            total: step.data.length + inFlex.length,
            measurementsLogs: [...context.measurementsLogs, event.data],
          };
        }),
        changeRemoteState: assign((context, event) => {
          // Cargamos el evento
          if (event.type !== "REMOTE") return { ...context };

          // const data = {
          //   config: context.config,
          //   classConfig: context.classConfig
          // }
          // Sentry.captureMessage(`Sesión terminada de manera remota: ${JSON.stringify(data)}`)

          return {
            ...context,
            state: event.data,
          };
        }),
        unbind: assign((context, event) => {
          if (event.type !== "FINAL") return { ...context };

          context.channel?.unbind("user-new-measurement");
          context.channel?.unbind("event-stop");
          context.channel?.unbind("event-reset");

          return {
            ...context,
          };
        }),
        delayTimer: assign((context, event) => {
          if (event.type !== "DECREASE") return { ...context };

          const timer: number = context.waiting;

          const minutes: number = parseInt(`${timer / 60}`, 10);
          const seconds: number = parseInt(`${timer % 60}`, 10);

          const fMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
          const fSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

          return {
            ...context,
            waiting: timer > 0 ? timer - 1 : 0,
            formated: `${fMinutes}:${fSeconds}`,
          };
        }),
        deleteInterval: assign((context) => {
          localStorage.removeItem(`${LocalStorageKey.delay}-${context.id}`);

          return {
            ...context,
            progress: 100,
          };
        }),
        removeUser: assign((context, _event: any) => {
          // cargamos el evento
          const event: DoneInvokeEvent<BanUser> = _event;

          if (event.type !== "done.invoke.deleteUserFromRoom")
            return { ...context };

          const users = context.measurements!.data.filter(
            (u: Training) => u.final_user_uuid !== event.data.user_uuid
          );

          return {
            ...context,
            measurements: {
              ...context.measurements!,
              data: users,
            },
          };
        }),
        progressTimer: assign((context, event) => {
          if (event.type !== "PROGRESS" || !context.classConfig)
            return { ...context };

          // Obtenemos la fecha final en string
          const rawEndDate = context.classFinishAt
            ? context.classFinishAt
            : moment.utc(context.classConfig.class_finish_at).local().toDate();

          // Preparamos las fechas para hacer el calculo
          const startOfDate = moment(rawEndDate).subtract(
            context.classConfig.duration,
            "minutes"
          );

          const endDate = moment(rawEndDate);
          const currentDate = moment();

          // Obtenemos la diferencia en minutos
          const mainDifference = endDate.diff(startOfDate, "seconds");
          const difference = currentDate.diff(startOfDate, "seconds");

          // Obtenemos el progreso
          const progress = Math.round((difference * 100) / mainDifference);

          if (context.classFinishAt)
            return {
              ...context,
              progress,
            };

          return {
            ...context,
            progress,
            classFinishAt: endDate.toDate(),
          };
        }),
      },
      guards: {
        isUnauthorized: (context) => context.statusError === 401,
        isStoped: (context) => context.state === "stop",
        isRestarted: (context) => context.state === "initial",
        isOver: (context) => context.progress >= 100,
      },
    }
  );

export default createRTMachine;
