import React, { ChangeEvent, FC, useState, useEffect, useContext } from 'react'

// Dependencias
import { useMachine } from '@xstate/react';
import Pagination from '@mui/material/Pagination';
import { Snackbar, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom'

// Icons
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// Context
import UIContext from '../context/ui/UIContext'
import AdminMachine from '../context/machines/AdminMachine'

// types and models
import Texts from '../models/Texts'
import { Word, Administrator, OptionSearchBar, SearchIn } from '../types'

// Componentes
import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import SearchBar from '../components/utils/SearchBar'
import AdminList from '../components/admin/AdminList'
import Spinner from '../components/utils/Spinner';
import AdminModal from '../components/admin/AdminModal';
import Support from '../components/utils/Support';

// Hooks
import useTranslation from '../hooks/useTranslation'

const empty : Administrator = {
  name: "",
  email: "",
  uuid: '',
  last_name_1: '',
  last_name_2: '',
  mobile_phone: '',
  created: '',
  role: 'admin'
}

const internalOptions: OptionSearchBar[] = [
  {
    key: "name",
    title: "usersNameInput"
  },
  {
    key: "last_name_1",
    title: "adminTableHeaderLastName"
  },
  {
    key: "email",
    title: "loginEmail"
  },
  {
    key: "landline",
    title: "profileLandline"
  },
  {
    key: "mobile_phone",
    title: "profileMobilePhone"
  },
]

const Admin: FC = () => {

  // Machine para controlar esta vista
  const [machine, send] = useMachine(AdminMachine)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Mostrar o no el spinner
  const [loading, setLoading] = useState(true) 

  // Permisos
  const { permissions } = useContext( UIContext )

  // Navegación
  const history = useHistory()

  // Mostrar o no el spinner
  useEffect(() => {
    
    if(
      machine.matches('loading') 
      || machine.matches('idle') 
      || machine.matches('searching') 
      || machine.matches('lookingFor')
    ) setLoading(true)

    else setLoading(false)

    if ( machine.matches('updated') || machine.matches('created')) setShowAlert(true)
    else setShowAlert(false)

    if (machine.matches('login')) history.push('/login')


  }, [machine.value])

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
      send({
        type: 'PAGEING',
        page: value
      })
  };

  /**
   * Guardar o actualizar un admin
   * @param admin nuevo admin a guardar o actualizar
   */
  const saveAdmin = (admin: Administrator) : void => {
    if (machine.matches('waiting')) {
      send({
        type: 'CHANGE',
        data: admin
      })
    }
    else{
      send({
        type: 'ADDADMIN',
        data: admin
      })
    }
  }

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
   const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      <Spinner
        show = { loading }
      >
        <Container 
          title= { getTranslation( Texts.navMenuAdmin as Word ) } 
          icon = { <AdminPanelSettingsIcon /> }  
          rightButton = { 
            <Support
              action='admins'
            /> 
          }  
        >

          <Snackbar
            open = { showAlert }
            autoHideDuration = { 6000 }
            anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
            onClose = { () => setShowAlert( false ) }
          >
            <Alert
              severity = 'success'
              variant = "filled"
              onClose = { () => setShowAlert( false ) }
            >
              { machine.matches('updated') 
                ? getTranslation( Texts.adminUpdateSuccess as Word )
                : getTranslation( Texts.adminCreateSuccess as Word )
              }
            </Alert>
          </Snackbar>

          <div className="flex flex-col-reverse md:flex-row justify-between items-center">
      
            <button
              type = "button"
              className = {`uppercase bg-primary px-8 py-2 rounded-3xl mt-8 md:mt-0 w-full md:w-1/2  ${!permissions.includes('administrator_create') && 'invisible'} lg:w-max md:mr-4 lg:mr-0`}
              onClick = { () => send({ type: 'TOGGLE' }) }
            >
              { getTranslation( Texts.addminAdd as Word ) }
            </button>

            <SearchBar 
              action={ searchIn }
              options = { internalOptions }
            />

          </div>

          { machine.context.page && (
            <AdminList 
              service={ machine.context.page }
              isEditable = { permissions.includes('user_edit') }
              isRemovable = { permissions.includes('user_delete') } 
            />
          )}

          { ( machine.context.pages > 1) && (
            <div className="flex justify-center mt-12 mb-4">
              <Pagination 
                showFirstButton 
                showLastButton
                page = { machine.context.currentPage }
                variant="text" 
                color = "secondary"
                count = { machine.context.pages } 
                onChange = { handleChange }
              />
            </div>
          )}

          { machine.context.page && (
            <AdminModal
              value = { machine.value }
              roles = { machine.context.roles }
              openModal = { machine.context.openModal }
              info = { machine.context.addAdmin || empty }
              statusError = { machine.context.statusError }
              addUser = { saveAdmin }
              setOpenModal = { () => send({ type: 'TOGGLE' }) }
            />
          )}

        </Container>
      </Spinner>
    </Layout>
  );
}
 
export default Admin;