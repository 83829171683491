type Relation = {
  [key: string]: { 
    columns: number,
    streaming: number
  }
};

const sizes: Relation = {
  '1': { 
    columns: 1,
    streaming: 1
  },
  '2': { 
    columns: 2,
    streaming: 1
  },
  '3': { 
    columns: 2,
    streaming: 2
  },
  '4': { 
    columns: 2,
    streaming: 2
  },
  '5': { 
    columns: 3,
    streaming: 2
  },
  '6': { 
    columns: 3,
    streaming: 2
  },
}

export default sizes