import React, { FC, useRef } from 'react'

// Dependencies
import { ActorRef, State } from 'xstate'
import { useActor } from '@xstate/react';

// Components
import { RBox } from '../../utils/RookLayout';
import GenericTable from '../../utils/GenericTable';
import Medals from './Medals';

// Hook
import useTranslation from '../../../hooks/useTranslation'
import { PRMContext, PRMEvent } from '../../../context/machines/gaming/GamingRankingMachine';

interface BoardTableProps {
  service: any
}
 
const BoardTable: FC<BoardTableProps> = ({ service }) => {

  const [current] = useActor<ActorRef<PRMEvent, State<PRMContext>>>(service)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Headers de la tabla
  const headers = useRef([
    getTranslationByKey('user'),
    getTranslationByKey('usersPseudonymInput'),
    getTranslationByKey('adminTableHeaderEmail'),
    getTranslationByKey('boardPageChallengesParticipated'),
    getTranslationByKey('boardPageChallengesCompleted'),
    getTranslationByKey('boardPageChallengesAchievements'),
    getTranslationByKey('points'),
  ])

  // Keys de la tabla
  const keys = useRef([
    'name', 
    'pseudonym', 
    'email', 
    'participated_challenges', 
    'finished_challenges', 
    'achievement', 
    'points_meddals'
  ])


  return ( 
    <GenericTable 
      showCounter
      specialAt='achievement'
      headers={ headers.current } 
      body={ current.context.data } 
      bodyKeys={ keys.current } 
      customCounter = { 
        idx => (idx + 1) + (current.context.perPage * (current.context.page - 1))  
      }
      specialAtElement = { element => (
        <RBox
          className='flex-wrap items-center justify-between gap-4'
        >
          <Medals medals = { element.achievement } />
        </RBox>
      )}
    /> 
  );
}
 
export default BoardTable;