import React, { FC, ReactNode } from 'react'

interface TabPanelProps {
  index: number;
  value: number;
  children?: ReactNode;
}
 
/**
 * Tabs para navegación interna
 * @param props contiene todas las propiedades necesarias descritas en TabPanelProps
 * @param props.index de la tab
 * @param props.value vista que se esta viendo actualmente
 * @returns Tabs para navegación interna
 */
const TabPanel: FC<TabPanelProps> = ({ index, value, children }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      { value === index && (
        <div className = "p-5">
          { children }
        </div>
      )}
    </div>
  )
 
export default TabPanel;