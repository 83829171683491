import React, {FC} from 'react'

// Components
import GroupTitle from './GroupTitle';
import GroupBody from './GroupBody';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'
import { RBox } from '../../../utils/RookLayout';
import GroupBodyActions from './GroupBodyActions';
import FreeBody from '../FreeBody';

interface GroupProps {
  loadingMetrics: boolean,
  loadingMembers: boolean,
  team: number | string,
  earned: number,
  total: number | string,
  percentage: number,
  position: number,
  onClick ?: () => void,
  onClickMembers ?: () => void,
  isRoom ?: boolean,
  isFree ?: boolean,
  rookPoints ?: number
}

/**
 * Componente que muestra un equipo o sala
 * @param props contiene todas las propiedades necesarias descritas en
 * GroupProps
 * @param props.team nombre del equipo o sala
 * @param props.earned número de objetivos completados
 * @param props.total número de objetivos
 * @param props.percentage porcentaje de avance
 * @param props.position posición en la que esta el equipo
 * @param props.onClick función para ver el detalle del equipo
 * @param props.onClickMembers función para ver los integrantes del equipo
 * @param props.isRoom saber si es una sala
 * @returns Componente que muestra un equipo o sala
 */
const Group: FC<GroupProps> = ({ 
  team, earned, total, percentage, position, loadingMetrics,
  isRoom = false, isFree = false, rookPoints = 0, loadingMembers,
  onClick = () => {}, onClickMembers = () => {} 
}) => {

  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className='rounded-md bg-card-body'>
      <div className="rounded-t-md relative bg-group bg-no-repeat bg-cover bg-center h-60" />

      <GroupTitle 
        title = { !isRoom ? `${getTranslationByKey('team')} ${team}` : `${team}` }
        position={position}      
      />

      <RBox 
        type = 'grid'
        className='lg:grid-cols-eighty-twenty 2xl:grid-cols-eighty-fifteen'
      >
        { isFree 
          ? (
            <div className='w-full'>
              <FreeBody
                rookpoints={ Number.isNaN(Number(rookPoints)) ? 0 : Number(rookPoints) }
              />
            </div>
          ) 
          : (
            <GroupBody
              percentage = { percentage }
              goals = {{ earned, total: Number(total) }}
            />
          ) 
        }

        <GroupBodyActions
          loadingMetrics = { loadingMetrics } 
          loadingMembers = { loadingMembers } 
          handleProgress={ onClick } 
          handleMembers={ onClickMembers }          
        />
      </RBox>

    </div>
  );
}
 
export default Group;
