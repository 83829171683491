import React, { FC, useContext } from 'react'

// Context
import UIContext from '../../context/ui/UIContext'

// Types and modals
import Texts from '../../models/Texts'
import { Room, Word } from '../../types';

// Components
import Remote from './Remote';

// hooks
import useTranslation from '../../hooks/useTranslation'

interface RemoteListProps {
  rooms: Room[],
  loadingBackground: { state: boolean, uuid: string }
  deleting: { state: boolean, uuid: string },
  toggle: () => void,
  handleEdit: (room: Room) => void,
  handleDelete: (uuid: string) => void,
  handleGoToSession: (room: Room) => void,
  handleUpdateImage: (image: File, uuid: string) => void
}
 
/**
 * Lista de rooms de remote
 * @param props contiene todas las propiedades necesarias descritas en RemoteListProps
 * @param props.rooms lista de rooms de remote
 * @param props.loadingBackground saber si se esta actualizando el background
 * @param props.deleting saber a quien se esta borrando y si se esta borrando
 * @param props.toggle abrir el modal
 * @param props.handleEdit función para editar un room de remote
 * @param props.handleDelete función para eliminar un room de remote
 * @param props.handleGoToSession función para ir a la sesión de remote
 * @param props.handleUpdateImage función para actualizar la imagen del remote
 * @returns grid con los cards de rookremote
 */
const RemoteList: FC<RemoteListProps> = ({ 
  rooms, loadingBackground, deleting, toggle, handleEdit, handleDelete, handleGoToSession, handleUpdateImage
}) => {

  // hook para traducir
  const { getTranslation } = useTranslation()

  // Sacamos los permisos
  const { permissions } = useContext( UIContext )

  return ( 
    <>
      { permissions.includes('rookremote_create') && (
        <button
          type = "button"
          className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-max mb-8"
          onClick = { () => toggle() }
        >
          { getTranslation( Texts.remoteAddRoom as Word ) }
        </button>
      )}

      { rooms.length > 0 
        ? (
          <div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8"
          >
            {rooms.map((room: Room) => (
              <Remote
                isEditable = { permissions.includes('rookremote_edit') }
                isRemovable = { permissions.includes('rookremote_delete') }
                isImageChangable = { permissions.includes('rookremote_images') }
                key = { room.uuid }
                room = { room }
                loadingBackground = { loadingBackground.state && loadingBackground.uuid === room.uuid }
                deleting = { deleting.state && deleting.uuid === room.uuid }
                handleEdit = { handleEdit }
                handleDelete = { handleDelete }
                handleGoToSession = { handleGoToSession }
                handleUpdateImage = { handleUpdateImage }
              />
            ))}
          </div>
        ) 
        : (
          <p className = "text-center font-bold uppercase text-2xl my-24">
            { getTranslation( Texts.remoteEmpty as Word ) }
          </p>
        ) 
      }

    </>
  );
}
 
export default RemoteList;