/* eslint-disable react/no-unescaped-entities */
import React, { FC } from "react";
import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/images/unavailable.svg");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Unavailable: FC = () => {
  return (
    <div className="h-screen w-screen unavailable grid md:grid-cols-2 items-center gap-10">
      <div className="text-center lg:text-left">
        <img src="/images/rook-logo.png" alt="rook" className="lg:h-48" />
        <h1 className="px-6 md:px-0 md:pl-12 text-xl lg:text-4xl">
          We've transformed, now <span className="font-bold">RookMotion</span>{" "}
          is <span className="font-bold">ROOK</span>. To learn more about us,
          visit our website.
        </h1>

        <a
          className="lg:ml-12 bg-unavailable px-3 py-2.5 rounded-md mt-6 md:mt-10 inline-block w-56 text-center"
          href="https://www.tryrook.io/"
        >
          Go to tryrook.io
        </a>
      </div>
      <ImageContainer />
    </div>
  );
};

export default Unavailable;
