import React, { FC } from 'react'

// Dependencies
// import DeleteIcon from '@mui/icons-material/Delete';
// import CreateIcon from '@mui/icons-material/Create';
// import IconButton from '@mui/material/IconButton';

// Icons
// import Tooltip from '@mui/material/Tooltip';

// Types and models
import Texts from '../../models/Texts'
import { Word } from '../../types'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface RoleListProps {
  
}
 
const RoleList: FC<RoleListProps> = () => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <div className="overflow-x-scroll">
      <table className="table-auto mt-10 w-full capitalize">
        <thead className = "bg-input-background">
          <tr>
            <th className = "py-2 border border-table">
              { getTranslation(Texts.name as Word) }
            </th>
            <th className = "py-2 border border-table">
              { getTranslation( Texts.adminTableHeaderCreationDate as Word ) }
            </th>
            {/* <th className = "py-2 border border-table">
              { getTranslation( Texts.adminTableHeaderActions as Word ) }
            </th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className = "p-2 border border-table">
              { getTranslation( Texts.admin as Word ) }
            </td>
            <td className = "p-2 border border-table">19/08/21 17:05:05</td>
            {/* <td className = "p-2 border border-table">
              <div className="flex justify-evenly items-center">
                
                <div className="rounded-full bg-green-500">
                  <Tooltip title="editar">
                    <IconButton>
                      <CreateIcon 
                        fontSize = 'small'
                      />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="rounded-full bg-red-600">
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteIcon 
                        fontSize = 'small'
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                
              </div>
            </td> */}
          </tr>
          <tr>
            <td className = "p-2 border border-table">
              { getTranslation( Texts.coach as Word ) }
            </td>
            <td className = "p-2 border border-table">19/08/21 17:05:05</td>
            {/* <td className = "p-2 border border-table">
              <div className="flex justify-evenly items-center">
                
                <div className="rounded-full bg-green-500">
                  <Tooltip title="editar">
                    <IconButton>
                      <CreateIcon 
                        fontSize = 'small'
                      />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="rounded-full bg-red-600">
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteIcon 
                        fontSize = 'small'
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                
              </div>
            </td> */}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
 
export default RoleList;