import React, { FC, useContext, useEffect } from "react";

// Dependencies
import Swal from "sweetalert2";
import { useMachine } from "@xstate/react";

// Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Context
import UIContext from "./context/ui/UIContext";
import InitAppMachine from "./context/machines/InitAppMachine";

// PrivateRoute
import PrivateRoute from "./components/routes/PrivateRoute";

// Component
import Spinner from "./components/utils/Spinner";

// Pages
import Login from "./pages/Login";
import Users from "./pages/Users";
import Admin from "./pages/Admin";
import Roles from "./pages/Roles";
import Center from "./pages/Center";
import Profile from "./pages/Profile";
import Sensors from "./pages/Sensors";
import Reports from "./pages/Reports";
import Sessions from "./pages/Sessions";
import Requests from "./pages/Requests";
import NotFound from "./pages/NotFound";
import Recovery from "./pages/Recovery";
import Dashboard from "./pages/Dashboard";
import RookLegend from "./pages/RookLegend";
import RookRemote from "./pages/RookRemote";

// Sub Navs
import InternalRoutesGam from "./routes/gaming/InternalRoutesGam";

// Hooks
import useTranslation from "./hooks/useTranslation";
import Unavailable from "./components/unavailable/Unavailable";

const App: FC = () => {
  // Automata que prepara la aplicación
  const [machine] = useMachine(InitAppMachine);

  // hook para traducir
  const { getTranslationByLang } = useTranslation();

  // Cargamos el idioma en el context de la app
  const {
    setLang,
    setAuthenticated,
    setDashboardData,
    setCurrentUser,
    setCenterImage,
    setMetrics,
    setPermissions,
    setHostname,
    setContractAlreadyShown,
    setFeatures,
    setBranchUUID,
    setCenterName,
    setCenterData,
    setIsUnavailable,
  } = useContext(UIContext);

  useEffect(() => {
    if (machine.context.admin) {
      setLang(machine.context.admin.lang);
      setCenterImage(machine.context.admin.url);
      setHostname(machine.context.admin ? machine.context.admin.hostname : "");
      setCenterName(machine.context.admin.name);

      if (machine.context.admin.status === 0) {
        Swal.fire({
          icon: "warning",
          title: getTranslationByLang(
            "paymentErrorTitle",
            machine.context.admin.lang
          ),
          text: getTranslationByLang(
            "paymentErrorMessage",
            machine.context.admin.lang
          ),
        });

        const btn = document.querySelector<HTMLElement>(
          ".swal2-styled.swal2-confirm"
        );

        if (btn) {
          btn.style.textTransform = "uppercase";
          btn.style.borderRadius = "1rem";
          btn.style.paddingLeft = "2rem";
          btn.style.paddingRight = "2rem";
          btn.style.background =
            "linear-gradient(90deg, #A51313 0%, #E42823 100%)";
          btn.innerText = getTranslationByLang(
            "accept",
            machine.context.admin.lang
          );
        }
      }
    }

    if (machine.context.centerData) setCenterData(machine.context.centerData);
  }, [machine.context]);

  useEffect(() => {
    // Cuando la maquina llega a su fin se cargan los valores iniciales al state global
    if (machine.done) {
      setBranchUUID(machine.context.uuidBranch);
      setAuthenticated(true);
      setDashboardData(machine.context.data);
      setCurrentUser(machine.context.currentUser);
      setPermissions(machine.context.permissions);
      setMetrics(
        machine.context.admin
          ? machine.context.admin.measurement_system
          : "metric_system"
      );
      setContractAlreadyShown(!machine.context.showAgreement);
      setFeatures(machine.context.features);
    }

    // Si no se encuentra el centro
    if (machine.matches("unavailable")) setIsUnavailable(true);

    // Si se scaneo un qr de la caja del sensor
    if (machine.matches("qrbox")) {
      let fix: string = "-1";
      const url: string = "https://business.rookmotion.com/qr-manual-sensores";

      const userLang = navigator.language;

      if (userLang.includes("es")) fix = "";

      window.location.href = `${url}${fix}`;
    }
  }, [machine.value]);

  const getSpinnerText = (): string => {
    if (!machine.context.admin) return "";

    if (machine.context.admin.status === 0)
      return getTranslationByLang(
        "paymentErrorTitle",
        machine.context.admin.lang
      );

    return "";
  };

  return !["failure", "success", "unavailable"].includes(
    machine.value.toString()
  ) ? (
    <Spinner
      show
      stop={machine.context.admin ? machine.context.admin.status === 0 : false}
      title={getSpinnerText()}
    />
  ) : (
    <Router>
      <Switch>
        <Route exact path="/forgot-password" component={Recovery} />

        <Route exact path="/login" component={Login} />

        <Route exact path="/rook" component={Unavailable} />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/"
          component={Dashboard}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/users"
          component={Users}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/sessions"
          component={Sessions}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/profile"
          component={Profile}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/center"
          component={Center}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/admin"
          component={Admin}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/requests"
          component={Requests}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/rooklegend"
          component={RookLegend}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/rookremote"
          component={RookRemote}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/roles"
          component={Roles}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/reports"
          component={Reports}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          exact
          path="/sensors"
          component={Sensors}
        />

        <PrivateRoute
          failed={machine.matches("failure")}
          path="/challenges"
          component={InternalRoutesGam}
        />

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
