import React, {FC, ReactElement, useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { BandSensors, Gaming } from '../utils/Icons'

// Context
import UIContext from '../../context/ui/UIContext'

interface MenuItemProps {
  icon: ReactElement,
  title: string,
  action ?: string,
  sub?: boolean
}
 
/**
 * Opción de la barra de navegación
 * @param {Object} props contiene todas las propiedades necesarias descritas en MenuItemProps
 * @param {ReactElement} props.icon que tendrá la opción de navegación
 * @param {string} props.title que tendrá la opción de navegación
 * @param {string} props.action hacia donde se va a redirigir es opcional
 * @param {boolean} props.sub saber si es un submenú en caso de las salas
 * @returns opción de la navegación
 */
const MenuItem: FC<MenuItemProps> = ({ icon, title, action, sub}) => {

  // Hook para saber donde estamos actualmente
  const location = useLocation()

  // Bandera para saber si cambiar de color el menu
  const [isActive, setIsActive] = useState(false)

  // Context
  const { setTrunk, trunk } = useContext( UIContext )

  // Navegación
  const history = useHistory()

  useEffect(() => {
    
    if (action !== "/") {
      if ( location.pathname.includes(action || '____') ) setIsActive(true)
    }
    else if ( location.pathname === action ) setIsActive(true)

  }, [action])

  /**
   * Manejar el clic en la barra de navegación si es el primer click se navega normal
   * si es el segundo se agrega el back para regresar a la vista principal
   */
  const handleClick = () : void => {
    if (action) {
      history.push(action)

      if (trunk === action) setTrunk(`${action}/back`)
      else setTrunk(action)
    }
  }

  /**
   * Saber si es un icono propio o uno de material ui
   * @param path saber que menu u opción se va a dibujar
   * @param active saber si estamos viendo esa vista o si el menú esta activo
   * @returns un icono
   */
  const isCustomIcon = (path: string | undefined, active: boolean) : ReactElement => {
    
    if( !active || !path ) return icon

    switch (path) {

      case '/sensors':
        return <BandSensors color = '#ED3E3E'/>
    
      case '/challenges':
        return <Gaming color = '#ED3E3E'/>

      default: return icon
    }

  }

  return ( 
    <button 
      type = 'button'
      onClick = { handleClick }
      className = 'block'
      data-cy={`nav${action}`}
    >
      <div className={`flex mb-4 items-center ${sub && 'pl-8'} ${ isActive && 'text-title' }`}>
        { isCustomIcon(action, isActive) }
        <p className = "ml-4 tracking-wide text-left">
          {title}
        </p>
      </div>
    </button>
  )
}
 
export default MenuItem;