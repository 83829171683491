import React, { FC, CSSProperties } from 'react'

interface RQRImageFooterProps {
  sx ?: CSSProperties,
  className ?: string,
  title: string
}
 
const RQRImageFooter: FC<RQRImageFooterProps> = ({ title, className, sx }) => ( 
    <h1
      className={`font-bold md:text-xl uppercase text-center text-gray-700 border-t border-gray-500 bg-white w-full ${className || ''}`}
      style = { sx }
    >
      { title }
    </h1>
  )
 
export default RQRImageFooter;