import React, { FC, useRef, useContext, ReactElement } from 'react'

// Dependecies
import Fab from '@mui/material/Fab';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom'

// Types and models
import Texts from '../../models/Texts';
import { Word, Room } from '../../types';

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import QuickAccess from './QuickAccess';
import EmptyQuickAccess from './EmptyQuickAccess';
import Legend from "../rooklegend/Legend";
import Remote from '../rookremote/Remote'

// Hooks para traducir
import useTranslation from '../../hooks/useTranslation'

type QuickAccessListProps = {
  recentRRemote: Room | null | undefined,
  recentRLegend: Room | null,
}

/**
 * Contenedor para los rooms en acceso directo
 * @param param0 props contiene todas las propiedades necesarias descritas en QuickAccessListProps
 * @param {boolean} props.recentRRemote room de remote usado recientemente
 * @param {boolean} props.recentRLegend daq usado recientemente
 * @returns componente con los contenedores de acceso directo
 */
const QuickAccessList: FC<QuickAccessListProps> = ({ recentRRemote, recentRLegend }) => {

  // Context para sacar al usuario
  const { permissions } = useContext( UIContext )

  // Referencia para hacer scroll
  const quickAccess = useRef<any>()

  // Hook para traducir
  const { getTranslation } = useTranslation()

  const history = useHistory()

  // Acción del botón flotante para navegar a los accesos rápidos
  const navigateToQuickAccess = () : void => {
    
    if (quickAccess.current) quickAccess.current.scrollIntoView({ behavior: "smooth" })
    
  }

  /**
   * Conectarse a un daq
   * @param daq a conectarse
   */
  const handleConection = (daq: Room ) : void => {
    
    if (daq.daq_ip)
      window.open(`http://${daq.daq_ip}:8080/guacamole/#/?username=admin&password=rook`, '_blank')
    
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderRecentRRemote = () : ReactElement => {
    
    if (recentRRemote === undefined)
      return (
        <div className="flex justify-center h-full">
          <Skeleton
            variant = 'rectangular'
            width={300}
            height = '90%'
          />
        </div>
      )

    if (recentRRemote === null)
      return (
        <EmptyQuickAccess
          title = { getTranslation( Texts.dashboardEmptyRookRemote as Word ) }
        />
      )

    return (
      <div className = "flex flex-col items-center justify-center">
        <Remote
          room = {recentRRemote}
          isEditable = { permissions.includes('rookremote_edit') }
          isRemovable = { permissions.includes('rookremote_delete') }
          isImageChangable = { permissions.includes('rookremote_images') }
          handleGoToSession={ () => history.push(`/rookremote`, recentRRemote) }
        />
      </div>
    )

  }

  return ( 
    <div 
      className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10"
      ref = { quickAccess }
    >

      <QuickAccess
        title = {`RookLegend (${getTranslation(Texts.dashboardLastRoom as Word)})`}
        action = "/rooklegend"
      >
        { !recentRLegend 
          ? (
            <EmptyQuickAccess
              title = { getTranslation( Texts.dashboardEmptyRookLegend as Word ) }
            />
          ) 
          : (
            <div className = "flex flex-col items-center justify-center">
              <Legend
                legend = { recentRLegend }
                handleConection = { handleConection }
                isConnectable = { permissions.includes('rooklegend_connected') }
              />
            </div>
          ) 
        }
        
      </QuickAccess>

      <QuickAccess
        title = {`RookRemote (${getTranslation(Texts.dashboardLastRoom as Word)})`}
        action = "/rookremote"
      >

        { renderRecentRRemote() }

      </QuickAccess>

      <div className = "fixed bottom-24 right-4 block md:hidden">
        <Fab
          variant = "extended"
          color = "secondary"
          onClick = { navigateToQuickAccess } 
        >
          { getTranslation( Texts.dashboardFloatingButton as Word ) }
        </Fab>
      </div>

    </div>
  );
}
 
export default QuickAccessList;