import * as Yup from 'yup'

export default Yup.object().shape({
  'training_type_uuid': Yup.string()
                      .required('wConfigureTrainingTypeError')
                      .test(
                        'different', 
                        'wConfigureTrainingTypeError',
                        (text) => `${text}` !== '123'
                      ),
  duration: Yup.number()
                .required('wConfigureTrainingDurarionError')
                .positive('wConfigureTrainingDurarionValid')
                .integer('wConfigureTrainingDurarionValid')
                .min(1, 'wConfigureTrainingDurarionValid')
                .max(600, 'wConfigureTrainingDurarionValidMax'),
  'class_delay': Yup.number()
            .required('wConfigureTrainingDelayError')
            .min(0, 'wConfigureTrainingDelayValid')
            .max(10, 'wConfigureTrainingDelayValid')
            .integer('wConfigureTrainingDelayValid'),
  'data_order': Yup.string()
            .required('El tipo de entramiento es requerido')
            .test(
              'different', 
              'El tipo de entramiento es requerido',
              (text) => `${text}` !== '123'
            ),
  'user_capacity_status': Yup.boolean(),
  capacity: Yup.number()
              .when('capacity', {
                is: (value: any) => value,
                then: rule => rule.min(1, 'wConfigureTrainingCapacityError')
                                  .max(100, 'wConfigureTrainingCapacityError')
                                  .integer('wConfigureTrainingCapacityError')
              }),
}, [['capacity', 'capacity']])