/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'

// Dependencies
import Draggable from 'react-draggable';
import { Paper, PaperProps } from '@mui/material';

interface RPaperProps extends PaperProps {

}
 
const RPaper: FC<RPaperProps> = (props) => (
    <Draggable
      handle='#draggable-dialog-qr'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper { ...props } />
    </Draggable>
  )
 
export default RPaper;