import React, { ChangeEvent, FC, useRef, useState, ReactElement } from 'react'
import styled from 'styled-components'

// Dependencies
import Card from '@mui/material/Card';
import * as Sentry from '@sentry/react'
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Tooltip, Snackbar, Alert, CircularProgress } from '@mui/material';

// Icons
import CreateIcon from '@mui/icons-material/Create';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

// Type
import Texts from '../../models/Texts'
import { Room, Word } from '../../types'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface LegendProps {
  legend: Room,
  isEditable ?: boolean,
  loadingLogo ?: boolean,
  isConnectable: boolean,
  loadingBackground ?: boolean,
  handleConection: (daq: Room) => void,
  handleUpdateImage ?: (image: File, uuid: string, logo: boolean) => void,
  handleUpdate ?: (daq: Room) => void
}

type DaqImageProps = {
  image?: string | null,
}

const DaqImage = styled.div<DaqImageProps>`
  width: 10rem;
  height: 10rem;
  background-image: ${props => props.image ? `url(${props.image})` : "url('/images/avatar.png')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 9999px;
  bottom: 70%;
  left: 25%;
`

const Camera = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    display: none;
  }
  
  &:hover {
    background-color: rgba(0,0,0,0.6);
    
    & svg {
      display: block;
    }

  }
`
/**
 * Card de RookLegend
 * @param props contiene todas las propiedades necesarias descritas en LegendProps
 * @param props.legend la data a mostrar del daq
 * @param props.isEditable si se puede editar el daq con permisos o si esta en dashboard
 * no se puede editar
 * @param props.loadingLogo saber si se esta actualizado el logo
 * @param props.loadingBackground saber si se esta actualizado el background del daq
 * @param props.isConnectable saber si se puede conectar al daq
 * @param props.handleConection conectarse al daq por medio de guacamole
 * @param props.handleUpdateImage actualizar las imágenes del daq
 * @param props.handleUpdate actualizar la data del daq
 * @returns componente donde se muestra el daq con el botón de conexión
 */
const Legend: FC<LegendProps> = ({ 
  legend, isEditable, loadingLogo, loadingBackground, isConnectable,
  handleConection, handleUpdateImage, handleUpdate
}) => {

  // Hooks
  const { getTranslation } = useTranslation()

  // Referencia al input para hacer clic
  const inputFile = useRef<any>()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Para saber si es actualizar el logo o el background
  const [isLogo, setIsLogo] = useState(false)

  /**
   * Manejador de la imagen
   * @param e evento para recibir la imagen
   * @returns early return 
   */
   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) : void => {
    e.preventDefault()

    // Verificamos que exista la imagen
    if( !e.currentTarget.files ) return 

    // Sacamos el archivo
    const file = e.currentTarget.files.item(0)

    if ( !file ) {
      Sentry.captureMessage(
        `No se encontro la imagen profile: ${JSON.stringify(e.currentTarget.files)}`
      )
      return 
    }

    // Verificamos el tamaño del la imagen
    const size = (file.size / 1024 / 1024).toFixed(2)

    // Si es mayor a dos megas mandamos la alerta
    if ( Number(size) > 2 ) {
      setShowAlert( true )
      return 
    }

    // Si no mandamos la imagen
    if (handleUpdateImage)
      handleUpdateImage( e.currentTarget.files[0], legend.uuid, isLogo )

  }

  /**
   * Renderizar el botón o un loader
   * @returns la imagen a el loader
   */
  const renderImage = () : ReactElement => {
    
    if (loadingLogo) 
      return ( 
        <div className = "absolute" style = {{ bottom: '80%', left: '40%' }}>
          <CircularProgress 
            color = "secondary" 
            size = '5rem'
          /> 
        </div>
      )

    return (
      <button 
        type="button"
        onClick = { () => openInputFile(true) }
      >
        <DaqImage
          image = { legend.logo_image }
        >
          <Camera>
            <CameraAltIcon />
          </Camera>
        </DaqImage>
      </button>
    )
  }

  /**
   * Elementos para actualizar el background de la imagen
   * @returns el icono de la imagen
   */
  const renderBackgroundImage = () : ReactElement => {
    
    if (loadingBackground)
      return (
        <div 
          className="absolute top-one right-one rounded bg-gradient-to-br from-transparent to-gray-500 p-1"
        >
          <CircularProgress 
            color = 'primary' 
            size = '1.5rem'
          />
        </div>
      )

    return (
      <Tooltip title={ getTranslation(Texts.edit as Word) }>
        <button
          type = 'button'
          className = 'absolute top-one right-one rounded bg-gradient-to-br from-gray-500 to-transparent p-1'
          onClick = { () => openInputFile(false) }
          data-cy = 'btn-back-daq'
        >
          <CameraAltIcon />
        </button>
      </Tooltip>
    )

  }

  /**
   * Abrir el input de file
   * @param flag saber si es un logo(true) o fondo (false)
   */
  const openInputFile = (flag: boolean) : void => {
    inputFile.current.click()
    setIsLogo(flag)
  }

  const onClick = (daq: Room) : void => {
    
    if (handleUpdate) handleUpdate(daq)

  }

  return (
    <> 

      <input 
        data-cy = "image-daq"
        type="file" 
        id="image_admin" 
        name="image_admin" 
        accept="image/png, image/jpeg"
        className = "hidden"
        onChange = { handleFileChange }
        ref = { inputFile }
      />

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = 'error' 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getTranslation( Texts.profileImageSizeError as Word ) }
        </Alert>
      </Snackbar>

      <Card sx={{ maxWidth: 345 }}>
        <div className = 'relative'>
          <CardMedia
            component="img"
            height="140"
            image={ legend.background_image || '/images/image.png' }
            alt=""
            crossOrigin = 'anonymous'
          />

          { isEditable && renderBackgroundImage() }

        </div>
        <CardContent>
          <div className = 'relative'>

            { isEditable 
              ? (
                renderImage()
              ) 
              : (
                <DaqImage
                  image = { legend.logo_image }
                />
              ) 
            }

            <h3 className = "text-title text-center text-xl 2xl:text-2xl font-bold pt-20 capitalize">
              { legend.name }
            </h3>

            { isEditable && (
              <div className = 'absolute right-0 top-0'>
                <Tooltip 
                  title={ getTranslation(Texts.edit as Word) }
                >
                  <IconButton
                    onClick = { () => onClick(legend) }
                    data-cy = 'btn-edit-daq'
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <div className="flex items-center justify-center">
              <div className="w-3 h-3 bg-green-500 rounded-full mr-2" />
              <p className = "text-sm">
                { getTranslation(Texts.active as Word) }
              </p>
            </div>

            <div className="flex items-center justify-between mt-8 font-medium">
              <div className = "text-center">
                <h5 className = "uppercase text-xs">
                  { getTranslation(Texts.daqLocalIP as Word) }
                </h5>
                <p>
                  { legend.daq_ip || '' }
                </p>
              </div>
              <div className = "text-center">
                <h5 className = "uppercase text-xs">
                  { getTranslation( Texts.requestsLastUpdate as Word ) }
                </h5>
                <p>
                  { legend.updated || '-' }
                </p>
              </div>
            </div>

          </div>
        </CardContent>

        { isConnectable && (
          <CardActions>
            <div className = "flex justify-center w-full my-4">
              <button
                type = "button"
                className = "btn btn-default btn-outline btn-anim border border-primary px-8 py-2 rounded-full uppercase"
                onClick = { () => handleConection( legend ) }
              >
                <SettingsRemoteIcon 
                  color = "primary"
                />

                <p className = "btn-text text-primary">
                  { getTranslation( Texts.daqRemoteConection as Word ) }
                </p>
              </button>
            </div>
          </CardActions>
        )}
      </Card>
    </>
  )
}
export default Legend;