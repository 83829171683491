import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Dependencies
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Context
import UIState from './context/ui/UIState'

// App
import App from './App';

// Styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles/App.css';

// Initialize Sentry
Sentry.init({
  dsn: "https://96b5a5fe75e84cf297e1b56efac9dd2c@o1067051.ingest.sentry.io/6060339",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend: event => {
    if (window.location.hostname === 'localhost') return null

    return event
  }
});

// Adding theme to mui
const materialTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl:  1280,
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#e42823'
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem'
        }
      }
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          maxHeight: '13rem'
        }
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme = { materialTheme }>
      <UIState>
        <App />
      </UIState>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
