import React, { FC, ReactElement } from 'react'

// Icons
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

// Types
import { Clasification, Flow, Sorting } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { numberWithCommas } from '../../utils';

interface RankingListProps {
  sorting: Sorting,
  pageSize: number,
  currentPage: number,
  clasification: Clasification[],
  sort: (key: string) => void
}
 
/**
 * Tabla del ranking de usuarios
 * @param props contiene todas las propiedades necesarias descritas en RankingListProps
 * @param props.pageSize cantidad de usuarios a mostrar por página
 * @param props.clasification lista de usuarios o el ranking
 * @param props.currentPage página que esta viendo el usuario
 * @param props.sorting saber si hay alguna columna a la que se le dio clic y saber en que 
 * flujo esta si ascendente o descendente
 * @param props.sort función para ordenar la data de acuerdo a la columna
 * @returns Tabla del ranking de usuarios
 */
const RankingList: FC<RankingListProps> = ({ 
  clasification, pageSize, currentPage, sorting, sort 
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  /**
   * Renderizar icono en tabla
   * @param key saber si hay que mostrar el icono en la columna
   * @returns flecha así arriba o abajo
   */
  const renderIconSortIndicator = (key: string) : ReactElement => {
    
    if (key === sorting.key){

      if (sorting.flow === Flow.asc) return <ArrowDropUpRoundedIcon />
      
      if (sorting.flow === Flow.desc) return <ArrowDropDownRoundedIcon />
    
      return <></>
    }

    return <></>

  }

  return ( 
    <div className = "overflow-x-scroll lg:overflow-x-auto">
      <p
        className = "lg:bg-input-background p-2 mt-8 lg:rounded-tl-xl lg:rounded-tr-xl lg:border lg:border-table text-gray-450"
      >
        { getTranslationByKey('showing') } 1-{ clasification.length } { getTranslationByKey('of') } { clasification.length } { getTranslationByKey('registers') }
      </p>

      <table className="table-auto shadow-md w-full capitalize">
        <thead className = 'bg-input-background'>
          <tr>
            <th className="py-2 border border-table">
              #
            </th>
            <th className="py-2 border border-table">
              <button 
                type="button"
                onClick = { () => sort('user') }
              >
                { getTranslationByKey('user') }
                { renderIconSortIndicator('user') }
              </button>
            </th>
            <th className="py-2 border border-table">
              <button 
                type="button"
                onClick = { () => sort('total_sessions') }
              >
                { getTranslationByKey('reportsTotalSessions') }
                { renderIconSortIndicator('total_sessions') }
              </button>
            </th>
            <th className="py-2 border border-table">
              <button 
                type="button"
                onClick = { () => sort('kcal') }
              >
                { getTranslationByKey('trainingCalories') }
                { renderIconSortIndicator('kcal') }
              </button>
            </th>
            <th className="py-2 border border-table">
              <button 
                type="button"
                onClick = { () => sort('rookpoints') }
              >
                RookPoints
                { renderIconSortIndicator('rookpoints') }
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {clasification.map((position, index) => (
            <tr
              key = { position.user }
            >
              <td className="p-2 border border-table">
                { (index + 1) + (pageSize * (currentPage - 1)) }
              </td>
              <td className="p-2 border border-table normal-case">
                { position.user }
              </td>
              <td className="p-2 border border-table">
                { position.total_sessions }
              </td>
              <td className="p-2 border border-table">
                { numberWithCommas( position.kcal )}
              </td>
              <td className="p-2 border border-table">
                { numberWithCommas( position.rookpoints ) }
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  )
}
 
export default RankingList;