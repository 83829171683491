import { createMachine, assign, sendParent, send, spawn, DoneInvokeEvent } from "xstate";

// Dependencies
import moment from "moment";
import { AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import axiosClient from '../../../config/axios'

// Types
import { 
  Challenges, 
  ChallengeConfig, 
  Athletes, 
  Room, 
  CreateChallenge,
  Challenge,
  OptionSearchBarDrop,
  SearchIn,
  EditChallenge,
  DashboardGaming,
  UserChallenge,
  ChallengeTeam,
  ChallengeRoom
} from '../../../types'
import { dummyLinks, generateMeta } from "../../../utils";

type ExtraData = {
  users: Athletes,
  rooms: Room[],
  "challenge_users" ?: { data: UserChallenge[] }
  "challenge_teams" ?: { data: ChallengeTeam[] }
  "challenge_rooms" ?: { data: ChallengeRoom[] }
}

/**
 * page: Es el actor de la página que se esta visualizando
 * pageData: es el conjunto de páginas ya descargadas
 * pages: número de páginas este se afecta cuando se hace una paginacion con búsqueda
 * totalPages: número original de páginas
 * pageAlreadyExists: bandera que me indica si ya se descargo una página
 * searched: resultado de la búsqueda
 * focusChallenge: Challenge a editar
 * challengeConfig: contiene las modalidades y tipos de reto
 * editChallenge: data o body del request de update
 * createChallenge: data o body del request de post
 */
type GamingMachineContext = {
  page: any | null,
  pageData: any,
  pages: number,
  uuidBranch: string,
  totalPages: number,
  statusError: number,
  currentPage: number,
  rooms: null | Room[],
  users: Athletes | null,
  search: SearchIn | null,
  pageAlreadyExists: boolean,
  openModalChallenge: boolean,
  searched: Challenges | null,
  options: OptionSearchBarDrop[],
  focusChallenge: Challenge | null,
  dashboard: DashboardGaming | null,
  challengeConfig: ChallengeConfig | null,
  createChallenge: CreateChallenge | null,
  editChallenge: EditChallenge | null,
} 

/**
 * READY: cuando se terminó de descargar la data de la página y guardar el número de páginas
 * TOGGLE: cerrar/Abrir modal
 * HIDE: ocultar alertas basadas en el estado
 * SYNC: Actualizar la data del dashboard después de eliminar un reto
 */
type GamingMachineEvent = 
  | { type: 'PAGEING', page: number }
  | { type: 'READY', pages: number }
  | { type: 'INIT', uuidBranch: string }
  | { type: 'CREATE', challenge: CreateChallenge }
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'EDIT', data: Challenge }
  | { type: 'UPDATE', data: EditChallenge }
  | { type: 'TOGGLE' }
  | { type: 'HIDE' }
  | { type: 'SYNC' }
  
/**
 * deleteChallenge: Saber que reto eliminar
 */
type CreatePageMachineContext = {
  uuidBranch: string,
  page: number,
  challenges: Challenges | null,
  statusError: number,
  deleteChallenge: string
}

/**
 * HIDE: Decirle al padre que cambie de estado
 */
type CreatePageMachineEvent = 
  | { type: 'DELETE', uuid: string }
  | { type: 'APPEND', data: Challenge }
  | { type: 'REFRESH', data: Challenges }
  | { type: 'EDIT', data: Challenge }
  | { type: 'HIDE' }

/**
 * Descargar los retos
 * @returns lista de retos
 */
const fetchChallenges = async (context: CreatePageMachineContext) : Promise<Challenges> => {

  // Sacamos la página
  const { page, uuidBranch } = context

  // Definimos de que página vamos a descargar
  const url : string = page 
    ? `/api/v1/challenges?page=${page}&branch_uuid=${uuidBranch}` 
    : `/api/v1/challenges?branch_uuid=${uuidBranch}`

  const response = await axiosClient({
    url,
    baseURL: process.env.REACT_APP_GM
  })

  return response.data

}

/**
 * descargar las modalidades y tipo de reto
 * @returns descargar las modalidades y tipo de reto
 */
const prepareChallenges = async () : Promise<ChallengeConfig> => {

  const [ types, modality ] = await Promise.all([
    axiosClient({
      url: `/api/v1/challenge-types`,
      baseURL: process.env.REACT_APP_GM
    }),
    axiosClient({
      url: `/api/v1/challenge-modalities`,
      baseURL: process.env.REACT_APP_GM
    }),
  ]) 

  return {
    types: types.data.data,
    modalities: modality.data.data
  }

}

/**
 * Descargar usuarios y salas
 * @param context de la maquina pricipal
 * @returns devuelve los tipos de reto y modalidades
 */
const fetchUsersAndRooms = async (context: GamingMachineContext) : Promise<ExtraData> => {
  
  let { users, rooms } = context
  const { focusChallenge } = context

  let response: AxiosResponse<any> | null = null

  if (focusChallenge)
    // Recuperamos los teams, users and rooms del reto
    response = await axiosClient({
      url: `/api/v1/challenges/${focusChallenge.challenge_uuid}/search-user`,
      baseURL: process.env.REACT_APP_GM,
      method: 'GET',
    })

  if (!users || !rooms ) {
    const [ usersResponse, roomsResponse ] = await Promise.all([
      axiosClient.get('/api/v2/users?pagination=0'),
      axiosClient.get(`/api/v2/centers/${context.uuidBranch}`)
    ])

    const remoteRooms = roomsResponse.data.rooms.map(
      (room: Room) => room.type_room === 'remote' 
        ? room 
        : { ...room, name: `${room.name} - daq` }
    )

    users = usersResponse.data
    rooms = remoteRooms

  }

  return {
    users: users!,
    rooms: rooms || [],
    challenge_rooms: response ? response.data.challenge_rooms : null,
    challenge_teams: response ? response.data.challenge_teams : null,
    challenge_users: response ? response.data.challenge_users : null,
  }
}

/**
 * Crear un reto
 * @param context de la maquina principal
 */
const addNewChallenge = async (context: GamingMachineContext) : Promise<Challenge> => {
  
  const { createChallenge } = context

  if ( !createChallenge ) throw new Error('No existe el reto')

  const response = await axiosClient({
    url: '/api/v1/challenges',
    baseURL: process.env.REACT_APP_GM,
    method: 'POST',
    data: createChallenge
  })

  return response.data.data
}

/**
 * Eliminar un reto
 * @param context de la maquina hija
 */
const removeChallenge = async (context: CreatePageMachineContext) : Promise<any> => {
  
  const { deleteChallenge } = context

  if ( deleteChallenge === '' ) throw new Error('No existe el reto a eliminar')

  await axiosClient({
    url: `/api/v1/challenges/${deleteChallenge}`,
    baseURL: process.env.REACT_APP_GM,
    method: 'DELETE'
  })

}

/**
 * Buscar una lsita de retos
 * @param context de la maquina principal
 * @returns lista de retos
 */
const searchForChallenge = async (context: GamingMachineContext) : Promise<Challenges> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search, uuidBranch } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  // Hacemos la petición
  let url = `/api/v1/branch/${uuidBranch}/challenges?search_column=${search.search_column}&search=${search.search}`
  url = `${url}`

  const response = await axiosClient({
    url,
    baseURL: process.env.REACT_APP_GM,
  })

  return response.data

}

/**
 * Buscar retos con páginado
 * @param context de la maquina principal
 * @returns lista de retos
 */
const searchCompoundRequest = async (context: GamingMachineContext) : Promise<Challenges> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { currentPage, search } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  // Hacemos la petición
  let url = `/api/v1/challenges/search`
  url = `${url}?column=${search.search_column}&data=${search.search}`
  url = `${url}&page=${currentPage}`

  const response = await axiosClient({
    url,
    baseURL: process.env.REACT_APP_GM,
  })

  return response.data

}

/**
 * 
 * @param context de la maquina que controla esta vista
 * @returns 
 */
const updateChallenge = async (context: GamingMachineContext) : Promise<Challenge> => {
  
  const { editChallenge, focusChallenge  } = context

  if ( !editChallenge || !focusChallenge ) throw new Error('No existe el reto')

  const response = await axiosClient({
    url: `/api/v1/challenges/${focusChallenge.challenge_uuid}`,
    baseURL: process.env.REACT_APP_GM,
    method: 'PUT',
    data: editChallenge
  })

  return response.data.data

}

/**
 * Descargar la información del dashboard
 * @param context de la maquina
 * @returns data del dashboard
 */
const fetchMetrics = async (context: GamingMachineContext) : Promise<DashboardGaming> => {
  
  const { uuidBranch } = context

  const response = await axiosClient({
    url: `/api/v1/dashboard?branch_uuid=${uuidBranch}`,
    baseURL: process.env.REACT_APP_GM
  })

  return response.data.data

}

/**
 * Definición de la máquina que controla la vista principal es decir donde salen las tablas
 * de retos
 */
export default createMachine<GamingMachineContext, GamingMachineEvent>(
  {
    id: 'GamingMachine',
    initial: 'preparing',
    context: {
      pages: 0,
      page: null,
      users: null,
      rooms: null,
      options: [],
      search: null,
      pageData: {},
      totalPages: 0,
      statusError: 0,
      searched: null,
      uuidBranch: '',
      currentPage: 1,
      dashboard: null,
      editChallenge: null,
      focusChallenge: null,
      challengeConfig: null,
      createChallenge: null,
      pageAlreadyExists: false,
      openModalChallenge: false,
    },
    states: {
      login: {},
      loaded: {},
      metrics: {
        invoke: {
          id: 'fetchMetrics',
          src: fetchMetrics,
          onDone: {
            target: 'loaded',
            actions: assign({ dashboard: (_, event) => event.data })
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar las métricas del dashboard',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          } 
        }
      },
      preparing: {
        invoke: {
          id: 'prepareChallenges',
          src: prepareChallenges,
          onDone: {
            target: 'idle',
            actions: ['prepareOptions']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar los retos',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          } 
        },
      },
      idle: {
        entry: send({ type: 'PAGEING', page: 1 }),
      },
      loading: {
        always: [
          { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ],
        on: {
          READY: {
            target: 'metrics',
            actions: assign({ 
              pages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
              totalPages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
            })
          }
        }
      },
      extra: {
        // always: [
        //   { target: 'loaded', cond: 'isAlreadyExtraFetched' }
        // ],
        invoke: {
          id: 'fetchUsersAndRooms',
          src: fetchUsersAndRooms,
          onDone: {
            target: 'loaded',
            actions: [
              assign({
                rooms: (_, event) => event.data.rooms,
                users: (_, event) => event.data.users,
              }),
              'reAddMembers'
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar los retos',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          } 
        }
      },
      created: {
        type: 'final'
      },
      creating: {
        invoke: {
          id: 'addNewChallenge',
          src: addNewChallenge,
          onDone: {
            target: 'created',
            actions: [
              assign({ openModalChallenge: (context) => !context.openModalChallenge }),
              assign({ focusChallenge: (_, event) => event.data }),
              send((_, event) => ({ type: 'APPEND', data: event.data }), 
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'rejected',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo crear tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      searching: {
        invoke: {
          id: 'searchForChallenge',
          src: searchForChallenge,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedChallenges',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                currentPage: 1,
                page: Object.values(context.pageData)[0],
                pages: context.totalPages
              }
            })
          }
        }
      },
      lookingFor: {
        invoke: {
          id: 'searchCompoundRequest',
          src: searchCompoundRequest,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedChallenges',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      updated: {
        after: {
          3400: { target: 'metrics' }
        }
      },
      updating: {
        invoke: {
          id: 'updateChallenge',
          src: updateChallenge,
          onDone: {
            target: 'updated',
            actions: [
              assign({ openModalChallenge: (context) => !context.openModalChallenge }),
              send((_, event) => ({ type: 'APPEND', data: event.data }), 
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo editar el reto',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo editar el reto',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo editar el reto tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10,
                openGoalModal: false
              }

            })
          }
        }
      },
      rejected: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      INIT: {
        actions: assign({
          uuidBranch: (_, event) => event.uuidBranch
        })
      },
      PAGEING: {
        target: '.loading',
        actions: ['createPage']
      },
      TOGGLE: {
        target: 'extra',
        actions: [
          'resetFocusChallenge',
          assign({ 
            openModalChallenge: (context) => !context.openModalChallenge,
          })
        ],
      },
      CREATE: {
        target: 'creating',
        actions: assign({ createChallenge: (_, event) => event.challenge })
      },
      EDIT: {
        target: 'extra',
        actions: assign({ 
          focusChallenge: (_, event) => event.data,
          openModalChallenge: (context) => !context.openModalChallenge
        })
      },
      UPDATE: {
        target: 'updating',
        actions: assign({
          editChallenge: (_, event) => event.data
        })
      },
      HIDE: {
        target: '.loaded'
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
            ...context,
            search: event.data,
            currentPage: 1
          }))
      },
      SYNC: {
        target: '.metrics'
      }
    }
  },
  {
    actions: {
      prepareOptions: assign((context, _event: any) => {

        const event : DoneInvokeEvent<ChallengeConfig> = _event

        if (event.type !== 'done.invoke.prepareChallenges') return { ...context }

        return {
          ...context,
          challengeConfig: event.data,
          options: event.data.types.map(({ id, name }) => ({ key: `${id}`, value: name }))
        }

      }),
      createPage: assign((context, event) => {

        if (event.type !== 'PAGEING') return { ...context }

        if (context.search && context.search.search !== '-1')
            return {
              ...context,
              page: context.pageData.searchMachine,
              pageAlreadyExists: true,
              currentPage: event.page
            }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData[`${event.page}`]

        if (subPage) {
          return {
            ...context,
            page: subPage,
            pageAlreadyExists: true,
            currentPage: event.page
          }
        }

        // Otherwise, spawn a new subreddit actor and
        // save it in the subreddits object
        subPage = spawn( createPageMachine( event.page, 'loading', context.uuidBranch) )

        return {
          pageData: {
            ...context.pageData,
            [`${event.page}`]: subPage
          },
          page: subPage,
          pageAlreadyExists: false,
          currentPage: event.page
        }

      }),
      searchedChallenges: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Challenges> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.searchForChallenge" 
          && event.type !== 'done.invoke.searchCompoundRequest'
        ) 
          return context

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, 'loaded', context.uuidBranch) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      }),
      resetFocusChallenge: assign((context) => ({ ...context, focusChallenge: null })),
      reAddMembers: assign((context, _event: any) => {
        
        const event : DoneInvokeEvent<ExtraData> = _event

        if (event.type !== 'done.invoke.fetchUsersAndRooms' || !context.focusChallenge) 
          return { ...context }

        return {
          ...context,
          focusChallenge: {
            ...context.focusChallenge,
            challenge_rooms: event.data.challenge_rooms,
            challenge_teams: event.data.challenge_teams,
            challenge_users: event.data.challenge_users,
          }
        }

      })
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isUnauthorized: (context) => context.statusError === 401,
      isAlreadyExtraFetched: (context) => !!context.users && !!context.rooms,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      }
    }
  }
)

/**
 * Actor que controla la lista de retos
 * @param page la que se va a encargar de administrar este actor
 * @param initial estado inicial loading (va a descargar) o loaded (resultado de una búsqueda)
 * @param uuidBranch de la branch
 * @returns un actor 
 */
export const createPageMachine = (page: number, initial: string, uuidBranch: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvent >(
  {
    id: 'pageMachine',
    initial,
    context: {
      page,
      uuidBranch,
      statusError: 0,
      challenges: null,
      deleteChallenge: ''
    },
    states: {
      login: {},
      loaded: {},
      deleted: {},
      loading: {
        invoke: {
          id: 'fetchChallenges',
          src: fetchChallenges,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent(
                (context, _) => ({ type: 'READY', pages: context.challenges?.meta.last_page || 0 })
              )
            ]
          }, 
          onError: {
            target: 'failure',
            actions: [
              sendParent(
                (context, _) => ({ 
                  type: 'READY', pages: context.page >= 1 ? 0 : context.page - 1 })
              ),
              assign((context, event) => {

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: 'No se pudo descargar los retos',
                    level: Sentry.Severity.Error,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10,
                  challenges: {
                    data: [],
                    links: dummyLinks,
                    meta: generateMeta(context.page, context.page)
                  }
                }

              })
            ]
          } 
        }
      },
      deleting: {
        invoke: {
          id: 'removeChallenge',
          src: removeChallenge,
          onDone: {
            target: 'deleted',
            actions: [
              'filterChallenges',
              sendParent({ type: 'SYNC' })
            ]
          },
          onError: {
            target: 'rejected',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo crear al admin.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo crear tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      rejected: {},
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      DELETE: {
        target: '.deleting',
        actions: assign({ deleteChallenge: (_, event) => event.uuid })
      },
      APPEND: {
        target: '.loaded',
        actions: [ 'appendChallenge' ]
      },
      REFRESH: {
        target: '.loaded',
        actions: assign({ challenges: (_, event) => event.data })
      },
      EDIT: {
        actions: [
          sendParent(
            (_, event) => ({ 
              type: 'EDIT', data: event.data })
          )
        ]
      },
      HIDE: {
        target: '.loaded'
      }
    }
  },
  {
    actions: {
      preparingData: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Challenges> = _event

        if (event.type !== 'done.invoke.fetchChallenges') return { ...context }

        const challenges = event.data.data.map( challenge => {

          let status : 'active' | 'pending' | 'completed' = 'active'

          const currentDate = moment.utc(moment.utc().format())
          const challengeStart = moment.utc(challenge.start_at)
          const challengeEnd = moment.utc(challenge.finish_at)

          if (currentDate < challengeStart) status = 'pending'
          else if (currentDate > challengeEnd) status = 'completed'

          if ( challenge.challenge_rooms ){
            return {
              ...challenge,
              status,
              challenge_rooms: {
                data: challenge.challenge_rooms.data.map( room => ({
                    ...room, 
                    uuid: room.room_uuid || room.uuid
                  }))
              }
            }
          }
          
          return {
            ...challenge,
            status
          }

        })

        return  {
          ...context,
          challenges: {
            ...event.data,
            data: challenges
          },
        }

      }),
      filterChallenges: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<any> = _event

        // Verificamos el evento sea de eliminar
        if (event.type !== 'done.invoke.removeChallenge') return { ...context }

        // Verificamos que existan los retos
        if (!context.challenges) return { ...context, deleteChallenge: '' }

        const newChallenges = context.challenges.data.filter( 
          c => c.challenge_uuid !== context.deleteChallenge 
        )
        
        return {
          ...context,
          deleteChallenge: '',
          challenges: {
            ...context.challenges,
            data: newChallenges
          }
        }

      }),
      appendChallenge: assign((context, event) => {

        if (!context.challenges || event.type !== 'APPEND') return { ...context }

        const { data } = context.challenges

        // Buscamos si ya existe el reto
        const exists = data.find((ch) => ch.challenge_uuid ===  event.data.challenge_uuid)

        if (!exists) {
          return {
            ...context,
            challenges: {
              ...context.challenges,
              data: [
                event.data,
                ...data
              ]
            }
          }
        }

        const newChallenges = data.map(
          ch => ch.challenge_uuid === event.data.challenge_uuid ? event.data : ch
        )

        return {
          ...context,
          challenges: {
            ...context.challenges,
            data: newChallenges
          }
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401,
    }
  }
)