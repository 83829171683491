import React, { FC } from 'react'

// Dependencies
import { Drawer } from '@mui/material'

// Icons
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Types
import { TeamMetric } from '../../../../types';

// Components
import GroupBody from './GroupBody';
import GroupTitle from './GroupTitle';
import { RBox } from '../../../utils/RookLayout';
import { RButton, RButtonClose } from '../../../utils/RookButtons';
import GenericTable from '../../../utils/GenericTable';
import FreeBody from '../FreeBody';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

interface GroupDetailProps {
  isFree ?: boolean 
  open: boolean,
  teamName: string,
  goals: { earned: number, total: number }
  percentage: number,
  goalList: TeamMetric[],
  rookpoints ?: number
  onClose: () => void,
  handleMembers: () => void
}
 
const GroupDetail: FC<GroupDetailProps> = ({ 
  open, teamName, goals, percentage, goalList, isFree = false, rookpoints = 0, 
  onClose, handleMembers
}) => {

  const { getTranslationByKey } = useTranslation()

  /**
   * It closes the modal and then after 500ms it calls the handleMembers function.
   */
  const handleClick = () : void => {
    onClose()
    setTimeout(() => { 
      handleMembers()
    }, 500)
  }

  return ( 
    <Drawer
      anchor='right'
      open = { open }
      onClose = { onClose }
    >

      <RBox className='justify-between items-center px-5 py-3 bg-card-body'>

        <h1 className='uppercase font-bold'>
          Información del equipo
        </h1>

        <RButtonClose onClick={ onClose }/>

      </RBox>

      <main className='py-5 px-8'>
        <GroupTitle 
          fullRounded
          title = { teamName }
          position={1}          
        />

        { isFree 
          ? (
            <FreeBody
              rounded
              rookpoints={rookpoints}
            />
          ) 
          : (
            <GroupBody 
              fullRounded
              goals={ goals } 
              percentage={percentage}          
            />
          ) 
        }

        { isFree 
          ? (
            <GenericTable
              withoutStyles
              normalTableContainerClassName='bg-input-background px-0.5 rounded-lg max-h-72 md:max-h-80 2xl:max-h96 p-1'
              headers={[getTranslationByKey('goal'), getTranslationByKey('progress')]} 
              body={ goalList } 
              bodyKeys={['goal_type', 'accumulated']} 
            />
          ) 
          : (
            <GenericTable
              withoutStyles
              normalTableContainerClassName='bg-input-background px-0.5 rounded-lg max-h-72 md:max-h-80 2xl:max-h96 p-1'
              specialAt='goal_type'
              headers={[
                getTranslationByKey('goal'), 
                getTranslationByKey('gamingGoalModalGoal'), 
                getTranslationByKey('level')
              ]}
              body={ goalList } 
              bodyKeys={['goal_type', 'target', 'level']} 
              specialAtElement = {({completed, ...rest}) => (
                <RBox className='w-full'>
                  <CheckCircleOutlineIcon
                    className={ completed ? 'visible' : 'invisible' }
                    color = 'primary' 
                  />
                  <p className='ml-1'>{rest.goal_type}</p>
                </RBox>
              )}   
            />
          ) 
        }


        <RBox className='justify-center mt-8'>
          <RButton
            onClick={ handleClick }
          >
            <PeopleIcon sx = {{ marginRight: '1rem' }}/>
            { getTranslationByKey('team') }
          </RButton>
        </RBox>

      </main>

    </Drawer>
  );
}
 
export default GroupDetail;