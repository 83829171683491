import React, { FC, CSSProperties } from 'react';

interface RookTooltipTitleProps {
  className ?: string
  sx ?: CSSProperties,
  style ?: CSSProperties,
  title : string
}
 
const RookTooltipTitle: FC<RookTooltipTitleProps> = ({ 
  title, className, sx, style 
}) => {
  return (
    <h1
      className = { className || '' }
      style = {{ ...style, ...sx }}
    >
      {title}
    </h1>
  );
}
 
export default RookTooltipTitle;