import React, { FC, useContext } from 'react'

// Dependencies
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

// Validation
import { Formik } from "formik";

// Context
import UIContext from '../../context/ui/UIContext'

// Models and types
import Texts from '../../models/Texts'
import { Word, Coach, RoomBody, Room } from '../../types'

// Components
import { InputForm, InputTextArea, InputError, InputSelect } from '../utils/RookFormInputs';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validations
import validationSchema from '../../validations/ValidationsCreateRoom'

// Utils
import { capitalize } from '../../utils';

interface RemoteModalProps {
  openModal: boolean,
  coaches: Coach[],
  loading: boolean,
  room ?: Room | null
  setOpenModal: () => void,
  save: (data: RoomBody, isEdit: boolean) => void,
}
 
/**
 * Modal para actualizar/crear el room de remote
 * @param props contiene todas las propiedades necesarias descritas en RemoteModalProps
 * @param props.openModal saber si esta abierto el modal
 * @param props.coaches lista de entrenadores que se ponen en drop
 * @param props.loading saber si se esta creando/actualizando
 * @param props.room data del room en para editar
 * @param props.setOpenModal función para cerrar el modal
 * @param props.save función para guardar el room
 * @returns formulario con el modal para editar/crear un room de remote
 */
const RemoteModal: FC<RemoteModalProps> = ({ 
  openModal, coaches, loading, room, setOpenModal, save 
}) => {

  // Hooks para traducir
  const { getTranslation } = useTranslation()

  // Context
  const { currentUser } = useContext( UIContext )

  const getCoach = () : string => {
    
    if ( !room ) return '123'

    if (room.couches_asigned && room.couches_asigned.length > 0) {
      return room.couches_asigned[0].uuid
    }

    return '123'

  }

  const handleSubmit = (values: any) : void => {
    save(
      {
        'branch_uuid': '',
        'room_name': values.room_name,
        'create_by': currentUser?.uuid || '',
        'type_room': 'remote',
        'remote_status': 1,
        description: values.description,
        status: Number(values.status),
        couches: [
          { uuid: values.coach }
        ]
      },
      !!room
    )
  }

  return ( 
    <Dialog
      onClose = { () => setOpenModal() }
      open = { openModal }
    >
      <DialogTitle>
        { room 
          ? getTranslation( Texts.remoteEditRoom as Word ) 
          : getTranslation( Texts.remoteAddRoom as Word ) 
        }
      </DialogTitle>

      <Formik 
        initialValues={{
          room_name: room ? capitalize(room.name) : '',
          coach: getCoach(),
          description: (room && room.description) ? room.description : '',
          status: room ? Boolean(room.status) : true,
        }} 
        validationSchema = { validationSchema }
        enableReinitialize
        onSubmit={ handleSubmit }      
      >

        { props => (

          <form 
            className = "py-5 px-6"
            onSubmit = { props.handleSubmit }
          >

            <div className="">
              <InputForm
                marginTop = 'mt-0'
                background = "bg-card-body"
                type='text'
                placeholder={ getTranslation( Texts.remoteModalNamePlaceholder as Word ) }
                id='room_name'
                title={ getTranslation( Texts.remoteModalName as Word ) }
                value= { props.values.room_name }
                handleChange={ props.handleChange }
                handleBlur={ props.handleBlur }
                isError = {( props.touched.room_name && !!props.errors.room_name )}
                errorText={ props.errors.room_name &&
                  getTranslation( Texts[props.errors.room_name] as Word)
                }
              />
            </div>

            <InputSelect
              name = 'coach'
              label = { getTranslation( Texts.remoteModalCoach as Word ) }
            >
              <MenuItem 
                // key = { coach.label }
                value = '123'
              >
                { getTranslation( Texts.remoteModalCoach as Word ) }
              </MenuItem>

              {coaches.map((coach: Coach) => (
                <MenuItem 
                  key = { coach.uuid }
                  value = { coach.uuid }
                >
                  { capitalize(coach.name || '-') }
                </MenuItem>
              ))}
            </InputSelect>

            <InputTextArea 
              placeholder={getTranslation(Texts.centerGeneralDataCenterDescriptionPH as Word)}
              id='description'
              background = "bg-card-body"
              title={ getTranslation( Texts.centerGeneralDataCenterDescription as Word ) }
              value={props.values.description}
              handleChange={props.handleChange}
              handleBlur={props.handleBlur} 
              isError={(props.touched.description && !!props.errors.description )} 
              errorText={ props.errors.description &&
                getTranslation( Texts[props.errors.description] as Word)
              }    
            />

            <div className = "flex flex-col mt-8">
              <label
                htmlFor="status"
                // className = {`uppercase mb-1 ${(props.touched.sex && props.errors.sex) && 'text-red-500'}`}
                className = "uppercase mb-1"
              >
                { getTranslation( Texts.status as Word ) }
              </label>

              <div className="flex items-center">

                <p>
                  { getTranslation( Texts.inactive as Word ) }
                </p>

                <Switch
                  id = 'status'
                  name = 'status' 
                  data-cy = 'btn-status'
                  checked = { props.values.status }
                  onChange = { props.handleChange }
                />

                <p>
                  { getTranslation( Texts.active as Word ) }
                </p>

              </div>

              { (props.touched.status && props.errors.status )
                  && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { props.errors.status }
                    </InputError>
                  ) 
              }
            
            </div>

            <div className="flex flex-col lg:justify-between md:flex-row items-center mt-8 w-full">
              <button 
                type="button"
                data-cy = 'btn-cancel-remote'
                className = "uppercase bg-title px-8 py-2 rounded-3xl mt-2 md:mt-0 w-full lg:w-max md:mr-4 lg:mr-0"
                onClick = { () => setOpenModal() }
              >
                { getTranslation( Texts.cancel as Word ) }
              </button>

              { loading 
                ? (
                  <div className = "flex justify-center w-48">
                    <CircularProgress />
                  </div>
                ) 
                : (
                  <button 
                    type="submit"
                    className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 md:mt-0 w-full lg:w-max"
                  >
                    { room 
                      ? getTranslation( Texts.save as Word )
                      : getTranslation( Texts.remoteModalAadd as Word ) 
                    }
                  </button>
                ) 
              }

              
            </div>

          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default RemoteModal;