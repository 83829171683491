import React, { FC } from 'react'

// Dependencies
import shortid from 'shortid';

// Types
import { Medal } from '../../../types';

// Components
import { RBox } from '../../utils/RookLayout';

// Assets
import gold from '../../../assets/images/gold.png'
import silver from '../../../assets/images/plate.png'
import cooper from '../../../assets/images/cooper.png'

interface MedalsProps {
  medals: Medal[]
}
 
const Medals: FC<MedalsProps> = ({ medals }) => {
  return ( 
    <>
      {medals.map( medal => (
        <RBox
          key = { shortid.generate() }
          className = 'items-center gap-2'
        >
          { medal.medals_type === 'gold' && (
            <img src={gold} alt="gold medal" />
          )}
  
          { medal.medals_type === 'silver' && (
            <img src={silver} alt="gold medal" />
          )}
  
          { medal.medals_type === 'bronce' && (
            <img src={cooper} alt="gold medal" />
          )}
  
          { medal.medals_count }
        </RBox>
      ))}
    </>
  );
}
 
export default Medals;