import * as Yup from 'yup'

export default Yup.object().shape({
  goalType: Yup.number()
		.required('gamingChallengeGoalModalGoalTypeRequired')
		.moreThan(0, 'gamingChallengeGoalModalGoalTypeRequired'),
  goal: Yup.number()
		.required('gamingChallengeGoalModalGoalRequired')
		.moreThan(0, 'gamingChallengeGoalModalGoalValid'),
  points: Yup.number()
		.required('gamingChallengeGoalModalPointsRequired')
		.moreThan(0, 'gamingChallengeGoalModalPointsValid'),
})

export const freeGoalValidation = Yup.object().shape({
	goalType: Yup.number()
		.required('gamingChallengeGoalModalGoalTypeRequired')
		.moreThan(0, 'gamingChallengeGoalModalGoalTypeRequired'),
})