import React, { FC } from 'react'

// Dependencies
import { useHistory } from 'react-router-dom'

// Types
import { SearchIn } from '../../../types';

// Components
import Range from '../../reports/Range';
import SearchBar from '../../utils/SearchBar';
import { RBox } from '../../utils/RookLayout';
import { RButtonBack } from '../../utils/RookButtons';

interface BoardBarProps {
  handleSearch: (search: SearchIn) => void
}
 
const BoardBar: FC<BoardBarProps> = ({ handleSearch }) => {

  // Nav
  const history = useHistory()

  return ( 
    <RBox 
      type = 'grid'
      className='grid-cols-auto-fit gap-4'
    >

      <RBox className='flex-wrap md:flex-nowrap items-center gap-4'>

        <RButtonBack 
          action={ () => history.goBack() }
        />

        <div className="invisible">
          <Range
            loading = { false }
            withText = { false }
            searchPeriod={ () => {}}
          />
        </div>

      </RBox>


      <RBox className='justify-end'>
        <SearchBar
          options = {[
            {
              key: 'name',
              title: "usersNameInput"
            },
            {
              key: 'pseudonym',
              title: "usersPseudonymInput"
            },
            {
              key: 'email',
              title: "loginEmail"
            },
          ]}
          action={ handleSearch }
        />
      </RBox>

    </RBox>
   );
}
 
export default BoardBar;