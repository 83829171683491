/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'

// Icons
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

// Components
import ChallengeAction from './ChallengeAction';
import { RButtonLoading } from '../../utils/RookButtons';

// Hooks
import useTranslation from '../../../hooks/useTranslation'

interface ChallengeActionsProps {
  deleteLoading: boolean,
  handleViewGroup: () => void,
  handleViewTargets: () => void,
  handleEditChallenge: () => void,
  handleDeleteChallenge: () => void,
}

/**
 * Componente de la fila básica en el configurador
 * @param props contiene todas las propiedades necesarias descritas en
 * ChallengeActionsProps
 * @param props.deleteLoading saber si esta eliminando
 * @param props.handleViewGroup función para ir al detalle del reto
 * @param props.handleEditChallenge función para editar un reto
 * @param props.handleDeleteChallenge función para eliminar un reto
 * @returns Componente de la fila básica en el configurador
 */
const ChallengeActions: FC<ChallengeActionsProps> = ({ 
  deleteLoading, handleDeleteChallenge, handleViewGroup, handleEditChallenge, handleViewTargets
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className="flex gap-2 flex-wrap lg:flex-nowrap items-center justify-center px-2">

      <ChallengeAction
        color = 'bg-blue-500'
        title = { getTranslationByKey('gamingListToolTipoViewGroup') }
        onClick = { handleViewGroup }
      >
        <RemoveRedEyeIcon />
      </ChallengeAction>
      
      <ChallengeAction
        color = 'bg-white'
        title = { getTranslationByKey('gamingListToolTipoViewGoals') }
        onClick = { handleViewTargets }
      >
        <TrackChangesIcon 
          color = 'secondary'
        />
      </ChallengeAction>

      <ChallengeAction
        color = 'bg-green-500'
        title = { getTranslationByKey('edit') }
        onClick = { handleEditChallenge }
      >
        <CreateIcon />
      </ChallengeAction>

      <RButtonLoading
        color = 'secondary'
        loading = { deleteLoading }
      >
        <ChallengeAction
          color = 'bg-red-500'
          title = { getTranslationByKey('delete') }
          onClick = { handleDeleteChallenge }
        >
          <DeleteIcon />
        </ChallengeAction>
      </RButtonLoading>

    </div>
  );
}
 
export default ChallengeActions;