import React, { FC, useEffect, useState, ChangeEvent } from 'react'

// Dependencies
import SelectInput from 'react-select'
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// Validation
import { Formik } from "formik";

// icons
import CloseIcon from '@mui/icons-material/Close';

// types
import { Goal, GoalType } from '../../../types';

// Components
import { InputForm, InputError} from '../../utils/RookFormInputs';

// Hooks
import useTranslation from '../../../hooks/useTranslation'

// Validations
import validationSchema, { freeGoalValidation } from '../../../validations/ValidationsGoal'

// Styles
import createChallengeStyles from '../styles/CreateChallengeStyles'

interface GoalModalProps {
  isFree: boolean
  open: boolean,
  loading: boolean,
  edit: Goal | null,
  loadingExtra: boolean,
  goalTypes: GoalType[]
  onClose: () => void
  onSubmit: (values: any) => void
}

/**
 * Modal para crear/editar un objetivo
 * @param props contiene todas las propiedades necesarias descritas en
 * GoalModalProps
 * @param props.open saber si mostrar el pop up
 * @param props.loading saber si está esperando respuesta del server
 * @param props.loadingExtra saber si están descargando la lista de tipos de objetivos
 * @param props.goalTypes listado de tipos de objetivos
 * @param props.edit Objetivo a editar
 * @param props.onClose función que se ejecuta cuando se esta cerrando el modal de 
 * @param props.onSubmit función que se ejecuta para crear/editar un objetivo
 * @returns Modal para crear/editar un objetivo
 */
const GoalModal: FC<GoalModalProps> = ({
  open, loading, loadingExtra, goalTypes, edit, isFree,
  onClose, onSubmit 
}) => {

  // Saber que tipo de objetivo es
  const [goalTypeID, setGoalTypeID] = useState(0)
  const [previousGoalType, setPreviousGoalType] = useState<GoalType | null>(null)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  useEffect(() => {
    
    if (!open) setPreviousGoalType(null)

  }, [open])

  useEffect(() => {
    
    if (goalTypes.length > 0 && edit) {

      const bridge = goalTypes.find( type => type.name === edit.goal_type )

      if (bridge) {
        setGoalTypeID( bridge.id )
        setPreviousGoalType(bridge)
      }

    }

  }, [goalTypes, edit])

  const handleSubmit = (values: any) : void => {
    onSubmit(values)
  }

  const handleNumberChange=(e: ChangeEvent<HTMLInputElement>):ChangeEvent<HTMLInputElement> => {
    
    if (!e.target.value) return e

    const newEvent = e

    if (!Number.isNaN(Number(e.target.value)))
      newEvent.target.value = Math.round(Number(e.target.value)).toString()

    return newEvent
  }

  return ( 
    <Dialog
      fullWidth
      maxWidth = 'sm'
      open = { open }
      onClose ={ onClose }
    >

      <div className='flex justify-between items-center p-5'>
        <h2
          className='text-lg font-bold tracking-wider'
        >
          { edit 
            ? getTranslationByKey('gamingChallengeGoalEdit')
            : getTranslationByKey('gamingGoalCreateModalTitle') 
          }
        </h2>
        <IconButton
          onClick = { () => onClose() }
        >
          <CloseIcon />
        </IconButton>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          goalType: goalTypeID,
          goal: edit ? edit.meta : 0,
          points: edit ? edit.points : 0,
        }}
        onSubmit={ values => handleSubmit(values) }
        validationSchema = { isFree ? freeGoalValidation :validationSchema } 
        >

        { props => (
          
          <form 
            className='p-8' 
            onSubmit = { props.handleSubmit }
          >

            <div className="flex flex-col">

              <div >

                <label
                  htmlFor="role"
                  className = "uppercase mb-2 block"
                >
                  { getTranslationByKey('gamingGoalModalTypeGoal') }
                </label>

                <SelectInput
                  isDisabled = { !!edit }
                  value = { previousGoalType }
                  isLoading = { loadingExtra }
                  options = { goalTypes }
                  styles = { createChallengeStyles }
                  placeholder = { 
                    edit && props.values.goalType === 0 
                    ? edit.goal_type 
                    : getTranslationByKey('choose') 
                  }
                  getOptionValue = { options => `${options.id}` }
                  getOptionLabel = { options => options.name }
                  onChange={
                    option => {
                      props.handleChange({ target:{ 
                        name: 'goalType', 
                        value: option ? option.id : 0 
                      }})
                      setPreviousGoalType(option)
                    }
                  }
                  noOptionsMessage = { 
                    () => getTranslationByKey('gamingGoalModalChallengeConfigNoOptions') 
                  }
                />

                { props.errors.goalType && (
                  <InputError
                    colorLabel = "text-red-500"
                  >
                    { getTranslationByKey(props.errors.goalType) }
                  </InputError>
                )}

              </div>


              <InputForm
                type= "number"
                placeholder= { getTranslationByKey('gamingGoalModalGoal') }
                id= "goal"
                inputMode='decimal'
                title= { getTranslationByKey('gamingGoalModalGoal') }
                background = "bg-card-body"
                marginTop = {`mt-10 ${isFree && 'invisible'}`}
                value={ props.values.goal }
                handleChange={e => props.handleChange(handleNumberChange(e)) }
                handleBlur={e => props.handleBlur(handleNumberChange(e))}
                max= { 99999999 }
                isError={(props.touched.goal && !!props.errors.goal )}
                errorText={ props.errors.goal &&
                  getTranslationByKey(props.errors.goal)
                }
                steps = '1'
              />

              <InputForm
                type= "number"
                placeholder= 'Meta'
                id= "points"
                inputMode='numeric'
                title= { getTranslationByKey('gamingGoalModalPoints') }
                background = "bg-card-body"
                marginTop = {`mt-10 ${isFree && 'invisible'}`}
                value={ props.values.points }
                handleChange={e => props.handleChange(handleNumberChange(e)) }
                handleBlur={e => props.handleBlur(handleNumberChange(e))}
                isError={(props.touched.points && !!props.errors.points )}
                steps='1'
                errorText={ props.errors.points &&
                  getTranslationByKey(props.errors.points)
                }
              />

            </div>

            <div className = "flex flex-col md:flex-row gap-4 md:justify-around mt-12">

              <button
                type = "button"
                className = "uppercase bg-secondary-background px-8 py-2 rounded-3xl w-full md:w-max"
                onClick = { () => onClose() }
              >
                { getTranslationByKey('cancel') }
              </button>

              { loading 
                ? (
                  <CircularProgress />
                ) 
                : (
                  <button
                    type = "submit"
                    className = "uppercase bg-primary px-8 py-2 rounded-3xl w-full md:w-max"
                  >
                    { edit 
                      ? getTranslationByKey('save') 
                      : getTranslationByKey('gamingGoalModalCreate') 
                    }
                  </button>
                )
              }


            </div>
            
          </form>

        )}

      </Formik>
    </Dialog>
  );
}
 
export default GoalModal;