import React, { FC, useEffect, useState } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useActor } from '@xstate/react';
import Tooltip from '@mui/material/Tooltip'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Snackbar, Alert, AlertColor, capitalize } from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import TimelineIcon from '@mui/icons-material/Timeline';
import LockOpenIcon from '@mui/icons-material/LockOpen';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Tipos
import { Athlete, Word } from '../../types';
import Texts from '../../models/Texts';

interface UsersListProps {
  service: any,
  isEditable: boolean,
  isRemovable: boolean,
  showMetrics: boolean
}

/**
 * Lista de usuarios
 * @param props contiene todas las propiedades necesarias descritas en UsersListProps
 * @param props.service actor que controla la vista
 * @param props.isEditable si se puede editar los usuarios con permisos
 * @param props.isRemovable si se puede editar los usuarios con permisos
 * @param props.showMetrics si se puede ver las métricas de los usuarios con permisos
 * @returns Lista de usuarios
 */
const UsersList: FC<UsersListProps> = ({ service, isEditable, isRemovable, showMetrics }) => {

  // Machine prefetched
  const [current, send] = useActor( service ) 

  // Sacamos el context y el estado de la maquina
  const { context, value } : any = current

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Navegación
  const history = useHistory()

  // useEffect para saber cuando enviar al login
  useEffect(() => {
    
    if (value === 'removed' || value === 'aborted' || value === 'authorized') 
      setShowAlert(true)
    else setShowAlert(false)

    if (value === 'login') history.push("/login")

  }, [value])

  /**
   * Manejar la acutalización de un usuario
   * @param uuid del usuario a actualizar
   * @returns early return
   */
  const handleUpdate = (uuid: string) : void => {

    if (uuid === '') return

    send({
      type: 'UPDATE',
      data: uuid
    }) 

  }

  /**
   * Manejar la desvinculación de un usuairo
   * @param uuid del usuario a eliminat
   * @returns early return
   */
  const handleDeleteUser = (uuid: string, name: string) : void => {
    
    if (uuid === '') return

    Swal.fire({
      title: `${ getTranslation( Texts.userDeleteConfirmation as Word ) } ${name}?`,
      text: getTranslation( Texts.userContentConfirmation as Word ),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslation( Texts.userConfirmButtonConfirmation as Word ),
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        send({
          type: 'DELETE',
          data: uuid
        })
      }
    })
  }

  /**
   * Manejar la desvinculación de un usuairo
   * @param uuid del usuario a eliminat
   * @returns early return
   */
  const handleUnbanUser = (uuid: string, name: string) : void => {
    
    if (uuid === '') return

    Swal.fire({
      title: `${ getTranslation( Texts.userUnbanConfirmation as Word ) } ${capitalize(name)}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslation( Texts.userConfirmButtonUnban as Word ),
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        send({
          type: 'UNBAN',
          data: uuid
        })
      }
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (value) {
      case 'removed': 
      case 'authorized': 
        return 'success'
      case 'aborted': return 'error'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (value) {
      case 'removed': 
        return getTranslation( Texts.userSucessAlert as Word )

      case 'authorized': 
        return getTranslation( Texts.userAuthAlert as Word )

      case 'aborted': 
        return getTranslation( Texts.generalError as Word )

      default: return getTranslation( Texts.generalError as Word )
    }
  }

  const getSex = (sex: string) : string => sex === 'male' ? 'M' : 'F'

  const getLastName = (lastName: string | undefined) : string => {

    if (!lastName) return '-'

    return lastName.length > 30 ? `${lastName.substring(0, 30)}...` : lastName
  }

  return value === 'failure' ? (

    <div className = "flex flex-col h-full justify-center items-center">
      <h1 className = "uppercase text-center font-bold">
        { getTranslation( Texts.userGetListError as Word ) }
      </h1>
    </div>

  ) : ( 

    <>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 1000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <div className = "overflow-x-scroll lg:overflow-x-auto">
        <table className = "table-auto shadow-md mt-10 w-full capitalize">
          <thead className = "bg-input-background">
            <tr>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.usersNameInput as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.userTableHeaderLastName as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.usersPseudonymInput as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.loginEmail as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.usersSexInput as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.usersBirthdayInput as Word ) }
              </th>
              {/* { (isEditable || isRemovable || showMetrics) && ( */}
                <th className = "py-2 border border-table">
                  { getTranslation( Texts.userTableActions as Word ) }
                </th>
              {/* )} */}
            </tr>
          </thead>
          <tbody>

            { ( value !== 'loading' && context.users ) && (
              <>
                {context.users.data.map((user: Athlete) => (
                  <tr
                    key = { user.user_uuid }
                    className = { user.banned_user === 1 ? 'text-title' : 'text-white' }
                  >
                    <td className = "p-2 border border-table">
                      { user.name || '-'}
                    </td>
      
                    <td className = "p-2 border border-table">
                      { getLastName(user.lastName) }
                    </td>
      
                    <td className = "p-2 border border-table">
                      { user.pseudonym || '-'}
                    </td>
      
                    <td className = "p-2 border border-table normal-case">
                      { user.email.length > 45
                        ? `${user.email.substring(0, 45)}...`
                        : user.email
                      }
                    </td>
      
                    <td className = "p-2 border border-table">
                      { getSex(user.sex || '-')}
                    </td>
      
                    <td className = "p-2 border border-table">
                      { user.birthday || '-'}
                    </td>
      
                    <td className = "p-2 border border-table">
                      <div 
                        className="flex flex-col lg:flex-row justify-between 2xl:justify-evenly items-center"
                      >
                        { showMetrics && (
                          <div
                            className = "rounded-full bg-blue-500 text-xs 2xl:text-base"
                          >
                            <Tooltip 
                              title = {getTranslation( Texts.userStatisticsTooltip as Word )}
                              placement = "top"
                            >
                              <IconButton
                                disabled = { value === 'updating' || value === 'removing' }
                                onClick = { () => send({ type: 'TRANSITION', data: user })}
                              >
                                <TimelineIcon
                                  fontSize = 'small'
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}

                        { isEditable && (
                          <div 
                            className = "rounded-full bg-green-500 text-xs 2xl:text-base my-4 lg:my-0 lg:mx-2.5"
                          >
                            <Tooltip
                              title = {getTranslation( Texts.userEditTooltip as Word )}
                              placement = "top"
                            >
                              <IconButton
                                disabled = { value === 'updating' || value === 'removing'}
                                onClick = { () => handleUpdate(user.user_uuid || '') }
                              >
                                <CreateIcon
                                  fontSize = 'small'
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}

                        { isRemovable && (
                          (value === 'removing' && context.uuid === user.user_uuid)
                            ? (
                              <CircularProgress color = "secondary"/>
                            ) 
                            : (
                              <div
                                className = "rounded-full bg-red-600 text-xs 2xl:text-base"
                              >
                                <Tooltip 
                                  title = {getTranslation( Texts.userDeleteTooltip as Word )}
                                  placement="top"
                                >
                                  <IconButton
                                    disabled = { value === 'updating' }
                                    onClick = { () => handleDeleteUser(user.user_uuid || '', user.name) }
                                  >
                                    <DeleteIcon
                                      fontSize = 'small'
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) 
                        )}

                        { user.banned_user === 1 && (

                          (value === 'authorizing' && context.uuid === user.user_uuid)
                          ? (
                            <CircularProgress color = "primary"/>
                          ) 
                          : (
                            <div
                              className = "rounded-full bg-indigo-600 text-xs 2xl:text-base lg:mr-2.5"
                            >
                              <Tooltip 
                                title = { getTranslation( Texts.userUnbanTooltip as Word ) }
                                placement="top"
                              >
                                <IconButton
                                  disabled = { value === 'updating' }
                                  onClick = { () => handleUnbanUser(user.user_uuid || '', user.name) }
                                >
                                  <LockOpenIcon
                                    fontSize = 'small'
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) 
                        )}
                          
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}

          </tbody>
        </table>
      </div>
    </>
  )
}
 
export default UsersList;
