import React, { FC, useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

// Context
import UIContext from '../../context/ui/UIContext'
import { AuthData, LocalStorageKey } from '../../types';

export type ProtectedRouteProps = {
  failed ?: boolean
} & RouteProps;

/**
 * Es una ruta privada para evitar que se ingrese al sistema sin estar loggeado
 * @param props contiene todas las propiedades necesarias descritas en ProtectedRouteProps
 * @returns una ruta privada
 */
const PrivateRoute: FC<ProtectedRouteProps> = ({failed = false, ...routeProps}) => {

  // Sacamos si esta autenticado
  const { authenticated } = useContext( UIContext )

  const getAuth = () : boolean => {
    
    if (failed) return false

    if (authenticated) return true

    // Leemos
    const auth : AuthData = JSON.parse(localStorage.getItem(LocalStorageKey.auth) || 'null')

    if (auth) return true

    return false

  }

  return getAuth() 
    ? (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route { ...routeProps } />
    )
    : (
      <Redirect to = "/login" />
    )
  
}
 
export default PrivateRoute;