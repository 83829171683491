import React, { FC, useState, useEffect, useContext, ChangeEvent, useMemo } from 'react';

// Dependencies
import 'moment-timezone'
import moment from 'moment';
import { useMachine } from '@xstate/react';
import { AlertColor, Snackbar, Alert, CircularProgress  } from '@mui/material';
import { useHistory } from 'react-router-dom'

// Types 
import { GoalInformationChallenge, OptionSearchBar, SearchIn, SearchTypeBox } from '../types';

// Context
import GamingMachine from '../context/machines/gaming/GamingMachine'
import UIContext from '../context/ui/UIContext'

// Components
import Layout from '../components/layout/Layout'
import Spinner from '../components/utils/Spinner'
import Support from '../components/utils/Support';
import SearchBar from '../components/utils/SearchBar';
import { RButton } from '../components/utils/RookButtons';
import GamingStats from '../components/gaming/GamingStats';
import ChallengeList from '../components/gaming/ChallengeList';
import CreateChallenge from '../components/gaming/CreateChallengeModal';
import { RBox } from '../components/utils/RookLayout';

// Hooks
import useTranslation from '../hooks/useTranslation'

const internalOptions: OptionSearchBar[] = [
  {
    key: "name",
    title: "name",
  },
  {
    key: "challenge_type_id",
    title: "gamingListType",
    searchTypeBox: SearchTypeBox.drop
  },
  {
    key: "start_at",
    title: "adminTableHeaderCreationDate",
    searchTypeBox: SearchTypeBox.date
  },
]

interface GamingProps {
  url: string
}

const Gaming: FC<GamingProps> = ({ url }) => {

  // Machine para controlar esta vista
  const [machine, send] = useMachine( GamingMachine )

  // Mostrar la alerta
  const [showAlert, setShowAlert] = useState(false)

  const [transporting, setTransporting] = useState(false)

  // Saber si esta cargando la pagina
  const [loading, setLoading] = useState(false)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Sacamos el uuid de la branch
  const { branchUUID, lang, centerData } = useContext( UIContext )

  // Regresar al login en caso de error
  const history = useHistory()

  const memorizedUsers = useMemo(() => machine.context.users, [machine.context.users])

  useEffect(() => {
    
    if (branchUUID !== '')
      send({ type: 'INIT', uuidBranch: branchUUID })

  }, [branchUUID])

  useEffect(() => {
    
    if (machine.matches('login')) history.push('/login')

  }, [machine.value])

  useEffect(() => {
    
    handleAlert()
    handleSpinner()

    if (machine.matches('created')) {
      handleTransport()
    }

  }, [machine.value])

  /**
   * If the state machine is in the created, rejected, failure, or updated state, then show the alert
   */
  const handleAlert = () : void => {
    if (machine.matches('created') 
      || machine.matches('rejected') 
      || machine.matches('failure') 
      || machine.matches('updated') 
    )
      setShowAlert(true)
    else setShowAlert(false)
  }

  /**
   * If the state machine is in any of the following states: loading, preparing, idle, searching, lookingFor, then set the loading state to true. Otherwise, set the loading state to false
   */
  const handleSpinner = () : void => {
    if (machine.matches('loading') 
      || machine.matches('preparing')
      || machine.matches('idle')
      || machine.matches('searching')
      || machine.matches('lookingFor')
    )
      setLoading(true)
    else setLoading(false)
  }

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    send({
      type: 'PAGEING',
      page: value
    })
  };

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'created':
      case 'updated': return 'success'
      case 'rejected': return 'error'
      default: return 'error'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (machine.value) {
      case 'created': return getTranslationByKey('gamingChallengeCreatedSuccess')
      case 'updated': return getTranslationByKey('gamingChallengeUpdatedSuccessfully')
      case 'rejected': return getTranslationByKey('generalError')
      default: return getTranslationByKey('generalError')
    }
  }

  const closeAlert = () : void => {
    setShowAlert( false )
    setTimeout(() => { send({ type: 'HIDE' }) }, 300)
  }

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  /**
   * It's a function that handles the transport of the user to the next page, and it's called  when the user clicks on the "Goals" button
   */
  const handleTransport = () : void => {
    let usersInChallenge = 0
    const competitive = getTranslationByKey('competitive')
    const id = machine.context.focusChallenge?.challenge_uuid

    if (machine.context.focusChallenge?.count_challenge_users) 
      usersInChallenge = machine.context.focusChallenge.count_challenge_users

    if (machine.context.focusChallenge?.count_challenge_teams) 
      usersInChallenge =  machine.context.focusChallenge.count_challenge_teams.reduce(
        (acc, current) => current.users_in_team + acc, 0
      )

    if (machine.context.focusChallenge?.count_challenge_rooms) 
      usersInChallenge = machine.context.focusChallenge.count_challenge_rooms.reduce(
        (acc, current) => current.users_in_room + acc, 0
      )

    const start = moment
      .utc(machine.context.focusChallenge?.start_at || '')
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const end = moment
      .utc(machine.context.focusChallenge?.finish_at || '')
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const state : GoalInformationChallenge = {
      compliance: machine.context.focusChallenge?.challenge_compliance || competitive,
      description: machine.context.focusChallenge?.description || '',
      end,
      isFree: machine.context.focusChallenge?.challenge_compliance === 'free',
      modality: machine.context.focusChallenge?.challenge_modality || '-',
      name: machine.context.focusChallenge?.name || '-',
      participants: usersInChallenge,
      start,
      status: 'active',
      type: machine.context.focusChallenge?.challenge_type || '-',
    }

    setTransporting(true)

    if (id) setTimeout(() => { history.push(`${url}/${id}/goals`, state) }, 2200)
  }

  return ( 
    <Layout>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 2000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => closeAlert() }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => closeAlert() }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <Spinner
        show = { loading }
      >

        <div
          className = "flex justify-end mb-8"
        >
          <Support
            action='https://intercom.help/rookmotion-support/es/articles/5648138-que-es-el-dashboard-y-que-indicadores-muestra'
          />
        </div>

        <GamingStats 
          data = { machine.context.dashboard }
        />

        <div className="flex flex-col md:flex-row items-center justify-between my-4">

          { transporting 
            ?  ( <CircularProgress color = 'secondary' /> )
            :  (
              <RBox
                className='flex-col-reverse lg:flex-row gap-4 mb-4 md_mb-0'
              >
                <RButton
                  color = 'gradient'
                  onClick = { () =>send({ type: 'TOGGLE' }) }
                >
                  { getTranslationByKey('gamingCreateChallenge') }
                </RButton>
                <RButton 
                  onClick={() => history.push(`${url}/board`)}
                >
                  Ranking
                </RButton>
              </RBox>
            )
          }


          <SearchBar 
            lang = { lang }
            minText = { 1 }
            options={ internalOptions }
            dropDownOptions={ machine.context.options }
            action={ searchIn }
          />

        </div>

        { (machine.context.page && !loading) && (
          <ChallengeList 
            service={machine.context.page}
            url = { url }
            count={machine.context.pages}
            currentPage={machine.context.currentPage}
            handleChange={ handleChange }          
          />
        )}

        { machine.context.challengeConfig && (
          <CreateChallenge 
            edit = { machine.context.focusChallenge }
            config = { machine.context.challengeConfig }
            loadingExtra={machine.matches('extra')}
            loading = { machine.matches('creating') || machine.matches('updating') }
            open={machine.context.openModalChallenge}
            onClose={() => send({ type: 'TOGGLE' })} 
            rooms={ machine.context.rooms || [] } 
            // users = { machine.context.users ? machine.context.users.data.slice() : [] }   
            users = { 
              memorizedUsers
              ? JSON.parse(JSON.stringify(memorizedUsers.data)) 
              : [] 
            }   
            sendChallenge={ challenge => send({ type: 'CREATE', challenge }) }       
            updateChallenge={ challenge => send({ type: 'UPDATE', data: challenge }) }       
          />
        )}


      </Spinner>

    </Layout>
  );
}
 
export default Gaming;