import React, { FC } from 'react'

// Components
import { RBox } from '../../../utils/RookLayout';
import { RImage } from '../../../utils/RookImages';
import RTexts from '../../../utils/RookTexts/RTexts';

interface IndividualHeaderProps {
  image ?: string,
  name: string,
  email: string,
  position: number,
  fullRounded ?: boolean
}
 
const IndividualHeader: FC<IndividualHeaderProps> = ({ 
  image, name, email, position, fullRounded = false,
}) => {

  const getBackgroundColor = () : string => {
    
    switch (position) {
      case 1: return 'bg-first-gradient'
      case 2: return 'bg-second-gradient'
      case 3: return 'bg-third'
    
      default: return 'bg-card-header'
    }

  }

  return ( 
    <RBox
      className={
        `justify-between bg-card-header ${ fullRounded ? 'rounded-2xl' : 'rounded-t-2xl' }`
      }
    >

      <RBox className='flex-col md:flex-row items-center gap-4 py-2 pl-4 2xl:pl-6'>
        <RImage
          image = { image }
          style = {{ borderRadius: '100%' }}
          size = {{
            mobile: '3rem',
            tablet: '3rem',
            extra: '3rem'
          }}
        />
        <RBox className='flex-col'>
          <RTexts className='text-center md:text-left w-52 md:w-40 2xl:w-44'>
            {name}
          </RTexts>
          <RTexts className='text-center md:text-left w-52 md:w-40 2xl:w-44'> 
            {email}
          </RTexts>
        </RBox>
      </RBox>

      <RBox 
        className={`
          flex-col justify-center items-center py-1 px-3 md:py-3 md:px-5 2xl:py-5 2xl:px-8 
          ${fullRounded ? 'rounded-r-2xl' : 'rounded-tr-2xl'} ${getBackgroundColor()}
        `}
      >
        <p className={`font-bold ${ position > 3 ? 'text-white' : 'text-black' } text-xl`}>
          {position}º
        </p>
      </RBox>

    </RBox>
  );
}
 
export default IndividualHeader;