import React, { FC, useEffect, useState } from 'react'

// Dependencies
import { useMachine } from '@xstate/react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Pagination, Snackbar, Alert, AlertColor } from '@mui/material';


// Types
import { 
  GoalBody,
  GoalInformationChallenge,
  OptionSearchBar, 
  SearchIn, 
  SearchTypeBox, 
} from '../../../types';

// Context
import GamingGoalMachine from '../../../context/machines/gaming/GamingGoalMachine'

// Components
import Layout from '../../layout/Layout';
import RButtonBack from '../../utils/RookButtons/RButtonBack';
import Spinner from '../../utils/Spinner';
import GoalTable from './GoalTable';
import GoalModal from './GoalModal';

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import SearchBar from '../../utils/SearchBar';
import ChallengeInformation from '../detail/ChallengeInformation';

type ChallengeDescriptionParams = {
  id: string
}

const internalOptions: OptionSearchBar[] = [
  {
    key: "created_at",
    title: "adminTableHeaderCreationDate",
    searchTypeBox: SearchTypeBox.date
  },
]

interface GoalListProps {
  url: string
}

/**
 * Componente que muestra la lista de objetivos
 * @returns lista de objetivos
 */
const GoalList: FC<GoalListProps> = ({ url }) => {

  // sacar el uuid del challenge
  const { id } = useParams<ChallengeDescriptionParams>()

  // Regresar a la vista anterior
  const history = useHistory()

  // Para obtener el challenge
  const location = useLocation()

  // Maquina que controla la vista
  const [machine, send] = useMachine(GamingGoalMachine)

  // Para mostrar el spinner
  const [loading, setLoading] = useState(false)

  // Alerta
  const [alert, setAlert] = useState(false) 

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  useEffect(() => {
    
    send({ type: 'INIT', uuidChallenge: id })

    if (!location.state) history.push( url )

  }, [])

  useEffect(() => {
    
    if (machine.matches('login')) history.push('/login')

  }, [machine.value])

  useEffect(() => {

    if (machine.value === 'changed' 
      || machine.value === 'failure' 
      || machine.value === 'created'
      || machine.value === 'saved'
    )
      setAlert(true)
    else setAlert(false)

    if (
      machine.matches('preparing') 
      || machine.matches('loading') 
      || machine.matches('idle')
      || machine.matches('searching')
    ) setLoading(true)
    else setLoading(false)

  }, [machine.value])

  /**
   * Generar una petición para editar o crear un objetivo
   * @param values data a eviar
   */
  const onSubmit = (values: any) : void => {

    if (machine.context.focusGoal) {
      send({
        type: 'SAVE',
        data: {
          uuid: machine.context.focusGoal.uuid,
          "goal_type_id": values.goalType,
          meta: values.goal,
          points: values.points,
          status: values.status
        }
      })
    }
    else {

      const body : GoalBody = {
        "goal_type_id": values.goalType,
        meta: values.goal,
        points: values.points,
        status: values.status
      }

      if ((location.state as GoalInformationChallenge).isFree){
        body.meta = '20000000'
        body.points = 1
      }
      
      send({
        type: 'CREATE',
        data: {
          goal: body,
          isFree: (location.state as GoalInformationChallenge)?.isFree || false
        },
      })
    }
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'created':
      case 'saved':
      case 'changed': return 'success'

      case 'failure': 
        if ([30, 40].includes(machine.context.statusError)) return 'warning'
        return 'error'

      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (machine.value) {
      case 'created': return getTranslationByKey( 'gamingChallengeGoalCreatedSuccessfully' )
      case 'saved': return getTranslationByKey( 'gamingChallengeGoalEditedSuccessfully' )
      case 'changed': return getTranslationByKey( 'gamingChallengeGoalStatusSuccess' )
      
      case 'failure':
        if (machine.context.statusError === 30) 
          return getTranslationByKey('gamingGoalDuplicated')
        if (machine.context.statusError === 40)
          return getTranslationByKey('gamingGoalModalSameLevel')
        
        return getTranslationByKey( 'generalError' )

      default: return getTranslationByKey( 'generalError' )
    }
  }

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
   const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      
      <Snackbar
        open = { alert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setAlert(false) }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => setAlert(false) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <Spinner 
        show={ loading }
      >

        <div 
          className="flex flex-wrap md:flex-nowrap gap-2 items-center justify-between mb-4"
        >
          <div className='flex items-center'>
            <RButtonBack
              action={() => history.goBack()}
            />

            { (location.state as GoalInformationChallenge).status !== 'completed' && (
              <button
                type = "button"
                className = "uppercase bg-main-gradient px-8 py-2 rounded-3xl w-full lg:w-max ml-4"
                onClick = { () => send({ type: 'TOGGLE' }) }
              >
                { getTranslationByKey('gamingChallengeGoalCreate') }
              </button>
            )}
          </div>

          <SearchBar 
            options={ internalOptions }
            action={ searchIn }
          />
        </div>

        { location.state && (
          <ChallengeInformation 
            name={(location.state as GoalInformationChallenge).name} 
            description={(location.state as GoalInformationChallenge).description}
            modality={(location.state as GoalInformationChallenge).modality}
            type={(location.state as GoalInformationChallenge).type} 
            compliance={(location.state as GoalInformationChallenge).compliance} 
            start={(location.state as GoalInformationChallenge).start} 
            end={(location.state as GoalInformationChallenge).end} 
            users={(location.state as GoalInformationChallenge).participants}
          />
        )}

        { (machine.context.page && location.state) && (
          <GoalTable 
            isFree = { (location.state as GoalInformationChallenge).isFree || false }
            isEditable = { (location.state as GoalInformationChallenge).status !== 'completed' }
            service = { machine.context.page }
          />
        )}

        { machine.context.pages > 1 && (
          <div className="flex justify-center mt-12 mb-4">
            <Pagination
              showFirstButton
              showLastButton
              page = { machine.context.currentPage }
              variant="text"
              color = "secondary"
              count = { machine.context.pages }
              onChange = { () => {} }
            />
          </div>
        )}

        { location.state && (
          <GoalModal 
            isFree = { (location.state as GoalInformationChallenge).isFree || false }
            edit = { machine.context.focusGoal }
            open={machine.context.openGoalModal}
            loading={machine.matches('creating') || machine.matches('saving') }
            loadingExtra={machine.matches('extra')}
            goalTypes={ machine.context.goalTypes || [] }        
            onClose={() => send({ type: 'TOGGLE' })} 
            onSubmit={ onSubmit }
          />
        )}


      </Spinner>
    </Layout>
  );
}
 
export default GoalList;