import React, { FC } from 'react'
import { RBox } from '../../../utils/RookLayout'
import RTexts from '../../../utils/RookTexts/RTexts'

interface GroupTitleProps {
  title: string,
  position: number,
  fullRounded ?: boolean,
}
 
const GroupTitle: FC<GroupTitleProps> = ({ title, position, fullRounded = false}) => {

  const getBackgroundColor = () : string => {
    switch (position) {
      case 1:
        return 'bg-first-gradient'

      case 2:
        return 'bg-second-gradient'

      case 3:
        return 'bg-third'

      default:
        return 'bg-card-header'
    }
  }

  return ( 
    <RBox 
      type = 'grid'
      className = {`
        grid-cols-eighty-twenty 2xl:grid-cols-eighty-fifteen
        items-center bg-card-header ${ fullRounded && 'rounded-2xl'}
      `}
    >
      <RTexts 
        className = {`text-center px-4 capitalize ${ fullRounded ? 'w-64 md:w-80 2xl:w-96' : 'w-full'}`}
      >
        { title }
      </RTexts>

      <RBox 
        className={`
          flex-col justify-center items-center py-2
          ${fullRounded && 'rounded-r-2xl'} ${getBackgroundColor()}
        `}
      >
        <p className={`font-bold ${ position > 3 ? 'text-white' : 'text-black' } text-xl`}>
          {position}º
        </p>
      </RBox>
      
    </RBox>
  );
}
 
export default GroupTitle;