import React, { FC, useState, useEffect, useContext } from 'react'

// Dependencies
import { useMachine } from '@xstate/react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { useHistory } from 'react-router-dom'

// Automaton
import ProfileMachine from '../context/machines/ProfileMachine'

// Context
import UIContext from '../context/ui/UIContext'

// Types and models
import Texts from '../models/Texts'
import { CurrentUser, UpdatePassword, Word } from '../types';

// Componentes
import Layout from '../components/layout/Layout'
import Spinner from '../components/utils/Spinner'
import ProfileImage from '../components/profile/ProfileImage';
import ProfileTabs from '../components/profile/ProfileTabs';

// Hooks
import useTranslation from '../hooks/useTranslation'

const Profile: FC = () => {
  
  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Context para actualizar al usuairo
  const { setCurrentUser } = useContext( UIContext )

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // State para formatear el nombre
  const [username, setUsername] = useState('')

  // Hooks para navegar
  const history = useHistory()

  // Machine para manejar los estados de esta página
  const [machine, send] = useMachine(ProfileMachine)

  // Context
  const { currentUser }  = machine.context

  // Una vez descargado el user actualizamos
  useEffect(() => {
    
    if (currentUser) {
      setCurrentUser( currentUser )
      setUsername(
        `${currentUser.name} ${currentUser.last_name_1 || ''} ${currentUser.last_name_2 || ''}`
      )
    }

  }, [currentUser])

  // Actualizar las variables
  useEffect(() => {
    
    if ( getShowAlert() ) setShowAlert(true)
    else setShowAlert(false)

    if (machine.matches('login')) history.push("/login")

  }, [machine])

  /**
   * Actualizar la data del usuario
   * @param user nueva data del usuario
   */
  const handleUpdateUser = (user: CurrentUser) : void => {
    send({
      type: 'UPDATE',
      data: user
    })
  }

  /**
   * Actualizar la imagen de perfil
   * @param file Imagen a subir
   */
  const handleUpdateImage = (file: File) : void => {
    send({
      type: 'IMAGE',
      data: file
    })
  }

  /**
   * Función para actualizar la contraseña
   * @param password con los nuevos datos de la contraseña
   */
  const handleUpdatePassword = (password: UpdatePassword) : void => {
    send({
      type: 'WHISPER',
      data: password
    })
  }

  /**
   * Mostrar o no
   * @returns si es un estado valido para mostrar la alerta
   */
  const getShowAlert = () : boolean => {
    switch (machine.value) {

      case 'updated': return true

      case 'aborted': return true

      case 'whispered': return true

      case 'passwordfailed': return true
    
      default: return false
    }
  }

  /**
   * Determinar que mensaje se va a mostrar
   * @returns el mensaje de la alerta
   */
  const getMessage = () : string => {

    switch (machine.value) {

      case 'updated': return getTranslation( Texts.profileUpdateSucess as Word )

      
      case 'whispered': return getTranslation( Texts.profileUpdatePasswordSucess as Word )
      
      case 'passwordfailed': return getFailedPasswordMessage()

      case 'aborted': 
      default: return getTranslation( Texts.generalError as Word )

    }

  }

  /**
   * Descubrir el mensaje de error
   * @returns un mensaje de alert
   */
  const getFailedPasswordMessage = () : string => {
    
    switch (machine.context.statusError) {
      case 401: return getTranslation( Texts.profileCurrentPasswordError as Word )

      case 422: return getTranslation( Texts.profileCurrentPasswordWeakError as Word )
    
      default: return getTranslation( Texts.generalError as Word )
    }

  }
  
  /**
   * Severidad del mensaje
   * @returns color de la alerta
   */
  const getSeverity = () : AlertColor => {

    switch (machine.value) {

      case 'updated': 
      case 'whispered': return 'success'
      
      case 'passwordfailed':
      case 'aborted': 
      default: return 'error'

    }
  }

  return ( 
    <Layout>

      <Spinner
        show = { machine.matches('loading') }
      >

        <Snackbar
          open = { showAlert }
          autoHideDuration = { 6000 }
          anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
          onClose = { () => setShowAlert( false ) }
        >
          <Alert
            severity = { getSeverity() }
            variant = "filled"
            onClose = { () => setShowAlert( false ) }
          >
            { getMessage() }
          </Alert>
        </Snackbar>

        { machine.matches('failure') 
          ? (
            <h1>{ getTranslation( Texts.generalError as Word ) }</h1>
          ) 
          : (
            <div className="flex flex-col lg:flex-row items-center">

              { currentUser && (
                <>
                  <ProfileImage 
                    name={username}
                    email={currentUser.email}
                    image={currentUser?.image_url || null} 
                    changeImage={ handleUpdateImage }  
                    spinner = { machine.matches('changing') }                                  
                  />

                  <ProfileTabs 
                    currentUser = { currentUser! }
                    profileSpinner = { machine.matches('updating') }
                    passwordSpinner = { machine.matches('whispering') }
                    resetPasswordForm = { machine.matches('whispered') }
                    handleUpdate = { handleUpdateUser }
                    handleUpdatePassword = { handleUpdatePassword }
                  />
                </>
              )}
            </div>
          ) 
        }

      </Spinner>
    </Layout>
  );
}
 
export default Profile;