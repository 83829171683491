import React, { FC, ReactElement, useState } from 'react'

// Icons
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';

// Models
import Ranges from '../../models/Ranges'
import Texts from '../../models/Texts';
import { Range, Word, Training, ClusterIcon } from '../../types'

// Components
import { Container, Statistics, Disconnection, ImageContainer, BanUser } from './styles/ClusterStyles'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import Colors from '../../utils/colors'

type IconSize = {
  icon: string,
  text: string
}

type TextSize = {
  effort: string,
  name: string
}

interface ClusterProps {
  width ?: number | null,
  height ?: string | null,
  marginRight ?: number
  user : Training,
  icon : ClusterIcon | null,
  hasStreaming : boolean | null,
  handleBan: () => void,
  size ?: string | null
}
 
const Cluster: FC<ClusterProps> = ({ 
  user, icon, width, height, marginRight, size, hasStreaming,
  handleBan 
}) => {

  // Hook para traducir
  const { getTranslation } = useTranslation();

  // trimming el nombre
  const [trimming] = useState({ 
    limit: 20,
    sub: 17
  })

  /**
   * Calcular el color en base al esfuerzo
   * @param effort Nivel de esfuerzo que esta realizando el usuario
   * @returns Un Color basado en el enumerado
   */
   const calculeteColor = (effort: number): Colors => {
    let color: Colors = Colors.eMin;

    if (effort === 0) return Colors.waiting

    if (effort >= 100) return Colors.eMax;

    Ranges.forEach((zone : Range) => {
      if (effort >= zone.limitBottom && effort < zone.limitTop) {
        color = zone.color;
      }
    });
    return color;
  };

  /**
   * Seleccionar el icono a mostrar
   * @returns Icono a mostrar
   */
   const defineIcon = (iconWidth: string) : ReactElement | null => {

    let src = ''

    switch (icon) {
      case ClusterIcon.boots:
        src = '/icons/boots.png'
        break;

      case ClusterIcon.trampoline:
        src = '/icons/trampoline.png'
        break;
        
      default: 
        src ='/icons/steps.png'
        break;
    }

    return (
      <div
        style ={{ maxWidth: iconWidth, margin: '0 auto' }}
      >
        <img
          src= { src }
          alt="icono"
        />
      </div>
  );
  };

  const getIconSize = () : IconSize => {
    
    if (size === '26rem') return {icon: '3.7rem', text: '3.2rem'}

    if (size === '20rem' || size === '18rem' || size === '15rem') 
      return {icon: '3.2rem', text: '2.2rem'}

    if (size === '12rem' || size === '10rem' ) 
      return {icon: '2.5rem', text: '1.5rem'}

    if (size === '9.5rem' || size === '9rem' || size === '8.5rem' || size === '8rem')
      return {icon: '1.8rem', text: '1.2rem'}

    if (size === '6.5001rem' || size === '6.5rem') return {icon: '1.5rem', text: '1rem'}

    if (size === '5rem') return {icon: '1rem', text: '0.9rem'}

    if (size === '4rem') return {icon: '0.8rem', text: '0.7rem'}

    return {icon: '1.2rem', text: '1.1rem'}

  }

  const getTextsSize = () : TextSize => {
    
    if (size === '26rem') 
      return {
        effort: 'slg:text-8xl 2xl:text-9xl',
        name: 'slg:text-7xl 2xl:text-8xl'
      }

    if ( size === '20rem' || size === '18rem' || size === '15rem')
      return {
        effort: 'lg:text-6xl 2xl:text-7xl',
        name: 'lg:text-5xl 2xl:text-6xl'
      }
      
    if (size === '10rem' || size === '12rem')
      return {
        effort: 'slg:text-7xl',
        name: 'slg:text-6xl'
      }

    if (size === '9.5rem') {
      return {
        effort: 'slg:text-6xl',
        name: 'slg:text-5xl'
      }
    }

    if (size === '7rem' && hasStreaming) {
      return { 
        effort: 'slg:text-4xl 2xl:text-5xl',
        name: 'slg:text-3xl 2xl:text-4xl'
      }
    }

    if (size === '9rem' || size === '8.5rem' || size === '6.5rem')
      return {
        effort: 'slg:text-5xl',
        name: 'slg:text-4xl'
      }
      
    return { 
      effort: 'lg:text-4xl 2xl:text-5xl',
      name: 'lg:text-3xl 2xl:text-4xl'
    }
    
  }

  return ( 
    <Container
      color = { calculeteColor(user.effort) }
      width = { width }
      height = { height }
      right={ marginRight }
    >

      <BanUser
        id = "btn-delete-user"
        onClick = { handleBan }
      >
        <HighlightOffIcon />
      </BanUser>

      <div className={`h-full ${!user.status ? 'disabled' : ''}`}>

        <div className="grid grid-rows-cluster h-full">

          <div className='flex items-center justify-center'>
            <h5
              className = {`text-center text-lg ${getTextsSize().name}`}
            >
              { user.pseudonym.length >= trimming.limit
                ? `${user.pseudonym.substring(0, trimming.sub)}...`
                : user.pseudonym
              }
            </h5>
          </div>

          <div 
            className="flex flex-col items-center justify-center"
          >

            <div 
              className={`
                grid gap-5 items-center w-full h-full overflow-hidden
                ${ user.user_url_image ? 'grid-cols-2' : ''}
              `}
            >

            {(user.user_url_image) 
              && (
                <div className='w-full h-full flex justify-end items-center'>
                  <ImageContainer
                    image = { user.user_url_image }
                    size={ size }
                  />
                </div>
              )
            }

              <div 
                className={`${ 
                  user.user_url_image 
                    ? '' 
                    : 'text-center flex items-center justify-center'
                }`}
                style = { user.user_url_image ? {} : { height: size || '' } }
              >

                <h3
                  className = {`text-2xl ${getTextsSize().effort} font-bold`}
                >
                  <FlashOnIcon   
                    sx = {{
                      fontSize: getIconSize().icon
                    }}
                  />
                  {' '}{ user.effort }%
                </h3>

              </div>

            </div>

          </div>


          <Statistics>
            <div>

              <FavoriteIcon
                sx = {{
                  fontSize: getIconSize().icon
                }}
              />
              <p 
                className = "m-0"
                style = {{ fontSize: getIconSize().text }}
              >
                  { user.hr }
              </p>

            </div>

            <div>

              <WhatshotIcon
                sx = {{
                  fontSize: getIconSize().icon
                }}
              />
              <p 
                className = "m-0"
                style = {{ fontSize: getIconSize().text }}
              >
                { user.calories_tot }
              </p>

            </div>

            { icon ? (

              <div>
                { defineIcon( getIconSize().icon ) }
                <p 
                  className = "m-0"
                  style = {{ fontSize: getIconSize().text }}
                >
                  { user.steps_tot }
                </p>
              </div>

            ): null}
          </Statistics>

        </div>
      </div>

      { !user.status && (

        <Disconnection>
          <PortableWifiOffIcon
            style = {{ width: '1.3rem', color: 'white' }}
          />
          <p>
            { getTranslation(Texts.clUserDisconnected as Word) }
          </p>
        </Disconnection>

      )}

    </Container>
  );
}
 
export default Cluster;