import { Action } from "../../types";
import {
  SET_CENTER_NAME,
  SET_AUTHENTICATED,
  SET_DASHBOARD_DATA,
  SET_CURRENT_USER,
  SET_TRUNK,
  SET_LANG,
  SET_CENTER_IMAGE,
  SET_METRICS_SYSTEM,
  SET_PERMISSIONS,
  SET_CONTRACT_ALREADY_SHOWN,
  SET_HOSTNAME,
  SET_FEATURES_ENABLED,
  SET_BRANCH_UUID,
  SET_CENTER_DATA,
  SET_IS_UNAVAILABLE,
} from "../../types/context";

const UIReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };

    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload,
      };

    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case SET_TRUNK:
      return {
        ...state,
        trunk: action.payload,
      };

    case SET_CENTER_IMAGE:
      return {
        ...state,
        centerImage: action.payload,
      };

    case SET_METRICS_SYSTEM:
      return {
        ...state,
        metricSystem: action.payload,
      };

    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case SET_CONTRACT_ALREADY_SHOWN:
      return {
        ...state,
        contractAlreadyShown: action.payload,
      };

    case SET_HOSTNAME:
      return {
        ...state,
        hostname: action.payload,
      };

    case SET_FEATURES_ENABLED:
      return {
        ...state,
        features: action.payload,
      };

    case SET_BRANCH_UUID:
      return {
        ...state,
        branchUUID: action.payload,
      };

    case SET_CENTER_NAME:
      return {
        ...state,
        centerName: action.payload,
      };

    case SET_CENTER_DATA:
      return {
        ...state,
        centerData: action.payload,
      };

    case SET_IS_UNAVAILABLE:
      return {
        ...state,
        isUnavailable: action.payload,
      };

    default:
      return state;
  }
};

export default UIReducer;
