/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactElement, useCallback } from 'react'

// Dependencies
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';

// Types
import { ClusterIcon, Measurements, Training } from '../../types/index';

// Components
import Cluster from './Cluster';

interface VirtualRemoteProps {
  total: number,
  columns: number,
  icon: ClusterIcon | null,
  measurements: Measurements,
  handleBan: (user: Training) => void,
  getImageSize: (n: number) => string,
}
 
const VirtualRemote: FC<VirtualRemoteProps> = ({ 
  measurements, total, icon, columns,
  getImageSize, handleBan 
}) => {

  const getRowHeight = useCallback(() : number => {
    if (columns === 5) return 230
    if (columns === 4) return 200
    if (columns === 3) return 150
    return 140
  }, [columns])

  const RenderRows = ({ columnIndex, rowIndex, style }: GridChildComponentProps) : ReactElement => {
    
    const index = columnIndex + rowIndex * columns
    const user = measurements.data[index]

    if (!user) return <></>

    return (
      <div
        className='flex items-center justify-center md:block'
        style={{ 
          ...style, 
          padding: '0.3rem',
        }}
      >
        <Cluster
          height='100%'
          size={getImageSize(total)}
          user={user}
          icon={icon}
          handleBan={() => handleBan(user)} 
          hasStreaming = { false }         
        />
      </div>
    )

  }

  return ( 
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeGrid
          height = { height }
          width = { width }
          columnCount = { columns }
          columnWidth = { width / columns }
          rowCount = { Math.ceil(total / columns)  }
          rowHeight = { getRowHeight() }
        >
          { props => (<RenderRows { ...props } /> )}
        </FixedSizeGrid>
      )}
    </AutoSizer>
  );
}
 
export default VirtualRemote;