import { createMachine, assign, spawn, send, sendParent, DoneInvokeEvent } from "xstate";
import * as Sentry from '@sentry/react'
import moment from "moment";

// HTTP Client
import axiosClient from '../../config/axios'

// Types
import { Sessions, Session, SearchIn, TrainingMetrics, ChartData, Summaries, Translation } from '../../types'

// Utils
import Colors from '../../utils/colors'
import Ranges from "../../models/Ranges";


/**
 * page: actor actual
 * pageData: objeto con los actores
 * metrics: actor con la data de las metricas
 * totalPages: número de páginas originales
 * pages: número de páginas actuales
 * search: criterios de búsqueda
 * searched: resultados de la búsqueda
 * currentPage: número de la página actual
 * pageAlreadyExists: saber si la página que se esta consultando ya existe
 */
type SessionsMachineContext = {
  currentPage: number,
  lang: Translation,
  metrics: any,
  offsetName: string,
  page: any | null,
  pageAlreadyExists: boolean,
  pageData: any,
  pages: number,
  search: SearchIn | null,
  searched: Sessions | null,
  totalPages: number,
} 

/**
 * READY: Cuando la página termino de cargar la lista de entrenamientos
 * PAGEING: Paginar
 * SEARCH: Buscar por un sensor,
 * METRICS: Iniciar un actor para mostrar las métricas del entrenamiento
 * SHUTDOWN: terminar actor
 * CLEVER: Cuando el actor termino de cargar la data del entrenamiento para las gráficas
 * BACK: Volver a la vista anterior
 */
type SessionsMachineEvent = 
  | { type: 'BOOTING', offset: string }
  | { type: 'READY', pages: number }
  | { type: 'PAGEING', page: number }
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'METRICS', data: Session }
  | { type: 'LANG', data: Translation }
  | { type: 'SHUTDOWN'}
  | { type: 'CLEVER'}
  | { type: 'BACK'}

/**
 * Obtenemos la lista de sesiones
 * @returns lista de sesiones
 */
const fetchSessions = async (context: CreatePageMachineContext) : Promise<Sessions> => {
  
  // Sacamos la pagina
  const { page } = context

  // Definimos de que página vamos a descargar
  const url:string = page ? `/api/v2/trainings/level?page=${page}` : '/api/v2/trainings/level'

  const response = await axiosClient.get(url)
  return response.data

}

/**
 * Buscar una sesión basado en el buscador
 * @param context de la maquina principal
 * @returns Retorna una lista de usuarios
 */
const searchForSession = async (context: SessionsMachineContext) : Promise<Sessions> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  // Hacemos la petición
  const url = `/api/v2/trainings/level?search_column=${search.search_column}&search=${search.search}`
  const response = await axiosClient.get(url)

  return response.data

}

/**
 * Buscamos otra página basado en el buscador
 * @param context de la maquina principal
 * @returns lista de sesiones
 */
const serchCompundRequest = async (context: SessionsMachineContext) : Promise<Sessions> => {
  
  // Sacamos el objeto a buscar ademas de la página
  const { currentPage, search } = context

  if (!search) throw new Error()

  // Formamos la url
  let url = `/api/v2/trainings/level`
  url = `${url}?search_column=${search.search_column}&search=${search.search}`
  url = `${url}&page=${currentPage}`

  const response = await axiosClient.get(url)

  return response.data

}

/**
 * Descargar las metricas del entrenameinto
 * @param context del actor
 * @returns las metricas del entrenamiento
 */
const fetchTrainingMetrics = async (context: MetricsSessionMachineContext) : Promise<any> => {
  
  // Sacamos al usuario para 
  const { uuid } = context 

  // Hacemos la consulta
  const response = await axiosClient.get(`/api/v2/trainings/${uuid}/show`)

  return response.data

}

/**
 * Formatear data para las gráficas
 * @param summaries records del entrenameinto
 * @returns data formateada para la gráfica
 */
const getIntensityZones = (summaries: Summaries | Array<any>, lang: Translation) : ChartData | null => {
  
  if (Array.isArray(summaries)) return null

  const zones: number[] = [ 
    Number(summaries.z5_time_tot)/60,
    Number(summaries.z4_time_tot)/60,
    Number(summaries.z3_time_tot)/60,
    Number(summaries.z2_time_tot)/60,
    Number(summaries.z1_time_tot)/60,
  ]

  return {
    labels: Ranges.map(({label}) => lang === Translation.es ? label.es : label.en),
    datasets: [
      {
        label: '',
        data: zones,
        backgroundColor: [
          Colors.eMax,
          Colors.eIte,
          Colors.eMod,
          Colors.eLgt,
          Colors.eMin,
        ],
        borderColor: [
          Colors.eMax,
          Colors.eIte,
          Colors.eMod,
          Colors.eLgt,
          Colors.eMin,
        ],
        borderWidth: 1,
      }
    ]
  }

}

const SessionsMachine = createMachine<SessionsMachineContext, SessionsMachineEvent>(
  {
    id: 'SessionesMachine',
    initial: 'idle',
    context: {
      currentPage: 0,
      lang: Translation.en,
      metrics: null,
      offsetName: 'America/Mexico_City',
      page: null,
      pageAlreadyExists: false,
      pageData: {},
      pages: 0,
      search: null,
      searched: null,
      totalPages: 0,
    },
    states: {
      idle: {
        on: {
          BOOTING: {
            actions: send({ type: 'PAGEING', page: 1 })
          }
        }
      },
      loading: {
        always: [
          { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ]
      },
      loaded: {
        on: {
          LANG: {
            actions: assign({ lang: (_, event) => event.data})
          }
        }
      },
      searching: {
        invoke: {
          id: 'searchingSession',
          src: searchForSession,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedSessions',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                currentPage: 1,
                page: Object.values(context.pageData)[0],
                pages: context.totalPages
              }
            })
          }
        }
      },
      lookingFor: {
        invoke: {
          id: 'serchCompundRequest',
          src: serchCompundRequest,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedSessions',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      graphics: {

      },
      finish: {
        type: 'final'
      }
    },
    on: {
      READY: {
        target: '.loaded',
        actions: assign({ 
          pages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
          totalPages: (context, event) => event.pages > context.pages ? event.pages : context.pages
        })
      },
      PAGEING: {
        target: '.loading',
        actions: assign((context, event) => {

          if (event.type !== 'PAGEING') return context

          if (context.search && context.search.search !== '-1')
            return {
              ...context,
              page: context.pageData.searchMachine,
              pageAlreadyExists: true,
              currentPage: event.page
            }

          // Use the existing subredit actor if one already exists
          let subPage = context.pageData[`${event.page}`]

          if (subPage) {
            return {
              ...context,
              page: subPage,
              pageAlreadyExists: true,
              currentPage: event.page
            }
          }

          // Otherwise, spawn a new subreddit actor and
          // save it in the subreddits object
          subPage = spawn( createPageMachine( event.page, 'loading' ) )

          return {
            pageData: {
              ...context.pageData,
              [`${event.page}`]: subPage
            },
            page: subPage,
            pageAlreadyExists: false,
            currentPage: event.page
          }
        })
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
            ...context,
            search: event.data,
            currentPage: 1
          }))
      },
      METRICS: {
        target: '.loading',
        actions: [
          'showMetrics',
          'prepareTransition'
        ]
      },
      CLEVER: {
        target: '.graphics'
      },
      SHUTDOWN: {
        target: '.finish'
      },
      BACK: {
        target: '.loaded',
        actions: [
          send({ type: 'STOP' }, {
            to: (context) => context.metrics
          }),
        ]
      }
    }
  },
  {
    actions: {
      searchedSessions: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Sessions> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.searchingSession" && event.type !== 'done.invoke.serchCompundRequest') 
          return context
        
        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, 'loaded' ) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      }),
      showMetrics: assign((context) => ({
          ...context,
          search: null,
          pageAlreadyExists: false
        })),
      prepareTransition: assign((context, event) => {

        if (event.type !== 'METRICS') return { ...context }

        const metricsMachine = spawn(
          createMetricsMachine({
            lang: context.lang,
            offsetName: context.offsetName,
            training: event.data,
            uuid: event.data.training_uuid
          })
        )

        return {
          ...context,
          metrics: metricsMachine,
        }

      }),
      restoring: assign((context, event) => {

        if (event.type !== 'BACK') return { ...context }

        return {
          ...context,
          currentPage: 1,
          page: Object.values(context.pageData)[0],
          pages: context.totalPages
        }

      })
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      }
    }
  }
)

/**
 * page: número de página a la que pertenece el actor
 * sessions: lista de sessiones
 * statusError: de la API
 */
type CreatePageMachineContext = {
  page: number,
  sessions: Sessions | null,
  statusError: number,
}

/**
 * REFRESH: actualizar la data cuando es un actor de busqueda
 * TRANSITION: Indicarle al padre que se van a visualizar las gráficas y haga otro actor
 */
type CreatePageMachineEvent = 
  | { type: 'REFRESH', data: Sessions }
  | { type: 'TRANSITION', data: Session }

const createPageMachine = (page: number, initial: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvent>(
  {
    id: 'PageMachine',
    initial,
    context: {
      page,
      sessions: null,
      statusError: 0,
    },
    states: {
      loaded: {},
      login: {},
      loading: {
        invoke: {
          id: 'fetchSessions',
          src: fetchSessions,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent((context) => ({ type: 'READY', pages: context.sessions?.meta.last_page || 0 }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              sendParent({ type: 'READY', pages: -1 }),
              assign((context, event) => {

                const errorMessage : string = 'No se pudieron descargar los sesiones'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      shutdown: {
        type: 'final',
      },
    },
    on: {
      REFRESH: {
        target: '.loaded',
        actions: ['preparingData']
      },
      TRANSITION: {
        actions: [
          sendParent((_, event) => ({ type: 'METRICS', data: event.data })),
        ]
      },
    }
  },
  {
    actions: {
      preparingData: assign((context, _event: any) => {
        
        // Cargamos el evento
        const event : DoneInvokeEvent<Sessions> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.fetchSessions" && event.type !== "REFRESH") 
          return context

        // Combinamos los apellidos
        const sessions : Session[] = event.data.data.map((session: Session) => {

          let first = '-'
          let second = ''

          if (session.last_name_1 && session.last_name_1 !== 'null')
            first = session.last_name_1

          if (session.last_name_2 && session.last_name_2 !== 'null')
            second = session.last_name_2

          return {
            ...session,
            lastName: `${first} ${second}`
          }
        })

        // Spread en event.data para combiar las links y meta
        return {
          ...context,
          sessions: {
            ...event.data,
            data: sessions
          }
        }

      }),
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

/**
 * uuid: del entrenamiento
 * training: data del entrenamiento
 * metrics: estadísticas del entrenamiento
 * statusError: error de la API
 * effort: data para la gráfica de puntos de inicio
 * zones: data para la gráfica de barras y pie
 * heartRate: data para la gráfica de frecuencia cardíaca
 * calories: data para la gráfica de calorías
 */
type MetricsSessionMachineContext = {
  calories: ChartData | null,
  effort: ChartData | null,
  heartRate: ChartData | null,
  lang: Translation,
  metrics: TrainingMetrics | null,
  offsetName: string,
  statusError: number,
  training: Session,
  uuid: string,
  zones: ChartData | null,
}

type MetricsSessionMachineEvent = 
  | { type: 'BACK' }

type MetricsSessionProps = {
  lang: Translation,
  offsetName: string
  training: Session, 
  uuid: string, 
}

export const createMetricsMachine = ({uuid, training, lang, offsetName} : MetricsSessionProps) => createMachine<MetricsSessionMachineContext, MetricsSessionMachineEvent>(
    {
      id: 'MetricsSession',
      initial: 'loading',
      context: {
        calories: null,
        effort: null,
        heartRate: null,
        lang,
        metrics: null,
        offsetName,
        statusError: 0,
        training,
        uuid,
        zones: null,
      },
      states: {
        login:{},
        loaded: {

        },
        loading: {
          invoke: {
            id: 'fetchTrainingMetrics',
            src: fetchTrainingMetrics,
            onDone: {
              target: 'loaded',
              actions: [
                'prepareData',
                sendParent({ type: 'CLEVER' })
              ]
            },
            onError: {
              target: 'failure',
              actions: assign((context, event) => {

                const errorMessage : string = 'No se pudieron descargar las metricas de la sesion'

                  if (event.data.response) {
                    Sentry.addBreadcrumb({
                      category: 'error',
                      message: errorMessage,
                      level: Sentry.Severity.Error,
                      data: event.data
                    })

                    Sentry.addBreadcrumb({
                      category: 'info',
                      message: errorMessage,
                      level: Sentry.Severity.Error,
                      data: event.data.response
                    })
                  }
                  else {
                    Sentry.addBreadcrumb({
                      category: 'warning',
                      message: `${errorMessage}, por falta de data`,
                      level: Sentry.Severity.Warning,
                      data: event.data
                    })
                  }

                  Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }

              })
            }
          }
        },
        failure: {
          always: [
            { target: 'login', cond: 'isUnauthorized' }
          ]
        },
        end: {
          type: 'final'
        }
      },
      on: {
        BACK: {
          actions: sendParent({ type: 'BACK' })
        }
      }
    },
    {
      actions: {
        prepareData: assign((context, _event: any) => {

          // Cargamos el evento
          const event : DoneInvokeEvent<TrainingMetrics> = _event

          if (event.type !== 'done.invoke.fetchTrainingMetrics')
            return { ...context }

          const labels: string[] = []
          const effort: number[] = []
          const heartRate: number[] = []
          const calories: number[] = []
          const size = event.data.records.hr_derived_records.length
          const rawRange = size * 0.16 / 270
          const range = size <= 113 ? -1 : Number(rawRange.toFixed(2))

          const zones = getIntensityZones(event.data.summaries, context.lang)

          event.data.records.hr_derived_records.forEach((data, index) => {
            
            const timestamp = moment
                .utc(data.timestamp)
                .tz(context.offsetName)
                .format('HH:mm:ss')

            if (index === 0) {
              labels.push(timestamp)
              effort.push(Number(data.effort))
              heartRate.push(Number(data.heart_rate))
              calories.push(Number(data.calories))
            }
            else {

              const previous = effort[effort.length-1]
              const diff = Math.abs( Number(data.effort) - previous )

              if ( diff > range ){
                labels.push(timestamp)
                effort.push(Number(data.effort))
                heartRate.push(Number(data.heart_rate))
                calories.push(Number(data.calories))
              }

            }

          })
          
          return {
            ...context,
            metrics: event.data,
            effort: {
              labels,
              datasets: [
                {
                  label: 'Esfuerzo',
                  data: effort,
                  borderColor: Colors.secondaryBackgroundColor,
                  tension: 0.4,
                  segment: {
                    borderColor: (ctx: any) => {
                      
                      const diff = Math.abs( ctx.p0.parsed.y - ctx.p1.parsed.y ) 
                      const value = diff <= 20 ? ctx.p0.parsed.y : ctx.p1.parsed.y

                      switch (true) {

                        case (value < 60): return Colors.eMin

                        case (value > 60 && value < 70): return Colors.eLgt

                        case (value > 70 && value < 80): return Colors.eMod

                        case (value > 80 && value < 90): return Colors.eIte
                      
                        default: return Colors.eMax
                      }
                    }
                  },
                  pointBackgroundColor: (ctx: any) => {
                    const { parsed } = ctx
                    const value = parsed ? parsed.y : -1

                    if (!value || value === -1) return Colors.transparent

                    switch (true) {

                      case (value < 60): return Colors.eMin

                      case (value > 60 && value < 70): return Colors.eLgt

                      case (value > 70 && value < 80): return Colors.eMod

                      case (value > 80 && value < 90): return Colors.eIte
                    
                      default: return Colors.eMax
                    }
                  }
                }
              ]
            },
            heartRate: {
              labels,
              datasets: [
                {
                  label: 'Frecuencia Cardíaca',
                  data: heartRate,
                  backgroundColor: Colors.eMax,
                  borderColor: Colors.eMax,
                  tension: 0.4,
                }
              ]
            },
            calories: {
              labels,
              datasets: [
                {
                  label: 'calories',
                  data: calories,
                  backgroundColor: Colors.mainColor,
                  borderColor: Colors.mainColor,
                  tension: 0.4,
                }
              ]
            },
            
            zones
          }

        })
      },
      guards: {
        isUnauthorized: (context) => context.statusError === 401
      }
    }
  )

export default SessionsMachine;