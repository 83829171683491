import React, { FC, useState } from 'react'

// Dependencies
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

// types and models
import { CircularProgress } from '@mui/material';
import { Word, BanPeriode, BanUser, Training } from '../../types'
import Texts from '../../models/Texts'

// Hooks 
import useTranslation from '../../hooks/useTranslation'

interface EliminationModalProps {
  open: boolean,
  deleting: boolean,
  user: Training | null,
  handleClose: () => void,
  handleDelete: (ban: BanUser) => void
}
 
/**
 * Modal para definir cuanto tiempo se va a bannear al usuario
 * @param props contiene todas las propiedades necesarias descritas en EliminationModalProps
 * @param props.open saber si esta abierto el modal
 * @param props.deleting saber si esta eliminando a un usuario
 * @param props.handleClose función para cerrar el modal
 * @param props.handleDelete función para eliminar al usuario
 * @returns formulario para dar ban al usuario
 */
const EliminationModal: FC<EliminationModalProps> = ({ 
  open, user, deleting, handleClose, handleDelete 
}) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Guardar el valor de los radios
  const [banValue, setBanValue] = useState(BanPeriode.thisClass);

  /**
   * Cambiar tipo de baneo
   * @param value nuevo valor del check
   */
   const handleBanChange = (value: string) => {
    const banType = BanPeriode.permanent === value ? BanPeriode.permanent : BanPeriode.thisClass;
    setBanValue(banType);
  };

  return user && (
    <Dialog
      open = { open }
      onClose = { handleClose } 
    >
      <DialogTitle>
        { getTranslation(Texts.clModalTitle as Word) }
      </DialogTitle>

      <div className="p-5">
        <h5 className = "text-xl">
          { getTranslation(Texts.clModalDescription as Word) } {user.pseudonym}?
        </h5>

        <h6 className = "mb-8 mt-2 text-lg">
          { getTranslation(Texts.clModalAction as Word) }
        </h6>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="ban-periode"
            name="ban-periode"
            value = { banValue }
            onChange = { (e) => handleBanChange(e.target.value) }
          >
            <FormControlLabel
              value={ BanPeriode.thisClass }
              control={<Radio />}
              label={ getTranslation(Texts.clModalOptionOne as Word) }
              color = "default"
            />

            <FormControlLabel
              value={ BanPeriode.permanent }
              control={<Radio />}
              label={ getTranslation(Texts.clModalOptionEver as Word) }
              color = "default"
            />

          </RadioGroup>
        </FormControl>

        <div className="flex justify-between items-center mt-8">
          <button
            type = "button"
            className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
            onClick = { handleClose }
          >
            { getTranslation( Texts.cancel as Word ) }
          </button>

          { deleting 
            ? (
              <CircularProgress
                color = 'secondary'
              />
            ) 
            : (
              <button
                type = "button"
                className = "uppercase bg-title px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
                onClick = {() => handleDelete({user_uuid: user.final_user_uuid, type_banned: banValue })}
              >
                { getTranslation( Texts.delete as Word ) }
              </button>
            ) 
          }

        </div>

      </div>

    </Dialog>
  )
}
 
export default EliminationModal;