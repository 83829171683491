import React, { FC, ReactElement } from 'react'

// Dependecias
import { Link } from 'react-router-dom'

// Iconos
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Models
import Texts from '../../models/Texts'
import { Word } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface CardProps {
  icon: ReactElement,
  title: string,
  tooltip ?: string,
  detail ?: string,
}
 
/**
 * Card para mostrar estadística del centro
 * @param param0 props contiene todas las propiedades necesarias descritas en CardProps
 * @param {ReactElement} props.icon icono del card
 * @param {string} props.title titulo del card
 * @param {string} props.tooltip este puede venir o no si viene muestra la i de info en el card
 * @param {string} props.detail este puede venir o no si viene muestra un botón hasta abajo del
 * card para mostrar detalles
 * @returns cuadrito del dashboard
 */
const Card: FC<CardProps> = ({ icon, title, tooltip, detail, children }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <div>

      <div className="bg-card-header flex p-4 rounded-t-md flex items-center font-medium lg:text-xs">
        { icon }
        <p className = "ml-4 uppercase text-sm lg:text-xs">
          {title}
        </p>
      </div>

      <div className = "bg-card-body px-5 h-36 relative rounded-b-md">
        {children}

        { tooltip && (
          <div className = "absolute top-1 right-1">
            <Tooltip
              title = { tooltip }
              placement = "top"
            >
              <InfoIcon />
            </Tooltip>
          </div>
        )}

        { detail && (
          <Link to = { detail }>
            <div className="absolute bottom-0 right-0 flex items-center mb-1 text-gray-300 uppercase text-sm">
                <p>{ getTranslation( Texts.dashboardDetails as Word ) }</p>
                <KeyboardArrowRightIcon fontSize = 'small'/>
            </div>
          </Link>
        )}

      </div>

    </div>
  )
}

export default Card;