import React, { FC, useContext } from 'react'

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Types and models
import { Word, Translation } from '../../types'
import Texts from '../../models/Texts'
import SupportLinks from '../../models/Support'

// Context
import UIContext from '../../context/ui/UIContext'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface SupportProps {
  action: string
}
 
/**
 * Botón para mandar a la vista de base de conocimiento o soporte
 * @param props contiene todas las propiedades necesarias descritas en SpinnerProps
 * @param props.title si el spinner va a mostrar un texto
 * @returns Botón para mandar a la vista de base de conocimiento o soporte
 */
const Support: FC<SupportProps> = ({ action }) => {

  // hook para traducir
  const { getTranslation } = useTranslation()

  const { lang } = useContext( UIContext )

  const handleRedirect = () : void => {

    const path = getLinkByKey(action) 

    window.open( path !== '' ? path : action, '_blank' )
  }

  const getLinkByKey = (key: string) : string => {
   
    const text = SupportLinks[key] as Word

    if (text && text.en) return lang === Translation.en ? text.en : text.es

    return ''
    
  }

  return ( 
    <button
      type = 'button'
      className = 'flex items-center'
      onClick = { handleRedirect }
    >
      <div className="hidden sm:block">
        <InfoOutlinedIcon
          fontSize = 'small'
        />
      </div>

      <p className = 'text-xs sm:ml-2'>
        {getTranslation(Texts.supporInfo as Word)}
      </p>
    </button>
  )
}
 
export default Support;