import { createMachine, send, spawn, assign, sendParent, DoneInvokeEvent } from "xstate";
import * as Sentry from '@sentry/react'

// HTTP client
import axiosClient from '../../config/axios'

// types
import { SearchIn, LocalStorageKey, AuthData, Requests, Request } from '../../types'

type Ids = {
  'user_uuid': string
}

type RequetsMachineContext = {
  page: any | null,
  pages: number,
  totalPages: number,
  rows: number,
  pageData: any | null,
  search: SearchIn | null,
  searched: Requests | null,
  currentPage: number,
  pageAlreadyExists: boolean,
  users: Ids[]
}

type RequetsMachineEvents = 
  | { type: 'PAGEING', page: number }
  | { type: 'READY', config: { pages: number, rows: number }}
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'ACCEPT' }
  | { type: 'REFUSE' }
  | { type: 'RESTART' }

type CreatePageMachineContext = {
  page: number,
  requests: Requests | null,
  statusError: number,
  users: Ids[],
}

type CreatePageMachineEvents = 
  | { type: 'ACCEPT', data: Ids[] }
  | { type: 'REFUSE', data: Ids[] }
  | { type: 'REFRESH', data: Requests }
  | { type: 'READY' }

/**
 * Descargar las solicitudes
 * @param context de la maquina que controla la página
 * @returns Una lista de solicitudes
 */
const fetchRequets = async (context: CreatePageMachineContext) : Promise<Requests> => {
  
  // Sacamos el id de la sucursal
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData | null = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  // Sacamos la pagina
  const { page } = context

  const url : string = page ? `/api/v2/centers/${authData.uuidBranch}/requests?page=${page}` 
    : `/api/v2/centers/${authData.uuidBranch}/requests`

  const response = await axiosClient.get(url)

  return response.data

}

/**
 * Acptar solicitudes
 * @param context la maquina que controla la pagina
 * @returns una lista de los ids aceptados
 */
const acceptRequests = async (context: CreatePageMachineContext) : Promise<Ids[]> => {
  
  // Sacamos el id de la sucursal
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData | null = JSON.parse( rawAuth || 'null' )

  // Sacamos a los usuarios a aceptar
  const { users } = context

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  await axiosClient.post(`/api/v2/centers/${authData.uuidBranch}/requests`, {
    'users': users
  })

  return users
}

/**
 * Rechazar todas las solicitudes
 * @param context de la maquina que controla la página
 * @returns una lista de ids a sacar de la lista
 */
const rejectRequests = async (context: CreatePageMachineContext) : Promise<Ids[]> => {
  
  // Sacamos el id de la sucursal
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData | null = JSON.parse( rawAuth || 'null' )

  // Sacamos a los usuarios a aceptar
  const { users } = context

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  await axiosClient.delete(`/api/v2/centers/${authData.uuidBranch}/requests`, {
    data: {
      'users': users
    }
  })

  return users
}

/**
 * Buscar una solicitud
 * @param context de la maquina que controla la página
 * @returns una lista de solicitudes
 */
const searchForRequests = async (context: RequetsMachineContext) : Promise<Requests> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search } = context
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData | null = JSON.parse( rawAuth || 'null' )

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1' || !authData ) throw new Error()

  // Hacemos la petición
  const url : string = `/api/v2/centers/${authData.uuidBranch}/requests?search_colum=${search.search_column}&search=${search.search}`

  const response = await axiosClient.get(url) 

  return response.data

}

const searchCompundRequests = async (context: RequetsMachineContext) : Promise<Requests> => {
  
  // Sacamos el objeto a buscar ademas de la página
  const { currentPage, search } = context
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData | null = JSON.parse( rawAuth || 'null' )

  if (!search || !authData) throw new Error()

  // Formateamos la url
  let url = `/api/v2/centers/${authData.uuidBranch}/requests`
  url = `${url}?search_colum=${search.search_column}&search=${search.search}`
  url = `${url}&page=${currentPage}`

  const response = await axiosClient.get(url)

  return response.data

}

const RequetsMachine = createMachine<RequetsMachineContext, RequetsMachineEvents>(
  {
    id: 'RequetsMachine',
    initial: 'idle',
    context: {
      page: null,
      pageData: {},
      search: null,
      searched: null,
      currentPage: 0,
      pageAlreadyExists: false,
      totalPages: 0,
      pages: 0,
      rows: 0,
      users: []
    },
    states: {
      loaded: {},
      idle: {
        entry: send({ type: 'PAGEING', page: 1 })
      },
      loading: {
        always: [
          { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ]
      },
      accepting: {
        entry: [
          'collect',
          send((context) => ({ type: 'ACCEPT', data: context.users }), {
            to: (context) => context.page
          })
        ]
      },
      refusing: {
        entry: [
          'collect',
          send((context) => ({ type: 'REFUSE', data: context.users }), {
            to: (context) => context.page
          })
        ]
      },
      lookingFor: {
        invoke: {
          id: 'searchCompundRequests',
          src: searchCompundRequests,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedRequests',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      searching: {
        invoke: {
          id: 'searchForRequests',
          src: searchForRequests,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedRequests',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: [ 
              'getTheFirst',
              assign((context, event) => {

                if (event.data.response && event.data.response !== 422) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: 'No se pudo hacer la busqueda',
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: 'No se pudo hacer la busqueda',
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      }
    },
    on: {
      READY: {
        target: '.loaded',
        actions: [ 'setReady' ]
      },
      PAGEING: {
        target: '.loading',
        actions: [ 'createMachine' ]
      },
      ACCEPT: {
        target: '.accepting',
      },
      REFUSE: {
        target: '.refusing'
      },
      RESTART: {
        target: '.idle',
        actions: assign((context) => ({
            ...context,
            page: null,
            pageData: {},
            search: null,
            currentPage: 0,
            pageAlreadyExists: false,
            totalPages: 0,
            pages: 0,
            rows: 0,
            users: []
          }))
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
          ...context,
          search: event.data,
          currentPage: 1
        }))
      }
    }
  },
  {
    actions: {
      createMachine: assign((context, event) => {

        if (event.type !== 'PAGEING') return context

        if (context.search && context.search.search !== '-1')
          return {
            ...context,
            page: context.pageData.searchMachine,
            pageAlreadyExists: true,
            currentPage: event.page
          }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData[`${event.page}`]

        if (subPage) {
          return {
            ...context,
            page: subPage,
            pageAlreadyExists: true,
            currentPage: event.page
          }
        }

        // Otherwise, spawn a new subreddit actor and
        // save it in the subreddits object
        subPage = spawn( createPageMachine( event.page, 'loading' ) )

        return {
          pageData: {
            ...context.pageData,
            [`${event.page}`]: subPage
          },
          page: subPage,
          pageAlreadyExists: false,
          currentPage: event.page
        }
      }),
      setReady: assign((context, event) => {

        if (event.type !== 'READY') return context

        return {
          pages: event.config.pages > context.pages ? event.config.pages : context.pages,
          totalPages: event.config.pages > context.pages ? event.config.pages : context.pages,
          rows: event.config.rows
        }
      }),
      collect: assign((context, event) => {

        if (event.type !== 'ACCEPT' && event.type !== 'REFUSE') return { ...context }

        const uuids : Ids[] = []

        Object.values( context.pageData ).forEach(( actor: any ) => {
          
          const value = actor.getSnapshot()

          value.context.requests.data.forEach((request: Request) => {
            
            uuids.push({
              'user_uuid': request.uuid
            })

          });

        })

        return {
          ...context,
          users: uuids
        }

      }),
      searchedRequests: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Requests> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (
          event.type !== "done.invoke.searchForRequests" 
          && event.type !== 'done.invoke.searchCompundRequests'
        ) 
          return { ...context }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, 'loaded' ) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      }),
      getTheFirst: assign((context) => {

        let first: any = Object.values(context.pageData)[0]
        first = first.getSnapshot()

        return {
          ...context,
          currentPage: 1,
          page: Object.values(context.pageData)[0],
          pages: context.totalPages,
          rows: first.context.requests?.data.length || 0 
        }

      })
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      }
    }
  }
)

const createPageMachine = (page: number, initial: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvents>(
  {
    id: 'PageMachine',
    initial,
    context: {
      page,
      requests: null,
      statusError: 0,
      users: [],
    },
    states: {
      login: {},
      loaded: {},
      accepted: {},
      refused: {},
      loading: {
        invoke: {
          id: 'fetchRequets',
          src: fetchRequets,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent((context) => ({ 
                type: 'READY', 
                config: {
                  pages: context.requests?.meta.last_page || 0,
                  rows: context.requests?.data.length || 0
                } 
              }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              sendParent({ type: 'READY', pages: -1 }),
              assign((context, event) => {

                const errorMessage : string = 'No se pudieron descargar las solicitudes'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      accepting: {
        invoke: {
          id: 'acceptRequests',
          src: acceptRequests,
          onDone: {
            target: 'accepted',
            actions: [
              'updateDate', 
              sendParent((context) => ({ 
                type: 'READY', 
                config: {
                  pages: context.requests?.meta.last_page || 0,
                  rows: context.requests?.data.length || 0
                } 
              }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              assign((context, event) => {

                const errorMessage : string = 'No se pudo aceptar la solicitud'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      refusing: {
        invoke: {
          id: 'rejectRequests',
          src: rejectRequests,
          onDone: {
            target: 'refused',
            actions: [
              'updateDate', 
              sendParent((context) => ({ 
                type: 'READY', 
                config: {
                  pages: context.requests?.meta.last_page || 0,
                  rows: context.requests?.data.length || 0
                } 
              }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              assign((context, event) => {

                const errorMessage : string = 'No se pudo rechazar la solicitud'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      ACCEPT: {
        target: '.accepting',
        actions: assign({ users: (context, event) => event.data })
      },
      REFUSE: {
        target: '.refusing',
        actions: assign({ users: (context, event) => event.data })
      },
      REFRESH: {
        target: '.loaded',
        actions: [ 
          'preparingData',
          sendParent((context) => ({ 
            type: 'READY', 
            config: {
              pages: context.requests?.meta.last_page || 0,
              rows: context.requests?.data.length || 0
            } 
          })) 
        ]
      }
    }
  },
  {
    actions: {
      preparingData: assign((context, _event: any) => {
        
        // Cargamos el evento
        const event : DoneInvokeEvent<Requests> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.fetchRequets" && event.type !== "REFRESH") 
          return context

        // Combinamos los apellidos
        const requests : Request[] = event.data.data.map((request: Request) => {

          let first = '-'
          let second = ''

          if (request.last_name_1 && request.last_name_1 !== 'null')
            first = request.last_name_1

          if (request.last_name_2 && request.last_name_2 !== 'null')
            second = request.last_name_2

          return {
            ...request,
            lastName: `${first} ${second}`
          }
        })

        // Spread en event.data para combiar las links y meta
        return {
          ...context,
          requests: {
            ...event.data,
            data: requests
          }
        }
      }),
      updateDate: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Ids[]> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.acceptRequests" 
          && event.type !== "done.invoke.rejectRequests") 
          return context

        // Sacamos la data para filtrarla
        let data = context.requests ? context.requests.data : []

        event.data.forEach((user: Ids) => {

          data = data.filter((request: Request) => request.uuid !== user.user_uuid)

        })

        return {
          ...context,
          requests: {
            ...context.requests!,
            data
          }
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

export default RequetsMachine;