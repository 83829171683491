import React, { FC, ReactElement } from 'react'

// Dependencies
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// Types
import { ChartData } from '../../types';

// Components
import Container from '../layout/Container'
import PieChart from '../charts/PieChart'
import LineChart from '../charts/LineChart'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface ChartsProps {
  sessions: ChartData
  trainings: ChartData
  maximize: (isPie: boolean) => void,
}
 
/**
 * Contenedor de la gráfica de puntos y de pie
 * @param props contiene todas las propiedades necesarias descritas en ChartsProps
 * @param props.sessions data a mostrar en la gráfica de puntos
 * @param props.trainings data a mostrar en la gráfica de pie
 * @param props.maximize función para maximizar una gráfica
 * @returns formulario de rango de fechas
 */
const Charts: FC<ChartsProps> = ({ sessions, trainings, maximize }) => {

  // hook para traducir
  const { getTranslationByKey } = useTranslation()

  /**
   * Render el ico de maximizar
   * @param key saber si vamos a maximizar una de pie o no
   * @returns icono para maximizar
   */
  const renderFullScreenButton = (key: string) : ReactElement => (
    <Tooltip title="Maximizar">
      <IconButton
        onClick = { () => maximize( key !== 'line' ) }
      >
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  )

  return ( 
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10">
      <Container
        title = { getTranslationByKey('reportsSessionsHistory') }
        uppercase
        rightButton = { renderFullScreenButton('line') }
      >
        <LineChart 
          data = { sessions }
        />
      </Container>

      <Container
        title = { getTranslationByKey('reportsTypeTraining') }
        uppercase
        rightButton = { renderFullScreenButton('pie') }
      >
        <PieChart data = { trainings }/>
      </Container>
    </div>
   );
}
 
export default Charts;