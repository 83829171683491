import React, { FC } from 'react'

// Dependencias
import { CircularProgress } from '@mui/material';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';

// Validation
import { Formik } from "formik";

// types
import Texts from '../../models/Texts'
import { CurrentUser, Word } from '../../types'

// Componentes
import InputForm from '../utils/RookFormInputs/InputForm';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validations
import validationSchema from '../../validations/ValidationsProfileForm'

// Utils
import { capitalize } from '../../utils'

interface ProfileFormProps {
  currentUser: CurrentUser,
  spinner: boolean
  handleUpdate: (user: CurrentUser) => void,
}

/**
 * Formulario de actualizar perfil
 * @param props contiene todas las propiedades necesarias descritas en ProfileFormProps
 * @param props.currentUser usuario loggeado 
 * @param props.spinner saber si se esta actualizando el perfil
 * @param props.handleUpdate función para actualizar el perfil
 * @returns formulario de perfil
 */
const ProfileForm: FC<ProfileFormProps> = ({ currentUser, handleUpdate, spinner }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <Formik 
      initialValues={{
        name: capitalize(currentUser.name),
        last_name_1: capitalize(currentUser.last_name_1),
        last_name_2: capitalize(currentUser.last_name_2 || ''),
        landline: currentUser.landline || '',
        mobile_phone: currentUser.mobile_phone,
        email: currentUser.email
      }}
      validationSchema = { validationSchema }
      onSubmit={ 
        values => handleUpdate({...values, uuid: currentUser.uuid, role: currentUser.role})
      }    
    >
      { props => (

        <form
          onSubmit = { props.handleSubmit }
        >

          <InputForm 
            type="text"
            placeholder={getTranslation( Texts.profileNameInputPlaceHolder as Word )}
            inputMode="text"
            id="name"
            icon={<PersonIcon />}
            title={ getTranslation( Texts.profileNameInput as Word ) }
            value={props.values.name}
            handleBlur={props.handleBlur}
            handleChange={props.handleChange} 
            isError={(props.touched.name && !!props.errors.name )} 
            errorText={ props.errors.name === "profileNameInputError" 
              ? getTranslation(Texts.profileNameInputError as Word)
              : getTranslation(Texts.profileNameInputErrorLength as Word)
            }           
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-8">

            <InputForm 
              type="text"
              placeholder = {getTranslation( Texts.profileLastNameInputPlaceHolder as Word )}
              inputMode = "text"
              id="last_name_1" 
              icon={ <PersonIcon /> } 
              title={ getTranslation( Texts.profileLastNameInput as Word ) }
              value = { props.values.last_name_1 } 
              handleBlur = { props.handleBlur }  
              handleChange = { props.handleChange }  
              isError={(props.touched.last_name_1 && !!props.errors.last_name_1 )} 
              errorText={ props.errors.last_name_1 === "profileLastNameInputError" 
                ? getTranslation(Texts.profileLastNameInputError as Word)
                : getTranslation(Texts.profileLastNameInputErrorLength as Word)
              }   
            />

            <InputForm 
              type="text"
              placeholder = {getTranslation( Texts.profileMLastNameInputPlaceHolder as Word )}
              inputMode = "text"
              id="last_name_2" 
              icon={ <PersonIcon /> } 
              title={ getTranslation( Texts.profileMLastNameInput as Word ) } 
              value = { props.values.last_name_2 } 
              handleBlur = { props.handleBlur }  
              handleChange = { props.handleChange }
              isError={(props.touched.last_name_2 && !!props.errors.last_name_2 )} 
              errorText={ props.errors.last_name_2 === "profileMLastNameInputError" 
                ? getTranslation(Texts.profileMLastNameInputError as Word)
                : getTranslation(Texts.profileMLastNameInputErrorLength as Word)
              }      
            />

          </div>

          <InputForm 
            type="email"
            placeholder = {getTranslation( Texts.profileEmailPlaceHolder as Word )}
            id="email" 
            inputMode = "email"
            readOnly
            icon={ <EmailIcon /> } 
            title={getTranslation( Texts.loginEmail as Word )}    
            value = { props.values.email } 
            handleBlur = { props.handleBlur }  
            handleChange = { props.handleChange } 
            isError={(props.touched.email && !!props.errors.email )} 
            errorText={ props.errors.email === "loginEmailRequiredError" 
              ? getTranslation(Texts.loginEmailRequiredError as Word)
              : getTranslation(Texts.loginEmailValidError as Word)
            }    
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-8">

            <InputForm 
              type="tel"
              placeholder = {getTranslation( Texts.profileLandlinePlaceHolder as Word )}
              id="landline" 
              inputMode = "tel"
              icon={ <PhoneIcon /> } 
              title={getTranslation( Texts.profileLandline as Word )}     
              value = { props.values.landline }  
              handleBlur = { props.handleBlur }  
              handleChange = { props.handleChange }  
              isError={(props.touched.landline && !!props.errors.landline )} 
              errorText={ props.errors.landline === "profileLandlineErrorLength" 
                ? getTranslation(Texts.profileLandlineErrorLength as Word)
                : getTranslation(Texts.profileLandlineError as Word)
              }    
            />

            <InputForm 
              type="tel"
              placeholder = {getTranslation( Texts.profileMobilePhonePlaceHolder as Word )}
              id="mobile_phone" 
              inputMode = "tel"
              icon={ <PhoneAndroidIcon /> } 
              title={getTranslation( Texts.profileMobilePhone as Word )} 
              value = { props.values.mobile_phone }
              handleBlur = { props.handleBlur }  
              handleChange = { props.handleChange } 
              isError={(props.touched.mobile_phone && !!props.errors.mobile_phone )} 
              errorText={ props.errors.mobile_phone === "profileMobilePhoneErrorLength" 
                ? getTranslation(Texts.profileMobilePhoneErrorLength as Word)
                : getTranslation(Texts.profileMobilePhoneError as Word)
              }       
            />

          </div>

          <div className = "mt-8 flex justify-center">

            { spinner 
              ? (<CircularProgress />)  
              : (
                <button
                    type = "submit"
                    className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
                  >
                    {getTranslation( Texts.save as Word )}
                  </button>
              ) 
            }

          </div>
        </form>
      )}
    </Formik>
  )
}
 
export default ProfileForm;