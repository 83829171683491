import React, { FC, useState, useEffect } from 'react'

// Dependencies
import { useActor } from '@xstate/react';
import { useHistory } from 'react-router-dom'
import GoogleMapReact from 'google-map-react';
import { CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material';


// Icons
import RoomIcon from '@mui/icons-material/Room';

// Types and modles
import Texts from '../../models/Texts'
import { Word } from '../../types'

// componentes
import InputForm from '../utils/RookFormInputs/InputForm'

// hooks
import useTranslation from '../../hooks/useTranslation'

interface CenterAddressProps {
  service: any
}

/**
 * Form para actualizar la dirección con el mapa de google
 * @param {Object} props contiene todas las propiedades necesarias descritas en CenterInfoProps
 * @param {Any} props.service contiene al actor para controlar este componente
 * @returns Formulario para editar la dirección del centro
 */
const CenterAddress: FC<CenterAddressProps> = ({ service }) => {

  // Hook para invocar el automata para este componente
  const [current, send] = useActor(service)

  // Sacamos el context y el estado de la maquina
  const { context, value } : any = current

  // Address
  const [address, setAddress] = useState(context.address || '')

  // Alerta
  const [alert, setAlert] = useState(false)

  // Saber si mostrar el botón de buscar o no
  const [ready, setReady] = useState(false)

  // hook para traducir
  const { getTranslation, getTranslationByKey } = useTranslation()

  // Navegación
  const history = useHistory()

  useEffect(() => {
    
    if (value === 'lost' || value === 'failure' || value === 'updated') setAlert(true)
    else setAlert(false)

    if (value === 'preparing' || value === 'translating')
      setReady(false)
    else setReady(true)

    if (value === 'login') history.push('/login')

  }, [value])

  useEffect(() => {
    
    if (context.address !== address)
      setAddress( context.address )

  }, [context])

  // ComponentDidUnMount para indicar a la maquina volver al estado inicial
  useEffect(() => () => {
      send({ type: 'READY' })
    }, [])

  /**
   * Iniciarlizar el mapa
   * @param map elemento que se esta renderizando
   * @param maps las proridades del mapa
   */
  const handleMapLoaded = (map: any, maps: any) => {
    
    const marker = new maps.Marker({
      map,
      position: new maps.LatLng( context.coords.lat,  context.coords.lng),
      draggable: true
    });

    maps.event.addListener(marker, "dragend", (e: any) => {
      send({
        type: 'DRAGGED',
        data: { lat: e.latLng.lat(), lng: e.latLng.lng()}
      })
    });

    send({
      type: 'INIT',
      data: {
        maps,
        marker,
        geocoder: new maps.Geocoder(),
      }
    })

  }

  /**
   * Traducir la dirección
   * @returns early return
   */
  const translateAddress = () : void => {
    if (address === '') return
    
    send({
      type: 'TRANSLATE',
      data: address
    })

  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
  const getSeverity = () : AlertColor => {
    switch (value) {
      case 'lost': return 'warning'
      case 'failure': return 'error'
      case 'updated': return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (value) {
      case 'lost': return getTranslation( Texts.centerAddressBranchError as Word )
      case 'updated': return getTranslation(Texts.centerAddressUpdatedSuccessfully as Word)
      default: return getTranslation( Texts.generalError as Word )
    }
  }

  const handleClose = () : void => {
    setAlert(false)

    setTimeout(() => {
      send({ type: 'READY' })
    }, 120)

  }

  return ( 
    <>

      <Snackbar
        open = { alert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { handleClose }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { handleClose }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <InputForm 
        type='text' 
        placeholder={ getTranslation(Texts.centerAddressBranch as Word) }
        id='address' 
        title={ getTranslation(Texts.centerAddressBranch as Word) }
        icon = { <RoomIcon /> }
        extraButtonText = { !ready ? '' : getTranslationByKey('locate')}
        value={ address }
        readOnly = { !ready }
        handleChange={(e) => setAddress(e.target.value)} 
        handleBlur={(e) => setAddress(e.target.value)}
        handleExtraButton = { translateAddress }   
      />

      <p className = "mt-8">
        { getTranslation( Texts.centerAddressBranchLegend as Word ) }
      </p>

      <div className="h-96 w-full mt-10">
        <GoogleMapReact
          bootstrapURLKeys = {{ key: process.env.REACT_APP_MAPS_KEY }}
          center = {{ lat: context.coords.lat, lng: context.coords.lng }}
          defaultZoom = { 14 }
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleMapLoaded(map, maps)}
        />
      </div>

      <div className="mt-8 md:8 flex justify-center">

        { value === 'updating' 
          ? (<CircularProgress />) 
          : (
            <button
              type = "button"
              className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
              onClick = { () => send({ type: 'UPDATE' }) }
            >
              {getTranslation( Texts.save as Word )}
            </button>
          ) 
        }
        
      </div>

    </>
  );
}

export default CenterAddress;