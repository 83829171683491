import React, { FC, ReactElement } from 'react'
import { RookTooltip, RookTooltipContainer, RookTooltipList, RookTooltipTitle } from '../../utils/RookTooltip';

interface HtmlTooltipProps {
  title: string,
  list: string[],
  children: ReactElement
}
 
const HtmlTooltip: FC<HtmlTooltipProps> = ({ title, list, children }) => {
  return ( 
    <RookTooltip
      title = {
        <RookTooltipContainer>
          <RookTooltipTitle title = { title } />
          <RookTooltipList list={ list }/>
        </RookTooltipContainer>
      }
    >
      { children }
    </RookTooltip>
  );
}
 
export default HtmlTooltip;