import React, { ReactElement, FC, useState } from 'react'

// Icons
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputError from './InputError';

interface InputChangePasswordFormProps {
  placeholder: string,
  id: string,
  title: string
  notLock ?: boolean
  labelClass ?: string
  icon ?: ReactElement,
  leftIcon ?: ReactElement,
  isError ?: boolean,
  errorText ?: string,
  background ?: string,
  marginTop ?: string,
  value: any,
  handleChange: (e: any) => void,
  handleBlur: (e: any) => void,
}
 
/**
 * Input con label
 * @param props contiene todas las propiedades necesarias descritas en
 * InputChangePasswordFormProps
 * @param props.placeholder placeholder el input
 * @param props.id del input
 * @param props.title que va en con el input
 * @param props.icon que va a la derecha del input
 * @param props.leftIcon que va a la izquierda del input
 * @param props.value valor del input
 * @param props.isError saber si se debe mostrar el error en el input
 * @param props.errorText texto del error
 * @param props.notLock saber si se tiene que mostrar el icono del candado a lado izquierdo
 * @param props.background del input si se ocupa otra a la del defecto
 * @param props.marginTop cambiar el margin por defecto
 * @param props.labelClass cambiar las clases del label
 * @param props.handleChange función para cambiar el valor del input
 * @param props.handleBlur función para cambiar el valor del input
 * @returns input para formularios
 */
const InputChangePasswordForm: FC<InputChangePasswordFormProps> = ({ 
  placeholder, id, title, icon, value, isError, errorText, notLock, leftIcon,
  background = "bg-input-background", marginTop = "mt-8", labelClass = '',
  handleChange, handleBlur
 }) => {

  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className = {`flex flex-col ${marginTop}`}>
  
      <label 
        htmlFor={ id }
        className = {`uppercase ${labelClass}`}
      >
        { title }
      </label>

      <div 
        className={`flex items-center ${background} py-2 px-3 rounded-xl mt-2 border 
        ${ isError ? 'border-red-600' :'hover:border-white border-transparent '}`}
      >

        { !notLock && (
          <LockIcon />
        )}

        { leftIcon && (
          leftIcon
        )}
        
        <input
          type={ isVisible ? 'text' : 'password' }
          name={ id }
          id={ id }
          className = {`appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${background} focus:${background}`}
          placeholder = { placeholder }
          value = { value }
          onChange = { handleChange }
          onBlur = { handleBlur }
        />

        { icon || (
          <button
            type = "button"
            onClick = { () => setIsVisible( !isVisible ) }
          >
            { isVisible 
              ? (
                <VisibilityOffIcon />
              ) 
              : (
                <VisibilityIcon />
              ) 
            }
          </button>
          
        ) 
      }

    </div>

    { isError
        && (
          <InputError
            colorLabel = "text-red-500"
          >
            { errorText }
          </InputError>
        ) 
      }
  </div>
  );
}
 
export default InputChangePasswordForm;