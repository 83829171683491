import { createMachine, assign, DoneInvokeEvent } from "xstate";

// Dependencies
import 'moment-timezone';
import moment from "moment";
import * as Sentry from "@sentry/react";

// Types
import { 
  AuthData, 
  CurrentUser, 
  DashboardData, 
  FoundAdmin, 
  InitApp, 
  LocalStorageKey,
  Features,
  CenterData
} from "../../types";

// Axios
import axiosClient from '../../config/axios'
import tokenLevelAuth from '../../config/authTokenLevel'
import tokenAdminAuth from '../../config/authTokenAdmin'
import acceptedAdminLang from '../../config/acceptedAdminLang'
import branchUUID from '../../config/uuidBranch'

type InitAppMachineEvent = 
 | { type: 'DUMMY'}

 /**
  * currentUser: usuario actual
  * data: data de los cuadritos del dashboard
  * admin: centro encontrado
  * errorStatus: error que da la API
  * permissions: permissions que tiene el usuario
  * showAgreement: saber si mostrar los acuerdos o no
  * features: saber los features activados
  */
type InitAppMachineContext = {
  currentUser: CurrentUser | null,
  centerData: CenterData | null,
  data: DashboardData | null,
  admin : FoundAdmin | null,
  errorStatus ?: number,
  permissions: string[],
  showAgreement: boolean,
  features: Features,
  uuidBranch: string
}

/**
 * Obtener datos del centro
 * @returns Información del admin que estamos visitando
 */
const fetchAdmin = async () : Promise<FoundAdmin> => {

  // Leemos la url para saber que admin es
  let admin : string = window.location.hostname.split('.')[0]

  if( admin === "localhost" ) 
    admin = `${process.env.REACT_APP_HOST}.${process.env.REACT_APP_DOMAIN}`
    
  else admin = `${admin}.${process.env.REACT_APP_DOMAIN}`

  const response = await axiosClient.get(`api/v2/admin/${admin}/search`)

  return response.data
}

/**
 * Refrescamos el token
 */
const refreshToken = async () : Promise<string> => {
  
  // Leemos
  const auth : AuthData = JSON.parse(localStorage.getItem(LocalStorageKey.auth) || 'null')
  
  if (!auth) throw new Error()

  // Cargamos el token en el header 
  tokenAdminAuth(auth.tokenAdmin)


  if (auth.rememberme) {

    // Solicitamos el refresco del token
    const response = await axiosClient.post('/api/v2/auth/admin/refresh')

    // Actualizamos el token en local storage
    auth.tokenAdmin = response.data.token_user

    // Actualizamos los roles
    auth.role = response.data.role

    // Actualizamos los permisos
    auth.permissions = response.data.permissions

    // Actualizamos los features enabled
    auth.features_enabled = response.data.features_enabled

    // Actualizamos el token en axios
    tokenAdminAuth(response.data.token_user)

    // Guardamos el token el localstorage
    localStorage.setItem(LocalStorageKey.auth, JSON.stringify(auth))

  }

  branchUUID(auth.uuidBranch)

  return auth.uuidBranch
}

/**
 * Preparar los datos del cluster
 * @returns Los datos de los clusters
 */
 const fetchDashboardData = async () : Promise<InitApp> => {

  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const [ dashboard, currentUser, center ] = await Promise.all([
    axiosClient.get('/api/v2/admin/dashboard'),
    axiosClient.get(`/api/v2/admin/user/${authData.uuidUser}`),
    axiosClient.get(`/api/v2/centers/${authData.uuidBranch}`)
  ])

  return {
    centerData: center.data,
    dashboard: dashboard.data,
    currentUser: currentUser.data,
    permissions: authData.permissions,
    features: authData.features_enabled ? authData.features_enabled : {}
  }

}

/**
 * It updates the offset of the center if the offset of the center is different from the offset of the
 * timezone of the center
 * @param {InitAppMachineContext} context - InitAppMachineContext
 */
const updateOffset = async (context: InitAppMachineContext) : Promise<CenterData | null> => {

  const { centerData } = context

  if (centerData) {

    const minutesOffset = moment().tz(centerData.offset_name).utcOffset()
    const hourOffset = minutesOffset / 60
    const offset = hourOffset >= 0 ? `+${hourOffset}` : `${hourOffset}`

    if ( hourOffset !== Number(centerData.offset) ) {
      await axiosClient.put(
        `/api/v2/branch/${centerData.branch_uuid}/update-offset`,
        {
          offset_name: centerData.offset_name,
          offset,
        }
      ) 

      return {
        ...centerData,
        offset
      }
    }

  }

  return centerData
  
}

const InitAppMachine = createMachine<InitAppMachineContext, InitAppMachineEvent>(
  {
    id: 'login',
    initial: 'checking',
    context: {
      data: null,
      admin: null,
      centerData: null,
      errorStatus: 0,
      currentUser: null,
      permissions: [],
      showAgreement: false,
      features: {},
      uuidBranch: ''
    },
    states: {
      reviewing: {
        invoke: {
          id: 'updateOffset',
          src: updateOffset,
          onDone: {
            target: 'success',
            actions: [
              assign({ centerData: (_, event) => event.data })
            ]
          },
          onError: {
            target: 'success',
          }
        }
      },
      preparing: {
        invoke: {
          id: 'dashboardData',
          src: fetchDashboardData,
          onDone: {
            target: 'reviewing',
            actions: ['handleDashboardData']
          },
          onError: {
            target: 'failure',
            actions: [
              'sendError',
              assign((context, event) => {

                const message : string = 'No se descargó la data del dashboard.'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${message}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              }),
            ]
          }
        }
      },
      refreshing: {
        invoke: {
          id: "refreshToken",
          src: refreshToken,
          onDone: {
            target: 'preparing',
            actions: [
              assign({ uuidBranch: (_, event) => event.data })
            ]
          },
          onError: {
            target: 'failure',
            actions: ['deleteAuth']
          }
        }
      },
      continue: {
        always: [
          { target: 'refreshing', cond: 'isAnActiveAdmin'},
          { target: 'disabled', cond: 'isAnDisabledAdmin'}
        ]
      },
      loading: {
        invoke: {
          id: 'fetchAdmin',
          src: fetchAdmin,
          onDone: {
            target: 'continue',
            actions: ['onLoaded']
          },
          onError: {
            target: 'failure',
            actions: [ 
              'sendError',
              assign({ errorStatus: (_, event) => event.data.response ? event.data.response.status : 10  })
            ]
          }
        }
      },
      checking: {
        always: [
          { target: 'qrbox', cond: 'isQR' },
          { target: 'loading', cond: 'isNotQR' }
        ]
      },
      qrbox: {},
      unavailable: {},
      disabled: {},
      failure: {
        always: [
          { target: 'unavailable', cond: 'isValidDomain' },
        ],
        after: {
          1000: {
            target: 'success'
          }
        }
      },
      success: {
        type: 'final',
      }
    }
  },
  {
    actions: {
      sendError: assign((context, event) => {

        Sentry.captureException(event)

        return {
          ...context
        }
      }),
      onLoaded: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<FoundAdmin> = _event

        // Si no se mando llamar del donde fetch admin hacemos un early return
        if (event.type !== "done.invoke.fetchAdmin") return context

        // Cargamos el token en el axios
        tokenLevelAuth(event.data.token)

        // Cargamos el idioma
        acceptedAdminLang(event.data.lang)

        // Revisamos la bandera de show agreement
        const rawAuth = localStorage.getItem( LocalStorageKey.auth )
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        // Actualizamos el context de la maquina
        return {
          ...context,
          admin: event.data,
          showAgreement: authData ? authData.showAgreement : false
        }
      }),
      handleDashboardData: assign((context, _event:any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<InitApp> = _event

        // Si no se mando llamar del donde fetch admin hacemos un early return
        if (event.type !== "done.invoke.dashboardData") return context

        // Actualizamos el context de la maquina
        return {
          ...context,
          data: event.data.dashboard,
          currentUser: event.data.currentUser,
          permissions: event.data.permissions,
          features: event.data.features,
          centerData: event.data.centerData
        }

      }),
      deleteAuth: assign((context) => {

        localStorage.removeItem(LocalStorageKey.auth)

        return {
          ...context
        }
      })
    },
    guards: {
      isAnActiveAdmin: (context) => !!context.admin && context.admin.status === 1, 
      isAnDisabledAdmin: (context) => !!context.admin && context.admin.status === 0, 
      isValidDomain: (context) => !!context.errorStatus && context.errorStatus === 422,
      isQR: () => window.location.hostname.split('.')[0] === 'qrbox',
      isNotQR: () => window.location.hostname.split('.')[0] !== 'qrbox',
    }
  }
)

export default InitAppMachine
