import React, { FC } from 'react'
import { RBox } from '../../utils/RookLayout';
import BoardPodiumPlace from './BoardPodiumPlace';

type Winner = {
  name: string,
  position: 'first' | 'second' | 'third'
  image ?: string
}

interface BoardPodiumProps {
  podium: {
    first: Winner,
    second: Winner,
    third: Winner 
  }
}
 
const BoardPodium: FC<BoardPodiumProps> = ({ podium }) => {
  return ( 
    <RBox
      className='my-8 items-end justify-center gap-4'
    >
      <BoardPodiumPlace
        name = { podium.second.name }
        position = { podium.second.position }
        image = { podium.second.image }
      />

      <BoardPodiumPlace
        name = { podium.first.name }
        position = { podium.first.position }
        image = { podium.first.image }
      />

      <BoardPodiumPlace
        name = { podium.third.name }
        position = { podium.third.position }
        image = { podium.third.image }
      />

    </RBox>
  );
}
 
export default BoardPodium;