/* eslint-disable camelcase */
import { ReactElement } from "react";
import Colors from "../utils/colors";

export interface Range {
  limitTop: number;
  limitBottom: number;
  color: Colors;
  label: Word;
}

export interface Word {
  label: string;
  es: string;
  en: string;
}

export enum Translation {
  en = "en",
  es = "es",
}

export enum Gender {
  male = "male",
  female = "female",
  choose = "choose",
}

export enum LocalStorageKey {
  auth = "auth",
  rookRemoteRecent = "rookRemoteRecent",
  rookLegendRecent = "rookLegendRecent",
  training = "training",
  delay = "delay",
}

export enum Order {
  calories = "calories",
  jumps = "jumps",
  effort = "effort",
}

export enum ClusterIcon {
  boots = "boots",
  steps = "steps",
  trampoline = "trampoline",
}

export enum BanPeriode {
  thisClass = "this_class",
  permanent = "permanent",
}

export enum Ownership {
  owned = "owned",
  center = "center",
  borrowed = "borrowed",
}

export enum Flow {
  desc = "desc",
  asc = "asc",
  normal = "normal",
}

export enum SearchTypeBox {
  date,
  text,
  drop,
}

export interface Action {
  type: string;
  payload: any;
}

export interface FoundAdmin {
  token: string;
  hostname: string;
  name: string;
  lang: Translation;
  url: string;
  measurement_system: string;
  status: number;
}

export interface LoginBody {
  email: string;
  password: string;
  rememberme: boolean;
}

export interface MenuOption {
  key: string;
  title: string;
  icon: ReactElement;
  action?: string;
  sub?: boolean;
  parent?: string;
  always: boolean;
  feature?: string;
  hasPermissions?: boolean;
}

export interface Features {
  [key: string]: boolean | null;
}

export interface AuthData {
  rememberme: boolean;
  tokenAdmin: string;
  uuidUser: string;
  uuidBranch: string;
  permissions: string[];
  role: string[];
  showAgreement: boolean;
  features_enabled: Features;
}

export interface DashboardData {
  request_accepted_this_month: number;
  request_pending: number;
  remote_active_users: number;
  trainings_count: number;
  total_user: number;
  active_users: number;
}

export interface OptionSearchBar {
  key: string;
  title: string;
  searchTypeBox?: SearchTypeBox;
}

export interface OptionSearchBarDrop {
  key: string;
  value: string;
}

export interface Athlete {
  name: string;
  email: string;
  pseudonym: string;
  birthday: string;
  sex: Gender;
  weight?: number;
  height?: number;
  banned_user?: number;
  user_uuid?: string;
  last_name_1: string;
  last_name_2: string;
  lastName?: string;
  resting_heart_rate?: number;
  team?: string;
  url_image: string | null;
}

export interface FullAthlete {
  name: string;
  email: string;
  pseudonym: string;
  birthday: string;
  sex: Gender;
  user_uuid: string;
  last_name_1: string;
  last_name_2: string;
  physiological_variables: {
    weight: number;
    height: number;
    resting_heart_rate: number;
  };
}

export interface Links {
  first: string;
  last: string;
  prev?: string;
  next: string;
}

export interface SubLink {
  url?: string;
  label: string;
  active: boolean;
}

export interface Meta {
  current_page: number;
  last_page: number;
  per_page: number;
  from: number;
  links: SubLink[];
  path: string;
  to: number;
  total: number;
}

export interface Athletes {
  data: Athlete[];
  links: Links;
  meta: Meta;
}

export interface SearchIn {
  search_column: string;
  search: string;
}

export interface CurrentUser {
  uuid: string;
  name: string;
  email: string;
  role: string;
  status?: boolean;
  landline?: string;
  last_name_1: string;
  last_name_2?: string;
  mobile_phone: string;
  email_verified?: boolean;
  image_url?: string;
}

export interface InitApp {
  centerData: CenterData;
  dashboard: DashboardData;
  currentUser: CurrentUser;
  permissions: string[];
  features: Features;
}

export interface UpdtedImage {
  result: "updated";
  url: string;
}

export interface UpdatePassword {
  password_current: string;
  password_confirmation: string;
  password: string;
}

export interface Administrator {
  uuid: string;
  name: string;
  email: string;
  created: string;
  status?: boolean;
  landline?: string;
  lastName?: string;
  role: string;
  last_name_1: string;
  last_name_2?: string;
  mobile_phone: string;
  email_verified?: boolean;
}

export interface Administrators {
  data: Administrator[];
  links: Links;
  meta: Meta;
}

export interface Session {
  start: string;
  email: string;
  training_uuid: string;
  user_name: string;
  last_name_1: string;
  last_name_2: string;
  device_type: string;
  lastName?: string;
  sensor_name: string;
  training_name: string;
  status: number;
  offset: string | null;
}

export interface Sessions {
  data: Session[];
  links: Links;
  meta: Meta;
}

export interface Coach {
  uuid: string;
  name?: string;
  couch_name?: string;
}

export interface RoomBody {
  room_name: string;
  type_room: string;
  create_by: string;
  branch_uuid: string;
  remote_status: number;
  status: number;
  description?: string;
  couches: { uuid: string }[];
}

export interface ClassConfig {
  uuid?: string;
  channel: string;
  capacity: number;
  duration: number;
  data_order: string;
  class_delay: number;
  class_start_at: string;
  class_finish_at: string;
  remote_state?: number;
  steps_icon: ClusterIcon | null;
  step_options: ClusterIcon | null;
  streaming: boolean;
  meet_number?: string;
  meet_password?: string;
  signature?: string;
}

export interface Room {
  uuid: string;
  room_uuid?: string;
  name: string;
  status: number;
  updated?: string;
  couches?: Coach[];
  description?: string;
  type_room?: any;
  daq_ip?: string;
  logo_image?: string;
  background_image?: string;
  couches_asigned?: Coach[];
  class_configuration?: ClassConfig;
}

export interface Location {
  uuid?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_line_3?: string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
  location_point: string;
  status?: number;
}

export interface Multimedia {
  uuid: string;
  name: string;
  url: string;
  media_type: string;
  usage_type: string;
}

export interface Role {
  name: string;
}

export interface ZoomCredentials {
  uuid: string;
  email: string;
  api_key: string;
  secret_key: string;
}

export interface ZoomCredentialsBody {
  email: string;
  api_key: string;
  secret_key: string;
}

export interface CenterData {
  chain_uuid: string;
  image_url?: string;
  chain_name: string;
  branch_uuid: string;
  branch_name: string;
  lang_types: number;
  branch_description?: string;
  measurement_system: string;
  rooms: Room[];
  contacts: any[];
  location: Location;
  multimedia: Multimedia[];
  couches: Coach[];
  roles: Role[];
  credential_zoom?: ZoomCredentials;
  offset: string;
  offset_name: string;
}

export interface Request {
  uuid: string;
  name: string;
  pseudonym: string;
  status: string;
  last_name_1: string;
  last_name_2?: string;
  lastName?: string;
  email: string;
  sex: string;
  birthday: string;
}

export interface Requests {
  data: Request[];
  links: Links;
  meta: Meta;
}

export interface Configuration {
  user_capacity_status: number;
  training_type_uuid: string;
  class_delay: number;
  data_order: Order;
  capacity?: number;
  duration: number;
  streaming: boolean;
}

export interface Steps {
  enabled: boolean;
  steps_types: string;
}

export interface TrainingType {
  training_type_uuid: string;
  trainig_name: string;
  use_heart_rate: number;
  use_steps?: Steps;
  use_gps: boolean;
  use_cycling: boolean;
  created_at: string;
  updated_at: string;
}

export interface TrainingTypeList {
  data: TrainingType[];
  links: Links;
  meta: Meta;
}

export interface Subscription {
  result: string;
  uuid: string;
  channel: string;
  remaining_waiting_seconds: string;
  meet_number?: number;
  meet_password?: string;
  meet_url?: string;
  signature?: string;
}

export interface Training {
  final_user_uuid: string;
  pseudonym: string;
  email: string;
  steps_tot?: number;
  calories_tot: number;
  hr: number;
  effort: number;
  status: boolean;
  user_url_image?: string;
}

export interface TypeSession {
  training_name: string;
  step_options: string | null;
  use_steps: number;
  use_heart_rate: number;
  use_gps: number;
  use_cycling: number;
}

export interface Record {
  heart_rate: string;
  effort: string;
  calories: string;
  heart_rate_variability: string;
  timestamp: string;
}

export interface Records {
  hr_derived_records: Record[];
  steps_derived_records: any[];
}

export interface Rewards {
  calories_points: string;
}

export interface Summaries {
  duration_time_tot: string;
  z1_time_tot: string;
  z2_time_tot: string;
  z3_time_tot: string;
  z4_time_tot: string;
  z5_time_tot: string;
  heart_rate_min: string;
  heart_rate_avg: string;
  heart_rate_max: string;
  effort_min: string;
  effort_avg: string;
  effort_max: string;
  calories_tot: string;
  z1_calories_tot: string;
  z2_calories_tot: string;
  z3_calories_tot: string;
  z4_calories_tot: string;
  z5_calories_tot: string;
  steps_tot: string;
  z1_steps_tot: string;
  z2_steps_tot: string;
  z3_steps_tot: string;
  z4_steps_tot: string;
  z5_steps_tot: string;
  cadence_min: string;
  cadence_avg: string;
  cadence_max: string;
  z1_cadence_avg: string;
  z2_cadence_avg: string;
  z3_cadence_avg: string;
  z4_cadence_avg: string;
  z5_cadence_avg: string;
}

export interface MinSummaries {
  duration_time_tot: string;
  z1_time_tot: string;
  z2_time_tot: string;
  z3_time_tot: string;
  z4_time_tot: string;
  z5_time_tot: string;
  heart_rate_min: string;
  heart_rate_avg: string;
  heart_rate_max: string;
  effort_min: string;
  effort_avg: string;
  effort_max: string;
  calories_tot?: string;
  z1_calories_tot?: string;
  z2_calories_tot?: string;
  z3_calories_tot?: string;
  z4_calories_tot?: string;
  z5_calories_tot?: string;
  steps_tot?: string;
  z1_steps_tot?: string;
  z2_steps_tot?: string;
  z3_steps_tot?: string;
  z4_steps_tot?: string;
  z5_steps_tot?: string;
  cadence_min?: string;
  cadence_avg?: string;
  cadence_max?: string;
  z1_cadence_avg?: string;
  z2_cadence_avg?: string;
  z3_cadence_avg?: string;
  z4_cadence_avg?: string;
  z5_cadence_avg?: string;
}

export interface TrainingMetrics {
  device_type: string;
  groupal_mode: number;
  training_type: TypeSession;
  end: string;
  offset?: string;
  records: Records;
  rewards: Rewards;
  sensor: string;
  start: string;
  summaries: Summaries;
}

export interface Dataset {
  label: string;
  data: number[];
  backgroundColor?: Colors | Colors[] | string[];
  borderColor: Colors | Colors[] | string[];
  borderWidth?: number;
  tension?: number;
  segment?: object;
  fill?: boolean;
  cubicInterpolationMode?: string;
  pointBackgroundColor?: (ctx: any) => string;
  pointBorderColor?: (ctx: any) => string;
}

export interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

export interface Measurements {
  remaining_time: string;
  time_current: string;
  time_finish: string;
  data: Training[];
}

export interface BanUser {
  user_uuid: string;
  type_banned: BanPeriode;
}

export interface UserTraining {
  training_uuid: string;
  offset: string;
  start: string;
  end: string;
  training_type: string;
  device_type: string;
  groupal_mode: number;
  processed: number;
  summaries: MinSummaries;
  rewards: Rewards;
}

export interface MetricsDetail {
  sessions: number;
  calories: number;
  time: string;
  points: number;
  steps: number;
}

export interface UserMetrics {
  data: UserTraining[];
  links: Links;
  meta: Meta;
}

export interface Weigth {
  datetime: string;
  weight: string;
}

export interface UserWeigth {
  data: Weigth[];
  links: Links;
  meta: Meta;
}

export interface UserStats {
  metrics: UserMetrics;
  weigth: UserWeigth;
}

export interface Sensor {
  sensor_uuid: string;
  sensor_name: string;
  sensor_mac: string;
  ownership_type?: Ownership;
  updated_at: string;
  sensor_id_center?: number;
  user_uuid?: string;
  user_email?: string;
}

export interface Sensors {
  data: Sensor[];
  links: Links;
  meta: Meta;
}

export interface TrainingSummary {
  date: string;
  total_trainings: number;
}

export interface TrainingTypeSummary {
  [key: string]: number;
}

export interface Clasification {
  user: string;
  total_sessions: number;
  kcal: number;
  rookpoints: number;
}

export interface Reports {
  trainings_count_per_datetime: TrainingSummary[];
  trainings_count_per_training_type: TrainingTypeSummary;
  users_classification: Clasification[];
}

export type PageSizeOption = {
  key: string;
  value: number;
};

export type Sorting = {
  key: string;
  flow: Flow;
};

export interface GoalCompletedMetrics {
  id?: number;
  challenge_id: number;
  user_id: number;
  goal_id: number;
  goal_type: string;
  value: number;
  created_at?: string;
  updated_at?: string;
}

export interface UserChallenge {
  user_uuid: string;
  branch_uuid: string;
  name: string;
  last_name_1: string;
  last_name_2?: string;
  email: string;
  pseudonym: string;
  created_at: string;
  updated_at: string;
  metrics?: { data: GoalCompletedMetrics[] };
  sum_points_metrics?: number;
  completed_at?: string | null;
  team?: string;
  points?: number;
  earned?: number;
  image_url: string | null;
  total_goals_completed: number;
  rookpoints: number;
}

export interface TeamGoalCompleted {
  uuid: string;
  branch_uuid: string;
  name: string;
  users: { data: UserChallenge[] };
}

export interface RoomGoalCompleted {
  uuid: string;
  branch_uuid: string;
  name: string;
  description: string;
  logo_image: string;
  background_image: string;
  users: { data: UserChallenge[] };
}

export interface Goal {
  id: number;
  uuid: string;
  goal_type: string;
  goal_type_id: number;
  meta: string;
  points: string;
  status: boolean;
  user_goal_completed?: UserChallenge[];
  user_team_completed?: TeamGoalCompleted[];
  user_room_completed?: RoomGoalCompleted[];
  created_at: string;
  updated_at: string;
  level?: number;
}

export interface GoalType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Goals {
  data: Goal[];
  links: Links;
  meta: Meta;
}

export interface GoalBody {
  goal_type_id: number;
  meta: string;
  points: number;
  status: number;
  uuid?: string;
}

export interface CompletedGoal extends Goal {
  isCompleted: boolean;
  accumulated: number;
}

export interface ChallengeTeam {
  team_id: number;
  team_uuid: string;
  branch_uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  completed_at: string;
  rookpoints?: number;
  progress?: number;
  users?: UserChallenge[];
  users_in_team?: { data: number };
  earned?: number;
}

export interface ChallengeRoom extends Room {
  users?: UserChallenge[];
  users_in_room?: { data: number };
  earned?: number;
  rookpoints?: number;
  progress?: number;
}

export interface Challenge {
  challenge_uuid: string;
  branch_uuid: string;
  challenge_type: string;
  challenge_type_id: number;
  challenge_modality: string;
  challenge_modality_id: number;
  challenge_compliance?: string;
  name: string;
  description?: string;
  status?: "active" | "pending" | "completed";
  start_at: string;
  finish_at: string;
  challenge_users?: { data: UserChallenge[] };
  challenge_teams?: { data: ChallengeTeam[] };
  challenge_rooms?: { data: ChallengeRoom[] };
  count_challenge_users?: number;
  count_challenge_rooms?: { users_in_room: number }[];
  count_challenge_teams?: { users_in_team: number }[];
  total_goals: number;
  goals: Goal[];
  count_completed_user_goals: { data: number };
}

export interface Challenges {
  data: Challenge[];
  links: Links;
  meta: Meta;
}

export interface ChallengeTypeModality {
  id: number;
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface ChallengeConfig {
  types: ChallengeTypeModality[];
  modalities: ChallengeTypeModality[];
}

export interface UserIncluded {
  uuid: string;
  name: string | null;
  last_name_1: string;
  email: string;
  pseudonym: string;
  image_url: string | null;
  team: { name: string; uuid: string } | any;
}

export interface RoomIncluded {
  uuid: string;
  name: string;
  description?: string;
  logo_image?: string;
  background_image?: string;
}

export interface CreateChallenge {
  branch_uuid: string;
  challenge_modality_id: number;
  challenge_type_id: number;
  name: string;
  description?: string;
  start_at: string;
  finish_at: string;
  offset: string;
  offset_name: string;
  users: { [key: string]: UserIncluded } | {};
  rooms: RoomIncluded[];
  challenge_compliance: "competitive" | "free";
}

export interface EditChallenge extends CreateChallenge {
  detached_users: string[];
  detached_teams: string[];
  detached_rooms: string[];
}

export interface ShowChallenge {
  challenge_uuid: string;
  branch_uuid: string;
  challenge_type: string;
  challenge_type_id: number;
  challenge_modality: string;
  challenge_modality_id: number;
  name: string;
  description?: string;
  status?: "active" | "pending" | "completed";
  start_at: string;
  finish_at: string;
  challenge_users?: { data: UserChallenge[] };
  challenge_teams?: ChallengeTeam[];
  challenge_rooms?: ChallengeRoom[];
  total_goals: number;
  goals: Goal[];
  count_completed_user_goals: { data: number };
  challenge_compliance: string;
}

export interface UserDetail {
  user: UserChallenge;
  earned: number;
  percentage: number;
  total: number;
  position?: number;
  points?: number;
}

export interface DashboardGaming {
  total_challenges: number;
  active_challenges: number;
  total_goals: number;
  active_goals: number;
  user_participating: number;
  individual_challenges: number;
  group_challenges: number;
}

export interface ReportTraining {
  uuid: string;
  modality: string;
  trainingType: string;
  duration: string;
  date: string;
  summaries: MinSummaries;
  rewards: Rewards;
}

export interface Offset {
  offsetName: string;
  offset: string;
}

export interface GoalInformationChallenge {
  name: string;
  description: string;
  type: string;
  modality: string;
  compliance: string;
  participants: number;
  start: string;
  end: string;
  isFree: boolean;
  status: "active" | "pending" | "completed";
}

export interface TeamMetric {
  goal_type: string;
  accumulated: number;
  meta: number | null;
  completed: boolean;
  level?: number;
  target?: string;
}

export interface TeamMetrics {
  data: TeamMetric[];
}

export interface Medal {
  medals_type: "gold" | "silver" | "bronce";
  medals_count: number;
}

export interface MedalBoard {
  finished_challenges: number;
  participated_challenges: number;
  points_challenges: number;
  points_meddals: number;
  achievement: Medal[];
  email: string;
  image?: string;
  name?: string;
  pseudonym?: string;
}

export interface Board {
  data: MedalBoard[];
  links: Links;
  meta: Meta;
}
