import { createContext } from "react";
import {
  CurrentUser,
  DashboardData,
  Translation,
  Features,
  CenterData,
} from "../../types";

export type UIContextContent = {
  authenticated: boolean;
  branchUUID: string;
  centerData: CenterData | null;
  centerImage: string;
  centerName: string;
  contractAlreadyShown: boolean;
  currentUser: CurrentUser | null;
  dashboardData: DashboardData | null;
  features: Features;
  hostname: string;
  isUnavailable: boolean;
  lang: Translation;
  metricSystem: string;
  permissions: string[];
  trunk: string;
  setAuthenticated: (authenticated: boolean) => void;
  setBranchUUID: (uuid: string) => void;
  setCenterData: (data: CenterData) => void;
  setCenterImage: (centerImage: string) => void;
  setCenterName: (name: string) => void;
  setContractAlreadyShown: (flag: boolean) => void;
  setCurrentUser: (currentUser: CurrentUser | null) => void;
  setDashboardData: (data: DashboardData | null) => void;
  setFeatures: (features: Features) => void;
  setHostname: (hostname: string) => void;
  setIsUnavailable: (isUnavailable: boolean) => void;
  setLang: (lang: Translation) => void;
  setMetrics: (metrics: string) => void;
  setPermissions: (permissions: string[]) => void;
  setTrunk: (trunk: any) => void;
};

/**
 * lang: Saber el idioma que tiene el admin
 * contractAlreadyShown: saber si durante la sesiÃ³n ya se mostrÃ³ el pop con los acuerdos
 * authenticated: saber si el usuario esta autenticado
 * dashboardData: data del dashboard
 * currentUser: data del usuario actual
 * centerImage: logo del centro
 * metricSystem: sistema de mediciones que tiene el centro
 * trunk: variable para saber si hay que volver a la pÃ¡gina principal si estamos en una subvista
 * un ejemplo en rookRemote wrapper
 * hostname: saber el hostname del centro
 * permissions: permisos que tiene el usuario
 * features: features que tiene habilitado el centro
 */
const UIContext = createContext<UIContextContent>({
  lang: Translation.en,
  contractAlreadyShown: false,
  authenticated: false,
  dashboardData: null,
  currentUser: null,
  centerData: null,
  centerImage: "",
  metricSystem: "",
  trunk: "",
  hostname: "",
  branchUUID: "",
  permissions: [],
  features: {},
  centerName: "",
  isUnavailable: false,
  setAuthenticated: () => {},
  setBranchUUID: () => {},
  setCenterData: () => {},
  setCenterImage: () => {},
  setCenterName: () => {},
  setContractAlreadyShown: () => {},
  setCurrentUser: () => {},
  setDashboardData: () => {},
  setFeatures: () => {},
  setHostname: () => {},
  setIsUnavailable: () => {},
  setLang: () => {},
  setMetrics: () => {},
  setPermissions: () => {},
  setTrunk: () => {},
});

export default UIContext;
