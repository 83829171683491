import React, { HTMLAttributes, ReactElement, ChangeEvent, useRef, useState, useLayoutEffect, FC } from 'react'
import { fromEvent, Subscription, debounceTime, pluck } from 'rxjs';
import InputError from './InputError';

interface InputTextAreaProps extends HTMLAttributes<HTMLElement> {
  background ?: string,
  count ?: number
  errorText ?: string,
  icon ?: ReactElement,
  id: string,
  isError ?: boolean,
  isLimited ?: boolean,
  initialCount ?: number
  marginTop ?: string,
  placeholder: string,
  position ?: 'top' | 'bottom',
  readOnly ?: boolean,
  title: string,
  total ?: number,
  value: any,
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
  handleBlur: (e: any) => void,
}
 
/**
 * TextArea con label
 * @param props contiene todas las propiedades necesarias descritas en InputTextAreaProps
 * @param props.inputMode indicar el tipo de teclado para móviles
 * @param props.placeholder placeholder el input
 * @param props.id del input
 * @param props.icon que va a la izquierda del input
 * @param props.title que va en con el input
 * @param props.value valor del input
 * @param props.isError saber si se debe mostrar el error en el input
 * @param props.errorText texto del error
 * @param props.readOnly saber si es solo lectura
 * @param props.background del input si se ocupa otra a la del defecto
 * @param props.marginTop cambiar el margin por defecto
 * @param props.handleChange función para cambiar el valor del input
 * @param props.handleBlur función para cambiar el valor del input
 * @returns TextArea para formularios
 */

 
const InputTextArea: FC<InputTextAreaProps> = (props) => {

  const { 
    inputMode, id, icon, placeholder, title, value, isError, errorText, readOnly,
    background = "bg-input-background", marginTop = "mt-8", isLimited, total, count,
    position = 'top', initialCount = 0,
    handleChange, handleBlur
  } =  props

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const subscription = useRef<Subscription | null>(null);

  const [valueLength, setLength] = useState(initialCount)

  useLayoutEffect(() => {

    if (inputRef.current && total) {

      subscription.current = fromEvent( inputRef.current, 'keyup' )
        .pipe(
          debounceTime(300),
          pluck('target', 'value'),
        )
        .subscribe(txt => setLength((txt as string).length))

    }

    return () => {
      subscription.current?.unsubscribe()
    }

  }, [total])

  return ( 
    <div className = {`flex flex-col ${marginTop}`}>

      <div className="flex items-center justify-between">
        <label
          htmlFor={ id }
          className = "uppercase"
        >
          { title }
        </label>

        { (isLimited && position === 'top') && (

          <p className='text-gray-400'>
            {count}/{total}
          </p>

        )}

      </div>

      <div
        className={`flex items-center ${background} py-2 px-3 rounded-xl mt-2 border ${ isError ? 'border-red-600' :'hover:border-white border-transparent '}`}
      >
        { icon || null }
      
        <textarea
          ref = { inputRef }
          name = { id }
          id = { id }
          inputMode = { inputMode }
          readOnly = { readOnly }
          className = {`appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${background} focus:${background} ${readOnly ? 'bg-card-header text-gray-400' : 'bg-card-body'}`}
          onChange = { handleChange }
          onBlur = { handleBlur }
          placeholder = { placeholder }
          rows = { 6 }
          cols = { 50 }
          value = { value }
        />
        
      </div>

      {((isLimited && position === 'bottom')) && (
        <p className='text-right text-gray-400'>
          {valueLength}/{total}
        </p>
      )}

      { isError
        && (
          <InputError
            colorLabel = "text-red-500"
          >
            { errorText }
          </InputError>
        ) 
      }

    </div>
  )
}

export default InputTextArea;