import React, {
  FC,
  useEffect,
  useState,
  useContext,
  MutableRefObject,
} from "react";

// Dependencies
import Swal from "sweetalert2";
import { interval, Subscription, timer } from "rxjs";
import { useActor } from "@xstate/react";
import { useHistory, useLocation } from "react-router-dom";
import { Snackbar, Alert, AlertColor, capitalize } from "@mui/material";

// Types and models
import Texts from "../../models/Texts";
import { Configuration, Measurements, Word, ClusterIcon } from "../../types";

// Context
import UIContext from "../../context/ui/UIContext";

// Hooks
import useTranslation from "../../hooks/useTranslation";
import ConfigModal from "./ConfigModal";
import Spinner from "../utils/Spinner";
import Welcome from "./Welcome";
import RealTime from "./RealTime";
import QRModal from "../utils/QRModal";

// Utils
import { toggleFullScreen } from "../../utils/index";

interface WrapperProps {
  service: any;
  refZoomInWindow: MutableRefObject<Window | null | undefined>;
  updateZoomRef: (window: Window | null) => void;
}

/**
 * Wrapper que envuelve la página de bienvenida y el grid de clusters
 * @param props contiene todas las propiedades necesarias descritas en WrapperProps
 * @param props.service actor que controla este componente
 * @returns  sub-página de remote
 */
const Wrapper: FC<WrapperProps> = ({
  service,
  refZoomInWindow,
  updateZoomRef,
}) => {
  // Maquina que controla esta vista
  const [machine, send] = useActor(service);
  const { context, value, matches }: any = machine;

  // Navegación
  const history = useHistory();
  const location = useLocation();

  // Alerta
  const [alert, setAlert] = useState(false);
  const [autoFinish, setAutoFinish] = useState<Subscription | null>(null);
  const [progressInterval, setProgressInterval] = useState<Subscription | null>(
    null
  );
  const [waitingRoomInterval, setWaitingRoomInterval] =
    useState<Subscription | null>(null);

  const [open, setOpen] = useState(false);

  // Trunk: sacamos el maletero para saber si tengo que volver
  const { trunk, branchUUID, setTrunk } = useContext(UIContext);

  // Hooks para traducir
  const { getTranslation } = useTranslation();

  // Este es para poder volver a la página inicial dando clic en la opción de navegación
  useEffect(() => {
    if (typeof window !== "undefined") {
      const screenWide = window.matchMedia("(min-width: 992px)");
      if (screenWide) {
        handleMatch(screenWide);

        if (screenWide?.addEventListener)
          screenWide.addEventListener("change", handleMatch);
        else screenWide.addListener(handleMatch);
      }
    }
  }, []);

  useEffect(() => {
    if (trunk.includes("/back")) {
      setTrunk(location.pathname);
      send({ type: "BACK" });
    }
  }, [trunk]);

  useEffect(() => {
    if (matches("login")) history.push("/login");

    if (matches("started")) {
      initRoom();
      delayTimer();
      progressTimer();
    }

    if (matches("stop")) {
      waitingRoomInterval?.unsubscribe();
      progressInterval?.unsubscribe();
      autoFinish?.unsubscribe();
    }

    if (matches("failure") || matches("not") || matches("rejected"))
      setAlert(true);
    else setAlert(false);
  }, [value]);

  useEffect(() => {
    if (context.waiting <= 0) {
      waitingRoomInterval?.unsubscribe();
    }
  }, [context.waiting]);

  useEffect(() => {
    if (context.classFinishAt) {
      console.log(context.classFinishAt);
      setAutoFinish(
        timer(context.classFinishAt).subscribe({
          next: () => {
            console.log(`auto finish: ${context.classFinishAt}`);
            send({ type: "STATE" });
          },
        })
      );
    }
  }, [context.classFinishAt]);

  useEffect(() => {
    if (context.progress >= 100) {
      console.log(context.progress);
      progressInterval?.unsubscribe();
    }
  }, [context.progress]);

  useEffect(() => {
    if (!context.hasStreaming) toggleFullScreen(context.maximized);
  }, [context.maximized]);

  useEffect(
    () => () => {
      waitingRoomInterval?.unsubscribe();
      progressInterval?.unsubscribe();
      autoFinish?.unsubscribe();
      refZoomInWindow.current?.close();
      if (!context.hasStreaming) toggleFullScreen(false);
    },
    []
  );

  /**
   * Configurar una clase
   * @param config del room
   */
  const setConfiguration = (config: Configuration): void => {
    send({
      type: "CONFIG",
      data: config,
    });
  };

  /**
   * Cuando se inicia el room nos suscribimos al canal de pusher e iniciamos el observer para
   * flotar el progress bar
   */
  const initRoom = (): void => {
    context.channel.bind("user-new-measurement", (items: Measurements) => {
      send({ type: "UPDATE", data: items });
    });

    context.channel.bind("event-stop", () =>
      send({ type: "REMOTE", data: "reset" })
    );
    context.channel.bind("event-reset", () =>
      send({ type: "REMOTE", data: "initial" })
    );

    if ("IntersectionObserver" in window) {
      send({
        type: "OBSERVE",
        callback: (entries: any) => {
          if (!entries[0].isIntersecting) {
            send({ type: "FLOAT", data: true });
          } else {
            send({ type: "FLOAT", data: false });
          }
        },
      });
    }
  };

  /**
   * Timer para la sala de espera
   */
  const delayTimer = (): void => {
    if (waitingRoomInterval !== null || context.waiting === 0) return;

    setWaitingRoomInterval(
      interval(990).subscribe({
        next: () => send({ type: "DECREASE", data: 1 }),
      })
    );
  };

  /**
   * Timer de la barra de progreso
   */
  const progressTimer = (): void => {
    if (progressInterval !== null) return;

    setProgressInterval(
      interval(1000).subscribe({
        next: () => send({ type: "PROGRESS" }),
      })
    );
  };

  /**
   * Cambiar de estado el room para terminar o reiniciar
   */
  const handleChangeState = (): void => {
    if (context.state === "stop") {
      Swal.fire({
        title: getTranslation(Texts.confirmationStop as Word),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#b7b7b7",
        confirmButtonColor: "#e42823",
        confirmButtonText: getTranslation(Texts.stop as Word),
        cancelButtonText: getTranslation(Texts.cancel as Word),
      }).then((result) => {
        if (result.isConfirmed) {
          send({ type: "STATE" });
          waitingRoomInterval?.unsubscribe();
          progressInterval?.unsubscribe();
          autoFinish?.unsubscribe();
        }
      });
    } else {
      send({ type: "STATE" });
      waitingRoomInterval?.unsubscribe();
      progressInterval?.unsubscribe();
      autoFinish?.unsubscribe();
    }
  };

  /**
   * Obtener el icono que tiene la clase de acuerdo a la actividad
   * @returns un icono de pasos, saltos o kangoo
   */
  const getIcon = (): ClusterIcon | null => {
    if (!context.classConfig) return null;

    if (context.classConfig.steps_icon) return context.classConfig.steps_icon;

    if (context.classConfig.step_options)
      return context.classConfig.step_options;

    return null;
  };

  const handleMatch = (screen: any): void => {
    if (screen.matches) {
      send({ type: "SCREEN", data: true });
    } else {
      send({ type: "SCREEN", data: false });
    }
  };

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
  const getSeverity = (): AlertColor => {
    switch (value) {
      default:
        return "error";
    }
  };

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = (): string => {
    switch (value) {
      case "not":
      case "rejected":
        if (context.statusError === 407)
          return getTranslation(Texts.streamingZoomTokenConflict as Word);

        if (context.statusError !== 43)
          return getTranslation(Texts.generalError as Word);

        return getTranslation(Texts.streamingZoomConflict as Word);

      default:
        return getTranslation(Texts.generalError as Word);
    }
  };

  /**
   * Cancelar los intervals antes de salir
   */
  const handleBack = (): void => {
    waitingRoomInterval?.unsubscribe();
    progressInterval?.unsubscribe();
    autoFinish?.unsubscribe();

    send({ type: "BACK" });
  };

  return (
    <Spinner show={matches("loading")}>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert(false)}
      >
        <Alert
          severity={getSeverity()}
          variant="filled"
          onClose={() => setAlert(false)}
        >
          {getMessage()}
        </Alert>
      </Snackbar>

      {!matches("idle") &&
      !matches("failure") &&
      !matches("restart") &&
      !matches("not") ? (
        <RealTime
          icon={getIcon()}
          total={context.total}
          columns={context.columns}
          delay={context.formated}
          subscription={context.subscription}
          waiting={context.waiting}
          progress={context.progress}
          inFlex={context.measurementsInFlex}
          isFullScreen={context.isFullScreen}
          measurements={context.measurements}
          zoomCredentials={context.zoomCredentials}
          hasStreaming={context.hasStreaming}
          changing={matches("moving")}
          isFloating={context.floated}
          isMaximized={context.maximized}
          state={context.state}
          deleting={matches("removing")}
          resize={() => send({ type: "RESIZE" })}
          handleBack={handleBack}
          handleDelete={(ban) => send({ type: "REMOVE", data: ban })}
          changeState={handleChangeState}
          updateZoomRef={updateZoomRef}
          handleQR={setOpen}
          qrUrl={`${process.env.REACT_APP_QR_DOMAIN}/remote?room=${context.id}?branch=${branchUUID}`}
        />
      ) : (
        <>
          <Welcome
            handleClick={() => send({ type: "BACK" })}
            handleConfigure={() => send({ type: "TOGGLE" })}
            handleQR={() => setOpen(true)}
          />

          <ConfigModal
            config={context.config}
            open={context.openModal}
            loading={matches("starting")}
            trainingType={
              context.trainingTypes ? context.trainingTypes.data : []
            }
            handleClose={() => send({ type: "TOGGLE" })}
            setConfiguration={setConfiguration}
            streamingIsAlreadyActive={context.streamingIsAlreadyActive}
            hasStreamingCredentials={!!context.zoomCredentials}
          />
        </>
      )}

      <QRModal
        url={`${process.env.REACT_APP_QR_DOMAIN}/remote?room=${context.id}?branch=${branchUUID}`}
        open={open}
        title={capitalize(context.roomName)}
        onClose={() => setOpen(false)}
      />
    </Spinner>
  );
};

export default Wrapper;
