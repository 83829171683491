/* eslint-disable react/button-has-type */
import React, { FC, ButtonHTMLAttributes, CSSProperties } from 'react'

interface RButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  color ?: 'primary' | 'secondary' | 'gray' |'gradient',
  className ?: string,
  sx ?: CSSProperties
}
 
const RButton: FC<RButtonProps> = ({ 
  color = 'primary', type = 'button', className, sx, children, onClick
}) => {

  const getBackgroundColor = () : string => {
    switch (color) {
      case 'primary': return 'bg-primary'
      case 'secondary':  return 'bg-title'
      case 'gray': return 'bg-secondary-background'
      
      default: return 'bg-main-gradient'
    }
  }

  return ( 
    <button
      style = { sx }
      type = { type }
      className = {`uppercase ${getBackgroundColor()} px-8 py-2 rounded-3xl w-full md:w-max flex items-center justify-center ${className}`}
      onClick = { onClick }
    >
      {children}
    </button>
  );
}
 
export default RButton;