import React, { FC, useState, useEffect, useRef, useContext } from 'react'

// Types and models
import Texts from '../../models/Texts'
import { 
  ClusterIcon,
  Measurements, 
  Training, 
  BanUser, 
  Word, 
  Subscription,
  ZoomCredentials,
} from '../../types';

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import ProgressBar from './ProgressBar'
import ActionsBar from './ActionsBar';
import EliminationModal from './EliminationModal';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Styles
import Spinner from '../utils/Spinner';
import ClusterList from './ClusterList';
import RQRImage from '../utils/RookQRImage/RQRImage';

interface RealTimeProps {
  isMaximized: boolean,
  isFloating: boolean,
  changing: boolean,
  deleting: boolean,
  state: string,
  measurements: Measurements | null,
  inFlex: Training[],
  waiting: number,
  progress: number,
  delay: string,
  icon: ClusterIcon | null,
  columns: number,
  total: number,
  isFullScreen: boolean,
  subscription: Subscription | null,
  zoomCredentials: ZoomCredentials | null,
  hasStreaming: boolean,
  qrUrl : string,
  resize: () => void,
  changeState: () => void,
  handleBack: () => void,
  handleDelete: (ban: BanUser) => void,
  handleQR: (flag: boolean) => void 
  updateZoomRef: (window: Window | null) => void
}

/**
 * Grid con los clusters
 * @param props contiene todas las propiedades necesarias descritas en RealTimeProps
 * @param props.isMaximized saber si esta maximizado el grid
 * @param props.isFloating saber si la barra de progreso esta flotando
 * @param props.state saber el estado de la clase activa, stop o reset
 * @param props.changing saber si se esta cambiando de estado activa -> stop -> reset -> activa
 * @param props.measurements la data que se muestra en los clusters
 * @param props.waiting lo que falta para que se acabe el tiempo de espera
 * @param props.delay lo que falta para que se acabe el tiempo de espera pero en formato= 1:10 min
 * @param props.deleting saber si se esta sacando a un usuario del remote
 * @param props.progress el progreso que tiene la clase, este es para la barra de progreso
 * @param props.icon del cluster
 * @param props.resize función para maximizar/minimizar
 * @param props.changeState cambiar a la clase de estado
 * @param props.handleBack regresar al componente/vista anterior
 * @param props.handleDelete eliminar a un usuario de la clase
 * @param props.hasStreaming saber si tiene streaming
 * @returns grid con los clusters que son los alumnos de la clase
 */
const RealTime: FC<RealTimeProps> = ({ 
  isMaximized, isFloating, state, changing, measurements, isFullScreen,
  waiting, delay, deleting, progress, icon, inFlex, columns, total, subscription,
  zoomCredentials, hasStreaming, qrUrl,
  resize, handleBack, changeState, handleDelete, updateZoomRef, handleQR
}) => {

  // Abrir el modal para banear a un usuario
  const [open, setOpen] = useState(false)

  // Data del usuario a banear
  const [currentUser, setCurrentUser] = useState<Training | null>(null)

  // Referencia para meter el client de zoom
  const zoomMeet = useRef<HTMLDivElement>(null)

  const [zoomAlreadyOpen, setZoomAlreadyOpen] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Sacar el lenguaje
  const uiContext = useContext( UIContext )

  useEffect(() => {
    
    if(!zoomAlreadyOpen && state === 'stop') getUrlFrame()

  }, [subscription])

  useEffect(() => {
    
    setOpen( deleting )

  }, [deleting])

  useEffect(() => () => {
    zoomMeet.current?.remove()
    }, [])

  const handleBan = (user: Training) : void => {
    setCurrentUser(user)
    setOpen(true)
  }

  const getUrlFrame = () : string => {
    if (subscription && subscription.meet_number && subscription.signature 
      && zoomCredentials){

      let url = process.env.REACT_APP_ZOOM_FRAME
      url = `${url}?pwd=${subscription.meet_password}&mn=${subscription.meet_number}`
      url = `${url}&sg=${subscription.signature}&lg=${uiContext.lang}`
      url = `${url}&nm=${uiContext.currentUser?.name}&ky=${zoomCredentials.api_key}`
      url = `${url}&chnl=${subscription.channel}`

      const width = window.screen.width * 40 / 100
      const xAxis = window.screen.width * 60 / 100

      const params = `location=yes,height=${window.screen.height},width=${width},scrollbars=yes,status=yes`

      const zoomInWindow = window.open(url,'_blank', params)
      zoomInWindow?.moveTo(xAxis, 0)

      updateZoomRef(zoomInWindow)

      setZoomAlreadyOpen(true)

    }

    return ''
  }

  return ( 
    <>

      <ActionsBar
        waiting = { waiting }
        delay = { delay }
        changing = { changing }
        isMaximized = { isMaximized }
        state = { state }
        resize = { resize }
        handleBack = { handleBack }
        changeState = { () => changeState() }
        handleQR = {() => handleQR(true)}
      />

      <div 
        className={`
          w-full mx-auto 
          ${(!measurements || measurements.data.length === 0) ? 'hidden' : 'block'}
        `}
      >
        <ProgressBar
          progress={progress > 0 ? progress : 0}
          isFloating = { isFloating }
        />
      </div>

      <div>
        <Spinner
          size = 'h-part-screen'
          show = { !measurements || measurements.data.length === 0 }
          title = { state !== 'reset'
            ? getTranslation( Texts.wrTitle as Word )
            : getTranslation( Texts.wrTitleEnd as Word )
          }
          stop = { state === 'reset' || waiting > 0}
          showSide = { state !== 'reset' && waiting > 0}
          side = {
            <div className='mx-8'>
              <RQRImage 
                width={300}
                url = { qrUrl } 
              /> 
            </div>
          }
        >

          <ClusterList
            total={total}
            columns={columns}
            inFlex={inFlex}
            isFullScreen={isFullScreen}
            icon={icon}
            measurements={measurements}
            hasStreaming = { hasStreaming }
            handleBan={ handleBan }
          />
        </Spinner>

      </div>

      <EliminationModal 
        deleting = { deleting }
        open={open}
        handleClose={() => setOpen(false)} 
        user={ currentUser } 
        handleDelete={ handleDelete }      
      />

    </>
  )
}
 
export default RealTime;