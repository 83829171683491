import * as Yup from 'yup'
import moment from 'moment'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
        .required('gamingGoalModalNameRequired')
        .min(1, 'gamingGoalModalNameRequired')
        .max(50, 'gamingGoalModalNameLength'),
  'description': Yup.string()
        .notRequired()
        .when('description', {
          is: (value: any) => value,
          then: (rule) => rule.min(1, 'gamingGoalModalChallengeDescription')
                            .max(150, 'gamingGoalModalChallengeDescriptionMax')
        }),
  challenge: Yup.string()
          .required('gamingGoalModalChallengeTypeRequired')
          .matches(/^ind|group$/, 'gamingGoalModalChallengeTypeRequired'),
  modality: Yup.string()
          .required('gamingGoalModalChallengeTypeRequired')
          .matches(/^user|room|teams$/, 'gamingGoalModalChallengeTypeRequired'),
  start: Yup.date()
      .required('gamingGoalModalChallengeStartDate')
      .min(moment(), 'gamingGoalModalChallengeStartDate'),
  end: Yup.date()
      .required('gamingGoalModalChallengeEndDate')
      .when('start', (start, schema) => schema.test({
          test: (end: Date) => !!start && end >= start,
          message: 'gamingGoalModalChallengeEndBigger' 
        })),
  users: Yup.string()
        .notRequired()
        .when('users', {
          is: (value: any) => value,
          then: rule => rule.min(100, 'gamingGoalModalChallengeUsers')
        }),
  rooms: Yup.string()
        .notRequired()
        .when('rooms', {
          is: (value: any) => value,
          then: rule => rule.min(100, 'Agrega rooms')
        }),
  minimumUsersRegisterForTeams: Yup.boolean()
                                    .isTrue()
}, [['description', 'description'], ['users', 'users'], ['rooms', 'rooms']])

export const validationSchemaEdit = Yup.object().shape({
  name: Yup.string()
        .required('gamingGoalModalNameRequired')
        .min(1, 'gamingGoalModalNameRequired')
        .max(50, 'gamingGoalModalNameLength'),
  'description': Yup.string()
        .notRequired()
        .when('description', {
          is: (value: any) => value,
          then: (rule) => rule.min(1, 'gamingGoalModalChallengeDescription')
                            .max(150, 'gamingGoalModalChallengeDescriptionMax')
        }),
  challenge: Yup.string()
          .required('gamingGoalModalChallengeTypeRequired')
          .matches(/^ind|group$/, 'gamingGoalModalChallengeTypeRequired'),
  modality: Yup.string()
          .required('gamingGoalModalChallengeTypeRequired')
          .matches(/^user|room|teams$/, 'gamingGoalModalChallengeTypeRequired'),
  start: Yup.date()
      .required('gamingGoalModalChallengeStartDate'),
  end: Yup.date()
      .required('gamingGoalModalChallengeEndDate')
      .min(moment(), 'gamingGoalModalChallengeEndDate')
      .when('start', (start, schema) => schema.test({
          test: (end: Date) => !!start && end >= start,
          message: 'gamingGoalModalChallengeEndBigger' 
        })),
  users: Yup.string()
        .notRequired()
        .when('users', {
          is: (value: any) => value,
          then: rule => rule.min(100, 'gamingGoalModalChallengeUsers')
        }),
  rooms: Yup.string()
        .notRequired()
        .when('rooms', {
          is: (value: any) => value,
          then: rule => rule.min(100, 'Agrega rooms')
        }),
  minimumUsersRegisterForTeams: Yup.boolean()
                                    .isTrue()
}, [['description', 'description'], ['users', 'users'], ['rooms', 'rooms']])