import React, { FC, useContext } from 'react'

// Dependencies
import 'moment-timezone'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// Context
import UIContext from '../../context/ui/UIContext'

// Componentes
import ChallengeActions from './utils/ChallengeActions'

// Hooks
import useTranslation from '../../hooks/useTranslation'
import { Challenges, Challenge, GoalInformationChallenge } from '../../types'

interface ChallengesTableProps {
  url: string,
  isDeleting: { flag: boolean, uuid: string },
  data: Challenges,
  handleDeleteChallenge: (uuid: string) => void,
  handleEditChallenge: (challenge: Challenge) => void
}

/**
 * Componente con la tabla de retos
 * @param props contiene todas las propiedades necesarias descritas en
 * ChallengesTableProps
 * @param props.isDeleting saber que reto se esta eliminando
 * @param props.data listado de retos
 * @param props.handleDeleteChallenge función para eliminar un reto
 * @param props.handleEditChallenge función para editar un reto
 * @returns una lista de retos
 */
const ChallengesTable: FC<ChallengesTableProps> = ({ 
  data, isDeleting, handleDeleteChallenge, handleEditChallenge, url
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Hook para navegar
  const history = useHistory()

  // Context
  const { centerData } = useContext( UIContext )

  const getParticipants = (challenge: Challenge) : number => {
    
    if (challenge.count_challenge_users) return challenge.count_challenge_users

    if (challenge.count_challenge_teams) 
      return challenge.count_challenge_teams.reduce(
        (acc, current) => current.users_in_team + acc, 0
      )


    if (challenge.count_challenge_rooms) 
      return challenge.count_challenge_rooms.reduce(
        (acc, current) => current.users_in_room + acc, 0
      )

    return 0

  }

  /**
   * It takes a challenge object and returns a function that pushes the challenge's uuid to the url and  the challenge object to the history state
   * @param {Challenge} challenge - Challenge
   */
  const goToTargets = (challenge: Challenge) : void => {

    const competitive = getTranslationByKey('competitive')

    const start = moment
      .utc(challenge.start_at)
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const end = moment
      .utc(challenge.finish_at)
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const state : GoalInformationChallenge = {
      compliance: challenge.challenge_compliance || competitive,
      description: challenge.description || '',
      end,
      isFree: challenge.challenge_compliance === 'free',
      modality: challenge.challenge_modality,
      name: challenge.name,
      participants: getParticipants(challenge),
      start,
      status: challenge.status || 'active',
      type: challenge.challenge_type,
    }

    history.push(
      `${url}/${challenge.challenge_uuid}/goals`, 
      state
    ) 
  }

  const handlViewGroup = (challenge: Challenge) : void => {
    history.push(`${url}/${challenge.challenge_uuid}`, {
      participants: getParticipants(challenge)
    })
  }

  return ( 
    <div className = "overflow-x-scroll lg:overflow-x-hidden">
      <table className = "table-auto shadow-md w-full capitalize">
        <thead className = "bg-input-background">
          <tr>
            <th className = "py-2 border border-table">
              Nº
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('name') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('gamingListParticipants') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('gamingListType') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('gamingListModality') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('compliance') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('status') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('gamingListStartDate') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('gamingListEndDate') }
            </th>
            <th className = "py-2 border border-table">
              { getTranslationByKey('adminTableHeaderActions') }
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((challenge, idx) => {
            return (
              <tr
                key={ challenge.challenge_uuid }
              >
                <td className="p-2 border border-table">
                  { idx + 1 }
                </td>
                <td className="p-2 border border-table">
                  { challenge.name }
                </td>
                <td className="p-2 border border-table">
                  { getParticipants(challenge) } 
                  {' '}{ getTranslationByKey('gamingListParticipants') }
                </td>
                <td className="p-2 border border-table">
                  { challenge.challenge_type }
                </td>
                <td className="p-2 border border-table">
                  { challenge.challenge_modality }
                </td>
                <td className="p-2 border border-table">
                  { getTranslationByKey(challenge.challenge_compliance || 'competitive') }
                </td>
                <td className="p-2 border border-table">
                  { getTranslationByKey(challenge.status || '') || '-' }
                </td>
                <td className="p-2 border border-table">
                  { moment.utc(challenge.start_at)
                    .tz(centerData?.offset_name || 'America/Mexico_City')
                    .format( getTranslationByKey('momentDateFormat') ) 
                  }
                </td>
                <td className="p-2 border border-table">
                  { moment.utc(challenge.finish_at)
                    .tz(centerData?.offset_name || 'America/Mexico_City')
                    .format( getTranslationByKey('momentDateFormat') ) 
                  }
                </td>
                <td className="p-2 border border-table">
                  
                  <ChallengeActions 
                    deleteLoading={isDeleting.flag && isDeleting.uuid === challenge.challenge_uuid}
                    handleDeleteChallenge={() => handleDeleteChallenge(challenge.challenge_uuid)}
                    handleEditChallenge={() => handleEditChallenge(challenge)}
                    handleViewGroup={() => handlViewGroup(challenge) } 
                    handleViewTargets={() => goToTargets(challenge)}                  
                  />

                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div> 
  )
}
 
export default ChallengesTable;