import React, { FC, ChangeEvent, useState, useEffect, useContext } from 'react'

// Dependencies
import { useMachine } from '@xstate/react';
import Pagination from '@mui/material/Pagination';

// Icons
import TimelineIcon from '@mui/icons-material/Timeline';

// Context
import UIContext from '../context/ui/UIContext'

// Types and models
import Texts from '../models/Texts'
import { Word, OptionSearchBar, SearchIn, Translation } from '../types'

// Context
import SessionsMachine from '../context/machines/SessionsMachine'

// Components
import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import SearchBar from '../components/utils/SearchBar'
import Spinner from '../components/utils/Spinner'
import SessionList from '../components/sessions/SessionList';
import SessionMetrics from '../components/sessions/SessionMetrics'
import Support from '../components/utils/Support';

// Hook
import useTranslation from '../hooks/useTranslation'

// Opciones para buscar en el buscador
const internalOptions: OptionSearchBar[] = [
  {
    key: "user_name",
    title: "usersNameInput"
  },
  {
    key: "last_name_1",
    title: "adminTableHeaderLastName"
  },
  {
    key: "email",
    title: "loginEmail"
  },
  {
    key: "training_name",
    title: 'sessionsTableHeaderTraining'
  },
  {
    key: "sensor_name",
    title: 'sessionsTableHeaderSensor'
  },
  {
    key: "device_type",
    title: 'sessionsTableHeaderTypeSession'
  }
]

const Sessions: FC = () => {

  // Hook para tener la maquina que controla la vista
  const [machine, send] = useMachine(SessionsMachine)

  // Mostrar o no el spinner
  const [loading, setLoading] = useState(false) 

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Sacamos los permisos
  const { permissions, lang, centerData } = useContext( UIContext )

  useEffect(() => {
    
    if (lang !== Translation.en) send({ type: 'LANG', data: lang })

  }, [lang])

  useEffect(() => {
    
    if (centerData && machine.matches('idle'))
      send({ type: 'BOOTING', offset: centerData.offset_name })

  }, [centerData])

  useEffect(() => {
    // 
    if( ['loading', 'lookingFor', 'idle'].includes(machine.value.toString())  
      || (machine.matches('searching') && machine.context.search?.search !== '-1')
    ) setLoading(true)

    else setLoading(false)

  }, [machine.value])

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    send({
      type: 'PAGEING',
      page: value
    })
  };

 /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      <Spinner
        show = { loading }
      >
        <Container 
          title= { !machine.matches('graphics') 
            ? getTranslation( Texts.navMenuSesions as Word ) 
            : getTranslation( Texts.userMetricsTitle as Word )}
          icon = { <TimelineIcon /> }  
          rightButton = { 
            <Support
              action='sessions'
            /> 
          }   
        >
          
          <div 
            className={`
              ${!machine.matches('graphics')  ? 'flex': 'hidden'} justify-center md:justify-end w-full
            `}
          >
            <SearchBar
              options = { internalOptions }
              action={ searchIn }
            />
          </div>

          { !machine.matches('graphics') && (
            <>
      
              { (machine.context.page && !loading) && (
                <SessionList
                  service = { machine.context.page }  
                  showMetrics = { permissions.includes('trainigs_metrics') }              
                />
              )}

              { ( machine.context.pages > 1) && (
                <div className="flex justify-center mt-12 mb-4">
                  <Pagination 
                    showFirstButton 
                    showLastButton
                    page = { machine.context.currentPage }
                    variant="text" 
                    color = "secondary"
                    count = { machine.context.pages } 
                    onChange = { handleChange }
                  />
                </div>
              )}
            </>
          )}

          { (machine.matches('graphics')) && (

            <SessionMetrics
              service = { machine.context.metrics }
            />

          )}
    
        </Container>
      </Spinner>
    </Layout>
  )
}
 
export default Sessions;