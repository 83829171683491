import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
 
const NotFound: FC = () => {

  // Hook para navegar
  const history = useHistory()

  useEffect(() => {
    
    history.push('/')

  }, [])

  return (
    <></>
  )
}
 
export default NotFound;