import React, { FC } from 'react'

// types
import { MetricsDetail } from '../../types'

// hooks
import useTranslation from '../../hooks/useTranslation'

interface UserReportModalMetricsProps {
  metrics: MetricsDetail
}
 
const UserReportModalMetrics: FC<UserReportModalMetricsProps> = ({ metrics }) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className = "p-5 border-l-8 border-red-600 rounded-md bg-card-header mt-4">

      <h3 className = "font-bold uppercase mb-4">
        { getTranslationByKey( 'userReportModalMetricsTitle' ) }
      </h3>

      <div
        className='grid grid-cols-1 md:grid-cols-4 gap-4 ml-8'
      >

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'userReportModalCalories' ) }
          </h4>
          <p>
            { metrics.calories.toFixed(2) } kcal
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'userReportModalTrainings' ) }
          </h4>
          <div className="flex items-center justify-between">
            <p>
              {metrics.sessions} {getTranslationByKey('userStatisticsTrainingLabel') }
            </p>
          </div>
        </div>

        {!Number.isNaN(metrics.points) && (
          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslationByKey( 'userStatisticsPoints' ) }
            </h4>
            <p>{ metrics.points.toFixed(2) } RookPoints</p>
          </div>
        )}

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'userStatisticsSteps' ) }
          </h4>
          <p className = 'lowercase'>
            { metrics.steps } { getTranslationByKey( 'userStatisticsSteps' ) }
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'userStatisticsTime' ) }
          </h4>
          <p>
            { metrics.time }
          </p>
        </div>

      </div>

    </div>
   );
}
 
export default UserReportModalMetrics;