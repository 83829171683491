import React,{ FC } from 'react'

// Dependecies
import { Pie } from 'react-chartjs-2';

// Utils
// import Colors from '../../utils/colors'

type TimeIn = 'hours' | 'minutes' | 'seconds' 

interface PieChartProps {
  data ?: any,
  time ?: TimeIn
}


// Data Por default si se manda llamar el componente sin data
const defaults = {
  labels: ['Crossfit', 'Pesas', 'HIIT', 'Correr', 'Yoga', 'Tennis'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.9)',
        'rgba(54, 162, 235, 0.9)',
        'rgba(255, 206, 86, 0.9)',
        'rgba(75, 192, 192, 0.9)',
        'rgba(153, 102, 255, 0.9)',
        'rgba(255, 159, 64, 0.9)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

// Configuración por defecto
const options = {
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        title: (context: any) => context[0].label,
        afterTitle: (context: any) => {
          const total = context[0].dataset.data.reduce(
            (acc: number, current: number) => current + acc, 0
          )
          return `${Math.round(context[0].parsed * 100 / total)}%`
        },
        label: (context: any) => `Total: ${Math.round(context.parsed)}`
      }
    }
  },
  maintainAspectRatio: false,
  // animation: {
  //   duration: 0
  // }
};

/**
 * Gráfica de pie
 * @param {Object} props contiene todas las propiedades necesarias descritas en PieChartProps
 * @param {ChartData} props.data data a mostrar en la gráfica
 * @returns Componente con gráfica de pie
 */
const PieChart: FC<PieChartProps> = ({ data = defaults, time }) => {

  const getLabel = (context: any): string => {

    if (time === 'seconds') 
      return `${Math.round(context.parsed)} seconds`
    
    const calc = context.formattedValue.split('.')
    let first : string = 'min'
    let second : string = 'secs'                  

    if (time === 'hours') {
      first = 'hrs'
      second = 'min'
    }

    if (calc.length > 1) {
      let seconds : string = ''
      const minutes = Number(`0.${calc[1]}`) * 60
      const rawSeconds = Number(minutes.toFixed(2).split('.')[1])

      if (rawSeconds > 0) {
        seconds = `${(Number(`0.${rawSeconds}`) * 60).toFixed(0)} s`
      }

      if (time === 'hours')
        return `${calc[0]} ${first} ${minutes.toFixed(0)} ${second} ${seconds}`
      
      return `${calc[0]} ${first} ${minutes.toFixed(0)} ${second}`
    }

    return  `${calc[0]} ${first}`

  }

  return (
    <Pie 
      data = { data } 
      options = {
        time 
        ? { 
          ...options,
          plugins: {
            ...options.plugins,
            legend: {
              position: "left",
            },
            tooltip: {
              callbacks: {
                ...options.plugins.tooltip.callbacks,
                label: getLabel
              }
            }
          } 
        } 
        : {
          ...options,
          plugins: {
            legend: {
              position: "left",
            },
          }
        }
      } 
    />
  )
}
export default PieChart;