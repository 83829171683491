import React, { FC, useState, useEffect, ChangeEvent } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useActor } from '@xstate/react';
import { AlertColor, Pagination, Snackbar, Alert } from '@mui/material';

// Components
import ChallengesTable from './ChallengesTable';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface ChallengeListProps {
  service: any,
  url: string,
  currentPage: number,
  count: number,
  handleChange: (event: ChangeEvent<unknown>, value: number) => void
}
 
/**
 * Contenedor de la tabla de retos
 * @param props contiene todas las propiedades necesarias descritas en
 * ChallengeListProps
 * @param props.service actor que controla la tabla
 * @param props.currentPage número de página
 * @returns la tabla con los retos
 */
const ChallengeList: FC<ChallengeListProps> = ({ currentPage, count, service, url, handleChange}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Actor que controla la tabla
  const [machine, send] = useActor(service)
  const { context, value } : any = machine

  // Saber cuando cerrar y abrir la alerta
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    
    if (value === 'rejected' || value === 'deleted')
      setShowAlert(true)

  }, [value])

  /**
   * Eliminar un reto
   * @param uuid del reto a eliminar
   */
  const handleDeleteChallenge = (uuid: string) : void => {
    
    // Alerta para confirmar la eliminación
    Swal.fire({
      title: getTranslationByKey('gamingDeleteChallengeTitleConfirm'),
      text: getTranslationByKey('gamingDeleteChallengeContentConfirm'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslationByKey('accept'),
      cancelButtonText: getTranslationByKey('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        // Enviamos la petición al actor
        send({
          type: 'DELETE',
          uuid
        })
      }
    })

  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
  const getSeverity = () : AlertColor => {
    switch (value) {
      case 'deleted': return 'success'
      case 'rejected': return 'error'
      default: return 'error'
    }
  }

  /**
 * Obtener el mensaje a mostrar
 * @returns el mensaje a mostrar
 */
  const getMessage = () : string => {
    switch (value) {
      case 'deleted': return getTranslationByKey('gamingDeleteChallengeConfirm')
      case 'rejected': return getTranslationByKey('gamingDeleteChallengeError')
      default: return getTranslationByKey('generalError ')
    }
  }

  const closeAlert = () : void => {
    setShowAlert( false )
    send({ type: 'HIDE' })
  }

  return (
    <>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => closeAlert() }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => closeAlert() }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      { context.challenges && (
        <ChallengesTable
          url = { url }
          isDeleting={{ flag: value === 'deleting', uuid: context.deleteChallenge }}
          data = { context.challenges }
          handleDeleteChallenge = { handleDeleteChallenge }
          handleEditChallenge={ challenge => send({ type: 'EDIT', data: challenge }) }
        />
      )}

      { count > 1 && (
        <div className="flex justify-center mt-4">
          <Pagination
            showFirstButton
            showLastButton
            variant = 'text'
            color = 'secondary'
            count = { count }
            page = { currentPage }
            onChange={ handleChange }
          />
        </div>
      )}
    </>

  );
}
 
export default ChallengeList;