/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useContext } from 'react'

// Dependencies
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Context
import UIContext from '../../context/ui/UIContext'

// Types and models
import Texts from '../../models/Texts'
import { Word } from '../../types'

// Componentes
import CenterInfo from './CenterInfo'
import TabPanel from '../utils/TabPanel'
import CenterAddress from './CenterAddress'
import CenterGallery from './CenterGallery';
import CenterStreaming from './CenterStreaming';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { a11yProps } from '../../utils'

interface CenterTabsProps {
  services: {
    address: any,
    general: any,
    gallery: any,
    streaming: any
  }
}

/**
 * Tab Navigation
 * @param {Object} props contiene todas las propiedades necesarias descritas en CenterTabsProps
 * @param {Object} props.services contiene a los actores o sub maquinas que controlan cada tab
 * @returns un contenedor con las tabs y el contenido de cada tab
 */
const CenterTabs: FC<CenterTabsProps> = ({ services }) => {

  // Saber la tab
  const [value, setValue] = useState(0) 

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Sacar los features activos
  const { features } = useContext( UIContext )

  /**
   * Cambiar la tab
   * @param event de cambio
   * @param newValue nuevo valor de la tab
   */
   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div
      style = {{ flexBasis: '60%' }}
      className = "w-full"
    >
      <div className="flex justify-center">

        <Tabs
          value={value}
          textColor = "inherit"
          variant='fullWidth'
          onChange={handleChange}
        >

          <Tab 
            label= { getTranslation( Texts.centerTabData as Word ) }
            wrapped 
            {...a11yProps(0)}
          />

          <Tab 
            label= { getTranslation( Texts.centerTabAddress as Word ) }
            wrapped
            {...a11yProps(1)}
          />

          <Tab 
            label= { getTranslation( Texts.centerTabGallery as Word ) }
            wrapped
            {...a11yProps(2)}
          />

          { features.streaming && (
            <Tab 
              label= 'streaming'
              wrapped
              {...a11yProps(3)}
            />
          )}


        </Tabs>

      </div>

      <TabPanel value={value} index={0}>
        <CenterInfo 
          service = { services.general }
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <CenterAddress 
          service = { services.address }
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <CenterGallery 
          service = { services.gallery }
        />
      </TabPanel>

      { features.streaming && (
        <TabPanel value={value} index={3}>
          <CenterStreaming 
            service = { services.streaming }
          />
        </TabPanel>
      )}


    </div>
  );
}
 
export default CenterTabs;