import React, { FC, useState, useEffect } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useActor } from '@xstate/react'
import { Switch, CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material';

// types
import { Goal } from '../../../types';

// Components
import GoalRowActions from './GoalRowActions';

// hooks
import useTranslation from '../../../hooks/useTranslation'
import GenericTable from '../../utils/GenericTable';
import { RBox } from '../../utils/RookLayout';

interface GoalTableProps {
  isEditable: boolean,
  isFree: boolean,
  service: any,
}
 
/**
 * Contenedor de la lista de objetivos
 * @param props contiene todas las propiedades necesarias descritas en
 * GoalTableProps
 * @param props.service actor que controla la lista de objetivos
 * @returns Contenedor de la lista de objetivos
 */
const GoalTable: FC<GoalTableProps> = ({ service, isFree, isEditable }) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Actor que controla esta vista
  const [machine, send] = useActor(service)
  const { context, value, matches }: any = machine

  // Alerta
  const [alert, setAlert] = useState(false)

  useEffect(() => {

    if (value === 'changed' || value === 'failure' || value === 'deleted')
      setAlert(true)
    else setAlert(false)

  }, [value])

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (value) {
      case 'changed':
      case 'deleted': return 'success'
      case 'failure': return 'error'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (value) {
      case 'changed': return getTranslationByKey( 'gamingChallengeGoalStatusSuccess' )
      case 'deleted': return getTranslationByKey( 'gamingChallengeGoalDeletedSuccessfully' )
      default: return getTranslationByKey( 'generalError' )
    }
  }

  /**
   * Cerrar la alerta
   */
  const handleCloseAlert = () : void => {
    send({ type: 'HIDE' })
    setAlert(false)
  }

  /**
   * Cambiar de estado un objetivo
   * @param uuid del estado a cambiar
   * @param status previo del objetivo
   */
  const handleStatusChange = (uuid: string, status: boolean) : void => {
    
    send({ 
      type: 'STATUS', 
      data:{ 
        uuid, 
        status: !status,
      } 
    })

  }

  /**
   * Borrar un objetivo
   * @param uuid del objetivo a borrar
   */
  const handleDeleteGoal = (uuid: string) : void => {
    
    // Alerta para confirmar la eliminación
    Swal.fire({
      title: getTranslationByKey('gamingChallengeGoalDeleteConfirmation'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslationByKey('delete'),
      cancelButtonText: getTranslationByKey('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        send({
          type: 'DELETE',
          data: uuid
        })
      }
    })

  }

  
  /**
   * If the challenge is editable, return the headers for the table. If the challenge is not editable, return the
   * headers for the table
   * @returns An array of strings
   */
  const getHeaders = () : string[] => {
    
    let headers = !isFree 
    ? [
      getTranslationByKey('gamingChallengeGoalGoalTypeHeader'), 
      getTranslationByKey('gamingChallengeGoalGoalHeader'), 
      getTranslationByKey('level'), 
      getTranslationByKey('gamingChallengeGoalRewardHeader'), 
      getTranslationByKey('gamingStatsChallengesCreated'), 
    ] 
    :  [ 
      getTranslationByKey('gamingChallengeGoalGoalTypeHeader'),  
      getTranslationByKey('gamingStatsChallengesCreated'), 
    ]

    if (isEditable) 
      headers = headers.concat([
        getTranslationByKey('status'), 
        getTranslationByKey('userTableActions'), 
      ])
      
    return headers

  }

  const getBodyKeys = () : string[] => {

    const keys = !isFree 
    ? [
      'goal_type', 
      'target', 
      'level', 
      'points', 
      'created_at', 
    ]
    : [
      'goal_type',
      'created_at',
    ]

    if (isEditable) keys.push('status')
    
    return keys
   
  }
  

  return ( 
    <>

      <Snackbar
        open = { alert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { handleCloseAlert }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { handleCloseAlert }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      { context.goals &&  (
        <GenericTable
          showCounter 
          specialAt={ isEditable ? 'status' : undefined}
          headers={ getHeaders() }
          body = { context.goals.data.map((goal: Goal) => ({
            ...goal,
            target: `${ goal.meta } ${ goal.goal_type }`,
            level: goal.level ? `${getTranslationByKey('level')} ${goal.level}` : undefined
          })) } 
          bodyKeys={ getBodyKeys() }
          actions = { isEditable ? (element: Goal) => (
            <GoalRowActions 
              isEditable = { !isFree && element.status }
              loading = { matches('deleting') && context.uuidDelete === element.uuid }
              handleEdit={ () => send({ type: 'EDIT', data: element})}
              handleDelete = { () => handleDeleteGoal(element.uuid) }
            />
          ) : undefined}
          specialAtElement = {goal => (
            <RBox className='justify-center'>
              { (value === 'changing' &&  context.changeStatus.uuid === goal.uuid )
                ? ( <CircularProgress /> ) 
                : (
                  <Switch
                    checked = { Boolean(goal.status) }
                    onChange = { 
                      () => handleStatusChange(goal.uuid, Boolean(goal.status))
                    } 
                  />
                ) 
              }
            </RBox>
          )}
        />
      )}


    </>
  );
}
 
export default GoalTable;