import React, { FC, useReducer } from "react";
import UIContext from "./UIContext";
import UIReducer from "./UIReducer";

// Context Types
import {
  SET_CONTRACT_ALREADY_SHOWN,
  SET_AUTHENTICATED,
  SET_CENTER_IMAGE,
  SET_CURRENT_USER,
  SET_DASHBOARD_DATA,
  SET_LANG,
  SET_METRICS_SYSTEM,
  SET_PERMISSIONS,
  SET_TRUNK,
  SET_HOSTNAME,
  SET_FEATURES_ENABLED,
  SET_BRANCH_UUID,
  SET_CENTER_NAME,
  SET_CENTER_DATA,
  SET_IS_UNAVAILABLE,
} from "../../types/context";
import {
  CurrentUser,
  DashboardData,
  Translation,
  Features,
  CenterData,
} from "../../types";

// Axios header
import acceptedAdminLang from "../../config/acceptedAdminLang";

const UIState: FC = ({ children }) => {
  // Cargamos los valores iniciales
  const initialState = {
    lang: Translation.en,
    authenticated: false,
    contractAlreadyShown: true,
    currentUser: null,
    centerData: null,
    trunk: "",
    centerImage: "",
    metricSystem: "",
    hostname: "",
    branchUUID: "",
    centerName: "",
    permissions: [],
    features: {},
    isUnavailable: false,
  };

  // Reducer
  const [state, dispatch] = useReducer(UIReducer, initialState);

  /**
   * Cambiar el valor del lenguaje
   * @param lang lenguaje en la que se esta ejecutando la app
   */
  const setLang = (lang: Translation): void => {
    // Actualizamos el valor del header
    acceptedAdminLang(lang);

    dispatch({
      type: SET_LANG,
      payload: lang,
    });
  };

  /**
   * Cambiar el valor de autenticado
   * @param authenticated indicar si esta o no loggeado el usuario
   */
  const setAuthenticated = (authenticated: boolean): void => {
    dispatch({
      type: SET_AUTHENTICATED,
      payload: authenticated,
    });
  };

  /**
   * Cambiar el valor de autenticado
   * @param data data del dashboard
   */
  const setDashboardData = (data: DashboardData | null): void => {
    dispatch({
      type: SET_DASHBOARD_DATA,
      payload: data,
    });
  };

  /**
   * Cambiar el valor de currentUser
   * @param currentUser usuario loggeado
   */
  const setCurrentUser = (currentUser: CurrentUser | null): void => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: currentUser,
    });
  };

  /**
   * Cambiar el valor del maletero
   * @param trunk maletero para meter cualquier valor
   */
  const setTrunk = (trunk: string): void => {
    dispatch({
      type: SET_TRUNK,
      payload: trunk,
    });
  };

  /**
   * Guardar la imagen del centro
   * @param image la url de la imagen del centro
   */
  const setCenterImage = (image: string): void => {
    dispatch({
      type: SET_CENTER_IMAGE,
      payload: image,
    });
  };

  /**
   * Guardar el sistema de medidas
   * @param metrics el sistema de medidas que tiene el admin
   */
  const setMetrics = (metrics: string): void => {
    dispatch({
      type: SET_METRICS_SYSTEM,
      payload: metrics,
    });
  };

  /**
   * Guardar la lista de permisos que tiene el usuario
   * @param permissions lista de permisos del usuario
   */
  const setPermissions = (permissions: string[]): void => {
    dispatch({
      type: SET_PERMISSIONS,
      payload: permissions,
    });
  };

  /**
   * Saber si ya se mostro el contrato o no
   * @param flag saber si ya se mostro o no
   */
  const setContractAlreadyShown = (flag: boolean): void => {
    dispatch({
      type: SET_CONTRACT_ALREADY_SHOWN,
      payload: flag,
    });
  };

  /**
   * Guardar el hostname del admin
   * @param hostname del admin
   */
  const setHostname = (hostname: string): void => {
    dispatch({
      type: SET_HOSTNAME,
      payload: hostname,
    });
  };

  /**
   * Guardar los mÃ³dulos que estÃ¡n activos en el admin
   * @param features mÃ³dulos activos
   */
  const setFeatures = (features: Features): void => {
    dispatch({
      type: SET_FEATURES_ENABLED,
      payload: features,
    });
  };

  /**
   * Guardar el uuid de la branch
   * @param uuid de la branch
   */
  const setBranchUUID = (uuid: string): void => {
    dispatch({
      type: SET_BRANCH_UUID,
      payload: uuid,
    });
  };

  /**
   * Guardar el nombre de la branch
   * @param name de la branch
   */
  const setCenterName = (name: string): void => {
    dispatch({
      type: SET_CENTER_NAME,
      payload: name,
    });
  };

  /**
   * Guardar la data del centro
   * @param data de la branch
   */
  const setCenterData = (data: CenterData): void => {
    dispatch({
      type: SET_CENTER_DATA,
      payload: data,
    });
  };

  const setIsUnavailable = (isUnavailable: boolean): void => {
    dispatch({
      type: SET_IS_UNAVAILABLE,
      payload: isUnavailable,
    });
  };

  return (
    <UIContext.Provider
      value={{
        lang: state.lang,
        authenticated: state.authenticated,
        dashboardData: state.dashboardData,
        currentUser: state.currentUser,
        contractAlreadyShown: state.contractAlreadyShown,
        trunk: state.trunk,
        centerImage: state.centerImage,
        metricSystem: state.metricSystem,
        permissions: state.permissions,
        hostname: state.hostname,
        branchUUID: state.branchUUID,
        features: state.features,
        centerName: state.centerName,
        centerData: state.centerData,
        isUnavailable: state.isUnavailable,
        setAuthenticated,
        setBranchUUID,
        setCenterData,
        setCenterImage,
        setCenterName,
        setContractAlreadyShown,
        setCurrentUser,
        setDashboardData,
        setFeatures,
        setHostname,
        setIsUnavailable,
        setLang,
        setMetrics,
        setPermissions,
        setTrunk,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
export default UIState;
