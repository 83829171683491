import React, { FC, useContext, useEffect, useState } from "react";

// Dependencies
import moment from "moment";
import { useActor } from "@xstate/react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { DateRange } from "@mui/lab/DateRangePicker";
import { useLocation, useHistory } from "react-router-dom";

// Icons
import EmailIcon from "@mui/icons-material/Email";

// Types and models
import { Word } from "../../types";
import Texts from "../../models/Texts";

// Context
import UIContext from "../../context/ui/UIContext";

// Components
import Range from "../reports/Range";
import UserDetails from "./UserDetails";
import RButtonBack from "../utils/RookButtons/RButtonBack";
import MetricsChangable from "../sessions/MetricsChangable";

// Hooks
import useTranslation from "../../hooks/useTranslation";
import UserReportModal from "./UserReportModal";
import UserMetricsMainCharts from "./UserMetricsMainCharts";

type UserMetricsProps = {
  service: any;
};

/**
 * Mostrar las métricas del usuario
 * @param props contiene todas las propiedades necesarias descritas en UserMetricsProps
 * @param props.service actor que controla la vista
 * @returns Mostrar las métricas del usuario
 */
const UserMetrics: FC<UserMetricsProps> = ({ service }) => {
  // Context
  const [current, send] = useActor(service);
  const { context, matches, value }: any = current;

  // Hook para traducir
  const { getTranslation } = useTranslation();

  // Sacamos el maletero para saber si tengo que volver
  const { trunk, centerName, setTrunk } = useContext(UIContext);

  // Alerta
  const [alert, setAlert] = useState(false);

  // Navegación
  const location = useLocation();

  // Navegación
  const history = useHistory();

  // Este es para poder volver a la página inicial dando clic en la opción de navegación
  useEffect(() => {
    if (trunk.includes("/back")) {
      setTrunk(location.pathname);
      send({ type: "BACK" });
    }
  }, [trunk]);

  useEffect(() => {
    if (value === "failure" || value === "sended") setAlert(true);
    else setAlert(false);

    if (value === "login") history.push("/login");
  }, [value]);

  /**
   * Buscar por data dentro de un periodo
   * @param range Rango de fechas a buscar
   */
  const searchPeriod = (range: DateRange<Date>): void => {
    const dates2: string[] = [
      moment(range[0]).startOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
      moment(range[1]).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
    ];

    send({ type: "PERIOD", data: dates2 });
  };

  const handleDownload = (comments: string, download: boolean): void => {
    send({
      type: "DOWNLOAD",
      data: {
        center: centerName,
        comments,
        download,
      },
    });
  };

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
  const getSeverity = (): AlertColor => {
    switch (value) {
      case "failure":
        return "error";
      case "sended":
        return "success";
      default:
        return "info";
    }
  };

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = (): string => {
    switch (value) {
      case "failure":
        return getTranslation(Texts.generalError as Word);
      case "sended":
        return getTranslation(Texts.userReportSuccessfullySended as Word);
      default:
        return "";
    }
  };

  const handleClose = (): void => {
    setAlert(false);
    setTimeout(() => {
      send({ type: "DISMISS" });
    }, 250);
  };

  return (
    <>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        <Alert severity={getSeverity()} variant="filled" onClose={handleClose}>
          {getMessage()}
        </Alert>
      </Snackbar>

      <div className="grid gap-4 md:gap-0 md:grid-cols-ten-ninety">
        <div className="w-max md:mt-2 lg:mt-0">
          <RButtonBack action={() => send({ type: "BACK" })} />
        </div>

        <div className="flex flex-col lg:flex-row items-center lg:justify-end">
          <Range loading={matches("searching")} searchPeriod={searchPeriod} />

          <button
            type="button"
            className="shadow bg-main-gradient px-8 py-2 rounded-full cursor-pointer mt-4 lg:mt-0 md:ml-4 w-full md:w-max flex items-center justify-center uppercase"
            onClick={() => send({ type: "OPEN" })}
          >
            <div className="mr-2">
              <EmailIcon />
            </div>

            {getTranslation(Texts.reportButtonTitle as Word)}
          </button>
        </div>
      </div>

      {context.extra && (
        <UserDetails user={context.user} metrics={context.extra} />
      )}

      <UserMetricsMainCharts
        zones={context.zones}
        activities={context.activities}
        isEffortInHours={context.isEffortInHours}
      />

      <MetricsChangable
        title={`
          ${getTranslation(Texts.userStatisticsWeigth as Word)} 
          ${context.system === "english_system" ? "(lbs)" : "(kgs)"}
        `}
        data={context.weigth}
      />

      {context.reportData && (
        <UserReportModal
          user={context.user}
          period={context.period}
          sending={matches("sending")}
          showPreview={matches("view")}
          zones={context.reportData.zones}
          isOpen={context.isOpenReportModal}
          previewPDF={context.reportDataUri}
          metrics={context.reportData.metrics}
          activities={context.reportData.activities}
          isEffortInHours={context.isEffortInHours}
          trainings={context.reportData.trainings}
          handleClose={() => send({ type: "CLOSE" })}
          handleDelete={(uuid) => send({ type: "REMOVE", data: uuid })}
          handleDownload={(comments) => handleDownload(comments, true)}
          handleSend={(comments) => handleDownload(comments, false)}
        />
      )}
    </>
  );
};

export default UserMetrics;
