import React, { FC, useState } from 'react'

// Icons
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TimelineIcon from '@mui/icons-material/Timeline';

// Compontents
import HorizontalChart from '../charts/HorizontalChart'
import LineChart from '../charts/LineChart'
import Container from '../layout/Container'
import { ChartData } from '../../types';

interface MetricsChangableProps {
  title: string,
  data: ChartData
}
 
/**
 * Wrapper de la gráfica cambiable entre barras y puntos
 * @param props contiene todas las propiedades necesarias descritas en MetricsChangableProps
 * @param props.title title del contenedor
 * @param props.data para mostrar en las gráficas
 * @returns Wrapper de la gráfica cambiable entre barras y puntos
 */
const MetricsChangable: FC<MetricsChangableProps> = ({ title, data }) => {

  // Saber si cual gráfica estamos mostrando
  const [isTimeLine, setIsTimeLine] = useState(true)

  return ( 
    <Container
      title = { title }
      classes = "h-80"
      uppercase
    >
      <div className="flex p-4 h-full">
        { isTimeLine
          ? (<LineChart data = { data } />)
          : (<HorizontalChart data = { data } />)
        }
        <div>
          <button
            type = "button"
            onClick = { () => setIsTimeLine( !isTimeLine ) }
          >
    
            { isTimeLine
              ? (<EqualizerIcon />)
              : (<TimelineIcon />)
            }
          </button>
        </div>
      </div>
    </Container>
   );
}
 
export default MetricsChangable;