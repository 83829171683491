import React, { FC } from 'react';

// Dependencies
import { Skeleton } from '@mui/material';

// icons
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Gaming as GamingIcon } from '../utils/Icons';

// Types
import { DashboardGaming } from '../../types';

// Components
import Card from '../dashboard/Card';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface GamingStatsProps {
  data: DashboardGaming | null
}

/**
 * Dashboard de retos
 * @param props contiene todas las propiedades necesarias descritas en
 * GamingStatsProps
 * @param props.data data a mostrar
 * @returns dashboard de retos
 */
const GamingStats: FC<GamingStatsProps> = ({ data }) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return !data 
  ? (
    <div className = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
      <Skeleton 
        variant="rectangular" 
        height={158} 
        sx = {{ 
          width: '100%',
          borderRadius: '0.3rem'
        }}
      />
      <Skeleton 
        variant="rectangular" 
        height={158} 
        sx = {{ 
          width: '100%',
          borderRadius: '0.3rem'
        }}
      />
      <Skeleton 
        variant="rectangular" 
        height={158} 
        sx = {{ 
          width: '100%',
          borderRadius: '0.3rem'
        }}
      />
      <Skeleton 
        variant="rectangular" 
        height={158} 
        sx = {{ 
          width: '100%',
          borderRadius: '0.3rem'
        }}
      />
    </div>
  )
  :( 
    <div className = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
      <Card
        title= { getTranslationByKey('gamingStatsChallenges') }
        icon = { <GamingIcon /> }
      >

        <div className="grid grid-cols-2 gap-2 items-center h-full">
          <div className = "text-center font-medium">
            <h3 className = "text-3xl">
              { data.total_challenges } 
            </h3>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsChallengesCreated') }
            </h4>
          </div>

          <div className = "text-center text-warning">
            <h2 className = "text-3xl">
              { data.active_challenges }
            </h2>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsChallengesActive') }
            </h4>
          </div>
        </div>

      </Card>

      <Card
        title= { getTranslationByKey('gamingStatsGoals') }
        icon = { <TrackChangesIcon /> }
      >

        <div className="grid grid-cols-2 gap-2 items-center h-full">
          <div className = "text-center font-medium">
            <h3 className = "text-3xl">
              { data.total_goals } 
            </h3>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsChallengesCreated') }
            </h4>
          </div>

          <div className = "text-center text-warning">
            <h2 className = "text-3xl">
              { data.active_goals }
            </h2>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsGoalsCompleted') }
            </h4>
          </div>
        </div>

      </Card>

      <Card
        title= { getTranslationByKey('gamingStatsParticipants') }
        icon = { <DirectionsRunIcon /> }
      >

        <div className = "text-center h-full flex flex-col justify-center">
          <h2 className = "text-3xl">
            { data.user_participating }
          </h2>
          <h4 className='uppercase'>
            { getTranslationByKey('gamingStatsParticipants') }
          </h4>
        </div>

      </Card>

      <Card
        title= { getTranslationByKey('gamingListType') }
        icon = { <DonutLargeIcon /> }
      >

        <div className="grid grid-cols-2 gap-2 items-center h-full">
          <div className = "text-center font-medium">
            <h3 className = "text-3xl">
              { data.individual_challenges } 
            </h3>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsParticipantsIndividual') }
            </h4>
          </div>

          <div className = "text-center text-warning">
            <h2 className = "text-3xl">
              { data.group_challenges }
            </h2>
            <h4 className='uppercase'>
              { getTranslationByKey('gamingStatsParticipantsGroups') }
            </h4>
          </div>
        </div>

      </Card>
    </div>
  );
}
 
export default GamingStats;