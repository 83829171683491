import React, { FC, ReactElement } from 'react'

// Dependencies
import { CircularProgress, Drawer } from '@mui/material'

// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Types
import { TeamMetrics, UserChallenge } from '../../../../types';

// Components
import IndividualBody from './IndividualBody';
import { RBox } from '../../../utils/RookLayout';
import IndividualHeader from './IndividualHeader';
import GenericTable from '../../../utils/GenericTable';
import { RButton, RButtonClose } from '../../../utils/RookButtons';
import FreeBody from '../FreeBody';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

interface IndividualDetailProps {
  goalList: TeamMetrics | null,
  goals: { earned: number, total: number }
  isFree ?: boolean,
  isRemovable: boolean,
  open: boolean,
  percentage: number,
  points: number,
  position: number,
  rookpoints: number,
  user: UserChallenge
  onClose: () => void,
  onDelete: () => void,
}
 
const IndividualDetail: FC<IndividualDetailProps> = ({ 
  user, goals, open, position, goalList, points, percentage, rookpoints, isFree = false, isRemovable,
  onClose, onDelete
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  const RenderTable = () : ReactElement => {
    
    if (!goalList) 
      return (
        <RBox className='items-center justify-center w-full h-40'>
          <CircularProgress />
        </RBox>
      )

    if (isFree) 
      return (
        <GenericTable
          withoutStyles
          normalTableContainerClassName='bg-input-background px-0.5 rounded-lg max-h-72 md:max-h-80 2xl:max-h96 p-1'
          headers={[getTranslationByKey('goal'), getTranslationByKey('progress')]} 
          body={ goalList.data } 
          bodyKeys={['goal_type', 'accumulated']}  
        />
      )

    return (
      <GenericTable
        withoutStyles
        normalTableContainerClassName='bg-input-background px-0.5 rounded-lg max-h-72 md:max-h-80 2xl:max-h96 p-1'
        specialAt='goal_type'
        headers={[
          getTranslationByKey('goal'),
          getTranslationByKey('gamingGoalModalGoal'),
          getTranslationByKey('level')
        ]}
        body={ goalList.data }
        bodyKeys={['goal_type', 'target', 'level']}
        specialAtElement = {({completed, ...rest}) => (
          <RBox className='w-full'>
            <CheckCircleOutlineIcon
              className={ completed ? 'visible' : 'invisible' }
              color = 'primary' 
            />
            <p className='ml-1'>{rest.goal_type}</p>
          </RBox>
        )}
      />
    )

  }

  return ( 
    <Drawer 
      anchor = 'right'
      open = { open }  
      onClose = { onClose } 
    >
      <RBox className='justify-between items-center px-5 py-3 bg-card-body'>

        <h1 className='uppercase font-bold'>
          { getTranslationByKey('individualDetailTitle') }
        </h1>

        <RButtonClose onClick={ onClose }/>

      </RBox>

      <main className='p-5'>
        <IndividualHeader 
          fullRounded
          image = { user.image_url || undefined }
          name={ user.name }
          email={ user.email }
          position={ position }   
        />

        { isFree 
          ? (
            <FreeBody
              rounded
              rookpoints = {rookpoints} 
            />
          ) 
          : (
            <IndividualBody 
              rounded
              goals={ goals } 
              points={points} 
              rookPoints={rookpoints} 
              percentage={ percentage }          
            />
          ) 
        }

        <RenderTable />

        { isRemovable && (
          <RBox className='justify-center mt-8'>
            <RButton
              color = 'gradient'
              onClick={ onDelete }
            >
              { getTranslationByKey('delete') }
            </RButton>
          </RBox>
        )}

      </main>
    </Drawer>
  );
}
 
export default IndividualDetail;