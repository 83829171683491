import React, { FC } from 'react'

// Dependencies
import { useHistory } from 'react-router-dom'


// Icons
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// types and models
import Texts from '../models/Texts'
import { Word } from '../types'

// Componentes
import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import SearchBar from '../components/utils/SearchBar'
import RoleList from '../components/roles/RoleList';
// import Support from '../components/utils/Support';

// Hooks
import useTranslation from '../hooks/useTranslation'
 
/**
 * Ahorita los roles están hardcoded porque aún no se ha decido por implementarlos de manera
 * completa 
 * @returns dummy de roles
 */
const Roles: FC = () => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Hook para navegar
  const history = useHistory()

  return ( 
    <Layout>
      <Container
        title = { getTranslation(Texts.navMenuRoles as Word) }
        icon = { <SupervisorAccountIcon /> }
        // rightButton = { 
        //   <Support
        //     action='https://rookmotion.zendesk.com/hc/es-419/articles/1500002619301--C%C3%B3mo-inscribo-a-mis-usuarios-en-mi-GymVirtual-'
        //   /> 
        // }
      >
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center hidden">
      
          <button
            type = "button"
            className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
            onClick = { () => history.push('/roles/1') }
          >
            { getTranslation( Texts.rolesAddNewRole as Word ) }
          </button>

          <SearchBar 
            action={ () => {} }
            options = {[{ key: 'name', title: 'name' }]}
          />

        </div>

        <RoleList />
      </Container>
    </Layout>
  );
}
 
export default Roles;