import styled from 'styled-components';

type Props = {
    columns ?: number,
    basis ?: number | null,
    width ?: number | null 
}

export default styled.div<Props>`
    
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  align-items: center;
  justify-items: center;
  height: 100%;
  margin: 0 auto;

  @media (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
  }

  @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
  }

  /* @media (min-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
  } */

  @media (min-width: 992px) {
      /* ${ props => props.basis ? `flex-basis: ${props.basis}%;`: '' } */
      grid-template-columns: repeat(${props => props.columns ? props.columns : 5}, 1fr);
      ${ props => props.basis ? `height: ${props.basis}%;` : ''}
      ${ props => props.width ? `width: ${props.width}%;` : ''}
  }

`;