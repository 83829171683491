import React, { FC, ReactElement, ChangeEvent, useRef, useState, useEffect } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Tooltip, Snackbar, Alert, CircularProgress } from '@mui/material';

// Icons
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import SensorsIcon from '@mui/icons-material/Sensors';
import CameraAltIcon from '@mui/icons-material/CameraAlt'

// Types and models
import Texts from '../../models/Texts'
import { Word, Room } from '../../types'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface RemoteProps {
  room: Room
  isEditable: boolean,
  deleting ?: boolean,
  isRemovable: boolean,
  isImageChangable: boolean,
  loadingBackground ?: boolean,
  handleEdit ?: (room: Room) => void
  handleDelete ?: (uuid: string) => void
  handleGoToSession: (room: Room) => void
  handleUpdateImage ?: (image: File, uuid: string) => void,
}
 
/**
 * Card de remote
 * @param props contiene todas las propiedades necesarias descritas en RemoteProps
 * @param props.room data del room a mostrar
 * @param props.loadingBackground saber si se esta actualizando el background
 * @param props.deleting saber si se esta borrando
 * @param props.isEditable saber si se puede editar basado en los permisos y si esta en dashboard
 * @param props.isRemovable saber si se puede borrar basado en los permisos y si esta en dashboard
 * @param props.isImageChangable saber si se puede actualizar la imagen basado en permisos y si 
 * esta en dashboard
 * @param props.handleEdit función para editar un room de remote
 * @param props.handleDelete función para eliminar un room de remote
 * @param props.handleGoToSession función para ir a la sesión de remote
 * @param props.handleUpdateImage función para actualizar la imagen del remote
 * @returns card con la info del room y el botón de ir a la conexión
 */
const Remote: FC<RemoteProps> = ({ 
  room, loadingBackground, deleting, isEditable, isRemovable, isImageChangable,
  handleEdit, handleDelete, handleGoToSession, handleUpdateImage
}) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Referencia al input para hacer clic
  const inputFile = useRef<any>()

  // Saber si el room esta activo o nel
  const [isOnSession, setIsOnSession] = useState(false)

  // Saber si esta el botón de editar o eliminar
  const [isActionBar, setIsActionBar] = useState( !!handleEdit || !!handleDelete )

  // Saber si esta en sesión y así ocultar los botones de editar/borrar y cambiar
  // de color del botón de sesión
  useEffect(() => {
    
    if (!Array.isArray(room.class_configuration) 
      && room.class_configuration
      && room.class_configuration.remote_state !== 1 ) {
        setIsOnSession(true)
        setIsActionBar(false)
    }

  }, [])

  /**
   * Eliminar un room de remote
   * @param uuid del room a eliminar
   */
  const showConfirmation = (uuid: string) : void => {
    Swal.fire({
      title: getTranslation(Texts.remoteRemoveRoom as Word) ,
      text: getTranslation( Texts.remoteRemoveRoomDescription as Word ),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslation( Texts.delete as Word ),
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {

        if (handleDelete) handleDelete(uuid)
      }
    })
  }

  /**
   * Manejador de la imagen
   * @param e evento para recibir la imagen
   * @returns early return 
   */
   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) : void => {
    e.preventDefault()

    // Verificamos que exista la imagen
    if( !e.currentTarget.files ) return 

    // Sacamos el archivo
    const file = e.currentTarget.files.item(0)

    if ( !file ) {
      Sentry.captureMessage(
        `No se encontro la imagen profile: ${JSON.stringify(e.currentTarget.files)}`
      )
      return 
    }

    // Verificamos el tamaño del la imagen
    const size = (file.size / 1024 / 1024).toFixed(2)

    // Si es mayor a dos megas mandamos la alerta
    if ( Number(size) > 2 ) {
      setShowAlert( true )
      return 
    }

    // Si no mandamos la imagen
    if (handleUpdateImage)
      handleUpdateImage( e.currentTarget.files[0], room.uuid )

  }

  /**
   * Elementos para actualizar el background de la imagen
   * @returns el icono de la imagen
   */
   const renderBackgroundImage = () : ReactElement => {
    
    if(!Array.isArray(room.class_configuration) &&
      room.class_configuration && room.class_configuration.remote_state !== 1) return <></>

    if (loadingBackground)
      return (
        <div 
          className="absolute top-one right-one rounded bg-gradient-to-br from-transparent to-gray-500 p-1"
        >
          <CircularProgress 
            color = 'primary' 
            size = '1.5rem'
          />
        </div>
      )

    return (
      <Tooltip title={ getTranslation(Texts.edit as Word) }>
        <button
          type = 'button'
          className = 'absolute top-one right-one rounded bg-gradient-to-br from-gray-500 to-transparent p-1'
          data-cy = 'btn-remote-img'
          onClick = { () => inputFile.current.click() }
        >
          <CameraAltIcon />
        </button>
      </Tooltip>
    )

  }

  /**
   * Renderizar el botón de eliminación de room
   * @returns un botón para eliminar o el circular progress
   */
  const renderDeleteButton = () : ReactElement => {
    
    if(!Array.isArray(room.class_configuration) &&
      room.class_configuration && room.class_configuration.remote_state === 2) return <></>

    if (handleDelete) {

      if (deleting) {
        return (
          <CircularProgress color = 'secondary' />
        )
      }
      
      return (
        <Tooltip 
          title={ getTranslation( Texts.delete as Word ) }
        >
          <IconButton
            onClick = { () => showConfirmation(room.uuid) }
            data-cy = 'btn-delete-room'
          >
            <DeleteIcon
              fontSize = "small"
            />
          </IconButton>
        </Tooltip>
      )
    }

    return <></>

  }

  /**
   * Renderizar el botón de editar el room
   * @returns un botón para editar
   */
  const renderEditButton = () : ReactElement => {

    if(!Array.isArray(room.class_configuration) &&
      room.class_configuration && room.class_configuration.remote_state === 2) return <></>

    if (handleEdit) { 

      return (
        <Tooltip 
          title={ getTranslation( Texts.edit as Word ) }
        >
          <IconButton
            onClick = { () => handleEdit(room)}
            data-cy = 'btn-edit-room'
          >
            <CreateIcon
              fontSize = "small"
            />
          </IconButton>
        </Tooltip>
      )
    }
    
    return <></>
  }

  return ( 
    <>

      <input 
        data-cy = "image-remote"
        type="file" 
        id="image_admin" 
        name="image_admin" 
        accept="image/png, image/jpeg"
        className = "hidden"
        onChange = { handleFileChange }
        ref = { inputFile }
      />

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = 'error' 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getTranslation( Texts.profileImageSizeError as Word ) }
        </Alert>
      </Snackbar>

      <Card sx={{ maxWidth: 360 }}>
        <div className="relative">
          <CardMedia
            component="img"
            alt=""
            height="140"
            image={ room.background_image || '/images/remote.png' }
            sx={{
              height: '13rem'
            }}
          />

          { (handleUpdateImage && isImageChangable) && renderBackgroundImage() }

        </div>
        <CardContent>
          <div 
            className={`flex items-center lg:min-w-18 
              ${handleDelete || handleEdit ? 'justify-between' : 'justify-center'}
            `}
          >

            <div 
              className="flex items-center justify-center"
            >
              <div 
                className={`
                  w-3 h-3 rounded-full mr-2 ${room.status ? 'bg-green-500' :  'bg-gray-500'}`
                } 
              />
              <p className = "text-sm">
                { room.status
                  ? getTranslation(Texts.active as Word)
                  : getTranslation(Texts.inactive as Word)
                }
              </p>
            </div>

            <div 
              className="flex justify-between"
            >

              { isEditable && renderEditButton () }
                
              { isRemovable && renderDeleteButton() }

            </div>

          </div>

          <div className = "text-center mt-4">
            <h3 className = "text-title uppercase font-light text-base 2xl:text-lg">
              { getTranslation( Texts.room as Word ) }
            </h3>
            <h2 className = "text-title capitalize font-light text-xl 2xl:text-2xl font-bold">
              { room.name }
            </h2>

            <h3 className = "uppercase font-thin tracking-wider text-sm 2xl:text-base mt-8">
              { getTranslation( Texts.coach as Word ) }
            </h3>
            <h2 className = "capitalize font-light text-xl 2xl:text-2xl font-bold">
              { (room.couches_asigned && room.couches_asigned.length > 0 )
                ? room.couches_asigned[0].couch_name 
                : '-'
              }
            </h2>
          </div>
        </CardContent>
        { room.status ? (
          <CardActions>
            <div 
              className = {`flex justify-center w-full mb-4 ${isActionBar ? 'mt-3' : 'mt-5'}`}
            >
              <button
                type = "button"
                className = {`btn btn-default btn-outline btn-anim border ${isOnSession ? 'border-title' : 'border-primary'} px-8 py-2 rounded-full uppercase`}
                onClick = { () => handleGoToSession( room ) }
              >
                <SensorsIcon 
                  color = { isOnSession ? 'secondary' : 'primary'}
                />

                <p 
                  className = {`btn-text ${isOnSession ? 'text-title' : 'text-primary'}`}
                >
                  { isOnSession
                    ? getTranslation( Texts.remoteActiveConnection as Word ) 
                    : getTranslation( Texts.remoteConnection as Word )  
                  }
                </p>
              </button>
            </div>
          </CardActions>
        ) : null}
      </Card>
    </>
  )
}
export default Remote;