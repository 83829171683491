import React, { FC, CSSProperties } from 'react'

// Dependencies
import { Tooltip, IconButton, TooltipProps } from '@mui/material';

interface RButtonIconTooltipProps extends TooltipProps {
  sxTooltip ?: CSSProperties,
  sxIconButton ?: CSSProperties,
  title: string,
  onClick ?: () => void
}
 
const RButtonIconTooltip: FC<RButtonIconTooltipProps> = ({ 
  title, placement, sxTooltip, sxIconButton, children, onClick
}) => ( 
    <Tooltip
      title = { title }
      placement = { placement || 'top' }
      sx = {{ ...sxTooltip }}
    >
      <IconButton
        sx = {{ ...sxIconButton }}
        onClick={ () => onClick && onClick() }
      >
        { children }
      </IconButton>
    </Tooltip>
  )
 
export default RButtonIconTooltip;