import React, { FC } from 'react'

// Dependencies
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

// Validation
import { Formik } from "formik";

// Types and models
import Texts from '../../models/Texts'
import { Word, Room } from '../../types'

// Components
import InputForm from '../utils/RookFormInputs/InputForm'

// Hooks
import useTranslation from '../../hooks/useTranslation'
import { capitalize } from '../../utils';

// Validations
import validationSchema from '../../validations/ValidationsLegendModal'

interface LegendModalProps {
  daq: Room | null,
  open: boolean,
  loading: boolean,
  setOpen: () => void,
  handleUpdate: (name: string) => void
}

/**
 * Modal para actualizar el nombre del daq
 * @param props contiene todas las propiedades necesarias descritas en LegendProps
 * @param props.daq la data a mostrar del daq
 * @param props.open saber si esta abierto o no el modal
 * @param props.loading saber si se esta actualizado el nombre
 * @param props.setOpen función para cerrar el modal
 * @param props.handleUpdate actualizar la data del daq
 * @returns formulario para actualizar el nombre del daq
 */
const LegendModal: FC<LegendModalProps> = ({ daq, open, loading, setOpen, handleUpdate }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <Dialog
      onClose = { () => setOpen() }
      open = { open }
    >
      <DialogTitle >
        { getTranslation( Texts.legendModalTitle as Word ) }
      </DialogTitle>

      <Formik 
        initialValues={{
          'room_name': capitalize(daq?.name || '') || ''
        }} 
        enableReinitialize
        validationSchema = { validationSchema }
        onSubmit={values => handleUpdate(values.room_name)}
      >

        { props => (

          <form className="p-5" onSubmit = { props.handleSubmit }>
            <InputForm
              type='text'
              placeholder={ getTranslation( Texts.legendModalPlaceholder as Word ) }
              id='room_name'
              title={ getTranslation(Texts.legendModalInput as Word) }
              background = 'bg-card-body'
              marginTop = 'mt-0'
              value={ props.values.room_name }
              handleChange={ props.handleChange }
              handleBlur={ props.handleBlur }
              isError={
                (props.touched.room_name && !!props.errors.room_name )
              }
              errorText={ props.errors.room_name && 
                getTranslation( Texts[props.errors.room_name] as Word) 
              } 
            />
              <div className="flex justify-center mt-10">
            { loading 
              ? (<CircularProgress color = 'primary' />) 
              : (
                <button
                  type = "submit"
                  className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
                >
                  { getTranslation( Texts.save as Word ) }
                </button>
              ) 
            }
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
 
export default LegendModal;