import React, { ReactElement, useState, Fragment } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window';

// Dependencies
import shortid from 'shortid'

interface GenericTableProps<T> {
  normalTableContainerClassName ?: string
  showCounter ?: boolean
  body: T[],
  headers: string[],
  bodyKeys: string[],
  specialAt ?: string
  isVirtualList ?: boolean,
  withoutStyles ?: boolean,
  height ?: number | string,
  rowVirtualHeight ?: number,
  customCounter ?: (index: number) => number,
  actions ?: (element: T) => ReactElement,
  specialAtElement ?: (element: T) => ReactElement,
}
 
const GenericTable = <T extends unknown>({ 
  showCounter = false, headers, body, bodyKeys, isVirtualList = false, height = 150,
  specialAt = '', rowVirtualHeight = 46, withoutStyles = false, normalTableContainerClassName,
  actions, specialAtElement, customCounter
}: GenericTableProps<T>) => {

  const [columns] = useState(showCounter ? headers.length + 1 : headers.length)

  const whatToShow = (e: any) : any => {
    
    if (e === 0) return e

    return '-'

  }

  /* A function that returns a ReactElement. */
  const renderRows = (props: ListChildComponentProps) : ReactElement => {
    const { index, style } = props
    
    const element = body[index]

    if (element)
      return (
        <div 
          style={{ ...style, gridTemplateColumns: `repeat(${columns}, 1fr)`}}
          className="grid text-center items-center bg-card-header p-2"
        >

          { showCounter && (
            <p>
              { index + 1 }
            </p>
          )}

          {bodyKeys.map(key => (
            // eslint-disable-next-line react/jsx-fragments
            <Fragment
              key={ shortid.generate() }
            >
              { specialAt === key && specialAtElement
                ? specialAtElement(element)
                : (
                  <p className = 'overflow-hidden'>
                    { (element as any)[key] || whatToShow((element as any)[key]) }
                  </p>
                )
              }
            </Fragment>
          ))}

          { actions && (
            <div className="flex justify-center items-center">
              { actions(element) }
            </div>
          )}
        </div>
      )
    
    return <></>
  }

  return !isVirtualList ? ( 
    <div 
    className = {`overflow-x-scroll lg:overflow-x-hidden ${normalTableContainerClassName || ''}`}
    >
      <table className = {`table-auto ${withoutStyles ? '' : 'shadow-md'} w-full capitalize`}>
        <thead className={`${withoutStyles ? '' : 'bg-input-background'}`}>
          <tr>

            { showCounter && (
              <th
                className = {`py-2 ${withoutStyles ? '' : 'border border-table'}`}
              >
                Nº
              </th>
            )}

            {headers.map((head) => (
              <th
                key={ shortid.generate() }
                className = {`py-2 ${withoutStyles ? '' : 'border border-table'}`}
              >
                { head }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((element, idx) => (
            <tr
              key={ shortid.generate() }
            >
              { showCounter && (
                <td 
                  className = {`${withoutStyles ? 'py-2' : 'p-2 border border-table'}`}
                >
                  { customCounter ? customCounter(idx) : idx + 1 }
                </td>
              )}

              {bodyKeys.map(key => (
                <td 
                  key={ shortid.generate() }
                  className = {`p-2 ${withoutStyles ? 'py-2' : 'p-2 border border-table'}`}
                >
                  { specialAt === key && specialAtElement
                    ? specialAtElement(element)
                    : (element as any)[key] || whatToShow((element as any)[key])
                  }
                </td>
              ))}

              { actions && (
                <td 
                  className = {`p-2 ${withoutStyles ? 'py-2' : 'p-2 border border-table'}`}
                >
                  <div className="flex justify-center items-center">
                    { actions(element) }
                  </div>
                </td>
              )}

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className='bg-card-header rounded-md pb-4'>
      <div
        style={{
          gridTemplateColumns: `repeat(${columns}, 1fr)`
        }} 
        className="grid text-center items-center bg-card-header rounded-t-md p-2"
      >

        { showCounter && (
          <p>
            Nº
          </p>
        )}

        {headers.map((head) => (
          <p
           key={ shortid.generate() }
          >
            { head }
          </p>
        ))}

      </div>

      <div 
        style={{ height }}
        className="w-full rounded-b-md"
      >
        <FixedSizeList
          height= { height }
          width = '100%'
          itemSize = { rowVirtualHeight }
          itemCount = { body.length }
          overscanCount = { 5 }
        >
          { renderRows }
        </FixedSizeList>
      </div>
    </div>
  )
}
export default GenericTable;