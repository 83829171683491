import styled from 'styled-components';

// Colors
import Colors from '../../../utils/colors';

export default styled.div`
    
  background-color: ${Colors.secondaryBackgroundColor};
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 25rem;
  }

  @media (min-width: 900px) {
    min-width: 25rem;
    margin-right: 1rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }

`;

export const Square = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  background-color: ${(props) => props.color || Colors.eMin};
  margin-right: 1rem;
  border-radius: 0.3rem;
`;