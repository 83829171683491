import React, { FC } from 'react'

// Icons
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import rookPointsIcon from '../../../../assets/icons/rookpoints.png'
import trophyIcon from '../../../../assets/icons/trophy.png'

// Components
import { RBox } from '../../../utils/RookLayout';
import IndividualBodyDescription from './IndividualBodyDescription';
import CircularProgressWithLabel from '../CircularProgressWithLabel';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

// Utils
import Colors from '../../../../utils/colors';

interface IndividualBodyProps {
  goals: { earned: number, total: number }
  points: number,
  rookPoints: number,
  percentage: number,
  rounded ?: boolean
}
 
const IndividualBody: FC<IndividualBodyProps> = ({ 
  goals, points, rookPoints, percentage, rounded,
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <RBox className={`p-5 justify-center ${rounded && 'rounded-2xl bg-card-body my-4'}`}>
      <RBox className='flex-col gap-4'>

        <IndividualBodyDescription
          title = { getTranslationByKey('goals') }
          description={`${goals.earned} ${getTranslationByKey('from')} ${goals.total}`}
        >
          <TrophyIcon 
            fontSize='small'
          />
        </IndividualBodyDescription>

        <IndividualBodyDescription
          title = { getTranslationByKey('points') }
          description={`${points} pts`}
        >
          <img src={trophyIcon} alt="trophy" />
        </IndividualBodyDescription>

        <IndividualBodyDescription
          title = 'Rookpoints'
          description={`${Number(rookPoints).toFixed(2).replace(/\.00/, '')} rp`}
        >
          <img src={rookPointsIcon} alt="rookpoints" />
        </IndividualBodyDescription>
        
      </RBox>

      <CircularProgressWithLabel
        value = { Math.round(percentage) }
        fill = { Colors.title }
        size = { 90 }
      />

    </RBox>
  );
}
 
export default IndividualBody;