import { DialogActions } from '@mui/material';
import React, { FC } from 'react'

interface RActionsProps {
  
}
 
const RActions: FC<RActionsProps> = ({ children }) => ( 
    <DialogActions
      sx={{
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (min-width: 768px)': {
          flexDirection: 'row'
        }
      }}
    >
      { children }
    </DialogActions>
  )
 
export default RActions;