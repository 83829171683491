import * as Yup from 'yup'

export default Yup.object().shape({
  name: Yup.string()
            .required('profileNameInputError')
            .min(3, 'profileNameInputError')
            .max(50, 'profileNameInputErrorLength'),
  last_name_1: Yup.string()
            .required('profileLastNameInputError')
            .min(4, 'profileLastNameInputError')
            .max(100, 'profileLastNameInputErrorLength'),
  last_name_2: Yup.string()
            .notRequired()
            .when('last_name_2', {
              is: (value: any) => value,
              then: rule => rule.min(4, 'profileMLastNameInputError')
                                .max(100, 'profileMLastNameInputErrorLength'), 
            }),
  landline: Yup.number()
            .positive('profileLandlineError')
            .integer('profileLandlineError')
            .notRequired()
            .when('landline', {
              is: (value: any) => value,
              then: (rule) => rule.positive().test(
                'minDigits', 
                'profileLandlineErrorLength', 
                (number) => String(number).length >= 8 && String(number).length <= 15
              ),
            }),
  mobile_phone: Yup.number()
                    .positive('profileMobilePhoneError')
                    .required('profileMobilePhoneError')
                    .integer('profileMobilePhoneError')
                    .test(
                      'minDigits', 
                      'profileMobilePhoneErrorLength', 
                      (number) => String(number).length >= 8 && String(number).length <= 15
                    ),
  email: Yup.string()
            .email("loginEmailValidError")
            .required("loginEmailRequiredError"),
},[['landline', 'landline'], ['last_name_2', 'last_name_2']])