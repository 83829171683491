import React, { FC, useState } from 'react'

// Dependencies
import moment from 'moment'
import { useMachine } from '@xstate/react';
import { DateRange }  from '@mui/lab/DateRangePicker';

// Icons
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

// types
import { Flow, SearchIn } from '../types'

// Context
import ReportsMachine from '../context/machines/ReportsMachine'

// Components
import Range from '../components/reports/Range'
import Layout from '../components/layout/Layout'
import Charts from '../components/reports/Charts'
import Spinner from '../components/utils/Spinner';
import Support from '../components/utils/Support';
import Ranking from '../components/reports/Ranking';
import Container from '../components/layout/Container'
import ChartModal from '../components/reports/ChartModal';

// Hook
import useTranslation from '../hooks/useTranslation'
 
const Reports: FC = () => {

  // Bandera para abrir el ChartModal
  const [open, setOpen] = useState(false)

  // Saber si la gráfica que se va a mostrar es de pie
  const [isPie, setIsPie] = useState(false)

  // Machine que controla esta vista
  const [machine, send] = useMachine(ReportsMachine)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  /**
   * Mostrar en un modal la gráfica más grande
   * @param flag saber si es pie
   */
  const maximize = (flag: boolean) : void => {
    setOpen(true)
    setIsPie(flag)
  }

  /**
   * Buscar por data dentro de un periodo
   * @param range Rango de fechas a buscar
   */
   const searchPeriod = (range: DateRange<Date>) : void => {
    const dates : string[] = [
      moment(range[0]).format('YYYY-MM-DD'),
      moment(range[1]).format('YYYY-MM-DD')
    ]
    send({ type: 'PERIOD', data: dates })
  }

  /**
   * Función para buscar
   * @param search usuario a buscar 
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      <Spinner
        show = { machine.matches('loading')}
      >
        <Container
          title = { getTranslationByKey('navMenuReports') }
          icon = { <DonutLargeIcon /> }
          rightButton = { 
            <Support
              action='statistics'
            /> 
          }
        >
          <Range
            loading = { machine.matches('searching') }
            searchPeriod = { searchPeriod }
          />

          { (machine.context.sessions && machine.context.trainings) && (
            <Charts
              maximize = { maximize }
              sessions = { machine.context.sessions }
              trainings= { machine.context.trainings }
            />
          )}

          <Ranking 
            pageSize = { machine.context.pageSize }
            sizes = { machine.context.sizes }
            sorting = { machine.context.sorting || { key: '', flow: Flow.normal } }
            clasification = { machine.context.pageData }
            currentPage = { machine.context.currentPage }
            pages = { machine.context.totalPages }
            handlePageSizeChange = { option => {
              send({ type: 'RESIZE', data: option })
            }}
            handlePageChange = { page => send({ type: 'PAGEING', data: page })}
            handleSearch = { searchIn }
            sort = { key => send({ type: 'SORT', data: key }) }
          />

          { (machine.context.sessions && machine.context.trainings) && (
            <ChartModal 
              open = { open }
              isPie = { isPie }
              setClose = { () => setOpen(false) }
              sessions = { machine.context.sessions }
              trainings= { machine.context.trainings }
            />
          )}


        </Container>
      </Spinner>
    </Layout>
  )

}
 
export default Reports;