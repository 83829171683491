import React, { FC } from 'react'

// Dependencies
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// Icons
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

// Modelos
import CircularProgress from '@mui/material/CircularProgress';
import { Word } from '../../types';
import Texts from '../../models/Texts';

// Components
import RButtonBack from '../utils/RookButtons/RButtonBack';

// Hooks
import useTranslation from '../../hooks/useTranslation';
import RButton from '../utils/RookButtons/RButton';

interface ActionsBarProps {
  isMaximized: boolean,
  state: string,
  changing: boolean,
  waiting: number,
  delay: string,
  resize: () => void,
  changeState: () => void,
  handleBack: () => void,
  handleQR: () => void
}
 
/**
 * Barra de acciones del grid contiene el botón de estado maximizar y minimizar
 * @param props contiene todas las propiedades necesarias descritas en ActionsBarProps
 * @param props.isMaximized saber si esta maximizado el grid
 * @param props.state saber el estado de la clase activa, stop o reset
 * @param props.changing saber si se esta cambiando de estado activa -> stop -> reset -> activa
 * @param props.waiting lo que falta para que se acabe el tiempo de espera
 * @param props.delay lo que falta para que se acabe el tiempo de espera pero en formato= 1:10 min
 * @param props.changeState cambiar a la clase de estado
 * @param props.handleBack regresar al componente/vista anterior
 * @param props.resize función para maximizar/minimizar
 * @returns barra con las acciones posibles dentro de la clase
 */
const ActionsBar: FC<ActionsBarProps> = ({ 
  isMaximized, state, changing, waiting, delay, changeState, handleBack, resize, handleQR
}) => {

  // Hooks para traducir
  const { getTranslation } = useTranslation();

  return (
    <>
      <div className="w-max">
        <RButtonBack
          action={handleBack}
        />
      </div>

      <div 
        id = 'action-bar'
        className="flex justify-between items-center w-full mx-auto mt-2"
      >
        <Tooltip title="Minimizar">
          <IconButton
            onClick = { () => resize() }
          >
            { isMaximized 
              ? <MinimizeIcon /> 
              : <FullscreenIcon /> 
            }
          </IconButton>
        </Tooltip>

        <p
          className = {`text-2xl font-bold uppercase opacity-1 ${waiting <= 0 && 'opacity-0'} transition-all lg:ml-60`}
        >
          { getTranslation(Texts.wWaitingRoom as Word) }{' '}<span className="text-title">{ delay } min</span>
        </p>

        <div
          className='flex flex-col-reverse md:flex-row items-center flex-wrap justify-between'
        >
          <RButton
            className='mr-2 mt-2 md:mt-0'
            onClick = { handleQR }
          >
            <span className="block mr-2">
              { getTranslation( Texts.wConfigurationQR as Word ) }
            </span>
            <QrCodeScannerIcon/>
          </RButton>

          { changing || state === 'initial'
            ? (
              <CircularProgress
                color = { state === 'stop' ? 'secondary' : 'primary' }
              />
            ) 
            : (
              <button
                type = "button"
                className = {`
                  uppercase ${ state === 'stop' ? 'bg-title' : 'bg-primary' } px-8 py-2 rounded-3xl lg:mt-0 lg:w-max`
                }
                onClick = { () => changeState() }
              >
                { getTranslation( Texts[state] as Word ) }
              </button>
            ) 
          }
        </div>


      </div>
    </>
  )
}
 
export default ActionsBar;