import React, { FC, useState, useEffect, useRef, ReactElement } from 'react'

// Dependencies
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment'

// Types and models
import { 
  OptionSearchBar, SearchIn, Word, 
  SearchTypeBox, OptionSearchBarDrop, Translation,
} from '../../types';
import Texts from '../../models/Texts';

// Components
import { InputError, InputDatePicker }  from './RookFormInputs';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface SearchBarProps {
  options ?: OptionSearchBar[],
  dropDownOptions ?: OptionSearchBarDrop[],
  lang ?: Translation,
  minText ?: number
  action: (search: SearchIn) => void
}

const inputStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    color: 'white',
    // minWidth: '5rem',
    borderTopLeftRadius: '4rem!important',
    borderBottomLeftRadius: '4rem!important',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `4rem 0 0 4rem !important`,
        borderColor: '#424242'
      },
      '& .PrivateNotchedOutline-root-11.MuiOutlinedInput-notchedOutline': {
        border: '2px solid transparent!important',
      },
      '& svg':{
        color: 'white'
      }
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: '#424242'
    },
  },
});

const internalDrop = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    color: 'white',
    minWidth: '10rem',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#424242'
      },
      '& .PrivateNotchedOutline-root-11.MuiOutlinedInput-notchedOutline': {
        border: '2px solid transparent!important',
      },
      '& svg':{
        color: 'white'
      }
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: '#424242'
    },
  },
});

// Opciones por defecto
const internalOptions: OptionSearchBar[] = [
  {
    key: "name",
    title: "usersNameInput"
  },
  {
    key: "last_name_1",
    title: "adminTableHeaderLastName"
  },
  {
    key: "email",
    title: "loginEmail"
  },
  {
    key: "pseudonym",
    title: "usersPseudonymInput"
  },
]

/**
 * Barra de busqueda
 * @param props contiene todas las propiedades necesarias descritas en SearchBarProps
 * @param props.options opciones para buscar o seleccionar las columnas
 * @param props.action función para buscar
 * @returns Barra de busqueda
 */
const SearchBar: FC<SearchBarProps> = ({ 
  options = internalOptions, dropDownOptions, lang, minText = 3,
  action 
}) => {

  // Estilos
  const classes = inputStyles()
  const internal = internalDrop()
  // const menuStyle = menuStyles()

  // Saber si esta corriendo en móviles
  const [isInMobile, setInMobile] = useState(false)

  // Saber si ya se busco se elimina la caja de busqueda para regresar a la lista normal
  const [isSubmited, setIsSubmited] = useState(false)

  // Mostrar texto de error
  const [isError, setIsError] = useState<{ flag: boolean, type: SearchTypeBox }>({
    flag: false,
    type: SearchTypeBox.text
  })

  // Valor del input
  const [search, setSearch] = useState<Moment | string>("")

  // Columna de la tabla
  const [searchColumn, setSearchColumn] = useState(options[0].key)

  // Seleccionamos la caja de texto
  const textInput = useRef<any>()

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Habilitar cmd + k
  useEffect(() => {
    
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (e) => {
        
        if ( e.metaKey && e.key === 'k'){
          
          if (textInput.current) {
            textInput.current.focus()
            e.preventDefault()
          }

        }

      })
    }

  }, [])

  // Habilitar el responsive en la barra para cambiar el ancho del drop
  useEffect(() => {
    
    if (typeof window !== 'undefined') {
      const screenWide = window.matchMedia('(max-width: 639px)')
      if (screenWide) {

        handleMatch(screenWide)

        if (screenWide?.addEventListener) screenWide.addEventListener('change', handleMatch)

        else screenWide.addListener(handleMatch)
        
      }
    }
  }, [])

  useEffect(() => {

    setSearch('')

  }, [searchColumn])

  /**
   * Función para saber si cae dentro del query
   * @param screen mediaquery
   */
  const handleMatch = (screen: any) : void => {
    if (screen.matches) {
      setInMobile(true)
    }
    else{
      setInMobile(false)
    }
  }

  /**
   * Función para mandar buscar, se busca solo se ha escrito más de tres caracteres se manda 
   * buscar, si se elimina todo se manda -1 para regresar a la lista original
   */
  const handleSubmit = (e: any) : void => {
    e.preventDefault()

    let data: string = '' 

    if (typeof search !== 'string') 
      data = moment(search).format('YYYY-MM-DD')

    else data = search

    if ( data.trim().length >= minText ) {
      setIsSubmited( true )
      action({ search: data, search_column: searchColumn })
    }
    else if ( data.trim().length === 0 ) 
       action({ search: '-1', search_column: searchColumn })
      else 
        setIsError({
          ...isError,
          flag: true,
        })
  }

  /**
   * Función para actualizar el valor del input
   * @param e evento del input cuando se escribe
   * @returns early return 
   */
  const handleTextChange = (e:any) : void => {

    // Si esta vacío y ya se había buscado se manda a la lista original
    if ( e.target.value.trim().length === 0 && isSubmited) {
      setSearch( e.target.value )
      setIsError({ flag: false, type: SearchTypeBox.text })
      setIsSubmited( false )

      setTimeout(() => {
        action({ search: '-1', search_column: searchColumn })
      }, 100)
      
      return
    }

    // si es 0 o mayor igual a 3 y no se ha mandado a buscar no se muestra el error
    if ( e.target.value.trim().length === 0  || e.target.value.trim().length >= minText) {
      setIsError({ flag: false, type: SearchTypeBox.text })
    } 
    else setIsError({ flag: true, type: SearchTypeBox.text })

    setSearch( e.target.value )
  }

  /**
   * saber que placeholder mostrar si es cmd + K o win + K
   */
  const getPlaceholder = () : string => {
    
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      return getTranslation( Texts.searchBarMacPlaceHolder as Word )
    }
    
    if (navigator.appVersion.indexOf('Win') !== -1){
      return getTranslation( Texts.searchBarWinPlaceHolder as Word )
    }

    return getTranslation( Texts.searchBarPlaceHolder as Word )

  }

  const renderSearchBox = () : ReactElement => {

    const option = options.find( column => column.key === searchColumn )
    let selected: SearchTypeBox = SearchTypeBox.text

    if (option && option.searchTypeBox !== undefined) {
      selected = option.searchTypeBox
    }

    if (selected === SearchTypeBox.date){
      return ( 
        <InputDatePicker
          lang = { lang || Translation.en }
          value = { typeof search === 'string' ? null : search }
          onChange = { date => setSearch( date ) }
        />
      )
    }

    if (selected === SearchTypeBox.drop && dropDownOptions)
      return (
        <FormControl
          variant = 'outlined'
          classes = { internal }
        >
          <Select
            labelId = "type"
            id = "column"
            value = { search }
            onChange = { (e) => setSearch(e.target.value as string)}
            sx = {{
              background: '#424242',
              borderColor: 'none',
              color: 'white',
              minWidth: '10rem',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
              outline: '2px solid transparent',
              outlineOffset: '2px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#424242'
                },
                '& .PrivateNotchedOutline-root-11.MuiOutlinedInput-notchedOutline': {
                  border: '2px solid transparent!important',
                },
                '& svg':{
                  color: 'white'
                }
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: '#424242'
              },
            }}
          >
            {dropDownOptions.map(drop => (
      
              <MenuItem
                key = { drop.key }
                value= { drop.key }
              >
                { drop.value }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )

    return (
      <input
        type="text"
        className="bg-card-body font-normal w-full py-1 px-2 outline-none text-gray-100"
        placeholder= { getPlaceholder() }
        id = "search"
        name = "search"
        value = { typeof search === 'string' ? search : '' }
        onChange = { handleTextChange }
        ref = { textInput }
      />
    )

  }

  return (
    <div className = "flex flex-col">
      <form
        className="flex flex-row w-full lg:w-max"
        onSubmit = { handleSubmit}
      >
        <FormControl
          variant = 'outlined'
          classes = { classes }
          style={{ minWidth: isInMobile ? '7rem' : '10rem' }}
        >
          <Select
            labelId = "type"
            id = "column"
            value = { searchColumn }
            onChange = { (e) => setSearchColumn(e.target.value as string)}
            sx = {{
              background: '#424242',
              borderColor: 'none',
              color: 'white',
              // minWidth: '5rem',
              borderTopLeftRadius: '4rem!important',
              borderBottomLeftRadius: '4rem!important',
              outline: '2px solid transparent',
              outlineOffset: '2px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: `4rem 0 0 4rem !important`,
                  borderColor: '#424242'
                },
                '& .PrivateNotchedOutline-root-11.MuiOutlinedInput-notchedOutline': {
                  border: '2px solid transparent!important',
                },
                '& svg':{
                  color: 'white'
                }
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: '#424242'
              },
            }}
          >
            {options.map(option => (
      
              <MenuItem
                key = { option.key }
                value= { option.key }
              >
                { getTranslation( Texts[option.title] as Word ) }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        { renderSearchBox() }
        <span
          className="flex items-center bg-card-body rounded-3xl rounded-l-none border-0 px-3 font-bold text-grey-100"
        >
            <button
              type = "submit"
              className = "text-red-400"
              data-cy = 'btn-search'
            >
              <SearchIcon />
            </button>
        </span>
      </form>

      { isError.flag && (

        <InputError>
          { getTranslation( Texts.searchBarError as Word ) }
        </InputError>

      )}

    </div>
  )
}
 
export default SearchBar;