import React, { FC, useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'

// Dependencies
import 'moment-timezone'
import moment from 'moment'
import { fromEvent, tap } from 'rxjs';
import { useActor } from '@xstate/react';
import Tooltip from '@mui/material/Tooltip'
import { useHistory } from 'react-router-dom'
import { Snackbar, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// Iconos
import TimelineIcon from '@mui/icons-material/Timeline';

// Context
import UIContext from '../../context/ui/UIContext'

// Types and model
import Texts from '../../models/Texts'
import { Session, Word } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface SessionListProps {
  service: any,
  showMetrics: boolean
}
 
/**
 * Lista de entrenamientos
 * @param props contiene todas las propiedades necesarias descritas en SessionListProps
 * @param props.service actor que controla la vista
 * @param props.showMetrics bandera para saber si mostrar o no el botón para ir a métricas basado
 * en permisos
 * @returns tabla de entrenamientos
 */
const SessionList: FC<SessionListProps> = ({ service, showMetrics }) => {

  // Machine que controla la tabla
  const [current, send] = useActor(service)

  // Sacamos el context y el estado de la maquina
  const { context, value } : any = current

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para la traducción
  const { getTranslation, getTranslationByKey } = useTranslation()

  const { centerData } = useContext( UIContext )

  // Referencia de la tabla para tener la misma medida con el title
  const tableRef = useRef<HTMLTableElement | null>(null)

  // Title style
  const [styles, setStyles] = useState<{width: string}>({ width: `100%` })

  // hook para navegar
  const history = useHistory()

  // Actualizar las variables
  useEffect(() => {
    
    if ( value === 'failure' ) setShowAlert(true)
    else setShowAlert(false)

    if ( value === 'login' ) history.push("/login")

  }, [value])

  useLayoutEffect(() => {

    if (tableRef && tableRef.current) {
      setStyles({ width: `${tableRef.current.offsetWidth}px` })

      fromEvent(window, 'resize')
        .pipe(
          tap(() => {

            if (tableRef && tableRef.current) 
              setStyles({ width: `${tableRef.current.offsetWidth}px` })

            else setStyles({ width: `100%` })

          }),
        )
        .subscribe()

    }

  }, [tableRef])

  /**
   * Obtener el número de resultados
   * @returns el total de resultados
   */
  const getPerPage = () : number => {

    if (!context.sessions) return 0

    // eslint-disable-next-line camelcase
    const { per_page, total } = context.sessions.meta

    // eslint-disable-next-line camelcase
    return total < per_page ? total : per_page
  }

  const prepareTransition = (session: Session) : void  => {
    send({
      type: 'TRANSITION',
      data: session
    })
  }

  const getSensorName = (session: Session) : string => {
    
    if (!session.sensor_name) return '-'

    if (session.sensor_name.includes('appleWatch')) return 'Apple Watch'

    return session.sensor_name
  }

  const getSessionEmail = (session: Session) : string => {
    
    if (!session.email) return '-'

    if (session.email.length > 35) return `${session.email.substring(0, 32)}...`

    return session.email

  }

  return ( 

    <>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = 'error'
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          {getTranslation( Texts.generalError as Word )} 
        </Alert>
      </Snackbar>

      <div className="overflow-x-scroll lg:overflow-x-auto">
        <p
          style = { styles }
          className = "lg:bg-input-background p-2 mt-8 lg:rounded-tl-xl lg:rounded-tr-xl lg:border lg:border-table text-gray-450"
        >
          {getTranslation( Texts.showing as Word )} 1-{ getPerPage() } de {context.sessions ? context.sessions.meta.total : 0} {getTranslation( Texts.navMenuSesions as Word )}
        </p>
        <table 
          ref = { tableRef }
          className="table-auto shadow-md w-full capitalize"
        >
          <thead className="bg-input-background">
            <tr>
              <th className="py-2 border border-table">
                {getTranslation( Texts.sessionsHeaderDateTime as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.adminTableHeaderName as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.adminTableHeaderLastName as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.adminTableHeaderEmail as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.sessionsTableHeaderTypeSession as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.sessionsTableHeaderSensor as Word )}
              </th>
              <th className="py-2 border border-table">
                {getTranslation( Texts.sessionsTableHeaderTraining as Word )}
              </th>
              { showMetrics && (
                <th className="py-2 border border-table">
                  {getTranslation( Texts.sessionsTableHeaderMetrics as Word )}
                </th>
              )}
            </tr>
          </thead>

          <tbody>

            { (value !== 'loading' && context.sessions) && (

              <>
                {context.sessions.data.map((session: Session) => (
                  <tr
                    key = { session.training_uuid }
                  >
                    <td className="p-2 border border-table">
                      { !session.offset 
                        ? moment(session.start)
                          .format(`${getTranslationByKey('momentDateFormat')} HH:mm:ss`)
                        : moment
                          .utc(session.start)
                          .tz(centerData?.offset_name || 'America/Mexico_City')
                          .format(`${getTranslationByKey('momentDateFormat')} HH:mm:ss`)
                      }
                    </td>
                    <td className="p-2 border border-table">
                      { session.user_name }
                    </td>
                    <td className="p-2 border border-table">
                      { session.lastName || '-' }
                    </td>
                    <td className="p-2 border border-table lowercase">
                      { getSessionEmail(session) }
                    </td>
                    <td className="p-2 border border-table">
                      { session.device_type }
                    </td>
                    <td className="p-2 border border-table">
                      { getSensorName(session) }
                    </td>
                    <td className="p-2 border border-table">
                      { session.training_name }
                    </td>
                    { showMetrics && (
                      <td className="p-2 border border-table text-center">
                        <div className = "flex justify-center">
                          <div
                            // disabled = { value === 'updating' || value === 'removing' }
                            className = "rounded-full bg-blue-500 text-xs 2xl:text-base"
                          >
                            <Tooltip
                              title = { getTranslation( Texts.trainingToolTip as Word ) }
                              placement = "top"
                            >
                              <IconButton
                                onClick = { () => prepareTransition(session)}
                                data-cy = 'btn-trainings'
                              >
                                <TimelineIcon
                                  fontSize = 'small'
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))} 
              </>

            )}

          </tbody>

        </table>
      </div>  

    </>
  );
}
 
export default SessionList;