import React, { FC, useEffect, useState } from 'react';

// Dependencies 
import { useMediaQuery, useTheme } from '@mui/material';

// types
import { 
  Training,
  ClusterIcon,
  Measurements, 
} from '../../types';

// Components
import AutoSizeRemote from './AutoSizeRemote';

// Hooks
import useSizes from '../../hooks/useSizes';
import VirtualRemote from './VirtualRemote';

interface ClusterListProps {
  total: number,
  columns: number,
  inFlex: Training[],
  isFullScreen: boolean,
  hasStreaming: boolean,
  icon: ClusterIcon | null,
  measurements: Measurements | null,
  handleBan: (user: Training) => void
}
 
const ClusterList: FC<ClusterListProps> = ({ 
  measurements, icon, columns, isFullScreen, total, inFlex, hasStreaming,
  handleBan
}) => {

  // Saber cuantas columnas mostrar
  const [virtualColumns, setVirtualColumns] = useState(5)

  // Hooks para saber en que media query estamos
  const theme = useTheme()
  const fullScreen = useMediaQuery( theme.breakpoints.up('xl') )
  const desktop = useMediaQuery( theme.breakpoints.between('lg', 'xl') )
  const tablet = useMediaQuery( theme.breakpoints.between('md', 'lg') )
  
  // Medidas para auto size
  const {
    basis,
    widthInFlex,
    getWidth,
    getClusterHeight,
    getImageSize 
  } = useSizes({ measurements, inFlex, total, columns, isFullScreen })

  useEffect(() => {
    
    setVirtualColumns(() => {

      if (fullScreen) return 5

      if (desktop) return 4

      if (tablet) return 3

      return 1

    })

  }, [tablet, desktop, fullScreen])

  return measurements && (
    total < 20
    ?  (
      <AutoSizeRemote
          total={total}
          columns={columns}
          inFlex={inFlex}
          isFullScreen={isFullScreen}
          hasStreaming={hasStreaming}
          icon={icon}
          measurements={measurements}
          handleBan={handleBan} 
          basis={ basis } 
          widthInFlex={  widthInFlex } 
          getWidth = {getWidth} 
          getClusterHeight={ getClusterHeight } 
          getImageSize={ getImageSize }      
        />
    )
    : (
      <div className="h-grid w-full">
        <VirtualRemote
          columns = { virtualColumns }
          total={total}
          icon={icon}
          measurements={measurements}
          handleBan={handleBan}
          getImageSize={ getImageSize }
        />
      </div>
    )
  )
}
 
export default ClusterList;