import React, { ChangeEvent, FC, useRef, useState, useEffect } from 'react'

// Dependencies
import { useActor } from '@xstate/react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Snackbar, Alert, AlertColor, CircularProgress } from '@mui/material';

// Icons
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Swal from 'sweetalert2';

// Types and model
import Texts from '../../models/Texts'
import { Multimedia, Word } from '../../types'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Styles
import { Delete, Image } from './CenterStyles'

interface CenterGalleryProps {
  service: any,
}

/**
 * Galeria del centro
 * @param {Object} props contiene todas las propiedades necesarias descritas en CenterGalleryProps
 * @param {Any} props.service contiene al actor para controlar este componente
 * @returns componente con la galleria del centro
 */
const CenterGallery: FC<CenterGalleryProps> = ({ service }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Referencia al input para hacer clic
  const inputFile = useRef<any>()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Navegación
  const history = useHistory()

  // hook para inicar al automata para esta página
  const [current, send] = useActor(service)
  const { context, value } : any = current

  useEffect(() => {
    
    if (value === 'uploaded' || value === 'deleted' || value === 'failure')
      setShowAlert( true )

    else setShowAlert(false)

    if (value === 'login') history.push('/login')

  }, [value])

  // ComponentDidUnMount para indicar a la maquina volver al estado inicial
  useEffect(() => () => {
    send({ type: 'READY' })
  }, [])

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (value) {
      case 'failure': return 'error'
      case 'uploaded': 
      case 'deleted': 
        return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
   const getMessage = () : string => {
    switch (value) {
      case 'uploaded': return getTranslation(Texts.centerImageAddedSuccessfully as Word)
      case 'deleted': return getTranslation( Texts.centerImageDeletedSuccessfully as Word )
      case 'failure': return getTranslation( Texts.generalError as Word )
      default: return getTranslation( Texts.profileImageSizeError as Word ) 
    }
  }

  /**
   * Eliminar una imagen
   * @param id de la imagen a eliminar
   */
  const handleDelete = (id: string) : void => {

    Swal.fire({
      title: getTranslation(Texts.centerGalleryConfirmation as Word),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslation( Texts.delete as Word ),
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        send({
          type: 'REMOVE',
          data: id
        })
      }
    })
  }

  /**
   * Manejador de la imagen
   * @param e evento para recibir la imagen
   * @returns early return 
   */
   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) : void => {
    e.preventDefault()

    // Verificamos que exista la imagen
    if( !e.currentTarget.files ) return 

    // Sacamos el archivo
    const file = e.currentTarget.files.item(0)

    if ( !file ) {
      Sentry.captureMessage(
        `No se encontro la imagen profile: ${JSON.stringify(e.currentTarget.files)}`
      )
      return 
    }

    // Verificamos el tamaño del la imagen
    const size = (file.size / 1024 / 1024).toFixed(2)

    // Si es mayor a dos megas mandamos la alerta
    if ( Number(size) > 2 ) {
      setShowAlert( true )
      return 
    }

    // Si no mandamos la imagen
    send({
      type: 'IMAGE',
      data: file
    })

  }

  const handleClose = () : void => {
    setShowAlert(false)

    setTimeout(() => {
      send({ type: 'READY' })
    }, 120)

  }

  return ( 
    <div 
      className={`grid grid-cols-1 ${(context.data && context.data.length > 0) ? 'md:grid-cols-2 lg:grid-cols-3' : ''} gap-8 items-center min-h-20 mt-10`}
    >
      
      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { handleClose }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { handleClose }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <input 
        type="file" 
        id="image_admin" 
        name="image_admin" 
        accept="image/png, image/jpeg"
        className = "hidden"
        onChange = { handleFileChange }
        ref = { inputFile }
      />

      { context.data && (
        <>
          {context.data.map((image: Multimedia) => (
            <Image
              key = { image.uuid }
              image = { image.url }
            >
              <Delete
                id = "btn-delete"
              >
                { value === 'removing' 
                  ? (
                    <CircularProgress  
                      color = "secondary"
                      size = "2rem"
                    />
                  )
                  : (
                    <button 
                      type = "button"
                      className = "p-1 rounded-xl"
                      onClick = { () => handleDelete(`${image.uuid}`) }
                    >
                      <HighlightOffIcon />
                    </button>
                  )
                }
              </Delete>
            </Image>
          ))}
        </>
      )}

      <div className = "bg-input-background rounded-xl p-5 text-center h-40 w-full lg:w-48 2xl:w-64 flex items-center justify-center justify-self-center shadow-xl">
        { value === 'adding' 
          ? (
            <CircularProgress  color = "secondary" />
          ) 
          : (
            <Tooltip 
              title = { getTranslation(Texts.centerGalleryAdd as Word) }
            >
              <IconButton
                onClick = { () => inputFile.current.click() }
              >
                <AddIcon
                  fontSize = "large"
                />
              </IconButton>
            </Tooltip>
          ) 
        }
        
      </div>
    </div>
  )
}
 
export default CenterGallery;