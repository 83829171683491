import React, { FC, ChangeEvent, useState, useEffect } from 'react'

// Dependencies
import { useMachine } from '@xstate/react';
import { Pagination } from '@mui/material';

// Icons
import { BandSensors } from '../components/utils/Icons';

// Context
import SensorMachine from '../context/machines/SensorsMachine'

// Models and types
import { OptionSearchBar, SearchIn, Word } from '../types'
import Texts from '../models/Texts'

// Components
import Layout from '../components/layout/Layout';
import SearchBar from '../components/utils/SearchBar'
import Container from '../components/layout/Container';
import SensorList from '../components/sensors/SensorList';
import Spinner from '../components/utils/Spinner';
import Support from '../components/utils/Support';


// Hooks
import useTranslation from '../hooks/useTranslation'

// Opciones para el buscador
const internalOptions: OptionSearchBar[] = [
  {
    key: "name",
    title: "sensorTableHeaderSensor"
  },
  {
    key: "mac",
    title: "mac"
  },
]


const Sensors: FC = () => {

  // Hook para traducir
  const { getTranslation } = useTranslation()
  
  // Mostrar o no el spinner
  const [loading, setLoading] = useState(false) 

  // Maquina que controla la vista
  const [machine, send] = useMachine(SensorMachine)

  useEffect(() => {
    
    if(
      machine.matches('loading') 
      || (machine.matches('searching') && machine.context.search?.search !== '-1')
      || machine.matches('lookingFor')
    ) setLoading(true)

    else setLoading(false)

  }, [machine])

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    send({
      type: 'PAGEING',
      page: value
    })
  };

  return ( 
    <Layout>
      <Spinner
        show = { loading }
      >
        <Container
          title = { getTranslation( Texts.navMenuSensors as Word )}
          icon = { <BandSensors color = '#fff'/> }
          rightButton = {
            <Support
              action='sensors'
            /> 
          } 
        >

          <div
            className="flex justify-center md:justify-end w-full"
          >
            <SearchBar
              options = { internalOptions }
              action={ searchIn }
            />
          </div>

          { (machine.context.page && !loading) && (
            <SensorList 
              service = { machine.context.page }
            />
          )}

          { (machine.context.pages > 1) && (
            <div
              className="flex justify-center mt-12 mb-4"
            >
              <Pagination
                showFirstButton
                showLastButton
                page = { machine.context.currentPage }
                variant="text"
                color = "secondary"
                count = { machine.context.pages }
                onChange = { handleChange }
              />
            </div>
          )}

        </Container>
      </Spinner>
    </Layout>
  )
}
 
export default Sensors;