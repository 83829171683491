import React, { FC } from 'react'

// Icon
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

// Components
import { RBox, RRoundedContainer } from '../../utils/RookLayout'
import ChallengeInfoText from './ChallengeInfoTexts';

// Hooks
import useTranslation from '../../../hooks/useTranslation'

interface ChallengeInformationProps {
  name: string,
  description: string,
  modality: string
  type: string,
  compliance: string,
  start: string,
  end: string,
  users: number
}
 
const ChallengeInformation: FC<ChallengeInformationProps> = ({
  name, description, modality, type, compliance, start, end, users
}) => {

  const { getTranslationByKey } = useTranslation()

  return ( 
    <RRoundedContainer className='bg-card-body py-2 px-4 mb-8'>
      {/* <RBox type='grid' className='md:grid-cols-sixty-forty gap-8'> */}
      <RBox className='flex-col md:flex-row justify-between'>
        <RBox className='items-center'>
            <MilitaryTechIcon />
            <div className='ml-4'>
              <p className='capitalize'>
                {name}
              </p>

              <p className='font-thin text-sm'>
                { description }
              </p>
            </div>
        </RBox>

        <RBox type = 'grid' className='grid-cols-3 md:grid-cols-2 gap-4 lg:gap-0 lg:gap-y-1 md:grid-cols-2 lg:grid-cols-fixed-two-columns'>

          <ChallengeInfoText
            title= { getTranslationByKey('gamingGoalModalType') }
            text = { type }
          />

          <ChallengeInfoText
            title={ getTranslationByKey('gamingListParticipants') }
            text = { `${users >= 0 ? users : '-'} ${getTranslationByKey('users')}` }
          />

          <ChallengeInfoText
            title={ getTranslationByKey('gamingGoalModalModality') }
            text = { modality }
          />

          <ChallengeInfoText
            title={ getTranslationByKey('gamingListStartDate') }
            text = { start }
          />

          <ChallengeInfoText
            title={ getTranslationByKey('compliance') }
            text = { getTranslationByKey(compliance || 'competitive') }
          />

          <ChallengeInfoText
            title={ getTranslationByKey('gamingListEndDate') }
            text = { end }
          />

        </RBox>

      </RBox>
    </RRoundedContainer>
  );
}
 
export default ChallengeInformation;