import React, { FC, useContext, useEffect } from "react";

// Dependencies
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Context
import UIContext from "../context/ui/UIContext";

// Components
import Form from "../components/login/Form";

type AdminImageProps = {
  image?: string | null;
};

const AdminImage = styled.div<AdminImageProps>`
  width: 6rem;
  height: 6rem;
  min-height: 6rem;
  margin-top: 4rem;
  border-radius: 50%;
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "url('/images/placeholder.png')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    margin-top: 0;
    width: 12rem;
    height: 12rem;
    min-height: 12rem;
  }
`;

// eslint-disable-next-line arrow-body-style
const Login: FC = () => {
  const { centerImage, centerName, isUnavailable } = useContext(UIContext);
  const history = useHistory();

  useEffect(() => {
    if (isUnavailable || centerName === "") history.push("/rook");
  }, []);

  return (
    <main>
      <div className="w-1/2 mx-auto mt-4 md:w-auto md:mx-8 ">
        <img src="/images/RM_white.svg" alt="RookMotion" />
      </div>

      <div className="w-full mx-auto flex flex-col items-center mt-6">
        <AdminImage image={centerImage !== "" ? centerImage : null} />

        <h1 className="mt-8 text-center text-2xl text-title font-bold">
          {centerName}
        </h1>

        <Form showButton={centerName !== ""} />
      </div>
    </main>
  );
};

export default Login;
