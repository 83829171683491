/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useEffect, useContext } from 'react'

// Dependencies
import { StateValue } from 'xstate';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress, Snackbar, Alert, Switch } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

// Validation
import { Formik } from "formik";

// Context
import UIContext from '../../context/ui/UIContext'

// Componentes
import { InputForm , InputChangePasswordForm, InputSelect } from '../utils/RookFormInputs'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Types and models
import { Word, Administrator, Role } from '../../types'
import Texts from '../../models/Texts';

// Estilos
import { useStyles } from './AdminModalStyles'

// utils
import { capitalize, getTranscriptionOfRole } from '../../utils'

// Validations
import { 
  validationSchema, 
  validationWithoutPassword, 
} from '../../validations/ValidationsAdminModal'

interface AdminModalProps {
  info: Administrator
  openModal: boolean,
  value: StateValue,
  statusError: number,
  roles: Role[],
  setOpenModal: (flag: boolean) => {},
  addUser: (athlete: any) => void
}

/**
 * Modal para editar o crear un admin
 * @param param0 contiene todas las propiedades necesarias descritas en AdminModalProps
 * @param {StateValue} value estado de la maquina principal que controla esta vista
 * para saber si esta cargando o en que acción esta
 * @param {boolean} openModal Saber cuando abrir o no el modal
 * @param {Administrator} info pre-llenar el modal con el admin a editar cuando es de creación llegan strings vacíos
 * @param {statusError} number saber el código de respuesta que regreso la api para saber que alerta mostrar
 * @param {Role[]} roles lista de roles disponibles para el admin
 * @param {Function} addUser Función para editar y/o agregar un admin
 * @param {Function} setOpenModal Función para cerrar el modal
 * @returns 
 */
const AdminModal: FC<AdminModalProps> = ({
  value, openModal, info, statusError, roles, addUser, setOpenModal
}) => {

  // Estilos para el pop up de crear/editar usuario
  const classes = useStyles()
  

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Saber si estamos actualizando o no
  const [updating, setUpdating] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Sacamos el lenguaje
  const { lang } = useContext(UIContext)

  // Actualizar las variables
  useEffect(() => {
    
    if ( value === 'aborted' || value === 'failure') setShowAlert(true)
    else setShowAlert(false)

    if (value === 'waiting' || value === 'failure' || value === 'updating')
      setUpdating(true) 
    else {
      setUpdating(false)
    }

  }, [value])

  // Reset form
  const handleReset = () : void => {
    setOpenModal(false)
  }

  // hadleSubmit
  const handleSubmit = (values: any) : void => {
    if (updating) 
      addUser({ ...values, uuid: info.uuid })
    else addUser(values)
  }

  return ( 
    <Dialog
      onClose = { handleReset }
      open = { openModal }
      classes = { classes }
    >

      <DialogTitle id="simple-dialog-title">
        { updating
          ? getTranslation( Texts.adminModalUpdate as Word ) 
          : getTranslation( Texts.adminModalCreate as Word ) 
        }
      </DialogTitle>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = 'error'
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { statusError === 422
            ? getTranslation( Texts.adminCreateError as Word ) 
            : getTranslation( Texts.generalError as Word )
          }
        </Alert>
      </Snackbar>

      <Formik
        initialValues = {{
          email: info.email,
          name: capitalize(info.name) || '',
          last_name_1: capitalize(info.last_name_1),
          last_name_2: capitalize(info.last_name_2 || ''),
          landline: info.landline || "",
          mobile_phone: info.mobile_phone,
          role: info.role,
          password: '',
          password_confirmation: '',
          status: Boolean(info.status) || false
        }}
        validationSchema = {info.name === '' ? validationSchema : validationWithoutPassword}
        enableReinitialize
        onSubmit = { values => handleSubmit( values ) }
      >

        { props => (
            
            <form className="p-5" onSubmit = { props.handleSubmit }>

              <div className="md:grid md:grid-cols-3 gap-3">

                <InputForm 
                  type= "text"
                  placeholder= { getTranslation( Texts.adminNamePlaceHolder as Word ) }
                  id= "name" 
                  title= { getTranslation( Texts.adminTableHeaderName as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-0"
                  value={ props.values.name } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur}   
                  isError={(props.touched.name && !!props.errors.name )} 
                  errorText={ props.errors.name && 
                    getTranslation( Texts[props.errors.name] as Word) 
                  }                
                />

                <InputForm 
                  type= "text"
                  placeholder= { getTranslation(Texts.adminLasNamePlaceHolder as Word) }
                  id= "last_name_1" 
                  title= { getTranslation( Texts.adminTableHeaderLastName as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0"
                  value={ props.values.last_name_1 } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.last_name_1 && !!props.errors.last_name_1 )} 
                  errorText={ props.errors.last_name_1 && 
                    getTranslation( Texts[props.errors.last_name_1] as Word) 
                  }                  
                />

                <InputForm 
                  type= "text"
                  placeholder= { getTranslation( Texts.adminMLasNamePlaceHolder as Word ) }
                  id= "last_name_2" 
                  title= { getTranslation( Texts.usersMLastNameInput as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0"
                  value={ props.values.last_name_2 } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.last_name_2 && !!props.errors.last_name_2 )} 
                  errorText={ props.errors.last_name_2 && 
                    getTranslation( Texts[props.errors.last_name_2] as Word) 
                  }                 
                />

              </div>

              <div className = "mt-4">

                <InputForm 
                  type= "email"
                  placeholder= { getTranslation( Texts.adminEmailPlaceHolder as Word ) }
                  id= "email" 
                  inputMode = "email"
                  title= { getTranslation( Texts.adminTableHeaderEmail as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0"
                  readOnly = { updating }
                  value={ props.values.email } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur}   
                  isError={(props.touched.email && !!props.errors.email )} 
                  errorText={ props.errors.email && 
                    getTranslation( Texts[props.errors.email] as Word) 
                  }               
                />
                
              </div>

              <div className = "grid grid-cols-1 gap-4 md:grid-cols-2 mt-0 md:mt-4">

                <InputForm 
                  type= "tel"
                  placeholder= { getTranslation( Texts.adminPhonePlaceHolder as Word ) }
                  id= "landline" 
                  title= { getTranslation( Texts.adminTableHeaderPhone as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0"
                  value={ props.values.landline } 
                  handleChange={
                    e => props.handleChange({ 
                      target: { name: 'landline', value: e.target.value.replace(/\s/g, '') } 
                    })
                  }
                  handleBlur={props.handleBlur}   
                  isError={(props.touched.landline && !!props.errors.landline )} 
                  errorText={ props.errors.landline === "profileLandlineErrorLength" 
                    ? getTranslation(Texts.profileLandlineErrorLength as Word)
                    : getTranslation(Texts.profileLandlineError as Word)
                  }                
                />

                <InputForm 
                  type= "tel"
                  placeholder= { getTranslation( Texts.adminMobilePhonePlaceHolder as Word ) }
                  id= "mobile_phone" 
                  title= { getTranslation( Texts.adminTableHeaderMobile as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0"
                  value={ props.values.mobile_phone } 
                  handleChange={
                    e => props.handleChange({ 
                      target: { 
                        name: 'mobile_phone', 
                        value: e.target.value.replace(/\s/g, '')
                      } 
                    })
                  } 
                  handleBlur={props.handleBlur}   
                  isError={(props.touched.mobile_phone && !!props.errors.mobile_phone )} 
                  errorText={ props.errors.mobile_phone === "profileMobilePhoneErrorLength" 
                    ? getTranslation(Texts.profileMobilePhoneErrorLength as Word)
                    : getTranslation(Texts.profileMobilePhoneError as Word)
                  }                
                />

              </div>

              <div 
                className={`grid grid-cols-1 gap-4 items-center
                  ${updating ? 'md:grid-cols-2' : ''}`
                }
              >

                <InputSelect 
                  name = 'role'
                  label = { `${getTranslation( Texts.adminTableHeaderRole as Word )} *` }
                >
                  {roles.map(role => (
                    <MenuItem 
                      key = { role.name }
                      value = { role.name }
                    >
                      { getTranscriptionOfRole(role.name, lang) }
                    </MenuItem>
                  ))}
                </InputSelect>

                <div 
                  className = {`mt-8 md:mt-4 flex-col ${updating ? 'flex' : 'hidden'}` }
                >

                  <label
                    htmlFor="status"
                    className = "uppercase"
                  >
                    { getTranslation( Texts.adminTableHeaderStatus as Word ) } *
                  </label>

                  <div className="flex flex items-center">

                    <p>
                      { getTranslation( Texts.inactive as Word ) }
                    </p>

                    <Switch
                      checked = { props.values.status }
                      onChange = { 
                        (e) => props.handleChange({ target: { name: 'status', value: e.target.checked } })
                      }
                    />

                    <p>
                      { getTranslation( Texts.active as Word ) }
                    </p>
                  </div>

                </div>

              </div>


              <div 
                className = {`grid-cols-1 gap-4 md:grid-cols-2 mt-0 md:mt-4
                  ${ updating ? 'hidden' : 'grid'}`
                }
              >

                <InputChangePasswordForm 
                  placeholder= { getTranslation( Texts.adminPasswordPlaceHolder as Word ) }
                  id= 'password'
                  title= { getTranslation( Texts.loginPassword as Word ) }
                  background = "bg-card-body"
                  notLock
                  marginTop = "mt-8 md:mt-0 md:mr-4"
                  value={ props.values.password } 
                  handleChange={ props.handleChange } 
                  handleBlur={props.handleBlur}  
                  isError={(props.touched.password && !!props.errors.password )} 
                  errorText={ props.errors.password && 
                    getTranslation( Texts[props.errors.password] as Word) 
                  }  
                />

                <InputChangePasswordForm 
                  placeholder= {getTranslation( Texts.adminConfirmPasswordPlaceHolder as Word )}
                  id= 'password_confirmation'
                  title= { getTranslation( Texts.adminConfirmPassword as Word ) }
                  background = "bg-card-body"
                  marginTop = "mt-8 md:mt-0 md:mr-4"
                  notLock
                  value={ props.values.password_confirmation } 
                  handleChange={ props.handleChange } 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.password_confirmation && !!props.errors.password_confirmation )} 
                  errorText={ props.errors.password_confirmation && 
                    getTranslation( Texts[props.errors.password_confirmation] as Word) 
                  }                 
                />

              </div>

              <div className = "md:flex md:justify-around mt-10">

                <button
                  type = "button"
                  className = "uppercase bg-secondary-background px-8 py-2 rounded-3xl w-full md:w-max"
                  onClick = { handleReset }
                >
                  { getTranslation( Texts.cancel as Word ) }
                </button>

                { value === 'adding' || value === 'updating'
                  ? (<CircularProgress />) 

                  : (
                    <button
                      type = "submit"
                      className = "uppercase bg-primary px-8 py-2 rounded-3xl w-full mt-8 md:mt-0 md:w-max"
                    >
                      { updating
                        ? getTranslation( Texts.save as Word )
                        : getTranslation( Texts.adminSaveButtonModal as Word )
                      }
                      
                    </button>
                  ) 
                }

              </div>

            </form>
          )}


      </Formik>

    </Dialog>
  )
}
 
export default AdminModal;