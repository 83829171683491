import React, { FC, CSSProperties, ReactNode } from 'react';

interface RookTooltipContainerProps {
  className ?: string,
  sx ?: CSSProperties,
  style ?: CSSProperties,
  children ?: ReactNode
}
 
const RookTooltipContainer: FC<RookTooltipContainerProps> = ({ 
  className, sx, style, children 
}) => {
  return ( 
    <div
      className={`bg-white w-full h-full ${ className || '' }`}
      style={{ ...style, ...sx }}
    >
      { children }
    </div>
  );
}
 
export default RookTooltipContainer;