import React, { FC, useState, useEffect } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useActor } from '@xstate/react';
import Tooltip from '@mui/material/Tooltip'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Icons
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';

// Types and model
import Texts from '../../models/Texts'
import { Request, Word } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface RequestListProps {
  service: any
}
 
/**
 * Lista de solicitudes
 * @param props contiene todas las propiedades necesarias descritas en RequestListProps
 * @param props.service data la para la gráfica de puntos
 * @returns tabla de solicitudes
 */
const RequestList: FC<RequestListProps> = ({ service }) => {

  // Machine que controla la tabla
  const [current, send] = useActor(service)

  // Sacamos el context y el estado de la maquina
  const { context, value } : any = current

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // hook para navegar
  const history = useHistory()

  // Hook para la traducción
  const { getTranslation } = useTranslation()

  // Actualizar las variables
  useEffect(() => {
    
    if ( value === 'failure' || value === 'refused' || value === 'accepted')
      setShowAlert(true)
    else setShowAlert(false)

    if ( value === 'login' ) history.push("/login")

  }, [value])

  /**
   * Manejar aceptar el usuario
   * @param uuid del usuario a aceptar
   */
  const handleAccept = (uuid: string) : void => {
    send({ 
      type: 'ACCEPT', 
      data: [{ 'user_uuid': uuid }] 
    })
  }

  /**
   * Manejar rechazar el usuario
   * @param uuid del usuario a rechazar
   */
  const handleRefuse = (uuid: string) : void => {
    Swal.fire({
      title: '¿Deseas rechazar esta solicitud?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: 'rechazar',
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        send({ 
          type: 'REFUSE', 
          data: [{ 'user_uuid': uuid }] 
        })
      }
    })
  }
  
  /**
   * Obtener el mensaje a mostrar
   * @returns mensaje en la alerta
   */
  const getMessage = () : string => {
    switch (value) {
      case 'accepted': 

        if (context.users.length === 1)
          return getTranslation( Texts.requestsOneAccepted as Word )

        return getTranslation( Texts.requestsBunchAccepted as Word )
      
        case 'refused': 

          if (context.users.length === 1)
            return getTranslation( Texts.requestsOneRejected as Word )

          return getTranslation( Texts.requestsBunchRejected as Word )

      default: return getTranslation( Texts.generalError as Word )
    }
  }

  /**
   * Obtener el tipo de alerta
   * @returns mensaje en la alerta
   */
  const getSeverity = () : AlertColor => {
    switch (value) {
      case 'accepted':
      case 'refused': return 'success'
      default: return 'error'
    }
  }


  return  ( 
    <>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() } 
        </Alert>
      </Snackbar>

      <div className="overflow-x-scroll lg:overflow-x-hidden mt-10">
        <table className="table-auto shadow-md w-full capitalize">
          <thead className="bg-input-background">
            <tr>
              <th className="py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderName as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderLastName as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.usersPseudonymInput as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.loginEmail as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.usersSexInput as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.usersBirthdayInput as Word ) }
              </th>
              <th className="py-2 border border-table">
                { getTranslation( Texts.userTableActions as Word ) }
              </th>
            </tr>
          </thead>
    
          <tbody>

            { ( value !== 'loading' && context.requests ) && (

              <>
                {context.requests.data.map((request: Request) => (
                  <tr
                    key = { request.uuid }
                  >
                    <td className="p-2 border border-table">
                      {request.name}
                    </td>
                    <td className="p-2 border border-table">
                      { request.lastName || '-' }
                    </td>
                    <td className="p-2 border border-table">
                      { request.pseudonym }
                    </td>
                    <td className="p-2 border border-table normal-case">
                      { request.email }
                    </td>
                    <td className="p-2 border border-table">
                      { request.sex === 'male' ? 'M' : 'F'}
                    </td>
                    <td className="p-2 border border-table">
                      { request.birthday }
                    </td>
                    <td className="p-2 border border-table">
                      <div 
                        className="flex flex-col lg:flex-row justify-evenly items-center"
                      >

                        <div
                          className = {
                            `rounded-full text-xs 2xl:text-base my-4 lg:my-0 
                            ${ (value === 'accepting' || value === 'refusing') 
                              ? 'bg-green-800 text-gray-500' 
                              : 'bg-green-500 text-white' 
                            }`
                          }
                        >
                          <Tooltip
                            title = { getTranslation( Texts.requestsAcceptToolTip as Word ) }
                            placement = "top"
                          >
                            <IconButton
                              onClick = { () => handleAccept( request.uuid ) }
                              disabled = { value === 'accepting' || value === 'refusing'}
                            >
                              <CheckIcon 
                                fontSize = 'small'
                              />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div
                          className = {
                            `rounded-full text-xs 2xl:text-base my-4 lg:my-0 
                            ${ (value === 'accepting' || value === 'refusing') 
                              ? 'bg-red-800 text-gray-400' 
                              : 'bg-red-600 text-white' 
                            }`
                          }
                          
                        >
                          <Tooltip
                            title = { getTranslation(Texts.requestsRejectToolTip as Word) }
                            placement = "top"
                          >
                            <IconButton
                              disabled = { value === 'accepting' || value === 'refusing'}
                              onClick = { () => handleRefuse( request.uuid ) }
                            >
                              <BlockIcon 
                                fontSize = 'small'
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
          
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}

          </tbody>
        </table>
      </div>
    </>
  )
}
 
export default RequestList;