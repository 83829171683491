/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number,
  fill: string
}
 
const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = (props) => {

  const getPadding = () : string => {
    const progress = `${props.value}`

    if (progress.length === 3) return '1rem 0.5rem'
    if (progress.length === 1) return '0.8rem 1rem'

    return '1rem 0.8rem'

  }

  return ( 
    <Box 
      sx={{ 
        position: 'relative', 
        display: 'flex',
        alignItems: 'center' 
      }}
    >
      <CircularProgress 
        variant="determinate" 
        {...props} 
        sx={{
          color: props.fill
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <Box
          sx ={{
            backgroundColor: 'white',
            borderRadius: "50%",
            padding: '0.8rem',
          }}
        > */}
          <Typography
            variant="caption"
            component="div"
            color='black'
            fontSize={20}
            sx = {{
              backgroundColor: 'white',
              padding: getPadding(),
              borderRadius: '100%'
            }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        {/* </Box> */}
      </Box>
    </Box>
  )
}
 
export default CircularProgressWithLabel;