import React, { FC } from 'react';

// Styles
import Container from './styles/InfoStyles'

export interface InfoProps {
  title: String
  paddingX?: Number
}

/**
 * Container de la card de info 
 * @param props contiene todas las propiedades necesarias descritas en InfoProps
 * @param props.title lo la tarjeta
 * @param props.paddingX padding en x
 * @param props.children contenido de la tarjeta
 * @returns componente a manera container de las tarjetas en bienvenida
 */
const Info: FC<InfoProps> = ({ title, paddingX = 5, children }) => (
  <Container>

      <div className="border-b border-b-table">

          <h5 className = "py-3 px-4">
              {title}
          </h5>

      </div>

      <div className={`md:px-${paddingX} py-2`}>
          { children }
      </div>

  </Container>

);
export default Info;
