import React,{ FC, useEffect, useState, useContext } from 'react'

// Dependencies
import 'moment-timezone'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useMachine } from '@xstate/react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Alert, AlertColor, Snackbar, useMediaQuery, useTheme } from '@mui/material'

// Types
import { GoalInformationChallenge, OptionSearchBar, UserChallenge } from '../../../types'

// Context
import UIContext from '../../../context/ui/UIContext'
import GamingShowMachine from '../../../context/machines/gaming/GamingShowMachine'

// Components
import Spinner from '../../utils/Spinner'
import Layout from '../../layout/Layout';
import RoomChallenge from './RoomChallenge'
import GroupChallenge from './GroupChallenge'
import SearchBar from '../../utils/SearchBar'
import IndividualChallenge from './IndividualChallenge'
import RButtonBack from '../../utils/RookButtons/RButtonBack';

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import ChallengeInformation from './ChallengeInformation'

type ChallengeDescriptionParams = {
  id: string
}

interface ChallengeDescriptionProps {
  url: string
}

type ChallengeDescriptionLocation = {
  participants: number
}
 
const internalOptions: OptionSearchBar[] = [
  {
    key: "name",
    title: "name",
  },
  {
    key: "email",
    title: "loginEmail",
  },
]

const internalOptions2: OptionSearchBar[] = [
  {
    key: "name",
    title: "name",
  },
]

/**
 * Este es el componente donde se muestra el detalle del reto, es decir esta componente
 * se renderiza cuando se da clic en el botón del ojito
 * @returns Component
 */
const ChallengeDescription: FC<ChallengeDescriptionProps> = ({ url }) => {
  
  // Machine que contala esta vista
  const [machine, send] = useMachine(GamingShowMachine)

  const [columns, setColumns] = useState(0)

  // Mostrar la alerta
  const [showAlert, setShowAlert] = useState(false)

  // sacar el uuid del challenge
  const { id } = useParams<ChallengeDescriptionParams>()

  // Obtener el offset
  const { centerData } = useContext( UIContext )

  // Regresar a la vista anterior
  const history = useHistory()

  const location = useLocation()

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Hooks para saber en que media query estamos
  const theme = useTheme()
  const desktop = useMediaQuery( theme.breakpoints.up('lg') )
  const tablet = useMediaQuery( theme.breakpoints.between('md', 'lg') )

  useEffect(() => {
    
    if (desktop) setColumns(3)
    else if (tablet) setColumns(2)
      else setColumns(1)

  }, [desktop, tablet])

  useEffect(() => {
    
    if (machine.matches('login')) history.push('/login')

  }, [machine.value])

  useEffect(() => {
    
    send({ type: 'INIT', uuidChallenge: id })

  }, [id])

  useEffect(() => {
    
    if (machine.matches('removed') 
      || machine.matches('failure') 
    )
      setShowAlert(true)
    else setShowAlert(false)

  }, [machine.value])

  /**
   * Mostrar el detalle de un usuario
   * @param user a mostrar
   * @param earned objetivos completados
   * @param percentage porcentaje de avance
   * @param total de objetivos
   */
  const showUserDetail = (
    user: UserChallenge, earned: number, 
    percentage: number, total: number, position: number, points: number
  ) : void => {
    
    send({
      type: 'REVIEW',
      data: {
        user,
        earned,
        percentage,
        total,
        position,
        points
      }
    })

  }

  /**
   * Eliminar un equipo del reto
   * @param uuid del usuario a sacar del reto
   * @param teamRoom uuid del room o team
   */
  const onRemoveUser = (uuid: string, teamRoom: string) : void => {
    // Alerta para confirmar la eliminación
    Swal.fire({
      title: getTranslationByKey('gamingChallengeDeleteUserConfirmation'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslationByKey('delete'),
      cancelButtonText: getTranslationByKey('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        // Enviamos la petición al actor
        send({
          type: 'REMOVE',
          data: {
            user: uuid,
            teamRoom
          }
        })
      }
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'removed': return 'success'
      case 'failure': return 'error'
      default: return 'error'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (machine.value) {
      case 'removed': return getTranslationByKey('gamingChallengeDeleteUserSuccess')
      default: return getTranslationByKey('generalError')
    }
  }
  
  /**
   * If the user is viewing the members view, send a message to the parent component to go back 
   * to the main view. Otherwise, go back to the previous page in the browser history
   * @param {boolean} isMembersView - boolean - this is a boolean that tells us if we're in the members
   * view or not.
   */
  const handleBack = (isMembersView: boolean) : void => {
    
    if (isMembersView) send({ type: 'BACK' })
    else history.push(url)

  }

  /**
   * It takes the data from the machine context and formats it to be used in the next screen
   */
  const handleGoToTargets = () : void => {

    const competitive = getTranslationByKey('competitive')

    const start = moment
      .utc(machine.context.data?.start_at || '')
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const end = moment
      .utc(machine.context.data?.finish_at || '')
      .tz(centerData?.offset_name || 'America/Mexico_City')
      .format( getTranslationByKey('momentDateFormat') )

    const state : GoalInformationChallenge = {
      compliance: machine.context.data?.challenge_compliance || competitive,
      description: machine.context.data?.description || '',
      end,
      isFree: machine.context.data?.challenge_compliance === 'free',
      modality: machine.context.data?.challenge_modality || '-',
      name: machine.context.data?.name || '-',
      participants: (location.state as ChallengeDescriptionLocation).participants || -1,
      start,
      status: machine.context.data?.status || 'active',
      type: machine.context.data?.challenge_type || '-',
    }

    history.push(`${url}/${id}/goals`, state) 
  }

  return ( 
    <Layout>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 2000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <Spinner
        show = { 
          machine.matches('preparing') 
          || machine.matches('loading') || machine.matches('searching') 
        }
      >
        { machine.context.data && (

          <>

            <div className="flex flex-col md:flex-row justify-between items-center mb-4">

              <div className='flex items-center mb-4 md:mb-0'>
                <RButtonBack
                  action={() => handleBack(machine.context.isMembersView)}
                />
                <button
                  type = "button"
                  className = "uppercase bg-primary px-8 py-2 rounded-3xl w-full lg:w-max ml-4"
                  onClick = { handleGoToTargets }
                >
                  { getTranslationByKey('gamingChallengeCheckGoals') }
                </button>
              </div>

              { machine.context.data && (
                (machine.context.data.challenge_users || machine.context.data.challenge_rooms) 
                  && (
                  <SearchBar
                    options={ machine.context.data.challenge_users
                      ? internalOptions 
                      : internalOptions2
                    }
                    action={ search => send({ type: 'SEARCH', data: search })}
                  />

                )
              )}


            </div>

            <ChallengeInformation
              name = {`${machine.context.data.name}
                ${ machine.context.isMembersView 
                  ? `- ${machine.context.teamRoom?.name}` 
                  : '' }
              `}
              description = { machine.context.data.description || '' }
              type = { machine.context.data.challenge_type }
              modality = { machine.context.data.challenge_modality }
              start = { moment
                .utc(machine.context.data.start_at)         
                .tz(centerData?.offset_name || 'America/Mexico_City') 
                .format( getTranslationByKey('momentDateFormat') )
              }
              end = { moment
                .utc(machine.context.data.finish_at) 
                .tz(centerData?.offset_name || 'America/Mexico_City') 
                .format( getTranslationByKey('momentDateFormat') )
              }
              users = { (location.state as ChallengeDescriptionLocation).participants || -1 }
              compliance = { 
                machine.context.data.challenge_compliance || getTranslationByKey('compliance') 
              }
            />

            { machine.context.data.challenge_users && (
              
              <IndividualChallenge 
                columns = { columns }
                data={ machine.context.data }
                filteredData = { machine.context.pageUserData }
                openDetail={ machine.matches('reviewing') } 
                userDetail={ machine.context.userDetail } 
                goalsDetail={ machine.context.userMetrics }
                loading = { machine.matches('downloading') }
                hasMore = { machine.context.currentPage < machine.context.lastPage }
                removing = {{ 
                  flag: machine.matches('removing'), 
                  user: machine.context.removeUser ? machine.context.removeUser.user : ''
                }}
                next = { () => send({ type: 'UPDATE' }) }
                onClose={ () => send({ type: 'HIDE' }) } 
                showUserDetail={ showUserDetail }  
                removeUser={ user => onRemoveUser(user, '') }  
              />
              
            )}

            { machine.context.data.challenge_teams && (
              
              <GroupChallenge
                data={machine.context.data}
                openDetail={machine.matches('loaded')}
                goalsDetail={machine.context.teamMetrics?.data || []}
                onClose={() => send({ type: 'HIDE' })}
                showDetail={uuid => send({ type: 'CHECK', data: uuid })} 
                handleMembers={ data => send({ type: 'CONVERT', data })}                
                isLoadingMetrics={{ 
                  flag: machine.matches('checking'), 
                  who: machine.context.teamUUID
                }}
                isLoadingMembers={{ 
                  flag: machine.matches('converting'), 
                  who: machine.context.teamRoom?.uuid || ''
                }}
              />
              
            )}

            { machine.context.data.challenge_rooms && (

              <RoomChallenge
                openDetail={machine.matches('loaded')}
                data={machine.context.data}
                filteredData={machine.context.pageRoomData} 
                goalsDetail={machine.context.teamMetrics?.data || []}
                onClose={() => send({ type: 'HIDE' }) }
                showDetail={ uuid => send({ type: 'PROGRESS', data: uuid }) } 
                handleMembers={ data => send({ type: 'CONVERT', data })} 
                isLoadingMetrics={{ 
                  flag: machine.matches('seeking'), 
                  who: machine.context.roomUUID
                }}
                isLoadingMembers={{ 
                  flag: machine.matches('converting'), 
                  who: machine.context.teamRoom?.uuid || ''
                }}
              />

            )}

          </>
        )}
      </Spinner>
    </Layout>
  );
}
 
export default ChallengeDescription;