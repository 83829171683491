import React, { FC } from 'react'

export interface InputErrorProps {
  colorLabel ?: string
}
 
/**
 * Label del error para el input
 * @param props contiene todas las propiedades necesarias descritas en InputErrorProps
 * @param props.colorLabel color del label
 * @returns label del error
 */
const InputError: FC<InputErrorProps> = ({ colorLabel, children }) => ( 
  <p className = {`text-center mt-2 ${ colorLabel || 'text-red-600'  }`}>
    {children}
  </p>
)
 
export default InputError;