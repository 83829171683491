import Colors from '../utils/colors';
import { Range } from '../types';

/**
 * Arreglo con las zonas de intensidad que se manejan
 * label: lo que se va a mostrar en pantalla
 */
const Ranges: Range[] = [
  {
    limitTop: 100,
    limitBottom: 90,
    color: Colors.eMax,
    label: {
      en: 'Maximum',
      es: 'Máximo',
      label: 'zone1',
    },
  },
  {
    limitTop: 90,
    limitBottom: 80,
    color: Colors.eIte,
    label: {
      es: 'Intenso',
      en: 'Intense',
      label: 'zone2',
    },
  },
  {
    limitTop: 80,
    limitBottom: 70,
    color: Colors.eMod,
    label: {
      es: 'Moderado',
      en: 'Moderate',
      label: 'zone3',
    },
  },
  {
    limitTop: 70,
    limitBottom: 60,
    color: Colors.eLgt,
    label: {
      es: 'Ligero',
      en: 'Light',
      label: 'zone4',
    },
  },
  {
    limitTop: 60,
    limitBottom: 50,
    color: Colors.eMin,
    label: {
      es: 'Muy ligero',
      en: 'Very Light',
      label: 'zone5',
    },
  },
];

export default Ranges;
