import * as Yup from 'yup'

// Esquema de validación para la información del centro
export default Yup.object().shape({
  'measurement_system': Yup.string()
                      .required('El sistema de medidas es requerido'),
  'branch_name': Yup.string()
                    .required('El nombre de la sucursal es obligatorio'),
  'lang_types': Yup.string()
                    .required('El idioma es obligatorio'),
  'description': Yup.string()
                    .notRequired()
                    .when('description', {
                      is: (value: any) => value,
                      then: (rule) => rule.trim('La descripción es obligatoria')
                                          .min(5, 'La descripción debe tener al menos 5 caracteres')
                                          .max(400, 'La descripción debe tener máximo 100 caracteres')
                    })
}, [['description', 'description']])