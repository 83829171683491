import React, { FC, CSSProperties } from 'react'

import { CircularProgress, CircularProgressProps } from '@mui/material';

interface RButtonLoadingProps extends CircularProgressProps {
  sx ?: CSSProperties
  loading: boolean
}
 
const RButtonLoading: FC<RButtonLoadingProps> = ({ loading, color, sx, children }) => ( 
    <>
      { loading 
        ?  ( <CircularProgress  color = { color || 'primary' } sx = { sx } /> )
        :  children 
      }
    </>
  )
 
export default RButtonLoading;