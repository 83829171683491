import React, { FC, useState, useEffect, useContext } from 'react'

// Dependencies
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import { SelectChangeEvent } from '@mui/material/Select';
import { CircularProgress } from '@mui/material';

// Validation
import { Formik } from 'formik'

// Types and models
import { TrainingType, Word, Configuration } from '../../types'
import Texts from '../../models/Texts'

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import InputForm from '../utils/RookFormInputs/InputForm'
import InputError from '../utils/RookFormInputs/InputError'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validations
import validationSchema from '../../validations/ValidationsRemoteClass'

// utils
import { capitalize } from '../../utils'
import { InputSelect } from '../utils/RookFormInputs';

interface ConfigModalProps {
  open: boolean,
  loading: boolean,
  trainingType: TrainingType[]
  config ?: Configuration | null,
  hasStreamingCredentials: boolean,
  streamingIsAlreadyActive: boolean,
  handleClose: () => void,
  setConfiguration: (config: Configuration) => void,
}

/**
 * Modal para configurar la clase
 * @param props contiene todas las propiedades necesarias descritas en ConfigModalProps
 * @param props.open saber si esta abierto el modal
 * @param props.trainingType lista de tipos de entrenamientos
 * @param props.loading saber si esta haciendo la petición al servidor
 * @param props.config configuración si es que antes se haya configurado dicha clase
 * @param props.setConfiguration función para crear la clase en el servidor
 * @param props.handleClose función para cerrar el modal
 * @returns formulario para dar ban al usuario
 */
const ConfigModal: FC<ConfigModalProps> = ({ 
  open, trainingType, loading, config, streamingIsAlreadyActive, hasStreamingCredentials,
  setConfiguration, handleClose 
}) => {

  // Hook para traducir
  const { getTranslation, getLabel } = useTranslation()

  // Saber cual es la tercera opción
  const [extraOption, setExtraOption] = useState<string | null>(null)

  // Context
  const { features } = useContext(UIContext)

  useEffect(() => {
    
    if (config && !extraOption)
      setExtraOption(getExtraSortOption(config.training_type_uuid))

  }, [config])

  useEffect(() => {
    
    if (extraOption && extraOption !== '123' && !extraOption.includes('wConfigureOrder'))
      setExtraOption(getExtraSortOption(extraOption))

  }, [extraOption])

  /**
   * Actualizar el entrenamiento
   * @param props de formik para actualizar el valor
   * @param e el nuevo entranimiento seleccionado
   */
  const handleChangeType = (props: any, e: SelectChangeEvent<any>) : void => {
    
    // Mandamos el nuevo deporte
    setExtraOption( e.target.value )

    // Cambiamos el valor del select del training
    props.handleChange(e)

    // Cambiamos a calorías cuando se cambia de deporte
    props.handleChange({
      target: {
        name: 'data_order',
        value: 'calories'
      }
    })

  }

  /**
   * Buscar la tercera opción de ordenamiento
   * @param uuid del entranamiento
   * @returns un string que es la key de los textos
   */
  const getExtraSortOption = (uuid: string) : string | null => {
    
    const type : TrainingType | undefined = trainingType.find(t => t.training_type_uuid === uuid)

    if (!type || !type.use_steps) return null

    switch (type.use_steps.steps_types) {

      case 'jumps': 
      case 'trampoline': 
      case 'boots': 
      case 'rope': 
        return 'wConfigureOrderJumps'

      case 'steps': 
      case 'run':
        return 'wConfigureOrderSteps'
        
      default: return 'wConfigureOrderSteps'
    }

  }

  /**
   * Preparar los datos antes de enviar
   * @param values provenientes del formik
   */
  const prepareSubmit = (values: any) : void => {
    
    const bridge : Configuration = {
      ...values,
      'user_capacity_status': Number(values.user_capacity_status)
    }

    if (values.user_capacity_status) {

      const capacity = Number(values.capacity)

      if (capacity > 0) bridge.capacity = capacity
      else {
        bridge.user_capacity_status = 0
        bridge.capacity = 1
      }

    }
    else bridge.capacity = 100

    setConfiguration(bridge)

  }

  return ( 
    <Dialog
      open = { open }
      onClose = { () => handleClose() } 
    >
      <DialogTitle>
        { getTranslation(Texts.wConfigurationTitle as Word) }
      </DialogTitle>

      <Formik
        initialValues = {{
          'training_type_uuid': config ? config.training_type_uuid : '123',
          duration: config ? config.duration : 60,
          'class_delay': config ? config.class_delay : 0,
          'data_order': config ? config.data_order :'calories',
          capacity: config ? config.capacity : 15,
          'user_capacity_status': config ? Boolean(config.user_capacity_status) : false,
          'streaming': (config && features.streaming && !streamingIsAlreadyActive && hasStreamingCredentials) 
            ? Boolean(config.streaming) 
            : false
        }}
        validationSchema = { validationSchema }
        enableReinitialize
        onSubmit = { values => prepareSubmit(values) }
      >

        { props => (

          <form 
            className = 'p-5 w-100 max-w-sxs md:max-w-none overflow-x-hidden'
            onSubmit = { props.handleSubmit }
          >
            
            <InputSelect
              name = 'training_type_uuid'
              divClasses  = 'max-w-sxs md:max-w-none'
              label = { getTranslation( Texts.wConfigureTrainingType as Word ) }
              onChange = { (e) => handleChangeType(props, e) }
            >
              <MenuItem 
                value = '123'
              >
                { getTranslation( Texts.wConfigureTrainingType as Word ) }
              </MenuItem>

              {trainingType.map((training) => (
                <MenuItem 
                  key = { training.training_type_uuid }
                  value = { training.training_type_uuid }
                >
                  { capitalize(training.trainig_name) }
                </MenuItem>
              ))}
            </InputSelect>

            <div
              className = "mt-8 max-w-sxs md:max-w-none"
            >

              <p>
                { getTranslation( Texts.wConfigureTrainingDuration as Word ) }
              </p>

              <div className = 'flex items-center'>

                <div
                  style = {{ flexBasis: '40%' }}
                >
                  <InputForm
                    type='number'
                    placeholder='60'
                    id='duration'
                    inputMode = 'numeric'
                    title=''
                    background = "bg-card-body"
                    marginTop = 'mt-0'
                    value={ props.values.duration }
                    handleChange={ props.handleChange }
                    handleBlur={ props.handleBlur }
                  />
                </div>

                <p 
                  style = {{ flexBasis: '60%' }}
                  className = 'ml-4'
                >
                  min
                </p>

              </div>

              { (props.touched.duration && props.errors.duration )
                  && (
                    <InputError
                      colorLabel = "text-red-500"
                    >
                      { getTranslation(Texts[props.errors.duration] as Word) }
                    </InputError>
                  ) 
              }
            </div>

            <div
              className = "mt-8 max-w-sxs md:max-w-none"
            >

              <p>
                { getTranslation( Texts.wConfigureTrainingCapacity as Word ) }
              </p>

              <div 
                className = "flex items-center"
              >

                <p>
                  { getTranslation( Texts.wConfigureTrainingUnlimited as Word ) }
                </p>

                <Switch 
                  name = 'user_capacity_status'
                  checked = { props.values.user_capacity_status }
                  onChange = { props.handleChange }
                />

                <p>
                { getTranslation( Texts.wConfigureTrainingLimited as Word ) }
                </p>

                <div
                  className = {`ml-4 w-1/4 transition-all 
                    ${props.values.user_capacity_status ? 'opacity-100' : 'opacity-0'}
                  `}
                >
                  <InputForm
                    type='number'
                    placeholder='15'
                    id='capacity'
                    inputMode = 'numeric'
                    title=''
                    background = "bg-card-body"
                    marginTop = 'mt-0'
                    min = { 1 }
                    max = { 100 }
                    value={ props.values.capacity }
                    handleChange={ props.handleChange }
                    handleBlur={ props.handleBlur }
                  />
                </div>

              </div>

              { (props.touched.capacity && props.errors.capacity )
                    && (
                      <InputError
                        colorLabel = "text-red-500"
                      >
                        { getTranslation(Texts[props.errors.capacity] as Word) }
                      </InputError>
                    ) 
                }

            </div>

              <div
                className = "mt-4 max-w-sxs md:max-w-none"
              >

                <p>
                  { getTranslation( Texts.wConfigureTrainingDelay as Word ) }
                </p>

                <div className = 'flex items-center'>

                  <div
                    style = {{ flexBasis: '40%' }}
                  >
                    <InputForm
                      type='number'
                      placeholder='10'
                      id='class_delay'
                      inputMode = 'numeric'
                      title=''
                      background = "bg-card-body"
                      marginTop = 'mt-0'
                      value={ props.values.class_delay }
                      handleChange={ props.handleChange }
                      handleBlur={ props.handleBlur }
                    />
                  </div>

                  <p 
                    style = {{ flexBasis: '60%' }}
                    className = 'ml-4'
                  >
                    min
                  </p>

                </div>

                { (props.touched.class_delay && props.errors.class_delay )
                    && (
                      <InputError
                        colorLabel = "text-red-500"
                      >
                        { getTranslation(Texts[props.errors.class_delay] as Word) }
                      </InputError>
                    ) 
                }
              </div>

              <InputSelect
                name = 'data_order'
                divClasses='max-w-sxs md:max-w-none'
                label = { getTranslation( Texts.wConfigureTrainingSorted as Word ) }
              >
                <MenuItem 
                  value = { getLabel(Texts.wConfigureOrderCalories as Word) }
                >
                  { getTranslation( Texts.wConfigureOrderCalories as Word ) }
                </MenuItem>

                <MenuItem 
                  value = { getLabel(Texts.wConfigureOrderEffort as Word) }
                >
                  { getTranslation( Texts.wConfigureOrderEffort as Word ) }
                </MenuItem>

                { (extraOption && extraOption.includes('wConfigureOrder')) && (
                  <MenuItem 
                    value = { getLabel(Texts[extraOption] as Word) }
                  >
                    { getTranslation( Texts[extraOption] as Word ) }
                  </MenuItem>
                )}
              </InputSelect>

              <div 
                className={`my-8 
                  ${features.streaming ? 'block' : 'hidden'}
                  ${(streamingIsAlreadyActive || !hasStreamingCredentials) ? 'text-gray-400' : 'text-white'}
                `}
              >

                <p>
                  { getTranslation( Texts.wConfigureStreaming as Word ) }
                </p>

                <div 
                  className = "flex items-center"
                >
                  <p>
                    { getTranslation( Texts.inactive as Word ) }
                  </p>

                  <Switch
                    disabled = { streamingIsAlreadyActive }
                    name = 'streaming'
                    checked = { props.values.streaming }
                    onChange = { props.handleChange }
                  />

                  <p>
                    { getTranslation( Texts.active as Word ) }
                  </p>
                </div>

                <p
                  className={`text-primary ${streamingIsAlreadyActive ? 'block' : 'hidden'}`}
                >
                  { getTranslation( Texts.streamingIsAlreadyActive as Word ) }
                </p>

                <p
                  className={`text-primary ${!hasStreamingCredentials ? 'block' : 'hidden'}`}
                >
                  { getTranslation( Texts.streamingWithoutCredentials as Word ) }
                </p>

              </div>

            <div className="flex flex-col md:flex-row justify-evenly mt-4 md:mt-8 max-w-sxs md:max-w-none">

              <button
                type = "button"
                className = "uppercase bg-title px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-1/2 w-full md:w-1/2 lg:w-max md:mr-4 lg:mt-0"
                onClick = { () => handleClose() }
              >
                { getTranslation( Texts.cancel as Word ) }
              </button>

              { loading 
                ? (<CircularProgress />) 
                : (
                  <button
                    type = "submit"
                    className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-1/2 w-full md:w-1/2 lg:w-max"
                  >
                    { getTranslation( Texts.start as Word ) }
                  </button>
                ) 
              }

            </div>

          </form>

        )}

      </Formik>

    </Dialog>
  )
}
 
export default ConfigModal;