import React, { FC, useState, useEffect } from 'react'

// Dependencies
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

// Icons
import CloseIcon from '@mui/icons-material/Close';

interface RadiosRowProps {
  firstText: string,
  isEditable: boolean,  
  previousTeam ?: string | null,
  secondText: string,
  teams: number,
  changeTeam: (team: string) => void,
  deleteRow: () => void,
}

/**
 * Componente que muestra una fila para seleccionar equipo en el configurador
 * @param props contiene todas las propiedades necesarias descritas en
 * RadiosRowProps
 * @param props.teams número de equipos
 * @param props.firstText primer texto
 * @param props.secondText primer texto
 * @param props.previousTeam saber si es edición
 * @param props.deleteRow función para eliminar la fila
 * @param props.changeTeam función para cambiar de equipo
 * @returns Componente que muestra una fila para seleccionar equipo en el configurador
 */
const RadiosRow: FC<RadiosRowProps> = ({ 
  firstText, secondText, teams, previousTeam = null, isEditable,
  deleteRow, changeTeam 
}) => {

  const [groups, setGroups] = useState([1])
  const [value, setValue] = useState<string | null>(previousTeam)

  useEffect(() => {
    
    setGroups(Array(teams).fill(0).map((_, i) => 1 + i))

  }, [teams])

  useEffect(() => {
    
    if (previousTeam) setValue(previousTeam)

  }, [previousTeam])

  return ( 
    <>
      <p>
        {
          firstText.length > 13
          ? `${firstText.substring(0, 10)}...`
          : firstText
        }
      </p>
      <p>
          {
            secondText.length > 13
            ? `${secondText.substring(0, 10)}...`
            : secondText
          }
        </p>
      <div className='flex justify-between items-center'>

        <FormControl component="fieldset">
          <RadioGroup 
            row 
            aria-label="gender" 
            name="row-radio-buttons-group"
            value = { value }
            onChange={ e => {
              changeTeam(e.target.value)
              setValue(e.target.value)
            }}
          >
            {groups.map(group => (
              <FormControlLabel 
                key = { group }
                value={`${group}`} 
                control={<Radio />} 
                label={`${group}`} 
                disabled = { !isEditable }
              />
            ))}
          </RadioGroup>
        </FormControl>

        { isEditable && (
          <IconButton
            onClick = { deleteRow }
          >
            <CloseIcon 
              fontSize='small'
            />
          </IconButton>
        )}


      </div>

    </>
  );
}
 
export default RadiosRow;