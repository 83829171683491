import { createMachine } from "xstate";

// Dependecies
import axiosClient from '../../config/axios'
import { LocalStorageKey } from "../../types";

type BarMachineEvent =  {
  type: 'LOGOUT'
}

type BarMachineContext = {
}

const onLogout = async () : Promise<any> => {
  await axiosClient.post('/api/v2/auth/admin/logout')
}

const BarMachine = createMachine<BarMachineContext, BarMachineEvent>(
  {
    id: 'Bar',
    initial: 'idle',
    states: {
      idle: {},
      error: {},
      loading: {
        invoke:{
          id: 'logout',
          src: 'handleLogout',
          onDone: {
            target: 'success',
            actions: ['deleteSession']
          },
          onError: {
            target: 'error'
          }
        },
      },
      success: {
        type: 'final'
      }
    },
    on: {
      LOGOUT: {
        target: '.loading'
      }
    }
  },
  {
    actions: {
      deleteSession: () => {
        localStorage.removeItem(LocalStorageKey.auth)
      }
    },
    services: {
      handleLogout: () => onLogout()
    }
  }
)

export default BarMachine