import { createMachine, assign, DoneInvokeEvent } from "xstate";
import * as Sentry from '@sentry/react'

// Dependecies
import axiosClient from '../../config/axios'
import { 
  AuthData, 
  CurrentUser, 
  LocalStorageKey, 
  UpdtedImage, 
  UpdatePassword 
} from "../../types";

/**
 * currentUser: usuario actual
 * newUser: data con la que se actualizará al usuario
 * statusError: error que da la API
 * image: imagen de perfil
 */
type ProfileMachineContext = {
  currentUser: CurrentUser | null
  newUser: CurrentUser | null,
  password: UpdatePassword | null,
  statusError: number,
  image: File | null
}

type ProfileMachineEvents = 
  | { type: 'UPDATE', data: CurrentUser }
  | { type: 'IMAGE', data: File }
  | { type: 'WHISPER', data: UpdatePassword }

/**
 * Información del usuario actual
 * @returns Información del usuario actual
 */
const fetchCurrentUser = async () : Promise<CurrentUser> => {
  
  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  // Hacemos la consulta
  const response = await axiosClient.get(`/api/v2/admin/user/${authData.uuidUser}`)

  return response.data

}

/**
 * Actualizar al usuario
 * @param context Context de la maquina
 * @returns retorna una respuesta de la api
 */
const updateUser = async (context: ProfileMachineContext) : Promise<CurrentUser> => {

  // Sacamos el usuario
  const { newUser } = context

  // Si no existe el usuario mandamos un error
  if (!newUser) throw new Error()
  
  if (newUser.landline === '') delete newUser.landline

  if (newUser.last_name_2 === '') delete newUser.last_name_2
  
  await axiosClient.put(`/api/v2/admin/user/${newUser.uuid}`, newUser)

  return newUser

}

/**
 * Actualizar la imagen del perfil
 * @param context Context de la maquina
 * @returns la url de la imagen actualizada
 */
const updateImage = async (context: ProfileMachineContext) : Promise<UpdtedImage> => {
  
  // Sacamos la imagen
  const { image, currentUser } = context

  // Verificamos que exista un usuario y una imagen
  if ( !image || !currentUser ) throw new Error()

  // Creamos un FormData
  const data = new FormData()
  data.append('image_admin', image)

  // Hacemos la petición
  const response = await axiosClient.post(`/api/v2/admin/user/${currentUser.uuid}/image`, data)

  return response.data
}

/**
 * Actualizar la contraseña
 * @param context context de la maquina
 * @returns una respuesta de la api
 */
const updateUserPassword = async (context: ProfileMachineContext) : Promise<any> => {
  
  // Sacamos la contraseña 
  const { currentUser, password } = context

  // Verificamos que exista un usuario y una contraseña
  if ( !password || !currentUser ) throw new Error()

  // Hacemos la petición
  const url : string = `/api/v2/admin/user/${currentUser.uuid}/password`
  const response = await axiosClient.put(url, password)

  return response.data

}

const ProfileMachine = createMachine<ProfileMachineContext, ProfileMachineEvents>(
  {
    id: 'ProfileMachine',
    initial: 'loading',
    context: {
      currentUser: null,
      newUser: null,
      image: null,
      password: null,
      statusError: 0
    },
    states: {
      loaded: {},
      updated: {},
      whispered: {},
      login: {},
      loading: {
        invoke: {
          id: 'fetchCurrentUserData',
          src: fetchCurrentUser,
          onDone: {
            target: 'loaded',
            actions: assign({ currentUser: (_, event) => event.data })
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se descargar la data del perfil'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateUser',
          src: updateUser,
          onDone: {
            target: 'updated',
            actions: [ 'updatedUser' ]
          },
          onError: {
            target: 'aborted',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo actualizar al usuario.'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      changing: {
        invoke: {
          id: 'changingImage',
          src: updateImage,
          onDone: {
            target: 'updated',
            actions: [ 'updatedImage' ]
          },
          onError: {
            target: 'imagefailed',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo actualizar la imagen del perfil.'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      whispering: {
        invoke: {
          id: 'updatingPassword',
          src: updateUserPassword,
          onDone: {
            target: 'whispered'
          },
          onError: {
            target: 'passwordfailed',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo actualizar la contraseña'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      imagefailed: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      passwordfailed: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      aborted: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      UPDATE: {
        target: '.updating',
        actions: assign({ newUser: (_, event) => event.data })
      },
      IMAGE: {
        target: '.changing',
        actions: assign({ image: (_, event) => event.data })
      },
      WHISPER: {
        target: '.whispering',
        actions: assign({ password: (_, event) => event.data })
      }
    }
  },
  {
    actions: {
      updatedUser: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<CurrentUser> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.updateUser") return context

        return {
          ...context,
          newUser: null,
          currentUser: {
            ...context.currentUser!,
            ...event.data,
          }
        }
      }),
      updatedImage: assign((context, _event: any) => {

        // Cargamos el evento
        const event: DoneInvokeEvent<UpdtedImage> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.changingImage") return context

        return {
          ...context,
          currentUser: {
            ...context.currentUser!,
            image_url: event.data.url
          },
          image: null
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

export default ProfileMachine