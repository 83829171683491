import React, { FC, HTMLAttributes } from 'react'

interface GroupButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className ?: string
}
 
const GroupButton: FC<GroupButtonProps> = ({ children, className, onClick }) => {
  return (
    <button
      type = 'button'
      className={`flex flex-col items-center justify-center py-1 ${className || ''}`}
      onClick={ onClick }
    >
      { children }
    </button>
  );
}
 
export default GroupButton;