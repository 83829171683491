import styled from 'styled-components'

// Colors
import Colors from '../../../utils/colors'

type Props = {
  color ?: string,
  width ?: number | null,
  height ?: string | null,
  right ?: number,
  base ?: boolean,
}

export const Container = styled.div<Props>`
  width: 80%;
  border-radius: 1rem;
  background-color: ${(props) => props.color || Colors.waiting};
  position: relative;
  transition: all 0.1s ease-in-out;

  @media( min-width: 768px) {
    width: 100%;
    height: 8.5rem;
  }

  @media( min-width: 992px) {
    width: ${props => props.width ? `${props.width}%` : '100%'};
    height: ${props => props.height ? `${props.height}`: '100%'};
    margin-right: ${props => props.right ? `${props.right}rem` : 0}
  }

  & #btn-delete-user {
    display: block;
  }

  &:hover #btn-delete-user {
    display: block;
  }

  @media(min-width: 768px){
    & #btn-delete-user {
      display: none;
    }
  }

  .disabled {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    width: 100%;
  }
`;

export const BanUser = styled.button`
  position: absolute;
  top: -1%;
  left: 0;
  border: none;
  background-color: transparent;
  color: ${Colors.deleteUserButton};
  z-index: 10;
  display: none;
`;

type Config = {
  spacing ?: string
};

export const Statistics = styled.div<Config>`
  border-radius: 1rem;
  background-color: rgba(15, 15, 15, 0.4);
  display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  align-items: center;
  padding: 0.2rem 0;

  @media (min-width: 768px) {
    height: 100%;
  }

  div {
    text-align: center;
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    div {
      border-left: 1px solid white;
    }

    div:first-child {
      border-left: none;
      padding-left: 0;
    }
  }

`;

export const Disconnection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(33,33,33,0.8);
  justify-content: center;
  align-items: center;

  p {
    margin: 0 0 0 0.6rem;
    font-weight: bold;
    text-transform: uppercase;
  }

`;

type ImageContainerProps = {
  image?: string | null,
  size?: string | null
}

export const ImageContainer = styled.div<ImageContainerProps>`
  width: 3.5rem;
  height: 3.5rem;
  background-image: ${props => props.image ? `url(${props.image})` : "url('https://avatars.dicebear.com/api/pixel-art/cente.svg')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
  
  @media (min-width: 768px) {
    margin: 0 0.8rem 0 auto;
    width: 4rem;
    height: 4rem;
  }
    
  @media (min-width: 992px) {
    margin: 0;
    width: ${props => props.size ? props.size : '5rem'};
    height: ${props => props.size ? props.size : '5rem'};
  }
`