import React, { FC, useState } from 'react';

// Dependencies
import Dialog from '@mui/material/Dialog';
import Checkbox  from '@mui/material/Checkbox';
import { CircularProgress } from '@mui/material';

// Traducción
import useTranslation from '../../hooks/useTranslation'

interface ContractProps {
  open: boolean,
  loading: boolean,
  acceptAgreement: () => void,
}
 
/**
 * Contrato, Acuerdo de privacidad, términos y condiciones
 * @param param0 props contiene todas las propiedades necesarias descritas en ContractProps
 * @param {boolean} props.open si esta abierto o no el modal
 * @param {boolean} props.loading saber si esta cargando la petición de aceptar
 * @param {Function} props.acceptAgreement función para hacer la petición de aceptar el contrato
 * @returns modal con los acuerdos
 */
const Contract: FC<ContractProps> = ({ open, loading, acceptAgreement }) => {

  // Check
  const [checked, setChecked] = useState(false)

  // hook para traducir el modal
  const { getTranslationByKey } = useTranslation()

  /**
   * Abrir en otra pestaña el contrato
   */
  const handleNavigateContract = () : void => {
    
    const url : string = 'https://www.rookmotion.com/contract'

    window.open(
      `${url}`, 
      '_blank'
    )

  }

  /**
   * Abrir en otra pestaña el aviso de privacidad
   */
  const handleNavigatePrivacy = () : void => {
    
    const url : string = 'https://www.rookmotion.com/privacy-policy'

    window.open(
      `${url}`, 
      '_blank'
    )

  }

  /**
   * Abrir en otra pestaña los términos y condiciones
   */
  const handleNavigateTerms = () : void => {
    
    const url : string = ' https://www.rookmotion.com/terms-and-conditions'

    window.open(
      `${url}`, 
      '_blank'
    )

  }

  return ( 
    <Dialog
      onClose = {() => {}}
      open = { open }
    >

      <div
        className = "w-2/5 mx-auto p-5"
      >
        <img src="/images/logo.png" alt="RookMotion" />
      </div>

      <div
        className = "bg-white text-black p-5 font-extralight"
      >

        <h1
          className = "text-2xl underline text-center font-bold mb-8"
        >
          { getTranslationByKey('contractTitle') }
        </h1>

        <p
          className = "text-center"
        >
          { getTranslationByKey('contractDescription') }
        </p>

        <div
          className = 'mt-8 flex flex-col items-center justify-center'
        >
          <button
            type = "button"
            onClick = { handleNavigateContract }
            className = "underline text-blue-500"
          >
            { getTranslationByKey('contractLink') }
          </button>

          <button
            type = "button"
            onClick = { handleNavigatePrivacy }
            className = "underline text-blue-500 my-4"
          >
            { getTranslationByKey('contractPrivacy') }
          </button>

          <button
            type = "button"
            onClick = { handleNavigateTerms }
            className = "underline text-blue-500"
          >
            { getTranslationByKey('contractConditions') }
          </button>

          <div
            className = 'flex items-center mt-4'
          >
            <Checkbox
              inputProps={{ 'aria-label': 'primary checkbox' }}
              checked = { checked }
              onChange = {() => setChecked(!checked)}
              sx={{
                color: '#009688',
                '&.Mui-checked': {
                  color: '#009688',
                },
              }}
            />

            <p>
              { getTranslationByKey('contractAgree') }
            </p>
          </div>

        </div>
        
        <div
          className = "flex justify-center"
        >
          { loading 
            ? (
              <div className="mt-4">
                <CircularProgress color = "secondary"/>
              </div>
            ) 
            : (
              <button
                type="button"
                disabled = { !checked }
                className = {`shadow uppercase bg-main-gradient py-2 px-8 rounded-full cursor-pointer text-white mt-8 ${checked ? 'opacity-1' : 'opacity-50' }`}
                onClick = { acceptAgreement }
              >
                { getTranslationByKey('accept') }
              </button>
            ) 
          }
        </div>

      </div>

    </Dialog>
  );
}
 
export default Contract;