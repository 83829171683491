import React, { FC } from 'react'

interface EmptyQuickAccessProps {
  title: string
}
 
/**
 * Titulo de no hay acceso directo
 * @param param0 props contiene todas las propiedades necesarias descritas en
 * EmptyQuickAccessProps
 * @param {boolean} props.title texto a mostrar
 * @returns componente con una legenda
 */
const EmptyQuickAccess: FC<EmptyQuickAccessProps> = ({ title }) => ( 
  <div className="h-full flex flex-col justify-center py-8 md:py-0">
    <h1 className = "text-center uppercase font-bold text-lg md:text-xl">
      { title }
    </h1>
  </div>
)
 
export default EmptyQuickAccess;