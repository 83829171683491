import React, { FC, useEffect, useState, useContext, useRef } from 'react'

// Dependencies
import { useMachine } from '@xstate/react';
import { useHistory, useLocation } from 'react-router-dom'
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Icons
import SensorsIcon from '@mui/icons-material/Sensors';

// Context
import UIContext from '../context/ui/UIContext'
import RemoteMachine from '../context/machines/RemoteMachine'

// Types and models
import { Word, RoomBody, Room } from '../types'
import Texts from '../models/Texts'

// Components
import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import RemoteList from '../components/rookremote/RemoteList';
import RemoteModal from '../components/rookremote/RemoteModal';
import Wrapper from '../components/rookremote/Wrapper';
import Spinner from '../components/utils/Spinner';
import Support from '../components/utils/Support';

// hooks
import useTranslation from '../hooks/useTranslation'

interface RookRemoteProps {
  
}
 
const RookRemote: FC<RookRemoteProps> = () => {

  // hook para traducir
  const { getTranslation } = useTranslation()

  // Machine que va a controlar esta vista
  const [machine, send] = useMachine(RemoteMachine)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Contador
  const [counter, setCounter] = useState(0)

  // Tener la referencia de la ventana abierta con zoom
  const refZoomInWindow = useRef<Window | null>()

  // Navegación
  const history = useHistory()
  const location = useLocation();

  // Context para sacar al usuario
  const { currentUser, hostname } = useContext( UIContext )

  useEffect(() => {

    if (machine.matches('login')) history.push("/login")

    if (machine.matches('waiting') && counter === 0) {
      setCounter(1)
      send({ type: 'HOSTNAME', data: hostname })
      
      if (!location.state) { 
        send({ type: 'NORMAL' })
      }
      else {
        handleGoToSession(location.state as Room)
      }
    }

    if (
      machine.matches('failure') 
      || machine.matches('created') 
      || machine.matches('edited')
      || machine.matches('updated')
      || machine.matches('deleted')
    )
      setShowAlert(true)
    else setShowAlert(false)

  }, [machine.value])

  /**
   * Actualzar un room de remote
   * @param room nueva data del room
   */
  const handleEdit = (room: Room) : void => {
    send({
      type: 'FOCUS',
      data: room
    })
  }

  /**
   * Eliminar un room de remote
   * @param uuid del room a eliminar
   */
  const handleDelete = (uuid: string) : void => {
    send({
      type: 'DELETE',
      data: uuid
    })
  }

  /**
   * Ir a una sesión de remote
   * @param room ir a la sesión de un room
   */
  const handleGoToSession = (room: Room) : void => {
    send({
      type: 'SESSION',
      data: {
        room,
        user: currentUser?.uuid || ''
      }
    })
  }

  /**
   * Ediar un room
   * @param data del room del nuevo o editar
   * @param isEdit estamos editando
   */
  const saveRoom = (data: RoomBody, isEdit: boolean) : void => {
    send({ 
      type: isEdit ? 'EDIT' : 'CREATE',
      data 
    })
  }

  /**
   * Agregar una imagen al room
   * @param image a subir al servidor
   * @param uuid del room
   */
  const handleUpdateImage = (image: File, uuid: string) : void => {
    send({
      type: 'BACKGROUND',
      data: {
        image, 
        uuid
      }
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'failure': return 'error'
      case 'created':
      case 'edited':
      case 'updated': 
      case 'deleted': 
        return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
   const getMessage = () : string => {
    switch (machine.value) {
      case 'created': return getTranslation(Texts.roomCreatedSuccessfully as Word)

      case 'edited': return getTranslation( Texts.roomUpdatedSuccessFully as Word )

      case 'updated': return getTranslation( Texts.imageUpdatedSuccessfully as Word )

      case 'deleted': return getTranslation( Texts.roomDeletedSuccessfully as Word )

      case 'failure': 
        return machine.context.statusError === 11 
          ? getTranslation(Texts.roomCreatedError as Word)
          : getTranslation( Texts.generalError as Word )

      default: return getTranslation( Texts.generalError as Word )
    }
  }

  /**
   * Guardar una referencia de la ventana abierta
   * @param window referencia de la ventana abierta
   */
  const updateZoomRef = (window: Window | null) : void => {
    refZoomInWindow.current = window
  }

  return ( 
    <Layout 
      isInvisible = { machine.matches('realtime') && machine.context.maximized }
    >
      <Spinner
        show = { machine.matches('loading') || machine.matches('waiting') }
      >

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() } 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      { (!machine.matches('loading') && !machine.matches('waiting')) && (
        machine.matches('realtime') 
          ? (
            <Wrapper 
              service={machine.context.realtime}
              refZoomInWindow = { refZoomInWindow }
              updateZoomRef = { updateZoomRef }
            />
          ) 
          : (
            <Container
              title = "RookRemote"
              icon = { <SensorsIcon /> }
              rightButton = { 
                <Support
                  action='remote'
                /> 
              }
            >

              <RemoteList 
                  rooms={machine.context.rooms}
                  loadingBackground={{
                    state: machine.matches('changing'),
                    uuid: machine.context.image?.uuid || ''
                  }}
                  deleting={{
                    state: machine.matches('deleting'),
                    uuid: machine.context.roomID 
                  }}
                  toggle={() => send({ type: 'TOGGLE' })} 
                  handleEdit={handleEdit} 
                  handleDelete={handleDelete} 
                  handleGoToSession={handleGoToSession} 
                  handleUpdateImage={handleUpdateImage}                
              />
              
              <RemoteModal 
                room = { machine.context.edit }
                coaches = { machine.context.coaches }
                openModal={ machine.context.openModal } 
                loading = { machine.matches('loading') || machine.matches('editing')}
                setOpenModal={ () => send({ type: 'TOGGLE' }) } 
                save = { saveRoom } 
              />
            </Container>  
          ) 
      )}



      </Spinner>
    </Layout>
  )
}
 
export default RookRemote;