import { createMachine, send, assign, spawn, sendParent, DoneInvokeEvent } from 'xstate'
import * as Sentry from '@sentry/react'

// HTTP Client
import axiosClient from '../../config/axios'

// Types
import { SearchIn, Sensors } from '../../types'

/**
 * page: actor actual
 * pageData: objeto con los actores
 * totalPages: número de páginas originales
 * pages: número de páginas actuales
 * search: criterios de búsqueda
 * searched: resultados de la búsqueda
 * currentPage: número de la página actual
 * pageAlreadyExists: saber si la página que se esta consultando ya existe
 */
type SensorMachineContext = {
  page: any | null,
  pageData: any,
  totalPages: number,
  pages: number,
  search: SearchIn | null,
  searched: Sensors | null
  currentPage: number,
  pageAlreadyExists: boolean,
}

/**
 * SEARCH: Buscar por un sensor
 * READY: Cuando la página termino de cargar
 * PAGEING: Paginar
 */
type SensorMachineEvent = 
 | { type: 'SEARCH', data: SearchIn }
 | { type: 'READY', pages: number }
 | { type: 'PAGEING', page: number }


/**
 * Page: número de página a la que pertenece
 * sensors: data descargada
 * statusError: error dado por la API
 */
type CreatePageMachineContext = {
  page: number,
  sensors: Sensors | null,
  statusError: number
}

/**
 * READY: Avisar que ya se descargo la data
 * REFRESH: nueva data descargada de acuerdo a la busqueda
 */
type CreatePageMachineEvent = 
  | { type: 'READY' }
  | { type: 'REFRESH', data: Sensors }

/**
 * Obtenemos la lista de sensores
 * @param context de la maquina que controla las subpáginas
 * @returns lista de sensores
 */
const fetchSensors = async (context: CreatePageMachineContext) : Promise<Sensors> => {
  
  // Sacamos la página
  const { page } = context

  // Definimos de que página vamos a descargar
  const url:string = page ? `/api/v2/sensors?page=${page}` : '/api/v2/sensors'

  const response = await axiosClient.get(url)
  return response.data

}

/**
 * Buscar una sesión basado en el buscador
 * @param context de la maquina principal
 * @returns Retorna una lista de usuarios
 */
 const searchForSensor = async (context: SensorMachineContext) : Promise<Sensors> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  // Hacemos la petición
  const url = `/api/v2/sensors?search_colum=${search.search_column}&search=${search.search}`
  const response = await axiosClient.get(url)

  return response.data

}

/**
 * Buscar un usuario con páginación
 * @param context Contexto de la maquina principal
 * @returns Retorna la respuesta de la api
 */
 const searchCompundRequest = async (context: SensorMachineContext) : Promise<Sensors> => {
 
  // Sacamos el objeto con el que vamos a buscar
  const { search, currentPage } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  // Formatemos la url
  let url : string  = '/api/v2/sensors'
  url = `${url}?search_colum=${search.search_column}&search=${search.search}`
  url = `${url}&page=${currentPage}`

  // Hacemos la petición
  const response = await axiosClient(url)

  return response.data
}

const SensorsMachine = createMachine<SensorMachineContext, SensorMachineEvent>(
  {
    id: 'SensorMachine',
    initial: 'idle',
    context: {
      page: null,
      pageData: {},
      totalPages: 0,
      pages: 0,
      search: null,
      searched: null,
      pageAlreadyExists: false,
      currentPage: 0,
    },
    states: {
      idle: {
        entry: send({ type: 'PAGEING', page: 1 })
      },
      loaded: { },
      loading: {
        always: [
          { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ]
      },
      searching: {
        invoke: {
          id: 'searchForSensor',
          src: searchForSensor,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedSensors',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }

              Sentry.captureException(event.data)
                
              return {
                ...context,
                currentPage: 1,
                page: Object.values(context.pageData)[0],
                pages: context.totalPages
              }
            })
          }
        }
      },
      lookingFor: {
        invoke: {
          id: 'searchCompundRequest',
          src: searchCompundRequest,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedSensors',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
        }
      }
    },
    on: {
      READY: {
        target: '.loaded',
        actions: assign({ 
          pages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
          totalPages: (context, event) => event.pages > context.pages ? event.pages : context.pages
        })
      },
      PAGEING: {
        target: '.loading',
        actions: [ 'changePage' ]
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
            ...context,
            search: event.data,
            currentPage: 1
          }))
      }
    }
  },
  {
    actions: {
      changePage: assign((context, event) => {

        if (event.type !== 'PAGEING') return context

        if (context.search && context.search.search !== '-1')
          return {
            ...context,
            page: context.pageData.searchMachine,
            pageAlreadyExists: true,
            currentPage: event.page
          }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData[`${event.page}`]

        if (subPage) {
          return {
            ...context,
            page: subPage,
            pageAlreadyExists: true,
            currentPage: event.page
          }
        }

        // Otherwise, spawn a new subreddit actor and
        // save it in the subreddits object
        subPage = spawn( createPageMachine( event.page, 'loading' ) )

        return {
          pageData: {
            ...context.pageData,
            [`${event.page}`]: subPage
          },
          page: subPage,
          pageAlreadyExists: false,
          currentPage: event.page
        }

      }),
      searchedSensors: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Sensors> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.searchForSensor" && event.type !== 'done.invoke.searchCompundRequest') 
          return context

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, 'loaded' ) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      })
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      }
    }
  }
)

const createPageMachine = (page: number, initial: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvent>(
  {
    id: 'PageMachine',
    initial,
    context: {
      page,
      sensors: null,
      statusError: 0
    },
    states: {
      loaded: {},
      login: {},
      loading: {
        invoke: {
          id: 'fetchSensors',
          src: fetchSensors,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent((context) => ({ type: 'READY', pages: context.sensors?.meta.last_page || 0 }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              assign((context, event) => {

                const errorMessage : string = 'No se pudieron descargar los sensores'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      shutdown: {
        type: 'final',
      },
    },
    on: {
      REFRESH: {
        target: '.loaded',
        actions: ['preparingData']
      },
    }
  },
  {
    actions: {
      preparingData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Sensors> = _event

        if (event.type !== 'done.invoke.fetchSensors' && event.type !== "REFRESH" ) 
          return { ...context }

        return {
          ...context,
          sensors: event.data
        }

      })
    }
  }
)

export default SensorsMachine