import React, { FC, useState } from 'react'

// Dependencies
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

// Icons
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

// Types
import { Athlete, ChartData, MetricsDetail, ReportTraining } from '../../types';

// Components
import GenericTable from '../utils/GenericTable';
import UserMetricsMainCharts from './UserMetricsMainCharts';
import UserReportModalDetails from './UserReportModalDetails';
import UserReportModalMetrics from './UserReportModalMetrics';
import UserReportModalComments from './UserReportModalComments'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { mobileAndTabletCheck } from '../../utils'


interface UserReportModalProps {
  user: Athlete,
  isOpen: boolean,
  period: string[],
  sending: boolean,
  previewPDF: string,
  showPreview: boolean,
  metrics: MetricsDetail,
  zones: ChartData | null,
  isEffortInHours: boolean,
  trainings: ReportTraining[],
  activities: ChartData | null,
  handleClose: () => void,
  handleDelete: (uuid: string) => void
  handleSend: (comments: string) => void,
  handleDownload: (comments: string) => void,
}
 
const UserReportModal: FC<UserReportModalProps> = ({ 
  isOpen, user, period, metrics, zones, isEffortInHours, 
  activities, trainings, previewPDF, showPreview, sending,
  handleClose, handleDelete, handleDownload, handleSend
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  const [startDownload, setStartDownload] = useState({ counter: 0, download: false })

  const onClick = (comments: string) : void => {
    
    if (startDownload.download) handleDownload(comments)
    else handleSend(comments)

  }

  return (
    <Dialog
      open = { isOpen }
      onClose={ handleClose }
      fullWidth
      maxWidth = 'md'
    >
      <DialogTitle>
        { getTranslationByKey('userReportModalTitle') }
      </DialogTitle>

      <DialogContent>

        { !showPreview
          ? (
            <>
              <UserReportModalDetails
                user = { user }
                period = { period }
              />
      
              <UserReportModalMetrics
                metrics={metrics}
              />
      
              <UserReportModalComments
                startDownload = { startDownload }
                handleDownload = { onClick }
              />

              {/* Si cambiaste este id es necesario cambiarlo en la maquina  */}
              {/* UserMachine ln: 189 */}
              <div id="report-charts">
                <UserMetricsMainCharts
                  zones={zones}
                  activities={activities}
                  isEffortInHours={isEffortInHours}
                />
              </div>
      
              <GenericTable 
                isVirtualList
                showCounter
                height={ 208 }
                body={ trainings } 
                headers={[
                  getTranslationByKey('userReportModalTrainingsList'), 
                  getTranslationByKey('wConfigureTrainingType'), 
                  getTranslationByKey('duration'), 
                  getTranslationByKey('userReportModalTrainingsListDate'), 
                  getTranslationByKey('adminTableHeaderActions'), 
                ]} 
                bodyKeys={['modality', 'trainingType', 'duration', 'date']}
                actions = {(element: ReportTraining) => (
                  <IconButton
                    onClick = {() => handleDelete(element.uuid)}
                  >
                    <CloseIcon />
                  </IconButton>
                )} 
              />
            </>
          ) 
          : (
            <iframe
              title='PDF preview'
              width='100%'
              height='700px'
              src={ previewPDF }
            />
          ) 
        }


      </DialogContent>

      <DialogActions
        sx={{
          padding: '1rem',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '@media (min-width: 768px)': {
            flexDirection: 'row'
          }
        }}
      >
        
        <button
          type = "button"
          className = "uppercase bg-secondary-background px-8 py-2 rounded-3xl w-full md:w-max flex items-center justify-center"
          onClick = { handleClose }
        >
          <div className="mr-2">
            <CloseIcon />
          </div>
          { getTranslationByKey('cancel') }
        </button>

        { !showPreview && (
          <>
            { !mobileAndTabletCheck() && (
              <button
                type = "button"
                data-cy = 'btn-period'
                className = "md:ml-4 uppercase bg-primary px-8 py-2 rounded-3xl mt-8 md:mt-0 w-full md:w-max flex items-center uppercase"
                onClick={
                  () => setStartDownload(prev => ({counter: prev.counter + 1, download: true})) 
                }
              >
                <div className="mr-2">
                  <DownloadIcon />
                </div>

                { getTranslationByKey('download') }
              </button>
            )}

            { sending 
              ? (
                <div
                  className='mt-4 md:mt-0 md:ml-4 '
                >
                  <CircularProgress color='secondary'/>
                </div>
              ) 
              : (
                <button
                  type="button"
                  className = "shadow bg-main-gradient px-8 py-2 rounded-full cursor-pointer mt-4 md:mt-0 md:ml-4 w-full md:w-max flex items-center uppercase justify-center"
                  onClick={
                    () => setStartDownload(prev => ({counter: prev.counter + 1, download: false})) 
                  }
                >
                  <div className="mr-2">
                    <EmailIcon />
                  </div>

                  { getTranslationByKey('send') }
                </button>
              ) 
            }

          </>
        )}

      </DialogActions>

    </Dialog>
  )
}
 
export default UserReportModal;