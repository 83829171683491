import React, { FC, useEffect, useContext } from 'react'

// Dependencies
import { useActor } from '@xstate/react';
import { useLocation } from 'react-router-dom'


// Types and modelss
import { Word } from '../../types'
import Texts from '../../models/Texts'

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import Container from '../layout/Container'
import PieChart from '../charts/PieChart';
import LineChart from '../charts/LineChart';
import MetricsDetails from './MetricsDetails'
import RButtonBack from '../utils/RookButtons/RButtonBack';
import HorizontalChart from '../charts/HorizontalChart';

// Hooks
import useTranslation from '../../hooks/useTranslation'
import MetricsChangable from './MetricsChangable';

const horizontalOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: true,
};

type SessionMetricsProps = {
  service: any
}

/**
 * Wrapper o vista de las métricas del entrenamiento
 * @param props contiene todas las propiedades necesarias descritas en SessionMetricsProps
 * @param props.service actor que controla la vista
 * @returns info del entrenamiento y gráficas
 */
const SessionMetrics: FC<SessionMetricsProps> = ({ service }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Context de la vista
  const [current, send] = useActor(service)
  const { context } : any = current

  // Sacamos el maletero para saber si tengo que volver
  const { trunk, setTrunk } = useContext( UIContext )

  // Navegación
  const location = useLocation()

  // Este es para poder volver a la página inicial dando clic en la opción de navegación
  useEffect(() => {
    
    if (trunk.includes('/back')) {
      setTrunk(location.pathname)
      send({ type: 'BACK' })
    }

  }, [trunk])

  return ( 
    <>
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">

          <RButtonBack
            action = { () => send({ type: 'BACK' }) }
          />

        </div>

        <MetricsDetails 
          session = { context.training }
          additional = { context.metrics }
        />

        { context.effort && (
          <div className = "mt-10">
            <Container
              title = { getTranslation( Texts.wConfigureOrderEffort as Word ) }
              classes = "h-80"
            >
              <LineChart 
                data = { context.effort }
              />
            </Container>
          </div>
        )}


        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-10">

          { context.zones && (
            <Container
              title = { getTranslation( Texts.trainingEffortMinutes as Word ) }
              uppercase
            >
              
              <HorizontalChart 
                data = { context.zones }
                options = { horizontalOptions }
                time = 'minutes'
              />
              
            </Container>
          )}

          { 
            context.zones && (
              <Container
                title = { getTranslation( Texts.trainingTypeTrainingMinutes as Word ) }
                classes = "h-80"
                uppercase
              >
                <div className = "h-full 2xl:ml-20">
                  <PieChart 
                    data = { context.zones }
                    time = 'minutes'
                  />
                </div>

              </Container>
            )
          }


        </div>

        { context.heartRate && (
          <MetricsChangable 
            title = { getTranslation( Texts.trainingHeartRate as Word ) }
            data = {context.heartRate}
          />
        )}

        { context.calories && (
          <div className="mt-10">
            <MetricsChangable 
              title = { getTranslation( Texts.trainingCalories as Word ) }
              data = { context.calories }
            />
          </div>
        )}
        
    </>
  );
}
 
export default SessionMetrics;