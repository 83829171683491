import React, { FC, useContext, useEffect, useState } from 'react'

// Dependencies
import { useMachine } from '@xstate/react'
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Context
import UIContext from '../context/ui/UIContext'
import DashboardMachine from '../context/machines/DashboardMachine'

// Components
import CardList from '../components/dashboard/CardList';
import QuickAccessList from '../components/dashboard/QuickAccessList';
import Layout from '../components/layout/Layout'
import Support from '../components/utils/Support';
import Contract from '../components/dashboard/Contract';

// Hooks
import useTranslation from '../hooks/useTranslation'

const Dashboard: FC = () => {

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Sacamos la data de los clusters
  const { 
    dashboardData, 
    permissions, 
    currentUser, 
    contractAlreadyShown,
    hostname,
    setContractAlreadyShown 
  } = useContext( UIContext )

  // Maquina que contr0la esta vista
  const [machine, send] = useMachine(DashboardMachine)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  useEffect(() => {
    
    if (currentUser) {
      send({ 
        type: 'INIT', 
        data: currentUser 
      })
    }

  }, [currentUser])

  useEffect(() => {
    
    if (!contractAlreadyShown && machine.matches('loaded')){
      send({ type: 'TOGGLE' })
    }

  }, [contractAlreadyShown, machine.value])

  useEffect(() => {
    
    if (machine.matches('accepted')) {
      setShowAlert(true)
      setContractAlreadyShown(true)
    }

    if (machine.matches('failure')) setShowAlert(true)

  }, [machine.value])

  // const handleCloseContract = () : void => {
    
  //   send({ type: 'TOGGLE' })
  //   setContractAlreadyShown(true)

  // }

  const handleAcceptAgreement = () : void => {
    send({
      type: 'ACCEPT',
      data: hostname
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'failure': return 'error'
      case 'accepted': return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
   const getMessage = () : string => {
    switch (machine.value) {
      case 'accepted': return getTranslationByKey('contractConditionsSuccess')
      default: return getTranslationByKey( 'generalError')
    }
  }

  return ( 
    <Layout>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() } 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <div
        className = "flex justify-end mb-8"
      >
        <Support
          action='dashboard'
        />
      </div>

      { (dashboardData && permissions.includes('dashboard')) && (
        <CardList 
          data = { dashboardData }
        />
      )}

      <QuickAccessList 
        recentRLegend = { machine.context.recentRLegend }
        recentRRemote = { machine.context.recentRRemote }
      />

      <Contract 
        loading = { machine.matches('accepting') }
        open={ machine.context.contractOpen } 
        acceptAgreement = { handleAcceptAgreement }   
      />

    </Layout>
  )

}
 
export default Dashboard;