import React, { FC } from 'react'

// Components
import { RBox } from '../../../utils/RookLayout';

interface IndividualBodyDescriptionProps {
  title: string,
  description: string
}
 
const IndividualBodyDescription: FC<IndividualBodyDescriptionProps> = ({ 
  title, description, children 
}) => {
  return ( 
    <RBox 
      type = 'grid' 
      className='grid-cols-1 md:grid-cols-2 gap-5 items-center'
    >

      <RBox className='items-center gap-2'>
        { children }
        <p>{title}</p>
      </RBox>

      <p>{description}</p>
    </RBox>
  );
}
 
export default IndividualBodyDescription;