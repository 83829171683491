import React, { FC } from 'react'
import { CSSProperties } from 'styled-components';

interface RBoxProps {
  type ?: 'flex' | 'grid'
  className ?: string
  style ?: CSSProperties
  sx ?: CSSProperties
}
 
const RBox: FC<RBoxProps> = ({ type = 'flex', className, style, sx, children }) => ( 
    <div 
      className={`${type} ${className || ''}`}
      style = {{ ...style, ...sx }}
    >
      { children }
    </div>
  )
 
export default RBox;