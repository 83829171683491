import React, { FC } from 'react'

// Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TimelineIcon from '@mui/icons-material/Timeline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
// import CakeIcon from '@mui/icons-material/Cake';
import SensorsIcon from '@mui/icons-material/Sensors';

// Components
import Card from './Card'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Models
import Texts from '../../models/Texts'
import { DashboardData, Word } from '../../types';

type CardListProps = { 
  data: DashboardData
}

/**
 * Componente que muestra las estadísticas del centro
 * @param param0 props contiene todas las propiedades necesarias descritas en
 * @param {DashboardData} props.data datos de los cuadritos
 * @returns cuadritos del dashboard
 */
const CardList: FC<CardListProps> = ({ data }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <div className = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
      <Card
        title = { getTranslation( Texts.navMenuRequests as Word ) }
        icon = { <PersonAddIcon /> }
        // detail = '/requests'
      >
        
        <div className="grid grid-cols-2 gap-2 items-center h-full">
          <div className = "text-center font-medium">
            <h3 className = "text-4xl">
              { data.request_accepted_this_month }
            </h3>
            <h4>
              { getTranslation( Texts.dashboardAcceptedRequests as Word ) }
            </h4>
          </div>

          <div className = "text-center text-warning">
            <h2 className = "text-4xl">
              { data.request_pending }
            </h2>
            <h4>
              { getTranslation( Texts.dashboardPedingRequests as Word ) }
            </h4>
          </div>
        </div>
        
      </Card>
      <Card
        title = { getTranslation( Texts.dashboardUsersActive as Word ) }
        icon = { <SensorsIcon /> }
        tooltip = { getTranslation( Texts.dashboardRookRemoteUserTooltip as Word ) }
        // detail = '/users'
      >
        <div className = "text-center font-medium flex flex-col justify-center h-full">
          <h3 className = "text-3xl">
            { data.remote_active_users }
          </h3>
          <h4 className = "text-sm uppercase">
            { getTranslation( Texts.dashboardUsersActiveDescription as Word ) }
          </h4>
        </div>
      </Card>
      <Card
        title = { getTranslation( Texts.dashboardSessionsPerMoth as Word ) }
        icon = { <TimelineIcon /> }
        // detail = '/sessions'
      >
        <div className = "text-center font-medium flex flex-col justify-center h-full">
          <h3 className = "text-4xl">
            { data.trainings_count }
          </h3>
          <h4 className = "text-sm uppercase">
            { getTranslation( Texts.dashboardSessionsPerMothDescription as Word ) }
          </h4>
        </div>
      </Card>
      <Card
        title = { getTranslation( Texts.dashboardSubscribedUsers as Word ) }
        icon = { <DirectionsRunIcon /> }
        tooltip = { getTranslation( Texts.dashboardLinkedUsersTooltip as Word ) }
        // detail = '/users'
      >
        <div className="grid grid-cols-2 gap-2 items-center h-full">
          <div className = "text-center font-medium">
            <h3 className = "text-4xl">
              { data.total_user }
            </h3>
            <h4>
              { getTranslation( Texts.dashboardSubscribedTotalDescription as Word ) }
            </h4>
          </div>

          <div className = "text-center">
            <h2 className = "text-4xl">
              { data.active_users }
            </h2>
            <h4>
              { getTranslation( Texts.dashboardSubscribedActiveDescription as Word ) }
            </h4>
          </div>
        </div>
      </Card>
      {/* <Card
        title = { getTranslation( Texts.dashboardBirthdaysTitle as Word ) }
        icon = { <CakeIcon /> }
      >
        <div className = "text-center font-medium flex flex-col justify-center h-full">
          <h3 className = "text-4xl">
            { data.found_brithday }
          </h3>
          <h4 className = "text-sm uppercase">
            { getTranslation( Texts.dashboardBirthdays as Word ) }
          </h4>
        </div>
      </Card> */}
    </div>
  );
}
 
export default CardList;