import React, { FC, useEffect, useState, useContext } from 'react'

// Dependencias
import Swal from 'sweetalert2';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom'
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Icons
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

// Context
import DaqMachine from '../context/machines/DaqMachine'
import UIContext from '../context/ui/UIContext'

// Types
import Texts from '../models/Texts'
import { Room, Word } from '../types';

// Components
import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Legend from '../components/rooklegend/Legend';
import Spinner from '../components/utils/Spinner';
// import Support from '../components/utils/Support';

// Hooks
import useTranslation from '../hooks/useTranslation'
import LegendModal from '../components/rooklegend/LegendModal';

const RookLengend: FC = () => {

  // Automatas que controlan esta página
  const [machine, send] = useMachine(DaqMachine)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Navegación
  const history = useHistory()

  // Hooks para traducir
  const { getTranslation } = useTranslation()

  // Permissions
  const { permissions } = useContext( UIContext )

  useEffect(() => {
    
    if (machine.value === 'login') history.push('/login')

    if (machine.matches('updated') || machine.matches('failure') || machine.matches('refreshed'))
      setShowAlert(true)
    else setShowAlert(false)

  }, [machine.value])

  /**
   * Connectar al daq por medio de guacamole
   * @param daq al cual se va a conectar
   */
  const handleConection = (daq: Room ) : void => {
    
    if (daq.daq_ip) {
      window.open(`http://${daq.daq_ip}:8080/guacamole/#/?username=admin&password=rook`, '_blank')

      send({
        type: 'CURRENT',
        data: daq
      })
    }
    else {
      Swal.fire({
        icon: 'info',
        title: getTranslation( Texts.rookLegenAlert as Word ) ,
      })
    }


  }

  /**
   * Actualizar las imagenes del daq
   * @param image la imagen a subir
   * @param uuid el key del room
   * @param flag si es logo o background
   */
  const handleUpdateLogo = (image: File, uuid: string, flag: boolean) : void => {
    send({
      type: flag ? 'LOGO' : 'BACKGROUND',
      data: {
        image,
        uuid
      }
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'failure': return 'error'
      case 'updated': 
      case 'refreshed': 
        return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
   const getMessage = () : string => {
    switch (machine.value) {
      case 'updated': return getTranslation(Texts.imageUpdatedSuccessfully as Word)
      case 'refreshed': return getTranslation(Texts.legendUpdated as Word)
      default: return getTranslation( Texts.generalError as Word )
    }
  }

  /**
   * Actualizar el nombre del daq
   * @param name del daq
   */
  const handleUpdate = (name: string) : void => {
    send({
      type: 'UPDATE',
      data: name
    })
  }

  return (
    <Layout>
      <Spinner
        show = { machine.matches('loading') }
      >

        <Snackbar
          open = { showAlert }
          autoHideDuration = { 6000 }
          anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
          onClose = { () => setShowAlert( false ) }
        >
          <Alert
            severity = { getSeverity() } 
            variant = "filled"
            onClose = { () => setShowAlert( false ) }
          >
            { getMessage() }
          </Alert>
        </Snackbar>

        <Container 
          title = 'RookLegend (DAQ)'
          icon = { <SettingsRemoteIcon /> }  
          // rightButton = {
          //   <Support
          //     action='https://rookmotion.zendesk.com/hc/es-419/articles/1500002619301--C%C3%B3mo-inscribo-a-mis-usuarios-en-mi-GymVirtual-'
          //   /> 
          // }     
        >

          <div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8"
          >
            {machine.context.daqs.map((daq: Room) => (
              <Legend 
                key = { daq.uuid }
                legend = { daq }
                isEditable = { permissions.includes('rooklegend_images') }
                isConnectable = { permissions.includes('rooklegend_connected') }
                loadingLogo = { 
                  machine.matches('updating') && machine.context.image?.uuid === daq.uuid 
                }
                loadingBackground = {
                  machine.matches('changing') && machine.context.image?.uuid === daq.uuid 
                }
                handleConection = { handleConection }
                handleUpdateImage = { handleUpdateLogo }
                handleUpdate = { data => send({ type: 'FOCUS', data }) }
              />
            ))}
          </div>

        </Container>

        <LegendModal
          daq = { machine.context.daq } 
          open = { machine.context.openModal }
          loading = { machine.matches('refreshing') }
          setOpen = { () => send({ type: 'TOGGLE' }) }
          handleUpdate = { handleUpdate }
        />
      </Spinner>
    </Layout>
  )
}
 
export default RookLengend;