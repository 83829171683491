import React, { FC } from 'react'

// Types and models
import Texts from '../../models/Texts'
import { Athlete, MetricsDetail, Word } from '../../types';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { calculateAge } from '../../utils';


interface UserDetailsProps {
  user: Athlete,
  metrics: MetricsDetail
}
 
/**
 * Mostrar los detalles del usuario
 * @param props contiene todas las propiedades necesarias descritas en UserDetailsProps
 * @param props.user data el usuario primera columna
 * @param props.metrics data los entrenamientos segunda columna
 * @returns Mostrar los detalles del usuario
 */
const UserDetails: FC<UserDetailsProps> = ({ user, metrics }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <div className = "p-5 border-l-8 border-red-600 mt-10 rounded-md bg-card-header">

      <h3 className = "font-bold uppercase mb-10 md:mb-0">
        { getTranslation( Texts.dashboardDetails as Word ) }
      </h3>

      <div className="md:flex md:ml-8 md:my-4">

        <div className="grid lg:grid-cols-3 gap-4 md:gap-8 lg:gap-4 border-b-2 md:border-b-0 md:border-r-2 border-gray-450 pb-8 md:pb-0 md:pr-8 md:mr-8">

          <div className=''>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.name as Word ) }
            </h4>
            <p
              className = 'capitalize'
            >
              { user.name }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.userTableHeaderLastName as Word ) }
            </h4>
            <p
              className = 'capitalize'
            >
              { user.lastName || user.last_name_1 }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.birthday as Word ) }
            </h4>
            <p>
              { user.birthday }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.age as Word ) }
            </h4>
            <p>
              { calculateAge( new Date(user.birthday) ) } años
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.sex as Word ) }
            </h4>
            <p
              className = 'capitalize'
            >
              { user.sex === 'male' ? 'M' : 'F' }
            </p>
          </div>

        </div>

        <div className = "grid grid-cols-2 lg:grid-cols-3 gap-8 mt-8 md:mt-0">

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.userStatisticsCalories as Word ) }
            </h4>
            <p>
              { metrics.calories.toFixed(2) } kcal
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.userStatisticsTraining as Word ) }
            </h4>
            <div className="flex items-center justify-between">
              <p>
                {metrics.sessions} {getTranslation(Texts.userStatisticsTrainingLabel as Word) }
              </p>
            </div>
          </div>

          { metrics.steps > 0 && (
            <div>
              <h4 className = "uppercase text-gray-450 text-sm">
                { getTranslation( Texts.userStatisticsSteps as Word ) }
              </h4>
              <p className = 'lowercase'>
                { metrics.steps } { getTranslation( Texts.userStatisticsSteps as Word ) }
              </p>
            </div>
          )}


          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.userStatisticsTime as Word ) }
            </h4>
            <p>
              { metrics.time }
            </p>
          </div>

          { !Number.isNaN(metrics.points) && (
            <div>
              <h4 className = "uppercase text-gray-450 text-sm">
                { getTranslation( Texts.userStatisticsPoints as Word ) }
              </h4>
              <p>{ metrics.points.toFixed(2) } RookPoints</p>
            </div>
          )}


        </div>

      </div>

    </div>
  )
}

export default UserDetails;