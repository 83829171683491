import React, { FC, useRef } from 'react'

// Dependencies
import { toPng } from 'html-to-image'
import { useMediaQuery } from '@mui/material';
import { ResizableBox } from "react-resizable";
import { useTheme } from '@mui/material/styles';

// Icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Components
import { RButtonClose } from './RookButtons';
import { RQRImage, RQRImageFooter } from './RookQRImage';
import { RActions, RContent, RHeader, RModal, RTitle, RPaper } from './RookModal';

// Hooks
import useTranslation from '../../hooks/useTranslation'
import RButton from './RookButtons/RButton';

// Styles
import useResizeStyles from './UtilsStyles';

interface QRModalProps {
  url: string,
  open: boolean, 
  title ?: string,
  onClose: () => void
}

const QRModal: FC<QRModalProps> = ({ url, open, title, onClose }) => {

  // Hook para traducciones
  const { getTranslationByKey } = useTranslation()

  // Estilos para el resize
  const classes = useResizeStyles()

  // Saber en que width estamos
  const theme = useTheme()
  const tabletScreen = useMediaQuery( theme.breakpoints.up('md') ) 

  // Referencia del QR
  const qrContainer = useRef<HTMLDivElement | null>(null)

  /**
   * It returns an object with a width and height property
   * @returns An object with two properties, width and height.
   */
  const getResizeSize = () : { width: number, height: number} => {
    
    if (tabletScreen) return { width: 500, height: 620 }

    return {
      width: 300,
      height: 450
    }

  }

  /**
   * It downloads the QR code as a PNG file.
   */
  const handleDownLoadQR = async () : Promise<any> => {
    
    if (qrContainer && qrContainer.current) {
      const dataUrl = await toPng(qrContainer.current)

      const anchor = document.createElement('a')
      anchor.download = `${title} - qr.png`
      anchor.href = dataUrl
      anchor.click()
    }

  }

  return ( 
    <RModal
      open = { open }
      onClose = { onClose }
      maxWidth = { false }
      PaperComponent={ RPaper }
      aria-labelledby="draggable-dialog-qr"
    >

      <ResizableBox
        height={ getResizeSize().height }
        width={ getResizeSize().width }
        className = { classes.resizable }
      >

        <RHeader>

          <RTitle 
            style = {{ cursor: 'move' }}
            id = 'draggable-dialog-qr'
            title = { getTranslationByKey('wConfigurationQRTitle') } 
          />

          <RButtonClose onClick = { onClose } />

        </RHeader>
        <RContent>

          <div 
            ref = { qrContainer } 
            className = 'flex flex-col items-center justify-center bg-white'
          >
            
            <RQRImage
              url = { url }
              width = { 400 }
            />
            
            { title && (
              <RQRImageFooter 
                className='capitalize' 
                title = { title }
                sx = {{
                  maxWidth: 400
                }} 
              />
            )}

          </div>

        </RContent>

        <RActions>
          <RButton onClick = { handleDownLoadQR }>
            <FileDownloadIcon />
            <span className="block ml-2">
              { getTranslationByKey('download') }
            </span>
          </RButton>
        </RActions>

      </ResizableBox>
    </RModal>
  );
}
 
export default QRModal;
