import React, { FC } from 'react'

// types
import { ChartData } from '../../types/index';

// Components
import PieChart from '../charts/PieChart';
import Container from '../layout/Container';
import HorizontalChart from '../charts/HorizontalChart';

// hooks
import useTranslation from '../../hooks/useTranslation'

interface UserMetricsMainChartsProps {
  zones: ChartData | null,
  isEffortInHours: boolean,
  activities: ChartData | null,
  withAnimation ?: boolean
}

// Configuración para la gráfica de barras
const horizontalOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: true,
};

const horizontalOptionsNoAnimation = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: true,
};

const UserMetricsMainCharts: FC<UserMetricsMainChartsProps> = ({ 
  zones, isEffortInHours, activities, withAnimation = false 
}) => {

  // Translation
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-10">

        { zones && (
          <Container
            title = {getTranslationByKey('userStatisticsMinutes')}
            uppercase
          >
            
            <HorizontalChart 
              options = { withAnimation ? horizontalOptions : horizontalOptionsNoAnimation }
              data = { zones }
              time = { isEffortInHours ? 'hours' : 'minutes' }
            />
            
          </Container>
        )}


        <Container
          title = {getTranslationByKey( 'userStatisticsTypeTraining' )}
          classes = "h-80"
          uppercase
        >
          <div className = "h-full 2xl:ml-20">
            <PieChart 
              data = { activities }
              time = { isEffortInHours ? 'hours' : 'minutes' }
            />
          </div>

        </Container>

      </div>
  );
}
 
export default UserMetricsMainCharts;