import React, { FC } from 'react'

// Dependencies
import IconButton from '@mui/material/IconButton';

// Icons
import CloseIcon from '@mui/icons-material/Close';

interface BasicRowProps {
  firstText: string,
  isDeletable: boolean,
  secondText: string,
  deleteRow: () => void
}
 
/**
 * Componente de la fila básica en el configurador
 * @param props contiene todas las propiedades necesarias descritas en
 * BasicRowProps
 * @param props.firstText primer texto de la fila
 * @param props.secondText segundo texto de la fila
 * @param props.deleteRow función para eliminar
 * @returns Componente de la fila básica en el configurador
 */
const BasicRow: FC<BasicRowProps> = ({ firstText, secondText, isDeletable, deleteRow }) => ( 
    <>
      <p>
        {
          firstText.length > 56
          ? `${firstText.substring(0, 53)}...`
          : firstText
        }
      </p>
      <div className='flex justify-between items-center'>
        <p>
          {
            secondText.length > 56
            ? `${secondText.substring(0, 53)}...`
            : secondText
          }
        </p>
        { isDeletable && (
          <IconButton
            onClick = { deleteRow }
          >
            <CloseIcon 
              fontSize='small'
            />
          </IconButton>
        )}
      </div>
    </>
  )
 
export default BasicRow;