import React, { FC, CSSProperties } from 'react'

interface RTextsProps {
  className ?: string,
  style ?: CSSProperties,
  sx ?: CSSProperties,
}
 
const RTexts: FC<RTextsProps> = ({ className, style, sx, children }) => {
  return ( 
    <p 
      className={`whitespace-nowrap overflow-hidden ${className || ''}`}
      style = {{ 
        textOverflow: 'ellipsis',
        ...style, 
        ...sx 
      }}
    >
      {children}
    </p>
  );
}
 
export default RTexts;