import React, { FC } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

// Main Page
import Gaming from '../../pages/Gaming';

// Sub Pages
import Board from '../../components/gaming/board/Board';
import GoalList from '../../components/gaming/goals/GoalList';
import ChallengeDescription from '../../components/gaming/detail/ChallengeDescription'

interface InternalRoutesGamProps {
  
}
 
const InternalRoutesGam: FC<InternalRoutesGamProps> = () => {
  
  const { path, url } = useRouteMatch()

  return ( 
    <Switch>
      <Route
        exact
        path = { path }
      >
        <Gaming url = {url} />
      </Route>

      <Route
        path = {`${path}/board`}
      >
        <Board />
      </Route>

      <Route
        path = {`${path}/:id/goals`}
      >
        <GoalList url = {url} />
      </Route>

      <Route
        path = {`${path}/:id`}
      >
        <ChallengeDescription url = { url }/>
      </Route>
    </Switch>
  )
}
 
export default InternalRoutesGam;