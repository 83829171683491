import React, { FC, useState } from 'react'

// Dependencies
import shortid from 'shortid'

// Components
import Cluster from './Cluster';

// Styles
import Grid from './styles/RealTimeStyles'
import { ClusterIcon, Measurements, Training } from '../../types';

interface AutoSizeRemoteProps {
  total: number,
  columns: number,
  inFlex: Training[],
  isFullScreen: boolean,
  hasStreaming: boolean,
  icon: ClusterIcon | null,
  measurements: Measurements | null,
  basis: { grid: number, flex: number },
  widthInFlex: number,
  getWidth: (n: number) => number | null,
  getClusterHeight: () => string | null,
  getImageSize: (n: number) => string
  handleBan: (user: Training) => void
}

const AutoSizeRemote: FC<AutoSizeRemoteProps> = ({
  measurements, icon, columns, isFullScreen, total, inFlex, hasStreaming,
  basis, widthInFlex, getWidth, getClusterHeight, getImageSize, 
  handleBan
}) => {

  // Key dummies
  const [keys] = useState(Array(25).fill("").map(() => shortid.generate()))
  const [flexKeys] = useState(Array(25).fill("").map(() => shortid.generate()))

  return measurements && ( 
    <div 
      className={
        `w-full mx-auto slg:h-grid slg:overflow-auto
        ${(total <= 12 && isFullScreen && columns !== 5) ? 'flex flex-col' : ''}
      `}
    >
      <Grid
        columns={ columns }
        basis = { basis.grid }
        width={ getWidth(total) }
      >
        {measurements.data.map((user, index) => (
          <Cluster
            hasStreaming = { hasStreaming }
            key={keys[index]}
            height = { getClusterHeight() }
            size={ getImageSize(total) } 
            user={user}
            icon={icon}
            handleBan={() => handleBan(user)}                
          />
        ))}
      </Grid>
      
      { inFlex.length > 0 && (
        <div 
          className='flex justify-center mt-2'
          style={{ height: `${basis.flex}%` }}
        >
          {inFlex.map((flex, index) => (
            
            <Cluster
              hasStreaming = { hasStreaming }
              key = { flexKeys[index] }
              size={ getImageSize(total) }
              width = { widthInFlex }
              marginRight={ index < (inFlex.length - 1) ? 0.5 : 0}
              user={ flex }
              icon={ icon }
              handleBan = { () => handleBan(flex) }
            />

          ))}
        </div>
      )}
    </div>
  );
}
 
export default AutoSizeRemote;