import { Word } from '../types';

type Diccionary = {
  [key: string]: Word
};

/**
 * Links de la base de conocimiento, estos son aplicados en el label que dice
 * aprender más de este módulo
 */
const Support : Diccionary = {
  dashboard: {
    label: 'dashboard',
    en: 'https://intercom.help/rookmotion-support/en/articles/5754316-what-is-the-dashboard-and-which-metrics-does-it-show',
    es: 'https://intercom.help/rookmotion-support/en/articles/5648138-que-es-el-dashboard-y-que-indicadores-muestra',
  },
  remote: {
    label: 'remote',
    en: 'https://intercom.help/rookmotion-support/en/articles/5754363-what-is-rookremote-all-i-need-to-know',
    es: 'https://intercom.help/rookmotion-support/es/articles/5644098-que-es-rookremote-todo-lo-que-necesito-saber',
  },
  requests: {
    label: 'requests',
    en: 'https://intercom.help/rookmotion-support/en/articles/5754559-requests-module',
    es: 'https://intercom.help/rookmotion-support/es/articles/5724943-modulo-solicitudes',
  },
  users: {
    label: 'users',
    en: 'https://intercom.help/rookmotion-support/en/articles/5754610-users-module',
    es: 'https://intercom.help/rookmotion-support/es/articles/5658911-modulo-de-usuarios',
  },
  sessions: {
    label: 'sessions',
    en: 'https://intercom.help/rookmotion-support/en/articles/5764951-statistics-module',
    es: 'https://intercom.help/rookmotion-support/es/articles/5658983-modulo-sesiones',
  },
  sensors: {
    label: 'sensors',
    en: 'https://intercom.help/rookmotion-support/en/articles/5764486-what-is-the-best-way-to-put-on-my-rook-sensor',
    es: 'https://intercom.help/rookmotion-support/es/articles/5647344-como-vinculo-mi-sensor-rook-con-la-aplicacion',
  },
  statistics: {
    label: 'statistics',
    en: 'https://intercom.help/rookmotion-support/en/articles/5764951-statistics-module',
    es: 'https://intercom.help/rookmotion-support/es/articles/5659049-modulo-estadisticas',
  },
  admins: {
    label: 'admins',
    en: 'https://intercom.help/rookmotion-support/en/articles/5764998-administrators-module',
    es: 'https://intercom.help/rookmotion-support/es/articles/5659194-modulo-administradores',
  },
}

export default Support