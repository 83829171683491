import React, { FC, ReactElement } from 'react'

export interface SpinnerProps {
  title ?: string,
  show: boolean,
  size ?: string
  stop ?: boolean,
  side ?: ReactElement,
  showSide ?: boolean
}
 
/**
 * spinner cuando la vista esta cargando
 * @param props contiene todas las propiedades necesarias descritas en SpinnerProps
 * @param props.title si el spinner va a mostrar un texto
 * @param props.show saber si mostrar o no el spinner
 * @param props.size altura del spinner
 * @param props.stop detener el bounce de la M
 * @returns spinner cuando la vista esta cargando
 */
const Spinner: FC<SpinnerProps> = ({ 
  title, show, size, side, children, stop = false, showSide = true
}) => ( 
    <div className = "relative transition-all duration-300 ease-in-out ">

      { show && (

          <div 
            className = {`bg-background w-full ${size || 'h-double-screen md:h-semi-screen'} z-50 absolute flex flex-col items-center pt-10 md:justify-center md:pt-0`}
          >

            <div 
              className = "mx-auto flex justify-end items-center"
            >
              <div className = {`${stop ? 'animate-none' : 'animate-wiggle'}`}>
                <img
                  src="/spin.png"
                  alt="spin"
                />
              </div>

              { showSide && (
                side || null 
              )}


            </div>

            <h3 className = "text-center font-bold uppercase text-2xl">
              { title }
            </h3>

          </div>

      )}

      { children }

  </div>
)
 
export default Spinner;