import React, { FC } from 'react'

interface RHeaderProps {
  
}
 
const RHeader: FC<RHeaderProps> = ({ children }) => ( 
    <div className='flex justify-between items-center px-2'>
      { children }
    </div>
  )
 
export default RHeader;