import React, { FC } from 'react'

// Icons
import TrophyIcon from '@mui/icons-material/EmojiEvents';

// Components
import CircularProgressWithLabel from '../CircularProgressWithLabel';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

// Utils
import Colors from '../../../../utils/colors'

interface GroupBodyProps {
  percentage: number
  fullRounded ?: boolean,
  goals: { earned: number, total: number }
}
 
const GroupBody: FC<GroupBodyProps> = ({ percentage, goals, fullRounded = false  }) => {

  const { getTranslationByKey } = useTranslation()

  return ( 
    <div 
      className={`flex justify-center py-5 items-center cursor-pointer w-full ${ fullRounded && 'rounded-2xl my-4 bg-card-body' }`}
    >

      <CircularProgressWithLabel
        value = { percentage }
        size = { 90 }
        fill = { Colors.title }
      />

      <div className='p-2 ml-4 lg:border-l lg:border-neutral'>
        
        <div className="flex flex-col justify-center items-center ml-2">
          <TrophyIcon
            fontSize='large'
          />
          <h5 className='my-2 text-lg'>
            { getTranslationByKey('gamingTeamTitle') }:
          </h5>
          <p className='font-bold'>
            { goals.earned } { getTranslationByKey('from') } { goals.total }
          </p>
        </div>

      </div>

    </div>
  );
}
 
export default GroupBody;