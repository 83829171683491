import React, { FC, ChangeEvent, useRef, useState } from 'react'

// Dependencies
import styled from 'styled-components'
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react'

// Icon
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// Types and models
import Texts from '../../models/Texts'
import { Word } from '../../types'

// Hook
import useTranslation from '../../hooks/useTranslation'

interface ProfileImageProps {
  name: string,
  email: string,
  image: string | null,
  spinner: boolean,
  changeImage: (image: File) => void
}

type ProfileImageContainerProps = {
  image?: string | null
}

const ProfileImageContainer = styled.div<ProfileImageContainerProps>`
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  background-image: ${props => props.image ? `url(${props.image})` : "url('/images/avatar.png')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

const Camera = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    display: none;
  }
  
  &:hover {
    background-color: rgba(0,0,0,0.6);
    
    & svg {
      display: block;
    }

  }
`

/**
 * Imagen del perfil
 * @param props contiene todas las propiedades necesarias descritas en ProfileImageProps
 * @param props.name del usuario loggeado 
 * @param props.email del usuario loggeado 
 * @param props.image del usuario loggeado 
 * @param props.spinner saber si se esta actalizando la imagen de perfil
 * @returns icono para cambiar la foto del perfil
 */
const ProfileImage: FC<ProfileImageProps> = ({ name, email, image, spinner, changeImage }) => {

  // Referencia al input para hacer clic
  const inputFile = useRef<any>()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  /**
   * Manejador de la imagen
   * @param e evento para recibir la imagen
   * @returns early return 
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) : void => {
    e.preventDefault()

    // Verificamos que exista la imagen
    if( !e.currentTarget.files ) return 

    // Sacamos el archivo
    const file = e.currentTarget.files.item(0)

    if ( !file ) {
      Sentry.captureMessage(
        `No se encontró la imagen profile: ${JSON.stringify(e.currentTarget.files)}`
      )
      return 
    }

    // Verificamos el tamaño del la imagen
    const size = (file.size / 1024 / 1024).toFixed(2)

    // Si es mayor a dos megas mandamos la alerta
    if ( Number(size) > 2 ) {
      setShowAlert( true )
      return 
    }

    // Si no mandamos la imagen
    changeImage( file )

  }

  return ( 
    <div 
      style = {{ flexBasis: '40%' }}
      className = "flex flex-col items-center justify-center h-full mb-10 lg:mb-0"
    >

    <Snackbar
      open = { showAlert }
      autoHideDuration = { 6000 }
      anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
      onClose = { () => setShowAlert( false ) }
    >
      <Alert
        severity = 'error' 
        variant = "filled"
        onClose = { () => setShowAlert( false ) }
      >
        { getTranslation( Texts.profileImageSizeError as Word ) }
      </Alert>
    </Snackbar>

      <input 
        type="file" 
        id="image_admin" 
        name="image_admin" 
        accept="image/png, image/jpeg"
        className = "hidden"
        onChange = { handleFileChange }
        ref = { inputFile }
      />

      { spinner 
        ? (
          <CircularProgress 
            color = "secondary"
            size = '10rem'
          />
        ) 
        : (
          <button type = "button" onClick = { () => inputFile.current.click() }>
            <ProfileImageContainer
              image = { image }
            >
              <Camera>
                  <CameraAltIcon />
              </Camera>
            </ProfileImageContainer>
          </button>
        ) 
      }

      <div className = "text-center">
        <h3 className = "text-title font-bold text-xl mt-8 capitalize">
          { name }
        </h3>
        <p className = "text-gray-450 text-sm" >
          { email }
        </p>
      </div>

    </div>
  )
}
 
export default ProfileImage;