/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState } from 'react'

// Dependencies
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Types and Models
import Texts from '../../models/Texts'
import { CurrentUser, UpdatePassword, Word } from '../../types'

// Components
import TabPanel from '../utils/TabPanel'
import ProfileForm from './ProfileForm'
import ChangePasswordForm from './ChangePasswordForm';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { a11yProps } from '../../utils'

interface ProfileTabsProps {
  currentUser: CurrentUser,
  profileSpinner: boolean,
  passwordSpinner: boolean,
  resetPasswordForm: boolean,
  handleUpdate: (user: CurrentUser) => void
  handleUpdatePassword: (password: UpdatePassword) => void
}
 
/**
 * Tabs con las opciones a mostrar
 * @param props contiene todas las propiedades necesarias descritas en ProfileTabsProps
 * @param props.currentUser usuario loggeado 
 * @param props.profileSpinner saber si se esta actualizando el perfil
 * @param props.passwordSpinner saber si se esta actualizando la contraseña
 * @param props.resetPasswordForm para saber cuando vaciar el formulario y dejarlo en blanco
 * @param props.handleUpdate función para actualizar el perfil
 * @param props.handleUpdatePassword función para actualizar la contraseña
 * @returns navegación interna del perfil
 */
const ProfileTabs: FC<ProfileTabsProps> = ({ 
  currentUser, profileSpinner, passwordSpinner, resetPasswordForm, handleUpdate, handleUpdatePassword
}) => {

  // Saber la tab
  const [value, setValue] = useState(0)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  /**
   * Cambiar la tab
   * @param event de cambio
   * @param newValue nuevo valor de la tab
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return ( 
    <div
      style = {{ flexBasis: '60%' }}
      className = "w-full"
    >
      <div className = "flex justify-center">
        <Tabs
          textColor = "inherit"
          value={value}
          onChange={handleChange}
        >
          <Tab 
            label= { getTranslation( Texts.profileTabProfileData as Word ) }
            wrapped 
            {...a11yProps(0)}
          />
          <Tab 
            label= { getTranslation( Texts.profileTabChangePassword as Word ) } 
            wrapped
            {...a11yProps(1)}
          />
        </Tabs>
      </div>

      <TabPanel value={value} index={0}>
        
        <ProfileForm 
          currentUser = { currentUser }
          handleUpdate = { handleUpdate }
          spinner = { profileSpinner }
        />
        
      </TabPanel>

      <TabPanel value={value} index={1}>
        
        <ChangePasswordForm 
          spinner={ passwordSpinner } 
          handleUpdate={ handleUpdatePassword }   
          reset = { resetPasswordForm }     
        />
        
      </TabPanel>

    </div>
  );
}
 
export default ProfileTabs;