import React, { FC } from 'react'

// Dependencies
import moment from 'moment';

// types
import { Athlete } from '../../types'

// hooks
import useTranslation from '../../hooks/useTranslation'

// Utils
import { calculateAge } from '../../utils';

interface UserReportModalDetailsProps {
  user: Athlete,
  period: string[]
}
 
const UserReportModalDetails: FC<UserReportModalDetailsProps> = ({ user, period }) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className = "p-5 border-l-8 border-red-600 rounded-md bg-card-header ">

      <h3 className = "font-bold uppercase mb-4">
        { getTranslationByKey( 'userReportModalUserTitle' ) }
      </h3>

      <div
        className='grid grid-cols-1 md:grid-cols-6 gap-2 ml-8'
      >

        <div className=''>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'name' ) }
          </h4>
          <p
            className = 'capitalize'
          >
            { user.name }
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'userTableHeaderLastName') }
          </h4>
          <p
            className = 'capitalize'
          >
            { user.lastName || user.last_name_1 }
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'age' ) }
          </h4>
          <p>
            { calculateAge( new Date(user.birthday) ) } años
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey( 'sex' ) }
          </h4>
          <p
            className = 'capitalize'
          >
            { user.sex === 'male' ? 'M' : 'F' }
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey('birthday') }
          </h4>
          <p>
            { user.birthday }
          </p>
        </div>

        <div>
          <h4 className = "uppercase text-gray-450 text-sm">
            { getTranslationByKey('period') }
          </h4>
          <p>
            { moment(period[0]).format(getTranslationByKey('momentDateFormat')) } - {' '} 
            { moment(period[1]).format(getTranslationByKey('momentDateFormat')) }
          </p>
        </div>

      </div>

    </div>
   );
}
 
export default UserReportModalDetails;