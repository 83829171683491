import { Gender, Word } from '../types';

type Diccionary = {
  [key: string]: Word | Word[]
};

/**
 * Diccionario de palabras de la app
 */
const Texts : Diccionary = {
  generalError: {
    label: "generalError",
    en: 'An error occurred, please try again later',
    es: 'Ocurrió un error, intente más tarde',
  },
  serviceUnavailable: {
    label: "serviceUnavailable",
    en: 'Service temporarily unavailable',
    es: 'Servicio temporalmente no dispo nible',
  },
  cancel: {
    label: "cancel",
    en: 'Cancel',
    es: 'Cancelar',
  },
  save: {
    label: "save",
    en: 'Save changes',
    es: 'Guardar cambios',
  },
  delete: {
    label: "delete",
    en: 'Delete',
    es: 'Eliminar',
  },
  inactive: {
    label: "inactive",
    en: 'Inactive',
    es: 'Inactivo',
  },
  active: {
    label: "active",
    en: 'Active',
    es: 'Activo',
  },
  showing: {
    label: "showing",
    en: 'Showing',
    es: 'Mostrando',
  },
  refresh: {
    label: "refresh",
    en: 'Refresh',
    es: 'Actualizar',
  },
  edit: {
    label: "edit",
    en: 'Edit',
    es: 'Editar',
  },
  room: {
    label: "room",
    en: 'Room',
    es: 'Sala',
  },
  coach: {
    label: "coach",
    en: 'Coach',
    es: 'Entrenador',
  },
  admin: {
    label: "admin",
    en: 'Administrator',
    es: 'Administrador',
  },
  status: {
    label: "status",
    en: 'Status',
    es: 'Estado'
  },
  name: {
    label: "name",
    en: 'Name',
    es: 'Nombre',
  },
  birthday: {
    label: "birthday",
    en: 'Birthday',
    es: 'Fecha de nacimiento',
  },
  age: {
    label: "age",
    en: 'Age',
    es: 'Edad',
  },
  sex: {
    label: "sex",
    en: 'Sex',
    es: 'Sexo',
  },
  start: {
    label: "start",
    en: 'Start',
    es: 'Iniciar',
  },
  back: {
    label: 'back',
    en: 'Back',
    es: 'Regresar',
  },
  hours: {
    label: 'hours',
    en: 'hours',
    es: 'horas',
  },
  minutes: {
    label: 'minutes',
    en: 'minutes',
    es: 'minutos',
  },
  seconds: {
    label: 'seconds',
    en: 'seconds',
    es: 'segundos',
  },
  stop: {
    label: 'stop',
    en: 'Stop',
    es: 'Detener',
  },
  reset: {
    label: 'reset',
    en: 'Reset',
    es: 'Reiniciar',
  },
  user: {
    label: 'user',
    en: 'User',
    es: 'Usuario',
  },
  users: {
    label: 'users',
    en: 'Users',
    es: 'Usuarios',
  },
  center: {
    label: 'center',
    en: 'Center',
    es: 'Centro',
  },
  searchPeriod: {
    label: 'searchPeriod',
    en: 'Search Period',
    es: 'Buscar Periodo',
  },
  show: {
    label: 'show',
    en: 'Show',
    es: 'Mostrar',
  },
  registers: {
    label: 'registers',
    en: 'Registers',
    es: 'Registros',
  },
  of: {
    label: 'of',
    en: 'of',
    es: 'de',
  },
  from: {
    label: 'from',
    en: 'from',
    es: 'de',
  },
  mac: {
    label: 'mac',
    en: 'MAC',
    es: 'MAC',
  },
  accept: {
    label: 'accept',
    en: 'Accept',
    es: 'Aceptar',
  },
  goals: {
    label: 'goals',
    en: 'Goals',
    es: 'Objetivos',
  },
  goal: {
    label: 'goal',
    en: 'Goal',
    es: 'Objetivo',
  },
  points: {
    label: 'points',
    en: 'Points',
    es: 'Puntos',
  },
  completed: {
    label: 'completed',
    en: 'Completed',
    es: 'Finalizado',
  },
  pending: {
    label: 'pending',
    en: 'Pending',
    es: 'Pendiente',
  },
  competitive: {
    label: 'competitive',
    en: 'Competitive',
    es: 'Competitivo',
  },
  free: {
    label: 'free',
    en: 'Free',
    es: 'Libre',
  },
  team: {
    label: 'team',
    en: 'Team',
    es: 'Equipo',
  },
  close: {
    label: 'close',
    en: 'Close',
    es: 'Cerrar',
  },
  choose: {
    label: 'choose',
    en: 'Choose',
    es: 'Seleccionar',
  },
  description: {
    label: 'description',
    en: 'Description',
    es: 'Descripción',
  },
  continue: {
    label: 'continue',
    en: 'Continue',
    es: 'Continuar',
  },
  recover: {
    label: 'recover',
    en: 'Recover',
    es: 'Recuperar',
  },
  period: {
    label: 'period',
    en: 'Period',
    es: 'Periodo',
  },
  send: {
    label: 'send',
    en: 'Send',
    es: 'Enviar',
  },
  download: {
    label: 'download',
    en: 'Download',
    es: 'Descargar',
  },
  compliance: {
    label: 'compliance',
    en: 'Completion',
    es: 'Cumplimiento',
  },
  duration: {
    label: 'duration',
    en: 'Duration',
    es: 'Tiempo',
  },
  level: {
    label: 'level',
    en: 'Level',
    es: 'Nivel',
  },
  progress: {
    label: 'progress',
    en: 'Progress',
    es: 'Progreso',
  },
  locate: {
    label: 'locate',
    en: 'Locate',
    es: 'Localizar',
  },
  momentDateFormat: {
    label: "datePickerInputFormat",
    en: 'MM/DD/YYYY',
    es: 'DD/MM/YYYY',
  },
  datePickerInputFormat: {
    label: "datePickerInputFormat",
    en: 'MM/dd/yyyy',
    es: 'dd/MM/yyyy',
  },
  datePickerInputMask: {
    label: "datePickerInputMask",
    en: '__/__/____',
    es: '__/__/____',
  },
  paymentErrorTitle: {
    label: "paymentErrorTitle",
    en: 'Your account is suspended for non-payment',
    es: 'Su cuenta se encuentra suspendida por falta de pago',
  },
  paymentErrorMessage: {
    label: "paymentErrorMessage",
    en: 'Please contact support through our chat to be able to regularize the payment.',
    es: 'Por favor póngase en contacto con soporte a traves de nuestro chat para poder regularizar el pago.',
  },
  imageUpdatedSuccessfully: {
    label: "imageUpdatedSuccessfully",
    en: 'The image was updated successfully',
    es: 'La imagen se actualizó correctamente',
  },
  loginTitle: {
    label: "loginTitle",
    en: "Enter your credentials to log in",
    es: "Ingresa tus datos para iniciar sesión",
  },
  loginEmail: {
    label: "loginEmail",
    en: "Email",
    es: "Correo electrónico",
  },
  loginEmailPlaceHolder: {
    label: "loginEmailPlaceHolder",
    en: "Enter email",
    es: "Ingresa tu correo electrónico",
  },
  loginEmailValidError: {
    label: "loginEmailValidError",
    en: "Enter a valid email",
    es: "Ingresa un correo electrónico válido",
  },
  loginEmailRequiredError: {
    label: "loginEmailRequiredError",
    en: "The email is required",
    es: "El correo electrónico es requerido",
  },
  loginPassword: {
    label: "loginPassword",
    en: "Password",
    es: "Contraseña",
  },
  loginPasswordPlaceHolder: {
    label: "loginPasswordPlaceHolder",
    en: "Enter password",
    es: "Ingresa tu contraseña",
  },
  loginPasswordRequiredError: {
    label: "loginPasswordRequiredError",
    en: "The password is mandatory",
    es: "La contraseña es requerida",
  },
  loginRememberme: {
    label: "loginRememberme",
    en: "Remember me",
    es: "Recordarme",
  },
  loginForgotPassword: {
    label: "loginForgotPassword",
    en: "Did you forget your password?",
    es: "¿Olvidaste tu contraseña?",
  },
  loginSubmit: {
    label: "loginSubmit",
    en: "Log in",
    es: "Iniciar sesión",
  },
  loginErrorOpenSession: {
    label: "loginErrorOpenSession",
    en: "You have a session open on another device",
    es: "Tienes una sesión abierta en otro dispositivo",
  },
  loginError: {
    label: "loginErrorOpenSession",
    en: "Incorrect email or password",
    es: "Correo electrónico o contraseña incorrecto",
  },
  loginErrorVerified: {
    label: "loginErrorVerified",
    en: 'You need to verify your email before continuing',
    es: 'Es necesario que verifiques tu email antes de continuar',
  },
  loginErrorInactive: {
    label: "loginErrorInactive",
    en: 'Your account has been disabled, contact the center administrator',
    es: 'Tu cuenta ha sido inhabilitada, comunicate con el administrador del centro',
  },
  centerError: {
    label: "centerError",
    en: "We could not find your center, check the url",
    es: "No hemos podido encontrar tu centro, revisa la url",
  },
  navTitle: {
    label: "navTitle",
    en: "Menu",
    es: "Menú",
  },
  navMenuDashboard: {
    label: "navMenuDashboard",
    en: "Dashboard",
    es: "Inicio",
  },
  navMenuRooms: {
    label: "navMenuRooms",
    en: "Rooms",
    es: "Salas",
  },
  navMenuRequests: {
    label: "navMenuRequests",
    en: "Requests",
    es: "Solicitudes",
  },
  navMenuUsers: {
    label: "navMenuUsers",
    en: "Users",
    es: "Usuarios",
  },
  navMenuSesions: {
    label: "navMenuSessions",
    en: "Trainings",
    es: "Entrenamientos",
  },
  navMenuReports: {
    label: "navMenuReports",
    en: "Statistics",
    es: "Estadísticas",
  },
  navMenuAdmin: {
    label: "navMenuAdmin",
    en: "Administrators",
    es: "Administradores",
  },
  navMenuRoles: {
    label: "navMenuRoles",
    en: "Roles",
    es: "Roles",
  },
  navMenuSensors: {
    label: "navMenuSensors",
    en: "Sensors",
    es: "Sensores",
  },
  profileMenuProfile: {
    label: "profileMenuProfile",
    en: "Profile",
    es: "Perfil",
  },
  profileMenuCenterInfo: {
    label: "profileMenuCenterInfo",
    en: "Center information",
    es: "Información del centro",
  },
  profileMenuLogOut: {
    label: "profileMenuLogOut",
    en: "Log out",
    es: "Cerrar sesión",
  },
  dashboardAcceptedRequests: {
    label: "dashboardAcceptedRequests",
    en: "Accepted",
    es: "Aceptadas",
  },
  dashboardPedingRequests: {
    label: "dashboardPendingRequests",
    en: "Pending",
    es: "Pendientes",
  },
  dashboardUsersActive: {
    label: "dashboardUsersActive",
    en: "RookRemote Users",
    es: "Usuarios RookRemote",
  },
  dashboardUsersActiveDescription: {
    label: "dashboardUsersActiveDescription",
    en: "Active users in the month",
    es: "Usuarios activos en el mes",
  },
  dashboardSessionsPerMoth: {
    label: "dashboardSessionsPerMoth",
    en: "Trainings per month",
    es: "Entrenamientos por mes",
  },
  dashboardSessionsPerMothDescription: {
    label: "dashboardSessionsPerMothDescription",
    en: "Trainings in the month",
    es: "Entrenamientos en el mes",
  },
  dashboardSubscribedUsers: {
    label: "dashboardSubscribedUsers",
    en: "Linked Users",
    es: "Usuarios Vinculados",
  },
  dashboardSubscribedTotalDescription: {
    label: "dashboardSubscribedTotalDescription",
    en: "Total users",
    es: "Total de usuarios",
  },
  dashboardSubscribedActiveDescription: {
    label: "dashboardSubscribedActiveDescription",
    en: "Active users",
    es: "Usuarios activos",
  },
  dashboardBirthdaysTitle: {
    label: "dashboardBirthdaysTitle",
    en: "Birthdays",
    es: "Cumpleaños",
  },
  dashboardBirthdays: {
    label: "dashboardBirthdays",
    en: "Birthdays of the month",
    es: "Cumpleaños del mes",
  },
  dashboardFloatingButton: {
    label: "dashboardFloatingButton",
    en: "Recent rooms",
    es: "Salas recientes",
  },
  dashboardDetails: {
    label: "dashboardDetails",
    en: "Details",
    es: "Detalles",
  },
  dashboardEmptyRookLegend: {
    label: "dashboardEmptyRookLegend",
    en: "You have no recently used RookLegend rooms",
    es: "No tienes salas de RookLegend utilizadas recientemente",
  },
  dashboardEmptyRookRemote: {
    label: "dashboardEmptyRookRemote",
    en: "You have no recently used RookRemote rooms",
    es: "No tienes salas de RookRemote utilizadas recientemente",
  },
  dashboardRookRemoteUserTooltip: {
    label: "dashboardRookRemoteUserTooltip",
    en: "A user is considered active when they have completed 3 or more trainings on RookRemote.",
    es: "Se considera que un usuario está activo cuando ha completado 3 entrenamientos o más en RookRemote.",
  },
  dashboardLinkedUsersTooltip: {
    label: "dashboardLinkedUsersTooltip",
    en: "The number of linked users and the number of active users, who have registered at least one session, are displayed.",
    es: "Se muestra la cantidad de usuarios vinculados y la cantidad de usuarios activos, que han registrado al menos una sesión.",
  },
  dashboardLastRoom: {
    label: "dashboardLastRoom",
    en: "Last room used",
    es: "Última sala utilizada",
  },
  rookLegenAlert: {
    label: "rookLegendAlert",
    en: "Your RookLegend has not connected to the internet",
    es: "Tu RookLegend no se ha conectado a internet",
  },
  searchBarPlaceHolder: {
    label: "searchBarPlaceHolder",
    en: "Search",
    es: "Buscar",
  },
  searchBarMacPlaceHolder: {
    label: "searchBarMacPlaceHolder",
    en: 'Search - ⌘ + K',
    es: 'Buscar - ⌘ + K',
  },
  searchBarWinPlaceHolder: {
    label: "searchBarWinPlaceHolder",
    en: 'Search',
    es: 'Buscar',
  },
  usersAdd: {
    label: "usersAdd",
    en: "Add user",
    es: "Agregar usuario",
  },
  usersModalUpdate: {
    label: "usersModalUpdate",
    en: "Edit user",
    es: "Editar Usuario",
  },
  usersModalCreate: {
    label: "usersModalCreate",
    en: "New user",
    es: "Nuevo usuario",
  },
  usersNameInput: {
    label: "usersNameInput",
    en: "Name",
    es: "Nombre",
  },
  usersNameInputPlaceholder: {
    label: "usersNameInputPlaceholder",
    en: "Enter the username",
    es: "Ingresa el nombre del usuario",
  },
  usersNameInputError: {
    label: "usersNameInputError",
    en: "The name is required",
    es: "El nombre es requerido",
  },
  usersNameInputLengthError: {
    label: "usersNameInputLengthError",
    en: "The name must have a maximum of 100 characters",
    es: "El nombre debe tener un máximo de 100 caracteres",
  },
  usersLastNameInput: {
    label: "usersLastNameInput",
    en: "First Last name",
    es: "Primer Apellido",
  },
  usersLastNameInputPlaceholder: {
    label: "usersLastNameInputPlaceholder",
    en: "Enter the first last name of the user",
    es: "Ingresa el primer apellido del usuario",
  },
  usersLastNameInputError: {
    label: "usersLastNameInputError",
    en: "The first last name is required",
    es: "El primer apellido paterno es requerido",
  },
  usersLastNameInputLengthError: {
    label: "usersLastNameInputLengthError",
    en: "The first last name must have a maximum of 100 characters",
    es: "El primer apellido debe tener un máximo de 100 caracteres",
  },
  usersMLastNameInput: {
    label: "usersMLastNameInput",
    en: "Second Last name",
    es: "Segundo Apellido",
  },
  usersMLastNameInputPlaceholder: {
    label: "usersMLastNameInputPlaceholder",
    en: "Enter the second last name of the user",
    es: "Ingresa el segundo apellido del usuario",
  },
  usersMLastNameInputError: {
    label: "usersMLastNameInputError",
    en: "The second last name is required",
    es: "El segundo apellido es requerido",
  },
  usersMLastNameInputLengthError: {
    label: "usersMLastNameInputLengthError",
    en: "The second last name must have a maximum of 100 characters",
    es: "El segundo apellido debe tener un maximo de 100 caracteres",
  },
  usersPseudonymInput: {
    label: "usersPseudonymInput",
    en: "Pseudonym",
    es: "Pseudónimo",
  },
  usersPseudonymInputPlaceholder: {
    label: "usersPseudonymInputPlaceholder",
    en: "This will be displayed on RookMotion screens",
    es: "Este se mostrará en pantallas de RookMotion",
  },
  usersPseudonymInputError: {
    label: "usersPseudonymInputError",
    en: "The pseudonym is required",
    es: "El pseudónimo es requerido",
  },
  usersPseudonymInputLengthError: {
    label: "usersPseudonymInputLengthError",
    en: "The pseudonym must have a maximum of 100 characters and a minimum of 2 characters",
    es: "El pseudónimo debe tener un mínimo de 2 y máximo de 100 caracteres",
  },
  usersSexInput: {
    label: "usersSexInput",
    en: "Sex",
    es: "Sexo",
  },
  usersSexInputError: {
    label: "usersSexInputError",
    en: "The sex is required",
    es: "El sexo es requerido",
  },
  userSexOptions: [
    {
      label: "choose",
      en: "Choose",
      es: "Elegir",
    },
    {
      label: Gender.male as string,
      en: "Male",
      es: "Hombre",
    },
    {
      label: Gender.female as string,
      en: "Female",
      es: "Mujer",
    }
  ],
  usersBirthdayInput: {
    label: "usersBirthdayInput",
    en: "Date of birth",
    es: "Fecha de nacimiento",
  },
  usersBirthDayInputError: {
    label: "usersBirthDayInputError",
    en: "Please enter a valid date of birth",
    es: "Ingresa una fecha de nacimiento válida",
  },
  usersWeigthInput: {
    label: "usersWeightInput",
    en: "WEIGHT",
    es: "PESO",
  },
  usersHeightInput: {
    label: "usersHeightInput",
    en: "HEIGHT",
    es: "ALTURA",
  },
  usersHeartRateInput: {
    label: "usersHeartRateInput",
    en: "BASAL H.R",
    es: "F.C BASAL",
  },
  userInfoError: {
    label: "userInfoError",
    en: "Enter a valid data",
    es: "Ingresa un dato válido",
  },
  userRestingError: {
    label: "userRestingError",
    en: "The heart rate must be between 40 and 100.",
    es: "La frecuencia cardíaca debe ser entre 40 y 100 ",
  },
  userWeigthError: {
    label: "userWeightError",
    en: "The weight must be between 30 and 190.",
    es: "El peso debe ser entre 30 y 190 ",
  },
  userEnglishWeigthError: {
    label: "userEnglishWeightError",
    en: "The weight must be between 67 and 418.",
    es: "El peso debe ser entre 67 y 418.",
  },
  userHeightError: {
    label: "userHeightError",
    en: "The height must be between 120 and 230.",
    es: "La altura debe ser entre 120 y 230.",
  },
  userEnglishHeightError: {
    label: "userEnglishHeightError",
    en: "The height must be between 48 and 90.",
    es: "La altura debe ser entre 48 y 90.",
  },
  userTableHeaderLastName: {
    label: "userTableHeaderLastName",
    en: "Last Name",
    es: "Apellido",
  },
  userSaveButtonModal: {
    label: "userSaveButtonModal",
    en: "Add user",
    es: "Agregar usuario",
  },
  userTableActions: {
    label: "userTableActions",
    en: "Actions",
    es: "Acciones",
  },
  userAlredyLinked: {
    label: "userAlreadyLinked",
    en: 'The user is already registered in this or another center. Ask him to link to your center from the application',
    es: 'El usuario ya esta registro en este u otro centro. Pídele que se vincule a tu centro desde la aplicación',
  },
  userUpdatedorCreatedSuccessfully: {
    label: "userUpdatedCreatedSuccessfully",
    en: 'User was saved successfully',
    es: 'Se guardo correctamente al usuario',
  },
  userPartialUpdatedOrCreated: {
    label: "userPartialUpdatedOrCreated",
    en: 'User information was partially inserted, due to an error, please update it',
    es: 'Se insertó parcialmente la información del usuario, debido a un error, favor de actualizarla',
  },
  userMetricsTitle: {
    label: "userMetricsTitle",
    en: 'Training statistics',
    es: 'Estadísticas de entrenamiento',
  },
  userStatisticsTooltip: {
    label: 'userStatisticsTooltip',
    en: 'User statistics',
    es: 'Estadísticas del usuario'
  },
  userEditTooltip: {
    label: 'userEditTooltip',
    en: 'Edit user',
    es: 'Editar usuario'
  },
  userDeleteTooltip: {
    label: 'userDeleteTooltip',
    en: 'Delete user',
    es: 'Eliminar usuario'
  },
  userUnbanTooltip: {
    label: 'userUnbanTooltip',
    en: 'This user is expelled, click to reinstate',
    es: 'Este usuario esta expulsado, clic para reintegrar'
  },
  userDeleteConfirmation: {
    label: 'userDeleteConfirmation',
    en: 'Are you sure you want to delete',
    es: '¿Deseas eliminar de tu centro a'
  },
  userUnbanConfirmation: {
    label: 'userUnbanConfirmation',
    en: 'Are you sure you want to bring back',
    es: '¿Deseas reintegrar al centro a'
  },
  userContentConfirmation: {
    label: 'userContentConfirmation',
    en: 'You will lose all user records. To re-enter your center, you must link again',
    es: 'Perderá todos los registros del usuario. Para volver a ingresar a su centro, se debe vincular nuevamente'
  },
  userConfirmButtonConfirmation: {
    label: 'userConfirmButtonConfirmation',
    en: 'Delete user',
    es: 'Eliminar usuario'
  },
  userConfirmButtonUnban: {
    label: 'userConfirmButtonUnban',
    en: 'Unban',
    es: 'Reintegrar'
  },
  userSucessAlert: {
    label: 'userSuccessAlert',
    en: 'The user was deleted successfully',
    es: 'Se eliminó correctamente al usuario'
  },
  userAuthAlert: {
    label: 'userAuthAlert',
    en: 'The user is successfully reinstated',
    es: 'Se reintegró correctamente al usuario'
  },
  userGetListError: {
    label: 'userGetListError',
    en: 'Could not download users try later',
    es: 'No se pudieron descargar los usuarios intenta más tarde'
  },
  searchBarError: {
    label: 'searchBarError',
    en: 'Enter at least 3 characters',
    es: 'Escribe al menos 3 caracteres'
  },
  searchBarErrorDate: {
    label: 'searchBarErrorDate',
    en: 'Enter a valid date',
    es: 'Ingresa una fecha válida'
  },
  profileUpdateSucess: {
    label: 'profileUpdateSuccess',
    en: 'User has been successfully updated',
    es: 'Se ha actualizado correctamente al usuario'
  },
  profileUpdatePasswordSucess: {
    label: 'profileUpdatePasswordSuccess',
    en: 'Password has been updated successfully',
    es: 'Se ha actualizado la contraseña correctamente'
  },
  profileCurrentPasswordError: {
    label: 'profileCurrentPasswordError',
    en: 'The current password does not match',
    es: 'La contraseña actual no coincide'
  },
  profileCurrentPasswordWeakError: {
    label: 'profileCurrentPasswordWeakError',
    en: 'The password is very insecure choose another',
    es: 'La contraseña es muy insegura elige otra'
  },
  profileImageSizeError: {
    label: 'profileImageSizeError',
    en: 'The weight of the image must be a maximum of 2 MB',
    es: 'El peso de la imagen debe ser máximo de 2 MB'
  },
  profileTabProfileData: {
    label: 'profileTabProfileData',
    en: 'Profile data',
    es: 'Datos del perfil'
  },
  profileTabChangePassword: {
    label: 'profileTabChangePassword',
    en: 'Change Password',
    es: 'Cambiar contraseña'
  },
  profileNameInput: {
    label: 'profileNameInput',
    en: 'Name',
    es: 'Nombre'
  },
  profileNameInputPlaceHolder: {
    label: 'profileNameInputPlaceHolder',
    en: 'Enter your name',
    es: 'Ingresa tu nombre'
  },
  profileNameInputError: {
    label: 'profileNameInputError',
    en: 'The name is required',
    es: 'El nombre es requerido'
  },
  profileNameInputErrorLength: {
    label: 'profileNameInputErrorLength',
    en: 'The name must be less than 50 characters',
    es: 'El nombre debe tener menos de 50 caracteres'
  },
  profileLastNameInput: {
    label: 'profileLastNameInput',
    en: 'First Last Name',
    es: 'Primer Apellido'
  },
  profileLastNameInputPlaceHolder: {
    label: 'profileLastNameInputPlaceHolder',
    en: 'Enter your first last name',
    es: 'Ingresa tu primer apellido'
  },
  profileLastNameInputError: {
    label: 'profileLastNameInputError',
    en: 'The first last name is required',
    es: 'El primer apellido es obligatorio'
  },
  profileLastNameInputErrorLength: {
    label: 'profileLastNameInputErrorLength',
    en: 'The first last Name must be less than 100 characters',
    es: 'El primer apellido debe tener menos de 100 caracteres'
  },
  profileMLastNameInput: {
    label: 'profileMLastNameInput',
    en: "Second last name",
    es: 'Segundo Apellido'
  },
  profileMLastNameInputPlaceHolder: {
    label: 'profileMLastNameInputPlaceHolder',
    en: "Enter your second last name",
    es: 'Ingresa tu segundo apellido'
  },
  profileMLastNameInputError: {
    label: 'profileMLastNameInputError',
    en: "The second last name is required",
    es: 'El segundo apellido es obligatorio'
  },
  profileMLastNameInputErrorLength: {
    label: 'profileMLastNameInputErrorLength',
    en: "The second last name must be less than 100 characters",
    es: 'El segundo apellido debe tener menos de 100 caracteres'
  },
  profileLandline: {
    label: 'profileLandline',
    en: "Landline",
    es: 'Teléfono fijo'
  },
  profileLandlinePlaceHolder: {
    label: 'profileLandlinePlaceHolder',
    en: "Enter your landline surname",
    es: 'Ingresa tu teléfono fijo'
  },
  profileLandlineError: {
    label: 'profileLandlineError',
    en: "Enter a valid landline",
    es: 'Ingresa un numero de teléfono válido'
  },
  profileLandlineErrorLength: {
    label: 'profileLandlineErrorLength',
    en: "The landline must be between 8 and 15 digits.",
    es: 'El Teléfono fijo debe ser entre 8 y 15 dígitos'
  },
  profileMobilePhone: {
    label: 'profileMobilePhone',
    en: "Mobile Phone",
    es: 'Teléfono móvil'
  },
  profileMobilePhonePlaceHolder: {
    label: 'profileMobilePhonePlaceHolder',
    en: "Enter your mobile phone number",
    es: 'Ingresa tu teléfono móvil materno'
  },
  profileMobilePhoneError: {
    label: 'profileMobilePhoneError',
    en: "Enter a valid phone number",
    es: 'Ingresa un numero de teléfono válido'
  },
  profileMobilePhoneErrorLength: {
    label: 'profileMobilePhoneErrorLength',
    en: "The mobile phone must be between 8 and 15 digits.",
    es: 'El teléfono fijo debe ser entre 8 y 15 dígitos'
  },
  profileEmailPlaceHolder: {
    label: 'profileEmailPlaceHolder',
    en: "Enter your email",
    es: 'Ingresa tu correo electrónico'
  },
  profileChangePwdCurrent: {
    label: 'profileChangePwdCurrent',
    en: "Current password",
    es: 'Contraseña actual'
  },
  profileChangePwdCurrentPlaceholder: {
    label: 'profileChangePwdCurrentPlaceholder',
    en: "Enter your current password",
    es: 'Ingresa tu contraseña actual'
  },
  profileChangePwdCurrentError: {
    label: 'profileChangePwdCurrentError',
    en: "The current password is required",
    es: 'La contraseña actual es requerida'
  },
  profileChangePwdCurrentErrorLengthMin: {
    label: 'profileChangePwdCurrentErrorLengthMin',
    en: 'The current password is at least 8 characters long',
    es: "La contraseña actual tiene por lo menos 8 caracteres"
  },
  profileChangePwdCurrentErrorLengthMax: {
    label: 'profileChangePwdCurrentErrorLengthMin',
    en: 'The current password has a maximum of 21 characters',
    es: "La contraseña actual tiene máximo 21 caracteres"
  },
  profileChangePwdNew: {
    label: 'profileChangePwdNew',
    en: "New password",
    es: 'Contraseña nueva'
  },
  profileChangePwdNewPlaceholder: {
    label: 'profileChangePwdNewPlaceholder',
    en: "Enter your new password",
    es: 'Ingresa tu contraseña nueva'
  },
  profileChangePwdNewError: {
    label: 'profileChangePwdNewError',
    en: "The new password is required",
    es: 'La contraseña nueva es requerida'
  },
  profileChangePwdNewErrorLowerCase: {
    label: 'profileChangePwdNewErrorLowerCase',
    en: "The new password must have at least one lower case",
    es: "La contraseña nueva debe tener por lo menos una minúscula"
  },
  profileChangePwdNewErrorUppercaseCase: {
    label: 'profileChangePwdNewErrorUppercaseCase',
    en: "The new password must have at least one capital letter",
    es: "La contraseña nueva debe tener por lo menos una mayúscula"
  },
  profileChangePwdNewErrorNumber: {
    label: 'profileChangePwdNewErrorNumber',
    en: "The new password must have at least one number",
    es: "La contraseña nueva debe tener por lo menos un número"
  },
  profileChangePwdNewErrorSymbol: {
    label: 'profileChangePwdNewErrorSymbol',
    en: 'The new password must have at least one symbol !, @, #, $, *, -',
    es: 'La contraseña nueva debe tener al menos un símbolo !, @, #, $, *, -'
  },
  profileChangePwdNewErrorMin: {
    label: 'profileChangePwdNewErrorMin',
    en: "The new password must have at least 8 characters",
    es: 'La contraseña nueva debe tener al menos 8 caracteres'
  },
  profileChangePwdConfirmation: {
    label: 'profileChangePwdConfirmation',
    en: "Repeat new password",
    es: 'Repetir contraseña nueva'
  },
  profileChangePwdConfirmationPlaceholder: {
    label: 'profileChangePwdConfirmationPlaceholder',
    en: "Repeat your new password",
    es: 'Repite tu contraseña nueva'
  },
  profileChangePwdConfirmationError: {
    label: 'profileChangePwdConfirmationError',
    en: "Confirmation is required",
    es: 'La confirmación es requerida'
  },
  profileChangePwdConfirmationErrorEqual: {
    label: 'profileChangePwdConfirmationErrorEqual',
    en: 'Passwords do not match',
    es: 'Las contraseñas no coinciden'
  },
  profileChangePwdButton: {
    label: 'profileChangePwdButton',
    en: 'Change password',
    es: 'Guardar contraseña'
  },
  addminAdd: {
    label: "addAminAdd",
    en: "Add administrator",
    es: "Agregar administrador",
  },
  adminTableHeaderName: {
    label: "adminTableHeaderName",
    en: "Name",
    es: "Nombre",
  },
  adminTableHeaderLastName: {
    label: "adminTableHeaderLastName",
    en: "Last Name",
    es: "Apellido",
  },
  adminTableHeaderEmail: {
    label: "adminTableHeaderEmail",
    en: "Email",
    es: "Correo electrónico",
  },
  adminTableHeaderPhone: {
    label: "adminTableHeaderPhone",
    en: "Phone",
    es: "Teléfono",
  },
  adminTableHeaderMobile: {
    label: "adminTableHeaderMobile",
    en: "Mobile Phone",
    es: "Teléfono móvil",
  },
  adminTableHeaderRole: {
    label: "adminTableHeaderRole",
    en: "Role",
    es: "Rol",
  },
  adminTableHeaderStatus: {
    label: "adminTableHeaderStatus",
    en: "Status",
    es: "Estado",
  },
  adminTableHeaderCreationDate: {
    label: "adminTableHeaderCreationDate",
    en: "Creation Date",
    es: "Fecha de creación",
  },
  adminTableHeaderActions: {
    label: "adminTableHeaderActions",
    en: "Actions",
    es: "Acciones",
  },
  adminSaveButtonModal: {
    label: "adminSaveButtonModal",
    en: "Add administrator",
    es: "Agregar administrador",
  },
  adminModalCreate: {
    label: "adminModalCreate",
    en: "New Administrator",
    es: "Nuevo administrador",
  },
  adminModalUpdate: {
    label: "adminModalUpdate",
    en: "Update Administrator",
    es: "Actualizar administrador",
  },
  adminUpdateSuccess: {
    label: "adminUpdateSuccess",
    en: "The administrator was updated successfully",
    es: "Se actualizó correctamente al admin",
  },
  adminCreateSuccess: {
    label: "adminCreateSuccess",
    en: "The administrator was created successfully",
    es: "Se agregó correctamente al admin",
  },
  adminCreateError: {
    label: "adminCreateError",
    en: 'There is already an administrator with this email ',
    es: 'Ya existe un administrador con este correo',
  },
  adminNamePlaceHolder: {
    label: "adminNamePlaceHolder",
    en: 'Enter the name of the administrator',
    es: 'Ingresa el nombre del administrador',
  },
  adminLasNamePlaceHolder: {
    label: "adminLasNamePlaceHolder",
    en: 'Enter the last name of the administrator',
    es: 'Ingresa el nombre del administrador',
  },
  adminMLasNamePlaceHolder: {
    label: "adminMLasNamePlaceHolder",
    en: 'Enter the second last name of the administrator',
    es: 'Ingresa el segundo apellido del administrador',
  },
  adminEmailPlaceHolder: {
    label: "adminEmailPlaceHolder",
    en: 'Enter the email of the administrator',
    es: 'Ingresa el correo electrónico del administrador',
  },
  adminPhonePlaceHolder: {
    label: "adminPhonePlaceHolder",
    en: 'Enter the phone of the administrator',
    es: 'Ingresa el teléfono fijo del administrador',
  },
  adminMobilePhonePlaceHolder: {
    label: "adminMobilePhonePlaceHolder",
    en: 'Enter the mobile phone of the administrator',
    es: 'Ingresa el teléfono móvil del administrador',
  },
  adminPasswordPlaceHolder: {
    label: "adminPasswordPlaceHolder",
    en: 'Enter the password of the administrator',
    es: 'Ingresa la contraseña del administrador',
  },
  adminConfirmPassword: {
    label: "adminConfirmPassword",
    en: 'Repeat new password',
    es: 'Repite la contraseña',
  },
  adminConfirmPasswordPlaceHolder: {
    label: "adminConfirmPasswordPlaceHolder",
    en: 'Repeat the password of the administrator',
    es: 'Repite la contraseña del administrador',
  },
  adminDeleteAlertTitle: {
    label: "adminDeleteAlertTitle",
    en: 'Do you want to delete this administrator?',
    es: '¿Deseas eliminar este administrador?',
  },
  adminDeleteAlertConfirmation: {
    label: "adminDeleteAlertConfirmation",
    en: 'Delete Admin',
    es: 'Eliminar administrador',
  },
  adminDeleteAlertSuccess: {
    label: "adminDeleteAlertSuccess",
    en: 'Administrator has been successfully removed',
    es: 'Se ha eliminado correctamente al administrador',
  },
  sessionsHeaderDateTime: {
    label: "sessionsHeaderDateTime",
    en: 'Date/Time',
    es: 'Fecha/hora',
  },
  sessionsTableHeaderTypeSession: {
    label: "sessionsTableHeaderTypeSession",
    en: 'Source',
    es: 'Origen',
  },
  sessionsTableHeaderTraining: {
    label: "sessionsTableHeaderTraining",
    en: 'Training',
    es: 'Entrenamiento',
  },
  sessionsTableHeaderMetrics: {
    label: "sessionsTableHeaderMetrics",
    en: 'Metrics',
    es: 'Métricas',
  },
  sessionsTableHeaderSensor: {
    label: "sessionsTableHeaderSensor",
    en: 'Sensor',
    es: 'Sensor',
  },
  centerTabData: {
    label: 'centerTabData',
    en: 'General Data',
    es: 'Datos Generales'
  },
  centerTabAddress: {
    label: 'centerTabAddress',
    en: 'Address',
    es: 'Dirección'
  },
  centerTabGallery: {
    label: 'centerTabGallery',
    en: 'Gallery',
    es: 'Galería'
  },
  centerGeneralDataTimezoneDescription: {
    label: 'centerGeneralDataTimezoneDescription',
    en: 'Select a time zone in the list or click an area on the map.',
    es: 'Seleccione una zona horaria en la lista o haz clic en un área en el mapa'
  },
  centerGeneralDataMetrics: {
    label: 'centerGeneralDataMetrics',
    en: 'Measurement system',
    es: 'Sistema de medidas'
  },
  centerGeneralDataMetricsTypes: [
    {
      label: 'metric_system',
      en: "Metric system (kg/m)",
      es: 'Sistema Métrico Decimal (kg/m)',
    },
    {
      label: 'english_system',
      en: "English system (lib/in)",
      es: 'Sistema inglés (lib/in)',
    },
  ],
  centerGeneralDataCenterName: {
    label: 'centerGeneralDataCenterName',
    en: 'Name of the center',
    es: 'Nombre del centro'
  },
  centerGeneralDataCenterTimezone: {
    label: 'centerGeneralDataCenterTimezone',
    en: 'Timezone (UTC)',
    es: 'Zona Horaria (UTC)'
  },
  centerGeneralDataCenterBranch: {
    label: 'centerGeneralDataCenterBranch',
    en: 'Name of the branch',
    es: 'Nombre de la sucursal'
  },
  centerGeneralDataCenterBranchPlaceholder: {
    label: 'centerGeneralDataCenterBranchPlaceholder',
    en: 'Enter the name of the branch',
    es: 'Ingresa el nombre de la sucursal'
  },
  centerGeneralDataCenterLanguaje: {
    label: 'centerGeneralDataCenterLanguage',
    en: 'Language',
    es: 'Idioma'
  },
  centerGeneralDataLanguaje: [
    {
      label: '1',
      en: 'English',
      es: 'Ingles',
    },
    {
      label: '2',
      en: 'Spanish',
      es: 'Español',
    },
  ],
  centerGeneralDataCenterDescription: {
    label: 'centerGeneralDataCenterDescription',
    en: 'Description',
    es: 'Descripción'
  },
  centerGeneralDataCenterDescriptionPH: {
    label: 'centerGeneralDataCenterDescriptionPH',
    en: 'Enter the description of the branch',
    es: 'Ingresa la descripción de la sucursal'
  },
  centerAddressBranch: {
    label: 'centerAddressBranch',
    en: 'Address of the branch',
    es: 'Dirección de la sucursal'
  },
  centerAddressBranchError: {
    label: 'centerAddressBranchError',
    en: 'Your address was not found, try to be more precise with the address',
    es: 'No se encontró tu dirección, intenta ser más preciso con la dirección'
  },
  centerAddressBranchLegend: {
    label: 'centerAddressBranchLegend',
    en: 'You can drag the marker on the map to have greater precision in the direction, remember that at the end you must click save to apply your changes.',
    es: 'Puedes arrastrar el marcador en el mapa para tener una mayor precisión en la dirección, recuerda que al finalizar debes hacer clic en guardar para aplicar tus cambios.'
  },
  centerGalleryConfirmation: {
    label: 'centerGalleryConfirmation',
    en: 'Are you sure you want to delete this image?',
    es: '¿Seguro de eliminar esta imagen?'
  },
  centerGalleryAdd: {
    label: 'centerGalleryAdd',
    en: 'Add Image',
    es: 'Agregar Imagen'
  },
  centerAddressUpdatedSuccessfully: {
    label: 'centerAddressUpdatedSuccessfully',
    en: 'The address was updated successfully',
    es: 'La dirección se actualizó correctamente'
  },
  centerImageAddedSuccessfully: {
    label: 'centerImageAddedSuccessfully',
    en: 'Image was added successfully',
    es: 'Se agregó la imagen correctamente'
  },
  centerImageDeletedSuccessfully: {
    label: 'centerImageDeletedSuccessfully',
    en: 'Image was deleted successfully',
    es: 'Se eliminó la imagen correctamente'
  },
  centerInfoUpdatedSuccessfully: {
    label: 'centerInfoUpdatedSuccessfully',
    en: 'The center data was updated successfully',
    es: 'Los datos del centro se actualizó correctamente'
  },
  requestsAcceptAll: {
    label: 'requestsAcceptAll',
    en: 'Accept all the requests',
    es: 'Aceptar todas las solicitudes'
  },
  requestsRefuseAll: {
    label: 'requestsRefuseAll',
    en: 'Reject all requests',
    es: 'Rechazar todas las solicitudes'
  },
  requestsLastUpdate: {
    label: 'requestsLastUpdate',
    en: 'Last update',
    es: 'Última Actualización'
  },
  requestsOneAccepted: {
    label: 'requestsOneAccepted',
    en: 'The request was successfully approved',
    es: 'La solicitud se aprobó correctamente'
  },
  requestsBunchAccepted: {
    label: 'requestsBunchAccepted',
    en: 'All applications were accepted',
    es: 'Se aceptaron todas las solicitudes'
  },
  requestsOneRejected: {
    label: 'requestsOneRejected',
    en: 'The request was successfully rejected',
    es: 'La solicitud fue rechazada con éxito'
  },
  requestsBunchRejected: {
    label: 'requestsBunchRejected',
    en: 'All applications were rejected',
    es: 'Todas las solicitudes fueron rechazadas'
  },
  requestsAcceptToolTip: {
    label: 'requestsAcceptToolTip',
    en: 'Accept request',
    es: 'Aceptar Solicitud'
  },
  requestsRejectToolTip: {
    label: 'requestsRejectToolTip',
    en: 'Reject request',
    es: 'Rechazar Solicitud'
  },
  daqLocalIP: {
    label: 'daqLocalIP',
    en: 'Local IP',
    es: 'IP Local'
  },
  daqRemoteConection: {
    label: 'daqRemoteConnection',
    en: 'Remote connection',
    es: 'Conexión remota'
  },
  remoteEmpty: {
    label: 'remoteEmpty',
    en: 'You do not have rooms created yet',
    es: 'Aún no tienes salas creadas'
  },
  remoteConnection: {
    label: 'remoteConnection',
    en: 'Go to session',
    es: 'Ir a la sesión'
  },
  remoteActiveConnection: {
    label: 'remoteActiveConnection',
    en: 'There is an active session',
    es: 'Hay una sesión activa'
  },
  remoteAddRoom: {
    label: 'remoteAddRoom',
    en: 'Create Room',
    es: 'Crear Sala'
  },
  remoteEditRoom: {
    label: 'remoteEditRoom',
    en: 'Edit Room',
    es: 'Editar Sala'
  },
  remoteModalName: {
    label: 'remoteModalName',
    en: 'Room\'s name',
    es: 'Nombre de la sala'
  },
  remoteModalNamePlaceholder: {
    label: 'remoteModalNamePlaceholder',
    en: 'Enter the room\'s name',
    es: 'Escribe el nombre de la sala'
  },
  remoteModalCoach: {
    label: 'remoteModalCoach',
    en: 'Choose a coach',
    es: 'Elegir un entrenador'
  },
  remoteModalAadd: {
    label: 'remoteModalAad',
    en: 'Add room',
    es: 'Agregar sala'
  },
  remoteRemoveRoom: {
    label: 'remoteRemoveRoom',
    en: 'Are you sure you want to delete this room?',
    es: '¿Seguro que deseas eliminar esta sala?'
  },
  remoteRemoveRoomDescription: {
    label: 'remoteRemoveRoomDescription',
    en: 'If you delete this room, users trainings are not deleted',
    es: 'Si eliminas esta sala, no se borran los entrenamientos de los usuarios'
  },
  rolesAddNewRole: {
    label: 'rolesAddNewRole',
    en: 'Add new role',
    es: 'Agregar nuevo rol'
  },
  legendModalTitle: {
    label: 'legendModalTitle',
    en: 'Edit RookLegend',
    es: 'Editar RookLegend'
  },
  legendModalPlaceholder: {
    label: 'legendModalPlaceholder',
    en: 'Enter the name of your RookLegend',
    es: 'Ingresa el nombre de tu RookLegend'
  },
  legendModalInput: {
    label: 'legendModalInput',
    en: 'Name of yours RookLegend',
    es: 'Nombre de tu RookLegend'
  },
  legendUpdated: {
    label: 'legendUpdated',
    en: 'The RookLegend was updated successfully',
    es: 'El nombre de tu RookLegend se actualizó correctamente'
  },
  legendErrorName: {
    label: 'legendErrorName',
    en: 'The name is required',
    es: 'El nombre es requerido'
  },
  legendErrorNameMin: {
    label: 'legendErrorNameMin',
    en: 'Enter at least 4 characters',
    es: 'Ingresa al menos 4 caracteres'
  },
  legendErrorNameMax: {
    label: 'legendErrorNameMax',
    en: 'The name must not be longer than 100 characters',
    es: 'El nombre no debe tener más de 100 caracteres'
  },
  roomCreatedSuccessfully: {
    label: 'roomCreatedSuccessfully',
    en: 'The room was created successfully',
    es: 'Se creó la sala correctamente'
  },
  roomCreatedError: {
    label: 'roomCreatedError',
    en: 'A room with that name already exists',
    es: 'Ya existe una sala con ese nombre'
  },
  roomUpdatedSuccessFully: {
    label: 'roomUpdatedSuccessFully',
    en: 'The room was updated successfully',
    es: 'La sala se actualizó correctamente'
  },
  roomNameValidation: {
    label: 'roomNameValidation',
    en: 'The name of room is required',
    es: 'El nombre del room es requerido'
  },
  roomNameValidationMin: {
    label: 'roomNameValidation',
    en: 'The room name must be at least 4 characters long',
    es: 'El nombre del room debe tener al menos 4 caracteres'
  },
  roomNameValidationMax: {
    label: 'roomNameValidation',
    en: 'The room name must have a maximum of 15 characters',
    es: 'El del room nombre debe tener máximo 15 caracteres'
  },
  roomDescriptionValidation: {
    label: 'roomDescriptionValidation',
    en: 'The description of room is required',
    es: 'La descripción del room es requerido'
  },
  roomDescriptionValidationLength: {
    label: 'roomDescriptionValidationLength',
    en: 'Description must be between 5 to 100 characters',
    es: 'La descripción debe tener entre 5 a 100 caracteres'
  },
  roomCoachValidation: {
    label: 'roomCoachValidation',
    en: 'The coach is required',
    es: 'El entrenador es requerido'
  },
  roomDeletedSuccessfully: {
    label: 'roomDeletedSuccessfully',
    en: 'The room was deleted successfully',
    es: 'La sala ha sido eliminada correctamente'
  },
  recoveryTitle: {
    label: 'recoveryTitle',
    en: 'Forgot your password?',
    es: '¿Olvidaste la contraseña?'
  },
  recoverySubTitle: {
    label: 'recoverySubTitle',
    en: 'Don\'t worry, you can reset it here',
    es: 'No te preocupes, puedes restablecerla aquí'
  },
  recoveryInstruction: {
    label: 'recoveryInstruction',
    en: 'Write your email and then we will send you the instructions to create a new password. Make sure to enter the same email account that you used to register.',
    es: 'Escribe tu email y continuación te enviaremos las instrucciones para crear una nueva contraseña. Asegúrate de introducir la misma cuenta de email que usaste para registrarte.'
  },
  recoveryInstructionSend: {
    label: 'recoveryInstructionSend',
    en: 'Send instructions',
    es: 'Enviar instrucciones'
  },
  recoveryGetNewPassword: {
    label: 'recoveryGetNewPassword',
    en: 'Recover password',
    es: 'Recuperar contraseña'
  },
  recoveryAlertTitle: {
    label: 'recoveryAlertTitle',
    en: 'Excellent!',
    es: '¡Excelente!'
  },
  recoveryAlertDescription: {
    label: 'recoveryAlertDescription',
    en: 'We have sent a message to your email so you can reset your password',
    es: 'Hemos enviado un mensaje a tu correo para que puedas restablecer tu contraseña'
  },
  recoveryAlertError: {
    label: 'recoveryAlertError',
    en: 'Make sure you have entered the email correctly',
    es: 'Asegurate de haber ingresado correctamente el correo electrónico'
  },
  wTitle: {
    label: 'title',
    en: 'Welcome to RookRemote',
    es: 'Te damos la bienvenida a RookRemote',
  },
  wDescription: {
    label: 'description',
    en: 'The platform where you can see in real time the progress and intensity zones of users during training',
    es: 'La plataforma donde puedes ver en tiempo real los avances y las zonas de intensidad de los usuarios durante el entrenamiento',
  },
  wZones: {
    label: 'zones',
    en: 'Intensity zones',
    es: 'Zonas de intensidad',
  },
  wExaple: {
    label: 'example',
    en: 'Effort and performance',
    es: 'Esfuerzo y rendimiento',
  },
  wConfiguration: {
    label: 'configuration',
    en: 'Before starting, configure the room, selecting the type of training and its duration',
    es: 'Antes de iniciar, configura el room, seleccionando el tipo de entrenamiento y su duración',
  },
  wConfigurationQR: {
    label: 'wConfigurationQR',
    en: 'See qr',
    es: 'Ver QR',
  },
  wConfigurationQRTitle: {
    label: 'wConfigurationQRTitle',
    en: 'QR Code',
    es: 'Código Qr',
  },
  wConfigurationTitle: {
    label: 'wConfigurationTitle',
    en: 'Configure Room',
    es: 'Configurar Sala',
  },
  wBullets: [
    {
      label: 'wBullet1',
      en: 'Percentage',
      es: 'Porcentaje',
    },
    {
      label: 'wBullet2',
      en: 'Heart Rate',
      es: 'Frecuencia cardíaca',
    },
    {
      label: 'wBullet3',
      en: 'Calories',
      es: 'Calorías',
    },
    {
      label: 'wBullet5',
      en: 'Jumps',
      es: 'Saltos',
    },
  ],
  wrTitle: {
    label: 'wrTitle',
    en: 'There are not users in the class yet',
    es: 'Todavía no hay usuarios en la clase',
  },
  wrTitleEnd: {
    label: 'wrTitleEnd',
    en: 'The class has finished',
    es: 'La clase ha finalizado',
  },
  wConfigureButton: {
    label: 'wConfigureButton',
    en: 'Configure Room',
    es: 'Configurar Sala',
  },
  wConfigureTrainingType: {
    label: 'wConfigureTrainingType',
    en: 'Training Type',
    es: 'Tipo de entrenamiento',
  },
  wConfigureTrainingDuration: {
    label: 'wConfigureTrainingDuration',
    en: 'Duration of training',
    es: 'Duración del entrenamiento',
  },
  wConfigureTrainingCapacity: {
    label: 'wConfigureTrainingCapacity',
    en: 'Users capacity',
    es: 'Capacidad de usuarios',
  },
  wConfigureTrainingLimited: {
    label: 'wConfigureTrainingLimited',
    en: 'Limited',
    es: 'Limitado',
  },
  wConfigureTrainingUnlimited: {
    label: 'wConfigureTrainingUnlimited',
    en: 'Unlimited',
    es: 'Ilimitado',
  },
  wConfigureTrainingDelay: {
    label: 'wConfigureTrainingDelay',
    en: 'Wait time',
    es: 'Tiempo de espera',
  },
  wConfigureTrainingSorted: {
    label: 'wConfigureTrainingSorted',
    en: 'Sort by',
    es: 'Ordenar por',
  },
  wConfigureTrainingTypeError: {
    label: 'wConfigureTrainingTypeError',
    en: 'The training type is required',
    es: 'El tipo de entrenamiento es requerido',
  },
  wConfigureTrainingDurarionError: {
    label: 'wConfigureTrainingDurarionError',
    en: 'The training duration is required',
    es: 'La duración del entrenamiento es requerida',
  },
  wConfigureTrainingDurarionValid: {
    label: 'wConfigureTrainingDurarionValid',
    en: 'Enter a valid duration, greater than 1 minute',
    es: 'Ingresa una duración válida, mayor que 1 minuto',
  },
  wConfigureTrainingDurarionValidMax: {
    label: 'wConfigureTrainingDurarionValidMax',
    en: 'The duration must be less than 600 minutes',
    es: 'Ingresa una duración válida, mayor que 600 minutos',
  },
  wConfigureTrainingDelayError: {
    label: 'wConfigureTrainingDelayError',
    en: 'The waiting time is required',
    es: 'El tiempo de espera es requerida',
  },
  wConfigureTrainingDelayValid: {
    label: 'wConfigureTrainingDelayValid',
    en: 'The waiting time must be between 1 and 10 minutes',
    es: 'El tiempo de espera debe ser entre 1 a 10 minutos',
  },
  wConfigureTrainingCapacityError: {
    label: 'wConfigureTrainingCapacityError',
    en: 'Capacity must be between 1 and 100',
    es: 'La capacidad debe ser entre 1 a 100',
  },
  wConfigureOrderCalories: {
    label: 'calories',
    en: 'Calories',
    es: 'Calorías',
  },
  wConfigureOrderEffort: {
    label: 'effort',
    en: 'Effort',
    es: 'Esfuerzo',
  },
  wConfigureOrderSteps: {
    label: 'jumps',
    en: 'Steps',
    es: 'Pasos',
  },
  wConfigureOrderJumps: {
    label: 'jumps',
    en: 'Jumps',
    es: 'Saltos',
  },
  wWaitingRoom: {
    label: 'wWaitingRoom',
    en: 'the class starts in:',
    es: 'La clase inicia en:',
  },
  wConfigureStreaming: {
    label: 'wConfigureStreaming',
    en: 'Enable video streaming',
    es: 'Habilitar video en vivo',
  },
  spTitle: {
    label: 'spTitle',
    en: 'We are preparing',
    es: 'Nos estamos preparando',
  },
  clUserDisconnected: {
    label: 'clUserDisconnected',
    en: 'User disconnected',
    es: 'Usuario desconectado',
  },
  clModalTitle: {
    label: 'clModalTitle',
    en: 'Delete user',
    es: 'Eliminar usuario',
  },
  clModalDescription: {
    label: 'clModalDescription',
    en: 'Are you sure you want to delete',
    es: '¿Seguro que deseas eliminar a ',
  },
  clModalOptionOne: {
    label: 'clModalOptionOne',
    en: 'Remove only from this class (24 hrs)',
    es: 'Quitar solo de esta clase (24 hrs)',
  },
  clModalOptionEver: {
    label: 'clModalOptionEver',
    en: 'Permanently remove from this room',
    es: 'Eliminarlo permanentemente de esta sala',
  },
  clModalAction: {
    label: 'clModalAction',
    en: 'Choose an option',
    es: 'Elige una opción',
  },
  confirmationStop: {
    label: 'confirmationStop',
    en: 'Do you want to end the session?',
    es: '¿Quieres finalizar la sesión?',
  },
  trainingToolTip: {
    label: 'trainingToolTip',
    en: 'See session statistics',
    es: 'Ver estadísticas de la sesión',
  },
  trainingDetailTime: {
    label: 'trainingDetailTime',
    en: 'Date / Time',
    es: 'Fecha / Hora',
  },
  trainingDetailTypeTraining: {
    label: 'trainingDetailTypeTraining',
    en: 'Training Type',
    es: 'Tipo de entrenamiento',
  },
  trainingDetailDuration: {
    label: 'trainingDetailDuration',
    en: 'Duration',
    es: 'Duración',
  },
  trainingDetailTypeSession: {
    label: 'trainingDetailTypeSession',
    en: 'Training Type',
    es: 'Tipo de entrenamiento',
  },
  trainingEffortMinutes: {
    label: 'trainingEffortMinutes',
    en: 'Heart rate intensity zones (time minutes)',
    es: 'Zonas de intensidad de frecuencia cardíaca (tiempo minutos)',
  },
  trainingTypeTrainingMinutes: {
    label: 'trainingTypeTrainingMinutes',
    en: 'Type of training (time minutes)',
    es: 'Tipo de entrenamiento (tiempo minutos)',
  },
  trainingHeartRate: {
    label: 'trainingHeartRate',
    en: 'Heart Rate',
    es: 'Frecuencia cardíaca',
  },
  trainingCalories: {
    label: 'trainingCalories',
    en: 'Calories',
    es: 'Calorías',
  },
  supporInfo: {
    label: 'supporInfo',
    en: 'Learn more about this module',
    es: 'Aprender más de este módulo',
  },
  userStatisticsCalories: {
    label: 'userStatisticsCalories',
    en: 'Total Calories for the period',
    es: 'Calorías Totales del periodo',
  },
  userStatisticsTime: {
    label: 'userStatisticsTime',
    en: 'Training hours',
    es: 'Horas de entrenamiento',
  },
  userStatisticsTraining: {
    label: 'userStatisticsTraining',
    en: 'Period trainings',
    es: 'Entrenamientos del período',
  },
  userStatisticsTrainingLabel: {
    label: 'userStatisticsTrainingLabel',
    en: 'trainings',
    es: 'entrenamientos',
  },
  userStatisticsPoints: {
    label: 'userStatisticsPoints',
    en: 'RookPoints',
    es: 'RookPoints',
  },
  userStatisticsSteps: {
    label: 'userStatisticsSteps',
    en: 'Steps/Jumps',
    es: 'Pasos/Saltos',
  },
  userStatisticsMinutes: {
    label: 'userStatisticsMinutes',
    en: 'Heart rate intensity zones',
    es: 'Zonas de intensidad de frecuencia cardíaca',
  },
  userStatisticsTypeTraining: {
    label: 'userStatisticsTypeTraining',
    en: 'Training Type',
    es: 'Tipos de entrenamiento',
  },
  userStatisticsWeigth: {
    label: 'userStatisticsWeight',
    en: 'Historical Weight',
    es: 'Peso Histórico',
  },
  sensorTableHeaderSensor: {
    label: 'sensorTableHeaderSensor',
    en: 'Sensor',
    es: 'Sensor',
  },
  sensorTableHeaderIDSensor: {
    label: 'sensorTableHeaderIDSensor',
    en: 'Center ID',
    es: 'ID del centro',
  },
  sensorTableHeaderOwnership: {
    label: 'sensorTableHeaderOwnership',
    en: 'Type',
    es: 'Tipo',
  },
  reportsSessionsHistory: {
    label: 'reportsSessionsHistory',
    en: 'Training history',
    es: 'Historial de entrenamientos',
  },
  reportsTypeTraining: {
    label: 'reportsTypeTraining',
    en: 'Type training',
    es: 'Tipo de entrenamiento',
  },
  reportsUsersRanking: {
    label: 'reportsUsersRanking',
    en: 'User Rating',
    es: 'Clasificación de usuarios',
  },
  reportsTotalSessions: {
    label: 'reportsTotalSessions',
    en: 'Total trainings',
    es: 'Entrenamientos totales',
  },
  contractTitle: {
    label: 'contractTitle',
    en: 'Framework contract',
    es: 'Contrato de prestación de servicios',
  },
  contractDescription: {
    label: 'contractDescription',
    en: 'Thank you for joining the #RookMotion community, we are glad you are here. To continue with the experience, we ask you to read and accept the framework contract.',
    es: 'Gracias por unirte a la comunidad de #RookMotion, nos alegra que estes aquí. Para continuar con la experiencia, te pedimos leer y aceptar el contrato de prestación de servicios.',
  },
  contractAgree: {
    label: 'contractAgree',
    en: 'I agree',
    es: 'Estoy de acuerdo',
  },
  contractLink: {
    label: 'contractLink',
    en: 'Framework contract.',
    es: 'Contrato de prestación de servicios.',
  },
  contractPrivacy: {
    label: 'contractPrivacy',
    en: 'Privacy policies',
    es: 'Las políticas de privacidad',
  },
  contractConditions: {
    label: 'contractConditions',
    en: 'Terms and Conditions',
    es: 'Términos y Condiciones',
  },
  contractConditionsSuccess: {
    label: 'contractConditionsSuccess',
    en: 'The agreement has been accepted successfully',
    es: 'Los acuerdos han sido aceptados correctamente',
  },
  gaming: {
    label: 'gaming',
    en: 'Challenges',
    es: 'Retos',
  },
  gamingStatsChallenges: {
    label: 'gamingStatsChallenges',
    en: 'Challenges created',
    es: 'Retos creados',
  },
  gamingStatsChallengesCreated: {
    label: 'gamingStatsChallengesCreated',
    en: 'Created',
    es: 'Creados',
  },
  gamingStatsChallengesActive: {
    label: 'gamingStatsChallengesActive',
    en: 'Active',
    es: 'Activos',
  },
  gamingStatsGoals: {
    label: 'gamingStatsGoals',
    en: 'Goals',
    es: 'Objetivos',
  },
  gamingStatsGoalsCompleted: {
    label: 'gamingStatsGoalsCompleted',
    en: 'Active',
    es: 'Activos',
  },
  gamingStatsParticipants: {
    label: 'gamingStatsParticipants',
    en: 'users participating',
    es: 'Usuarios Participando',
  },
  gamingStatsParticipantsGroups: {
    label: 'gamingStatsParticipantsGroups',
    en: 'Group',
    es: 'Grupales',
  },
  gamingStatsParticipantsIndividual: {
    label: 'gamingStatsParticipantsIndividual',
    en: 'Individual',
    es: 'Individuales',
  },
  gamingCreateChallenge: {
    label: 'gamingCreateChallenge',
    en: 'Create Challenge',
    es: 'Crear Reto',
  },
  gamingEditChallenge: {
    label: 'gamingEditChallenge',
    en: 'Edit Challenge',
    es: 'Editar Reto',
  },
  gamingListTitle: {
    label: 'gamingListTitle',
    en: 'Challenges',
    es: 'Retos',
  },
  gamingListParticipants: {
    label: 'gamingListParticipants',
    en: 'Participants',
    es: 'Participantes',
  },
  gamingListType: {
    label: 'gamingListType',
    en: 'Type',
    es: 'Tipo',
  },
  gamingListModality: {
    label: 'gamingListModality',
    en: 'Modality',
    es: 'Modalidad',
  },
  gamingListEndDate: {
    label: 'gamingListEndDate',
    en: 'Ending',
    es: 'Fin',
  },
  gamingListStartDate: {
    label: 'gamingListStartDate',
    en: 'Starting',
    es: 'Inicio',
  },
  gamingListToolTipoViewGroup: {
    label: 'gamingListToolTipoViewGroup',
    en: 'View Group',
    es: 'Ver Grupo',
  },
  gamingListToolTipoViewGoals: {
    label: 'gamingListToolTipoViewGoals',
    en: 'View Goals',
    es: 'Ver Objetivos',
  },
  gamingListCreateChallenge: {
    label: 'gamingListCreateChallenge',
    en: 'Create Challenge',
    es: 'Crear reto',
  },
  gamingTeamMembers: {
    label: 'gamingTeamMembers',
    en: 'See members',
    es: 'Ver integrantes',
  },
  gamingTeamTitle: {
    label: 'gamingTeamTitle',
    en: 'Completed Goals',
    es: 'Objetivos completados',
  },
  gamingGoalCreateModalTitle: {
    label: 'gamingGoalCreateModalTitle',
    en: 'Create Goal',
    es: 'Crear objetivo',
  },
  gamingGoalModalTypeGoal: {
    label: 'gamingGoalModalTypeGoal',
    en: 'Goal Type',
    es: 'Tipo de Objetivo',
  },
  gamingGoalModalGoal: {
    label: 'gamingGoalModalGoal',
    en: 'Goal target',
    es: 'Meta',
  },
  gamingGoalModalPoints: {
    label: 'gamingGoalModalPoints',
    en: 'Points',
    es: 'Puntos',
  },
  gamingGoalModalCreate: {
    label: 'gamingGoalModalCreate',
    en: 'Create Goal',
    es: 'Crear objetivo',
  },
  gamingGoalModalName: {
    label: 'gamingGoalModalName',
    en: 'Challenge name',
    es: 'Nombre del reto',
  },
  gamingGoalModalDescription: {
    label: 'gamingGoalModalDescription',
    en: 'Challenge description',
    es: 'Descripción del reto',
  },
  gamingGoalModalType: {
    label: 'gamingGoalModalType',
    en: 'Challenge type',
    es: 'Tipo de reto',
  },
  gamingGoalModalModality: {
    label: 'gamingGoalModalModality',
    en: 'Modality',
    es: 'Modalidad',
  },
  gamingGoalModalStartingDate: {
    label: 'gamingGoalModalStartingDate',
    en: 'Starting date',
    es: 'Fecha de inicio',
  },
  gamingGoalModalEndingDate: {
    label: 'gamingGoalModalEndingDate',
    en: 'Ending date',
    es: 'Fecha de fin',
  },
  gamingGoalModalOptionInd: {
    label: 'gamingGoalModalOptionInd',
    en: 'Individual',
    es: 'Individual',
  },
  gamingGoalModalOptionTeams: {
    label: 'gamingGoalModalOptionTeams',
    en: 'Groups',
    es: 'Grupal',
  },
  gamingGoalModalOptionUsers: {
    label: 'gamingGoalModalOptionUsers',
    en: 'Per users',
    es: 'Por usuarios',
  },
  gamingGoalModalOptionRooms: {
    label: 'gamingGoalModalOptionRooms',
    en: 'Per rooms',
    es: 'Por salas',
  },
  gamingGoalModalOptionTeamsM: {
    label: 'gamingGoalModalOptionTeamsM',
    en: 'Per teams',
    es: 'Por equipos',
  },
  gamingGoalModalSameLevel: {
    label: 'gamingGoalModalSameLevel',
    en: 'Create a goal with a different target',
    es: 'Crea un objetivo con una meta diferente',
  },
  gamingGoalModalNameRequired: {
    label: 'gamingGoalModalNameRequired',
    en: 'The name of the challenge is required',
    es: 'El nombre del reto es requerido',
  },
  gamingGoalModalNameLength: {
    label: 'gamingGoalModalNameLength',
    en: 'The name must be between 1 and 50 characters',
    es: 'El nombre del reto debe tener entre 1 a 50 caracteres',
  },
  gamingGoalModalChallengeTypeRequired: {
    label: 'gamingGoalModalChallengeTypeRequired',
    en: 'The challenge type is required',
    es: 'El tipo de reto es requerido',
  },
  gamingGoalModalChallengeStartDate: {
    label: 'gamingGoalModalChallengeStartDate',
    en: 'Enter a valid date',
    es: 'Ingresa una fecha de inicio válida',
  },
  gamingGoalModalChallengeEndDate: {
    label: 'gamingGoalModalChallengeEndDate',
    en: 'Enter a valid date',
    es: 'Ingresa una fecha de fin válida',
  },
  gamingGoalModalChallengeEndBigger: {
    label: 'gamingGoalModalChallengeEndBigger',
    en: 'The finish date must be bigger than the starting date',
    es: 'La fecha de finalización debe ser mayor que la fecha de inicio.',
  },
  gamingGoalModalChallengeDescription: {
    label: 'gamingGoalModalChallengeDescription',
    en: 'The description must have at least one character',
    es: 'La descripción debe tener al menos un carácter',
  },
  gamingGoalModalChallengeDescriptionMax: {
    label: 'gamingGoalModalChallengeDescriptionMax',
    en: 'The description must have a maximum of 150 characters',
    es: 'La descripción debe tener al máximo 150 caracteres',
  },
  gamingGoalModalChallengeConfigUsersLabel: {
    label: 'gamingGoalModalChallengeConfigUsersLabel',
    en: 'Select users',
    es: 'Selecciona usuarios',
  },
  gamingGoalModalChallengeConfigRoomsLabel: {
    label: 'gamingGoalModalChallengeConfigRoomsLabel',
    en: 'Select rooms',
    es: 'Selecciona salas',
  },
  gamingGoalModalChallengeConfigCheckAllUsers: {
    label: 'gamingGoalModalChallengeConfigCheckAllUsers',
    en: 'Select all users',
    es: 'Elegir todos los usuarios',
  },
  gamingGoalModalChallengeConfigCheckAllRooms: {
    label: 'gamingGoalModalChallengeConfigCheckAllRooms',
    en: 'Select all rooms',
    es: 'Elegir todos las salas',
  },
  gamingGoalModalChallengeConfigNoOptions: {
    label: 'gamingGoalModalChallengeConfigNoOptions',
    en: 'No options',
    es: 'No hay opciones',
  },
  gamingGoalModalChallengeConfigRandomTeams: {
    label: 'gamingGoalModalChallengeConfigRandomTeams',
    en: 'Random',
    es: 'Aleatorio',
  },
  gamingGoalModalChallengeConfigRandomTeamsDesc: {
    label: 'gamingGoalModalChallengeConfigRandomTeamsDesc',
    en: 'By clicking, the participants will be designated equally in each group',
    es: 'Al dar click se designarán a los participantes de manera equitativa en cada grupo',
  },
  gamingGoalModalChallengeComplianceTPTitle: {
    label: 'gamingGoalModalChallengeConfigComplianceTPTitle',
    en: 'Choose between the two types of challenge compliance:',
    es: 'Elija entre los dos tipos de cumplimiento de retos:',
  },
  gamingGoalModalChallengeComplianceTPList1: {
    label: 'gamingGoalModalChallengeComplianceTPList1',
    en: 'Competitive: The first to complete their objectives wins',
    es: 'Competitivo: El primero en culminar sus objetivos, gana',
  },
  gamingGoalModalChallengeComplianceTPList2: {
    label: 'gamingGoalModalChallengeComplianceTPList2',
    en: 'Free: The one who accumulates the highest score through their metrics wins',
    es: 'Libre: El que acumula mayor puntaje a traves de sus métricas, gana',
  },
  gamingDeleteChallengeTitleConfirm: {
    label: 'gamingDeleteChallengeTitleConfirm',
    en: 'Are you sure to eliminate the challenge?',
    es: '¿Estás seguro de eliminar el reto?',
  },
  gamingDeleteChallengeContentConfirm: {
    label: 'gamingDeleteChallengeContentConfirm',
    en: 'Once you delete it you will lose the information generated',
    es: 'Una vez que lo elimines perderás la información generada',
  },
  gamingDeleteChallengeConfirm: {
    label: 'gamingDeleteChallengeConfirm',
    en: 'The challenge was deleted successfully',
    es: 'El reto se eliminó correctamente',
  },
  gamingDeleteChallengeError: {
    label: 'gamingDeleteChallengeError',
    en: 'The challenge was not removed, please try again later',
    es: 'El reto no se eliminó, intenta más tarde',
  },
  gamingGoalModalChallengeUsers: {
    label: 'gamingGoalModalChallengeUsers',
    en: 'Select at least two users',
    es: 'Selecciona al menos dos usuarios',
  },
  gamingGoalModalChallengeRoom: {
    label: 'gamingGoalModalChallengeRoom',
    en: 'Select a room',
    es: 'Selecciona una sala',
  },
  gamingGoalModalChallengeRoom2Rooms: {
    label: 'gamingGoalModalChallengeRoom2Rooms',
    en: 'Select at least two rooms',
    es: 'Selecciona al menos dos salas',
  },
  gamingGoalModalChallengeTeams: {
    label: 'gamingGoalModalChallengeTeams',
    en: 'Add users according to the number of teams',
    es: 'Agrega usuarios de acuerdo al número de equipos',
  },
  gamingGoalModalChallengeTimeZone: {
    label: 'gamingGoalModalChallengeTimeZone',
    en: 'The challenge will be created according your timezone',
    es: 'El reto se creará de acuerdo a tu zona horaria',
  },
  gamingChallengeCreatedSuccess: {
    label: 'gamingChallengeCreatedSuccess',
    en: 'The challenge was created successfully',
    es: 'El reto se creó  satisfactoriamente',
  },
  gamingChallengeUpdatedSuccessfully: {
    label: 'gamingChallengeUpdatedSuccessfully',
    en: 'The challenge was updated successfully',
    es: 'El reto se editó satisfactoriamente',
  },
  gamingChallengeCheckGoals: {
    label: 'gamingChallengeCheckGoals',
    en: 'View goals',
    es: 'Ver objetivos',
  },
  gamingGoalListToolTipDelete: {
    label: 'gamingGoalListToolTipDelete',
    en: 'Delete goal',
    es: 'Eliminar objetivo',
  },
  gamingGoalListToolTipEdit: {
    label: 'gamingGoalListToolTipEdit',
    en: 'Edit goal',
    es: 'Editar objetivo',
  },
  gamingChallengeGoalGoalTypeHeader: {
    label: 'gamingChallengeGoalGoalTypeHeader',
    en: 'Goal Type',
    es: 'Tipo de objetivo',
  },
  gamingChallengeGoalGoalHeader: {
    label: 'gamingChallengeGoalGoalHeader',
    en: 'Goal Target',
    es: 'Meta',
  },
  gamingChallengeGoalRewardHeader: {
    label: 'gamingChallengeGoalRewardHeader',
    en: 'Points',
    es: 'Puntos',
  },
  gamingChallengeGoalStatusSuccess: {
    label: 'gamingChallengeGoalStatusSuccess',
    en: 'The status of the goal was updated successfully',
    es: 'El estado del objetivo se actualizó correctamente',
  },
  gamingChallengeGoalCreate: {
    label: 'gamingChallengeGoalCreate',
    en: 'Create goal',
    es: 'Añadir objetivo',
  },
  gamingChallengeGoalEdit: {
    label: 'gamingChallengeGoalEdit',
    en: 'Edit goal',
    es: 'Editar objetivo',
  },
  gamingChallengeGoalModalGoalTypeRequired: {
    label: 'gamingChallengeGoalModalGoalTypeRequired',
    en: 'The goal type is required',
    es: 'El tipo de objetivo es requerido',
  },
  gamingChallengeGoalModalGoalRequired: {
    label: 'gamingChallengeGoalModalGoalRequired',
    en: 'The goal target is required',
    es: 'La meta es requerida',
  },
  gamingChallengeGoalModalGoalValid: {
    label: 'gamingChallengeGoalModalGoalValid',
    en: 'Enter a valid goal target',
    es: 'Ingresa una meta válida',
  },
  gamingChallengeGoalModalPointsRequired: {
    label: 'gamingChallengeGoalModalPointsRequired',
    en: 'The points are required',
    es: 'Los puntos son requeridos',
  },
  gamingChallengeGoalModalPointsValid: {
    label: 'gamingChallengeGoalModalPointsValid',
    en: 'Enter valid points',
    es: 'Ingresa un puntaje válido',
  },
  gamingChallengeGoalCreatedSuccessfully: {
    label: 'gamingChallengeGoalCreatedSuccessfully',
    en: 'The goal was created successfully',
    es: 'El objetivo se creó  correctamente',
  },
  gamingChallengeGoalEditedSuccessfully: {
    label: 'gamingChallengeGoalEditedSuccessfully',
    en: 'The goal was created successfully',
    es: 'El objetivo se creó correctamente',
  },
  gamingChallengeGoalDeleteConfirmation: {
    label: 'gamingChallengeGoalDeleteConfirmation',
    en: 'Are you sure you want to remove this goal?',
    es: '¿Seguro que desea eliminar este objetivo?',
  },
  gamingChallengeGoalDeletedSuccessfully: {
    label: 'gamingChallengeGoalDeletedSuccessfully',
    en: 'The goal was deleted successfully',
    es: 'El objetivo se eliminó correctamente',
  },
  gamingChallengeDeleteUserConfirmation: {
    label: 'gamingChallengeDeleteUserConfirmation',
    en: 'Are you sure to delete this user?',
    es: '¿Seguro que desea eliminar este usuario?',
  },
  gamingChallengeDeleteUserSuccess: {
    label: 'gamingChallengeDeleteUserSuccess',
    en: 'The user was deleted successfully',
    es: 'El usuario se ha eliminado correctamente',
  },
  gamingGoalDuplicated: {
    label: 'gamingGoalDuplicated',
    en: 'There is already a goal with that goal type',
    es: 'Ya existe una meta con ese tipo de objetivo',
  },
  streamingZoomAccount: {
    label: 'streamingZoomAccount',
    en: 'Zoom account',
    es: 'Cuenta de zoom',
  },
  streamingZoomTokenIDPlaceHolder: {
    label: 'streamingZoomTokenIDPlaceHolder',
    en: 'Enter your Zoom API Key',
    es: 'Ingresa tu API Key de Zoom',
  },
  streamingZoomTokenID: {
    label: 'streamingZoomTokenID',
    en: 'Zoom API Key',
    es: 'API Key de Zoom',
  },
  streamingZoomTokenIDError: {
    label: 'streamingZoomTokenIDError',
    en: 'The API key is required',
    es: 'El API KEY es requerido',
  },
  streamingZoomSecretPlaceHolder: {
    label: 'streamingSecretPlaceHolder',
    en: 'Enter your API Secret',
    es: 'Ingresa tu API Secret de Zoom',
  },
  streamingZoomSecret: {
    label: 'streamingZoomSecret',
    en: 'Zoom API Secret',
    es: 'API Secret de Zoom',
  },
  streamingZoomSecretError: {
    label: 'streamingZoomSecretError',
    en: 'The Zoom API Secret is required',
    es: 'El API Secret de Zoom es requerido',
  },
  streamingZoomDeleted: {
    label: 'streamingZoomDeleted',
    en: 'Delete credentials',
    es: 'Eliminar credenciales',
  },
  streamingZoomUpdatedSuccessfully: {
    label: 'streamingZoomUpdatedSuccessfully',
    en: 'The credentials was saved successfully',
    es: 'Las credenciales se guardaron correctamente',
  },
  streamingZoomDeletedSuccessfully: {
    label: 'streamingZoomDeletedSuccessfully',
    en: 'The credentials was deleted successfully',
    es: 'Las credenciales se eliminaron correctamente',
  },
  streamingZoomDeletedSConfirmation: {
    label: 'streamingZoomDeletedConfirmation',
    en: 'Are you sure you want to remove the credentials?',
    es: '¿Estás seguro de eliminar las credenciales?',
  },
  streamingZoomConflict: {
    label: 'streamingZoomConflict',
    en: 'We had trouble starting zoom, try again',
    es: 'Tuvimos problemas para iniciar zoom, intenta de nuevo',
  },
  streamingZoomTokenConflict: {
    label: 'streamingZoomTokenConflict',
    en: 'Failed to start class as entered zoom credentials are incorrect',
    es: 'No se pudo iniciar la clase ya que las credenciales ingresadas de zoom son incorrectas',
  },
  streamingZoomConfirmationTitle: {
    label: 'streamingZoomConfirmationTitle',
    en: 'Are you sure you want to recover streaming?',
    es: '¿Seguro que desea recuperar el streaming?',
  },
  streamingZoomConfirmationText: {
    label: 'streamingZoomConfirmationText',
    en: 'If you recover the streaming session, you will end the one that is active',
    es: 'Si recuperas la sesión de streaming, terminaras la que se encuentra activa',
  },
  streamingZoomAlertOfZoomTitle: {
    label: 'streamingZoomAlertOfZoomTitle',
    en: 'The class has been configured to use streaming',
    es: 'La clase está configurada para transmitir en vivo',
  },
  streamingZoomAlertOfZoomText: {
    label: 'streamingZoomAlertOfZoomText',
    en: 'Do you want to continue streaming?',
    es: '¿Desea continuar con la transmisión?',
  },
  streamingZoomBackAlertOfZoomTitle: {
    label: 'streamingZoomBackAlertOfZoomTitle',
    en: 'Are you sure of go back?',
    es: '¿Seguro que deseas salir de la sala?',
  },
  streamingZoomBackAlertOfZoomText: {
    label: 'streamingZoomBackAlertOfZoomText',
    en: 'At go back the current session of streaming will be closed',
    es: 'Al salir de la sala se cerrará la sesión de streaming actual',
  },
  streamingZoomBackAlertOfZoomButton: {
    label: 'streamingZoomBackAlertOfZoomButton',
    en: 'Without video',
    es: 'Sin vídeo',
  },
  streamingIsAlreadyActive: {
    label: 'streamingIsAlreadyActive',
    en: 'The live stream is being used in another class at this time. To re-enable it, delete the scheduled class.',
    es: 'La transmisión en vivo esta siendo utilizada en otra clase en este momento. Para volver a habilitarlo, elimine la clase programada.',
  },
  streamingWithoutCredentials: {
    label: 'streamingWithoutCredentials',
    en: 'You do not have Zoom credentials, please enter your credentials in the center information',
    es: 'No tienes credenciales de Zoom, por favor ingresa tus credenciales en la información del centro',
  },
  reportButtonTitle: {
    label: 'reportButtonTitle',
    en: 'Send Report',
    es: 'Enviar reporte',
  },
  userReportModalMetricsTitle: {
    label: 'userReportModalMetricsTitle',
    en: 'Training metrics during the period',
    es: 'Métricas de entrenamientos durante el periodo',
  },
  userReportModalUserTitle: {
    label: 'userReportModalUserTitle',
    en: 'User information',
    es: 'Información del usuario',
  },
  userReportModalTitle: {
    label: 'userReportModalTitle',
    en: 'User report',
    es: 'Reporte del usuario',
  },
  userReportModalCommentsTitle: {
    label: 'userReportModalCommentsTitle',
    en: 'Comments',
    es: 'Comentarios',
  },
  userReportModalCommentsPlaceholder: {
    label: 'userReportModalCommentsPlaceholder',
    en: 'Enter comment',
    es: 'Ingresa comentario',
  },
  userReportModalCalories: {
    label: 'userReportModalCalories',
    en: 'Calories',
    es: 'Calorías',
  },
  userReportModalTrainings: {
    label: 'userReportModalTrainings',
    en: 'Trainings',
    es: 'Entrenamientos',
  },
  userReportModalTrainingsList: {
    label: 'userReportModalTrainingsList',
    en: 'Modality',
    es: 'Modalidad',
  },
  userReportModalTrainingsListDate: {
    label: 'userReportModalTrainingsListDate',
    en: 'Training date',
    es: 'Fecha de entrenamiento',
  },
  userReportSuccessfullySended: {
    label: 'userReportSuccessfullySended',
    en: 'The email was sent to the user correctly',
    es: 'El email se le envío al usuario correctamente',
  },
  userReportModalCommentsError: {
    label: 'userReportModalCommentsError',
    en: 'Comments must have a maximum of 1200 characters',
    es: 'Los comentarios deben tener máximo 1200 caracteres',
  },
  boardPageChallengesParticipated: {
    label: 'boardPageChallengesParticipated',
    en: 'Participated Challenges',
    es: 'Retos Participados',
  },
  boardPageChallengesCompleted: {
    label: 'boardPageChallengesCompleted',
    en: 'Completed Challenges',
    es: 'Retos Completados',
  },
  boardPageChallengesAchievements: {
    label: 'boardPageChallengesAchievements',
    en: 'Achievements',
    es: 'Logros',
  },
  individualDetailTitle: {
    label: 'individualDetailTitle',
    en: 'User Progress',
    es: 'Progreso del usuario',
  },
};

export default Texts;
