import { createMachine, assign, DoneInvokeEvent, spawn, send } from "xstate";
import * as Sentry from '@sentry/react'

// Types and models
import moment from "moment";
import { Room, Coach, CenterData, AuthData, LocalStorageKey, RoomBody, Subscription, ClassConfig, Configuration, Order, ZoomCredentials } from '../../types'

// HTPP
import axiosClient from '../../config/axios'

// Actors
import createRTMachine from './RealTimeMachine'

/**
 * uuid: uuid del room
 */
type Image = {
  image: File, 
  uuid: string
}

type UpdatedImage = {
  uuid: string,
  url: string,
}

/**
 * rooms: Lista de rooms
 * realtime: actor que controla el grid
 * roomID
 * coaches: lista de coaches que se muestra en el drop para crear/editar
 * branchID
 * openModal: abrir o no el modal para crear/editar
 * maximized: saber si esta maximizado o no para avisar al layout
 * image: image a subir
 * room: nueva data del room
 */
type RemoteMachineContext = {
  rooms: Room[],
  realtime: any,
  roomID: string,
  coaches: Coach[]
  branchID: string,
  edit: Room | null,
  openModal: boolean,
  maximized: boolean,
  statusError: number,
  image: Image | null,
  room: RoomBody | null,
  hostname: string,
  streamingIsAlreadyActive: boolean,
  zoomCredentials: ZoomCredentials | null,
}

/**
 * CREATE: create un room
 * FOCUS: poner un room en focus para llenar el modal
 * EDIT: Actualizar un room
 * SESSION: ir a la sesión
 * BACKGROUND: actualizar el background del room
 * DELETE: eliminar un room
 * RESTART: reiniciar un room
 * START: se inicio un room y hay que actualizar el estado en la lista de rooms
 * TOGGLE: mostrar/ocultar el modal para editar/crear room
 * RESIZE: maximizar/minimizar con el botón de la barra de acciones
 * MAXIMIZE: maximizar cuando se flota la barra
 * BACK: regresar a la vista anterior
 * NORMAL: iniciar un room de manera normal
 */
type RemoteMachineEvent = 
  | { type: 'CREATE', data: RoomBody }
  | { type: 'FOCUS', data: Room }
  | { type: 'EDIT', data: RoomBody }
  | { type: 'SESSION', data: { room: Room, user: string } }
  | { type: 'BACKGROUND', data: Image }
  | { type: 'DELETE', data: string }
  | { type: 'RESTART', data: { id: string, streaming: boolean } }
  | { type: 'START', data: { uuid: string, config: ClassConfig } }
  | { type: 'HOSTNAME', data: string }
  | { type: 'TOGGLE' }
  | { type: 'READY' }
  | { type: 'RESIZE' }
  | { type: 'MAXIMIZE' }
  | { type: 'BACK' }
  | { type: 'NORMAL' }

/**
 * Buscar los rooms que estan disponibles
 * @returns información del centro
 */
 const fetchRooms = async () : Promise<CenterData> => {
  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const response = await axiosClient.get(`/api/v2/centers/${authData.uuidBranch}`)

  return response.data
}

/**
 * Crear un room
 * @param context de la maquina que controla esta vista
 * @returns un room nuevo
 */
const createRoom = async (context: RemoteMachineContext) : Promise<Room> => {
  
  // Creamos un nuevo room
  const { room, branchID, coaches } = context

  if (!room) throw new Error()

  if (!room.description || room.description === '')
    delete room.description

  const response = await axiosClient.post(`/api/v2/room`, { ...room, branch_uuid: branchID })

  const coach : Coach = coaches.find((c: Coach) => c.uuid === room.couches[0].uuid)!

  return {
    name: room.room_name,
    type_room: room.type_room,
    uuid: response.data.uuid,
    couches_asigned: [{ uuid: coach.uuid, couch_name: coach.couch_name || coach.name }],
    status: Number(room.status),
    class_configuration: {
      channel: '',
      capacity: 0,
      duration: 0,
      'data_order': '',
      'class_delay': 0,
      'class_start_at':	'',
      'class_finish_at': '',
      remote_state: 1,
      steps_icon: null,
      step_options: null,
      streaming: false
    }
  }

}

/**
 * Actualizar un room
 * @param context de la maquina que controla esta vista
 * @returns un room actualizado
 */
const editRoom = async (context: RemoteMachineContext) : Promise<Room> => {
  
  // Sacamos el room a editar
  // edit es que se coloca para inicializar el modal
  // room es el que tiene los nuevos datos
  const { edit, room, branchID, coaches } = context

  if (!edit || !room ) throw new Error()

  if ( !room.description || room.description === '')
    delete room.description

  await axiosClient.put(`/api/v2/room/${edit.uuid}`, { ...room, branch_uuid: branchID })

  const coach : Coach = coaches.find((c: Coach) => c.uuid === room.couches[0].uuid)!

  return {
    name: room.room_name,
    type_room: room.type_room,
    uuid: edit.uuid,
    couches_asigned: [{ uuid: coach.uuid, couch_name: coach.couch_name || coach.name }],
    status: Number(room.status),
    description: room.description ? room.description : ''
  }

}

/**
 * Actualizar el background del daq
 * @param context de la maquina
 * @returns la imagen de background actualizada
 */
 const updateBackground = async (context: RemoteMachineContext) : Promise<UpdatedImage> => {
  
  // Sacamos la imagen del contexto
  const { image }  = context

  if (!image) throw new Error()

  // Creamos un FormData
  const data = new FormData()
  data.append('center_image', image.image)

  const response = await axiosClient.post(`/api/v2/room/${image.uuid}/background`, data)

  return {
    uuid: image.uuid,
    url:  response.data.url
  }

}

const deleteRoom = async (context: RemoteMachineContext) : Promise<string> => {
  
  // Sacamos el room a eliminar
  const { roomID } = context

  if (roomID === '') throw new Error()

  await axiosClient.delete(`/api/v2/room/${roomID}`)

  return roomID

}

const RemoteMachine = createMachine<RemoteMachineContext, RemoteMachineEvent>(
  {
    id: 'RemoteMachine',
    initial: 'loading',
    context: {
      rooms: [],
      room: null,
      roomID: '',
      edit: null,
      image: null,
      coaches: [],
      branchID: '',
      hostname: '',
      statusError: 0,
      realtime: null,
      openModal: false,
      maximized: false,
      zoomCredentials: null,
      streamingIsAlreadyActive: false
    },
    states: {
      idle: {},
      login: {},
      started: {},
      loading: {
        invoke: {
          id: 'fetchRooms',
          src: fetchRooms,
          onDone: {
            target: 'waiting',
            actions: [ 'prepareData' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudieron descargar los rooms'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      created: {},
      creating: {
        invoke: {
          id: 'createRoom',
          src: createRoom,
          onDone: {
            target: 'created',
            actions: [ 'appendRoom' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              let code = event.data.response ? event.data.response.status : 7

              const errorMessage : string = 'No se pudo crear el room'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              if (!event.data.response) code = 10

              if (event.data.response.data.room_name) code = 11


              return {
                ...context,
                statusError: code
              }

            })
          }
        }
      },
      edited: {},
      editing: {
        invoke: {
          id: 'editRoom',
          src: editRoom,
          onDone: {
            target: 'edited',
            actions: [ 'updateRoom' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              let code = event.data.response ? event.data.response.status : 7

              const errorMessage : string = 'No se pudo editar el room'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              if (!event.data.response) code = 10

              if (event.data.response.data.room_name) code = 11


              return {
                ...context,
                statusError: code
              }

            })
          }
        }
      },
      updated: {},
      changing: {
        invoke: {
          id: 'updateBackground',
          src: updateBackground,
          onDone: {
            target: 'updated',
            actions: [ 'changeImage' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo actualizar el background del room'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      deleted: {},
      deleting: {
        invoke: {
          id: 'deleteRoom',
          src: deleteRoom,
          onDone: {
            target: 'deleted',
            actions: [ 'deleteRoom' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo eliminar el room'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      realtime: {
        on: {
          BACK: {
            target: 'idle',
            actions: [
              send({ type: 'FINAL'}, { to: (context) => context.realtime })
            ]
          },
          RESTART: {
            target: 'idle',
            actions: [
              'changeState',
              send({ type: 'FINAL'}, { to: (context) => context.realtime })
            ]
          },
          START: {
            actions: [ 'startRoom' ]
          }
        }
      },
      waiting: {
        on: {
          READY: {
            target: 'realtime'
          },
          NORMAL: {
            target: 'started',
            // cond: 'isEmpty'
          },
          HOSTNAME: {
            actions: assign({ hostname: (_, event) => event.data })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      },
      finish: {
        type: 'final'
      }
    },
    on: {
      TOGGLE: {
        target: '.idle',
        actions: [ 'toggle' ]
      },
      CREATE: {
        target: '.creating',
        actions: assign({ room: (_, event) => event.data })
      },
      FOCUS: {
        target: '.idle',
        actions: [ 'getCoach' ]
      },
      EDIT: {
        target: '.editing',
        actions: assign({ room: (_, event) => event.data })
      },
      SESSION: {
        target: '.waiting',
        actions: [ 'saving' ]
      },
      BACKGROUND: {
        target: '.changing',
        actions: assign({ image: (_, event) => event.data })
      },
      DELETE: {
        target: '.deleting',
        actions: assign({ roomID: (_, event) => event.data })
      },
      RESIZE: {
        actions: assign({ maximized: (context) => !context.maximized })
      },
      MAXIMIZE: {
        actions: [ 'fullscreen' ]
      },
    }
  },
  {
    actions: {
      toggle: assign((context) => ({
          ...context,
          openModal: !context.openModal,
          edit: null,
          room: null
        })),
      prepareData: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<CenterData> = _event

        if (event.type !== 'done.invoke.fetchRooms') 
          return { ...context }

        const rooms : Room[] = []
        let streamingIsAlreadyActive = false

        event.data.rooms.forEach(room => {

          if (room.type_room === 'remote') {

            if (room.class_configuration && !Array.isArray(room.class_configuration)) {

              // Pasarla fecha de tiempo de México a UTC
              const endDate = moment
                .tz(room.class_configuration.class_finish_at, 'America/Mexico_City')
                .local()

              const startDate = moment
                .tz(room.class_configuration.class_start_at, 'America/Mexico_City')
                .local()

              rooms.push({
                ...room,
                class_configuration: {
                  ...room.class_configuration,
                  class_finish_at: endDate.format('YYYY-MM-DD HH:mm:ss'),
                  class_start_at: startDate.format('YYYY-MM-DD HH:mm:ss')
                }
              })
            }

            else rooms.push(room)
          }

          if ( !streamingIsAlreadyActive && room.class_configuration && room.class_configuration.streaming) streamingIsAlreadyActive = true

        })
        // const rooms : Room[] = event.data.rooms.filter(
        //   (room: Room) => room.type_room === 'remote'
        // )

        // const streamingIsAlreadyActive = event.data.rooms.some( r => {

        //   if (r.class_configuration) return Boolean(r.class_configuration.streaming)
        //   return false

        // })

        return {
          ...context,
          rooms,
          streamingIsAlreadyActive,
          coaches: event.data.couches,
          branchID: event.data.branch_uuid,
          zoomCredentials: event.data.credential_zoom,
        }

      }),
      appendRoom: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Room> = _event

        if (event.type !== 'done.invoke.createRoom') 
          return { ...context }

        return {
          ...context,
          openModal: !context.openModal,
          room: null,
          rooms: [
            ...context.rooms,
            event.data,
          ]
        }

      }),
      getCoach: assign((context, event) => {

        if (event.type !== 'FOCUS') return { ...context }

        let selected : string = '' 

        if (event.data.couches_asigned && event.data.couches_asigned.length > 0)
          selected = event.data.couches_asigned[0].couch_name || ''

        const coach : Coach | undefined = context.coaches.find(
          (c: Coach) => c.name === selected
        )

        return {
          ...context,
          edit: {
            ...event.data,
            couches_asigned: coach ? [ coach ] : []
          },
          openModal: !context.openModal
        }

      }),
      updateRoom: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Room> = _event

        if (event.type !== 'done.invoke.editRoom') 
          return { ...context }

        const rooms : Room[] = context.rooms.map(
          (r: Room) => r.uuid === event.data.uuid ? { ...r, ...event.data} : r
        )

        return {
          ...context,
          openModal: !context.openModal,
          edit: null,
          room: null,
          rooms
        }

      }),
      saving: assign((context, event) => {

        if (event.type !== 'SESSION') return { ...context }

        if (event.data.user !== ''){
          localStorage.setItem(
            `${LocalStorageKey.rookRemoteRecent}-${event.data.user}`,
            JSON.stringify(event.data.room)
          )
        }

        let initial = 'loading'
        let state = 'stop'
        let subscription: Subscription | null = null
        let config: Configuration | null = null
        let classConfig: ClassConfig | null = null

        if ( !Array.isArray(event.data.room.class_configuration) 
          && event.data.room.class_configuration 
          && event.data.room.class_configuration.remote_state !== 1
        ) {

          const key : string = `${LocalStorageKey.delay}-${event.data.room.uuid}`
          const delay : string | null  = localStorage.getItem(key)

          let remaining : number = 0

          if (delay){

            const start = new Date(JSON.parse(delay))
            const current = new Date()
            const diffTime = start.getTime() - current.getTime()
            remaining = diffTime / 1000
          }

          initial = 'started'
          state = event.data.room.class_configuration.remote_state === 3 ? 'reset' : 'stop'
          subscription = {
            channel: event.data.room.class_configuration.channel,
            uuid: event.data.room.class_configuration.uuid || '',
            result: '',
            remaining_waiting_seconds: `${remaining}`,
          }
          config = {
            "user_capacity_status": 1,
            "training_type_uuid": '',
            "class_delay": event.data.room.class_configuration.class_delay,
            "data_order": Order.calories,
            capacity: event.data.room.class_configuration.capacity,
            duration: event.data.room.class_configuration.duration,
            streaming: Boolean(event.data.room.class_configuration.streaming)
          }
          classConfig = event.data.room.class_configuration
        }

        const rtMachine = spawn(
          createRTMachine( 
            initial, 
            event.data.room.uuid, 
            state, 
            event.data.room.name,
            subscription, 
            config, 
            classConfig,
            context.hostname,
            context.zoomCredentials,
            context.streamingIsAlreadyActive
          )
        )

        return {
          ...context,
          edit: event.data.room,
          user: event.data.user,
          realtime: rtMachine,
          maximized: initial !== 'loading'
        }

      }),
      changeImage: assign((context, _event: any) => {
        
        // Cargamos el evento
        const event : DoneInvokeEvent<UpdatedImage> = _event

        if ( event.type !== 'done.invoke.updateBackground'  ) 
          return { ...context }

        // Actualizamos la lista de daqs
        const newRooms = context.rooms.map((room: Room) => {

          if (room.uuid === event.data.uuid){
            return {
              ...room,
              'background_image': event.data.url
            }
          }

          return room

        })

        // Sacamos el usuario loggeado
        const rawAuth : string | null = localStorage.getItem(LocalStorageKey.auth)
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        if ( authData ) {

          const key : string = `${LocalStorageKey.rookRemoteRecent}-${authData.uuidUser}`

          // Sacamos el daq guardado
          const previousRoom : string | null  = localStorage.getItem(key)
          const remote : Room | null = JSON.parse(previousRoom || 'null')

          if (remote && remote.uuid === event.data.uuid) {

            remote.background_image = event.data.url
  
            // Guardamos en el localStorage 
            localStorage.setItem(key, JSON.stringify(remote))          
          }

        }

        return {
          ...context,
          rooms: newRooms,
          maximized: false
        }

      }),
      deleteRoom: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<string> = _event

        if ( event.type !== 'done.invoke.deleteRoom') 
          return { ...context }

        const rooms : Room[] = context.rooms.filter((r: Room) => r.uuid !== event.data)

        // Borramos la configuración del localstorage
        localStorage.removeItem(`${LocalStorageKey.training}-${event.data}`)

        // Sacamos el usuario loggeado
        const rawAuth : string | null = localStorage.getItem(LocalStorageKey.auth)
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        if (authData) {

          const key : string = `${LocalStorageKey.rookRemoteRecent}-${authData.uuidUser}`

          // Sacamos el room guardado
          const previousRoom : string | null  = localStorage.getItem(key)
          const remote : Room | null = JSON.parse(previousRoom || 'null')

          if (remote && remote.uuid === event.data ){
            localStorage.removeItem(key)
          }

        }

        return {
          ...context,
          rooms,
        }

      }),
      fullscreen: assign((context) => ({
          ...context,
          maximized: true
        })),
      changeState: assign((context, event) => {
        
        if (event.type !== 'RESTART') return { ...context }

        let newRoom : Room | null = null

        // Actualizamos la lista de daqs
        const newRooms = context.rooms.map((room: Room) => {

          if (room.uuid === event.data.id){
            newRoom = {
              ...room,
              class_configuration: {
                ...room.class_configuration!,
                remote_state: 1,
                streaming: false,
              }
            }
            return newRoom
          }

          return room

        })

        // Sacamos el usuario loggeado
        const rawAuth : string | null = localStorage.getItem(LocalStorageKey.auth)
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        if ( authData ) {
          
          const key : string = `${LocalStorageKey.rookRemoteRecent}-${authData.uuidUser}`

          // Sacamos el daq guardado
          const previousRoom : string | null  = localStorage.getItem(key)
          const remote : Room | null = JSON.parse(previousRoom || 'null')

          if (remote && remote.uuid === event.data.id) {

            // Guardamos en el localStorage 
            localStorage.setItem(key, JSON.stringify(newRoom))          
          }

        }

        const streamingIsAlreadyActive = newRooms.some( r => {

          if (r.class_configuration) return Boolean(r.class_configuration.streaming)
          return false

        })

        return {
          ...context,
          rooms: newRooms,
          streamingIsAlreadyActive
        }

      }),
      startRoom: assign((context, event) => {

        if (event.type !== 'START') return { ...context }

        let newRoom : Room | null = null

        // Actualizamos la lista de rooms de remote
        const newRooms = context.rooms.map((room: Room) => {

          if (room.uuid === event.data.uuid){
            newRoom = {
              ...room,
              class_configuration: {
                ...room.class_configuration!,
                ...event.data.config
              }
            }
            return newRoom
          }

          return room

        })

        // Sacamos el usuario loggeado
        const rawAuth : string | null = localStorage.getItem(LocalStorageKey.auth)
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        if ( authData && newRoom) {

          const key : string = `${LocalStorageKey.rookRemoteRecent}-${authData.uuidUser}`

          // Sacamos el daq guardado
          const previousRoom : string | null  = localStorage.getItem(key)
          const remote : Room | null = JSON.parse(previousRoom || 'null')

          if (remote && remote.uuid === event.data.uuid) {

            // Guardamos en el localStorage 
            localStorage.setItem(key, JSON.stringify(newRoom))          
          }

        }

        return {
          ...context,
          rooms: newRooms,
          streamingIsAlreadyActive: Boolean(event.data.config.streaming)
        }
      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401,
      isEmpty: (context) => context.rooms.length !== 0
    }
  }
)

export default RemoteMachine