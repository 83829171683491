import React, { ChangeEvent, FC, useEffect, useState } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useMachine } from '@xstate/react';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';


// Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SyncIcon from '@mui/icons-material/Sync';

// Context
import RequestsMachine from '../context/machines/RequestsMachine'

// Types and models
import { Word, SearchIn } from '../types'
import Texts from '../models/Texts'

// Compontents
import Layout from '../components/layout/Layout'
import Spinner from '../components/utils/Spinner'
import Container from '../components/layout/Container'
import SearchBar from '../components/utils/SearchBar'
import RequestList from '../components/requests/RequestsList';
import Support from '../components/utils/Support';

// Hooks
import useTranslation from '../hooks/useTranslation'

interface RequestsProps {
  
}
 
const Requests: FC<RequestsProps> = () => {

  // Hook que va a controlar esta vista
  const [machine, send] = useMachine(RequestsMachine)

  // Hook para la traducción
  const { getTranslation } = useTranslation()

  // Mostrar o no el spinner
  const [loading, setLoading] = useState(false) 

  useEffect(() => {
    
    if(
      machine.matches('loading') 
      || machine.matches('searching') 
      || machine.matches('lookingFor')
    ) setLoading(true)

    else setLoading(false)

  }, [machine])

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    send({
      type: 'PAGEING',
      page: value
    })
  };

  // Rechazar todas las solicitudes
  const handleRefuse = () : void => {
    Swal.fire({
      title: '¿Deseas rechazar todas las solicitudes?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: 'rechazar',
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        send({  type: 'REFUSE' })
      }
    })
  }

  /**
   * Obtener la hora
   * @returns hora y minutos cuando se renderizó la página
   */
  const getTime = () : string => {
    
    const date = new Date()
    let text = `${date.getHours()}`

    if (date.getMinutes() <= 9)
      text = `${text}:0${date.getMinutes()}`

    else text = `${text}:${date.getMinutes()}`

    return text

  }

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      <Spinner
        show = { loading }
      >
        <Container 
          icon = { <PersonAddIcon /> }
          title = { getTranslation( Texts.navMenuRequests as Word ) }
          rightButton = { 
            <Support
              action='requests'
            /> 
          }
        >

          <div className="flex flex-col md:flex-row justify-between items-center">

            <div className = "flex items-center text-sm lg:ml-1">
              <p>
                { getTranslation(Texts.requestsLastUpdate as Word) }: {getTime()} hrs
              </p>

              <Tooltip 
                title = { getTranslation(Texts.refresh as Word) }
              >
                <IconButton
                  onClick = { () => send({ type: 'RESTART' }) }
                >
                  <SyncIcon 
                    fontSize = 'small'
                  />
                </IconButton>
              </Tooltip>
            </div>

            <SearchBar
              action = { searchIn }
            />
          </div>

          <div 
            className={`flex-row flex-col md:flex-row justify-start w-full items-center md:mt-8 ${machine.context.rows > 0 ? 'flex' : 'hidden'}`}
          >

            { machine.matches('accepting') 
              ? ( 
                <div className="lg:mr-8">
                  <CircularProgress />
                </div>
              ) 
              : (
                <button
                  type = 'button'
                  className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full md:w-4/12 lg:w-full lg:w-max md:mr-4"
                  onClick = { () => send({ type: 'ACCEPT' }) }
                >
                  { getTranslation( Texts.requestsAcceptAll as Word ) }
                </button>
              ) 
            }

            { machine.matches('refusing') 
              ? ( <CircularProgress color = 'secondary' /> ) 
              : (
                <button
                  type = 'button'
                  className = "uppercase bg-red-750 px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full md:w-4/12 lg:w-full lg:w-max"
                  onClick = { handleRefuse }
                >
                  { getTranslation( Texts.requestsRefuseAll as Word ) }
                </button>
              ) 
            }
          </div>

          { (machine.context.page && !machine.matches('loading')) && (
            <RequestList 
              service = { machine.context.page }
            />
          )}

          { (machine.context.pages > 1) && (
            <div className="flex justify-center mt-12 mb-4">
              <Pagination 
                showFirstButton 
                showLastButton
                page = { machine.context.currentPage }
                variant="text" 
                color = "secondary"
                count = { machine.context.pages } 
                onChange = { handleChange }
              />
            </div>
          )}


        </Container>
      </Spinner>
    </Layout>
  )
}
 
export default Requests;