import moment from 'moment'
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import logo from './logoImageInBase64.json'
import { Translation } from '../types/index';

// limite izquierdo: x -> 10
// limite derecho: x -> 190
// centro: y -> 100

class GeneratePDF { 

  private backgroundColor: string

  private redBorder: string

  private grayTitle: string

  private doc: jsPDF

  private lang: Translation

  private total: number

  constructor(doc: jsPDF, lang: Translation, total: number) {
    this.backgroundColor = '#eae8e8'
    this.redBorder = '#db101a'
    this.grayTitle = '#747474'
    this.doc = doc
    this.lang = lang
    this.total = total
  }

  public getHeader = () : void=> {
  
    // Agregamos la imagen
    this.doc.addImage(logo.image, 'png', 10, 5, 34, 6)
  
    // Agregamos la fecha
    const format : string = this.lang === Translation.en ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setFontSize(11)
    this.doc.text(moment().format(format), 180, 10)
  
    // Agregamos el titulo
    const title = this.lang === Translation.en ? 'User Report' : 'Reporte de usuario'
    const axisX = this.lang === Translation.en ? 93 : 82
    this.doc.setFillColor(this.backgroundColor)
    this.doc.roundedRect(10, 15, 190, 12, 2, 2, 'F');
    this.doc.text(title.toUpperCase(), axisX, 22)
  
  }

  public getFooter = (page: number) : void=> {
  
    // Insertamos la división al final
    this.doc.setFillColor(this.backgroundColor)
    this.doc.line(10, 290, 200, 290)
  
    // Insertamos el texto
    const footer = this.lang === Translation.en ? 'Page' : 'Página'
    const separator = this.lang === Translation.en ? 'of' : 'de'
    this.doc.setFont('Helvetica', 'normal', 'normal')
    this.doc.setTextColor('black')
    this.doc.setFontSize(8)
  
    if (this.total === 0) this.doc.text(`${footer} ${page}`, 100, 293.5)
    else this.doc.text(`${footer} ${page} ${separator} ${this.total}`, 98, 293.5)
  
  }

  public getPageTitle = (
    title: string, subTitle ?: string, x ?: number, y ?: number
  ) : void => {
  
    // Insertamos el cuadro de fondo
    this.doc.setFillColor(this.backgroundColor)
    this.doc.roundedRect(10, 30, 190, 12, 2, 2, 'F');
  
    // Insertamos el border rojo
    this.doc.setFillColor(this.redBorder)
    this.doc.roundedRect(10, 30, 2, 12, 1, 1, 'F');
  
    // Insertamos el titulo
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setFontSize(11)
    this.doc.text(title, 15, 37.5)
  
    if( subTitle ) {
      this.doc.setFont('Helvetica', 'normal', 'normal')
      this.doc.setFontSize(10)
      this.doc.text(subTitle, x || 56, y || 37.5)
    }
  
  }

  public getTable = (
    head: string[][], body: string[][], pageTitle: string
  ) : void=> {
    
    autoTable(this.doc, {
      head,
      body,
      margin: {
        top: 45,
        bottom: 15,
        left: 10,
        right: 10
      },
      willDrawCell: (data) => {
        if (data.section === 'head') {
          this.doc.setFillColor(this.backgroundColor)
          this.doc.setTextColor('black')
        }
      },
      didDrawPage: (data) => {
        this.getHeader()
        this.getPageTitle(pageTitle)
        this.getFooter(data.pageNumber + 1)
      }
    })
  }

  public getSimpleBox = (
    title: string, content: string, x: number, y: number
  ) : void=> {
    
    // Cambiamos el color de la letra
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setTextColor(this.grayTitle)
    this.doc.setFontSize(10)
  
    // Insertamos el titulo
    this.doc.text(title, x, y)
    
    // Cambiamos a negro
    this.doc.setFont('Helvetica', 'normal', 'normal')
    this.doc.setTextColor('black')
    this.doc.text(
      content.length >= 24 ? `${content.substring(0, 21)}...` : content, 
      x, 
      y + 5
    )
  
  }

  public getBoxContent = (
    title: string, content: string[][], x: number, y: number, w: number, h: number
  ) : void=> {
    
    // Insertamos el cuadro de fondo
    this.doc.setFillColor(this.backgroundColor)
    this.doc.roundedRect(x, y, w, h, 2, 2, 'F');
  
    // Insertamos el border rojo
    this.doc.setFillColor(this.redBorder)
    this.doc.roundedRect(x, y, 2, h, 1, 1, 'F');
  
    // Insertamos el titulo
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setTextColor('black')
    this.doc.setFontSize(11)
    this.doc.text(title, x + 5, y + 8)
  
    // Insertamos el contenido
    let counter = 0
    let currentY = y + 8 + 8
    let currentX = x + 5
    const distance = 190 / 4
  
    content.forEach(element => {
      
      if (element.length === 2) {
        this.getSimpleBox(element[0], element[1], currentX, currentY)
        counter = counter + 1
        currentX = currentX + distance
      }
  
      if (counter === 4) {
        counter = 0
        currentY = currentY + 15
        currentX = x + 5
      }
  
    })
  
  }

  public getChartContainer = (
    title: string, x: number, y: number,w: number, h: number
  ) : void=> {
    
    // Insertamos el cuadro de fondo
    this.doc.setFillColor(this.backgroundColor)
    this.doc.roundedRect(x, y, w, h, 2, 2, 'F');
  
    // Insertamos un fondo blanco
    this.doc.setFillColor('#fff')
    this.doc.roundedRect(x + 2.5, y + 8, w - 5, h - 10, 2, 2, 'F')
  
    // Insertamos el titulo
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setFontSize(11)
    this.doc.text(title, x + 3, y + 5)
  
  }

  public insertImage = (
    img: string, extension: string, x: number, y: number, w: number, h: number
  ) : void => {
    
    this.doc.addImage(img, extension, x, y, w, h);
  
  }

  public getSimpleContent = (
    title: string, content: string, x: number, y: number, w: number, h: number
  ) : void=> {
  
    // Insertamos el cuadro de fondo
    this.doc.setFillColor(this.backgroundColor)
    this.doc.roundedRect(x, y, w, h, 2, 2, 'F');
  
    // Insertamos el border rojo
    this.doc.setFillColor(this.redBorder)
    this.doc.roundedRect(x, y, 2, h, 1, 1, 'F');
  
    // Insertamos el titulo
    this.doc.setFont('Helvetica', 'normal', 'bold')
    this.doc.setFontSize(11)
    this.doc.text(title, x + 5, y + 5)
  
    // Insertamos el contenido
    // const splittedContent = this.doc.splitTextToSize(content, 90)
    this.doc.setFont('Helvetica', 'normal', 'normal')
    this.doc.setTextColor(this.grayTitle)
    this.doc.setFontSize(10)
    this.doc.text(content, x + 5, y + 13, { maxWidth: 180 })
    
  }

}

export default GeneratePDF