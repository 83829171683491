/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useContext, useMemo, useRef } from 'react'

// Dependencies
import moment from 'moment'
import { StateValue } from 'xstate';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

// Validation
import { Formik } from "formik";

// Types and models
import { Word, FullAthlete, Gender } from '../../types'
import Texts from '../../models/Texts';

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import { InputForm, InputDatePicker, InputError, InputSelect } from '../utils/RookFormInputs';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validation
import validation from '../../validations/ValidationsUser'

// Utils
import { capitalize } from '../../utils'

interface UserModalProps {
  info: FullAthlete
  openModal: boolean,
  value: StateValue,
  setOpenModal: (flag: boolean) => {},
  addUser: (athlete: any) => void
}

/**
 * Modal para editar/crear usuario
 * @param props contiene todas las propiedades necesarias descritas en UserModalProps
 * @param props.value estado en el que esta el actor para saber si esta creando/editando
 * @param props.openModal saber si debe estar abierto el modal o no
 * @param props.info data del usuario si esta por crearse este viene con cadenas vacías
 * @param props.addUser función para agregar al usuario
 * @param props.setOpenModal función para agregar al usuario
 * @returns formulario para editar/crear usuario
 */
const UserModal: FC<UserModalProps> = ({
  value, openModal, info, addUser, setOpenModal
}) => {

  // Lenguaje
  const { lang, metricSystem } = useContext( UIContext )

  // validation
  const validationSchema = useMemo(() => validation(metricSystem), [metricSystem])

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Opciones de sexo
  const sexOptions = useRef<Word[]>(Texts.userSexOptions as Word[])

  // Reset form
  const handleReset = () : void => {
    setOpenModal(false)
  }

  // hadleSubmit
  const handleSubmit = (values: any) : void => {
    addUser({ 
      ...values, 
      birthday: moment(values.birthday).format('YYYY-MM-DD'),
      weight: metricSystem === 'metric_system' 
        ? values.weight 
        : Number((values.weight / 2.205).toFixed(2)),
      height: metricSystem === 'metric_system' 
          ? values.height 
          : Number((values.height * 2.54).toFixed(2)),
    })
  }

  const getWeight = () : number => {
    
    if (info.physiological_variables) {
      
      const weigth = info.physiological_variables.weight
      return metricSystem === 'metric_system' ? weigth : Number((weigth * 2.205).toFixed(2))
      
    }

    return metricSystem === 'metric_system' ? 30 : 67

  }

  const getHeigth = () : number => {
    
    if (info.physiological_variables) {
      const heigth = info.physiological_variables.height
      return metricSystem === 'metric_system' ? heigth : Number((heigth / 2.54).toFixed(2))
    }

    return metricSystem === 'metric_system' ? 120 : 48

  }

  return ( 
    <Dialog
      onClose = { handleReset }
      open = { openModal }
    >

      <DialogTitle id="simple-dialog-title">
        { info.name !== '' 
          ? getTranslation( Texts.usersModalUpdate as Word ) 
          : getTranslation( Texts.usersModalCreate as Word ) 
        }
      </DialogTitle>

      <Formik
        initialValues = {{
          email: info.email,
          name: capitalize(info.name || ''),
          last_name_1: capitalize(info.last_name_1),
          last_name_2: capitalize(info.last_name_2 || ''),
          pseudonym: info.pseudonym,
          birthday: info.birthday ? moment(info.birthday) : moment(),
          sex: info.sex || Gender.choose,
          weight: getWeight(),
          height: getHeigth(),
          resting_heart_rate: info.physiological_variables ? info.physiological_variables.resting_heart_rate : 60
        }}
        validationSchema = { validationSchema }
        enableReinitialize
        onSubmit = { values => handleSubmit( values ) }
      >

        { props => (
            
            <form className="p-5" onSubmit = { props.handleSubmit }>

              <div className="md:grid md:grid-cols-3 gap-3">

                <InputForm 
                  saveReference
                  type='text' 
                  id='name' 
                  background = "bg-card-body"
                  marginTop = "mt-0"
                  value={ props.values.name } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.name && !!props.errors.name )} 
                  placeholder={getTranslation( Texts.usersNameInputPlaceholder as Word )} 
                  title={`${ getTranslation( Texts.usersNameInput as Word ) } *`} 
                  errorText={ props.errors.name && 
                    getTranslation( Texts[props.errors.name] as Word) 
                  } 
                />

                <InputForm 
                  saveReference
                  type='text' 
                  id='last_name_1' 
                  background = "bg-card-body"
                  marginTop = "mt-4 md:mt-0"
                  value={ props.values.last_name_1 } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.last_name_1 && !!props.errors.last_name_1 )} 
                  placeholder = { getTranslation( Texts.usersLastNameInputPlaceholder as Word ) }
                  title={`${ getTranslation( Texts.usersLastNameInput as Word ) } *`} 
                  errorText={ props.errors.last_name_1 && 
                    getTranslation( Texts[props.errors.last_name_1] as Word) 
                  } 
                />

                <InputForm 
                  saveReference
                  type='text' 
                  id='last_name_2' 
                  background = "bg-card-body"
                  marginTop = "mt-4 md:mt-0"
                  value={ props.values.last_name_2 } 
                  handleChange={props.handleChange} 
                  handleBlur={props.handleBlur} 
                  isError={(props.touched.last_name_2 && !!props.errors.last_name_2 )} 
                  placeholder = { getTranslation( Texts.usersMLastNameInputPlaceholder as Word ) }
                  title={`${ getTranslation( Texts.usersMLastNameInput as Word ) }`} 
                  errorText={ props.errors.last_name_2 && 
                    getTranslation( Texts[props.errors.last_name_2] as Word) 
                  } 
                />
              </div>


              <InputForm 
                saveReference
                type='email' 
                id='email' 
                background = "bg-card-body"
                marginTop = "mt-4"
                value={ props.values.email } 
                handleChange={props.handleChange} 
                handleBlur={props.handleBlur} 
                isError={(props.touched.email && !!props.errors.email )} 
                placeholder = { getTranslation( Texts.loginEmailPlaceHolder as Word ) }
                title={`${ getTranslation( Texts.loginEmail as Word ) } *`} 
                errorText={ props.errors.email && 
                  getTranslation( Texts[props.errors.email] as Word) 
                } 
              />


              <div className = "md:flex mt-4">

                <div
                  style = {{ flexBasis: '70%', marginRight: '1rem' }}
                >
                  <InputForm
                    saveReference
                    type='text'
                    id='pseudonym'
                    background = "bg-card-body"
                    marginTop = "mt-0"
                    value={ props.values.pseudonym }
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    isError={(props.touched.pseudonym && !!props.errors.pseudonym )}
                    placeholder = { getTranslation(Texts.usersPseudonymInputPlaceholder as Word) }
                    title={`${ getTranslation(Texts.usersPseudonymInput as Word) } *`}
                    errorText={ props.errors.pseudonym &&
                      getTranslation( Texts[props.errors.pseudonym] as Word)
                    }
                  />
                </div>

                <InputSelect
                  name = 'sex'
                  label = {`${ getTranslation( Texts.usersSexInput as Word ) } *`}
                  divStyle = {{ flexBasis: '30%', marginTop: 0 }}
                  divClasses = 'mb-8 md:mb-0'
                  labelStyle = {{ marginBottom: '0.5rem' }}
                >
                  {sexOptions.current.map((sexOption: Word) => (
                        
                    <MenuItem 
                      key = { sexOption.label }
                      value = { sexOption.label }
                    >
                      { getTranslation( sexOption ) }
                    </MenuItem>

                  ))}
                </InputSelect>

              </div>

              <div className = "flex flex-col mt-4 mb-12">

                <label
                  htmlFor="birthday"
                  className = "uppercase mb-1"
                >
                  { getTranslation( Texts.usersBirthdayInput as Word ) } *
                </label>

                  <InputDatePicker
                    rounded 
                    lang={ lang }
                    value = { props.values.birthday } 
                    onChange = { 
                      date => props.handleChange({ target: { name: "birthday",value: date } }) 
                    }
                  />

                  { (props.errors.birthday )
                      && (
                        <InputError
                          colorLabel = "text-red-500"
                        >
                          { getTranslation( Texts.usersBirthDayInputError as Word ) }
                        </InputError>
                      ) 
                  }
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 mt-4 md:pb-2">

                <InputForm
                  saveReference
                  normalCase
                  type='number'
                  id='weight'
                  background = "bg-card-body"
                  marginTop = "mt-4 lg:mt-0"
                  inputMode = 'numeric'
                  value={ props.values.weight }
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  isError={(props.touched.weight && !!props.errors.weight )}
                  placeholder = "0"
                  title={`${ getTranslation(Texts.usersWeigthInput as Word) } (${metricSystem === 'metric_system' ? 'kg' : 'lb'})`}
                  errorText={ props.errors.weight &&
                    getTranslation( Texts[props.errors.weight] as Word)
                  }
                  min = { metricSystem === 'metric_system' ? 30 : 67 }
                  max = { metricSystem === 'metric_system' ? 190 : 418 } 
                  steps = '0.01'
                />

                <InputForm
                  normalCase
                  saveReference
                  type='number'
                  id='height'
                  background = "bg-card-body"
                  marginTop = "mt-4 md:mt-0"
                  inputMode = 'numeric'
                  value={ props.values.height }
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  isError={(props.touched.height && !!props.errors.height )}
                  placeholder = "0"
                  title={`${ getTranslation(Texts.usersHeightInput as Word) } (${metricSystem === 'metric_system' ? 'cm' : 'in'})`}
                  errorText={ props.errors.height &&
                    getTranslation( Texts[props.errors.height] as Word)
                  }
                  min = { metricSystem === 'metric_system' ? 120 : 48 }
                  max = { metricSystem === 'metric_system' ? 230 : 90 } 
                  steps = '0.01'
                />

                <InputForm
                  normalCase
                  saveReference
                  type='number'
                  id='resting_heart_rate'
                  background = "bg-card-body"
                  marginTop = "mt-4 md:mt-0"
                  inputMode = 'numeric'
                  value={ props.values.resting_heart_rate }
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  isError={(props.touched.resting_heart_rate && !!props.errors.resting_heart_rate )}
                  placeholder = "0"
                  title={`${ getTranslation(Texts.usersHeartRateInput as Word) } (lpm)`}
                  errorText={ props.errors.resting_heart_rate &&
                    getTranslation( Texts[props.errors.resting_heart_rate] as Word)
                  }
                  min = {40}
                  max = {120} 
                />
              </div>

              <div className = "md:flex md:justify-around mt-10">

                <button
                  type = "button"
                  className = "uppercase bg-secondary-background px-8 py-2 rounded-3xl w-full md:w-max"
                  onClick = { handleReset }
                >
                  { getTranslation( Texts.cancel as Word ) }
                </button>

                { value === 'adding'
                  ? (<CircularProgress />) 

                  : (
                    <button
                      type = "submit"
                      className = "uppercase bg-primary px-8 py-2 rounded-3xl w-full mt-8 md:mt-0 md:w-max"
                    >
                      { info.email !== '' 
                        ? getTranslation( Texts.save as Word )
                        : getTranslation( Texts.userSaveButtonModal as Word )
                      }
                      
                    </button>
                  ) 
                }

              </div>

            </form>
          )}


      </Formik>

    </Dialog>
  )
}
 
export default UserModal;