import { createMachine, assign, DoneInvokeEvent } from "xstate";
import * as Sentry from '@sentry/react'

import { 
  AuthData, 
  CenterData,
  CurrentUser, 
  DashboardData, 
  Features, 
  InitApp, 
  LocalStorageKey, 
  LoginBody, 
} from "../../types";
import axiosClient from '../../config/axios'
import authTokenAdmin from '../../config/authTokenAdmin'
import branchUUID from '../../config/uuidBranch'

type LoginMachineEvent = {
  type: 'SUBMIT',
  data: LoginBody
}

/**
 * message: mensaje de acuerdo al error
 * permissions: obtener los permisos
 * data: data para hacer autenticación
 * dashboard: datos de los cuadritos
 * currentUser: usuario loggeado actualemente
 * showAgreement: mostrar el modal de los permisos 
 * features: lista de features activados
 */
type LoginMachineContext = {
  centerData: CenterData | null,
  currentUser: CurrentUser | null,
  dashboard: DashboardData | null,
  data: LoginBody | null,
  features: Features,
  message: number,
  permissions: string[],
  showAgreement: boolean,
  uuidBranch: string,
}

type LoginResponse = {
  permissions: string[],
  showAgreement: boolean,
  'features_enabled': Features,
  uuidBranch: string,
  centerData: CenterData
}

/**
 * Iniciar sesión
 * @param data user y contraseña para hacer el login 
 */
const handleLogin = async (context: LoginMachineContext) : Promise<LoginResponse> => {

  const { data } = context

  if (!data) throw new Error()

  // Hacemos login
  const response = await axiosClient.post('/api/v2/auth/admin/login', data)

  // Formamos el objeto para despues guardar en el localstorage
  const authData: AuthData = {
    rememberme: data.rememberme,
    tokenAdmin: response.data.token_user,
    uuidUser: response.data.user_uuid,
    uuidBranch: response.data.branch_uuid,
    permissions: response.data.permissions,
    showAgreement: response.data.show_agreement,
    role: response.data.role,
    features_enabled: Array.isArray(response.data.features_enabled) ? {} : response.data.features_enabled
  }

  localStorage.setItem(LocalStorageKey.auth, JSON.stringify(authData))

  // Cargamos el 
  authTokenAdmin(response.data.token_user)

  // Cargamos el branch-uuid
  branchUUID(authData.uuidBranch)

  // Buscamos la información del centro
  const centerDataResponse = await axiosClient.get<CenterData>(
    `/api/v2/centers/${authData.uuidBranch}`,
  )

  return {
    centerData: centerDataResponse.data,
    features_enabled: authData.features_enabled,
    permissions: response.data.permissions,
    showAgreement: response.data.show_agreement,
    uuidBranch: authData.uuidBranch,
  }
  
}

/**
 * Preparar los datos del cluster
 * @returns Los datos de los clusters
 */
 const fetchDashboardData = async () : Promise<any> => {

  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const [ dashboard, currentUser ] = await Promise.all([
    axiosClient.get('/api/v2/admin/dashboard'),
    axiosClient.get(`/api/v2/admin/user/${authData.uuidUser}`)
  ])

  return {
    dashboard: dashboard.data,
    currentUser: currentUser.data
  }

}

const LoginMachine = createMachine<LoginMachineContext, LoginMachineEvent>(
  {
    id: 'Login',
    initial: 'idle',
    context: {
      centerData: null,
      currentUser: null,
      dashboard: null,
      data: null,
      features: {},
      message: 0,
      permissions: [],
      showAgreement: false,
      uuidBranch: '',
    },
    states: {
      idle: {},
      error: {},
      loading: {
        invoke: {
          id: "login",
          src: handleLogin,
          onError: {
            target: 'error',
            actions: assign((context, event) => {

              let message = event.data.response.status

              if (message === 401) {
                const { errors } = event.data.response.data
                const { auth } = errors

                if( auth[0].includes("verified") ) message = 10
                else if( auth[0].includes('Inactive') || auth[0].includes('inhabilitada'))
                    message = 12
              } 
              else {
                const errorMessage : string = 'No se pudo iniciar sesión.'

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: errorMessage,
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: `${errorMessage}, por falta de data`,
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                message,
              }
            })
          },
          onDone: {
            target: 'preparing',
            actions: assign({ 
              permissions: (_, event) => event.data.permissions,
              showAgreement: (_, event) => event.data.showAgreement,
              features: (_, event) => event.data.features_enabled,
              uuidBranch: (_, event) => event.data.uuidBranch,
              centerData: (_, event) => event.data.centerData
            })
          }
        }
      },
      preparing: {
        invoke: {
          id: 'dashboardData',
          src: fetchDashboardData,
          onDone: {
            target: 'success',
            actions: ['handleDashboardData']
          },
          onError: {
            target: 'success',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo descargar la data del dashboard.'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      success: {
        type: 'final',
      }
    },
    on: {
      SUBMIT: {
        target: '.loading',
        actions: ['onSubmit']
      }
    }
  },
  {
    actions: {
      onSubmit: assign((context, event) => ({
        ...context,
        data: event.type !== 'SUBMIT' ? context.data : event.data
      })),
      handleDashboardData: assign((context, _event:any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<InitApp> = _event

        // Si no se mando llamar del donde fetch admin hacemos un early return
        if (event.type !== "done.invoke.dashboardData") return context

        // Actualizamos el context de la maquina
        return {
          ...context,
          dashboard: event.data.dashboard,
          currentUser: event.data.currentUser
        }

      }),
    },
    // services: {
    //   login: (context, _) => context.data ? handleLogin( context.data )  : () => {}
    // }
  }
)

export default LoginMachine