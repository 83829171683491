import { useState, useEffect } from 'react';

import { Measurements, Training } from "../types";

type Props = {
  measurements: Measurements | null,
  inFlex: Training[],
  total: number,
  columns: number,
  isFullScreen: boolean,
}

type ToolBox = {
  isLG: boolean,
  basis: { grid: number, flex: number },
  widthInFlex: number,
  getWidth: (n: number) => number | null,
  getClusterHeight: () => string | null,
  getImageSize: (n: number) => string
}

const useSizes = ({ measurements, inFlex, total, columns, isFullScreen } : Props) : ToolBox => {
  
  const [basis, setBasis] = useState({ grid: 100, flex: 100 })
  const [widthInFlex, setWidthInFlex] = useState(40)
  const [is2xl, setIs2xl] = useState(false)
  const [isLG, setIsLG] = useState(false)
    
  useEffect(() => {
    
    if (typeof window !== 'undefined') {
      const screenWide = window.matchMedia('(min-width: 1536px)')
      if (screenWide) {

        handleMatch(screenWide)

        if (screenWide?.addEventListener) screenWide.addEventListener('change', handleMatch)

        else screenWide.addListener(handleMatch)
        
      }

      const semiScreen = window.matchMedia('(min-width: 1024px)')

      if (semiScreen) {
        handleMatchSemiScreen(semiScreen)

        if (semiScreen?.addEventListener) 
          semiScreen.addEventListener('change', handleMatchSemiScreen)

        else semiScreen.addListener(handleMatchSemiScreen)

      }

    }
  }, [])

  useEffect(() => {
    
    if (measurements && inFlex.length > 0) {
      const bridgeRows = measurements.data.length / columns
      let gridBasis = 50
      let flexBasis = 50
      let tempWidth = 40

      if (bridgeRows === 1 && inFlex.length === 2) {
        tempWidth = 35
      }

      if (bridgeRows === 2 && inFlex.length === 1) {
        tempWidth = 40
        gridBasis = 70
        flexBasis = 30
      }

      if (bridgeRows === 2 && columns === 3 && inFlex.length === 1) {
        tempWidth = 30
        gridBasis = 68
        flexBasis = 32
      }
      
      if (bridgeRows === 2 && columns === 3 && inFlex.length === 2) {
        tempWidth = 35
        gridBasis = 66
        flexBasis = 34
      }

      if (columns === 4) {
        tempWidth = 24.5
        gridBasis = 67
        flexBasis = 33
      }

      setBasis({ grid: gridBasis, flex: flexBasis })
      setWidthInFlex(tempWidth)
      
    }
    else {
      setBasis({ grid: 100, flex: 100 })
    }

  }, [measurements, inFlex])

  /**
   * Determinar el ancho de un cluster
   * @param n número de clusters
   * @returns un porcentaje de ancho
   */
  const getWidth = (n: number) : number | null => {

    if (!isFullScreen) return 90

    if (columns === 5) return 100

    if (n === 3) return 80

    if (n === 7) return 90

    return 100

  }

  /**
   * Sacar la altura que debe tener el cluster
   * @returns altura del cluster
   */
  const getClusterHeight = () : string | null => {
    
    if (!measurements) return null

    // if (hasStreaming && total === 1) return '70%'

    if (total > 12 || columns === 5) return '100%'

    if (measurements.data.length === 2 && inFlex.length === 0)
      return '70%'

    return null

  }

  /**
   * Obtener el tamaño de la imagen con streaming
   * @param n cantidad de clusters
   * @returns el tamaño en rems
   */
  // const getImageSizeWithStreaming = (n: number) : string => {
    
  //   if (is2xl) {
  //     switch (n) {
  //       case 1: return '18rem'
  //       case 2: return '12rem'
  //       case 3: return '9.5rem'
  //       case 5: return '8rem'
      
  //       default: return '9.5rem'
  //     }
  //   }

  //   if (isLG) {
  //     switch (n) {
  //       case 1: return '12rem'
  //       case 2: return '9.5rem'
  //       case 5: return '5rem'

  //       default: return '8rem'
  //     }
  //   }

  //   switch (n) {
  //     case 1: return '12rem'
  //     case 2: return '9.5rem'
  //     case 3: 
  //     case 4:
  //       return '7rem'

  //     default: return '6rem'
  //   }

  // }

  /**
   * Calcular el tamaño de la imagen en el cluster
   * @param n cantidad de clusters
   * @returns el tamaño de imagen en rems
   */
  const getImageSize = (n: number) : string => {
    
    if (!isFullScreen) return '4rem'
    if (columns === 5) return isLG ? '6rem' : '4rem'

    // if (hasStreaming) return getImageSizeWithStreaming(n)

    if (is2xl) {
      switch (n) {
        case 1:
          return '26rem'

        case 2:
          return '18rem'

        case 3:
        case 4:
        case 5:
        case 6:
          return '12rem'
          
        case 7:
        case 8:
        case 9:
          return '8rem'
  
        default: return '8.5rem'
      }
    }
    
    if (isLG) {
      switch (n) {
        case 1:
          return '18rem'

        case 2:
          return '12rem'

        case 3:
        case 4:
        case 6:
          return '9rem'
          
        case 5:
          return '8.5rem'
          
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          return '5rem'
  
        default: return '6rem'
      }
    }

    switch (n) {
      case 1:
        return '26rem'
        
      case 2:
        return '12rem'

      case 3:
      case 4:
        return '9rem'

      case 5:
      case 6:
        return '8.5rem'
        
      case 7:
      case 8:
      case 9:
        return '7rem'

      default: return '6rem'
    }

  }

  /**
   * Saber cuando estamos >= 1536px
   * @param screen mediaquery
   */
  const handleMatch = (screen: any) : void => {
    if (screen.matches) {
      setIs2xl(true)
    }
    else{
      setIs2xl(false)
    }
  }

  /**
   * Saber cuando estamos >= 1024px
   * @param screen mediaquery
   */
  const handleMatchSemiScreen = (screen: any) : void => {
    if (screen.matches) {
      setIsLG(true)
    }
    else{
      setIsLG(false)
    }
  }

  return {
    isLG,
    basis,
    widthInFlex,
    getWidth,
    getClusterHeight,
    getImageSize
  }

} 

export default useSizes;