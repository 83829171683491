import React, { FC, HTMLAttributes, ReactElement, useLayoutEffect, useRef, useState } from 'react'
import { fromEvent, debounceTime, pluck, Subscription } from 'rxjs';
import InputError from './InputError';

interface InputFormProps extends HTMLAttributes<HTMLElement> {
  background ?: string,
  errorText ?: string,
  extraButtonText ?: string
  icon ?: ReactElement,
  id: string,
  initialCount ?: number,
  isError ?: boolean,
  labelClass ?: string,
  marginTop ?: string,
  max ?: number,
  maxCountValue ?: number,
  min ?: number,
  normalCase ?: boolean,
  placeholder: string,
  readOnly ?: boolean,
  saveReference ?: boolean,
  steps ?: string
  title: string,
  type: string,
  value: any,
  handleChange: (e: any) => void,
  handleBlur: (e: any) => void,
  handleExtraButton ?: () => void,
}
 
/**
 * Input con label
 * @param props contiene todas las propiedades necesarias descritas en InputFormProps
 * @param props.type del input email, text, ...
 * @param props.inputMode indicar el tipo de teclado para móviles
 * @param props.placeholder placeholder el input
 * @param props.id del input
 * @param props.icon que va a la izquierda del input
 * @param props.title que va en con el input
 * @param props.value valor del input
 * @param props.isError saber si se debe mostrar el error en el input
 * @param props.errorText texto del error
 * @param props.readOnly saber si es solo lectura
 * @param props.min si es input tipo number el mínimo
 * @param props.max si es input tipo number el max
 * @param props.background del input si se ocupa otra a la del defecto
 * @param props.marginTop cambiar el margin por defecto
 * @param props.extraButtonText saber si se tiene que agregar un botón al lado derecho
 * @param props.labelClass cambiar las clases del label
 * @param props.steps si es input tipo number los saltos
 * @param props.handleChange función para cambiar el valor del input
 * @param props.handleBlur función para cambiar el valor del input
 * @param props.handleExtraButton función para el botón extra
 * @param props.normalCase para saber si hacer mayúsculas el titulo
 * @returns input para formularios
 */
const InputForm: FC<InputFormProps> = ({ 
  type, inputMode, placeholder, id, icon, title, value, isError, errorText, readOnly, min, max,
  background = "bg-input-background", marginTop = "mt-8", extraButtonText, labelClass = '', 
  steps = '1', normalCase = false, saveReference = false, maxCountValue, initialCount = 0,
  handleChange, handleBlur, handleExtraButton
}) => {

  const inputRef = useRef<HTMLInputElement | null>(null);
  const subscription = useRef<Subscription | null>(null);

  const [count, setCount] = useState(initialCount)

  useLayoutEffect(() => {

    if (inputRef.current && maxCountValue) {

      subscription.current = fromEvent( inputRef.current, 'keyup' )
        .pipe(
          debounceTime(300),
          pluck('target', 'value'),
        )
        .subscribe(txt => setCount((txt as string).length))

    }

    return () => {
      subscription.current?.unsubscribe()
    }

  }, [maxCountValue])

  return ( 
    <div className = {`flex flex-col ${marginTop}`}>

      <label 
        htmlFor={ id }
        className = {`${normalCase ? 'normal-case' : 'uppercase'}  ${labelClass}`}
      >
        { title }
      </label>

      <div
        style = { extraButtonText ? { flexBasis: '90%'} : {}}
        className={`flex items-center ${background} py-2 px-3 rounded-xl mt-2 border ${ isError ? 'border-red-600' :'hover:border-white border-transparent '}`}
      >
        { icon || null }
      
        <input
          ref = { inputRef }
          type= { type }
          name={ id }
          id={ id }
          inputMode = { inputMode }
          readOnly = { readOnly }
          className = {`appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${background} focus:${background} ${readOnly ? 'bg-card-header text-gray-400' : 'bg-card-body'}`}
          placeholder = { placeholder }
          value = { value }
          onChange = { handleChange }
          onBlur = { handleBlur }
          min = { min || 0 }
          max = { max || 999 }
          step = { steps }
        />

        { (extraButtonText && handleExtraButton) && (
          <button 
            style = {{ flexBasis: '10%' }}
            type = "button"
            onClick = { handleExtraButton }
            className = 'uppercase text-primary'
          >
            { extraButtonText }
          </button>
        )}
        
      </div>

      { (maxCountValue) && (
        <p className='text-right text-gray-400'>
          { count }/{ maxCountValue }
        </p>
      )}

      { isError
        && (
          <InputError
            colorLabel = "text-red-500"
          >
            { errorText }
          </InputError>
        ) 
      }

      { (saveReference && !isError) && (
        <p className='invisible mt-2'>Referencia de espacio</p>
      )}

    </div>
  )
}
export default InputForm;