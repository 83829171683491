import React, { FC } from 'react'

// Dependencies
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// Icon
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// Hooks
import useTranslation from '../../../hooks/useTranslation'

interface RButtonBackProps {
  background ?: string,
  iconColor ?: string,
  action: () => void
}
 
const RButtonBack: FC<RButtonBackProps> = ({ 
  background = 'bg-low-gray', iconColor = 'black', action 
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className={`${background} rounded-full`}>
      <Tooltip
        title={ getTranslationByKey('back') }
      >
        <IconButton
          onClick={ action }
        >
          <ChevronLeftIcon
            fontSize='large'
            sx = {{ color: iconColor }}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}
 
export default RButtonBack;