import React, { FC, CSSProperties } from 'react'

// Dependencies
import styled from 'styled-components'

type ImageProps = {
  image ?: string,
  size ?: { mobile: string, tablet: string, extra: string }
}

export const Image = styled.div<ImageProps>`
  width: ${props => props.size ? props.size.mobile : '100%'};
  height: ${props => props.size ? props.size.mobile : '100%'};
  background-image: ${props => props.image ? `url(${props.image})` : 'url(/images/avatar.png)'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    width: ${props => props.size ? props.size.tablet : '12rem'};
    height: ${props => props.size ? props.size.tablet : '12rem'};
  }

  @media (min-width: 1536px) {
    width: ${props => props.size ? props.size.extra : '16rem'};
    height: ${props => props.size ? props.size.extra : '16rem'};
  }
`

interface RImageProps {
  image ?: string,
  size ?: { mobile: string, tablet: string, extra: string }
  style ?: CSSProperties,
  sx ?: CSSProperties
}
 
const RImage: FC<RImageProps> = ({ image, size, style, sx }) => {
  return (  
    <Image 
      image = {image}
      size = { size }
      style = {{ ...style, ...sx }}
    />
  );
}
 
export default RImage;