import React, { FC, CSSProperties } from 'react';

// Dependencies
import shortid from 'shortid'

interface RookTooltipListProps {
  className ?: string
  sx ?: CSSProperties
  style ?: CSSProperties
  list : string[]
}
 
const RookTooltipList: FC<RookTooltipListProps> = ({ list, className, sx, style }) => {
  return ( 
    <ul 
      className={`list-decimal px-5 ${className || ''}`}
      style = {{ ...style, ...sx }}
    >
      {list.map(item => (
        <li
          key = { shortid.generate() }
        >
          { item }
        </li>
      ))}
    </ul>
  );
}
 
export default RookTooltipList;