import * as Yup from 'yup';

export default (metricSystem: string) => (
  Yup.object().shape(
    {
      name: Yup.string()
                .required('usersNameInputError')
                .min(3, 'usersNameInputError')
                .max(50, 'usersNameInputLengthError'),
      email: Yup.string()
                  .email('loginEmailValidError')
                  .required('loginEmailRequiredError'),
      last_name_1: Yup.string()
                      .required('usersLastNameInputError')
                      .min(4, 'usersLastNameInputError')
                      .max(100, 'usersLastNameInputLengthError'),
      last_name_2: Yup.string()
                      .notRequired()
                      .when('last_name_2', {
                        is: (v: any) => v,
                        then: rule => rule.min(4, 'usersMLastNameInputError')
                                          .max(100, 'usersMLastNameInputLengthError')
                      }),
      pseudonym: Yup.string()
                      .required('usersPseudonymInputError')
                      .min(2, 'usersPseudonymInputLengthError')
                      .max(100, 'usersPseudonymInputLengthError'),
      birthday: Yup.date()
                      .required('usersBirthDayInputError')
                      .max(new Date(), 'usersBirthDayInputError'),
      sex: Yup.string()
                .required('usersSexInputError')
                .matches(/^female|male$/, 'usersSexInputError'),
      weight: Yup.number()
                  .required('userInfoError')
                  .positive('userInfoError')
                  .min(
                    metricSystem === 'metric_system' ? 30 : 67, 
                    metricSystem === 'metric_system' 
                      ? 'userWeigthError' 
                      : 'userEnglishWeigthError'
                  )
                  .max(
                    metricSystem === 'metric_system' ? 190 : 418, 
                    metricSystem === 'metric_system' 
                      ? 'userWeigthError' 
                      : 'userEnglishWeigthError'
                  ),
      height: Yup.number()
                  .required('userInfoError')
                  .positive('userInfoError')
                  .min(
                    metricSystem === 'metric_system' ? 120 : 48, 
                    metricSystem === 'metric_system'
                      ? 'userHeightError' 
                      : 'userEnglishHeightError'
                  )
                  .max(
                    metricSystem === 'metric_system' ? 230 : 90, 
                    metricSystem === 'metric_system'
                      ? 'userHeightError' 
                      : 'userEnglishHeightError'
                  ),
      resting_heart_rate: Yup.number()
                            .required('userInfoError')
                            .positive('userInfoError')
                            .min(40, 'userRestingError')
                            .max(100, 'userRestingError'),
      
    },
    [['last_name_2', 'last_name_2']]
  )
)