import React, { FC, useEffect, useState }  from 'react'

// Dependecies
import Drawer  from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';

// Componentes
import Bar from './Bar'
import Navigation from './Navigation'

// Estilos para el check para de recordar sesión
const useStyles = makeStyles({
  paper: {
    background: "#212121",
    color: "white"
  }
})

type LayoutProps = {
  isInvisible ?: boolean
}

/**
 * Wrapper de las dos barras y el contenido central de la página
 * @param {Object} props contiene todas las propiedades necesarias descritas en LayoutProps
 * @param {boolean} props.isInvisible para ocultar las barras esto se usa para remote
 * @returns contenedor principal
 */
const Layout: FC<LayoutProps> = ({ isInvisible, children }) => {

  // Bandera para saber si mostrar la navegación en un drawer o no
  const [showInDrawer, setShowInDrawer] = useState(true)

  // Mostrar el menu o no
  const [showDrawer, setShowDrawer] = useState(false)

  // Estilos del drawer
  const styles = useStyles()

  // Hacer un mediaquery con Js para mostrar en drawer o normal
  useEffect(() => {
    
    if (typeof window !== 'undefined') {
      // Inicializamos el query
      const screenWide = window.matchMedia('(max-width: 1279px)')

      if (screenWide) {

        handleMatch(screenWide)

        // Si existe la función addEventListener si no el else para navegadores viejos
        if (screenWide?.addEventListener) screenWide.addEventListener('change', handleMatch)

        else screenWide.addListener(handleMatch)
        
      }
    }
  }, [])

  /**
   * Cachar si la pantalla entra dentro del query
   * @param screen resultado del mediaQuery
   */
  const handleMatch = (screen: any) : void => {
    if (screen.matches) {
      setShowInDrawer(true)
    }
    else{
      setShowInDrawer(false)
    }
  }

  return (
    <>

      <div className={`sm:flex ${ !isInvisible && 'mt-0'}`}>

        { !isInvisible && (
          showInDrawer 
            ? (
              <Drawer
                classes = { styles }
                anchor = "left"
                open = { showDrawer }
                onClose = { () => setShowDrawer(false) }
              >
                <Navigation />
              </Drawer>
            ) 
            : (
              <Navigation />
            ) 
        )}

        <div 
          className={`flex flex-col w-full my-0 mx-auto transition-all h-screen overflow-y-auto
            transition-all
            ${isInvisible ? 'px-5 md:px-8': 'p-5 md:p-8'}
            `
          }
        >
          { !isInvisible && (
            <Bar 
              openNavigationInDrawer = { () => setShowDrawer(true) }
            />
          )}

          {children}
        </div>

      </div>
    </>
  )
}

export default Layout;