import { createMachine, assign, DoneInvokeEvent } from "xstate";

// Dependencies
import lodash from 'lodash'
import * as Sentry from "@sentry/react";
import moment from "moment";


// Types
import { 
  ShowChallenge, UserDetail, CompletedGoal, 
  SearchIn, UserChallenge, Room, ChallengeTeam,
  ChallengeRoom,
  TeamMetrics,
  TeamMetric,
  Goal
} from "../../../types";

// Cliente HTTP
import axiosClient from '../../../config/axios'

type FirstFetch = {
  data: ShowChallenge,
  lastPage: number
}

type NextFetch = {
  data: UserChallenge[],
  lastPage: number
}

/**
 * pageUserData: se usa para filtrar la data de acuerdo al buscador
 * pageRoomData: se usa para filtrar la data de acuerdo al buscador
 * userDetail: en caso que sea un reto individual saber que usuario se esta visualizando
 * goalsDetail: Saber la lista de objetivos para saber si esta completo y su acumulado
 * teamRoom: saber a que room/team pertenecen los miembros que se están observando
 */
type GamingShowMachineContext = {
  backUpData: ShowChallenge | null,
  backUpRoomID: string,
  currentPage: number,
  currentPageBackUp: number,
  data: ShowChallenge | null,
  goalsDetail: CompletedGoal[] | null,
  isMembersView: boolean,
  lastPage: number
  lastPageBackUp: number
  pageRoomData: Room[],
  pageUserData: UserChallenge[],
  pageUserDataBackUp: UserChallenge[],
  removeUser: { user: string, teamRoom: string } | null,
  roomUUID: string,
  search: SearchIn | null,
  statusError: number,
  teamMetrics: TeamMetrics | null,
  teamRoom: { name: string, uuid: string } | null,
  teamUUID: string,
  userDetail: UserDetail | null,
  userMetrics: TeamMetrics | null,
  usersInChallenge: number,
  uuidChallenge: string,
}

/**
 * INIT: cargar el uuid del challenge
 * REVIEW: obtener los objetivos completados por el usuario a ver
 * CHECK: obtener los objetivos completados por el equipo a ver
 * PROGRESS: obtener los objetivos completados por el sala a ver
 * HIDE: Cambiar de estado para cerrar alertas
 * CONVERT: Sacar a los miembros del equipo a una vista aparte
 */
type GamingShowMachineEvent = 
  | { type: 'INIT', uuidChallenge: string }
  | { type: 'REVIEW', data: UserDetail }
  | { type: 'CHECK', data: string }
  | { type: 'PROGRESS', data: string }
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'REMOVE', data: {user: string, teamRoom: string}}
  | { type: 'CONVERT', data: { name: string, uuid: string}}
  | { type: 'UPDATE' }
  | { type: 'HIDE' }
  | { type: 'BACK' }

/**
 * Descargar la información del reto
 * @param context de la maquina que controla la vista de ver el reto
 * @returns la información del reto
 */
const fetchChallenge = async (context: GamingShowMachineContext) : Promise<FirstFetch> => {
  
  const { uuidChallenge } = context

  const response = await axiosClient({
    url: `/api/v1/challenges/${uuidChallenge}`,
    baseURL: process.env.REACT_APP_GM
  })

  let lastPage = 1

  // Si es individual por usuario
  if (
    response.data.data.challenge_type_id === 1 
    && response.data.data.challenge_modality_id === 1
  ) {
    const users = await axiosClient({
      url: `/api/v2/users/challenges/${uuidChallenge}`,
      baseURL: process.env.REACT_APP_GM,
    })

    lastPage = users.data.meta.last_page

    response.data.data.challenge_users = { data: users.data.data }
  }

  // Si es individual por sala
  if (
    response.data.data.challenge_type_id === 1 
    && response.data.data.challenge_modality_id === 3
  ) {

    const room: string = response.data.data.challenge_rooms[0]?.room_uuid || ''

    if (room) {
      const users = await axiosClient({
        url: `/api/v2/users/challenges/${uuidChallenge}/room/${room}`,
        baseURL: process.env.REACT_APP_GM
      })
      
      lastPage = users.data.meta.last_page

      response.data.data.challenge_rooms[0].users = { data: users.data.data }
    }
  }

  return {
    data: response.data.data,
    lastPage,
  }

}

/**
 * Eliminar un usuario del reto
 * @param context de la maquina
 * @returns el reto actualizado
 */
const deleteUserOfChallenge = async (context: GamingShowMachineContext) : Promise<ShowChallenge> => {
  
  const { uuidChallenge, removeUser } = context

  if (!removeUser) throw new Error();

  const response = await axiosClient({
    url: `/api/v2/challenges/${uuidChallenge}/users/${removeUser.user}`,
    baseURL: process.env.REACT_APP_GM,
    method: 'POST',
    data: { source: 'admin' }
  })

  return response.data.data
}

/**
 * It fetches the user's metrics for a given challenge
 * @param {GamingShowMachineContext} context - GamingShowMachineContext
 * @returns An array of GoalCompletedMetrics
 */
const fetchUserMetrics = async (context: GamingShowMachineContext) : Promise<TeamMetrics> => {

  const { userDetail, uuidChallenge } = context

  if (!userDetail || uuidChallenge === '') throw new Error();

  const response = await axiosClient({
    url: `/api/v2/challenges/${uuidChallenge}/users/${userDetail.user.user_uuid}/metrics`,
    baseURL: process.env.REACT_APP_GM,
    method: 'GET'
  })

  return response.data

}

/**
 * It fetches the team metrics for a given team
 * @param {GamingShowMachineContext} context - GamingShowMachineContext
 */
const fetchTeamMetrics = async (context: GamingShowMachineContext) : Promise<TeamMetrics> => {
  
  const { teamUUID } = context

  if (!teamUUID) throw new Error("");
  
  const response = await axiosClient({
    url: `/api/v2/team/${teamUUID}/metrics`,
    baseURL: process.env.REACT_APP_GM,
    method: 'GET'
  })

  return response.data

}

const fetchRoomMetrics = async (context: GamingShowMachineContext) : Promise<TeamMetrics> => {
  
  const { roomUUID, uuidChallenge } = context

  if (!roomUUID) throw new Error("");
  
  const response = await axiosClient({
    url: `/api/v2/challenges/${uuidChallenge}/room/${roomUUID}/metrics`,
    baseURL: process.env.REACT_APP_GM,
    method: 'GET'
  })

  return response.data

}

const nextUsersPage = async (context: GamingShowMachineContext) : Promise<NextFetch> => {
  
  const { uuidChallenge, data, currentPage, backUpRoomID, teamRoom, search } = context

  if (!uuidChallenge || !data) throw new Error()

  // baseURL para las búsquedas/pagination
  let endpoint = `/api/v2/users/challenges/${uuidChallenge}`

  // parámetros para hacer la búsqueda/pagination
  const params : any = {
    page: currentPage,
  }

  // Verificamos que haya una búsqueda
  if (search && search.search !== '-1') {
    params.search = search.search
    params.search_column = search.search_column
  }

  // Si es individual por sala
  if ( data.challenge_type_id === 1 && data.challenge_modality_id === 3 && backUpRoomID ) {
    endpoint = `${endpoint}/room/${backUpRoomID}`
  }

  // Si es grupal
  if ( data.challenge_type_id === 2 ) {

    let type = 'room'
    if( data.challenge_modality_id === 2 ) type = 'team'

    endpoint = `${endpoint}/${type}/${teamRoom?.uuid}`
    
  }

  const users = await axiosClient({
    url: endpoint,
    baseURL: process.env.REACT_APP_GM,
    params
  })

  return {
    lastPage: users.data.meta.last_page,
    data: users.data.data,
  }

}

/**
 * Ordenar a los equipos
 * @param data del reto
 * @returns ordenar a los equipos del reto
 */
const sortTeams = (data: ShowChallenge) : ChallengeTeam[]=> {
 
  const sorted = data

  if (!data.challenge_teams || !sorted.challenge_teams) return []

  sorted.challenge_teams = data.challenge_teams.map(current => {

    let earned: number = 0

    data.goals.forEach( goal => {

      if (goal.user_team_completed) {

        const filtered = goal.user_team_completed.filter(
          i => i.uuid === current.team_uuid
        )
        earned += filtered.length

      }

    })

    return {
      ...current,
      earned
    }

  })

  return lodash.orderBy(
    sorted.challenge_teams, 
    ['progress','earned', 'name'],
    ['desc', 'desc', 'asc']
  )
  
}

/**
 * Ordenar a las salas
 * @param data del reto
 * @returns ordenar a las salas del reto
 */
const sortRooms = (data: ShowChallenge) : ChallengeRoom[]=> {
 
  const sorted = data

  if (!data.challenge_rooms || !sorted.challenge_rooms) return []

  sorted.challenge_rooms = data.challenge_rooms.map(current => {

    let earned: number = 0

    data.goals.forEach( goal => {

      if (goal.user_room_completed) {

        const filtered = goal.user_room_completed.filter(
          i => i.uuid === current.uuid || i.uuid === current.room_uuid
        )
        earned += filtered.length

      }

    })

    return {
      ...current,
      earned
    }

  })

  return lodash.orderBy(
    sorted.challenge_rooms, 
    ['progress', 'earned', 'name'],
    ['desc', 'desc', 'asc']
  )
  
}

/**
 * Ordenar a los usuarios
 * @param data del reto
 * @returns ordenar a los participantes del reto cuando es por equipo
 */
 const getEarnedGoals = (data: ShowChallenge, users: UserChallenge[]) : UserChallenge[]=> {
  
  const newUsers: UserChallenge[]  = users.map( user => {

    let earned = 0;
    let points = 0;

    data.goals.forEach( goal => {

      if (goal.user_goal_completed){
        const found = goal.user_goal_completed.find(
          i => i.user_uuid === user.user_uuid
        )

        if (found) {
          earned += 1
          points += Number(goal.points)
        }

      }

    })

    return {
      ...user,
      earned,
      points,
      completed_at: user.completed_at || '',
      name: user.name ? user.name.toLocaleLowerCase() : "-",
      sum_points_metrics: Number(user.sum_points_metrics || '0') 
    }

  })


  return newUsers
}

const transformChallenge = (challenge: ShowChallenge) : ShowChallenge | null => {
  
  const data = { ...challenge }

  if (data.challenge_rooms){
    delete data.challenge_rooms
    return data
  }

  if (data.challenge_teams) {
    delete data.challenge_teams
    return data
  }

  return null

}

/**
 * Add the level of the goal
 * @param {TeamMetric[]} metrics - TeamMetric[]
 */
const labelingMetrics = (metrics: TeamMetric[]) : TeamMetric[]=> {
  
  let counter = 1
  let previous = ''
  const copy = metrics

  for (let index = 0; index < copy.length; index = index + 1) {
    
    if (previous === copy[index].goal_type) counter = counter + 1
    else {
      counter = 1
      previous = copy[index].goal_type
    }

    copy[index] = { ...copy[index], level: counter } 

  }

  return copy

}

/**
 * Fill with empty metrics if it does not exits
 * @param {Goal[]} goals - Goal[] - this is the array of goals that we want to fill the metrics with
 * @param {boolean} isFree - boolean - this is a boolean that tells us if the challenge is a free team or  not.
 * @returns An array of TeamMetric objects
 */
const fillMetrics = (goals: Goal[], isFree: boolean) : TeamMetric[] => {
  
  if (goals.length === 0) return []

  const metrics: TeamMetric[] = []

  goals.forEach(goal => {
    
    const meta = Number.isNaN(Number(goal.meta))
      ? 0
      : Number(goal.meta)

    metrics.push({
      goal_type: goal.goal_type,
      accumulated: 0,
      meta: isFree ? null : meta,
      target: `0/${goal.meta}`,
      completed: false
    })

  })

  return metrics

}

/**
 * Maquina que controla la vista de ver reto
 */
export default createMachine<GamingShowMachineContext, GamingShowMachineEvent>(
  {
    id: 'GamingShowMachine',
    initial: 'starting',
    context: {
      backUpData: null,
      backUpRoomID: '',
      currentPage: 1,
      currentPageBackUp: 1,
      data: null,
      goalsDetail: null,
      isMembersView: false,
      lastPage: 1,
      lastPageBackUp: 1,
      pageRoomData: [],
      pageUserData: [],
      pageUserDataBackUp: [],
      removeUser: null,
      roomUUID: '',
      search: null,
      statusError: 0,
      teamMetrics: null,
      teamRoom: null,
      teamUUID: '',
      userDetail: null,
      userMetrics: null,
      usersInChallenge: 0,
      uuidChallenge: '',
    },
    states: {
      login: {},
      downloading: {
        invoke: {
          id: 'nextUsersPage',
          src: nextUsersPage,
          onDone: {
            target: 'idle',
            actions: [ 'appendUsers' ]
          },
          onError: {
            target: 'idle'
          }
        }
      },
      idle: {
        entry: 'resetDetail',
        on: {
          UPDATE: {
            target: 'downloading',
            actions: assign({ currentPage: (context) => context.currentPage + 1 })
          }
        }
      },
      starting: {
        on: {
          INIT: {
            target: 'loading',
            actions: assign({ uuidChallenge: (_, event) => event.uuidChallenge})
          }
        }
      },
      loading: {
        invoke: {
          id: 'fetchChallenge',
          src: fetchChallenge,
          onDone: {
            target: 'idle',
            actions: [ 'prepareData' ]
          }
        }
      },
      reviewing: {
        invoke: {
          id: 'fetchUserMetrics',
          src: fetchUserMetrics,
          onDone: {
            actions: [
              'checkCompletedGoals'
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo consultar las métricas del usuario',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo consultar las métricas del usuario',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo consultar las métricas del usuario por falta de data',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      loaded: {},
      checking: {
        invoke: {
          id: 'fetchTeamMetrics',
          src: fetchTeamMetrics,
          onDone: {
            target: 'loaded',
            actions: ['checkTeamCompletedGoals']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo consultar las métricas del equipo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo consultar las métricas del equipo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo consultar las métricas del equipo por falta de data',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      seeking: {
        invoke: {
          id: 'fetchRoomMetrics',
          src: fetchRoomMetrics,
          onDone: {
            target: 'loaded',
            actions: ['checkRoomCompletedGoals']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo consultar las métricas del equipo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo consultar las métricas del equipo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo consultar las métricas del equipo por falta de data',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      searching: {
        always: [
          { target: 'idle', actions: ['searchRoom'], cond: 'isRoomChallenge'},
          { target: 'idle', actions: ['resetData'], cond: 'isSearchEmpty'},
        ],
        invoke: {
          id: 'nextUsersPage',
          src: nextUsersPage,
          onDone: {
            target: 'idle',
            actions: ['appendUsers']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar a los usuarios',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo descargar a los usuarios',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      removed: {
        after: { 
          3400: { target: 'idle' }
        }
      },
      removing: {
        invoke: {
          id: 'deleteUserOfChallenge',
          src: deleteUserOfChallenge,
          onDone: {
            target: 'removed',
            actions: [ 'removeUserOfData' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo eliminar al usuario',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo eliminar al usuario',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      converting: {
        invoke: {
          id: 'nextUsersPage',
          src: nextUsersPage,
          onDone:{
            target: 'idle',
            actions: ['convertIntoIndividual']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar a los usuarios',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo descargar a los usuarios',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      REVIEW: {
        target: '.reviewing',
        actions: [
          assign({ userDetail: (_, event) => event.data })
        ]
      },
      CHECK: {
        target: '.checking',
        actions: assign({ teamUUID: (_, event) => event.data })
      },
      PROGRESS: {
        target: '.seeking',
        actions: assign({ roomUUID: (_, event) => event.data })
      },
      HIDE: {
        target: '.idle'
      },
      SEARCH: {
        target: '.searching',
        actions: ['prepareSearch']
      },
      REMOVE: {
        target: '.removing',
        actions: assign({ removeUser: (_, event) => event.data })
      },
      CONVERT: {
        target: 'converting',
        actions: [
          assign({ teamRoom: (_, event) => event.data })
        ]
      },
      BACK: {
        actions: ['goBack']
      }
    }
  },
  {
    actions: {
      prepareData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<FirstFetch> = _event
        const { data, lastPage } = event.data

        if (event.type !== 'done.invoke.fetchChallenge') return { ...context }

        let usersInChallenge = 0
        let backUpRoomID = ''

        // Determinamos si el estado del reto
        let status : 'active' | 'pending' | 'completed' = 'active'

        const currentDate = moment.utc(moment.utc().format())
        const challengeStart = moment.utc(data.start_at)
        const challengeEnd = moment.utc(data.finish_at)

        if (currentDate < challengeStart) status = 'pending'
        else if (currentDate > challengeEnd) status = 'completed'

        // Guardamos el status
        data.status = status

        // grupal por equipos
        if (event.data.data.challenge_teams && data.challenge_teams) {

          data.challenge_teams = data.challenge_teams.map( team => {

            if (team.users_in_team)
              usersInChallenge = usersInChallenge + team.users_in_team.data

            if (team.users && data.challenge_compliance === 'free') {

              let rookpoints = 0 

              team.users.forEach( user => {
                rookpoints = rookpoints + Number(user.rookpoints)
              })

              return {
                ...team,
                rookpoints,
              }
            }

            return team

          })

          // data.challenge_teams = getProgressTeam(data)
          data.challenge_teams = sortTeams(data)
          // data.challenge_teams = sortTeamMembers(data)

        }

        // Si el reto es individual por sala
        if (
          event.data.data.challenge_modality_id === 3
          && event.data.data.challenge_type_id === 1
        ){
          if (data.challenge_rooms){

            const { users } = data.challenge_rooms[0]

            data.challenge_users = { data: users || [] }

            backUpRoomID = data.challenge_rooms[0].room_uuid || ''

            delete data.challenge_rooms

          }
        }

        // grupal por sala
        if (data.challenge_rooms) {

          data.challenge_rooms = data.challenge_rooms.map( room => {
            
            if (room.users_in_room)
              usersInChallenge = usersInChallenge + room.users_in_room.data

            if (room.users)
              return {
                ...room,
                rookpoints: room.users.reduce((total, i) => total + i.rookpoints, 0)
              }
            
            return room
          })

          // data.challenge_rooms = getProgressRoom(data)
          data.challenge_rooms = sortRooms(data)
          // data.challenge_rooms = sortRoomMembers(data)

        }

        // Individua por usuario
        if (data.challenge_users)  {

          usersInChallenge = data.challenge_users.data.length
          data.challenge_users.data = getEarnedGoals(data, data.challenge_users.data)
          
        }

        return {
          ...context,
          backUpRoomID,
          data,
          lastPage,
          pageRoomData: data.challenge_rooms ? data.challenge_rooms : [],
          pageUserData: data.challenge_users ? data.challenge_users.data : [],
          usersInChallenge,
        }

      }),
      checkCompletedGoals: assign((context, _event: any) => {

        const event : DoneInvokeEvent<TeamMetrics> = _event

        if (event.type !== 'done.invoke.fetchUserMetrics') return { ...context }

        let metrics: TeamMetric[] = []

        if (event.data.data.length === 0) 
          metrics = fillMetrics( 
            context.data?.goals || [], 
            context.data?.challenge_compliance === 'free'
          )
        else 
          metrics = event.data.data.map( metric => {

            const accumulated = Number.isNaN(Number(metric.accumulated)) 
              ? 0 
              : Math.round(Number(metric.accumulated))

            const meta = Number.isNaN(Number(metric.meta)) 
              ? 0 
              : Math.round(Number(metric.meta))

            return {
            ...metric,
              accumulated,
              meta,
              target: `${accumulated}/${metric.meta}`, 
            }
          })

        // Si es competitivo le agregamos el nivel
        if (context.data && context.data.challenge_compliance !== 'free') {

          metrics = lodash.sortBy(metrics, ['goal_type', 'meta'])
          metrics = labelingMetrics(metrics)

        }

        return  {
          ...context,
          userMetrics: { data: metrics }
        }

      }),
      checkTeamCompletedGoals: assign((context, _event: any) => {

        const event: DoneInvokeEvent<TeamMetrics> = _event

        if ( event.type !== 'done.invoke.fetchTeamMetrics') return { ...context }

        let metrics: TeamMetric[] = []

        if (event.data.data.length === 0) 
          metrics = fillMetrics( 
            context.data?.goals || [], 
            context.data?.challenge_compliance === 'free'
          )
        else 
          // Quitamos el .00 en lo acumulado
          metrics = event.data.data.map( metric => {

            const accumulated = Number.isNaN(Number(metric.accumulated)) 
              ? 0 
              : Math.round(Number(metric.accumulated))

            const meta = Number.isNaN(Number(metric.meta)) 
              ? 0 
              : Math.round(Number(metric.meta))

            return {
              ...metric,
              accumulated,
              meta,
              target: `${accumulated}/${metric.meta}` 
            }
          })

        // Si es competitivo le agregamos el nivel
        if (context.data && context.data.challenge_compliance !== 'free') {

          metrics = lodash.sortBy(metrics, ['goal_type', 'meta'])
          metrics = labelingMetrics(metrics)

        }

        return {
          ...context,
          teamMetrics: { data: metrics }
        }

      }),
      checkRoomCompletedGoals: assign((context, _event: any) => {

        const event: DoneInvokeEvent<TeamMetrics> = _event

        if ( event.type !== 'done.invoke.fetchRoomMetrics') return { ...context }

        let metrics: TeamMetric[] = []

        if (event.data.data.length === 0) 
          metrics = fillMetrics( 
            context.data?.goals || [], 
            context.data?.challenge_compliance === 'free'
          )
        else 
          // Quitamos el .00 en lo acumulado
          metrics = event.data.data.map( metric => {

            const accumulated = Number.isNaN(Number(metric.accumulated)) 
              ? 0 
              : Math.round(Number(metric.accumulated))

            const meta = Number.isNaN(Number(metric.meta)) 
              ? 0 
              : Math.round(Number(metric.meta))

            return   {
              ...metric,
              accumulated,
              meta,
              target: `${accumulated}/${metric.meta}` 
            }
          })

        // Si es competitivo le agregamos el nivel
        if (context.data && context.data.challenge_compliance !== 'free') {

          metrics = lodash.sortBy(metrics, ['goal_type', 'meta'])
          metrics = labelingMetrics(metrics)

        }

        return {
          ...context,
          teamMetrics: { data: metrics }
        }

      }),
      appendUsers: assign((context, _event: any) => {

        const event : DoneInvokeEvent<NextFetch> = _event
        const { data } = context
        let { pageUserData } = context

        if (event.type !== 'done.invoke.nextUsersPage' || !data) return { ...context }

        if (data.challenge_users) {

          const users = getEarnedGoals(data, event.data.data)

          if ( !context.search  || context.search.search === '-1')
            data.challenge_users.data = data.challenge_users.data.concat(users)

          pageUserData = pageUserData.concat(users)
        }

        return {
          ...context,
          data,
          lastPage: event.data.lastPage,
          pageUserData
        }
        
      }),
      prepareSearch: assign((context, event) => {

        if (event.type !== 'SEARCH') return { ...context }

        if (context.search) 
          return {
            ...context,
            currentPage: 1,
            pageUserData: [],
            search: event.data,
          }

        return {
          ...context,
          currentPage: 1,
          currentPageBackUp: context.currentPage,
          pageUserData: [],
          pageUserDataBackUp: context.pageUserData,
          search: event.data,
          lastPageBackUp: context.lastPage
        }

      }),
      resetData: assign((context) => {
        return {
          ...context,
          currentPage: context.currentPageBackUp,
          currentPageBackUp: 1,
          lastPage: context.lastPageBackUp,
          lastPageBackUp: 1,
          pageUserData: context.pageUserDataBackUp,
          pageUserDataBackUp: [],
          search: null,
        }
      }),
      resetDetail: assign((context) => ({
          ...context,
          userDetail: null,
          goalsDetail: null,
        })),
      goBack: assign((context) => ({
        ...context,
        data: context.backUpData,
        backUpData: null,
        isMembersView: false,
        currentPage: 1,
        lastPage: 1,
        teamRoom: null,
      })),
      searchUser: assign((context) => {

        if (!context.data) return { ...context }

        if (!context.search || context.search.search === '-1') {

          if (context.data.challenge_users)
            return {
              ...context,
              pageUserData: context.data.challenge_users.data,
              search: null
            }

          if (context.data.challenge_rooms)  
            return { 
              ...context,
              pageRoomData: context.data.challenge_rooms,
              search: null
            }
        }

        if (context.data.challenge_users && context.search) {

          const newUsers = context.data.challenge_users.data.filter(user => {

            if (context.search!.search_column === 'name') {
              return user.name
                      .toLocaleLowerCase()
                      .includes( context.search!.search.toLocaleLowerCase() )
            }

            if (context.search!.search_column === 'email') {
              return user.email.includes( context.search!.search.toLocaleLowerCase() )
            }

            return false

          })

          return {
            ...context,
            pageUserData: newUsers
          }

        }

        if (context.data.challenge_rooms && context.search) {

          const newRooms = context.data.challenge_rooms.filter( 
            room => room.name.includes(context.search!.search)
          )

          return {
            ...context,
            pageRoomData: newRooms
          }

        }

        return {
          ...context,
        }

      }),
      searchRoom: assign((context) => {

        if (!context.data) return { ...context }

        if (!context.search || context.search.search === '-1') 
          return { 
            ...context,
            pageRoomData: context.data.challenge_rooms,
            search: null
          }

        if (!context.data.challenge_rooms || !context.search) return { ...context }

        const newRooms = context.data.challenge_rooms.filter( 
          room => room.name.includes(context.search!.search)
        )

        return {
          ...context,
          pageRoomData: newRooms
        }

      }),
      removeUserOfData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<ShowChallenge> = _event

        if (event.type !== 'done.invoke.deleteUserOfChallenge' || !context.data ) 
          return { ...context }

        if (event.data.challenge_users && context.removeUser) {
          return {
            ...context
          }
        }

        const filteredUsers = context.pageUserData.filter( 
          u => u.user_uuid !== context.removeUser?.user 
        ) 

        return {
          ...context,
          pageUserData: filteredUsers,
          removeUser: null,
          usersInChallenge: context.usersInChallenge - 1
        }

      }),
      convertIntoIndividual: assign((context, _event: any) => {

        const event: DoneInvokeEvent<NextFetch> = _event

        if (event.type !== 'done.invoke.nextUsersPage') return { ...context }

        const { data } = context
        let backUpData : ShowChallenge | null = null 

        if (!data) return { ...context }

        backUpData = { ...data }

        const newChallenge = transformChallenge(data)

        if (newChallenge){

          let users  = event.data.data
          newChallenge.challenge_users = { data: users }
          users = getEarnedGoals(newChallenge, users)

          return {
            ...context,
            backUpData,
            data: newChallenge,
            isMembersView: true,
            lastPage:  event.data.lastPage,
            pageUserData: users || [],
          }
        }

        return {
          ...context
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401,
      isSearchEmpty: (context) => {
        if (!context.search) return true
        if (context.search.search === '-1') return true

        return false
      },
      isRoomChallenge: (context) => {

        if (!context.data) return false
        if (context.data.challenge_rooms) return true

        return false
        
      }
    }
  }
)