import Colors from '../../../utils/colors';

export default {
  option: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.secondaryBackgroundColor,
    '&:hover': {
      backgroundColor: Colors.mainColor,
      cursor: 'pointer'
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.secondaryBackgroundColor,
  }),
  control: (base: any) => ({
    ...base,
    backgroundColor: Colors.secondaryBackgroundColor,
    borderColor: 'transparent',
    padding: '0.6rem 0rem',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    }
  }),
  input: (base: any) => ({
    ...base,
    color: '#fff',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#b9b9b9',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
}

export const freeSelectStyles = {
  option: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.secondaryBackgroundColor,
    '&:hover': {
      backgroundColor: Colors.mainColor,
      cursor: 'pointer'
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.secondaryBackgroundColor,
  }),
  control: (base: any) => ({
    ...base,
    backgroundColor: Colors.secondaryBackgroundColor,
    borderColor: 'transparent',
    padding: '0.6rem 0rem',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    }
  }),
  input: (base: any) => ({
    ...base,
    color: '#fff',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#b9b9b9',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menuList: (base: any) => ({
    ...base,
    height: '5rem',
  })
}