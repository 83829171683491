/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactElement, useRef, useCallback } from 'react'

// Dependencies
import { FixedSizeGrid, GridChildComponentProps } from 'react-window'
import { Skeleton } from '@mui/material';
import AutoSizer from "react-virtualized-auto-sizer";
import shortid from 'shortid';

// Types
import { UserChallenge, ShowChallenge, UserDetail, TeamMetrics } from '../../../types'

// Components
import Individual from '../utils/individual/Individual'
import IndividualDetail from '../utils/individual/IndividualDetail';

interface IndividualChallengeProps {
  data: ShowChallenge,
  filteredData: UserChallenge[],
  openDetail: boolean,
  userDetail: UserDetail | null,
  goalsDetail: TeamMetrics | null,
  removing: { flag: boolean, user: string },
  columns: number,
  loading: boolean,
  hasMore: boolean,
  onClose: () => void,
  next: () => void,
  removeUser: (uuid: string) => void,
  showUserDetail: (
    user: UserChallenge, earned: number, percentage: number, total: number, position: number, points: number
    ) => void
}
 
/**
 * Contenedor de la lista de usuarios
 * @param props contiene todas las propiedades necesarias descritas en
 * IndividualChallengeProps
 * @param props.data en general del reto
 * @param props.filteredData lista de usuarios filtrada
 * @param props.openDetail bandera para mostrar o no el detalle del usuario
 * @param props.goalsDetail listado de objetivos
 * @param props.removing saber a quien se esta eliminando
 * @param props.onClose función que se ejecuta cuando se esta cerrando el modal de 
 * @param props.removeUser función que se ejecuta para eliminar un usuario
 * @param props.showUserDetail función que se ejecuta para saber los detalles del usuairo
 * @returns Contenedor de la lista de usuarios
 */
const IndividualChallenge: FC<IndividualChallengeProps> = ({ 
  data, openDetail, userDetail, goalsDetail, filteredData, removing, columns,
  loading, hasMore,
  showUserDetail, onClose, removeUser, next
}) => {

  const observer$ = useRef<IntersectionObserver | null>()

  const getRowHeight = () : number => {
    
    if (data.challenge_compliance === 'free') 
      if (columns === 1) return 300
      else return 230

    if (columns === 1) return 470
    return 280
  }

  const lastUserElement = useCallback((node: HTMLDivElement | null ) => {
    
    if (loading) return

    if (observer$.current) observer$.current.disconnect()

    observer$.current = new IntersectionObserver(entries => {

      let visible = false;
      let index = 0;

      while (!visible && index < entries.length) {
        if (entries[index].isIntersecting) visible = true;

        index = index + 1
      }

      if (visible && hasMore) next()

    })

    if (node) observer$.current.observe(node)

  }, [loading, hasMore])

  /**
   * Renderizar el cluster de participantes
   * @param param0.columnIndex indice de la columna
   * @param param1.rowIndex indice de la fila
   * @param param2.style estilos de la fila
   * @returns 
   */
  const renderRows = ({ columnIndex, rowIndex, style }: GridChildComponentProps) : ReactElement => {
    
    const index = columnIndex + rowIndex * columns
    const user = filteredData[index]

    if (!user){ 

      if (!loading && !hasMore) 
        return <></>
      
      return (
        <div
          style={{ ...style, padding: '0 0.5rem'}}
        >
          <Skeleton
            variant="rectangular"
            sx = {{
              borderRadius: '1rem',
              height: '90%'
            }}
          />
        </div>
      )

    }

    const earned = user.earned || 0
    const total : number = data.total_goals
    const percentage = user.total_goals_completed

    return (
      <div
        ref = { index === (filteredData.length - 1) ? lastUserElement : undefined }
        style={{ ...style, padding: '0 0.5rem'}}
      >
        <Individual
          isRemovable = { data.status !== 'completed' }
          isFree = { data.challenge_compliance === 'free' }
          image={user.image_url}
          name={user.name}
          email={user.email}
          position={(index) + 1}
          percentage={Number.isNaN(percentage) ? 0 : percentage}
          rookpoints={ user.rookpoints }
          points={user.points || 0}
          earned={earned}
          total={total}
          onClick={() => showUserDetail(
            user,
            earned,
            Number.isNaN(percentage) ? 0 : percentage,
            total,
            index + 1,
            user.points || 0
          )}
          onRemove={() => removeUser(user.user_uuid)}
          removing={removing.flag && removing.user === user.user_uuid} 
        />
      </div>
    )

  }

  const getRowCount = useCallback(() => {

    if (hasMore) return Math.ceil((filteredData.length + 1) / columns)
    
    return Math.ceil((filteredData.length) / columns)

  }, [hasMore, filteredData])

  return ( 
    <div
      className='w-full h-part-screen lg:h-98 2xl:h-110'
    >

      { (data.challenge_users) && (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeGrid
                columnCount = {columns}
                columnWidth = { (width / columns) - 10 }
                height = { height }
                rowCount = { getRowCount() }
                rowHeight = { getRowHeight() }
                width = { width }
              >
                { renderRows }
              </FixedSizeGrid>
            )}
          </AutoSizer>
      )}

      { (userDetail ) && (
        <IndividualDetail 
          isRemovable = { data.status !== 'completed' }
          isFree = { data.challenge_compliance === 'free' }
          position = { userDetail.position || 0 }
          user = { userDetail.user }
          goalList = { goalsDetail } 
          goals = {{ earned: userDetail.earned, total: userDetail.total }}
          percentage = { userDetail.percentage }
          points = { userDetail.points || 0 }
          open = { openDetail }
          rookpoints = { userDetail.user.rookpoints }
          onClose = { onClose }
          onDelete = { () => removeUser(userDetail.user.user_uuid) }
        />
      )}
    </div>  
  );
}
 
export default IndividualChallenge;