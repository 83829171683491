import * as Yup from 'yup'

export default Yup.object().shape({
  'room_name': Yup.string()
          .required('roomNameValidation')
          .trim('roomNameValidation')
          .min(4, 'roomNameValidationMin')
          .max(15, 'roomNameValidationMax'),
  description: Yup.string()
          .notRequired()
          .when('description', {
            is: (value: any) => value,
            then: rule => rule
                          .min(5, 'roomDescriptionValidationLength')
                          .max(100, 'roomDescriptionValidationLength')
          }),
  coach: Yup.string()
          .required('roomCoachValidation')
          .test(
            'different', 
            'roomCoachValidation',
            (text) => `${text}` !== '123'
          ),
  status: Yup.boolean()
            .required('El estado es requerido')
          
}, [['description', 'description']])