/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'

// Components
import IndividualHeader from './IndividualHeader';
import IndividualBody from './IndividualBody';
import IndividualFooter from './IndividualFooter';
import FreeBody from '../FreeBody';

interface IndividualProps {
  earned: number,
  email: string | null,
  image: string | null
  isFree ?: boolean
  isRemovable: boolean,
  name: string | null,
  percentage: number,
  points: number,
  position: number,
  removing ?: boolean,
  rookpoints: number,
  total: number,
  onClick ?: () => void,
  onRemove ?: () => void,
}
 
/**
 * Componente que muestra un usuario
 * @param props contiene todas las propiedades necesarias descritas en
 * IndividualProps
 * @param props.image del usuario
 * @param props.name del usuario
 * @param props.email del usuario
 * @param props.points del usuario
 * @param props.earned número de objetivos completados
 * @param props.total número de objetivos
 * @param props.isRemovable saber si mostrar o no el icono para borrar
 * @param props.position posición en la que esta el equipo
 * @param props.onClick función para ver el detalle del equipo
 * @param props.onRemove función para eliminar al usuario
 * @param props.removing saber si se está eliminando un usuario
 * @returns Componente que muestra un usuario
 */
const Individual: FC<IndividualProps> = ({ 
  name = '', email = '', removing = false, rookpoints, isFree = false,
  position, percentage, points, earned, total, image, isRemovable,
  onClick = () => {}, onRemove = () => {}
}) => {

  return ( 
    <div 
      className='rounded-2xl bg-card-body'
    >

      <IndividualHeader
        name = { name || '-' }
        email = { email || '-' }
        image = { image || undefined }
        position = { position }
      />

      { isFree 
        ? (
          <FreeBody
            rookpoints={ Number.isNaN(Number(rookpoints)) ? 0 : Number(rookpoints) }
          />
        ) 
        : (
          <IndividualBody 
            goals={{
              earned,
              total
            }} 
            points={ points } 
            rookPoints={ Number.isNaN(Number(rookpoints)) ? 0 : Number(rookpoints) }
            percentage={percentage}        
          />
        ) 
      }


      <IndividualFooter
        isRemovable = { isRemovable }
        isRemoving = { removing }
        handleProgress = { onClick }
        handleRemove = { onRemove }
      />

    </div>
  );
}
 
export default Individual;