/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactElement, useState } from 'react'

// Dependencies
import moment from 'moment'
import { CircularProgress, IconButton } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange }  from '@mui/lab/DateRangePicker';
import SearchIcon from '@mui/icons-material/Search';

// Hooks
import useTranslation from '../../hooks/useTranslation'
import { RButton } from '../utils/RookButtons';

interface RangeProps {
  loading: boolean,
  withText ?: boolean,
  searchPeriod: (range: DateRange<Date>) => void
}
 
/**
 * Barra para buscar datos dentro un periodo de fechas
 * @param props contiene todas las propiedades necesarias descritas en RangeProps
 * @param props.loading saber si se esta buscando un periodo
 * @param props.searchPeriod función para buscar un periodo de fechas en el servidor
 * @returns formulario de rango de fechas
 */
const Range: FC<RangeProps> = ({ loading, withText = true, searchPeriod }) => {

  // State con las fechas a buscar
  const [value, setValue] = useState<DateRange<Date>>([
    moment().subtract(7, 'd').toDate(),
    moment().toDate(), 
  ]);

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  /**
   * Manejar el clic en buscar periodo
   */
  const handleClick = () : void => {
    if (value.every(a => a))
      searchPeriod(value)
  }

  const RenderSearchButton = () : ReactElement => {
    
    if (withText)
      return (
        <RButton
          className='ml-2'
          onClick = { handleClick }
        >
          { getTranslationByKey('searchPeriod') }
        </RButton>
      )

    return (
      <div className="rounded-full bg-primary ml-2">
        <IconButton
          onClick = { handleClick }
        >
          <SearchIcon />
        </IconButton>
      </div>
    )

  }

  return ( 
    <div className="flex flex-col md:flex-row justify-end items-center">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          startText="Fecha de inicio"
          endText="Fecha de termino"
          inputFormat={ getTranslationByKey('datePickerInputFormat') }
          mask={ getTranslationByKey('datePickerInputMask') }
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <input
                ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                {...startProps.inputProps}
                data-cy = 'btn-range-from'
                className = "appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-input-background focus:bg-input-background hover:border-white border-transparent mr-4 px-2 py-4"
              />
              <input
                ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                {...endProps.inputProps}
                data-cy = 'btn-range-to'
                className = "appearance-none rounded-xl w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-input-background focus:bg-input-background hover:border-white border-transparent px-2 py-4"
              />
            </>
          )}
        />
      </LocalizationProvider>

      { loading 
        ? (
          <div className="px-8 ml-4 lg:mt-0">
            <CircularProgress color = 'primary' />
          </div>
        ) 
        : (
          <RenderSearchButton />
        )
      }

    </div>
  );
}
 
export default Range;