import { assign, createMachine, DoneInvokeEvent, send, spawn, sendParent } from 'xstate';

// Dependencies
import * as Sentry from '@sentry/react'
import axiosClient from '../../../config/axios'

// Types
import { Board, MedalBoard,SearchIn } from '../../../types'

type GRMContext = {
  branchUUID: string,
  currentPage: number,
  page: any | null,
  pageAlreadyExists: boolean,
  pageData: any,
  pages: number,
  podium: MedalBoard[]
  search: SearchIn | null,
  searched: Board | null
  statusError: number,
  totalPages: number,
}

type GRMEvents = 
  | { type: 'READY', data: {pages: number, podium: MedalBoard[]} }
  | { type: 'SETUP', uuid: string }
  | { type: 'NEXT', page: number }
  | { type: 'SEARCH', data: SearchIn }

/**
 * It fetches the medal board for a given branch
 * @param {PRMContext} context - PRMContext
 * @returns MedalBoard[]
 */
const fetchMedalBoard = async (context: PRMContext) : Promise<MedalBoard[]> => {

  const { branchUUID, page } = context

  if (!branchUUID) throw new Error()

  const response = await axiosClient({
    url: `/api/v2/branch/${branchUUID}`,
    params: {
      page: page || 1
    },
    baseURL: process.env.REACT_APP_GM
  })

  return response.data

}

/**
 * It fetches the medal board for a given branch
 * @param {PRMContext} context - PRMContext
 * @returns MedalBoard[]
 */
const searchUsers = async (context: GRMContext) : Promise<MedalBoard[]> => {

  const { branchUUID, search, currentPage } = context

  if (!branchUUID || !search || search.search === '-1') throw new Error()

  const response = await axiosClient({
    url: `/api/v2/branch/${branchUUID}`,
    params: {
      search: search.search,
      search_column: search.search_column,
      page: currentPage || 1
    },
    baseURL: process.env.REACT_APP_GM
  })

  return response.data

}

/**
 * It takes an array of objects, and returns an array of objects with a new property called points
 * @param {MedalBoard[]} items - MedalBoard[]
 */
// const getPoints = (items: MedalBoard[]) : MedalBoard[]=> {
  
//   return items.map( i => {

//     const points = i.achievement.reduce((acc, current) => {

//       if (current.medals_type === 'gold') return acc + current.medals_count * 4
//       if (current.medals_type === 'silver') return acc + current.medals_count * 2
//       if (current.medals_type === 'bronce') return acc + current.medals_count * 1

//       return acc

//     }, 0)

//     return {
//       ...i,
//       points
//     }

//   })

// }

export default createMachine<GRMContext, GRMEvents>(
  {
    id: 'GamingRankingMachine',
    initial: 'idle',
    context: {
      branchUUID: '',
      currentPage: 1,
      page: null,
      pageAlreadyExists: false,
      pageData: {},
      pages: 0,
      podium: [],
      search: null,
      searched: null,
      statusError: 0,
      totalPages: 0,
    },
    states: {
      login:{
        type: 'final'
      },
      idle: {
        on: {
          SETUP: {
            actions: [
              assign({ branchUUID: (_, event) => event.uuid }),
              send({ type: 'NEXT', page: 1 })
            ]
          }
        }
      },
      loading: {
        always: [
          { target: 'searching', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' },
        ],
        on: {
          READY: {
            target: 'loaded',
            actions: ['systemReady']
          }
        }
      },
      loaded: {
        on: {
          SEARCH: {
            target: 'searching',
            actions: [ 'prepareSearch' ]
          }
        }
      },
      searching: {
        invoke: {
          id: 'searchUsers',
          src: searchUsers,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedUsers',
              send(
                (context) => ({ type: 'REFRESH', data: context.searched?.data ?? []}),
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'failed',
            actions: ['prepareFailure']
          }
        }
      },
      failed: {
        always: [
          { target: 'login', cond: 'isUnauthorized' },
          { target: 'loaded', cond: 'isNotASearch' }
        ],
        after: {
          3600: {
            target: 'loaded'
          }
        }
      }
    },
    on: {
      NEXT: {
        target: '.loading',
        actions: ['getNextPage']
      },
    }
  }, {
    actions: {
      systemReady: assign((context, event) => {

        if (event.type !== 'READY') return { ...context }

        const { pages, podium } = event.data

        return {
          ...context,
          pages: pages > context.pages ? pages : context.pages,
          podium: context.podium.length === 0 ? podium : context.podium,
          totalPages: pages > context.totalPages ? pages : context.totalPages
        }

      }),
      getNextPage: assign((context, event) => {

        if (event.type !== 'NEXT') return { ...context }

        if (context.search && context.search.search !== '-1')
          return {
            ...context,
            pageAlreadyExists: true,
            currentPage: event.page
          }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData[`${event.page}`]

        if (subPage) {
          return {
            ...context,
            page: subPage,
            pageAlreadyExists: true,
            currentPage: event.page
          }
        }

        // Otherwise, spawn a new subreddit actor and
        // save it in the subreddits object
        subPage = spawn(createPageMachine({
          page: event.page,
          branchUUID: context.branchUUID,
          initial: 'loading'
        }))

        return {
          ...context,
          pageData: {
            ...context.pageData,
            [`${event.page}`]: subPage
          },
          page: subPage,
          pageAlreadyExists: false,
          currentPage: event.page
        }

      }),
      prepareSearch: assign((context, event) => {

        if (event.type !== 'SEARCH') return { ...context }

        return {
          ...context,
          currentPage: 1,
          page: Object.values(context.pageData)[0],
          pages: context.totalPages,
          search: event.data,
        }

      }),
      searchedUsers: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Board> = _event

        if (event.type !== 'done.invoke.searchUsers')
          return { ...context }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page,
          }

        }

        subPage = spawn( createPageMachine({
          page: 1,
          branchUUID: context.branchUUID,
          initial: 'loaded'
        }))

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page,
          currentPage: 1
        }

      }),
      prepareFailure: assign((context, _event: any) => {

          const event : DoneInvokeEvent<any> = _event

          if (event.data.response) {
            Sentry.addBreadcrumb({
              category: 'error',
              message: 'No se pudo actualizar el logo del centro',
              level: Sentry.Severity.Error,
              data: event.data
            })

            Sentry.addBreadcrumb({
              category: 'info',
              message: 'No se pudo actualizar el logo del centro',
              level: Sentry.Severity.Error,
              data: event.data.response
            })
          }
          else {
            Sentry.addBreadcrumb({
              category: 'warning',
              message: 'No se pudo actualizar el logo del centro tal vez no se conocía el branch o no se tenía la imagen.',
              level: Sentry.Severity.Warning,
              data: event.data
            })
          }

          Sentry.captureException(event.data)
          
          return {
            ...context,
            statusError: event.data.response ? event.data.response.status : 10 
          }
      })
    },
    guards: {
      isNotASearch: (context) => context.search?.search === '-1',
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isUnauthorized: (context) => context.statusError === 401,
      isCompoundPagination: (context) => {
        if(context.search && context.search.search !== '-1')
          return true

        return false
      }
    }
  }
)

export type PRMContext = {
  branchUUID: string,
  data: MedalBoard[],
  page: number,
  perPage: number,
  statusError: number,
}

export type PRMEvent = {
  type: 'REFRESH',
  data: MedalBoard[]
}

type Config = {
  page: number, 
  branchUUID: string, 
  initial: string
}

const createPageMachine = (config: Config) =>{ 

  const { page, initial, branchUUID } = config

  return createMachine<PRMContext, PRMEvent> (
    {
      id: 'PageRankingMachine',
      initial,
      context: {
        branchUUID,
        data: [],
        page,
        perPage: 0,
        statusError: 0,
      },
      states: {
        login: {
          type: 'final'
        },
        loaded: {
          on: {
            REFRESH: {
              actions: ['updateData']
            }
          }
        },
        loading: {
          invoke: {
            id: 'fetchMedalBoard',
            src: fetchMedalBoard,
            onDone: {
              target: 'loaded',
              actions: [ 
                'prepareMedalBoard',
                sendParent(
                  (context, event) => ({ type: 'READY', data: {
                    pages: event.data?.meta.last_page || 0,
                    podium: context.data.slice(0, 3)
                  }})
                )
              ]
            },
            onError: {
              target: 'failed',
              actions: ['prepareFailure']
            }
          }
        },
        failed: {
          always: [
            { target: 'login', cond: 'isUnauthorized' }
          ],
        }
      }
    }, {
      actions: {
        prepareMedalBoard: assign((context, _event: any) => {

          const event: DoneInvokeEvent<Board> = _event
  
          if (event.type !== 'done.invoke.fetchMedalBoard') return { ...context }
  
          // Calculamos los puntos
          // data = getPoints(data)
  
          const types : ['gold', 'silver', 'bronce'] = ['gold', 'silver', 'bronce']

          // Limpiamos la data
          const cleanData = event.data.data.map( u => {
  
            let { achievement } = u
            const name = u.name || '-'
            const pseudonym = u.pseudonym || '-'
            const email = u.email || '-'

            if (achievement.length === 0)
              achievement = [
                { medals_type: 'gold', medals_count: 0 },
                { medals_type: 'silver', medals_count: 0 },
                { medals_type: 'bronce', medals_count: 0 },
              ]
            else 
              types.forEach(type => {
      
                const exits = achievement.find( m => m.medals_type === type )
                if (!exits) achievement.push({ medals_type: type, medals_count: 0 })
          
              })
  
            return {
              ...u,
              name: name.length >= 25 ? `${name.substring(0, 23)}...` : name,
              email: email.length >= 27 ? `${email.substring(0, 25)}...` : email,
              pseudonym: pseudonym.length >= 20 ? `${pseudonym.substring(0, 17)}...` : pseudonym,
              achievement
            }
  
          })
  
          return {
            ...context,
            data: cleanData,
            pageData: cleanData,
            perPage: event.data.meta.per_page,
            totalPages: event.data.meta.last_page,
          }
  
        }),
        updateData: assign((context, event) => {

          if (event.type !== 'REFRESH') return { ...context }

          return {
            ...context,
            data: event.data
          }

        }),
        prepareFailure: assign((context, _event: any) => {

          const event : DoneInvokeEvent<any> = _event

          if (event.data.response) {
            Sentry.addBreadcrumb({
              category: 'error',
              message: 'No se pudo actualizar el logo del centro',
              level: Sentry.Severity.Error,
              data: event.data
            })

            Sentry.addBreadcrumb({
              category: 'info',
              message: 'No se pudo actualizar el logo del centro',
              level: Sentry.Severity.Error,
              data: event.data.response
            })
          }
          else {
            Sentry.addBreadcrumb({
              category: 'warning',
              message: 'No se pudo actualizar el logo del centro tal vez no se conocía el branch o no se tenía la imagen.',
              level: Sentry.Severity.Warning,
              data: event.data
            })
          }

          Sentry.captureException(event.data)
          
          return {
            ...context,
            statusError: event.data.response ? event.data.response.status : 10 
          }
        })
      },
      guards: {
        isUnauthorized: (context) => context.statusError === 401,
      }
    }
  )
}