import React, { FC } from 'react'

// Dependencies
import { IconButton } from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components 
import { RBox } from '../../../utils/RookLayout';
import { RButtonLoading } from '../../../utils/RookButtons';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

interface IndividualFooterProps {
  isRemovable: boolean,
  isRemoving: boolean,
  handleRemove: () => void,
  handleProgress: () => void
}
 
const IndividualFooter: FC<IndividualFooterProps> = ({ 
  isRemoving, isRemovable, handleProgress, handleRemove 
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  return ( 
    <RBox className='bg-white text-black justify-between rounded-b-2xl'>

      <button
        type='button'
        className='uppercase text-sm text-center w-full'
        onClick={ handleProgress }
      >
        <VisibilityIcon />
        {' '}{ getTranslationByKey('progress') }
      </button>

      { isRemovable && (
        <RButtonLoading 
          loading = { isRemoving }
          color = 'secondary'      
        >
          <RBox className='bg-title rounded-br-xl'>
              <IconButton
                onClick={ handleRemove }
              >
                <DeleteIcon />
              </IconButton>
          </RBox>
        </RButtonLoading>
      )}


    </RBox>
  );
}
 
export default IndividualFooter;