import React, { FC } from 'react'
import { RImage } from '../../utils/RookImages';

// Components
import { RRoundedContainer } from '../../utils/RookLayout';
import RTexts from '../../utils/RookTexts/RTexts';

type Size = {
  mobile: string,
  tablet: string,
  extra: string
}

interface BoardPodiumPlaceProps {
  name: string,
  image ?: string,
  position: 'first' | 'second' | 'third'
}
 
const BoardPodiumPlace: FC<BoardPodiumPlaceProps> = ({ name, image, position }) => {

  /**
   * It returns a string that is either 'bg-first-gradient', 'bg-second-gradient', or 'bg-third'
   * depending on the value of the variable position
   * @returns A string
   */
  const getBackgroundColor = () : string => {
    switch (position) {
      case 'first': return 'bg-first-gradient'
      case 'second': return 'bg-second-gradient'
      default: return 'bg-third'
    }
  }

  /**
   * Get the size of the podium card
   * @returns An object with three properties: mobile, tablet, and extra.
   */
  const getContainerSize = () : Size => {
    switch (position) {
      case 'first': 
        return {
          mobile: '4rem',
          tablet: '6rem',
          extra: '8rem'
        }

      case 'second': 
        return {
          mobile: '3rem',
          tablet: '5rem',
          extra: '6rem'
        }

      default: 
        return {
          mobile: '2.4rem',
          tablet: '3.7rem',
          extra: '4.7rem'
        }
    }
  }

  return ( 
    <RRoundedContainer
      className={`flex flex-col items-center ${getBackgroundColor()} p-4`}
    >
      <RImage 
        image = {image}
        size = {getContainerSize()}
        style = {{ borderRadius: '50%' }}
      />

      <RTexts className="font-bold text-black text-center mt-3 w-16 md:w-32 lg:w-64">
        { name }
      </RTexts>
    </RRoundedContainer>
  );
}
 
export default BoardPodiumPlace;