import React, { FC, useContext, useEffect } from 'react'

// Dependencies
import { useMachine } from '@xstate/react'
import { Pagination } from '@mui/material';
import { useHistory } from 'react-router-dom';

// Layout
import Layout from '../../layout/Layout';
import Spinner from '../../utils/Spinner';

// Context
import UIContext from '../../../context/ui/UIContext'
import GamingRankingMachine from '../../../context/machines/gaming/GamingRankingMachine'

// Icons
import { Gaming as GamingIcon } from '../../utils/Icons'

// Components
import { RBox } from '../../utils/RookLayout';
import BoardBar from './BoardBar';
import BoardPodium from './BoardPodium';
import BoardTable from './BoardTable';

interface BoardProps {
  
}

const showSpinnerWhen = ['idle', 'loading', 'searching']

const Board: FC<BoardProps> = () => {

  // Maquina que controla esta vista
  const [machine, send] = useMachine(GamingRankingMachine)

  // Sacamos el uuid del branch
  const { branchUUID } = useContext( UIContext )

  // Hook para navegar
  const history = useHistory()

  useEffect(() => {
    
    if (branchUUID) send({ type: 'SETUP', uuid: branchUUID })

  }, [branchUUID])

  // Actualizar las variables
  useEffect(() => {

    if (machine.matches('login')) history.push("/login")

  }, [machine.value])

  return ( 
    <Layout>
      <Spinner 
        show = { showSpinnerWhen.includes(machine.value.toString()) }
      >

        <BoardBar 
          handleSearch = { search => send({ type: 'SEARCH', data: search }) }
        />

        <RBox 
          className='rounded-2xl bg-card-body p-4 my-4 items-center'
        >
          <GamingIcon color = "white" />

          <p className='ml-4'>
            Ranking
          </p>

        </RBox>

        <BoardPodium 
          podium={{
            first: { 
              name: machine.context.podium[0]?.pseudonym ||'',
              image: machine.context.podium[0]?.image ||'',
              position: 'first',
            },
            second: { 
              name: machine.context.podium[1]?.pseudonym ||'',
              image: machine.context.podium[1]?.image ||'',
              position: 'second',
            },
            third: { 
              name: machine.context.podium[2]?.pseudonym ||'',
              image: machine.context.podium[2]?.image ||'',
              position: 'third',
            },
          }}
        />

        { (machine.context.page && !showSpinnerWhen.includes(machine.value.toString())) && (
          <BoardTable 
            service = { machine.context.page }          
          />
        )}

        { machine.context.pages > 1 && (
          <RBox className="justify-center mt-8">
            <Pagination
              showFirstButton
              showLastButton
              variant = 'text'
              color = 'secondary'
              count = { machine.context.pages }
              page = { machine.context.currentPage }
              onChange={ (_, page) => send({ type: 'NEXT', page }) }
            />
          </RBox>
        )}

      </Spinner>
    </Layout>
  );
}
 
export default Board;