import React,{ FC } from 'react'

// Dependecies
import { Line } from 'react-chartjs-2';

// Utils
interface LineChartProps {
  data ?: any
}

// Data Por default si se manda llamar el componente sin data
const defaults = {
  labels: ['10/09/2021', '10/09/2021', '10/09/2021', '10/09/2021', '10/09/2021', '10/09/2021'],
  datasets: [
    {
      label: 'Peso en kg',
      data: [60, 61, 63, 65, 62, 63],
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ],
};

/**
 * Gráfica de linea o puntos
 * @param {Object} props contiene todas las propiedades necesarias descritas en LineChartProps
 * @param {ChartData} props.data data a mostrar en la gráfica
 * @returns Componente con gráfica de puntos y lineas
 */
const LineChart: FC<LineChartProps> = ({ data = defaults }) => ( 
    <Line data={data} 
      options={{
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
      }} 
    />
  )
 
export default LineChart;