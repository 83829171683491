import * as Yup from 'yup';

// Esquema de validación cuando se esta creando un admin
export const validationSchema = Yup.object().shape({
  name: Yup.string()
              .required('usersNameInputError')
              .min(3, 'usersNameInputError')
              .max(50, 'usersNameInputLengthError'),
  email: Yup.string()
              .email('loginEmailValidError')
              .required('loginEmailRequiredError'),
  last_name_1: Yup.string()
                  .required('usersLastNameInputError')
                  .min(4, 'usersLastNameInputError')
                  .max(100, 'usersLastNameInputLengthError'),
  last_name_2: Yup.string()
                  .notRequired()
                  .when('last_name_2', {
                    is: (value: any) => value,
                    then: rule => rule.min(4, 'usersMLastNameInputError')
                                      .max(100, 'usersMLastNameInputLengthError')
                  }),
  landline: Yup.number()
                .positive('profileLandlineError')
                .integer('profileLandlineError')
                .notRequired()
                .when('landline', {
                  is: (value: any) => value,
                  then: (rule) => rule.positive().test(
                    'minDigits', 
                    'profileLandlineErrorLength', 
                    (number) => String(number).length >= 8 && String(number).length <= 15
                  ),
                }),
  mobile_phone: Yup.number()
                .positive('profileMobilePhoneError')
                .required('profileMobilePhoneError')
                .integer('profileMobilePhoneError')
                .test(
                  'minDigits', 
                  'profileMobilePhoneErrorLength', 
                  (number) => String(number).length >= 8 && String(number).length <= 15
                ),
  role: Yup.string()
            .required('usersSexInputError'),
  password: Yup.string()
            .required("profileChangePwdNewError")
            .min(8, 'profileChangePwdNewErrorMin'),
'password_confirmation': Yup.string()
                          .required('profileChangePwdConfirmationError')
                          .oneOf(
                            [Yup.ref('password'), null], 
                            'profileChangePwdConfirmationErrorEqual'
                          )
},[['landline', 'landline'], ['last_name_2', 'last_name_2']])

// Esquema de validación cuando se esta editando un admin
export const validationWithoutPassword = Yup.object().shape({
  name: Yup.string()
              .required('usersNameInputError')
              .min(3, 'usersNameInputError')
              .max(50, 'usersNameInputLengthError'),
  email: Yup.string()
              .email('loginEmailValidError')
              .required('loginEmailRequiredError'),
  last_name_1: Yup.string()
                  .required('usersLastNameInputError')
                  .min(4, 'usersLastNameInputError')
                  .max(100, 'usersLastNameInputLengthError'),
                  last_name_2: Yup.string()
                  .notRequired()
                  .when('last_name_2', {
                    is: (value: any) => value,
                    then: rule => rule.min(4, 'usersMLastNameInputError')
                                      .max(100, 'usersMLastNameInputLengthError')
                  }),
  landline: Yup.number()
                .positive('profileLandlineError')
                .integer('profileLandlineError')
                .notRequired()
                .when('landline', {
                  is: (value: any) => value,
                  then: (rule) => rule.positive().test(
                    'minDigits', 
                    'profileLandlineErrorLength', 
                    (number) => String(number).length >= 8 && String(number).length <= 15
                  ),
                }),
  mobile_phone: Yup.number()
                .positive('profileMobilePhoneError')
                .required('profileMobilePhoneError')
                .integer('profileMobilePhoneError')
                .test(
                  'minDigits', 
                  'profileMobilePhoneErrorLength', 
                  (number) => String(number).length >= 8 && String(number).length <= 15
                ),
  role: Yup.string()
            .required('usersSexInputError'),
},[['landline', 'landline'],['last_name_2', 'last_name_2']])