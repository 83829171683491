import React, { FC, useEffect, useState, useContext } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { useActor } from '@xstate/react';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Snackbar, Alert } from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

// Context
import UIContext from '../../context/ui/UIContext'

// Types and models
import Texts from '../../models/Texts'
import { Administrator, Word } from '../../types'

// Hook
import useTranslation from '../../hooks/useTranslation'
import { getTranscriptionOfRole } from '../../utils';

interface AdminListProps {
  service: any,
  isEditable: boolean,
  isRemovable: boolean
}
 
/**
 * Componente que renderiza la lista de admins
 * @param {Object} props contiene todas las propiedades necesarias descritas en AdminListProps
 * @param {any} props.service contiene el actor que contiene la lista de admins y acciones
 * disponibles para la tabla de admins
 * @param {boolean} props.isEditable para indicar que el usuario puede editar admins
 * @param {boolean} props.isRemovable para indicar que el usuario puede eliminar admins
 * @returns un React Component con una lista de administradores
 */
const AdminList: FC<AdminListProps> = ({ service, isEditable, isRemovable }) => {

  // Machine prefetched
  const [current, send] = useActor(service)

  // Sacamos el context y el estado de la maquina
  const { context, value } : any = current

  // Hook para traducir
  const { getTranslation }= useTranslation()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para navegar
  const history = useHistory()

  // Hook para traducir
  const { lang } = useContext( UIContext )

  // Actualizar las variables
  useEffect(() => {
    
    if ( value === 'failure' || value === 'deleted') setShowAlert(true)
    else setShowAlert(false)

    if (value === 'login') history.push("/login")

  }, [value])

  /**
   * Actualizar estatus
   * @param admin data del admin a actualizar pero cambiando el status
   */
  const handleStatus = (admin: Administrator) => {
    send({
      type: 'STATUS',
      data: {
        ...admin,
        status: admin.status ? 1 : 0
      }
    })
  };

  /**
   * Actualizar un admin
   * @param admin data nueva
   */
  const handleUpdate = (admin: Administrator) => {
    send({
      type: 'UPDATE',
      data: admin
    })
  };

  /**
   * Eliminar un admin
   * @param admin a eliminar
   */
  const handleDeleteAdmin = (admin: Administrator) : void => {
    // Alerta para confirmar la eliminación
    Swal.fire({
      title: getTranslation( Texts.adminDeleteAlertTitle as Word ),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslation( Texts.adminDeleteAlertConfirmation as Word ),
      cancelButtonText: getTranslation( Texts.cancel as Word ),
    }).then((result) => {
      if (result.isConfirmed) {
        // Enviamos la petición al actor
        send({
          type: 'DELETE',
          data: admin
        })
      }
    })
  }

  return ( 
    <>

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = {value !== 'deleted' ? 'error' : 'success'}
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          {  value !== 'deleted' 
            ? getTranslation( Texts.generalError as Word ) 
            : getTranslation( Texts.adminDeleteAlertSuccess as Word )
          }
        </Alert>
      </Snackbar>

      <div className = "overflow-x-scroll lg:overflow-x-hidden">
        <table className = "table-auto shadow-md mt-10 w-full capitalize">
          <thead className = "bg-input-background">
            <tr>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderName as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderLastName as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderEmail as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderPhone as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderMobile as Word ) }
              </th>
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderRole as Word ) }
              </th>
              { isEditable && (
                <th className = "py-2 border border-table">
                  { getTranslation( Texts.adminTableHeaderStatus as Word ) }
                </th>
              )}
              <th className = "py-2 border border-table">
                { getTranslation( Texts.adminTableHeaderCreationDate as Word ) }
              </th>
              { (isEditable || isRemovable) && (
                <th className = "py-2 border border-table">
                  { getTranslation( Texts.adminTableHeaderActions as Word ) }
                </th>
              )}
            </tr>
          </thead>

          <tbody>

            { ( value !== 'loading' && context.admins ) && (
              <>
                {context.admins.data.map((admin: Administrator) => (
                  <tr
                    key = { admin.uuid }
                  >
                    <td className = "p-2 border border-table">
                      { admin.name }
                    </td>
                    <td className = "p-2 border border-table">
                      { admin.lastName || '-' }
                    </td>
                    <td className = "p-2 border border-table lowercase">
                      { admin.email }
                    </td>
                    <td className = "p-2 border border-table">
                      { admin.landline || '-' }
                    </td>
                    <td className = "p-2 border border-table">
                      { admin.mobile_phone }
                    </td>
                    <td className = "p-2 border border-table">
                      { getTranscriptionOfRole(admin.role, lang) }
                    </td>
                    { isEditable && (
                      <td className = "p-2 border border-table text-center">
                        { (value === 'changing' &&  context.admin.uuid === admin.uuid )
                            ? ( <CircularProgress /> ) 
                            : (
                              <Switch
                                checked = { !!admin.status }
                                onChange = { e => handleStatus({ ...admin, status: e.target.checked }) } 
                              />
                            ) 
                        }
                      </td>
                    )}
                    <td className = "p-2 border border-table">
                      { admin.created }
                    </td>
                    { (isEditable || isRemovable) && (
                      <td className = "p-2 border border-table">

                          <div className="flex justify-between 2xl:justify-evenly items-center">
                            { isEditable && (
                              <div
                                // disabled = { value === 'updating' || value === 'removing'}
                                className = "rounded-full bg-green-500 md:mr-4"
                              >
                                <Tooltip
                                  title = {getTranslation( Texts.userEditTooltip as Word )}
                                  placement = "top"
                                >
                                  <IconButton
                                    onClick = { () => handleUpdate(admin) }
                                  >
                                    <CreateIcon 
                                      fontSize = 'small'
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )}

                            { isRemovable && (
                              (value === 'deleting' && context.admin.uuid === admin.uuid )
                                ? (
                                  <CircularProgress color = "secondary"/>
                                ) 
                                : (
                                  <div  
                                    className = "rounded-full bg-red-600"
                                  >
                                    <Tooltip 
                                      title = {getTranslation( Texts.userDeleteTooltip as Word )}
                                      placement="top"
                                    >
                                      <IconButton
                                        disabled = { value === 'updating' }
                                        onClick = { () => handleDeleteAdmin(admin)}
                                      >
                                        <DeleteIcon 
                                          fontSize = 'small'
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) 
                            )}
                          </div>
                      </td>
                    )}
                </tr>
                ))}
              </>
            )}

          </tbody>

        </table>
      </div>
    </>
   );
}
 
export default AdminList;