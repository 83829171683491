import React, { FC } from 'react'

// Dependencies
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface RButtonCloseProps {
  onClick: () => void
}
 
const RButtonClose: FC<RButtonCloseProps> = ({ onClick }) => ( 
    <IconButton onClick = { onClick }>
      <CloseIcon />
    </IconButton>
  )
 
export default RButtonClose;