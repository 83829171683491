import React, { FC, useState } from 'react'

// Types
import { ShowChallenge, TeamMetric } from '../../../types';

// Components
import Group from '../utils/group/Group';

// hook
import useTranslation from '../../../hooks/useTranslation'
import GroupDetail from '../utils/group/GroupDetail';

interface GroupChallengeProps {
  isLoadingMetrics: { flag: boolean, who: string },
  isLoadingMembers: { flag: boolean, who: string },
  data: ShowChallenge,
  openDetail: boolean,
  goalsDetail: TeamMetric[],
  onClose: () => void,
  showDetail: (uuid: string) => void,
  handleMembers: (data: { name: string, uuid: string }) => void
}
 
/**
 * Contenedor de la lista de equipos
 * @param props contiene todas las propiedades necesarias descritas en
 * GroupChallengeProps
 * @param props.data en general del reto
 * @param props.openDetail bandera para mostrar o no el detalle del usuario
 * @param props.goalsDetail listado de objetivos
 * @param props.removing saber a quien se esta eliminando
 * @param props.onClose función que se ejecuta cuando se esta cerrando el modal de 
 * @param props.removeUser función que se ejecuta para eliminar un usuario
 * @param props.showUserDetail función que se ejecuta para saber los detalles del usuairo
 * @returns Contenedor de la lista de equipos
 */
const GroupChallenge: FC<GroupChallengeProps> = ({ 
  data, openDetail, goalsDetail, isLoadingMetrics, isLoadingMembers,
  onClose, showDetail, handleMembers
}) => {

  // hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Guardar la data para mostrar cuales son los objetivos completos
  const [teamDetail, setTeamDetail] = useState({
    uuid: '',
    percentage: 0,
    position: 0,
    earned: 0,
    total: 0,
    team: 0,
    rookpoints: 0
  })

  /**
   * Set the information to show in the detail of the group
   * @param uuid of the group
   * @param percentage of the group
   * @param earned of the group
   * @param total of objectives
   * @param team name
   * @param position of the group
   * @param rookpoints of the group
   */
  const handleClick = (
    uuid: string, percentage: number, earned: number, 
    total: number, team: number, position: number, rookpoints: number
  ) : void => {
    
    setTeamDetail({ percentage, earned, total, team, position, uuid, rookpoints })
    showDetail(uuid)

  }

  return ( 
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        { data.challenge_teams && (
            data.challenge_teams.map((team, idx) => {

              const earned : number = team.earned || 0

              return (
                
                <Group
                  isFree = { data.challenge_compliance === 'free' }
                  key={team.team_uuid} 
                  team={ Number(team.name) } 
                  position={ (idx + 1) }
                  earned={ (team.progress || 0) >= 100 ? data.total_goals : earned } 
                  total={ data.total_goals } 
                  percentage={ team.progress || 0 } 
                  rookPoints = { team.rookpoints || 0 }
                  loadingMetrics = { 
                    isLoadingMetrics.flag && isLoadingMetrics.who === team.team_uuid 
                  }
                  loadingMembers = { 
                    isLoadingMembers.flag && isLoadingMembers.who === team.team_uuid 
                  }
                  onClick={ 
                    () => handleClick( 
                        team.team_uuid, 
                        team.progress || 0, 
                        earned, 
                        data.total_goals, 
                        Number(team.name),
                        (idx + 1),
                        team.rookpoints || 0
                      ) 
                  }    
                  onClickMembers={
                    () => handleMembers({
                      uuid: team.team_uuid, 
                      name: `${getTranslationByKey('team')} ${team.name}`
                    }) 
                  }            
                />

              )
            })

        )}
      </div>

      <GroupDetail 
        isFree = { data.challenge_compliance === 'free' }
        open = { openDetail }
        onClose = { onClose } 
        teamName = {`${getTranslationByKey('team')} ${teamDetail.team}`}
        goals = {{ earned: teamDetail.earned, total: teamDetail.total  }}
        percentage = { teamDetail.percentage }
        rookpoints = { teamDetail.rookpoints }
        goalList = { goalsDetail || [] }
        handleMembers = { () => handleMembers({ 
          uuid: teamDetail.uuid,
          name: `${getTranslationByKey('team')} ${teamDetail.team}`
        })}
      />

    </>
  );
}
 
export default GroupChallenge;