import React, { FC } from 'react';

// Styles
import { BottomLayer, TopLayer } from './styles/ProgressStyles'

export interface ProgressBarProps {
  progress: number,
  isFloating: boolean
}

/**
 * Barra de progreso
 * @param props contiene todas las propiedades necesarias descritas en ProgressBarProps
 * @param props.progress porcentaje de progreso que tiene la clase
 * @param props.isFloating saber si la barra esta flotando
 * @returns barra de progreso
 */
const ProgressBar: FC<ProgressBarProps> = ({ progress, isFloating }) => (progress !== -2 ? (
  <BottomLayer 
    isFloated = { isFloating }
  >
      <TopLayer
        style = {{ width: `${progress}%` }}
      >
        <span>{ Number.isNaN(Math.floor(progress)) ? 0 : Math.floor(progress) }%</span>
      </TopLayer>
  </BottomLayer>
) : null);

export default ProgressBar;