import React, { FC } from 'react'

// Components
import { RBox } from '../../utils/RookLayout';

// Assets
import rookPointsImage from '../../../assets/icons/CoinRM.svg'

interface FreeBodyProps {
  rookpoints: number,
  rounded ?: boolean
}
 
const FreeBody: FC<FreeBodyProps> = ({ rookpoints, rounded = false }) => {
  return ( 
    <RBox 
      className={
        `items-center justify-center p-5 gap-4 bg-card-body ${rounded && 'rounded-2xl my-4'}`
      }
    >
      <img 
        src = { rookPointsImage } 
        alt = 'rookpoints' 
        className='w-1/6'
      />
      <div>
        <p className='font-thin'>
          RookPoints
        </p>
        <p className='text-center'>
          { Number(rookpoints).toFixed(2).replace(/\.00/, '') } rp
        </p>
      </div>
    </RBox> 
  );
}
 
export default FreeBody;