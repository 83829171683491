import React, { FC } from 'react'

// Icons
import GroupIcon from '@mui/icons-material/Group';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import GroupButton from './GroupButton';
import { RBox } from '../../../utils/RookLayout';
import { RButtonLoading } from '../../../utils/RookButtons';

// Hooks
import useTranslation from '../../../../hooks/useTranslation'

interface GroupBodyActionsProps {
  loadingMembers: boolean,
  loadingMetrics: boolean,
  handleProgress: () => void,
  handleMembers: () => void,
}
 
const GroupBodyActions: FC<GroupBodyActionsProps> = ({
  loadingMetrics, loadingMembers,
  handleProgress, handleMembers
}) => {

  const { getTranslationByKey } = useTranslation()

  return ( 
    <RBox type='grid' className='grid-cols-2 lg:grid-cols-none'>

      <GroupButton
        className='bg-white text-black'
        onClick={ handleProgress }
      >
        <RButtonLoading
          loading = { loadingMetrics }
          sx = {{ margin: '0.25rem 0' }}
        >
          <VisibilityIcon  />
          { getTranslationByKey('progress') }
        </RButtonLoading>
      </GroupButton>

      <GroupButton
        className='bg-primary rounded-br-md'
        onClick={ handleMembers }
        >
        <RButtonLoading
          color = 'secondary'
          loading = { loadingMembers }
          sx = {{ margin: '0.25rem 0' }}
        >
          <GroupIcon />
          { getTranslationByKey('team') }
        </RButtonLoading>
      </GroupButton>
    </RBox>
  );
}
 
export default GroupBodyActions;