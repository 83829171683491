import { makeStyles } from '@mui/styles';

// Stilos del select
export const inputStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    color: 'white',
    borderRadius: '0.75rem!important',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0.75rem!important',
        borderColor: '#424242'
      },
      '& svg':{
        color: 'white'
      }
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '#424242'
    },
  },
});

// Menu del select
export const menuStyles = makeStyles({
  menuPaper: {
    backgroundColor: '#424242',
    color: "white"
  }
})