import React, { FC, useContext } from 'react'

// Dependencies
import 'moment-timezone'
import moment from 'moment'

// Types and models
import Texts from '../../models/Texts'
import { Word, Session, TrainingMetrics } from '../../types';

// Context
import UIContext from '../../context/ui/UIContext'

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface MetricsDetailsProps {
  session: Session,
  additional: TrainingMetrics
}

/**
 * Wrapper o vista de las métricas del entrenamiento
 * @param props contiene todas las propiedades necesarias descritas en MetricsDetailsProps
 * @param props.session es la info del entrenamiento las primeras dos columnas
 * @param props.additional es la info extra del entrenamiento la ultima columna
 * @returns info del entrenamiento y gráficas
 */
const MetricsDetails: FC<MetricsDetailsProps> = ({ session, additional }) => {

  // Hook para traducir
  const { getTranslation, getTranslationByKey } = useTranslation()

  // Context 
  const { centerData } = useContext( UIContext )

  const formatDuration = (duration: string) : string => {
    
    if (!duration) return '00 s'

    const postFixed = ['hrs', 'min', 's']
    const time : number = Number(duration)
    
    const timeWithDots = new Date(time * 1000).toISOString().substr(11, 8).split(':');
    let formattedTime = ''

    if (postFixed.length !== timeWithDots.length) return ''

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < postFixed.length; index++)
      if (timeWithDots[index] !== '00')
        formattedTime = `${formattedTime} ${timeWithDots[index]} ${postFixed[index]}`
    
    return formattedTime

  }

  return ( 
    <div className = "p-5 border-l-8 border-red-600 mt-10 rounded-md bg-card-header">

      <h3 className = "font-bold uppercase mb-10 md:mb-0">
        { getTranslation(Texts.dashboardDetails as Word) }
      </h3>

      <div className="md:flex md:ml-8 md:my-4">

        <div
          className = "border-b-2 md:border-b-0 md:border-r-2 border-gray-450 pb-8 md:pb-0 md:pr-8 md:mr-8"
        >
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.trainingDetailTime as Word ) }
            </h4>
            <p>
              { !session.offset 
                ? moment(session.start)
                  .format(`${getTranslationByKey('momentDateFormat')} HH:mm:ss`)
                : moment
                  .utc(session.start)
                  .tz(centerData?.offset_name || 'America/Mexico_City')
                  .format(`${getTranslationByKey('momentDateFormat')} HH:mm:ss`)
                
              }
            </p>
        </div>

        <div 
          className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 lg:gap-4 border-b-2 md:border-b-0 md:border-r-2 border-gray-450 pb-8 md:pb-0 md:pr-8 md:mr-8 mt-8 md:mt-0"
        >

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.name as Word ) }
            </h4>
            <p className = "capitalize">
              { session.user_name }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.userTableHeaderLastName as Word ) }
            </h4>
            <p className = "capitalize">
              { session.lastName || session.last_name_1}
            </p>
          </div>

          <div className = "break-words">
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.adminTableHeaderEmail as Word ) }
            </h4>
            <p>
              { session.email }
            </p>
          </div>

        </div>

        <div className = "grid grid-cols-1 lg:grid-cols-2 gap-8 pb-8 md:pb-0 md:pr-8 md:mr-8 mt-8 md:mt-0">

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.trainingDetailTypeSession as Word ) }
            </h4>
            <p>
              { additional.groupal_mode === 0 ? 'Individual' : 'Grupal'  }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.trainingDetailTypeTraining as Word ) }
            </h4>
            <div className="flex items-center justify-between capitalize">
              <p>
                { additional.training_type.training_name }
              </p>
            </div>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              { getTranslation( Texts.trainingDetailDuration as Word ) }
            </h4>
            <p>
              { formatDuration(additional.summaries.duration_time_tot) }
            </p>
          </div>

          <div>
            <h4 className = "uppercase text-gray-450 text-sm">
              Sensor
            </h4>
            <p>
              { additional.sensor }
            </p>
          </div>

        </div>

        {/* <div
          className = ""
        >
          <button
            type = 'button'
            className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
          >
            Enviar reporte
          </button>
        </div> */}

      </div>

    </div>
  )
}
 
export default MetricsDetails;