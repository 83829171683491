import { createMachine, assign, DoneInvokeEvent } from 'xstate'
import * as Sentry from "@sentry/react";

// dependencies
import _ from 'lodash'
import moment from 'moment'
import capitalize from '@mui/utils/capitalize';
import axiosClient from '../../config/axios'

// Types
import { 
  Clasification, 
  Reports, 
  PageSizeOption, 
  ChartData, 
  TrainingSummary, 
  TrainingTypeSummary,
  SearchIn,
  Sorting,
  Flow
} from '../../types'

// Utils
import Colors from '../../utils/colors'
import { generateRandomColors } from '../../utils';

type Pagination = {
  page: number,
  total: number,
  pageSize: number,
  totalPages: number,
  data: Clasification[]
}

/**
 * period: periodo a buscar
 * totalPages: número de páginas originales
 * statusError: error que de la API
 * currentPage: página actual
 * data: descargada del servidor
 * sorting: saber que columna se esta ordenando y si ascendente o descendente
 * sizes: Tamaños disponibles para el ranking
 * search: criterios de busqueda
 * pageSize: tamaño de la página
 * pageData: data de la página
 * sessions: data para la gráfica de puntos
 * training: data para la gráfica de pie
 * orderedData: data ordenada
 */
type ReportsMachineContext = { 
  period: string[],
  totalPages: number,
  statusError: number,
  currentPage: number,
  data: Reports | null,
  sorting: Sorting | null,
  sizes: PageSizeOption[],
  search: SearchIn | null,
  pageSize: PageSizeOption,
  pageData: Clasification[],
  sessions: ChartData | null,
  trainings: ChartData | null,
  orderedData: Clasification[],
}

/**
 * RESIZE: cambiar de tamaño las gráficas
 * SEARCH: buscar
 * PERIOD: Buscar en otro periodo
 * PAGEING: Paginar
 * SORT: Ordenar la tabla
 */
type ReportsMachineEvent = 
  | { type: 'RESIZE', data: PageSizeOption }
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'PERIOD', data: string[] }
  | { type: 'PAGEING', data: number }
  | { type: 'SORT', data: string }

/**
 * Obtener las estadisticas del centro
 * @returns estadisticas del centro
 */
const fetchStatistics = async () : Promise<Reports> => {
  
  const dateTo = moment().format('YYYY-MM-DD');
  const dateFrom = moment().subtract(7,'d').format('YYYY-MM-DD');

  const url : string = `/api/v2/center/report?from_date=${dateFrom}&to_date=${dateTo}`

  const response = await axiosClient.get(url)
  return response.data

}

/**
 * Obtener las estadisticas del centro
 * @returns estadisticas del centro
 */
const fetchStatisticsInAPeriod = async (context: ReportsMachineContext) : Promise<Reports> => {
  
  const { period } = context

  const url : string = `/api/v2/center/report?from_date=${period[0]}&to_date=${period[1]}`

  const response = await axiosClient.get(url)
  return response.data

}

/**
 * Obtener una paginación
 * @param items la lista de usuarios
 * @param page la página que deseamos obtener
 * @param pageSize el tamaño que deben tener las páginas
 * @returns una paginación
 */
const getPaginatedItems = (items: Clasification[], page: number, pageSize: number) : Pagination => {
  const pg = page || 1
  const pgSize = pageSize || 100
  const offset = (pg - 1) * pgSize
  const pagedItems = _.drop(items, offset).slice(0, pgSize);

  return {
    page: pg,
    pageSize: pgSize,
    total: items.length,
    totalPages: Math.ceil(items.length / pgSize),
    data: pagedItems
  };
}

/**
 * Modelar la data para el historial de sesiones
 * @param trainings lista de entramientos
 * @returns la data a mostrar en la gráfica de puntos
 */
const generateSessionsData = (trainings: TrainingSummary[]) : ChartData => {
  // formatemos la data de historial de sessiones
  const labels: string[] = []
  const sessions: number[] = []
  const rawRange = trainings.length * 0.5 / 169
  const range = Number(rawRange.toFixed(2))

  trainings.forEach((data, index) => {
    
    if (index === 0) {
      labels.push(data.date)
      sessions.push(data.total_trainings)
    }
    else {
      const previous = sessions[sessions.length-1]
      const diff = Math.abs( Number(data.total_trainings) - previous )

      if ( diff > range ){
        labels.push(data.date)
        sessions.push(data.total_trainings)
      }
    }

  })

  return {
    labels,
    datasets: [{
      label: '',
      data: sessions,
      borderColor: Colors.mainColor,
      tension: 0.4,
    }]
  }
}

/**
 * Generar la data de los entrenamientos
 * @param types objecto con la data de los entrenamientos
 * @returns la data a mostrar en la gráfica de pie
 */
const generateTypeTrainingData = (types: TrainingTypeSummary) : ChartData => {

  const labels : string[] = []
  const values : number[] = []
  
  // Sacamos los valores del objecto
  const entries = Object.entries(types)

  // Barremos las claves
  entries.forEach(entri => {
    labels.push(capitalize(entri[0]))
    values.push(entri[1])
  })

  // Generamos los colores
  const colors : string[] = generateRandomColors(labels.length)
  
  return {
    labels,
    datasets: [
      {
        label: '',
        data: values,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      }
    ]
  }

}

/**
 * Obtener el flujo por el cual ordenar
 * @param currentFlow saber si es ascendente o descendente
 * @param key la columna a ordenar
 * @returns un flujo a ordenar
 */
const getFlowSortingTable = (currentFlow: Sorting | null, key: string) : Flow=> {
  let flow: Flow = Flow.desc

  if (!currentFlow) flow = Flow.desc
  else if (currentFlow.key === key)
      if (currentFlow.flow === Flow.desc) flow = Flow.asc
      else if (currentFlow.flow === Flow.asc) 
          flow = Flow.normal 
        else if (currentFlow.flow === Flow.normal) 
          flow = Flow.desc


  return flow
}

const ReportsMachine = createMachine<ReportsMachineContext, ReportsMachineEvent>(
  {
    id: 'ReportsMachine',
    initial: 'loading',
    context: {
      data: null,
      period: [],
      search: null,
      pageData: [],
      orderedData: [],
      sorting: null,
      totalPages: 0,
      currentPage: 1,
      statusError: 0,
      sessions: null,
      trainings: null,
      pageSize: { key: '10', value: 10 },
      sizes: [
        {
          key: '10',
          value: 10
        },
        {
          key: '20',
          value: 20
        },
        {
          key: '50',
          value: 50
        },
        {
          key: '100',
          value: 100
        },
      ]
    },
    states: {
      login: {},
      idle: {
        on: {
          RESIZE: {
            actions: [
              'chagePageSize'
            ]
          },
          PAGEING: {
            target: 'updating',
            actions: assign({ currentPage: (c, event) => event.data })
          },
          PERIOD: {
            target: 'searching',
            actions: assign({ period: (c, event) => event.data })
          },
          SEARCH: {
            target: 'seeking',
            actions: [ 'prepareSearch' ]
          },
          SORT: {
            actions: [
              'sortData'
            ]
          }
        }
      },
      loading: {
        invoke: {
          id: 'fetchStatistics',
          src: fetchStatistics,
          onDone: {
            target: 'idle',
            actions: [
              'prepareData'
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo banear al usuario'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }
            })
          }
        }
      },
      updating: {
        always: [{
          target: 'idle',
          actions: [
            'updatePageData'
          ]
        }]
      },
      searching: {
        invoke: {
          id: 'fetchStatisticsInAPeriod',
          src: fetchStatisticsInAPeriod,
          onDone: {
            target: 'idle',
            actions: [
              'prepareData'
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo descargar la info de cierto periodo'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${errorMessage}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }
            })
          }
        }
      },
      seeking: {
        always: [{
          target: 'idle',
          actions: ['seekUser']
        }]
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    }
  },
  {
    actions: {
      prepareData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Reports> = _event

        if (
          event.type !== 'done.invoke.fetchStatistics' && 
          event.type !== 'done.invoke.fetchStatisticsInAPeriod'
        ) 
          return { ...context }

        // string to number calorias y rookpoints
        const classification = event.data.users_classification.map(
          (e) => ({...e, kcal: Number(e.kcal), rookpoints: Number(e.rookpoints)})
        )

        // Obtener la páginación
        const pages = getPaginatedItems(
          classification, 
          context.currentPage, 
          context.pageSize.value
        )

        // formatemos la data para las gráficas
        const sessionsData = generateSessionsData( event.data.trainings_count_per_datetime )
        const trainingData = generateTypeTrainingData(
          event.data.trainings_count_per_training_type
        )

        return {
          ...context,
          data: {
            ...event.data,
            users_classification: classification
          },
          pageData: pages.data,
          totalPages: pages.totalPages,
          sessions: sessionsData,
          trainings: trainingData,
          orderedData: classification
        }

      }),
      prepareSearch: assign((context, event) => {

        if (event.type !== 'SEARCH') return { ...context }

        return {
          ...context,
          search: event.data,
          currentPage: 1
        }

      }),
      chagePageSize: assign((context, event) => {

        if (event.type !== 'RESIZE') return { ...context }
        if (!context.data ) return { ...context }

        // Obtener la páginación
        const pages = getPaginatedItems(
          context.orderedData, 
          1, 
          event.data.value
        )

        return {
          ...context,
          pageSize: event.data,
          pageData: pages.data,
          totalPages: pages.totalPages,
          currentPage: 1
        }

      }),
      updatePageData: assign((context) => {

        if (!context.data ) return { ...context }

        // Obtener la páginación
        const pages = getPaginatedItems(
          context.orderedData, 
          context.currentPage, 
          context.pageSize.value
        )

        return {
          ...context,
          pageData: pages.data,
        }

      }),
      seekUser: assign((context) => {

        if (!context.data) return { ...context }

        if (!context.search || context.search.search === '-1') {

          // Obtener la páginación
          const pages = getPaginatedItems(
            context.data.users_classification, 
            context.currentPage, 
            context.pageSize.value
          )

          return {
            ...context,
            pageData: pages.data,
            totalPages: pages.totalPages,
          }
        }

        const newData = context.data.users_classification.filter(
          (user) => user.user.includes(context.search!.search)
        )

        return {
          ...context,
          pageData: newData,
          totalPages: 1
        }

      }),
      sortData: assign((context, event) => {

        if (event.type !== 'SORT') return { ...context }

        const flow : Flow = getFlowSortingTable(context.sorting, event.data)

        if (flow !== Flow.normal){

          const newOrder = _.orderBy(
            context.orderedData, 
            event.data, flow === Flow.asc ? 'asc' : 'desc' )

          // Obtener la páginación
          const pages = getPaginatedItems(
            newOrder, 
            1, 
            context.pageSize.value
          )

          return {
            ...context,
            sorting: {
              key: event.data,
              flow
            },
            pageData: pages.data,
            totalPages: pages.totalPages,
            currentPage: 1,
            orderedData: newOrder
          }
          
        }

        let sortedData = context.pageData

        if (context.data)
          sortedData = getPaginatedItems(
            context.data.users_classification, 
            context.currentPage, 
            context.pageSize.value
          ).data

        return {
          ...context,
          sorting: {
            key: event.data,
            flow
          },
          pageData: sortedData
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
) 

export default ReportsMachine