import React, { FC } from 'react'
import { DialogTitle, DialogTitleProps } from '@mui/material';

interface RTitleProps extends DialogTitleProps {
  title: string
}
 
const RTitle: FC<RTitleProps> = ({ title, style, id }) => ( 
    <DialogTitle
      style={ style }
      id = { id }
    >
      { title }
    </DialogTitle>
  )
 
export default RTitle;