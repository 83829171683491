import { interval, map, takeWhile, Subscription } from "rxjs"
import shortid from "shortid"
import { Links, Meta, Training, Athlete, Gender, Room, Measurements  } from "../types"

/**
 * Capitalizar la primera letra de un texto
 * @param text a capitalizar
 * @returns texto capitalizado con la primera letra ejemplo: hola a todos -> Hola a todos
 */
export const capitalize = (text: string): string => {

  if (text) return text.charAt(0).toUpperCase() + text.slice(1)

  return text

}

/**
 * Función para indicar las propiedades de cada tab
 * @param index el número de la tab de Material UI
 * @returns el id de la tab y atributo html
 */
export const a11yProps = (index: number) : object => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
})

/**
 * Buscar en la dirección obtenida sus componentes
 * @param {array} components Tiene la información de la dirección
 * @param {array} lookingfor que parte se va a buscar calle, colonia . . . 
 * @returns la parte de la dirección buscada
 */
export const getComponent = ( components: any[], lookingfor: string[] ) : object | null => 
      
  // se busca dentro de los componentes
  components.find( i => {

    let index = 0
    let flag = false

    // Busca si la tiene dada de alta
    while ( index < lookingfor.length && !flag ){

        if( i.types.includes( lookingfor[index] ) )
            flag = true

        index += 1

    }

    // retorna si la encontró
    if( flag ) return i

    return null

})  

/**
 * Traducir admin o coach
 * @param text a traducir
 * @param lang en el que esta el admin
 * @returns texto traducido
 */
export const getTranscriptionOfRole = (text: string, lang: string) : string => {
    
  if (text === 'admin' && lang === 'es')
    return 'Administrador'

  if (text === 'admin' && lang === 'en')
    return 'Administrator'

  if (text === 'coach' && lang === 'es')
    return 'Entrenador'

  return capitalize(text)

}

export const colorChartsPallete : string[] = [
  '#F0462F', '#21A8FA', '#FFEC01',
  '#E42364', '#2EBBD9', '#FEC200',
  '#9825B8', '#239687', '#FB9900',
  '#6337BE', '#57AF3F', '#FA5901',
  '#3E4FBB', '#8FC42C', '#652A0F',
  '#2A94FA', '#CFDD01', '#7756A7',
]

/**
 * Generate a hex color
 * @returns a hex color
 */
export const getRandomColor = (fixed = false) : string => {
  if (!fixed) {
    let color = '#';
    const letters = '0123456789ABCDEF';
  
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  
    return color;
  }
  
  return colorChartsPallete[Math.floor(Math.random()*colorChartsPallete.length)]
}


/**
 * Generar una lista de colors
 * @param size : number of colors;
 * @returns una lista de hex colors
 */
export const generateRandomColors = (size: number) : string[] => {
  const colors : string[] = []
  const template = [...colorChartsPallete]

  for (let index = 0; index < size; index += 1) {
    const first = template.shift()
    colors.push(first || '#652A0F')
    template.push(first || '#652A0F')
  }

  return colors
}

/**
 * Agregar un número con comas
 * @param x numero a agregarles las comas
 * @returns un número con comas
 */
export const numberWithCommas = (x: number) => x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

/**
 * Cuando la paginacion falla devolver una dummy
 */
export const dummyLinks : Links = {
  first: "",
  last: "",
  next: ""
}

/**
 * Generar dummy meta
 * @param current la página actual
 * @param last ultima página
 * @returns un dummy meta
 */
export const generateMeta = (current: number, last: number, ) : Meta=> ({
    'current_page': current,
    'last_page': last,
    'per_page': 20,
    from: 20,
    links: [],
    path: '',
    to: 20,
    total: 20,
  })

/**
   * Calcular aproximado de la edad
   * @param birthday fecha de nacimiento
   * @returns un aproximado de la edad
   */
 export const calculateAge = (birthday: Date) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const dataURItoBlob = (dataURI: string) : Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], {type: mimeString});
  return blob;

}

export const blobToBase64 = (blob: Blob) => new Promise((resolve, _) => {
  const reader = new FileReader()
  reader.onloadend = () => resolve(reader.result)
  reader.readAsDataURL(blob)
})

export const fakeMeasurement : Training = {
  "final_user_uuid": '',
  "pseudonym":"lince!12345678",
  "email":"oscar.villanueva.prieto@gmail.com",
  "steps_tot":20000,
  "calories_tot":3000,
  "hr":200,
  "effort":60,
  "user_url_image":"https://api2-rookmedia.s3.us-east-2.amazonaws.com/images/NCaG8CpmHCqf5AIV5U1eFjw8aSiG9wZE6QlEsq6J.jpg",
  "status": true
}

export const fakeEngAthlete: Athlete = {
  name: 'Choose',
  email: 'Choose',
  pseudonym: 'Choose',
  birthday: 'Choose',
  sex: Gender.choose,
  'last_name_1': '',
  'url_image': null,
  last_name_2: ''
}

export const fakeSpaAthlete: Athlete = {
  name: 'Seleccionar',
  email: 'Seleccionar',
  pseudonym: 'Seleccionar',
  birthday: 'Seleccionar',
  sex: Gender.choose,
  'last_name_1': '',
  'url_image': null,
  last_name_2: ''
}

export const fakeEngRoom : Room = {
  uuid: "choose",
  name: "Choose",
  status: 0
}

export const fakeSpaRoom : Room = {
  uuid: "choose",
  name: "Seleccionar",
  status: 0
}

export const insertDummyData = (time: number, limit: number,next: (value: Measurements) => void) : Subscription => {
    
  const fake$ = interval(time).pipe(
    takeWhile(counter => counter <= limit),
    map<number, Measurements>( counter => {
      const data = Array(counter)
        .fill(fakeMeasurement) 
        .map( f => ({ ...f, 
          final_user_uuid: shortid.generate(),
          effort: Math.floor(Math.random() * ( 140 - 40 ) + 40)
        }))
      return {
        data,
        'remaining_time': `${60 - (counter + (time / 1000))}`,
        'time_current': '',
        'time_finish': '',
      }
    })
  )

  return fake$.subscribe(next)

}

export const toggleFullScreen = (isMax: boolean) : void=> {
  
  const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
  
  const docWithBrowsersExitFunctions = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
  };

  if (isMax) {
    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen().catch(() => {});
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen().catch(() => {});
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen()
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen().catch(() => {});
    }
  }
  else if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen().catch(() => {});
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen().catch(() => {});
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen()
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen().catch(() => {});
    }
}

export const mobileAndTabletCheck = () : boolean => {
  let check = false;
  const a = navigator.userAgent||navigator.vendor
  // eslint-disable-next-line no-useless-escape
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) check = true;
  return check
};