import { createMachine, assign, DoneInvokeEvent } from 'xstate'

// Dependencies
import * as Sentry from "@sentry/react";
import axiosClient from '../../config/axios'

// Types
import { LocalStorageKey, Room, CurrentUser, AuthData, CenterData } from '../../types'

/**
 * currentUser: usuario actual
 * contractOpen: saber si mostrar o no el modal
 * recentRRemote: saber el remote más reciente
 * recentRLegend: saber el daq más reciente
 * hostname: hostname del centro
 */
type DashboardMachineContext = {
  currentUser: CurrentUser | null,
  contractOpen: boolean,
  recentRRemote: Room | null | undefined,
  recentRLegend: Room | null,
  hostname: string
}

/**
 * INIT: cargar el usuario actual
 * ACCEPT: aceptar el contrato
 * TOGGLE: Mostrar u ocultar el modal
 */
type DashboardMachineEvents = 
  | { type: 'INIT', data: CurrentUser}
  | { type: 'ACCEPT', data: string}
  | { type: 'TOGGLE'}


/**
 * Aceptar los acuerdos
 * @param context de la maquina
 * @returns respuesta del server
 */
const acceptAgreement = async (context: DashboardMachineContext) : Promise<any> => {
  
  const { hostname } = context

  const response = await axiosClient.put('/api/v2/admin/accept-agreement', { hostname })

  return response.data

}

const fetchRoomsToUpdateQuickAccess = async (context: DashboardMachineContext) : Promise<CenterData>=> {
  
  const { currentUser } = context

  if (!currentUser) throw new Error("");

  // Revisamos que haya un room reciente
  const remoteKey : string = `${LocalStorageKey.rookRemoteRecent}-${currentUser.uuid || ''}`
  const room : Room | null = JSON.parse(localStorage.getItem(remoteKey)||'null')

  if (!room) throw new Error("");

  // Sacamos el branch uuid del header de axios
  const uuidBranch:string|undefined=axiosClient.defaults.headers.common['branch-uuid'] as string

  if (!uuidBranch) throw new Error("");
  
  const response = await axiosClient.get(`/api/v2/centers/${uuidBranch}`)
  return response.data

}

const DashboardMachine = createMachine<DashboardMachineContext, DashboardMachineEvents>(
  {
    id: 'DashboardMachine',
    initial: 'idle',
    context: {
      currentUser: null,
      contractOpen: false,
      recentRRemote: undefined,
      recentRLegend: null,
      hostname: ''
    },
    states: {
      accepted: {},
      idle: {
        on: {
          INIT: {
            target: 'loaded',
            actions: [
              assign({ currentUser: (_, event) => event.data }),
              'findPreviousDAQ'
            ]
          }
        }
      },
      loaded: {
        invoke: {
          id: 'fetchRoomsToUpdateQuickAccess',
          src: fetchRoomsToUpdateQuickAccess,
          onDone: {
            actions: ['updatePreviousSessions']
          },
          onError: {
            actions: [
              'findPreviousSessions'
            ]
          }
        },
        on: {
          TOGGLE: {
            actions: assign({ contractOpen: (context) => !context.contractOpen })
          },
          ACCEPT: {
            target: 'accepting',
            actions: assign({ hostname: (_, event) => event.data })
          }
        }
      },
      accepting: {
        invoke: {
          id: 'acceptAgreement',
          src: acceptAgreement,
          onDone: {
            target: 'accepted',
            actions: [ 'prepareAcceptance' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const errorMessage : string = 'No se pudo aceptar el acuerdo'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: errorMessage,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }

              Sentry.captureException(event.data)

              return { ...context }

            })
          }
        }
      },
      failure: {}
    }
  },
  {
    actions: {
      findPreviousDAQ: assign((context) => ({
          ...context,
          recentRLegend: JSON.parse(
            localStorage.getItem(LocalStorageKey.rookLegendRecent) || "null"
          )
        })),
      updatePreviousSessions: assign((context, _event: any) => {
        
        // Cargamos el evento
        const event : DoneInvokeEvent<CenterData> = _event

        // Revisamos que hay un usuario
        const { currentUser } = context
        if (!currentUser) return { ...context }

        // Buscamos el room anterior
        const remoteKey = `${LocalStorageKey.rookRemoteRecent}-${currentUser.uuid || ''}`
        const room : Room | null = JSON.parse(localStorage.getItem(remoteKey)||'null')

        if (!room) 
          return {
            ...context,
            recentRRemote: null,
          }

        // Buscamos el room nuevo o actual
        const currentRoom : Room[] = event.data.rooms.filter(({ uuid }) => uuid === room.uuid )
        
        // Si no existe eliminamos el room de lo localStorage
        if (currentRoom.length === 0) {

          localStorage.removeItem(remoteKey)

          return {
            ...context,
            recentRRemote: null,
          }
        }
        
        return {
          ...context,
          recentRRemote: currentRoom.pop() || null,
        }

      }),
      findPreviousSessions: assign((context) => {

        if (!context.currentUser) return { ...context }

        const remoteKey : string = `${LocalStorageKey.rookRemoteRecent}-${context.currentUser.uuid || ''}`

        return {
          ...context,
          recentRRemote: JSON.parse(localStorage.getItem(remoteKey)||'null'),
        }

      }),
      prepareAcceptance: assign((context, _event: any) => {

        const event : DoneInvokeEvent<any> = _event

        if (event.type !== 'done.invoke.acceptAgreement') return { ...context }

        // Sacamos la data que este iniciada para actualizarla
        const rawAuth = localStorage.getItem( LocalStorageKey.auth )
        const authData : AuthData = JSON.parse( rawAuth || 'null' )

        if (authData) {
          authData.showAgreement = false
          localStorage.setItem(LocalStorageKey.auth, JSON.stringify(authData))
        }

        return { 
          ...context,
          contractOpen: false
        } 

      })
    }
  }
)

export default DashboardMachine