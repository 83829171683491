import React, { FC } from 'react'

// Components
import { RBox } from '../../utils/RookLayout';

interface ChallengeInfoTextProps {
  title: string
  text: string
}
 
const ChallengeInfoText: FC<ChallengeInfoTextProps> = ({ title, text }) => {
  return ( 
    <RBox
      type = 'flex'
      className='flex-col lg:flex-row lg:gap-x-1'
    >
      <p className='text-center md:text-left font-bold text-sm'>
        { title }:
      </p>
      <p className='text-center md:text-left font-thin text-sm capitalize'>
        { text }
      </p>
    </RBox>
  );
}
 
export default ChallengeInfoText;