import { useContext, useState, useEffect } from 'react';

// Types
import Texts from '../models/Texts'
import { Translation, Word } from '../types';

// Context
import UIContext from '../context/ui/UIContext';

type ToolBox = {
  getLabel: (word: Word) => string
  getTranslation: (word: Word) => string
  getTranslationByKey: (key: string) => string
  getTranslationByLang: (key: string, language: string) => string
};

type UseTranslationProps = {
  lang : Translation
};

/**
 * Hook para traducir una palabra dentro de models/Texts
 * @param props puede incluirse el idioma con que se va a traducir 
 * @returns una caja de herramientas con funciones para traducir 
 */
const useTranslation = (props ?: UseTranslationProps) : ToolBox => {

  // Sacamos el idioma del context
  const { lang } = useContext(UIContext);

  // Lo guardamos en el state el valor inicial del idioma
  const [base, setBase] = useState(props ? props.lang : lang);

  // cada que cambie el lenguaje actualizamos el hook
  useEffect(() => {
    setBase(lang);
  }, [lang]);

  /**
   * @deprecated
   * Función para saber que texto mostrar
   * @param word Palabra que va a ser traducida o mostrada
   * @param lang Ingles o español
   * @returns Texto final
   */
   const getTranslation = (word: Word): string => {

    if ( !word ) return ''

    return (base === Translation.en ? word.en : word.es)
  };

  /**
   * Buscar una traducción basado en el key
   * @param key texto a buscar
   * @returns un texto traducido
   */
  const getTranslationByKey = (key: string) : string => {
   
    const text = Texts[key] as Word

    if (text && text.en) return base === Translation.en ? text.en : text.es

    return ''
    
  }

  /**
   * Traducir un texto basado en el texto
   * @param key que vamos a buscar dentro del diccionario de palabras
   * @param language el lenguaje que estamos buscando ingles o español
   * @returns un texto traducido
   */
  const getTranslationByLang = (key: string, language: string): string => {

    const text = Texts[key] as Word

    if(text && text.en)

    if (text && text.en) return language === Translation.en ? text.en : text.es

    return ''

  }

  /**
   * Función para saber el label de una palabra
   * @param word palabra a la que vamos a sacarle el label
   * @returns un label
   */
  const getLabel = (word: Word | null | undefined): string => word ? word.label : ''

  return {
    getLabel,
    getTranslation,
    getTranslationByKey,
    getTranslationByLang
  };
};
export default useTranslation;
