import React, { FC, CSSProperties } from 'react'

interface RRoundedContainerProps {
  className ?: string,
  sx ?: CSSProperties,
  style ?: CSSProperties,
}
 
const RRoundedContainer: FC<RRoundedContainerProps> = ({ className, style, sx, children }) => ( 
    <div
      className = {`rounded-lg ${className || ''}`}
      style = {{ ...style, ...sx }}
    >
      { children }
    </div>
  )
 
export default RRoundedContainer;