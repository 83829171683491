import React, { FC, useContext } from 'react'

// Dependencias
import styled from 'styled-components'

// Types
import { Word } from '../../types'

// Context
import UIContext from '../../context/ui/UIContext'

// Models
import Texts from '../../models/Texts'

// Hook
import useTranslation from '../../hooks/useTranslation'
import useMenuList from '../../hooks/useMenuList'

// Component
import MenuItem from './MenuItem'

interface NavigationProps {
  
}

type CenterImageContainerProps = {
  image?: string | null
}

const CenterImageContainer = styled.div<CenterImageContainerProps>`
  width: 4rem;
  height: 4rem;
  margin: 0.5rem auto 0 auto;
  background-image: ${props => props.image ? `url(${props.image})` : "url('/images/default.png')"};
  border-radius: 1rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`

/**
 * Lista de navegación disponible
 * @returns la lista de opciones con el logo del centro, el contenedor esta en layout
 */
const Navigation: FC<NavigationProps> = () => {

  // hook para traducir
  const { getTranslation } = useTranslation()

  // Hooks para obtener el menu
  const { menuOptionList } = useMenuList()

  // Sacar la imagen del centro
  const { centerImage } = useContext( UIContext )

  return ( 
    <aside className = "sm:min-h-screen md:min-h-9/10 sm:w-full md:w-1/4 lg:w-1/5 xl:w-1/6 2xl:w-1/8 2xl:min-w-14 xl:border-r xl:border-gray-450 xl:bottom-0 xl:z-50 bg-background">

      <CenterImageContainer
        image = { centerImage !== '' ? centerImage : null }
      />

      <div className = "mt-2 xl:border-b xl:border-gray-450">
        <div className = "px-5">

          <h1 className = "uppercase text-gray-450 tracking-widest mb-4">
            { getTranslation( Texts.navTitle as Word ) }
          </h1>

          {menuOptionList.map(menu => (

            <MenuItem
              key = { menu.key }
              icon = { menu.icon }
              title = { menu.title }
              action = { menu.action }
              sub = { menu.sub }
            />

          ))}

        </div>
      </div>
    </aside>
  )
}
 
export default Navigation;