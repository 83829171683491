import React, { ChangeEvent, FC, useRef, useState, useContext } from 'react'

// Dependencies
import * as Sentry from '@sentry/react'
import { Snackbar, Alert, CircularProgress } from '@mui/material';

// Icon
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// Context
import UIContext from '../../context/ui/UIContext'

// Types and models
import Texts from '../../models/Texts'
import { Word } from '../../types'

// Hook
import useTranslation from '../../hooks/useTranslation'

// Styles
import { CenterImageContainer, Camera } from './CenterStyles'

interface CenterImageProps {
  name: string,
  loading: boolean,
  handleImage: (image: File) => void
}
 
/**
 * Imagen del centro
 * @param {Object} props contiene todas las propiedades necesarias descritas en CenterGalleryProps
 * @param {string} props.name nombre del centro a mostrar
 * @param {boolean} props.loading saber si se esta mandando lo imagen al servidor
 * @param {Function} props.handleImage función para actualizar la imagen del centro
 * @returns componente con la imagen del centro
 */
const CenterImage: FC<CenterImageProps> = ({ name, loading, handleImage }) => {

  // Referencia al input para hacer clic
  const inputFile = useRef<any>()

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Sacamos la imagen del centro
  const { centerImage } = useContext( UIContext )

  /**
   * Manejador de la imagen
   * @param e evento para recibir la imagen
   * @returns early return 
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) : void => {
    e.preventDefault()

    // Verificamos que exista la imagen
    if( !e.currentTarget.files ) return 

    // Sacamos el archivo
    const file = e.currentTarget.files.item(0)

    if ( !file ) {
      Sentry.captureMessage(
        `No se encontro la imagen profile: ${JSON.stringify(e.currentTarget.files)}`
      )
      return 
    }

    // Verificamos el tamaño del la imagen
    const size = (file.size / 1024 / 1024).toFixed(2)

    // Si es mayor a dos megas mandamos la alerta
    if ( Number(size) > 2 ) {
      setShowAlert( true )
      return 
    }

    // Si no mandamos la imagen
    handleImage( file )

  }

  return ( 
    <div
      style = {{ flexBasis: '40%' }}
      className = "flex flex-col items-center justify-center h-full mb-10 lg:mb-0"
    >

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = 'error' 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getTranslation( Texts.profileImageSizeError as Word ) }
        </Alert>
      </Snackbar>

      <input 
        type="file" 
        id="image_admin" 
        name="image_admin" 
        accept="image/png, image/jpeg"
        className = "hidden"
        onChange = { handleFileChange }
        ref = { inputFile }
      />

      { loading 
        ? (
          <CircularProgress 
            color = "secondary"
            size = '10rem'
          />
        ) 
        : ( 
          <button 
            type = "button"
            onClick = { () => inputFile.current.click() }
          >
            <CenterImageContainer
              image = { centerImage !== '' ? centerImage : null }
            >
              <Camera>
                <CameraAltIcon />
              </Camera>
            </CenterImageContainer>
          </button>
        )
      }



      <div className = "text-center">
        <h3 className = "text-title font-bold text-xl mt-8 capitalize">
          { name }
        </h3>
      </div>

    </div>
  )
}
 
export default CenterImage;