import React, { FC, ReactElement, useEffect, useState } from 'react';

// Dependencies
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import { useFormik } from "formik";
import { useActor } from '@xstate/react'
import { useHistory } from 'react-router-dom'
import { Alert, AlertColor, CircularProgress, Snackbar } from '@mui/material';

// Icons
import KeyIcon from '@mui/icons-material/Key';
import TokenIcon from '@mui/icons-material/Token';
import PersonIcon from '@mui/icons-material/Person';

// types
import { ZoomCredentialsBody } from '../../types/index';

// Components
import { InputForm, InputChangePasswordForm } from '../utils/RookFormInputs';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface CenterStreamingProps {
  service: any
}
 
const CenterStreaming: FC<CenterStreamingProps> = ({ service }) => {

  // Maquina que controla la vista
  const [machine, send] = useActor(service)
  const { context, value, matches }: any = machine

  // Alerta
  const [alert, setAlert] = useState(false)

  // Hook para navegar
  const history = useHistory()

  useEffect(() => {
    
    if (matches('updated') || matches('failed') || matches('deleted')) setAlert(true)
    else setAlert(false)

    if (matches('login')) history.push('/login')

  }, [value])

  // ComponentDidUnMount para indicar a la maquina volver al estado inicial
  useEffect(() => () => {
    send({ type: 'READY' })
  }, [])

  // Hook para validation
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: context.data ? context.data.email : '',
      key: context.data ? context.data.api_key : '',
      secret: context.data ? context.data.secret_key : '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("loginEmailValidError").required("loginEmailRequiredError"),
      key: Yup.string().required('streamingZoomTokenIDError').trim('streamingZoomTokenIDError'),
      secret: Yup.string().required('streamingZoomSecretError').trim('streamingZoomSecretError')
    }),
    onSubmit: values => {
      
      const data : ZoomCredentialsBody = {
        email: values.email,
        api_key: values.key,
        secret_key: values.secret
      }

      send({
        type: 'UPDATE',
        data
      })

    }
  })

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (value) {
      case 'updated':
      case 'deleted':
        return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (value) {
      case 'updated': return getTranslationByKey('streamingZoomUpdatedSuccessfully')
      case 'deleted': return getTranslationByKey('streamingZoomDeletedSuccessfully')
      case 'failure': return getTranslationByKey('generalError')
      default: return ''
    }
  }

  const deleteCredentials = () : void => {
    // Alerta para confirmar la eliminación
    Swal.fire({
      title: getTranslationByKey('streamingZoomDeletedSConfirmation'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#b7b7b7',
      confirmButtonColor: '#e42823',
      confirmButtonText: getTranslationByKey('delete'),
      cancelButtonText: getTranslationByKey('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        // Enviamos la petición al actor
        send({
          type: 'DELETE',
        })
      }
    })
  }

  const handleClose = () : void => {
    setAlert(false)

    setTimeout(() => {
      send({ type: 'READY' })
    }, 120)

  }

  const renderDeleteButton = () : ReactElement => {
    
    if (!context.data) return <></>

    if (matches('deleting')) return ( <CircularProgress color='secondary'/> )

    return (
      <button
        type = "button"
        disabled = { !matches('idle') && !matches('failure')}
        className = {`uppercase bg-title px-8 py-2 rounded-3xl w-full mt-8 md:mt-0 md:w-max ${matches('idle') ? 'opacity-100' : 'opacity-50'}`}
        onClick={ deleteCredentials }
      >
        { getTranslationByKey(`streamingZoomDeleted`) }
      </button>
    )

  }

  return ( 

    <>

      <Snackbar
        open = { alert }
        autoHideDuration = { 2000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { handleClose }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { handleClose }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <form 
        className='lg:min-h-30 xl:min-h-40'
        onSubmit={ formik.handleSubmit }
      >

        <InputForm 
          icon = { <PersonIcon /> }
          type = 'email'
          placeholder =  { getTranslationByKey('loginEmailPlaceHolder') }
          id = 'email' 
          inputMode='email'
          title={ getTranslationByKey('streamingZoomAccount') } 
          value={ formik.values.email } 
          handleChange={ formik.handleChange } 
          handleBlur={ formik.handleBlur  }
          isError={(formik.touched.email && !!formik.errors.email )} 
          errorText={ formik.errors.email && 
            getTranslationByKey(formik.errors.email.toString()) 
          }       
        />

        <InputChangePasswordForm
          notLock 
          leftIcon = { <KeyIcon /> }
          placeholder =  { getTranslationByKey('streamingZoomTokenIDPlaceHolder') }
          id = 'key' 
          title={ getTranslationByKey('streamingZoomTokenID') } 
          value={ formik.values.key } 
          handleChange={ formik.handleChange } 
          handleBlur={ formik.handleBlur  }   
          isError={(formik.touched.key && !!formik.errors.key )} 
          errorText={ formik.errors.key && 
            getTranslationByKey(formik.errors.key.toString()) 
          }      
        />

        <InputChangePasswordForm
          notLock
          leftIcon = { <TokenIcon /> }
          placeholder =  { getTranslationByKey('streamingZoomSecretPlaceHolder') }
          id = 'secret' 
          title={ getTranslationByKey('streamingZoomSecret') } 
          value={ formik.values.secret } 
          handleChange={ formik.handleChange } 
          handleBlur={ formik.handleBlur  }   
          isError={(formik.touched.secret && !!formik.errors.secret )} 
          errorText={ formik.errors.secret && 
            getTranslationByKey(formik.errors.secret.toString()) 
          }       
        />

        <div className="flex flex-col md:flex-row items-center justify-around mt-4 md:mt-10">

          { renderDeleteButton() }

          { matches('updating') 
            ? (
              <CircularProgress />
            )
            : (
              <button
                type = "submit"
                disabled = { !matches('idle') && !matches('failure')}
                className = {`uppercase bg-primary px-8 py-2 rounded-3xl w-full mt-8 md:mt-0 md:w-max ${matches('idle') ? 'opacity-100' : 'opacity-50'}`}
              >
                { getTranslationByKey(`save`) }
              </button>
            )
          }

        </div>

      </form>
    </>
  );
}
 
export default CenterStreaming;