import React, { FC, ReactElement } from 'react'

// Components
import { RButtonIconTooltip } from '../../utils/RookButtons';
import { RRoundedContainer } from '../../utils/RookLayout';

interface ChallengeActionProps {
  color: string,
  title: string,
  children: ReactElement,
  onClick: () => void,
}
 
const ChallengeAction: FC<ChallengeActionProps> = ({ color, title, onClick, children }) => ( 
    <RRoundedContainer
      className={ color }
    >
      <RButtonIconTooltip
        title = { title }
        onClick = { onClick }
      >
        { children }
      </RButtonIconTooltip>
    </RRoundedContainer>
  )
 
export default ChallengeAction;