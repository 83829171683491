import { createMachine, assign, DoneInvokeEvent } from "xstate";
import * as Sentry from '@sentry/react'

// Types
import { AuthData, CenterData, LocalStorageKey, Room } from '../../types'

// HTTP Client
import axiosClient from '../../config/axios'

/**
 * uuid: del room
 */
type Image = {
  image: File, 
  uuid: string
}

type UpdatedImage = {
  uuid: string,
  url: string,
  key: string
}

/**
 * branchID: id de la cadena
 * daqs: lista de daqs
 * daq: info del daq a actualizar
 * image: imagen a subir
 * statusError: error de la api
 * openModal: abrir modal para editar el nombre
 */
type DaqMachineContext = {
  branchID: string,
  daqs: Room[],
  daq: Room | null,
  image: Image | null,
  statusError: number,
  openModal: boolean
}

/**
 * CURRENT: Guardar el último daq utilizado
 * LOGO: Actualizar el logo del daq
 * BACKGROUND: Actualizar el background del daq
 * FOCUS: Indicar que daq se va a actualizar
 * UPDATE: actualizar el nombre del daq
 * TOGGLE: Abrir o cerrar el modal
 */
type DaqMachineEvents = 
  | { type: 'CURRENT', data: Room }
  | { type: 'LOGO', data: Image }
  | { type: 'BACKGROUND', data: Image }
  | { type: 'FOCUS', data: Room }
  | { type: 'UPDATE', data: string }
  | { type: 'TOGGLE' }

/**
 * Buscar los daqs que estan disponibles
 * @returns información del centro
 */
const fetchDaqs = async () : Promise<CenterData> => {
  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const response = await axiosClient.get(`/api/v2/centers/${authData.uuidBranch}`)

  return response.data
}

/**
 * Actualizar logo del daq
 * @param context de la maquina
 * @returns la imagen de logo actualizada
 */
const updateLogo = async (context: DaqMachineContext) : Promise<UpdatedImage> => {
  
  // Sacamos la imagen del contexto
  const { image }  = context

  if (!image) throw new Error()

  // Creamos un FormData
  const data = new FormData()
  data.append('center_image', image.image)

  // const reponse = await axiosClient.post(`/api/v2/room/${image.uuid}/logo`, data)
  const response = await axiosClient.post(`/api/v2/room/${image.uuid}/logo`, data)

  return {
    key: 'logo_image',
    uuid: image.uuid,
    url: response.data.url
  }
}

/**
 * Actualizar el background del daq
 * @param context de la maquina
 * @returns la imagen de background actualizada
 */
const updateBackground = async (context: DaqMachineContext) : Promise<UpdatedImage> => {
  
  // Sacamos la imagen del contexto
  const { image }  = context

  if (!image) throw new Error()

  // Creamos un FormData
  const data = new FormData()
  data.append('center_image', image.image)

  const response = await axiosClient.post(`/api/v2/room/${image.uuid}/background`, data)

  return {
    key: 'background_image',
    uuid: image.uuid,
    url:  response.data.url
  }

}

/**
 * Actualizar el nombre de un daq
 * @param context de la maquina principal
 * @returns un room actualizado
 */
const updateDaq = async (context: DaqMachineContext) : Promise<Room> => {
  
  // Sacamos el daq a actualizar
  const { daq, branchID } = context

  if (!daq) throw new Error()

  const bridge = {
    'branch_uuid': branchID,
    'room_name': daq.name
  }

  await axiosClient.put(`/api/v2/room/${daq.uuid}`, bridge)

  return daq
}

const DaqMachine = createMachine<DaqMachineContext, DaqMachineEvents>(
  {
    id: 'DaqMachine',
    initial: 'loading',
    context: {
      branchID: '',
      daqs: [],
      daq: null,
      image: null,
      statusError: 0,
      openModal: false,
    },
    states: {
      loaded: {},
      login: {},
      updated: {},
      changed: {},
      refreshed: {},
      loading: {
        invoke: {
          id: 'fetchDaqs',
          src: fetchDaqs,
          onDone: {
            target: 'loaded',
            actions: [ 'prepareData' ]
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateLogo',
          src: updateLogo,
          onDone: {
            target: 'updated',
            actions: [ 'updateDaq' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar el logo del daq',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar el logo del daq',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar el logo del daq, por falta de data',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      changing: {
        invoke: {
          id: 'updateBackground',
          src: updateBackground,
          onDone: {
            target: 'updated',
            actions: [ 'updateDaq' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const message : string = 'No se pudo actualizar el background del daq'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message,
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${message}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      refreshing: {
        invoke: {
          id: 'updateDaq',
          src: updateDaq,
          onDone: {
            target: 'refreshed',
            actions: [ 'refreshDaqs' ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              const message : string = 'No se pudo actualizar el nombre del daq'

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message,
                  level: Sentry.Severity.Error,
                  data: event.data
                })
                
                Sentry.addBreadcrumb({
                  category: 'info',
                  message,
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: `${message}, por falta de data`,
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      CURRENT: {
        actions: [ 'saveCurrentDaq' ]
      },
      LOGO: {
        target: '.updating',
        actions: [ 'chargeImage' ]
      },
      BACKGROUND: {
        target: '.changing',
        actions: [ 'chargeImage' ]
      },
      TOGGLE: {
        target: '.loaded',
        actions: assign({ openModal: (context) => !context.openModal })
      },
      FOCUS: {
        target: '.loaded',
        actions: assign({ 
          daq: (_, event) => event.data,
          openModal: (context) => !context.openModal
        })
      },
      UPDATE: {
        target: '.refreshing',
        actions: assign({ daq: (context, event) => ({ ...context.daq!, name: event.data })})
      }
    }
  },
  {
    actions: {
      prepareData: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<CenterData> = _event

        if (event.type !== 'done.invoke.fetchDaqs') 
          return { ...context }

        const daqs : Room[] = event.data.rooms.filter((room: Room) => room.type_room === 'daq')

        return {
          ...context,
          daqs,
          branchID: event.data.branch_uuid
        }

      }),
      saveCurrentDaq: assign((context, event) => {

        if (event.type !== 'CURRENT') return { ...context }

        // Guardamos en el localStorage 
        localStorage.setItem(LocalStorageKey.rookLegendRecent, JSON.stringify(event.data))

        return {
          ...context
        }

      }),
      chargeImage: assign((context, event) => {

        if (event.type !== 'LOGO' && event.type !== 'BACKGROUND' ) return {...context}

        return {
          ...context,
          image: event.data
        }

      }),
      updateDaq: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<UpdatedImage> = _event

        if (
          event.type !== 'done.invoke.updateLogo' && 
          event.type !== 'done.invoke.updateBackground'
        ) 
          return { ...context }

        // Actualizamos la lista de daqs
        const newDaqs = context.daqs.map((daq: Room) => {

          if (daq.uuid === event.data.uuid){
            return {
              ...daq,
              [event.data.key]: event.data.url
            }
          }

          return daq

        })

        // Sacamos el daq guardado
        const previousDaq : string | null  = localStorage.getItem(LocalStorageKey.rookLegendRecent)
        const daq : Room | null = JSON.parse(previousDaq || 'null')

        if (daq && daq.uuid === event.data.uuid) {

          if (event.data.key === 'logo_image')
            daq.logo_image = event.data.url

          else daq.background_image = event.data.url

          // Guardamos en el localStorage 
          localStorage.setItem(LocalStorageKey.rookLegendRecent, JSON.stringify(daq))          
        }

        return { 
          ...context,
          daqs: newDaqs
        }

      }),
      refreshDaqs: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Room> = _event

        if ( event.type !== 'done.invoke.updateDaq' ) 
          return { ...context }

        const newDaqs : Room[] = context.daqs.map(
          (room: Room) => room.uuid === event.data.uuid ? event.data : room
        )

        return {
          ...context,
          daqs: newDaqs,
          openModal: !context.openModal
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

export default DaqMachine