import styled from 'styled-components';

// Colors
import Colors  from '../../../utils/colors';

type BottomLayerProps = {
  isFloated: boolean
}

export const BottomLayer = styled.div<BottomLayerProps>`
  height: 2rem;
  position: ${ props => props.isFloated ? 'fixed' : 'relative' };
  width: ${props => props.isFloated ? '90%': '100%'};
  margin: 0.6rem 0 1rem 0;
  border-radius: 0.75rem;
  background-color: #f5f5f5;
  -webkit-box-shadow: ${ props => props.isFloated ? '2px 4px 12px 0px rgba(0,0,0,0.75)' : 'none'};
  -moz-box-shadow: ${ props => props.isFloated ? '2px 4px 12px 0px rgba(0,0,0,0.75)' : 'none'};
  box-shadow: ${ props => props.isFloated ? '2px 4px 12px 0px rgba(0,0,0,0.75)' : 'none'};
  transition: all 0.45s ease-in-out;
  z-index: ${props => props.isFloated ? '999999': 'auto'};
  top: 0;
  left: ${props => props.isFloated ? '5%': '0%'};
`;

export const TopLayer = styled.div`
  height: 2rem;
  border-radius: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background-color: ${Colors.eMax};
  color: white;
  padding: 0.2rem 1rem;
  font-weight: bold;
`;