import { makeStyles } from '@mui/styles';

// Estilos para el check para de recordar sesión
export const useStyles = makeStyles({
  paper: {
    background: "#303030",
    borderRadius: 8,
    color: "white",
    '& #simple-dialog-title': {
      background: "#424242"
    }
  }
})

// Stilos del select
export const inputStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    color: 'white',
    borderRadius: '0.75rem!important',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0.75rem!important',
        borderColor: '#424242'
      },
      '& svg':{
        color: 'white'
      }
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '#424242'
    },
  },
});

// Menu del select
export const menuStyles = makeStyles({
  menuPaper: {
    backgroundColor: '#424242',
    color: "white"
  }
})

// estilos del datepicker
export const datePickerStyles = makeStyles({
  root: {
    background: '#424242',
    borderColor: 'none',
    borderRadius: '0.75rem!important',
    padding: '0rem 1rem',
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd button": {
      color: 'white'
    },
    '& #date-picker-inline': {
      color: 'white',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        color: 'white'
      }
    },
   '& fieldset':{
      borderColor: 'rgba(255, 255, 255, 0)'
   }
  },
});