import React, { FC } from 'react';

// Icons
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsIcon from '@mui/icons-material/Settings';

// Components
import InfoList from './InfoList';
import RButtonBack from '../utils/RookButtons/RButtonBack';

// Modelos
import { Word } from '../../types';
import Texts from '../../models/Texts';

// Hooks
import useTranslation from '../../hooks/useTranslation';

type WelcomeProps = {
  handleClick: () => void
  handleConfigure: () => void
  handleQR: () => void
}

/**
 * Componente de bienvenida
 * @param props contiene todas las propiedades necesarias descritas en WelcomeProps
 * @param props.handleConfigure función para abrir el modal de configuración
 * @param props.handleClick función para regresar a la vista anterior
 * @returns componente que muestra el botón de configurar clase y las zonas de intensidad
 * a manera de informativo
 */
const Welcome: FC<WelcomeProps> = ({ handleConfigure, handleClick, handleQR }) => {

  // Hooks para traducir
  const { getTranslation } = useTranslation();

  return (
    <>

      <div className="w-max">
        <RButtonBack
          action = { handleClick }
        />
      </div>

      <div className = "">

        <div className = "flex justify-center">
          <div className="w-1/2">
            <img
              src="/images/logo.png"
              alt="logo"
            />
          </div>
        </div>

        <h1 className = "text-center my-4 2xl:my-8 text-2xl xl:text-4xl">
          { getTranslation(Texts.wTitle as Word) }
        </h1>

        <p className = "text-center mb-8 text-lg">
          { getTranslation(Texts.wDescription as Word) }
        </p>

      </div>

      <InfoList />

      <p className = "text-center mt-8 text-lg">
        { getTranslation(Texts.wConfiguration as Word) }
      </p>

      <div className = "my-8 flex flex-col md:flex-row justify-center">
        <button
          type = "button"
          className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-4 lg:mt-0 w-full md:w-max flex items-center justify-center md:mr-4"
          onClick = { handleQR }
        >
          <span className='block mr-2'>
            { getTranslation( Texts.wConfigurationQR as Word ) }
          </span> 
          <QrCodeScannerIcon/>
        </button>
        <button
          type = "button"
          className = "uppercase bg-title px-8 py-2 rounded-3xl mt-4 lg:mt-0 w-full md:w-max flex items-center justify-center"
          onClick = { handleConfigure }
        >
          <span className='block mr-2'>
            { getTranslation( Texts.wConfigureButton as Word ) }
          </span> 
          <SettingsIcon/>
        </button>
      </div>

    </>
  );
};
export default Welcome;
