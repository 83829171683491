import React, { FC, useState, useEffect, useContext, useRef } from 'react'

// Dependecies
import styled from 'styled-components'
import Menu from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';
import { useHistory, Link } from 'react-router-dom'
import { useMachine } from '@xstate/react';
import { Snackbar, Alert } from '@mui/material';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import VillaIcon from '@mui/icons-material/Villa';

// Machine
import BarMachine from '../../context/machines/BarMachine'

// Context
import UIContext from '../../context/ui/UIContext'

// Hooks
import useTranslation from '../../hooks/useTranslation'
import Texts from '../../models/Texts';
import { Word } from '../../types';

type ProfileImageProps = {
  image?: string | null
}

const ProfileImage = styled.div<ProfileImageProps>`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-image: ${props => props.image ? `url(${props.image})` : "url('/images/avatar.png')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-box-shadow: 0px 0px 82px 13px rgba(0,0,0,0.72);
  -moz-box-shadow: 0px 0px 82px 13px rgba(0,0,0,0.72);
  box-shadow: 0px 0px 82px 13px rgba(0,0,0,0.72);

  &:hover {
    cursor: pointer;
  }
`
// Estilos para el check para de recordar sesión
const useStyles = makeStyles({
  paper: {
    background: "#212121",
    color: "white",
    borderRadius: 8
  }
})

interface BarProps {
  openNavigationInDrawer: () => void,
}
 
/**
 * Barra de navegación que contiene el menú de hamburguesa y el icono del perfil 
 * @param {Object} props contiene todas las propiedades necesarias descritas en BarProps
 * @param {function} props.openNavigationInDrawer función para abrir la navegación
 * @returns barra superior
 */
const Bar: FC<BarProps> = ({ openNavigationInDrawer }) => {

  // Saber a donde se va a agregar el menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Observer
  const [observer, setObserver] = useState<IntersectionObserver | null>(null)

  // Machine para cerrar sesión
  const [machine, send] = useMachine(BarMachine)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Referencia de la barra 
  const bar = useRef<any>(null)
  const anchor = useRef<any>(null)

  // Estilos del menu
  const styles = useStyles()

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Context para indicar que ya noestamos autenticados
  const { currentUser, permissions, setAuthenticated } = useContext( UIContext )

  // Routing para cerrar sesión
  const history = useHistory()

  // Iniciamos el observer
  useEffect(() => {
    
    if('IntersectionObserver' in window && !observer){ 
      
      const bridge = new IntersectionObserver((entries: any) => {
        
        if( !entries[0].isIntersecting ) {

          const screenWide = window.matchMedia('(max-width: 1279px)')
          if (screenWide.matches && bar.current) {
            
            bar.current.classList.add("floated");
            
          }
          else if (bar.current) {
              const profile = bar.current.querySelector('#btn-profile')
              profile.classList.add('floated-profile')
            }

        }
        else if (bar.current) {
            bar.current.classList.remove("floated");

            const profile = bar.current.querySelector('#btn-profile')
            profile.classList.remove('floated-profile')
          }
      })
      
      // Se inicializa el observer
      if (anchor.current)
        bridge.observe( anchor.current )

      setObserver(bridge)

    }

  }, [])

  // ComponendWillUnMount Desconectar el observer
  useEffect(() => () => {
      if (observer){
        observer.disconnect()
      } 
    }, [])

  // useEffect para saber cuando enviar al login
  useEffect(() => {
    
    if (machine.matches("error")) setShowAlert(true)
    else setShowAlert(false)

    if (machine.done) {
      setAuthenticated(false)
      history.push("/login")
    }

  }, [machine])

  // Indicamos que elemento será el que contendrá el menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Para cerrar el menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Función para cerrar sesión
  const handleLogOut = async () : Promise<any> => {
    send({
      type: 'LOGOUT'
    })
  }

  return ( 

    <>

    <div className="flex justify-between w-full bg-background mb-8 lg:mb-1">
      
      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = "error"
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getTranslation( Texts.generalError as Word ) }
        </Alert>
      </Snackbar>

      <div 
        className = "w-full transition-all"
        ref = { bar }
      >
        <div 
          className = "flex justify-between items-center"
        >

          <button 
            id = 'btn-hamburger'
            type = "button" 
            onClick = { () => openNavigationInDrawer()}
          >
            <svg xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 visible xl:invisible" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          <button 
            id = 'btn-profile'
            type = "button" 
            onClick = {handleClick}
            className = 'pb-5 lg:mb-0 transition-all'
          >
            <ProfileImage
              image = { currentUser?.image_url || null }
            >
              <div
                className="rounded-full h-3 w-3 flex items-center justify-center bg-green-500 absolute bottom-0 right-0"
              />
            </ProfileImage>
          </button>

          <Menu
            id = "profile-menu"
            anchorEl = { anchorEl }
            keepMounted
            open = { Boolean(anchorEl) }
            classes = { styles }
            onClose = { handleClose }
          >
            <div className = "pt-5 w-max">

              <li className = "hover:text-title">

                <Link to = "/profile">
                  <button type="button" className = "flex items-center px-8">
                    <PersonIcon />
                    <p className = "ml-4">
                      { getTranslation( Texts.profileMenuProfile as Word ) }
                    </p>
                  </button>
                </Link>

              </li>

              { permissions.includes('center_information_show') && (
                <li className = "hover:text-title">

                  <Link to = '/center'>
                    <button type="button" className = "flex items-center px-8 mt-4">
                      <VillaIcon />
                      <p className = "ml-4">
                        { getTranslation( Texts.profileMenuCenterInfo as Word ) }
                      </p>
                    </button>
                  </Link>

                </li>
              )}


              <li className = "mt-4 border-t border-gray-450 hover:text-title">

                <button 
                  type = "button" 
                  className = "flex items-center px-8 py-3"
                  onClick = { handleLogOut }
                  disabled = { machine.matches('loading') }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                  <p className = "ml-4">
                    { getTranslation( Texts.profileMenuLogOut as Word ) }
                  </p>
                </button>

              </li>

            </div>
          </Menu>

        </div>
      </div>


    </div>
    
      <div 
        ref = { anchor }
        className="w-0 h-0 bg-red-400"
      />
    </>

  );
}
 
export default Bar;