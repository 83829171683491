import React, { FC } from 'react';

// Icons
import FavoriteIcon from '@mui/icons-material/Favorite';
import WhatshotIcon from '@mui/icons-material/Whatshot';

// Types
import { Word } from '../../types';

// Models
import Ranges from '../../models/Ranges';
import Texts from '../../models/Texts';

// Components
import Info from './Info';

// Hooks
import useTranslation from '../../hooks/useTranslation';

// Styles
import { Square } from './styles/InfoStyles'

// Sacamos las bullets para la explicación del cluster, se puso aquí para evitar re-renders
const bullets = Texts.wBullets as Word[];

/**
 * Tarjetas con la datos a manera de info
 * @returns un flex con las tarjetas con las zonas de intensidad y el explicativo del cluster
 */
const InfoList: FC = () => {

  // Hook para traducir
  const { getTranslation } = useTranslation();

  return (

    <div className="flex flex-col items-center items-normal lg:grid lg:grid-cols-1 lg:grid-cols-2 lg:gap-2 lg:w-3/4 2xl:w-3/5 mx-auto lg:pl-10 2xl:pl-16">

      <Info
        title = { getTranslation(Texts.wZones as Word) }
      >

        {Ranges.map((range) => (
          <div
            key = { range.color }
            className="flex items-center px-5 py-2"
          >

            <Square
              color = { range.color }
            />

            <p className = "mb-0">
              { range.limitBottom }% - { range.limitTop }% ( {getTranslation(range.label)} )
            </p>

          </div>
        ))}

      </Info>

      <Info
        title = { getTranslation(Texts.wExaple as Word) }
        paddingX = { 3 }
      >

        <div className="flex items-center justify-around pt-8">

          <div className = "">
            <ul>
              {bullets.map((text: Word) => (
                  <li
                      key = { text.label }
                  >
                      { getTranslation(text) }
                  </li>

              ))}
            </ul>
          </div>

          <div>

          <h1 
            className = "text-center text-2xl xl:text-4xl mb-2 md:mb-8"
          >
            91%
          </h1>

          <div className='grid grid-cols-2 lg:grid-cols-3 gap-2'>

            <div>
              <FavoriteIcon />
              <p>129</p>
            </div>

            <div>
              <WhatshotIcon />
              <p>619</p>
            </div>

              <div>

                <div
                  style = {{ maxWidth: '1.45rem' }}
                >
                  <img
                    src="/icons/boots.png"
                    alt="icono"
                  />
                </div>

                <p>1967</p>

              </div>

              </div>

            </div>

          </div>

        </Info>

      </div>

  );
};
export default InfoList;
