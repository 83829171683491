/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import { Dialog, DialogProps } from '@mui/material';

interface RModalProps extends DialogProps {
  open: boolean
  onClose: () => void,
}
 
const RModal: FC<RModalProps> = ({ children, ...props}) => ( 
    <Dialog
      { ...props }
    >
      { children }
    </Dialog>
  )
 
export default RModal;