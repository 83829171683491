import React, { FC, ChangeEvent } from 'react'

// Dependencies
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// Types
import { Clasification, PageSizeOption, OptionSearchBar, SearchIn, Sorting } from '../../types';

// Componentes
import Container from '../layout/Container'
import SearchBar from '../utils/SearchBar'

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Styles
import { inputStyles, menuStyles } from './ReportsMUIStyles'
import RankingList from './RankingList';

interface RankingProps {
  pages: number,
  sorting: Sorting,
  currentPage: number,
  sizes: PageSizeOption[],
  pageSize: PageSizeOption,
  clasification: Clasification[],
  sort: (key: string) => void,
  handlePageChange: (page: number) => void
  handleSearch: (search: SearchIn) => void,
  handlePageSizeChange: (size: PageSizeOption) => void,
}

// Opciones para el buscador
const internalOptions: OptionSearchBar[] = [
  {
    key: "user",
    title: "user"
  }
]

/**
 * Lista de usuarios a manera de ranking
 * @param props contiene todas las propiedades necesarias descritas en RankingProps
 * @param props.pageSize cantidad de usuarios a mostrar por página
 * @param props.sizes opciones para el tamaño de página
 * @param props.clasification lista de usuarios o el ranking
 * @param props.pages el número de páginas
 * @param props.currentPage página que esta viendo el usuario
 * @param props.sorting saber si hay alguna columna a la que se le dio clic y saber en que 
 * flujo esta si ascendente o descendente
 * @param props.handlePageSizeChange función para cambiar el tamaño de la página
 * @param props.handlePageChange función para cambiar de página
 * @param props.handleSearch función para buscar
 * @param props.sort función para ordenar la data de acuerdo a la columna
 * @returns ranking de usuarios
 */
const Ranking: FC<RankingProps> = ({ 
  pageSize, sizes, clasification, pages, currentPage, sorting, 
  handlePageSizeChange, handlePageChange, handleSearch, sort 
}) => {

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  // Estilos
  const styles = inputStyles()
  const menuStyle = menuStyles()

  const handleChange = (event: SelectChangeEvent) => {
    const option = event.target.value as string
    handlePageSizeChange({ value: Number(option), key:  option })
  };

  // Manejar el cambio de página
  const onChangePage = (event: ChangeEvent<unknown>, value: number) => {
    handlePageChange(value)
  };

  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => handleSearch(search)

  return ( 
  <div className="mt-10">
    <Container
      title = { getTranslationByKey('reportsUsersRanking') }
      uppercase
    >
      
      <div className="flex flex-col md:flex-row justify-between items-center">

        <div className = "flex items-center mb-8 md:mb-0">
          <label
            htmlFor="number"
            className = "mr-4 text-sm text-gray-450"
          >
            { getTranslationByKey('show') }
          </label>

          <FormControl
            variant = 'outlined'
            classes = { styles }
          >
            <Select
              labelId = "type"
              id = "number"
              name = "number"
              MenuProps = {{ classes: { paper: menuStyle.menuPaper } }}
              value = { pageSize.key }
              onChange = { handleChange }
              sx = {{
                background: '#424242',
                borderColor: 'none',
                color: 'white',
                borderRadius: '0.75rem!important',
                outline: '2px solid transparent',
                outlineOffset: '2px',
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '0.75rem!important',
                    borderColor: '#424242'
                  },
                  '& svg':{
                    color: 'white'
                  }
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '#424242'
                },
              }}
            >
              
              {sizes.map((option) => (
                <MenuItem 
                  key = { option.key }
                  value = { option.key }
                >
                  { option.value }
                </MenuItem>
              ))}

            </Select>
          </FormControl>

          <p
            className = "ml-4 text-sm text-gray-450"
          >
            { getTranslationByKey('users') }
          </p>
        </div>

        <SearchBar 
          options = { internalOptions }
          action={ searchIn } 
        />

      </div>

      <RankingList 
        clasification = { clasification }
        pageSize = { pageSize.value }
        currentPage = { currentPage }
        sort = { sort }
        sorting = { sorting }
      />

      { pages > 1 && (
        <div className="flex justify-center mt-12 mb-4">
          <Pagination
            showFirstButton
            showLastButton
            variant = 'text'
            color = 'secondary'
            count = { pages }
            page = { currentPage }
            onChange = { onChangePage }
          />
        </div>
      )}
      
    </Container>
  </div>
  )
}
 
export default Ranking;