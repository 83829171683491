import React, { ChangeEvent, FC, useState, useEffect, useContext } from 'react'

// Dependencies
import moment from 'moment';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom'
import { Snackbar, Alert } from '@mui/material';
import Pagination from '@mui/material/Pagination';

// Icons
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

// Types and models
import { Word, Athlete, FullAthlete, Gender, SearchIn } from "../types"
import Texts from '../models/Texts';

// Context
import UIContext from '../context/ui/UIContext'

// Machine
import UserMachine from '../context/machines/UserMachine'

// Components
import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import UserModal from '../components/users/UserModal';
import Spinner from '../components/utils/Spinner'
import SearchBar from '../components/utils/SearchBar'
import UsersList from '../components/users/UsersList';
import UserMetrics from '../components/users/UserMetrics';
import Support from '../components/utils/Support';

// Hooks
import useTranslation from '../hooks/useTranslation'

const empty : FullAthlete = {
  name: "",
  email: "",
  pseudonym: "",
  birthday: moment().format('YYYY-MM-DD'),
  sex: Gender.choose,
  'user_uuid' : "",
  'last_name_1': "",
  'last_name_2': "",
  'physiological_variables': {
    weight: 31,
    height: 121,
    'resting_heart_rate': 61,
  }
}

const Users: FC = () => {

  // Automata para contrar esta vista
  const [machine, send] = useMachine(UserMachine)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Saber si estamos en tiempo de carga
  const [loading, setLoading] = useState(false)

  // Saber si ya pasamos el sistema de medidas
  const [counter, setCounter] = useState(0)

  // Context para sacar el sistema de medidas
  const { metricSystem, permissions, lang } = useContext( UIContext )

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Hook para la navegación
  const history = useHistory()

  // Actualizar las variables
  useEffect(() => {
    
    if (machine.matches("updated") || machine.matches("failure")) setShowAlert(true)
    else setShowAlert(false)

    if (machine.matches('login')) history.push('/login')

    if (machine.matches('loaded') && counter === 0) {
      setCounter(1)
      send({ type: 'SYSTEM', data: metricSystem })
      send({ type: 'LANG', data: lang })
    }

    if(
      machine.matches('loading') 
      || machine.matches('searching') 
      || machine.matches('lookingFor')
    ) setLoading(true)

    else setLoading(false)

  }, [machine.value])

  // Manejar el cambio de página
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    send({
      type: 'PAGEING',
      page: value
    })
  };

  /**
   * Agregar un nuevo usuario
   * @param user el nuevo usuario a agregar
   */
  const saveUser = (user: Athlete) : void => {
    send({
      type: 'ADDUSER',
      data: user
    })
  }

  /**
   * Mostrar un texto en caso de error
   * @returns el texto a mostrar en caso de error
   */
  const getError = () : string => {
    switch (machine.context.statusError) {
      case 409:
        return getTranslation( Texts.userAlredyLinked as Word )
      case 0:
        return getTranslation( Texts.userUpdatedorCreatedSuccessfully as Word ) 
      case 111:
        return getTranslation( Texts.generalError as Word )
      default:
        return getTranslation( Texts.userPartialUpdatedOrCreated as Word )
    }
  }
  
  /**
   * Decirle al automata que buscar
   * @param search el objeto a buscar en las solicitudes
   */
  const searchIn = (search: SearchIn) : void => {
    send({
      type: 'SEARCH',
      data: search
    })
  }

  return ( 
    <Layout>
      <Spinner
        show = { loading }
      >
        <Container
          title = { machine.matches('graphics')
            ? getTranslation( Texts.userMetricsTitle as Word )
            : getTranslation( Texts.navMenuUsers as Word ) 
          }
          icon = { <DirectionsRunIcon /> }
          rightButton = { 
            <Support
              action='users'
            /> 
          }
        >

        <Snackbar
          open = { showAlert }
          autoHideDuration = { 6000 }
          anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
          onClose = { () => setShowAlert( false ) }
        >
          <Alert
            severity = { machine.matches('failure') ? 'warning' : 'success' }
            variant = "filled"
            onClose = { () => setShowAlert( false ) }
          >
            { getError() }
          </Alert>
        </Snackbar>

          <div 
            className={`${!machine.matches('graphics')  ? 'flex': 'hidden'} flex-col-reverse md:flex-row justify-between items-center`}
          >
    
            <button
              type = "button"
              className = {`uppercase bg-primary px-8 py-2 rounded-3xl mt-8 md:mt-0 w-full md:w-max ${!permissions.includes('user_create') && 'invisible'}`}
              onClick = { () => send({ type: 'TOGGLE' }) }
            >
              { getTranslation( Texts.usersAdd as Word ) }
            </button>
    
            <SearchBar 
              // options={ options } 
              action={ searchIn }
            />

          </div>

          { !machine.matches('graphics') && (
            <>
              { (machine.context.page && !loading) && (
                <UsersList 
                  service = { machine.context.page }
                  isEditable = { permissions.includes('user_edit') }
                  isRemovable = { permissions.includes('user_delete') }
                  showMetrics = { permissions.includes('user_metrics') }
                />
              )}

              { ( machine.context.pages > 1) && (
                <div className="flex justify-center mt-12 mb-4">
                  <Pagination 
                    showFirstButton 
                    showLastButton
                    page = { machine.context.currentPage }
                    variant="text" 
                    color = "secondary"
                    count = { machine.context.pages } 
                    onChange = { handleChange }
                  />
                </div>
              )}

              { machine.context.page && (
                <UserModal
                  value = { machine.value }
                  info = { machine.context.info || empty }
                  openModal = { machine.context.openModal }
                  addUser = { saveUser }
                  setOpenModal = { () => send({ type: 'TOGGLE' }) }
                />
              )}
            </>
          )}

          { machine.matches('graphics') && (

            <UserMetrics 
              service = { machine.context.metrics  }
            />

          )}


        </Container>
      </Spinner>
    </Layout>
  )
}
 
export default Users;