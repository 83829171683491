// UI Context
export const SET_LANG: string = "SET_LANG";
export const SET_AUTHENTICATED: string = "SET_AUTHENTICATED";
export const SET_DASHBOARD_DATA: string = "SET_DASHBOARD_DATA";
export const SET_CURRENT_USER: string = "SET_CURRENT_USER";
export const SET_TRUNK: string = "SET_TRUNK";
export const SET_CENTER_IMAGE: string = "SET_CENTER_IMAGE";
export const SET_METRICS_SYSTEM: string = "SET_METRICS_SYSTEM";
export const SET_PERMISSIONS: string = "SET_PERMISSIONS";
export const SET_CONTRACT_ALREADY_SHOWN: string = "SET_CONTRACT_ALREADY_SHOWN";
export const SET_HOSTNAME: string = "SET_HOSTNAME";
export const SET_BRANCH_UUID: string = "SET_BRANCH_UUID";
export const SET_FEATURES_ENABLED: string = "SET_FEATURES_ENABLED";
export const SET_CENTER_NAME: string = "SET_CENTER_NAME";
export const SET_CENTER_DATA: string = "SET_CENTER_DATA";
export const SET_IS_UNAVAILABLE: string = "SET_IS_UNAVAILABLE";
