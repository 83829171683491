import { createMachine, assign, spawn, DoneInvokeEvent, sendParent, send } from "xstate";
import * as Sentry from '@sentry/react'

// Types
import { 
  AuthData, 
  CenterData, 
  LocalStorageKey, 
  Location, 
  Multimedia, 
  ZoomCredentials, 
  ZoomCredentialsBody 
} from '../../types'

// HTTP Client
import axiosClient from '../../config/axios'

// utils
import { getComponent } from '../../utils'

type Config = {
  maps: any,
  geocoder: any,
  marker: any
}

type Coords = { 
  lat: number, 
  lng: number 
}

/**
 * Ready: Cuando la data del centro esta descargada se le avisa a la maquina padre que esta lista
 * IMAGE: Actualizar la imagen del centro
 * LOGIN: Cuando se marca error 401
 */
type CenterMachineEvents = 
  | { type: 'READY', data: CenterData }
  | { type: 'IMAGE', data: File }
  | { type: 'LOGIN' }

/**
 * actors: sub maquinas
 * data: data del centro
 * image: imagen a actualizar
 * statusError: error que da la api
 */
type CenterMachineContext = {
  actors: any | null,
  data: CenterData | null,
  image: File | null,
  statusError: number
}

/**
 * config: configuración de google maps
 * address: dirección del centro
 * coords: coordenadas del centro
 * statusError: error que da la api,
 * data: data del centro
 */
type AddressMachineContext = {
  config: Config | null,
  address: string | null,
  coords: Coords | null,
  statusError: number,
  data: CenterData | null
}

/**
 * INIT: se carga la configuración
 * TRANSLATE: de texto a coordenadas
 * DRAGGED: cuando se arrastra el pin del mapa
 * SYNC: Sincronizar la data con la maquina padre
 * UPDATE: Actualizar la dirección
 * READY: Volver al estado inicial
 */
type AddressMachineEvents = 
  | { type: 'INIT', data: Config }
  | { type: 'TRANSLATE', data: string }
  | { type: 'DRAGGED', data: Coords }
  | { type: 'SYNC', data: CenterData }
  | { type: 'UPDATE'}
  | { type: 'READY'}

/**
 * data: data del centro
 * statusError: error que da la api
 * center: es la data a actualizar
 */
type GenDataMachineContext = {
  data: CenterData | null,
  statusError: number,
  center: any | null
}

/**
 * UPDATE: actualizar la data del centro
 * READY: Volver al estado inicial
 */
type GenDataMachineEvents = 
  | { type: 'UPDATE', data: any }
  | { type: 'READY' }

/**
 * data: imágenes del centro
 * statusError: error que da la api
 * image: nueva imagen de la galeria
 * imageID: id de la imagen a borrar
 */
type GalleryMachineContext = {
  data: Multimedia[] | null,
  statusError: number,
  image: File | null,
  imageID: string | null,
}

/**
 * SYNC: cargar las imágenes del centro
 * IMAGE: subir una imagen
 * REMOVE: eliminar una imagen
 * READY: Volver al estado inicial
 */
type GalleryMachineEvents = 
  | { type: 'SYNC', data: Multimedia[] }
  | { type: 'IMAGE', data: File }
  | { type: 'REMOVE', data: string }
  | { type: 'READY' }


type StreamingMachineContext = {
  statusError: number,
  data: ZoomCredentials | null,
  credentials: ZoomCredentialsBody | null,
} 

type StreamingMachineEvents = 
  | { type: 'SYNC', data: ZoomCredentials | null }
  | { type: 'UPDATE', data: ZoomCredentialsBody }
  | { type: 'READY' }
  | { type: 'DELETE' }

/**
 * Traducir la dirección de letra a coordenadas
 * @param context de la maquina principal
 * @returns una url de la imagen
 */
const updateCenterLogo = async (context: CenterMachineContext) : Promise<string> => {
  
  // Sacamos la imagen
  const { image, data } = context

  if (!image || !data) throw new Error()

  // Creamos un FormData
  const body = new FormData()
  body.append('image_admin', image)

  const response = await axiosClient.post(`/api/v2/center/${data.branch_uuid}/image`, body)

  return response.data.url

}

/**
 * Traducir la dirección de letra a coordenadas
 * @param context de la maquina que controla el mapa
 * @returns Unas coordenadas
 */
const translateAddress = async (context: AddressMachineContext) : Promise<Coords> => {
  
  // Sacamos la dirección
  const { address, config } = context

  // Si no hay dirección o geocoder lanzamos el error
  if (!address || !config) throw new Error()

  const response = await config.geocoder.geocode({ address })

  if (response.results.length > 0 && response.results.length <= 3){
    return {
      lat: response.results[0].geometry.location.lat(),
      lng: response.results[0].geometry.location.lng()
    }
  }

  throw new Error()

}

/**
 * Retornar la data del centro
 * @returns la data del centro
 */
const fetchCenterData = async () : Promise<CenterData> => {
  
  // Sacamos el uuid del localStorage
  const rawAuth = localStorage.getItem( LocalStorageKey.auth )
  const authData : AuthData = JSON.parse( rawAuth || 'null' )

  // Si no existe mandamos un error
  if ( !authData ) throw new Error()

  const response = await axiosClient.get(`/api/v2/centers/${authData.uuidBranch}`)

  return response.data

}

/**
 * Actualizar la data del centro
 * @param context del actor encargado de esa vista
 * @returns la data acutalizada
 */
const updateCenterData = async (context: GenDataMachineContext) : Promise<any> => {
  
  // Sacamos la data nueva
  const { data, center } = context

  // Si no existe mandamos un error
  if ( !center || !data ) throw new Error()

  if (center.description === '')
    delete center.description

  const bridge = {
    ...center,
    chain_uuid: data.chain_uuid,
    offset_name: center.offset.offsetName,
    offset: center.offset.offset
  }

  await axiosClient.put(`/api/v2/branch/${data.branch_uuid}`, bridge)

  return bridge

}

/**
 * Actualizar la dirección del centro
 * @param context de la maquina que controla la dirección
 * @returns un objeto tipo Location para actualizar el mapar
 */
const updateCenterAddress = async (context: AddressMachineContext) : Promise<Location> => {
  
  // Sacamos la coordenas y la configuración
  const { config, coords, data } = context

  // Si no hay coordenadas o geocoder lanzamos el error
  if (!coords || !config || !data ) throw new Error()

  const address: Location | null = await getAddressComponents(coords, config)

  if (!address) throw new Error()

  const url : string = `/api/v2/branch/addres/${data.location.uuid}`
  await axiosClient.put(url, address)

  return address

}

/**
 * Traducir de coordenadas a texto
 * @param coords las coordenadas de la nueva dirección
 * @param config las propiedades del mapa
 * @returns la nueva localización si la encentra
 */
const getAddressComponents = async (coords: Coords, config: Config) : Promise<Location | null> =>{
  
  try {

    const response = await config.geocoder.geocode({ location: coords })

    if (response.results.length <= 0) throw new Error('pocos resultados')

    // se obitnen los componentes
    // eslint-disable-next-line camelcase
    const { address_components, formatted_address, geometry } : any = response.results[0]

    const country: any = getComponent( address_components, ['country']) 
    const state: any = getComponent( address_components, ['administrative_area_level_1' ]) 
    const city: any = getComponent( address_components, ['locality' ]) 
    const zipCode: any = getComponent(address_components, ["postal_code","postal_code_suffix"]) 

    return {
      'address_line_1': formatted_address,
      country: country ? country.long_name : 'Rookmotion' ,
      state: state ? country.long_name : 'Rookmotion',
      city: city ? city.long_name : 'Rookmotion',
      "zip_code": zipCode ? zipCode.long_name : '38300',
      "location_point": `${geometry.location.lat()}, ${geometry.location.lng()}`,
      status: 1
    }
  }
  catch(error) {
    return null
  }

}

/**
 * Agregar una imagen al centro
 * @param context de la maquina que controla la galleria
 * @returns una nueva imagen
 */
const addImageToCenter = async (context: GalleryMachineContext) : Promise<Multimedia> => {
  
  // Sacamos la imagen
  const { image } = context

  // Verificamos que exista un usuario y una imagen
  if ( !image ) throw new Error()

  // Creamos un FormData
  const data = new FormData()
  data.append('center_image', image)

  // Hacemos la petición
  const response = await axiosClient.post(`/api/v2/branch/image`, data)

  return {
    ...response.data,
    uuid: response.data.branch_image_uuid
  }

}

/**
 * Eliminar una imagen
 * @param context contexto de la maquina que controla la galeria
 * @returns el id de la imagen eliminada
 */
const deleteImage = async (context: GalleryMachineContext) : Promise<string> => {
  
  // Sacamos el id a eliminar
  const { imageID } = context

  // Verificamos que exista una imagen a eliminar
  if ( !imageID ) throw new Error()

  // Hacemos la elimación
  await axiosClient.delete(`/api/v2/branch/image/${imageID}`)

  return imageID
}

/**
 * Crear o actualizar las credenciales de streaming
 * @param context maquina que controla el tap de streaming
 * @returns las credenciales actualizadas
 */
const createOrUpdateZoomCredentials = async (context: StreamingMachineContext) : Promise<ZoomCredentials> => {
  
  const { data, credentials } = context

  if (!credentials) throw new Error();

  if (!data) {

    const response = await axiosClient.post('/api/v2/streaming', credentials)

    return {
      uuid: response.data.uuid,
      ...credentials
    }

  }

  await axiosClient.put(`/api/v2/streaming/${data.uuid}`, credentials)

  return {
    ...data,
    ...credentials
  }

}

/**
 * Eliminar las credenciales de zoom
 * @param context maquina que controla el tap de streaming 
 */
const deleteZoomCredentials = async (context: StreamingMachineContext) : Promise<any> => {
  
  const { data } = context

  if (!data) throw new Error();
  
  await axiosClient.delete(`/api/v2/streaming/${data.uuid}`)

}

/**
 * Maquina principal
 */
const CenterMachine = createMachine<CenterMachineContext, CenterMachineEvents>(
  {
    id: 'CenterMachine',
    initial: 'idle',
    context: {
      data: null,
      actors: {},
      image: null,
      statusError: 0,
    },
    states: {
      idle: {
        entry: ['initMainMachine'],
        on: {
          READY: {
            target: 'loaded',
            actions: [
              assign({ data: (_, event) => event.data }),
              send((context) => ({ type: 'SYNC', data: context.data}), 
                { to: (context) => context.actors.address }
              ),
              send((context) => ({ type: 'SYNC', data: context.data?.multimedia}), 
                { to: (context) => context.actors.gallery }
              ),
              send((context) => ({ type: 'SYNC', data: context.data?.credential_zoom}), 
                { to: (context) => context.actors.streaming }
              ),
            ]
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateCenterLogo',
          src: updateCenterLogo,
          onDone: {
            target: 'updated',
            actions: ['updateImage']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar el logo del centro',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar el logo del centro',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar el logo del centro tal vez no se conocía el branch o no se tenía la imagen.',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      loaded: {},
      login: {},
      updated: {},
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      LOGIN: {
        target: '.login'
      },
      IMAGE: {
        target: '.updating',
        actions: assign({ image: (_, event) => event.data })
      }
    }
  },
  {
    actions: {
      initMainMachine: assign((context) => ({
          ...context,
          actors: {
            ...context.actors,
            address: spawn( createAddressMachine() ),
            general: spawn( createGenDataMachine() ),
            gallery: spawn( createGalleryMachine() ),
            streaming: spawn( createStreamingMachine() ),
          }
        })),
      updateImage: assign((context, _event: any) => {
        
        const event : DoneInvokeEvent<string> = _event

        if (event.type !== 'done.invoke.updateCenterLogo') return { ...context }

        return {
          ...context,
          data: {
            ...context.data!,
            image_url: event.data
          }
        }
      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

/**
 * Maquina que controla el mapa
 * @returns un actor
 */
const createAddressMachine = () => createMachine<AddressMachineContext, AddressMachineEvents>(
  {
    id: 'AddressMachine',
    initial: 'preparing',
    context: {
      config: null,
      address: null,
      statusError: 0,
      data: null,
      coords: {
        lat: 19.433503306108946,
        lng: -99.13546680100099
      }
    },
    states: {
      idle: {},
      lost: {},
      updated: {},
      login: {
        entry: sendParent({ type: 'LOGIN' })
      },
      preparing: {
        on: {
          INIT: {
            target: 'idle',
            actions: [ 'initMachine' ]
          }
        }
      },
      translating: {
        invoke: {
          id: 'translateAddress',
          src: translateAddress,
          onDone: {
            target: 'idle',
            actions: [ 'updatePin' ]
          },
          onError: {
            target: 'lost',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'Google no encontro la dirección',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'Google no encontro la dirección',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar al admin tal vez no se conocía el branch.',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateCenterAddress',
          src: updateCenterAddress,
          onDone: {
            target: 'updated',
            actions: ['updateData']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar la dirección del centro',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar la dirección del centro',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar la dirección del centro, por falta de data',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      TRANSLATE: {
        target: '.translating',
        actions: assign({ address: (_, event) => event.data })
      },
      DRAGGED: {
        target: '.idle',
        actions: assign({ coords: (_, event) => event.data  })
      },
      UPDATE: {
        target: '.updating',
        cond: 'isCoords'
      },
      SYNC: {
        target: '.preparing',
        actions: [ 'syncContext' ]
      },
      READY: {
        target: '.idle'
      }
    }
  },
  {
    actions: {
      initMachine: assign((context, event) => {

        if (event.type !== 'INIT') return context

        return {
          ...context,
          config: event.data
        }

      }),
      updatePin: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Coords> = _event

        if (event.type !== 'done.invoke.translateAddress') return context

        if (context.config){
          context.config.marker.setPosition(new context.config.maps.LatLng(
            event.data.lat,
            event.data.lng
          ))
        }

        return {
          ...context,
          coords: event.data
        }

      }),
      updateData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Location> = _event

        if (event.type !== 'done.invoke.updateCenterAddress') return context

        return {
          ...context,
          data: {
            ...context.data!,
            location: {
              ...context.data!.location,
              ...event.data
            }
          },
          address: event.data.address_line_1
        }

      }),
      syncContext: assign((context, event) => {

        if (event.type !== 'SYNC') return context

        let splitted : string[] = [ '19.433503306108946', '-99.13546680100099' ]
        let address : string = ''

        if (event.data.location && event.data.location.location_point) {
          const partial = event.data.location.location_point.split(',')

          if (partial.every(point => !Number.isNaN(Number(point))))
            splitted = partial

          address = event.data.location.address_line_1 || ''

        }

        const coords : Coords = {
          lat: Number(splitted[0] ? splitted[0].trim() : '19.433503306108946'),
          lng: Number(splitted[1] ? splitted[1].trim() : '-99.13546680100099')
        }

        return {
          ...context,
          coords,
          address,
          data: event.data
        }

      }),
    },
    guards: {
      isCoords: (context) => !!context.coords,
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

/**
 * Maquina que controla la página de data del centro
 * @returns un actor
 */
const createGenDataMachine = () => createMachine<GenDataMachineContext, GenDataMachineEvents>(
  {
    id: 'GenDataMachine',
    initial: 'loading',
    context: {
      data: null,
      statusError: 0,
      center: null
    },
    states: {
      loaded: {},
      updated: {},
      login: {
        entry: sendParent({ type: 'LOGIN' })
      },
      loading: {
        invoke: {
          id: 'fetchCenterData',
          src: fetchCenterData,
          onDone: {
            target: 'loaded',
            actions: [
              assign({ data: (_, event) => event.data }),
              sendParent((context) => ({ type: 'READY', data: context.data }))
            ]
          },
          onError: {
            target: 'failure',
            actions: [
              assign({ statusError: (_, event) => event.data.response ? event.data.response.status : 10 })
            ]
          }
        }
      },
      updating: {
        invoke: {
          id: 'updateCenterData',
          src: updateCenterData,
          onDone: {
            target: 'updated',
            actions: [ 'updateData' ]
          },
          onError: {
            target: 'failure',
            actions: [
              assign((context, event) => {

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: 'No se pudo actualizar la información del branch.',
                    level: Sentry.Severity.Error,
                    data: event.data
                  })

                  Sentry.addBreadcrumb({
                    category: 'info',
                    message: 'No se pudo actualizar la información del branch.',
                    level: Sentry.Severity.Error,
                    data: event.data.response
                  })
                }
                else {
                  Sentry.addBreadcrumb({
                    category: 'warning',
                    message: 'No se pudo actualizar la información del branch, por falta de data',
                    level: Sentry.Severity.Warning,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10 
                }
  
              })
            ]
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      UPDATE: {
        target: '.updating',
        actions: assign({ center: (_, event) => event.data })
      },
      READY: {
        target: '.loaded'
      }
    }
  },
  {
    actions: {
      updateData: assign((context, _event: any) => {

        const event : DoneInvokeEvent<any> = _event

        if (event.type !== 'done.invoke.updateCenterData') return context

        return {
          ...context,
          data: {
            ...context.data,
            ...event.data,
            branch_description: event.data.description
          }
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

/**
 * Maquina que controla la galeria
 * @returns un actor que controla la vista de galería
 */
const createGalleryMachine = () => createMachine<GalleryMachineContext, GalleryMachineEvents>(
  {
    id: 'GalleryMachine',
    initial: 'preparing',
    context:{
      data: null,
      statusError: 0,
      image: null,
      imageID: null,
    },
    states: {
      idle: {},
      preparing: {},
      uploaded: {},
      deleted: {},
      login: {
        entry: sendParent({ type: 'LOGIN' })
      },
      adding: {
        invoke: {
          id: 'addImageToCenter',
          src: addImageToCenter,
          onDone: {
            target: 'uploaded',
            actions: ['append']
          },
          onError: {
            target: 'failure',
            actions: assign({ statusError: (_, event) => event.data.response ? event.data.response.status : 10 })
          }
        }
      },
      removing: {
        invoke: {
          id: 'deleteImage',
          src: deleteImage,
          onDone: {
            target: 'deleted',
            actions: ['remove']
          },
          onError: {
            target: 'failure',
            actions: assign({ statusError: (_, event) => event.data.response ? event.data.response.status : 10 })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      SYNC: {
        target: 'idle',
        actions: [ 'syncContext' ]
      },
      IMAGE: {
        target: 'adding',
        actions: [ 'setImage' ]
      },
      REMOVE: {
        target: 'removing',
        actions: ['removeImage']
      },
      READY: {
        target: '.idle'
      }
    }
  },
  {
    actions: {
      syncContext: assign((context, event) => {

        if (event.type !== 'SYNC') return context

        return {
          ...context,
          data: event.data
        }

      }),
      setImage: assign((context, event) => {
        
        if (event.type !== 'IMAGE') return context

        return {
          ...context,
          image: event.data
        }

      }),
      append: assign((context, _event: any) => {

        const event : DoneInvokeEvent<Multimedia> = _event

        if (event.type !== 'done.invoke.addImageToCenter') return context

        if (context.data)
          return {
            ...context,
            data: [
              ...context.data,
              event.data
            ]
          }
        
        return { ...context }

      }),
      removeImage: assign((context, event) => {

        if (event.type !== 'REMOVE') return context

        return {
          ...context,
          imageID: event.data
        }

      }),
      remove: assign((context, _event: any) => {

        const event : DoneInvokeEvent<string> = _event

        if (event.type !== 'done.invoke.deleteImage') return context

        if ( !context.data ) return context

        const newImages : Multimedia[] = context.data.filter(
          (image: Multimedia) => image.uuid !== event.data
        )

        return {
          ...context,
          data: newImages
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

const createStreamingMachine = () => createMachine<StreamingMachineContext, StreamingMachineEvents>(
  {
    id: 'StreamingMachine',
    initial: 'idle',
    context: {
      data: null,
      statusError: 0,
      credentials: null,
    },
    states: {
      login: {},
      idle: {
        on: {
          SYNC: {
            actions: assign({ data: (_, event) => event.data || null })
          },
          UPDATE: {
            target: 'updating',
            actions: assign({ credentials: (_, event) => event.data })
          },
          DELETE: {
            target: 'deleting'
          }
        }
      },
      updated: {
        after: {
          3400: { target: 'idle' }
        }
      },
      updating: {
        invoke: {
          id: 'createOrUpdateZoomCredentials',
          src: createOrUpdateZoomCredentials,
          onDone: {
            target: 'updated',
            actions: assign({ data: (_, event) => event.data })
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar la información de las credenciales.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar la información de las credenciales.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar la información de las credenciales.',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      deleted: {
        after: {
          3400: { target: 'idle' }
        }
      },
      deleting: {
        invoke: {
          id: 'deleteZoomCredentials',
          src: deleteZoomCredentials,
          onDone: {
            target: 'deleted',
            actions: ['restart']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo eliminar la información de las credenciales.',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo eliminar la información de las credenciales.',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar la información de las credenciales.',
                  level: Sentry.Severity.Warning,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      READY: {
        target: '.idle'
      }
    }
  }, 
  {
    actions: {
      restart: assign((context, _event: any) => {

        // cargamos el event
        const event: DoneInvokeEvent<any> = _event

        if (event.type !== 'done.invoke.deleteZoomCredentials') return { ...context }

        return {
          ...context,
          data: null,
          credentials: null
        }

      }),
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401
    }
  }
)

export default CenterMachine