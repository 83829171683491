import { makeStyles } from '@mui/styles';
import styled from 'styled-components'

// Estilos para el check para de recordar sesión
export const useStyles = makeStyles({
  paper: {
    background: "#303030",
    borderRadius: 8,
    color: "white",
    '& #simple-dialog-title': {
      background: "#424242"
    }
  }
})

// Stilos del select
export const inputStyles = makeStyles({
  root: {
    background: 'rgb(48,48,48)',
    borderColor: 'none',
    color: 'white',
    borderRadius: '0.75rem!important',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0.75rem!important',
        borderColor: 'rgb(48,48,48)'
      },
      '& svg':{
        color: 'white'
      }
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'rgb(48,48,48)'
    },
  },
});

// Menu del select
export const menuStyles = makeStyles({
  menuPaper: {
    backgroundColor: 'rgb(48,48,48)',
    color: "white"
  }
})

// estilos del datepicker
export const datePickerStyles = makeStyles({
  root: {
    background: 'rgb(48,48,48)',
    borderColor: 'none',
    borderRadius: '0.75rem!important',
    padding: '0rem 1rem',
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd button": {
      color: 'white'
    },
    '& #date-picker-inline': {
      color: 'white',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        color: 'white'
      }
    },
   '& fieldset':{
      borderColor: 'rgba(255, 255, 255, 0)'
   }
  },
});

type ImageProps = {
  image: string | null
}

export const Image = styled.div<ImageProps>`
  width: 100%;
  height: 10rem;
  background-image: ${props => `url(${props.image})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  justify-self: center;
  border-radius: 0.75rem;
  position: relative;

  @media (min-width: 1024px) {
    width: 12rem;
  }

  @media (min-width: 1536px) {
    width: 16rem;
  }

  & #btn-delete {
    display: block;
  }

  &:hover #btn-delete {
    display: block;
  }

  @media(min-width: 1024px){
    & #btn-delete {
      display: none;
    }
  }
`

export const Delete = styled.div`
  position: absolute;
  top: 0;
  right: 1%;
  background: linear-gradient(0deg, rgba(2,0,36,0.9) 0%, 
  rgba(0,0,0,0.9) 35%, rgba(255,255,255,0) 100%);
  z-index: 50;
  display: none;
  border-radius: 0.75rem;
  color: #f33636;
`

type CenterImageContainerProps = {
  image?: string | null,
}

export const CenterImageContainer = styled.div<CenterImageContainerProps>`
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  background-image: ${props => props.image ? `url(${props.image})` : "url('/images/placeholder.png')"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.75);
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

export const Camera = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    display: none;
  }
  
  &:hover {
    background-color: rgba(0,0,0,0.6);
    
    & svg {
      display: block;
    }

  }
`