import React, { FC, useEffect, useState, useContext } from 'react'

// Dependencias
import { useMachine } from '@xstate/react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Context
import UIContext from '../context/ui/UIContext'

// Machine
import CenterMachine from '../context/machines/CenterMachine'

// Types and models
import Texts from '../models/Texts'
import { Word } from '../types'

// Componentes
import Layout from '../components/layout/Layout'
import CenterImage from '../components/center/CenterImage';
import CenterTabs from '../components/center/CenterTabs';
import Spinner from '../components/utils/Spinner';

// Hook
import useTranslation from '../hooks/useTranslation'

const Center: FC = () => {

  // Hook para iniciar la maquina de estado
  const [machine, send] = useMachine(CenterMachine)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Para actualizar la imagen del centro
  const { centerImage, setCenterImage } = useContext( UIContext )

  useEffect(() => {
    
    if (machine.matches('updated') || machine.matches('failure'))
      setShowAlert(true)
    else setShowAlert(false)

  }, [machine.value])

  useEffect(() => {
    
    if ( machine.context.data 
      && machine.context.data.image_url && machine.context.data.image_url !== ''
    )
      if (machine.context.data.image_url !== centerImage) {
        setCenterImage(machine.context.data.image_url)
      }

  }, [machine.context])

  /**
   * Actualizar la imagen
   * @param image archivo a subir a la api
   */
  const handleImage = (image: File) : void => {
    send({
      type: 'IMAGE',
      data: image
    })
  }

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (machine.value) {
      case 'failure': return 'error'
      case 'updated': return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
   const getMessage = () : string => {
    switch (machine.value) {
      case 'updated': return getTranslation(Texts.imageUpdatedSuccessfully as Word)
      default: return getTranslation( Texts.generalError as Word )
    }
  }

  return ( 
    <Layout>
      <Spinner
        show = { machine.matches('idle') }
      >

      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { getSeverity() } 
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

        <div className="flex flex-col lg:flex-row items-center">
    
          <CenterImage 
            name = { machine.context.data?.chain_name || '' }
            loading = { machine.matches('updating') }
            handleImage = { handleImage }
          />
    
          <CenterTabs 
            services={{
              address: machine.context.actors.address,
              general: machine.context.actors.general,
              gallery: machine.context.actors.gallery,
              streaming: machine.context.actors.streaming,
            }} 
          />
    
        </div>
      </Spinner>
    </Layout>
  )
}
 
export default Center;