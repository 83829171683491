import React, { FC, useState } from 'react'

// Dependencies
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';
import * as Sentry from "@sentry/react";

// Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'

// Icon
import PersonIcon from '@mui/icons-material/Person';

// Types and models
import { Word } from '../types'
import Texts from '../models/Texts'

// Components
import InputForm from '../components/utils/RookFormInputs/InputForm'

// Hooks
import useTranslation from '../hooks/useTranslation'

// HTTP
import axiosClient from '../config/axios'

const Recovery: FC = () => {

  // Bandera para saber si se envio o no
  const [loading, setLoading] = useState(false)

  // validación
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
                .email('loginEmailValidError')
                .required('loginEmailRequiredError')
    }),
    onSubmit: values => {
      sendRequest(values.email)
    }
  })

  // Hook para traducir
  const { getTranslation } = useTranslation()

  const sendRequest = async (email: string) : Promise<any> => {
    
    try {
      setLoading(true)
      
      await axiosClient.post(`/api/v2/admin/forgot-password`, { email })

      Swal.fire(
        getTranslation(Texts.recoveryAlertTitle as Word),
        getTranslation(Texts.recoveryAlertDescription as Word),
        'success'
      )

      formik.resetForm()

    } catch (error) {
      Sentry.captureException(error)
      Swal.fire({
        icon: 'error',
        title: getTranslation(Texts.generalError as Word),
        text: getTranslation(Texts.recoveryAlertError as Word),
      })
    } finally {
      setLoading(false)
    }

  }

  return ( 
    <div 
      className = 'text-center mt-10 flex justify-center items-center h-semi-screen relative'
    >

      <Link
        to = '/login'
      >
        <img
          className = 'absolute top-one left-one w-48'
          src="/images/recovery.png"
          alt="recovery"
        />
      </Link>

      <div className = 'w-2/5'>

        <h1
          className = 'text-4xl'
        >
          { getTranslation( Texts.recoveryTitle as Word ) }
        </h1>

        <p
          className = 'mb-10 mt-2 text-lg'
        >
          { getTranslation( Texts.recoverySubTitle as Word ) }
        </p>

        <p
          className = "text-left mb-14"
        >
          { getTranslation( Texts.recoveryInstruction as Word ) }
        </p>

        <form
          onSubmit = { formik.handleSubmit }
        >

          <InputForm
            type='email'
            id = 'email'
            inputMode = 'email'
            labelClass = 'text-left'
            icon = { <PersonIcon /> }
            value={ formik.values.email }
            handleBlur={ formik.handleBlur }
            handleChange={ formik.handleChange }
            isError={(formik.touched.email && !!formik.errors.email )} 
            title={ getTranslation( Texts.loginEmail as Word ) }
            placeholder= { getTranslation( Texts.loginEmailPlaceHolder as Word ) }
            errorText={ formik.errors.email && 
              getTranslation( Texts[formik.errors.email] as Word) 
            }
          />

          { loading 
            ? (
              <div
                className = "mt-14"
              >
                <CircularProgress color = 'secondary' />
              </div>
            ) 
            : (
              <input
                type="submit"
                value= { getTranslation(Texts.recoveryInstructionSend as Word) }
                className = "shadow uppercase bg-main-gradient py-2 px-8 rounded-full cursor-pointer mt-14"
              />
            ) 
          }


        </form>

      </div>
    </div>
  )
}
 
export default Recovery;