import React, { FC } from 'react'

// Dependecias
import { Link } from 'react-router-dom'

// Iconos
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Types and models
import { Word } from '../../types'
import Texts from '../../models/Texts';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface QuickAccessProps {
  title: string,
  action: string
}
 
/**
 * Contenedor para los rooms en acceso directo
 * @param param0 props contiene todas las propiedades necesarias descritas en QuickAccessProps
 * @param {boolean} props.title texto a mostrar en la cabecera del contenedor
 * @param {boolean} props.action hacia donde se va a redirigir
 * @returns componente con un contenedor
 */
const QuickAccess: FC<QuickAccessProps> = ({ title, action, children }) => {

  // Hook para traducir
  const { getTranslation } = useTranslation()

  return ( 
    <div className = "border border-card-header rounded-md">
  
      <div className="flex flex-col md:flex-row justify-between items-center bg-card-header p-2">
  
        <h4 className = "uppercase font-medium text-sm">
          {title}
        </h4>
  
        <Link to = { action }>
          <p className = "uppercase text-details flex items-center text-sm md:text-base mt-4 md:mt-0">
            { getTranslation( Texts.dashboardDetails as Word ) } <KeyboardArrowRightIcon />
          </p>
        </Link>
  
      </div>
  
      <div className = "p-5 md:h-full md:min-h-20">
        {children}
      </div>
  
    </div>
  )

}
 
export default QuickAccess;