import React, { FC } from 'react'

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

// Components
import ChallengeAction from '../utils/ChallengeAction';

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import { RButtonLoading } from '../../utils/RookButtons';

interface GoalRowActionsProps {
  loading: boolean,
  isEditable: boolean,
  handleEdit: () => void
  handleDelete: () => void
}
 
/**
 * Contenedor de acciones de un objetivo
 * @param props contiene todas las propiedades necesarias descritas en
 * GoalRowActionsProps
 * @param props.loading saber si está eliminando
 * @param props.handleEdit función para editar
 * @param props.handleDelete función para eliminar
 * @returns Contenedor de acciones de un objetivo
 */
const GoalRowActions: FC<GoalRowActionsProps> = ({ 
  loading, isEditable, handleEdit, handleDelete 
}) => {

  // Hooks
  const { getTranslationByKey } = useTranslation()

  return ( 
    <div className="flex justify-around gap-4 items-center">

      { isEditable ? (
        <ChallengeAction
          color = 'bg-green-500'
          title = { getTranslationByKey('edit') }
          onClick = { handleEdit }
        >
          <CreateIcon />
        </ChallengeAction>
      ): <></>}

      <RButtonLoading
        color = 'secondary'
        loading = { loading }
      >
        <ChallengeAction
          color = 'bg-red-500'
          title = { getTranslationByKey('delete') }
          onClick = { handleDelete }
        >
          <DeleteIcon />
        </ChallengeAction>
      </RButtonLoading>

    </div>
  );
}
 
export default GoalRowActions;