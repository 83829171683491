/**
 * Este Hook se utiliza para el control de la lista de opciones que estarán dentro de la barra
 * de navegación, aquí se utiliza para ver los permisos y features enabled
 */

 import React, { useContext } from 'react'

 // Icons
 import HomeIcon from '@mui/icons-material/Home';
 import PersonAddIcon from '@mui/icons-material/PersonAdd';
 import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
 import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
 import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
 import TimelineIcon from '@mui/icons-material/Timeline';
 import DonutLargeIcon from '@mui/icons-material/DonutLarge';
 import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
 import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
 import SensorsIcon from '@mui/icons-material/Sensors';
 import { BandSensors, Gaming } from '../components/utils/Icons';
 
 // Types
 import { MenuOption, Word } from '../types';
 
 // Models
 import Texts from '../models/Texts'
 
 // Context
 import UIContext from '../context/ui/UIContext'
 
 // Hooks
 import useTranslation from './useTranslation'
 
 
 type ToolBox = {
   menuOptionList: MenuOption[]
 };
 
 // type useMenuListProps = {
 // };
 
 const useMenuList = () : ToolBox => {
   
   // Hook para traducir
   const { getTranslation } = useTranslation()
 
   // Sacamos los permisos
   const { permissions, features } = useContext( UIContext )
 
   /**
    * Obtener la lista de opciones 
    * @returns una lista con las opciones disponibles para la barra
    */
   const getMenus = () : MenuOption[] => {
     
     // Arreglo que contendrá las opciones finales
     const items : MenuOption[] = []
 
     // Todas las opciones
     const menuItems: MenuOption[] = [
       {
         key: "dashboard",
         title: getTranslation(Texts.navMenuDashboard as Word),
         icon: <HomeIcon />,
         action: "/",
         always: true
       },
       {
         key: "rooms",
         title: getTranslation(Texts.navMenuRooms as Word),
         icon: <FitnessCenterIcon />,
         always: true,
       },
       {
         key: "daq",
         title: "RookLegend",
         icon: <SettingsRemoteIcon />,
         action: "/rooklegend",
         sub: true,
         parent: 'rooms',
         always: false,
         feature: 'daqs',
         hasPermissions: false,
       },
       {
         key: "rookremote",
         title: "RookRemote",
         icon: <SensorsIcon />,
         action: "/rookremote",
         sub: true,
         parent: 'rooms',
         always: false,
         feature: 'realtime',
         hasPermissions: true,
       },
       {
         key: "gaming",
         title: getTranslation(Texts.gaming as Word),
         icon: <Gaming color = '#fff'/>,
         action: "/challenges",
         parent: 'rooms',
         always: false,
         feature: 'gamification'
       },
       {
         key: "request",
         title: getTranslation(Texts.navMenuRequests as Word),
         icon: <PersonAddIcon />,
         action: "/requests",
         always: true
       },
       {
         key: "user",
         title: getTranslation(Texts.navMenuUsers as Word),
         icon: <DirectionsRunIcon />,
         action: "/users",
         always: false
       },
       {
         key: "sesions",
         title: getTranslation(Texts.navMenuSesions as Word),
         icon: <TimelineIcon />,
         action: "/sessions",
         always: true
       },
       {
         key: "sensors",
         title: getTranslation(Texts.navMenuSensors as Word),
         icon: <BandSensors color = '#fff'/>,
         action: "/sensors",
         always: true
       },
       {
         key: "reports",
         title: getTranslation(Texts.navMenuReports as Word),
         icon: <DonutLargeIcon />,
         action: "/reports",
         always: false
       },
       {
         key: "administrator",
         title: getTranslation(Texts.navMenuAdmin as Word),
         icon: <AdminPanelSettingsIcon />,
         action: "/admin",
         always: false
       },
       {
         key: "role",
         title: getTranslation(Texts.navMenuRoles as Word),
         icon: <SupervisorAccountIcon />,
         action: "/roles",
         always: false
       },
     ]
 
     // Revisamos si es un feature, un permiso o una opción obligatoria
     menuItems.forEach((item) => {
       
       if (item.always) items.push(item)
 
       else if (item.key === 'reports' && permissions.includes('reports')){
           items.push(item)
         }
         else if(item.feature) {
   
             if (item.hasPermissions) {
               if (features[item.feature] && permissions.includes(`${item.key}_show`) ) 
                 items.push(item)
             }
             else if (features[item.feature]) 
                 items.push(item)
   
           }
           else if (permissions.includes(`${item.key}_show`)) {
     
               items.push(item)
     
             }
 
     })
     
 
     return items
 
   }
 
   return {
     menuOptionList: getMenus()
   };
 };
 
 export default useMenuList;