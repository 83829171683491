/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useContext, useEffect, useState, useCallback, useRef } from 'react'

// Dependencias
import 'moment-timezone';
import moment from "moment";
import { useActor } from '@xstate/react';
import { useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress, Snackbar, Alert, AlertColor, Button } from '@mui/material';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';

// Validation
import { Formik, FormikProps } from 'formik';

// Types and models
import Texts from '../../models/Texts'
import { Word, Translation } from '../../types'

// Context
import UIContext from '../../context/ui/UIContext'

// Components
import InputForm from '../utils/RookFormInputs/InputForm'
import InputTextArea from '../utils/RookFormInputs/InputTextArea'
import { RButton } from '../utils/RookButtons';

// Hooks
import useTranslation from '../../hooks/useTranslation'

// Validations
import validationSchema from '../../validations/ValidationsCenterInfo'

// utils
import { capitalize } from '../../utils'
import { InputSelect } from '../utils/RookFormInputs';

interface CenterInfoProps {
  service: any
}

/**
 * Form con la información del centro
 * @param {Object} props contiene todas las propiedades necesarias descritas en CenterInfoProps
 * @param {Any} props.service contiene al actor para controlar este componente
 * @returns Formulario para editar la información del centro
 */
const CenterInfo: FC<CenterInfoProps> = ({ service }) => {

  const [open, setOpen] = useState(false)

  // Hook para el automata que controla esta vista
  const [current, send] = useActor(service)

  // Context: state del actor
  // value: estado en que esta
  const { context, value } : any= current

  // Context para actualizar el idioma
  const { 
    lang, metricSystem, centerData, 
    setLang, setMetrics, setCenterData 
  } = useContext( UIContext )

  // Alerta
  const [alert, setAlert] = useState(false)

  // Hook para traducción
  const { getTranslation } = useTranslation()

  // Navegación
  const history = useHistory()

  // Opciones de métricas
  const metricsOptions = useRef( Texts.centerGeneralDataMetricsTypes as Word[] )
  const languages = useRef( Texts.centerGeneralDataLanguaje as Word[] )

  useEffect(() => {
    
    if (context.data) {

      const bridge = Number(context.data.lang_types) === 1 ? Translation.en : Translation.es

      if (bridge !== lang) setLang( bridge )

      if (context.data.measurement_system !== metricSystem)
        setMetrics( context.data.measurement_system )

    }

  }, [context])

  useEffect(() => {
    
    if (value === 'failure' || value === 'updated')
      setAlert(true)

    else setAlert(false)

    if (value === 'login') history.push('/login')

    if (value === 'updated' && centerData) {
      setCenterData({
        ...centerData,
        offset: context.data.offset,
        offset_name: context.data.offset_name
      })
    }

  }, [value])

  // ComponentDidUnMount para indicar a la maquina volver al estado inicial
  useEffect(() => () => {
    send({ type: 'READY' })
  }, [])

  /**
   * Obtener severidad del mensaje
   * @returns el tipo de alerta a mostrar
   */
   const getSeverity = () : AlertColor => {
    switch (value) {
      case 'failure': return 'error'
      case 'updated': return 'success'
      default: return 'info'
    }
  }

  /**
   * Obtener el mensaje a mostrar
   * @returns el mensaje a mostrar
   */
  const getMessage = () : string => {
    switch (value) {
      case 'updated': return getTranslation(Texts.centerInfoUpdatedSuccessfully as Word)
      default: return getTranslation( Texts.generalError as Word )
    }
  }

  /**
   * Función para cuando se cierra una alerta, indicarle al padre que regrese al estado
   * de cargado para evitar ver una alerta antes vista
   */
  const handleClose = () : void => {
    setAlert(false)

    setTimeout(() => {
      send({ type: 'READY' })
    }, 120)

  }

  const handleCloseTimeZone = useCallback(
    (timezone: string, props: FormikProps<any>) : void => {
   
    const minutesOffset = moment().tz(timezone).utcOffset()
    const hourOffset = minutesOffset / 60
    
    const offset = hourOffset >= 0 ? `+${hourOffset}` : `${hourOffset}`

    props.handleChange({ 
      target: { 
        name: 'offset', 
        value: { offsetName: timezone, offset}
      }
    })
    
    setOpen(false)
  }, [])

  return context.data && ( 
    <>
      <Snackbar
        open = { alert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { handleClose }
      >
        <Alert
          severity = { getSeverity() }
          variant = "filled"
          onClose = { handleClose }
        >
          { getMessage() }
        </Alert>
      </Snackbar>

      <Formik
        initialValues={{
          'measurement_system': context.data.measurement_system || 'metric_system',
          'branch_name': capitalize(context.data.branch_name || ''),
          'lang_types': `${context.data.lang_types || '1'}`,
          description: capitalize(context.data.branch_description || ''),
          offset: {
            offsetName: context.data.offset_name || 'America/Mexico_City',
            offset: context.data.offset || '-5',
          } 
        }}
        validationSchema = { validationSchema }
        onSubmit={ values => send({ type: 'UPDATE', data: values })}
      >

        { props => (
          <>
            <form
              onSubmit = { props.handleSubmit }
            >

              <InputSelect
                background = 'dark'
                name = 'measurement_system'
                label = { getTranslation( Texts.centerGeneralDataMetrics as Word ) }
              >
                {metricsOptions.current.map((metricOption: Word) => (
                          
                  <MenuItem 
                    key = { metricOption.label }
                    value = { metricOption.label }
                  >
                    { getTranslation( metricOption ) }
                  </MenuItem>

                ))}
              </InputSelect>


              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-8">

                <InputForm 
                  type = 'text' 
                  placeholder={getTranslation(Texts.centerGeneralDataCenterBranchPlaceholder as Word )} 
                  id='branch_name'
                  title={ getTranslation( Texts.centerGeneralDataCenterBranch as Word ) }
                  value={props.values.branch_name}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  isError={(props.touched.branch_name && !!props.errors.branch_name )} 
                  errorText={  props.errors.branch_name || '' }     
                />

                <InputForm 
                  type='text'
                  placeholder={ getTranslation(Texts.centerGeneralDataCenterName as Word) }
                  id='chain_name'
                  title={ getTranslation(Texts.centerGeneralDataCenterName as Word) }
                  readOnly
                  value={ context.data ? context.data.chain_name : '' }
                  handleChange={() => {}}
                  handleBlur={() => {}}
                />

              </div>

              <InputSelect
                background = 'dark'
                name = 'lang_types'
                label = { getTranslation( Texts.centerGeneralDataCenterLanguaje as Word ) }
              >
                {languages.current.map((language: Word) => (
                  <MenuItem 
                    key = { language.label }
                    value = { language.label }
                  >
                    { getTranslation( language ) }
                  </MenuItem>

                ))}
              </InputSelect>

              <div className="flex flex-col md:flex-row items-center mt-8">

                <InputForm 
                  marginTop='mt-0 md:mr-8 w-full md:w-8/12 xl:w-9/12'
                  type='text'
                  placeholder='TimeZone'
                  id='timeZone'
                  title={ getTranslation(Texts.centerGeneralDataCenterTimezone as Word) }
                  readOnly
                  value={ props.values.offset.offsetName }
                  handleChange={() => {}}
                  handleBlur={() => {}}
                />

                <RButton
                  className='mt-8'
                  onClick={ () => setOpen(true) }
                >
                  Configurar
                </RButton>
              </div>

              <InputTextArea 
                placeholder={getTranslation(Texts.centerGeneralDataCenterDescriptionPH as Word)}
                id='description'
                title={ getTranslation( Texts.centerGeneralDataCenterDescription as Word ) }
                value={props.values.description}
                handleChange={props.handleChange}
                handleBlur={props.handleBlur} 
                isError={(props.touched.description && !!props.errors.description )} 
                errorText={  props.errors.description || '' }      
              />

              <div className="mt-4 md:8 flex justify-center">

                { value === 'updating' 
                  ? (<CircularProgress />) 
                  : (
                    <button
                      type = "submit"
                      className = "uppercase bg-primary px-8 py-2 rounded-3xl mt-8 lg:mt-0 w-full lg:w-max"
                    >
                      {getTranslation( Texts.save as Word )}
                    </button>
                  )
                }
              </div>
            </form>

            <TimeZoneSelectDialog
              title= { getTranslation(Texts.centerGeneralDataCenterTimezone as Word) }
              open = { open }
              timeZoneName={props.values.offset.offsetName}
              onClose = { timezone => handleCloseTimeZone(timezone, props) }
              buttonLabelOk = { getTranslation(Texts.choose as Word) }
              buttonLabelCancel={ getTranslation(Texts.close as Word) }
              SubstituteButtonOk={ ({color, sx, ...args}) => <Button {...args} /> }
              SubstituteButtonCancel={ ({color, sx, ...args}) => <Button {...args} /> }
              description = { 
                getTranslation(Texts.centerGeneralDataTimezoneDescription as Word) 
              }
            />
          </>
        )}
      </Formik>

    </>
  )
}
 
export default CenterInfo;