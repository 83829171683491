import React, { FC, useRef, useEffect } from 'react'

// Dependencies
import * as Yup from 'yup'
import { useFormik } from "formik";

// Components
import InputTextArea from '../utils/RookFormInputs/InputTextArea';

// Hooks
import useTranslation from '../../hooks/useTranslation'

interface UserReportModalCommentsProps {
  startDownload: { counter: number, download: boolean },
  handleDownload: (comments: string) => void,
}
 
const UserReportModalComments: FC<UserReportModalCommentsProps> = ({
  startDownload, handleDownload
}) => {
  
  // Suscripción para tener la cuenta de los caracteres
  const submit = useRef<HTMLInputElement | null>(null)

  // Hook para traducir
  const { getTranslationByKey } = useTranslation()

  useEffect(() => {
    
    if (startDownload.counter > 0) {
      submit.current?.click()
    }

  }, [startDownload])

  // Guardar el comentario del coach
  const formik = useFormik({
    initialValues: {
      comments: '',
    },
    validationSchema: Yup.object().shape({
      comments: Yup.string()
        .notRequired()
        .when('comments', {
          is: (value: any) => value,
          then: (rule) => rule.max(1200, 'userReportModalCommentsError')
        })
    }, [['comments', 'comments']]),
    onSubmit: (values) => {
      let finalText = values.comments

      while (finalText.includes("\n\n")) finalText = finalText.replace("\n\n","\n");

      handleDownload(finalText)
    },
  })
  
  return ( 

    <form onSubmit={ formik.handleSubmit }>
      <InputTextArea 
        isLimited
        total = { 1200 }
        count = { formik.values.comments.length }
        marginTop='mt-4'
        placeholder= { getTranslationByKey('userReportModalCommentsPlaceholder') }
        id='comments'
        title= { getTranslationByKey('userReportModalCommentsTitle') } 
        value={ formik.values.comments } 
        handleChange={ formik.handleChange } 
        handleBlur={ formik.handleBlur }
        isError={(formik.touched.comments && !!formik.errors.comments )} 
        errorText={ formik.errors.comments && 
          getTranslationByKey( formik.errors.comments) 
        } 
      />

      <input 
        ref = { submit }
        type="submit" 
        value="none"  
        className='hidden'
      />
    </form>
    
  );
}
 
export default UserReportModalComments;