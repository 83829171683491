import React, { FC, useState, useEffect, useContext } from 'react'

// Dependecies
import * as Yup from "yup";
import { useFormik } from "formik";
import { withStyles } from '@mui/styles';
import { useMachine } from '@xstate/react';
import { useHistory, Link } from "react-router-dom";
import Checkbox, { CheckboxProps }  from '@mui/material/Checkbox';
import { CircularProgress, Snackbar, Alert } from '@mui/material';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Context
import UIContext from '../../context/ui/UIContext'

// Machines
import LoginMachine from '../../context/machines/LoginMachine'

// Utils
import InputError from '../utils/RookFormInputs/InputError';

// Models
import Texts from '../../models/Texts'

// Hooks
import useTranslation from '../../hooks/useTranslation'
import { Word } from '../../types';

// Estilos para el check para de recordar sesión
const RemembermeCheckBox = withStyles({
  root: {
    color: "white",
    '&$checked': {
      color: "white",
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type FormProps = {
  showButton: boolean
}

/**
 * Formulario del login
 * @param {Object} props contiene todas las propiedades necesarias descritas en FormProps
 * @param {boolean} props.showButton mostrar el botón para iniciar sesión
 * @returns formulario del login
 */
const Form: FC<FormProps> = ({ showButton }) => {

  // Bandera para saber si mostrar la contrase o mostrar los puntitos
  const [visible, setVisible] = useState(false)

  // Mostrar o no la alerta
  const [showAlert, setShowAlert] = useState(false)

  // Automata para mandar el login
  const [machine, send] = useMachine(LoginMachine)

  // Context para indicar que estamos autenticados
  const { 
    setAuthenticated, 
    setDashboardData, 
    setCurrentUser, 
    setPermissions,
    setContractAlreadyShown,
    setFeatures,
    setBranchUUID,
    setCenterData
  } = useContext( UIContext )

  // Hook para traducir
  const { getTranslation } = useTranslation()

  // Routing
  const history = useHistory()

  // Hook para validar el formulario
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberme: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email("loginEmailValidError").required("loginEmailRequiredError"),
      password: Yup.string().required("loginPasswordRequiredError")
  }),
    onSubmit: values => {
      send({
        type: "SUBMIT",
        data: values
      })
    }
  })

  useEffect(() => {
    
    if (machine.matches("error")) setShowAlert(true)
    else setShowAlert(false)

    // Cuando la maquina llega a su fin se cargan los valores iniciales al state global
    if (machine.done) {
      setBranchUUID(machine.context.uuidBranch)
      setPermissions(machine.context.permissions)
      setAuthenticated(true)
      setDashboardData(machine.context.dashboard)
      setCurrentUser(machine.context.currentUser)
      setContractAlreadyShown(!machine.context.showAgreement)
      setFeatures(machine.context.features)
      history.push("/")
    }

  }, [machine.value])

  useEffect(() => {
    
    if (machine.context.centerData) 
      setCenterData(machine.context.centerData)

  }, [machine.context])

  /**
   * Obtener el mensaje de error de acuerdo al estado del error
   * @returns Mensaje de error
   */
  const getError = () : string => {
    switch (machine.context.message) {
      case 401:
        return getTranslation( Texts.loginErrorOpenSession as Word )
    
      case 422: 
        return getTranslation( Texts.loginError as Word )
      
      case 10:
        return getTranslation( Texts.loginErrorVerified as Word )

      case 12:
        return getTranslation( Texts.loginErrorInactive as Word )

      case 500:
      case 503:
        return getTranslation( Texts.serviceUnavailable as Word )
      
      case 404: 
      default:
          return getTranslation( Texts.centerError as Word )
    }
  }

  return ( 
    <div className = "w-10/12 lg:w-4/12">
      <Snackbar
        open = { showAlert }
        autoHideDuration = { 6000 }
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}
        onClose = { () => setShowAlert( false ) }
      >
        <Alert
          severity = { machine.context.message === 401 ? 'warning' : 'error' }
          variant = "filled"
          onClose = { () => setShowAlert( false ) }
        >
          { getError() }
        </Alert>
      </Snackbar>

      <form 
        className = "mb-20 md:mb-0"
        onSubmit = { formik.handleSubmit }
      >
  
        <div className = "flex flex-col mt-2">
  
          <label 
            htmlFor="email"
            className = "uppercase"
          >
            { getTranslation( Texts.loginEmail as Word ) }
          </label>
  
          <div 
            className={`flex items-center bg-input-background py-2 px-3 rounded-md mt-2 
              ${(formik.touched.email && formik.errors.email) && 'border border-red-600'}`}
          >
  
            <PersonIcon 
              className = {`${(formik.touched.email && formik.errors.email) && 'text-red-600'}`}
            />
            
            <input
              type="text"
              name="email"
              inputMode = "email"
              id="email"
              className = "appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-input-background focus:bg-input-background"
              placeholder = { getTranslation( Texts.loginEmailPlaceHolder as Word ) }
              value = { formik.values.email }
              onChange = { formik.handleChange }
              onBlur = { formik.handleBlur }
            />
  
          </div>

          { (formik.touched.email && formik.errors.email )
              && (
                <InputError>
                  { formik.errors.email === "loginEmailRequiredError" 
                    ? getTranslation(Texts.loginEmailRequiredError as Word)
                    : getTranslation(Texts.loginEmailValidError as Word)
                  }
                </InputError>
              ) 
          }
  
        </div>
  
        <div className = "flex flex-col mt-4">
  
          <label 
            htmlFor="password"
            className = "uppercase"
          >
            { getTranslation( Texts.loginPassword as Word ) }
          </label>
  
          <div 
            className={`flex items-center bg-input-background py-2 px-3 rounded-md mt-2 
              ${(formik.touched.password && formik.errors.password) && 'border border-red-600'}`}
          >
  
            <LockIcon 
              className = {`${(formik.touched.password && formik.errors.password) && 'text-red-600'}`}
            />
            
            <input
              type={ visible ? 'text' : 'password' }
              name="password"
              id="password"
              className = "appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-input-background focus:bg-input-background"
              placeholder = { getTranslation( Texts.loginPasswordPlaceHolder as Word ) }
              value = { formik.values.password }
              onChange = { formik.handleChange }
              onBlur = { formik.handleBlur }
            />

            <button
              type = "button"
              onClick = { () => setVisible( !visible ) }
            >
              { visible 
                ? (
                  <VisibilityOffIcon />
                ) 
                : (
                  <VisibilityIcon />
                ) 
              }
            </button>
            
          </div>

          { (formik.touched.password && formik.errors.password )
              && (
                <InputError>
                  { formik.errors.password === "loginPasswordRequiredError" 
                    && getTranslation(Texts.loginPasswordRequiredError as Word)
                  }
                </InputError>
              ) 
          }
  
        </div>

        <div className="flex justify-between flex-col md:flex-row items-center mt-4">
          <div className = "flex items-center">
            <RemembermeCheckBox
              name = "rememberme"
              id = "rememberme"
              checked = { formik.values.rememberme }
              onChange = { formik.handleChange }
              inputProps={{ 'aria-label': 'primary checkbox' }}
              data-cy = "btn-rememberme"
            />
            <label htmlFor="rememberme">
              { getTranslation( Texts.loginRememberme as Word ) }
            </label>
          </div>

          <Link to="/forgot-password">
            <p
              className = "text-title cursor-pointer text-sm mt-10 md:mt-0 md:text-base"
            >
              { getTranslation( Texts.loginForgotPassword as Word ) }
            </p>
          </Link>
        </div>

        { showButton && (

          <div className = "w-full flex justify-center mt-8">

            { machine.matches('loading') || machine.matches('preparing') 
              ? (<CircularProgress color = "secondary"/>) 

              : (
                <input
                  type="submit"
                  value={ getTranslation( Texts.loginSubmit as Word ) }
                  className = "shadow uppercase bg-main-gradient py-2 px-8 rounded-full cursor-pointer"
                />
              ) 
            }

          </div>
        )}

  
      </form>
  
    </div>
  )
}
 
export default Form;