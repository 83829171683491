import { createMachine, assign, send, spawn, DoneInvokeEvent, sendParent } from "xstate";

// Dependencies
import lodash from 'lodash'
import * as Sentry from "@sentry/react";
import axiosClient from "../../../config/axios";

// types
import { Goal, GoalBody, Goals, GoalType, SearchIn } from "../../../types";

// Utils
import { dummyLinks, generateMeta } from '../../../utils'

/**
 * page: Es el actor de la página que se esta visualizando
 * pageData: es el conjunto de páginas ya descargadas
 * pages: número de páginas este se afecta cuando se hace una paginacion con búsqueda
 * totalPages: número original de páginas
 * pageAlreadyExists: bandera que me indica si ya se descargo una página
 * searched: resultado de la búsqueda
 * goal: data o body para el post
 * focusGoal: goal a editar
 */
type GaminGoalMachineContext = {
  page: any | null,
  pageData: any,
  pages: number,
  totalPages: number,
  currentPage: number,
  pageAlreadyExists: boolean,
  uuidChallenge: string,
  openGoalModal: boolean,
  goalTypes: GoalType[] | null,
  goal: GoalBody | null,
  focusGoal: Goal | null,
  search: SearchIn | null,
  searched: Goals | null,
  statusError: number
  isFreeChallenge: boolean,
}

/**
 * INIT: cargar el uuid del reto
 * READY: cuando se terminó de descargar la data de la página y guardar el número de páginas,
 * SAVE: actualizar el objetivo
 * EDIT: saber que objetivo se va actualizar
 */
type GamingGoalMachineEvent = 
  | { type: 'INIT', uuidChallenge: string }
  | { type: 'PAGEING', page: number }
  | { type: 'READY', pages: number }
  | { type: 'CREATE', data: { goal: GoalBody, isFree: boolean}}
  | { type: 'SAVE', data: GoalBody}
  | { type: 'EDIT', data: Goal }
  | { type: 'SEARCH', data: SearchIn }
  | { type: 'TOGGLE' }

/**
 * changeStatus: actualizar el estado del objetivo
 * goal: objetivo que se actualizó su estado
 * uuidDelete: del objetivo a eliminar
 */
type CreatePageMachineContext = {
  page: number,
  uuidChallenge: string
  statusError: number,
  goals: Goals | null
  changeStatus: { uuid: string, status: boolean} | null,
  goal: Goal | null,
  uuidDelete: string,
}
  
/**
 * APPEND: agregar el objetivo que se acaba de crear
 * Refresh: actualizar la data cuando se hace una busqueda
 * HIDE: decirle al padre que cambie de estado
 */
type CreatePageMachineEvent = 
  | { type: 'STATUS', data: { uuid: string, status: boolean} }
  | { type: 'APPEND', data: { goal: Goal, isFree: boolean } }
  | { type: 'EDIT', data: Goal }
  | { type: 'DELETE', data: string }
  | { type: 'REFRESH', data: Goals }
  | { type: 'HIDE' }


/**
 * Descargar los objetivos
 * @param context de la maquina que controla la tabla
 * @returns una lista de goals
 */
const fetchGoals = async (context: CreatePageMachineContext) : Promise<Goals> => {
  
  // Sacamos la página
  const { page, uuidChallenge } = context

  // Definimos de que página vamos a descargar
  let url : string = `/api/v1/challenges/${uuidChallenge}/goals`
  url = page ? `${url}?page=${page}` : url

  const response = await axiosClient({
    url,
    baseURL: process.env.REACT_APP_GM,
  })

  return response.data

}

/**
 * Cambiar el estado de un objetivo
 * @param context de la maquina que controla la lista
 */
const changeGoalStatus = async (context: CreatePageMachineContext) : Promise<any> => {
  
  const { changeStatus } = context

  if (!changeStatus) throw new Error()

  await axiosClient({
    url: `api/v1/goals/status/${changeStatus.uuid}`,
    baseURL: process.env.REACT_APP_GM,
    method: 'PUT',
    data: {
      status: changeStatus.status
    }
  })

}

/**
 * Descargar los tipos de objetivos
 */
const fetchGoalTypes = async () : Promise<GoalType[]> => {
 
  const response = await axiosClient({
    url: `/api/v1/goal-types`,
    baseURL: process.env.REACT_APP_GM,
  })
  
  return response.data.data

}

/**
 * Crear un objetivo
 * @param context de la maquina principal
 * @returns un objetivo nuevo
 */
const createGoal = async (context: GaminGoalMachineContext) : Promise<Goal> => {
  
  const { goal, uuidChallenge } = context

  if (!goal) throw new Error();
  
  const response = await axiosClient({
    url: `/api/v1/challenges/${uuidChallenge}/goals`,
    baseURL: process.env.REACT_APP_GM,
    data: goal,
    method: 'POST'
  })

  return {
    ...response.data.data,
    status: true
  }

}

/**
 * Actualizar un objetivo
 * @param context de la maquina principal
 * @returns un objetivo actualizado
 */
const updateGoal = async (context: GaminGoalMachineContext) : Promise<Goal> => {
  
  const { goal, uuidChallenge } = context

  if (!goal || !goal.uuid ) throw new Error();

  const response = await axiosClient({
    url: `/api/v1/challenges/${uuidChallenge}/goals/${goal.uuid}`,
    baseURL: process.env.REACT_APP_GM,
    data: goal,
    method: 'PUT'
  })

  return response.data.data

}

/**
 * Eliminar un objetivo
 * @param context de la maquina principal
 */
const deleteGoal = async (context: CreatePageMachineContext) : Promise<any> => {
  
  const { uuidChallenge, uuidDelete } = context

  await axiosClient({
    url: `/api/v1/challenges/${uuidChallenge}/goals/${uuidDelete}`,
    baseURL: process.env.REACT_APP_GM,
    method: 'DELETE'
  })

}

/**
 * Buscar un objetivo
 * @param context de la maquina principal
 * @returns una lista de objetivos
 */
const searchForGoal = async (context: GaminGoalMachineContext) : Promise<Goals> => {
  
  // Sacamos el objeto con el que vamos a buscar
  const { search, uuidChallenge } = context

  // Si no hay lanzamos un error
  if ( !search || search.search === '-1') throw new Error()

  const branch = axiosClient.defaults.headers.common['branch-uuid']

  // Hacemos la petición
  let url = `/api/v1/challenges/${uuidChallenge}`
  url = `${url}/goals/search?column=${search.search_column}&data=${search.search}`
  url = `${url}&branch_uuid=${branch}`

  const response = await axiosClient({
    url,
    baseURL: process.env.REACT_APP_GM,
  })

  return response.data

}

const labelingGoals = (goals: Goal[]) : Goal[]=> {
  
  let counter = 1
  let previous = 0
  const copy = goals

  for (let index = 0; index < goals.length; index = index + 1) {
    
    if (previous === goals[index].goal_type_id) counter = counter + 1
    else {
      counter = 1
      previous = goals[index].goal_type_id
    }

    copy[index] = { 
      ...goals[index], 
      level: counter,
      points: Number.isNaN(Number(goals[index].points)) 
        ? '0' 
        : Math.round(Number(goals[index].points)).toString()
    } 

  }

  return copy

}

/**
 * Maquina que controla la vista donde sala la tabla de objetivos
 */
export default createMachine<GaminGoalMachineContext, GamingGoalMachineEvent>(
  { 
    id: 'GamingGoalMachine',
    initial: 'preparing',
    context: {
      pages: 0,
      page: null,
      goal: null,
      search: null,
      searched: null,
      pageData: {},
      totalPages: 0,
      currentPage: 1,
      statusError: 0,
      goalTypes: null,
      focusGoal: null,
      uuidChallenge: '',
      openGoalModal: false,
      isFreeChallenge: false,
      pageAlreadyExists: false,
    },
    states: {
      login: {},
      loaded: {},
      idle: {
        entry: send({ type: 'PAGEING', page: 1 }),
      },
      preparing: {
        on: {
          INIT: {
            target: 'idle',
            actions: assign({ uuidChallenge: (_, event) => event.uuidChallenge})
          }
        }
      },
      loading: {
        always: [
          // { target: 'lookingFor', cond: 'isCompoundPagination' },
          { target: 'loaded', cond: 'isPageAlreadyExists' }
        ],
        on: {
          READY: {
            target: 'loaded',
            actions: assign({ 
              pages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
              totalPages: (context, event) => event.pages > context.pages ? event.pages : context.pages,
            })
          }
        }
      },
      extra: {
        always: [
          { target: 'loaded', cond: 'isAlreadyExtraFetched' }
        ],
        invoke: {
          id: 'fetchGoalTypes',
          src: fetchGoalTypes,
          onDone: {
            target: 'loaded',
            actions: assign({ goalTypes: (_, event) => event.data })
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo descargar los retos',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10,
                openGoalModal: false
              }

            })
          }
        }
      },
      created: {},
      creating: {
        invoke: {
          id: 'createGoal',
          src: createGoal,
          onDone: {
            target: 'created',
            actions: [
              assign({ openGoalModal: (context) => !context.openGoalModal }),
              send(
                (context, event) => ({ 
                  type: 'APPEND',
                  data: {goal: event.data, isFree: context.isFreeChallenge} 
                }), 
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              let status: number = event.data.response ? event.data.response.status : 10
              
              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo crear el objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo crear el objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })

                const data = event.data.response

                if (data && data.data && data.data.errors && data.data.errors.message) 
                  if (data.data.errors.message.includes('already exists')) 
                    if (context.isFreeChallenge)
                      status = 30
                    else status = 40
                  else if (data.data.errors.message.includes('be higher'))
                    status = 40

              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo crear el objetivo tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: status,
              }

            })
          }
        }
      },
      saved: {},
      saving: {
        invoke: {
          id: 'updateGoal',
          src: updateGoal,
          onDone: {
            target: 'saved',
            actions: [
              'resetFocusGoal',
              assign({ openGoalModal: (context) => !context.openGoalModal }),
              send(
                (context, event) => ({ 
                  type: 'APPEND',
                  data: {goal: event.data, isFree: context.isFreeChallenge} 
                }), 
                { to: (context) => context.page }
              )
            ]
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              let status = event.data.response ? event.data.response.status : 10

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo editar el objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo editar el objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })

                const data = event.data.response

                if (data && data.data && data.data.errors && data.data.errors.message) 
                  if (data.data.errors.message.includes('already exists')) 
                    if (context.isFreeChallenge)
                      status = 30
                    else status = 40
                  else if (data.data.errors.message.includes('be higher'))
                    status = 40

              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo editar el objetivo tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: status,
              }

            })
          }
        }
      },
      searching: {
        invoke: {
          id: 'searchForGoal',
          src: searchForGoal,
          onDone: {
            target: 'loaded',
            actions: [
              'searchedGoals',
              send((context) => ({ type: 'REFRESH', data: context.searched }), {
                to: (context) => context.page
              })
            ]
          },
          onError: {
            target: 'loaded',
            actions: assign((context, event) => {

              if (event.data.response && event.data.response !== 422) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo hacer la busqueda',
                  level: Sentry.Severity.Error,
                  data: event.data
                })
              }

              Sentry.captureException(event.data)

              return {
                ...context,
                currentPage: 1,
                page: Object.values(context.pageData)[0],
                pages: context.totalPages
              }
            })
          }
        }
      },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ]
      }
    },
    on: {
      PAGEING: {
        target: '.loading',
        actions: ['createPage']
      },
      TOGGLE: {
        target: '.extra',
        actions: [
          'resetFocusGoal',
          assign({ 
            openGoalModal: (context) => !context.openGoalModal,
          }),
        ]
      },
      CREATE: {
        target: '.creating',
        actions: [
          assign({ goal: (_, event) => event.data.goal }),
          assign({ isFreeChallenge: (_, event) => event.data.isFree })
        ]
      },
      EDIT: {
        target: '.extra',
        actions: assign({ 
          focusGoal: (_, event) => event.data,
          openGoalModal: (context) => !context.openGoalModal
        })
      },
      SAVE: {
        target: '.saving',
        actions: assign({ goal: (_, event) => event.data })
      },
      SEARCH: {
        target: '.searching',
        actions: assign((context, event) => ({
            ...context,
            search: event.data,
            currentPage: 1
          }))
      }
    }
  },
  {
    actions: {
      createPage: assign((context, event) => {

        if (event.type !== 'PAGEING') return { ...context }

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData[`${event.page}`]

        if (subPage) {
          return {
            ...context,
            page: subPage,
            pageAlreadyExists: true,
            currentPage: event.page
          }
        }

        // Otherwise, spawn a new subreddit actor and
        // save it in the subreddits object
        subPage = spawn( createPageMachine( event.page, context.uuidChallenge, 'loading' ))

        return {
          pageData: {
            ...context.pageData,
            [`${event.page}`]: subPage
          },
          page: subPage,
          pageAlreadyExists: false,
          currentPage: event.page
        }

      }),
      searchedGoals: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Goals> = _event

        // Si no se mando llamar de retrieveUsers hacemos un early return
        if (event.type !== "done.invoke.searchForGoal" 
          && event.type !== 'done.invoke.searchCompoundRequest'
        ) 
          return context

        // Use the existing subredit actor if one already exists
        let subPage = context.pageData.searchMachine

        if (subPage) {

          return {
            ...context,
            searched: event.data,
            page: subPage,
            pages: event.data.meta.last_page
          }

        }

        subPage = spawn( createPageMachine( 1, context.uuidChallenge,'loaded' ) )

        return {
          pageData: {
            ...context.pageData,
            searchMachine: subPage
          },
          page: subPage,
          searched: event.data,
          pages: event.data.meta.last_page
        }

      }),
      resetFocusGoal: assign((context) => ({
          ...context,
          focusGoal: null
        }))
    },
    guards: {
      isPageAlreadyExists: (context) => context.pageAlreadyExists,
      isAlreadyExtraFetched: (context) => !!context.goalTypes,
      isUnauthorized: (context) => context.statusError === 401,
    }
  }
)

/**
 * Actor que controla la lista de objetivos
 * @param page número de página que va a administrar
 * @param uuidChallenge uuid del reto
 * @param initial estado inicial loading (va a descargar) o loaded (resultado de una búsqueda)
 * @return un actor
 */
export const createPageMachine = (page: number, uuidChallenge: string, initial: string) => createMachine<CreatePageMachineContext, CreatePageMachineEvent >(
  {
    id: 'pageMachine',
    initial,
    context: {
      page,
      goal: null,
      goals: null,
      uuidChallenge,
      uuidDelete: '',
      statusError: 0,
      changeStatus: null,
    },
    states: {
      login: {},
      loaded:{
      },
      loading: {
        invoke: {
          id: 'fetchGoals',
          src: fetchGoals,
          onDone: {
            target: 'loaded',
            actions: [
              'preparingData', 
              sendParent(
                (context, _) => ({ type: 'READY', pages: context.goals?.meta.last_page || 0 })
              )
            ]
          },
          onError: {
            target: 'notExists',
            actions: [
              sendParent(
                (context, _) => ({ 
                  type: 'READY', pages: context.page >= 1 ? 0 : context.page - 1 })
              ),
              assign((context, event) => {

                if (event.data.response) {
                  Sentry.addBreadcrumb({
                    category: 'error',
                    message: 'No se pudo descargar los objetivos',
                    level: Sentry.Severity.Error,
                    data: event.data
                  })
                }

                Sentry.captureException(event.data)
                
                return {
                  ...context,
                  statusError: event.data.response ? event.data.response.status : 10,
                  goals: {
                    data: [],
                    links: dummyLinks,
                    meta: generateMeta(context.page, context.page)
                  }
                }

              })
            ]
          }
        }
      },
      changed: {
        on: {
          HIDE: {
            target: 'loaded',
          }
        }
      },
      changing: {
        invoke: {
          id: 'changeGoalStatus',
          src: changeGoalStatus,
          onDone: {
            target: 'changed',
            actions: ['updateStatus']
          },  
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo actualizar el status del objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo actualizar el status del objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo actualizar el status del objetivo tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      deleted: {},
      deleting: {
        invoke: {
          id: 'deleteGoal',
          src: deleteGoal,
          onDone: {
            target: 'deleted',
            actions: ['deleteGoal']
          },
          onError: {
            target: 'failure',
            actions: assign((context, event) => {

              if (event.data.response) {
                Sentry.addBreadcrumb({
                  category: 'error',
                  message: 'No se pudo eliminar el status del objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data
                })

                Sentry.addBreadcrumb({
                  category: 'info',
                  message: 'No se pudo eliminar el status del objetivo',
                  level: Sentry.Severity.Error,
                  data: event.data.response
                })
              }
              else {
                Sentry.addBreadcrumb({
                  category: 'warning',
                  message: 'No se pudo eliminar el status del objetivo tal vez porque no se tenía la información completa',
                  level: Sentry.Severity.Warning
                })
              }

              Sentry.captureException(event.data)
              
              return {
                ...context,
                statusError: event.data.response ? event.data.response.status : 10 
              }

            })
          }
        }
      },
      notExists: { },
      failure: {
        always: [
          { target: 'login', cond: 'isUnauthorized' }
        ],
        on: {
          HIDE: {
            target: 'loaded',
          }
        }
      },
    },
    on: {
      STATUS: {
        target: '.changing',
        actions: assign({ changeStatus: (_, event) => event.data })
      },
      APPEND: {
        target: '.loaded',
        actions: [ 'appendGoal' ]
      },
      EDIT: {
        actions: [
          assign({ goal: (_, event) => event.data }),
          sendParent(
            (_, event) => ({ 
              type: 'EDIT', data: event.data })
          )
        ]
      },
      REFRESH: {
        target: '.loaded',
        actions: assign({ goals: (_, event) => event.data })
      },
      DELETE: {
        target: 'deleting',
        actions: [
          assign({ uuidDelete: (_, event) => event.data })
        ]
      }
    }
  },
  {
    actions: {
      resetValues: assign((context) => ({
          ...context,
          changeStatus: null
        })),
      preparingData: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<Goals> = _event

        if (event.type !== 'done.invoke.fetchGoals') return { ...context }

        const goals = lodash.sortBy( event.data.data, ['goal_type_id', 'meta'])

        return {
          ...context,
          goals: {
            ...event.data,
            data: labelingGoals(goals)
          }
        }

      }),
      updateStatus: assign((context, _event: any) => {

        // Cargamos el evento
        const event : DoneInvokeEvent<any> = _event

        if (event.type !== 'done.invoke.changeGoalStatus' 
          || !context.goals 
          || !context.changeStatus
        ) 
          return { ...context }

        const newGoals = context.goals.data.map( 
          goal => goal.uuid === context.changeStatus!.uuid 
            ? { ...goal, status: !goal.status }
            : goal
        )

        return  {
          ...context,
          goals: {
            ...context.goals,
            data: newGoals
          }
        }

      }),
      appendGoal: assign((context, event) => {

        if (!context.goals || event.type !== 'APPEND') return { ...context }

        let { data } = context.goals

        // Buscamos si ya existe el reto
        const index = data.findIndex((goal) => goal.uuid ===  event.data.goal.uuid)

        if (index === -1) data.push(event.data.goal)
        else data[index] = event.data.goal

        if (!event.data.isFree){
          data = lodash.sortBy(data, ['goal_type_id', 'meta'])
          data = labelingGoals(data)
        }

        return {
          ...context,
          goals: {
            ...context.goals,
            data
          }
        }

      }),
      deleteGoal: assign((context, _event: any) => {

        const event : DoneInvokeEvent<any> = _event
 
        if (event.type !== 'done.invoke.deleteGoal' || !context.goals) 
          return { ...context } 

        const newGoals = context.goals.data.filter( goal => goal.uuid !== context.uuidDelete )

        return {
          ...context,
          goals: {
            ...context.goals,
            data: labelingGoals(newGoals)
          }
        }

      })
    },
    guards: {
      isUnauthorized: (context) => context.statusError === 401,
    }
  } 
)