import { initializeApp, getApps, FirebaseApp, getApp } from "firebase/app";
import { doc, Firestore, getFirestore, setDoc } from 'firebase/firestore'
import firebaseConfig from '../config/firebaseConnection';

/**
 * Clase para controlar la conexión con firebase
 */
class Firebase { 

  private app: FirebaseApp

  private db: Firestore

  constructor() {

    if ( getApps().length === 0 ) this.app = initializeApp(firebaseConfig)
    else this.app = getApp()

    this.db = getFirestore(this.app)
    
  }

  /**
   * Enviar data a firebase
   * @param data la data a enviar a firebase
   * @param key del documento
   * @param collection a que colección se va a enviar
   */
  public addDocument = async (data: any, key: string, collection: string) : Promise<any> => {
    
    try {
      await setDoc(doc(this.db, collection, key), data)
    } catch (error) {
      console.log(`error`, error)
      throw new Error("no se pudo subir");
      
    }

  }
  

}

const firebase : Firebase = new Firebase()
export default firebase